import React, {createContext, useCallback, useMemo, useState} from 'react'

import {NotificationBannersContainer} from './NotificationBannersContainer'

const NOTIFICATION_BANNER_DURATION = 5000

export enum NotificationType {
  Error = 'error',
  Success = 'success',
}

export type AppNotification = {
  id: string
  text: string
  notificationType: NotificationType
}

type NotificationContextProps = {
  notifications: AppNotification[]
  addNotification: (
    notificationMessage: string,
    notificationType: NotificationType,
  ) => void
}

export const NotificationContext = createContext({} as NotificationContextProps)

type NotificationContextProviderProps = {
  children: React.ReactNode
}

export const NotificationContextProvider: React.FC<
  NotificationContextProviderProps
> = ({children}) => {
  const [notifications, setNotifications] = useState<AppNotification[]>([])

  const dismissNotification = useCallback((notificationId: string) => {
    setNotifications((appNotifications) =>
      appNotifications.filter(
        (appNotification) => appNotification.id !== notificationId,
      ),
    )
  }, [])

  const addNotification = useCallback(
    (notificationMessage: string, notificationType: NotificationType) => {
      const id = new Date().valueOf().toString()
      const newNotification = {
        text: notificationMessage,
        id,
        notificationType,
      }

      setNotifications((appNotifications) => [
        newNotification,
        ...appNotifications,
      ])
      setTimeout(() => dismissNotification(id), NOTIFICATION_BANNER_DURATION)
    },
    [dismissNotification],
  )

  const contextValue = useMemo(
    () => ({notifications, addNotification}),
    [addNotification, notifications],
  )

  return (
    <NotificationContext.Provider value={contextValue}>
      {children}
      <NotificationBannersContainer
        notifications={notifications}
        dismissNotification={dismissNotification}
      />
    </NotificationContext.Provider>
  )
}
