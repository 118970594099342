/* tslint:disable */
/* eslint-disable */
/**
 * Cushla API
 * REST API for Cushla
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Source where did patient get the estimated date from.
 * @export
 * @enum {string}
 */

export enum PregnancyDeliveryDateEstimateSourceDto {
  GENERAL_PRACTITIONER = 'GENERAL_PRACTITIONER',
  HOSPITAL_SCAN = 'HOSPITAL_SCAN',
  PREGNANCY_APP = 'PREGNANCY_APP',
  OTHER = 'OTHER',
}
