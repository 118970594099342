import React from 'react'

import {
  StyledQuestionnairesColumn,
  QuestionnairesColumnItem,
} from './QuestionnairesColumn.styled'

import {Typography} from '~/components/general/typography/Typography'
import {theme} from '~/styles/theme'

type ItemType = {
  id: string | number
  title: string
  description: string
}

type Props = {
  items: ItemType[]
}

export const QuestionnairesColumn: React.FC<Props> = ({items}) => (
  <StyledQuestionnairesColumn>
    {items.map((item) => (
      <QuestionnairesColumnItem key={item.id}>
        <Typography color={theme.colors.grey.main}>{item.title}</Typography>
        <Typography size="lg">{item.description}</Typography>
      </QuestionnairesColumnItem>
    ))}
  </StyledQuestionnairesColumn>
)
