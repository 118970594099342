import {DashboardDto} from '@generated/models'

import {Sex} from './medical'
import {Notification} from './notification'

export type Name = {
  firstName?: string | null
  secondName?: string | null
  title?: string | null
  knownBy?: string | null
}

export type BasicPatientData = {
  dateOfBirth?: string
  gender?: Sex
}

export type Dashboard = Omit<DashboardDto, 'notifications'> & {
  notifications: Notification[]
}

export type {
  CompleteOnboardingStepRequest,
  IdentityDocumentsDto as IdentityDocuments,
  PatientForPractitionerDTO as PractitionersPatient,
  PatientOnboardingDto as PatientOnboarding,
  PatientResponse as Patient,
  PatientShortDto as PatientShort,
  RequestMedicalRecordsRequest,
  SignupRequest as AddPatientRequest,
  UpdateUnitSystemDto as UpdateUnitSystemRequest,
} from '@generated/models'

export {
  UnitSystem,
  SubscriptionStatus,
  PatientOnboardingStepStatus,
  OnboardingStep,
} from '@generated/models'
