import React from 'react'

import {
  ActivityRow,
  ActivityStatus as StyledStatus,
  ActivityText,
} from '../styles/styled'

import {PreventiveActivity} from '~/types/preventive'
import {Container} from '~/components/general/container/Container'
import {getActivityBackgroundColor} from '~/utils/preventive'

type ActivityStatusProps = {
  activity: PreventiveActivity
}

export const ActivityStatus: React.FC<ActivityStatusProps> = ({activity}) => (
  <Container>
    <ActivityRow>
      <StyledStatus backgroundColor={getActivityBackgroundColor(activity)} />
      <ActivityText>{activity.description}</ActivityText>
    </ActivityRow>
  </Container>
)
