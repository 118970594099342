import {
  ActivePhysitrackProgramDto,
  AppointmentCancelledDto,
  AppointmentRescheduledDto,
  DataRequestDto,
  MissingProfileInformationReminderDto,
  NewFeatureDto,
  NewFileSharedDto,
  NewPhysitrackProgramDto,
  NewQuestionnaireDto,
  PatientReportMismatchDto,
  PeriodicNotificationDto,
  PregnancyJourneyNotificationDto,
  QuestionnaireReminderDto,
  ReviewAppointmentDto,
  TriageReminderDto,
  UpcomingAppointmentDto,
} from '@generated/models'

export type {
  DeviceTokenResponse as DeviceToken,
  DeviceTokenRequest,
} from '@generated/models'

export {
  DeviceType,
  PeriodicNotificationTypeDto as PeriodicNotificationType,
} from '@generated/models'

export enum PushNotificationType {
  ACTIVE_PHYSITRACK_PROGRAM = 'ActivePhysitrackProgram',
  APPOINTMENT_CANCELLED = 'AppointmentCancelled',
  APPOINTMENT_RESCHEDULED = 'AppointmentRescheduled',
  DATA_ACCESS_REQUEST = 'DataRequest',
  MISSING_INFORMATION = 'MissingProfileInformationReminder',
  NEW_FEATURE = 'NewFeature',
  NEW_FILE_SHARED = 'NewFileShared',
  NEW_PHYSITRACK_PROGRAM = 'NewPhysitrackProgram',
  NEW_QUESTIONNAIRE = 'NewQuestionnaire',
  PATIENT_REPORT_MISMATCH = 'PatientReportMismatch',
  PERIODIC_NOTIFICATION = 'Periodic',
  PREGNANCY_JOURNEY_NOTIFICATION = 'PregnancyJourney',
  QUESTIONNAIRE_REMINDER = 'QuestionnaireReminder',
  REVIEW_APPOINTMENT = 'ReviewAppointment',
  TRIAGE_REMINDER = 'TriageReminder',
  UPCOMING_APPOINTMENT = 'UpcomingAppointment',
  USER_KYC_FAILURE = 'UserKycFailure',
  USER_KYC_SUCCESS = 'UserKycSuccess',
}

export enum NotificationType {
  ACTIVE_PHYSITRACK_PROGRAM = 'ActivePhysitrackProgramDto',
  APPOINTMENT_CANCELLED = 'AppointmentCancelledDto',
  APPOINTMENT_RESCHEDULED = 'AppointmentRescheduledDto',
  DATA_ACCESS_REQUEST = 'DataRequestDto',
  MISSING_INFORMATION = 'MissingProfileInformationReminderDto',
  NEW_FEATURE = 'NewFeatureDto',
  NEW_FILE_SHARED = 'NewFileSharedDto',
  NEW_PHYSITRACK_PROGRAM = 'NewPhysitrackProgramDto',
  NEW_QUESTIONNAIRE = 'NewQuestionnaireDto',
  PATIENT_REPORT_MISMATCH = 'PatientReportMismatchDto',
  PERIODIC_NOTIFICATION = 'PeriodicNotificationDto',
  PREGNANCY_NOTIFICATION = 'PregnancyJourneyNotificationDto',
  QUESTIONNAIRE_REMINDER = 'QuestionnaireReminderDto',
  REVIEW_APPOINTMENT = 'ReviewAppointmentDto',
  TRIAGE_REMINDER = 'TriageReminderDto',
  UPCOMING_APPOINTMENT = 'UpcomingAppointmentDto',
}

export type NotificationCommonProps = {
  id: string
  patientId: string
  createdAt: string
}

export type DataAccessNotificationContent = DataRequestDto & {
  type: NotificationType.DATA_ACCESS_REQUEST
}

export type FileSharingNotificationContent = NewFileSharedDto & {
  type: NotificationType.NEW_FILE_SHARED
}

export type MissingInformationNotificationContent =
  MissingProfileInformationReminderDto & {
    type: NotificationType.MISSING_INFORMATION
  }

export type PeriodicNotificationContent = PeriodicNotificationDto & {
  type: NotificationType.PERIODIC_NOTIFICATION
}

export type PregnancyJourneyNotificationContent =
  PregnancyJourneyNotificationDto & {
    type: NotificationType.PREGNANCY_NOTIFICATION
  }

export type NewFeatureNotificationContent = NewFeatureDto & {
  type: NotificationType.NEW_FEATURE
}

export type NewQuestionnaireNotificationContent = NewQuestionnaireDto & {
  type: NotificationType.NEW_QUESTIONNAIRE
}

export type NewPhysitrackProgramNotificationContent =
  NewPhysitrackProgramDto & {
    type: NotificationType.NEW_PHYSITRACK_PROGRAM
  }

export type ActivePhysitrackProgramNotificationContent =
  ActivePhysitrackProgramDto & {
    type: NotificationType.ACTIVE_PHYSITRACK_PROGRAM
  }

export type RescheduledAppointmentNotificationContent =
  AppointmentRescheduledDto & {
    type: NotificationType.APPOINTMENT_RESCHEDULED
  }

export type CancelledAppointmentNotificationContent =
  AppointmentCancelledDto & {
    type: NotificationType.APPOINTMENT_CANCELLED
  }

export type PatientReportMismatchNotificationContent =
  PatientReportMismatchDto & {
    type: NotificationType.PATIENT_REPORT_MISMATCH
  }

export type QuestionnaireReminderNotificationContent =
  QuestionnaireReminderDto & {
    type: NotificationType.QUESTIONNAIRE_REMINDER
  }

export type TriageReminderNotificationContent = TriageReminderDto & {
  type: NotificationType.TRIAGE_REMINDER
}

export type ReviewAppointmentNotificationContent = ReviewAppointmentDto & {
  type: NotificationType.REVIEW_APPOINTMENT
}

export type UpcomingAppointmentNotificationContent = UpcomingAppointmentDto & {
  type: NotificationType.UPCOMING_APPOINTMENT
}

export type NotificationContent =
  | ActivePhysitrackProgramNotificationContent
  | CancelledAppointmentNotificationContent
  | DataAccessNotificationContent
  | FileSharingNotificationContent
  | MissingInformationNotificationContent
  | NewFeatureNotificationContent
  | NewQuestionnaireNotificationContent
  | NewPhysitrackProgramNotificationContent
  | PatientReportMismatchNotificationContent
  | PeriodicNotificationContent
  | QuestionnaireReminderNotificationContent
  | RescheduledAppointmentNotificationContent
  | ReviewAppointmentNotificationContent
  | TriageReminderNotificationContent
  | UpcomingAppointmentNotificationContent

export type Notification = NotificationCommonProps & {
  content: NotificationContent
}

type RelatedNotificationContent<T extends NotificationType> = Extract<
  NotificationContent,
  {type: T}
>

export type RelatedNotification<T extends NotificationType> =
  NotificationCommonProps & {
    content: RelatedNotificationContent<T>
  }
