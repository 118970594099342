import {
  endpointKeys,
  ObstetricsFileUrlParams,
  PatientIdUrlParam,
  PatientTimelineParams,
} from '@common/api'
import {
  BasicHealthInfoResponse,
  BloodTypeDetails,
  CriticalNote,
  FamilyHistory,
  FamilyHistoryTypeValue,
  GeneralItemType,
  Obstetrics,
  PagedResponse,
  PatientTimelineItem,
  SettingsType,
  StaticFile,
} from '@common/models'
import {
  basicHealthInfoNormaliser,
  bloodTypeNormaliser,
  familyHistoryNormaliser,
  familyHistoryTypeValueNormaliser,
  getItemTypeNormaliser,
  getPagedResponseNormaliser,
  patientTimelineItemNormaliser,
  obstetricsOrNullNormaliser,
  staticFileNormaliser,
  criticalNoteOrNullNormaliser,
} from '@common/normalisers'
import {UseQueryOptions} from '@tanstack/react-query'

import {useApi, useWebGet, useWebInfiniteGet} from './helpers'

export const useInfiniteGetPatientTimeline = (params: PatientTimelineParams) =>
  useWebInfiniteGet<PagedResponse<PatientTimelineItem>>(
    [endpointKeys.patientTimeline, params],
    useApi().patientTimeline(params),
    getPagedResponseNormaliser(patientTimelineItemNormaliser),
  )

export const useGetPatientHealthSummary = (params: PatientIdUrlParam) =>
  useWebGet<BasicHealthInfoResponse>(
    [endpointKeys.patientBasicHealthInfo, params],
    useApi().patientBasicHealthInfo(params),
    basicHealthInfoNormaliser,
  )

export const useGetPatientBloodType = (params: PatientIdUrlParam) =>
  useWebGet<BloodTypeDetails>(
    [endpointKeys.bloodType, params],
    useApi().bloodType(params),
    bloodTypeNormaliser,
  )

export const useGetPatientObstetrics = (params: PatientIdUrlParam) =>
  useWebGet<Obstetrics | null>(
    [endpointKeys.patientObstetrics, params],
    useApi().patientObstetrics(params),
    obstetricsOrNullNormaliser,
  )

export const useGetPatientFamilyHistory = (params: PatientIdUrlParam) =>
  useWebGet<FamilyHistory>(
    [endpointKeys.patientFamilyHistory, params],
    useApi().patientFamilyHistory(params),
    familyHistoryNormaliser,
  )

export const useGetFamilyHistoryTypeList = () =>
  useWebGet<PagedResponse<GeneralItemType<FamilyHistoryTypeValue>>>(
    [endpointKeys.settings],
    useApi().settings({type: SettingsType.FAMILY_HISTORY}),
    getPagedResponseNormaliser(
      getItemTypeNormaliser(familyHistoryTypeValueNormaliser),
    ),
  )

export const useGetPatientCriticalNote = (params: PatientIdUrlParam) =>
  useWebGet<CriticalNote | null>(
    [endpointKeys.patientCriticalNote, params],
    useApi().patientCriticalNote(params),
    criticalNoteOrNullNormaliser,
  )

export const useGetPatientObstetricsBirthFile = (
  params: ObstetricsFileUrlParams,
  options?: UseQueryOptions<StaticFile, Error, StaticFile>,
) =>
  useWebGet<StaticFile>(
    [endpointKeys.patientObstetricsBirthFile, params],
    useApi().patientObstetricsBirthFile(params),
    staticFileNormaliser,
    options,
  )

export const useGetPatientObstetricsPregnancyFile = (
  params: ObstetricsFileUrlParams,
  options?: UseQueryOptions<StaticFile, Error, StaticFile>,
) =>
  useWebGet<StaticFile>(
    [endpointKeys.patientObstetricsPregnancyFile, params],
    useApi().patientObstetricsPregnancyFile(params),
    staticFileNormaliser,
    options,
  )
