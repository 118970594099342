import * as React from 'react'

import {IconProps} from '.'

function MonitoringNote({color, size, isFilled}: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={isFilled ? color : 'none'}
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.25 6C3.25 5.03379 4.03379 4.25 5 4.25H7C7.41421 4.25 7.75 4.58579 7.75 5C7.75 5.41421 7.41421 5.75 7 5.75H5C4.86221 5.75 4.75 5.86221 4.75 6V21C4.75 21.1378 4.86221 21.25 5 21.25H19C19.1378 21.25 19.25 21.1378 19.25 21V6C19.25 5.86221 19.1378 5.75 19 5.75H17C16.5858 5.75 16.25 5.41421 16.25 5C16.25 4.58579 16.5858 4.25 17 4.25H19C19.9662 4.25 20.75 5.03379 20.75 6V21C20.75 21.9662 19.9662 22.75 19 22.75H5C4.03379 22.75 3.25 21.9662 3.25 21V6Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.25 16C7.25 15.5858 7.58579 15.25 8 15.25H16C16.4142 15.25 16.75 15.5858 16.75 16C16.75 16.4142 16.4142 16.75 16 16.75H8C7.58579 16.75 7.25 16.4142 7.25 16Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.25 19C9.25 18.5858 9.58579 18.25 10 18.25H14C14.4142 18.25 14.75 18.5858 14.75 19C14.75 19.4142 14.4142 19.75 14 19.75H10C9.58579 19.75 9.25 19.4142 9.25 19Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.236 2.75C10.7631 2.75 10.3298 3.01749 10.1178 3.44141L9.67082 4.33541C9.54378 4.5895 9.28408 4.75 9 4.75H8C7.86221 4.75 7.75 4.86221 7.75 5V6C7.75 6.13779 7.86221 6.25 8 6.25H16C16.1378 6.25 16.25 6.13779 16.25 6V5C16.25 4.86221 16.1378 4.75 16 4.75H15C14.7159 4.75 14.4562 4.5895 14.3292 4.33541L13.8822 3.44141C13.6701 3.0173 13.2377 2.75 12.764 2.75H11.236ZM8.77618 2.77059C9.24222 1.83851 10.1949 1.25 11.236 1.25H12.764C13.8063 1.25 14.7579 1.8387 15.2238 2.77059L15.4635 3.25H16C16.9662 3.25 17.75 4.03379 17.75 5V6C17.75 6.96621 16.9662 7.75 16 7.75H8C7.03379 7.75 6.25 6.96621 6.25 6V5C6.25 4.03379 7.03379 3.25 8 3.25H8.53647L8.77618 2.77059Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.75 11.5C9.75 11.0858 10.0858 10.75 10.5 10.75H13.5C13.9142 10.75 14.25 11.0858 14.25 11.5C14.25 11.9142 13.9142 12.25 13.5 12.25H10.5C10.0858 12.25 9.75 11.9142 9.75 11.5Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 9.25C12.4142 9.25 12.75 9.58579 12.75 10V13C12.75 13.4142 12.4142 13.75 12 13.75C11.5858 13.75 11.25 13.4142 11.25 13V10C11.25 9.58579 11.5858 9.25 12 9.25Z"
        fill={color}
      />
    </svg>
  )
}

export default MonitoringNote
