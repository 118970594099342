import styled from 'styled-components'

export const StyledPatientHealthJourneyTimeline = styled.div`
  margin-top: ${({theme}) => theme.spacing.md};

  & > * {
    margin-bottom: ${({theme}) => theme.spacing.lg};
  }

  & > *:last-child {
    margin-bottom: 0;
  }
`
