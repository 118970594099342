import {useTranslation} from 'react-i18next'
import React, {useMemo} from 'react'
import {PagedResponse, AssignedQuestionnaireInfo} from '@common/models'
import {useNavigate, useParams} from 'react-router-dom'

import {getTableDateValue, getTablePatientName} from '~/utils'
import {AssignedQuestionnaires} from '~/components/questionnaires/AssignedQuestionnaires'

export const SentQuestionnaires: React.FC = () => {
  const {t} = useTranslation()
  const navigate = useNavigate()
  const {questionnaireId} = useParams()

  const tableHeaders = useMemo(
    () => ({
      columns: [
        {
          title: t('Patient name'),
          columnWidthPercent: 30,
        },
        {
          title: t('Questionnaire title'),
          columnWidthPercent: 30,
        },
        {
          title: t('Date sent'),
          columnWidthPercent: 20,
        },
        {
          title: t('Date answered'),
          columnWidthPercent: 20,
        },
      ],
    }),
    [t],
  )

  const getTableRows = (data?: PagedResponse<AssignedQuestionnaireInfo>) =>
    data?.records.map((record) => ({
      columns: [
        getTablePatientName(record.patientDetails),
        record.title,
        getTableDateValue(record.dateReceived),
        record.dateCompleted
          ? getTableDateValue(record.dateCompleted)
          : t('Unanswered'),
      ],
      onRowClick: record.dateCompleted
        ? () => navigate(`/app/questionnaires/responses/${record.id}`)
        : undefined,
    })) ?? []

  return (
    <AssignedQuestionnaires
      headers={tableHeaders}
      getRows={getTableRows}
      questionnaireId={questionnaireId}
    />
  )
}
