import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {
  Appointment,
  DiagnosedCondition,
  HealthCategory,
  LifeMilestone,
  MedicalTest,
  Medication,
  PatientAllergy,
  PatientCholesterol,
  PatientTimelineItem,
  PatientVaccination,
  PractitionersPatient,
  Surgery,
  TimelineItemType,
} from '@common/models'
import {useTranslation} from 'react-i18next'
import {isNotNull} from '@common/utils'
import {useSearchParams} from 'react-router-dom'
import {PatientTimelineParams} from '@common/api'

import {StyledPatientHealthJourneyTimeline} from './PatientTimeline.styled'
import {
  PatientTimelineModal,
  PatientTimelineModalData,
} from './components/PatientTimelineModal'

import {Typography} from '~/components/general/typography/Typography'
import {useInfiniteGetPatientTimeline} from '~/api'
import {Loading} from '~/components/general/loading/Loading'
import {useModalData, useTimelineCardData} from '~/utils/timeline'
import {
  TimelineTable,
  TimelineTableItem,
} from '~/components/general/timelineTable/TimelineTable'
import {SearchBox} from '~/components/general/searchBox/SearchBox'
import {Container} from '~/components/general/container/Container'

const useCreateCardData = (openModal: () => void) => {
  const {
    getAllergyModalData,
    getCholesterolModalData,
    getConditionModalData,
    getMedicalTestModalData,
    getMedicationModalData,
    getSurgeryModalData,
    getVaccinationModalData,
    getLifeMilestoneModalData,
    getAppointmentModalData,
  } = useModalData()

  const {
    getAllergyTimelineCardData,
    getCholesterolTimelineCardData,
    getConditionTimelineCardData,
    getMedicalTestTimelineCardData,
    getMedicationTimelineCardData,
    getSurgeryTimelineCardData,
    getVaccinationTimelineCardData,
    getAppointmentTimelineCardData,
    getLifeMilestoneTimelineCardData,
  } = useTimelineCardData()

  const [modalData, setModalData] = useState<PatientTimelineModalData>()

  const handleItemClick = (newModalData: PatientTimelineModalData) => {
    setModalData(newModalData)
    openModal()
  }

  const createAllergyCardData = (
    dataItem: PatientAllergy,
  ): TimelineTableItem => ({
    item: getAllergyTimelineCardData(dataItem),
    onClick: () => handleItemClick(getAllergyModalData(dataItem)),
  })

  const createCholesterolCardData = (
    dataItem: PatientCholesterol,
  ): TimelineTableItem => ({
    item: getCholesterolTimelineCardData(dataItem),
    onClick: () => handleItemClick(getCholesterolModalData(dataItem)),
  })

  const createConditionCardData = (
    item: DiagnosedCondition,
  ): TimelineTableItem => ({
    item: getConditionTimelineCardData(item),
    onClick: () => handleItemClick(getConditionModalData(item)),
  })

  const createMedicalTestCardData = (item: MedicalTest): TimelineTableItem => ({
    item: getMedicalTestTimelineCardData(item),
    onClick: () => handleItemClick(getMedicalTestModalData(item)),
  })

  const createMedicationCardData = (item: Medication): TimelineTableItem => ({
    item: getMedicationTimelineCardData(item),
    onClick: () => handleItemClick(getMedicationModalData(item)),
  })

  const createSurgeryCardData = (item: Surgery): TimelineTableItem => ({
    item: getSurgeryTimelineCardData(item),
    onClick: () => handleItemClick(getSurgeryModalData(item)),
  })

  const createVaccinationCardData = (
    item: PatientVaccination,
  ): TimelineTableItem => ({
    item: getVaccinationTimelineCardData(item),
    onClick: () => handleItemClick(getVaccinationModalData(item)),
  })

  const createAppointmentCardData = (item: Appointment): TimelineTableItem => ({
    item: getAppointmentTimelineCardData(item),
    onClick: () => handleItemClick(getAppointmentModalData(item)),
  })

  const createLifeMilestoneCardData = (
    item: LifeMilestone,
  ): TimelineTableItem => ({
    item: getLifeMilestoneTimelineCardData(item),
    onClick: () => handleItemClick(getLifeMilestoneModalData(item)),
  })

  const createCardData = (dataItem: PatientTimelineItem) => {
    try {
      switch (dataItem.type) {
        case HealthCategory.ALLERGY:
          return createAllergyCardData(dataItem.item)
        case HealthCategory.CHOLESTEROL:
          return createCholesterolCardData(dataItem.item)
        case HealthCategory.DIAGNOSED_CONDITION:
          return createConditionCardData(dataItem.item)
        case HealthCategory.MEDICAL_TEST:
          return createMedicalTestCardData(dataItem.item)
        case HealthCategory.MEDICATION:
          return createMedicationCardData(dataItem.item)
        case HealthCategory.SURGERY:
          return createSurgeryCardData(dataItem.item)
        case HealthCategory.VACCINATION:
          return createVaccinationCardData(dataItem.item)
        case HealthCategory.APPOINTMENT:
          return createAppointmentCardData(dataItem.item)
        case HealthCategory.LIFE_MILESTONE:
          return createLifeMilestoneCardData(dataItem.item)
        default:
          return null
      }
    } catch (error) {
      // [CUSH-655] FIXME: Add error logging
      // eslint-disable-next-line no-console
      console.warn('Could not create health journey timeline card', error)
    }
    return null
  }

  return {
    createCardData,
    modalData,
  }
}

type PatientHealthJourneyTimelineProps = {
  patient: PractitionersPatient
}

export const PatientTimeline: React.FC<PatientHealthJourneyTimelineProps> = ({
  patient,
}) => {
  const {t} = useTranslation()
  const loader = useRef(null)

  const [isRecordModalVisible, setIsRecordModalVisible] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const [timelineQuery, setTimelineQuery] = useState<PatientTimelineParams>({
    patientId: patient.patientId,
  })

  useEffect(() => {
    const searchTerm = searchParams.get('searchTerm') || undefined
    const typeParam = searchParams.get('typeFilter') || undefined
    const typeFilter =
      typeParam && typeParam in TimelineItemType
        ? (typeParam as TimelineItemType)
        : undefined
    setTimelineQuery({
      patientId: patient.patientId,
      searchTerm,
      typeFilter,
    })
  }, [searchParams, patient])
  const {createCardData, modalData} = useCreateCardData(() =>
    setIsRecordModalVisible(true),
  )

  const {
    data: healthJourneyData,
    isInitialLoading: isHealthDataLoading,
    isFetching: isHealthDataFetching,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteGetPatientTimeline(timelineQuery)

  const handleObserver = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const target = entries[0]
      if (target.isIntersecting && hasNextPage) {
        fetchNextPage()
      }
    },
    [fetchNextPage, hasNextPage],
  )

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '20px',
    }
    const observer = new IntersectionObserver(handleObserver, options)

    if (loader.current) {
      observer.observe(loader.current)
    }
  }, [handleObserver])

  const timelineItems = useMemo(
    () =>
      healthJourneyData?.pages
        .flatMap(({records}) => records)
        .map(createCardData)
        .filter(isNotNull) ?? [],
    [healthJourneyData, createCardData],
  )

  if (isHealthDataLoading) {
    return <Loading />
  }

  const handleSearchQueryChange = (searchTerm: string) => {
    setSearchParams((params) => {
      params.set('searchTerm', searchTerm)
      return params
    })
  }

  return (
    <>
      <StyledPatientHealthJourneyTimeline>
        <Container flex justify="flex-end">
          <SearchBox
            onClick={handleSearchQueryChange}
            initialValue={searchParams.get('searchTerm')}
          />
        </Container>
        {timelineItems.length ? (
          <>
            <TimelineTable items={timelineItems} />
            {isHealthDataFetching && <Loading />}
            <div ref={loader} />
          </>
        ) : (
          <Typography>{t('No data to display')}</Typography>
        )}
      </StyledPatientHealthJourneyTimeline>
      <PatientTimelineModal
        isVisible={isRecordModalVisible}
        onClose={() => setIsRecordModalVisible(false)}
        data={modalData}
      />
    </>
  )
}
