import styled from 'styled-components'

import {HealthDataTooltip} from './HealthDataTooltip'

import {spacing} from '~/styles/theme'

// Extra margin added around tooltip, so that the shadow is not cut off
export const TOOLTIP_SHADOW_MARGIN = spacing(2)

export const StyledTooltip = styled(HealthDataTooltip)`
  margin: ${TOOLTIP_SHADOW_MARGIN}px;
`
