/* tslint:disable */
/* eslint-disable */
/**
 * Cushla API
 * REST API for Cushla
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Source where the life milestone originates from. Only *MANUAL* life milestones should be editable/deletable! Other types are autogenerated and any changes might be lost upon regeneration. They should be updated at the source that is generating them.
 * @export
 * @enum {string}
 */

export enum LifeMilestoneSourceTypeDto {
  MANUAL = 'MANUAL',
  OBSTETRICS = 'OBSTETRICS',
}
