import * as React from 'react'

import {IconProps} from '.'

function CheckboxOn({color, size, isFilled}: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={isFilled ? color : 'none'}
      xmlns="http://www.w3.org/2000/svg">
      <rect x="3" y="3" width="18" height="18" rx="5" fill={color} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.7367 9.58213C16.0295 9.87511 16.0293 10.35 15.7363 10.6428L11.7323 14.6445C11.4397 14.9369 10.9655 14.9372 10.6726 14.6451L8.26539 12.2451C7.97206 11.9526 7.97134 11.4778 8.2638 11.1844C8.55625 10.8911 9.03112 10.8904 9.32446 11.1828L11.2015 13.0543L14.676 9.58182C14.969 9.28901 15.4439 9.28915 15.7367 9.58213Z"
        fill="white"
      />
    </svg>
  )
}

export default CheckboxOn
