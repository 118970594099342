import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {IDocument} from '@cyntler/react-doc-viewer'

import {
  AttachmentButton,
  PatientTimelineFileEndpointType,
} from './AttachmentButton'
import {AttachmentsModal} from './AttachmentsModal'
import {RowHeader} from './PatientTimelineModal.styled'

import {theme} from '~/styles/theme'

export type AttachedFile = {
  endpointType: PatientTimelineFileEndpointType
  fileId: string
  fileName: string
}

export type AttachmentsContainerProps = {
  parentObjectId: string
  files: AttachedFile[]
}

export const AttachmentsContainer = ({
  parentObjectId,
  files,
}: AttachmentsContainerProps) => {
  const {t} = useTranslation()
  const [isFileModalVisible, setIsFileModalVisible] = useState(false)
  const [fileIndex, setFileIndex] = useState(0)
  const [filesList, setFilesList] = useState<Array<IDocument | undefined>>(
    Array(files.length),
  )

  const openFileModal = (index: number) => {
    setFileIndex(index)
    setIsFileModalVisible(true)
  }

  const addFileUrlToList = (
    uri: string,
    index: number,
    attachedFile: AttachedFile,
  ) => {
    filesList[index] = {uri, fileName: attachedFile.fileName}
    setFilesList([...filesList])
  }

  return files ? (
    <>
      <RowHeader color={theme.colors.primary.main} weight="medium">
        {t('Files')}
      </RowHeader>
      {files.map((file, index) => (
        <AttachmentButton
          fileName={file.fileName}
          onClick={() => openFileModal(index)}
          endpoint={file.endpointType}
          parentObjectId={parentObjectId}
          fileId={file.fileId}
          onUrlLoaded={(url) => addFileUrlToList(url, index, file)}
          key={`AttachmentsButton-${index}`}
        />
      ))}
      <AttachmentsModal
        files={filesList.filter<IDocument>((i): i is IDocument => Boolean(i))}
        isVisible={isFileModalVisible}
        onClose={() => setIsFileModalVisible(false)}
        initialIndex={fileIndex}
      />
    </>
  ) : null
}
