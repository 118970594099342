import {useTranslation} from 'react-i18next'
import React, {useContext, useState} from 'react'
import {MISSING_ID} from '@common/utils'
import {useNavigate} from 'react-router-dom'
import {QuestionnaireInfo} from '@common/models'

import {QuestionnairesContext} from '../QuestionnairesContext'

import {PractitionerContext} from '~/components/profile/PractitionerContext'
import {ShareQuestionnaireModal} from '~/components/questionnaires/shareModal/ShareQuestionnaireModal'
import {useGetQuestionnaires} from '~/api'
import {Loading} from '~/components/general/loading/Loading'
import {Typography} from '~/components/general/typography/Typography'
import {LibraryCard} from '~/components/general/libraryCard/LibraryCard'
import {LayoutContainer} from '~/components/layout/container/LayoutContainer'

type QuestionnairesLibraryContentProps = {
  openModal: () => void
}

const QuestionnairesLibraryContent: React.FC<
  QuestionnairesLibraryContentProps
> = ({openModal}) => {
  const {t} = useTranslation()
  const navigate = useNavigate()
  const {selectedEmployment} = useContext(PractitionerContext)
  const {setQuestionnaire} = useContext(QuestionnairesContext)
  const {data, isInitialLoading} = useGetQuestionnaires({
    facilityId: selectedEmployment?.facilityId ?? MISSING_ID,
  })

  const handleShareQuestionnaire = (questionnaire: QuestionnaireInfo) => {
    setQuestionnaire(questionnaire)
    openModal()
  }

  if (isInitialLoading) {
    return <Loading />
  }

  if (!data?.length) {
    return <Typography>{t('No questionnaires to display')}</Typography>
  }

  return (
    <>
      {data.map((questionnaire) => (
        <LibraryCard
          title={questionnaire.title || t('Questionnaire')}
          content={questionnaire.description || t('No description provided')}
          onIconClick={() => handleShareQuestionnaire(questionnaire)}
          onClick={() =>
            questionnaire.id &&
            navigate(`/app/questionnaires/${questionnaire.id}`)
          }
          key={questionnaire.id}
          chips={questionnaire.tags}
          spaceAfter
        />
      ))}
    </>
  )
}

export const QuestionnairesLibrary: React.FC = () => {
  const [isShareModalVisible, setIsShareModalVisible] = useState(false)

  return (
    <LayoutContainer>
      <QuestionnairesLibraryContent
        openModal={() => setIsShareModalVisible(true)}
      />
      <ShareQuestionnaireModal
        isVisible={isShareModalVisible}
        onClose={() => setIsShareModalVisible(false)}
      />
    </LayoutContainer>
  )
}
