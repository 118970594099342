import React from 'react'
import {useTranslation} from 'react-i18next'
import {HealthDataType} from '@common/models'

import {BaseTrendEditor, TrendEditorProps} from './BaseTrendEditor'
import {EditableTrendTypes} from '../utils'

const BloodPressureEditor: React.FC<TrendEditorProps> = ({setData}) => {
  const {t} = useTranslation()

  return (
    <BaseTrendEditor
      setData={setData}
      fields={[
        {
          label: t('Systolic'),
          name: 'BP SYS',
          inputType: 'number',
        },
        {
          label: t('Diastolic'),
          name: 'BP DIA',
          inputType: 'number',
        },
      ]}
    />
  )
}

const RestingHeartRateEditor: React.FC<TrendEditorProps> = ({setData}) => {
  const {t} = useTranslation()
  return (
    <BaseTrendEditor
      setData={setData}
      fields={[
        {
          label: t('Resting Heart Rate'),
          name: 'REST HR',
          inputType: 'number',
        },
      ]}
    />
  )
}

const WeightEditor: React.FC<TrendEditorProps> = ({setData}) => {
  const {t} = useTranslation()
  return (
    <BaseTrendEditor
      setData={setData}
      fields={[
        {
          label: t('Weight (kg)'),
          name: 'Weight (kg)',
          inputType: 'number',
        },
      ]}
    />
  )
}

const HeightEditor: React.FC<TrendEditorProps> = ({setData}) => {
  const {t} = useTranslation()
  return (
    <BaseTrendEditor
      setData={setData}
      fields={[
        {
          label: t('Height (cm)'),
          name: 'Height (cm)',
          inputType: 'number',
        },
      ]}
    />
  )
}

const WaistCircumferenceEditor: React.FC<TrendEditorProps> = ({setData}) => {
  const {t} = useTranslation()
  return (
    <BaseTrendEditor
      setData={setData}
      fields={[
        {
          label: t('Waist Circumference (cm)'),
          name: 'Waist Circumference (cm)',
          inputType: 'number',
        },
      ]}
    />
  )
}

const BodyFatPercentageEditor: React.FC<TrendEditorProps> = ({setData}) => {
  const {t} = useTranslation()
  return (
    <BaseTrendEditor
      setData={setData}
      fields={[
        {
          label: t('Body Fat Percentage (%)'),
          name: '',
          inputType: 'number',
        },
      ]}
    />
  )
}

const CholesterolEditor: React.FC<TrendEditorProps> = ({setData}) => {
  const {t} = useTranslation()
  return (
    <BaseTrendEditor
      setData={setData}
      fields={[
        {
          label: t('Total'),
          name: 'Total Cholesterol',
          inputType: 'number',
        },
        {
          label: t('HDL'),
          name: 'HDL',
          inputType: 'number',
        },
        {
          label: t('LDL'),
          name: 'LDL',
          inputType: 'number',
        },
        {
          label: t('TriGlycerides'),
          name: 'TG',
          inputType: 'number',
        },
      ]}
    />
  )
}

const WaterConsumptionEditor: React.FC<TrendEditorProps> = ({setData}) => {
  const {t} = useTranslation()
  return (
    <BaseTrendEditor
      setData={setData}
      fields={[
        {
          label: t('Water Consumption (ml)'),
          name: 'Water Consumption (ml)',
          inputType: 'number',
        },
      ]}
    />
  )
}

const StepsEditor: React.FC<TrendEditorProps> = ({setData}) => {
  const {t} = useTranslation()
  return (
    <BaseTrendEditor
      setData={setData}
      fields={[
        {
          label: t('Steps'),
          name: 'Steps',
          inputType: 'number',
        },
      ]}
    />
  )
}

const ActiveMinutesEditor: React.FC<TrendEditorProps> = ({setData}) => {
  const {t} = useTranslation()
  return (
    <BaseTrendEditor
      setData={setData}
      fields={[
        {
          label: t('Active Minutes'),
          name: 'Active Minutes',
          inputType: 'number',
        },
      ]}
    />
  )
}

const QualitySleepSecondsEditor: React.FC<TrendEditorProps> = ({setData}) => {
  const {t} = useTranslation()
  return (
    <BaseTrendEditor
      setData={setData}
      fields={[
        {
          label: t('Sleep quality'),
          name: 'Sleep quality',
          inputType: 'number',
        },
      ]}
    />
  )
}

const AlcoholScoreEditor: React.FC<TrendEditorProps> = ({setData}) => {
  const {t} = useTranslation()
  return (
    <BaseTrendEditor
      setData={setData}
      fields={[
        {
          label: t('Alcohol Units (last 7 days)'),
          name: 'Alcohol Units (last 7 days)',
          inputType: 'number',
        },
      ]}
    />
  )
}

const TobaccoScoreEditor: React.FC<TrendEditorProps> = ({setData}) => {
  const {t} = useTranslation()
  return (
    <BaseTrendEditor
      setData={setData}
      fields={[
        {
          label: t('Tobacco (last 7 days)'),
          name: 'Tobacco (last 7 days)',
          inputType: 'number',
        },
      ]}
    />
  )
}

const Vo2MaxEditor: React.FC<TrendEditorProps> = ({setData}) => {
  const {t} = useTranslation()
  return (
    <BaseTrendEditor
      setData={setData}
      fields={[
        {
          label: t('VO2 Max'),
          name: 'VO2 Max',
          inputType: 'number',
        },
      ]}
    />
  )
}

const PeakFlowEditor: React.FC<TrendEditorProps> = ({setData}) => {
  const {t} = useTranslation()
  return (
    <BaseTrendEditor
      setData={setData}
      fields={[
        {
          label: t('Peak Flow'),
          name: 'Peak Flow',
          inputType: 'number',
        },
      ]}
    />
  )
}

export const editorMap: Record<
  EditableTrendTypes,
  React.FC<TrendEditorProps>
> = {
  [HealthDataType.BLOOD_PRESSURE]: BloodPressureEditor,
  [HealthDataType.RESTING_HEART_RATE]: RestingHeartRateEditor,
  [HealthDataType.WEIGHT_KG]: WeightEditor,
  [HealthDataType.HEIGHT_CM]: HeightEditor,
  [HealthDataType.WAIST_CIRCUMFERENCE_CM]: WaistCircumferenceEditor,
  [HealthDataType.BODY_FAT_PERCENTAGE]: BodyFatPercentageEditor,
  [HealthDataType.CHOLESTEROL_HDL_RATIO]: CholesterolEditor,
  [HealthDataType.WATER_CONSUMPTION_ML]: WaterConsumptionEditor,
  [HealthDataType.STEPS]: StepsEditor,
  [HealthDataType.ACTIVE_MINUTES]: ActiveMinutesEditor,
  [HealthDataType.QUALITY_SLEEP_SECONDS]: QualitySleepSecondsEditor,
  [HealthDataType.ALCOHOL_SCORE]: AlcoholScoreEditor,
  [HealthDataType.TOBACCO_SCORE]: TobaccoScoreEditor,
  [HealthDataType.VO2_MAX]: Vo2MaxEditor,
  [HealthDataType.PEAK_FLOW]: PeakFlowEditor,
}
