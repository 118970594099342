import styled from 'styled-components'

import {Icon} from '../icon/Icon'

type MultiSelectWrapperProps = {
  width?: number
}

type StyledMultiSelectProps = {
  showOptions: boolean
}

export const MultiSelectWrapper = styled.div<MultiSelectWrapperProps>`
  width: ${({width}) => (width == null ? '100%' : `${width}px`)};
  display: inline-flex;
`

export const MultiSelectContainer = styled.div<StyledMultiSelectProps>`
  width: 100%;
  position: absolute;
  overflow: hidden;
  background-color: ${({theme}) => theme.colors.white};
  border: 1px solid ${({theme}) => theme.colors.inputBorder};
  border-radius: ${({theme}) => theme.borderRadius.xs};
  ${({showOptions, theme}) =>
    showOptions && `box-shadow: ${theme.boxShadow.select}`};
  ${({showOptions}) => `z-index: ${showOptions ? 100 : 10}`};
`

export const MultiSelectInputContainer = styled.div`
  cursor: pointer;
  height: ${({theme}) => theme.height.input.normal};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({theme}) => theme.spacing.xs} ${({theme}) => theme.spacing.sm}
    ${({theme}) => theme.spacing.xs} ${({theme}) => theme.spacing.md};
`

export const MultiSelectIcon = styled(Icon)<StyledMultiSelectProps>`
  margin-left: ${({theme}) => theme.spacing.sm};
  transform: ${({showOptions}) =>
    showOptions ? 'rotate(180deg)' : 'rotate(0)'};
`

export const MultiSelectOptions = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${({theme}) => theme.colors.white};
  padding: ${({theme}) => theme.spacing.sm};
  row-gap: ${({theme}) => theme.spacing.xs};
`
