import React from 'react'
import {useTranslation} from 'react-i18next'

import {TableRow, TableCell} from './Table.styled'
import {Icon} from '../icon/Icon'

import {Typography} from '~/components/general/typography/Typography'
import {theme} from '~/styles/theme'
import {manualRecordIconName} from '~/icons'

export type SimpleTableRowProps = {
  columns: string[]
  hasParsedData?: boolean
  onRowClick?: () => void
  actionComponent?: React.ReactNode
}

export const SimpleTableRow: React.FC<SimpleTableRowProps> = ({
  columns,
  hasParsedData,
  onRowClick,
  actionComponent,
}) => {
  const {t} = useTranslation()

  return (
    <TableRow onClick={onRowClick}>
      {columns.map((text, index) => (
        <TableCell title={text} key={`${index}${text}`}>
          <Typography
            ellipsis
            color={theme.colors.grey.grey600}
            weight="normal">
            {text}
          </Typography>
        </TableCell>
      ))}
      {hasParsedData != null && (
        <TableCell
          title={hasParsedData ? t('Parsed from pdf') : t('Manually added')}>
          <Icon name={hasParsedData ? 'Pdf' : manualRecordIconName} />
        </TableCell>
      )}
      {actionComponent && (
        <TableCell
          onClick={(e) => e.stopPropagation()}
          style={{width: '100px'}}>
          {actionComponent}
        </TableCell>
      )}
    </TableRow>
  )
}
