import * as React from 'react'

import {IconProps} from '.'

function Share({color, size, isFilled}: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill={isFilled ? color : 'none'}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11 9.5L14 6.5L11 3.5"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 13.5H2.5C2.36739 13.5 2.24021 13.4473 2.14645 13.3536C2.05268 13.2598 2 13.1326 2 13V5.5"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.6891 11C5.02211 9.71201 5.77354 8.57116 6.82535 7.75665C7.87716 6.94213 9.16975 6.5001 10.5001 6.5H14.0001"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default Share
