import {
  AppointmentCancellationReason,
  AppointmentForPractitioner,
  Employment,
  EmploymentStatus,
  FacilityFeature,
  Practitioner,
} from '../models'
import {AssertTypeFn} from './general'
import {
  assertArray,
  assertEnum,
  assertEnumOrNull,
  assertObject,
  assertObjectOrUndefined,
  assertString,
  assertStringOrNull,
} from '../utils'
import {facilityNormaliser} from './facilities'
import {
  appointmentNotesNormaliser,
  appointmentSummaryNormaliser,
  consultationNormaliser,
} from './appointments'
import {patientShortNormaliser} from './patient'

export const employmentNormaliser: AssertTypeFn<Employment> = (obj) => ({
  facilityId: assertString(obj.facilityId),
  facilityName: assertString(obj.facilityName),
  id: assertString(obj.id),
  practitionerId: assertString(obj.practitionerId),
  specialization: assertString(obj.specialization),
  departments: assertArray(obj.departments, assertString),
  status: assertEnum(obj.status, EmploymentStatus),
  features: assertArray(obj.features, (val) =>
    assertEnum(val, FacilityFeature),
  ),
})

export const practitionerNormaliser: AssertTypeFn<Practitioner> = (obj) => ({
  email: assertString(obj.email),
  employments: assertArray(obj.employments, employmentNormaliser),
  firstName: assertString(obj.firstName),
  id: assertString(obj.id),
  phoneNumber: assertString(obj.phoneNumber),
  secondName: assertString(obj.secondName),
  title: assertStringOrNull(obj.title),
  defaultPatientPage: assertStringOrNull(obj.defaultPatientPage),
})

export const appointmentForPractitionerNormaliser: AssertTypeFn<
  AppointmentForPractitioner
> = (obj) => ({
  id: assertString(obj.id),
  startTime: assertString(obj.startTime),
  facility: assertObjectOrUndefined(obj.facility, facilityNormaliser),
  department: assertStringOrNull(obj.department),
  consultations: assertArray(obj.consultations, consultationNormaliser),
  summary: assertObjectOrUndefined(obj.summary, appointmentSummaryNormaliser),
  notes: appointmentNotesNormaliser(obj.notes),
  cancellationReason: assertEnumOrNull(
    obj.cancellationReason,
    AppointmentCancellationReason,
  ),
  cancellationNote: assertStringOrNull(obj.cancellationNote),
  patient: assertObject(obj.patient, patientShortNormaliser),
})

export const appointmentsForPractitionerNormaliser: AssertTypeFn<
  AppointmentForPractitioner[]
> = (obj) => assertArray(obj, appointmentForPractitionerNormaliser)
