import {TrendValue} from '@common/models'
import React from 'react'

import {Icon, IconName} from '../icon/Icon'
import {GradeIndicatorColor} from './GradeIndicator'
import {TrendIndicatorContainer} from './TrendIndicator.styled'

import {theme} from '~/styles/theme'

type TrendIndicatorProps = {
  circled?: boolean
  trend?: TrendValue | null
  colorVariant?: GradeIndicatorColor
}

const getTrendIconName: (trend?: TrendValue | null) => IconName = (trend) => {
  if (!trend) {
    return 'Minus'
  }
  return trend === TrendValue.UP ? 'TrendUp' : 'TrendDown'
}

export const TrendIndicator: React.FC<TrendIndicatorProps> = ({
  circled,
  colorVariant = 'grey',
  trend,
}) => (
  <TrendIndicatorContainer>
    <Icon
      size={trend ? 'sm' : 'xs'}
      name={getTrendIconName(trend)}
      circled={circled ? 'xs' : undefined}
      color={theme.colors.indicator.body[colorVariant]}
      circledBgd={theme.colors.indicator.border[colorVariant]}
    />
  </TrendIndicatorContainer>
)
