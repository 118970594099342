import React from 'react'

import {StyledCheckbox, CheckboxInput} from './Checkbox.styled'
import {Typography} from '../typography/Typography'
import {Icon} from '../icon/Icon'

import {theme} from '~/styles/theme'

type Props = {
  label: string
  onChange?: () => void
  checked?: boolean
  name?: string
}

export const Checkbox: React.FC<Props> = ({label, onChange, checked, name}) => (
  <StyledCheckbox htmlFor={name} disabled={!onChange}>
    <CheckboxInput
      type="checkbox"
      onChange={onChange}
      checked={checked}
      name={name}
      id={name}
      readOnly={!onChange}
    />
    <Icon
      color={checked ? theme.colors.primary.main : theme.colors.grey.grey300}
      name={checked ? 'CheckboxOn' : 'CheckboxOff'}
    />
    <Typography
      color={checked ? theme.colors.primary.main : theme.colors.grey.main}>
      {label}
    </Typography>
  </StyledCheckbox>
)
