import React, {ReactNode, useState} from 'react'

import {
  StyledSelect,
  SelectSelector,
  SelectInputWrapper,
  SelectSelectionSearch,
  SelectInput,
  SelectSelectionItem,
  SelectIcon,
  Options,
  StyledOption,
  SelectDropDownIconWrapper,
  SelectorWrapper,
} from './Select.styled'
import {Icon} from '../icon/Icon'

import {theme} from '~/styles/theme'
import {useOutsideClickClose} from '~/utils'

export type InputProps = {
  children: React.ReactNode
  className?: string
  disabled?: boolean
  size?: 'large' | 'normal' | 'small'
  placeholder?: string
  value?: string
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void
  noBorder?: boolean
  selectedOption?: ReactNode | ReactNode[]
  showOutsideIcon?: boolean
  noIcon?: boolean
  search?: boolean
  width?: number
}

type OptionProps = {
  children: React.ReactNode
  onClick?: () => void
  size?: 'large' | 'normal' | 'small'
}

export const Option: React.FC<OptionProps> = ({
  onClick,
  size = 'normal',
  children,
}) => (
  <StyledOption size={size} onClick={onClick}>
    {children}
  </StyledOption>
)

export const Select: React.FC<InputProps> = ({
  className,
  children,
  disabled,
  size = 'normal',
  placeholder,
  value,
  onChange,
  onBlur,
  onFocus,
  noBorder,
  selectedOption,
  showOutsideIcon,
  noIcon,
  search,
  width,
}) => {
  const [showOptions, setShowOptions] = useState<boolean>(false)

  const ref = useOutsideClickClose(() => setShowOptions(false))

  return (
    <StyledSelect
      className={className}
      sizeVariant={size}
      disabled={disabled}
      width={width}
      onClick={disabled ? undefined : () => setShowOptions(!showOptions)}
      ref={ref}>
      <SelectorWrapper>
        <SelectSelector
          noBorder={noBorder}
          showOptions={!!children && showOptions}>
          <SelectInputWrapper sizeVariant={size} disabled={disabled}>
            <SelectSelectionSearch sizeVariant={size} search={search}>
              <SelectInput
                showInput={!selectedOption}
                placeholder={placeholder}
                disabled={disabled}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                onFocus={onFocus}
                readOnly={!search}
                sizeVariant={size}
              />
            </SelectSelectionSearch>
            <SelectSelectionItem search={search}>
              {selectedOption}
            </SelectSelectionItem>
            {!noIcon && (
              <SelectIcon
                search={search}
                showOptions={!!children && showOptions && !search}
                name={search ? 'Search' : 'ArrowDown'}
                color={theme.colors.grey.grey500}
              />
            )}
          </SelectInputWrapper>
          {!!children && showOptions && <Options>{children}</Options>}
        </SelectSelector>
      </SelectorWrapper>
      {showOutsideIcon && (
        <SelectDropDownIconWrapper>
          <Icon
            flip={showOptions}
            name="ChevronDownLg"
            circled="lg"
            color={theme.colors.grey.grey300}
            size="md"
          />
        </SelectDropDownIconWrapper>
      )}
    </StyledSelect>
  )
}
