import {useTranslation} from 'react-i18next'
import React, {useContext, useMemo} from 'react'
import {
  EnumType,
  formatDate,
  MISSING_ID,
  useEnumTranslations,
  useTranslateBooleanValue,
} from '@common/utils'
import {BasePermissionCategory, MaritalStatus} from '@common/models'
import {DateTime} from 'luxon'

import {StyledAccordion} from './PersonalInformationPage.styled'

import {PermissionCheck} from '~/components/patients/common/requestData/PermissionCheck'
import {useGetPatientPersonalInformation} from '~/api'
import {PatientsContext} from '~/components/patients/PatientsContext'
import {Loading} from '~/components/general/loading/Loading'
import {DataAccordionContent} from '~/components/general/accordion/DataAccordionContent'
import {Typography} from '~/components/general/typography/Typography'

const PersonalInformationPageContent: React.FC = () => {
  const {t} = useTranslation()
  const {patient} = useContext(PatientsContext)
  const {translateEnumValue} = useEnumTranslations()
  const formatBooleanValue = useTranslateBooleanValue()
  const {data, isInitialLoading} = useGetPatientPersonalInformation({
    patientId: patient?.patientId ?? MISSING_ID,
  })

  const accordionData = useMemo(() => {
    if (!data) {
      return []
    }

    const hasSpouse =
      data.personalNetwork?.maritalStatus !== MaritalStatus.SINGLE

    const childrenDateOfBirths =
      data.personalNetwork?.children
        ?.map((date) => formatDate(DateTime.DATE_SHORT, date))
        .join(', ') || '-'

    return [
      {
        title: t('Personal network'),
        data: [
          {
            title: t('Marital status'),
            value:
              translateEnumValue(
                EnumType.MaritalStatus,
                data.personalNetwork?.maritalStatus,
              ) || '-',
          },
          ...(hasSpouse
            ? [
                {
                  title: t('Spouse name'),
                  value: data.personalNetwork?.spouseName || '-',
                },
                {
                  title: t('Years of marriage'),
                  value:
                    data.personalNetwork?.yearsOfMarriage?.toLocaleString() ??
                    '-',
                },
              ]
            : []),
          {
            title: t('Are you a carer?'),
            value: formatBooleanValue(!!data.personalNetwork?.caresFor?.length),
          },
          {
            title: t('Who do you care for?'),
            value:
              translateEnumValue(
                EnumType.CaresForOption,
                data.personalNetwork?.caresFor,
              ) || '-',
          },
        ],
      },
      {
        title: t('Children'),
        data: [
          {
            title: t('Number of children'),
            value:
              data.personalNetwork?.children?.length.toLocaleString() ?? '-',
          },
          {
            title: t("Child's date of birth"),
            value: childrenDateOfBirths,
          },
        ],
      },
      {
        title: t('Living situation'),
        data: [
          {
            title: t('Type of home'),
            value: translateEnumValue(
              EnumType.HomeType,
              data.livingSituation?.homeType,
            ),
          },
          {
            title: t('Who do you live with?'),
            value: translateEnumValue(
              EnumType.HomeSharedWithOption,
              data.livingSituation?.homeSharedWith,
            ),
          },
        ],
      },
    ]
  }, [data, formatBooleanValue, translateEnumValue, t])

  if (isInitialLoading) {
    return <Loading />
  }

  if (!accordionData.length) {
    return <Typography>{t('No data to display')}</Typography>
  }

  return (
    <>
      {accordionData.map((accordionItem) => (
        <StyledAccordion
          title={accordionItem.title ?? ''}
          key={accordionItem.title}>
          <DataAccordionContent data={accordionItem.data} />
        </StyledAccordion>
      ))}
    </>
  )
}

export const PersonalInformationPage: React.FC = () => (
  <PermissionCheck permission={BasePermissionCategory.PERSONAL_INFORMATION}>
    <PersonalInformationPageContent />
  </PermissionCheck>
)
