export enum SumsubStatus {
  Ready = 'Ready',
  Failed = 'Failed',
  Initial = 'Initial',
  Incomplete = 'Incomplete',
  Pending = 'Pending',
  TemporarilyDeclined = 'TemporarilyDeclined',
  FinallyRejected = 'FinallyRejected',
  Approved = 'Approved',
  ActionCompleted = 'ActionCompleted',
}

export {PatientAccountStatusDto as PatientAccountStatus} from '@generated/models'

export type {SumsubAccessTokenDto as SumsubAccessToken} from '@generated/models'

export type SumsubStatusChange = {
  newStatus: SumsubStatus
  prevStatus: SumsubStatus
}
