import React from 'react'

import {StyledDashboardInnerCardTableCell} from '../../../GeneralDataCard.styled'
import {ComponentValue} from '../fieldConfig'

import {Typography} from '~/components/general/typography/Typography'
import {Select, Option} from '~/components/general/select/Select'
import {Container} from '~/components/general/container/Container'

export const SelectDataInput: React.FC<{
  title: string
  name: string
  values: string[]
  initialValue: string | undefined
  onUpdate: (newData: ComponentValue[]) => void
}> = ({title, name, values, initialValue, onUpdate}) => {
  const [selectedValue, setSelectedValue] = React.useState<string>(
    initialValue || '',
  )

  const handleValueChange = (value: string) => {
    setSelectedValue(value)
    onUpdate([{name, value}])
  }

  return (
    <StyledDashboardInnerCardTableCell>
      <Container direction="column">
        <Typography weight="medium" size="sm">
          {title}
        </Typography>
        <Select placeholder="Select" value={selectedValue} size="small">
          {values.map((value) => (
            <Option onClick={() => handleValueChange(value)} key={value}>
              <Typography weight="medium">{value}</Typography>
            </Option>
          ))}
        </Select>
      </Container>
    </StyledDashboardInnerCardTableCell>
  )
}
