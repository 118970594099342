import {Practitioner, Employment, FacilityFeature} from '@common/models'
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import {useIsAuthenticated} from '@azure/msal-react'
import {useIdleTimer} from 'react-idle-timer'

import {LoadingPage} from '../website/LoadingPage'

import {useGetCurrentPractitioner} from '~/api'
import {AuthContext} from '~/auth/AuthContext'

const FIVE_MINUTES = 1000 * 60 * 5

type PractitionerContextData = {
  selectedEmployment?: Employment
  practitioner?: Practitioner
}

type PractitionerContextProps = {
  setSelectedEmployment: (employment?: Employment) => void
} & PractitionerContextData

type PractitionerContextProviderProps = {
  children: React.ReactNode
}

export const PractitionerContext = createContext({} as PractitionerContextProps)

export const PractitionerContextProvider: React.FC<
  PractitionerContextProviderProps
> = ({children}) => {
  const {data: practitioner} = useGetCurrentPractitioner()
  const [selectedEmployment, setSelectedEmployment] = useState<Employment>()
  const {signOut} = useContext(AuthContext)
  const isLoggedIn = useIsAuthenticated()

  useEffect(() => {
    if (practitioner?.employments.length && !selectedEmployment) {
      setSelectedEmployment(practitioner.employments[0])
    }
  }, [practitioner, selectedEmployment])

  // Load
  useEffect(() => {
    try {
      const storedData = window.localStorage.getItem('practitionerContextData')
      const parsedStoredData = storedData ? JSON.parse(storedData) : undefined

      if (
        parsedStoredData &&
        parsedStoredData.practitionerId === practitioner?.id
      ) {
        // There's an old version of the stored data that has the full employment object
        const employmentId =
          parsedStoredData.selectedEmploymentId ??
          parsedStoredData.selectedEmployment.id
        const employment = practitioner?.employments.find(
          (emp) => emp.id === employmentId,
        )

        setSelectedEmployment(employment)
      }
    } catch (error) {
      // [CUSH-655] FIXME: Add error logging
      // eslint-disable-next-line no-console
      console.warn('Could not load practitioner context', error)
    }
  }, [practitioner?.id, practitioner?.employments])

  useEffect(() => {
    if (selectedEmployment && practitioner?.id) {
      window.localStorage.setItem(
        'practitionerContextData',
        JSON.stringify({
          selectedEmploymentId: selectedEmployment.id,
          practitionerId: practitioner.id,
        }),
      )
    }
  }, [selectedEmployment, practitioner?.id])

  const contextValue = useMemo(
    () => ({practitioner, selectedEmployment, setSelectedEmployment}),
    [practitioner, selectedEmployment, setSelectedEmployment],
  )

  useIdleTimer({
    timeout: FIVE_MINUTES,
    onIdle: () =>
      isLoggedIn &&
      selectedEmployment?.features.includes(
        FacilityFeature.SHORT_WEB_TIMEOUT,
      ) &&
      signOut(),
  })

  if (!selectedEmployment) {
    return <LoadingPage />
  }

  return (
    <PractitionerContext.Provider value={contextValue}>
      {children}
    </PractitionerContext.Provider>
  )
}
