import React from 'react'
import styled from 'styled-components'

import {Loading} from '../general/loading/Loading'

const LoadingContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const LoadingPage: React.FC = () => (
  <LoadingContainer>
    <Loading />
  </LoadingContainer>
)
