/* tslint:disable */
/* eslint-disable */
/**
 * Cushla API
 * REST API for Cushla
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */

export enum QuestionType {
  CHOICE = 'CHOICE',
  MULTI_CHOICE = 'MULTI_CHOICE',
  NUMBER = 'NUMBER',
  TEXT = 'TEXT',
  SLIDER = 'SLIDER',
  WEIGHT = 'WEIGHT',
  HEIGHT = 'HEIGHT',
  DATE = 'DATE',
  DATE_TIME = 'DATE_TIME',
  TIME = 'TIME',
}
