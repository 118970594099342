import styled from 'styled-components'

export const StyledBanner = styled.div`
  position: relative;
  min-height: 232px;
  background-color: ${({theme}) => theme.colors.grey.grey50};
  display: flex;
  border-radius: ${({theme}) => theme.borderRadius.md};
  overflow: hidden;
`

export const BannerImage = styled.div`
  height: 232px;
  width: 232px;
  padding-right: ${({theme}) => theme.spacing.md};
`

export const BannerContent = styled.div`
  max-width: 434px;
  margin: ${({theme}) => theme.spacing.xl};
  margin-right: 80px;
`

export const BannerTitle = styled.h3`
  margin-bottom: ${({theme}) => theme.spacing.md};
`

export const BannerClose = styled.div`
  position: absolute;
  top: ${({theme}) => theme.spacing.lg};
  right: ${({theme}) => theme.spacing.lg};
  cursor: pointer;
`
