import React from 'react'
import {useTranslation} from 'react-i18next'

import {TableHeaderSort} from './PatientsTable.styled'

import {Typography} from '~/components/general/typography/Typography'
import {Icon} from '~/components/general/icon/Icon'
import {
  TableHeaderCell,
  TableHeaderCellWrap,
} from '~/components/general/table/Table.styled'
import {theme} from '~/styles/theme'

/** Currently unsupported feature */
const PatientsTableHeaderSortButton: React.FC = () => (
  <TableHeaderSort>
    <Icon name="TriangleUp" color={theme.colors.grey.grey400} size="xxs" />
    <Icon name="TriangleDown" color={theme.colors.grey.grey400} size="xxs" />
  </TableHeaderSort>
)

// TODO: CUSH-374 figure out filters
export const PatientsTableHeader: React.FC = () => {
  const {t} = useTranslation()

  return (
    <thead>
      <tr>
        <TableHeaderCell columnWidthPercent={20}>
          <TableHeaderCellWrap first>
            <Typography color={theme.colors.grey.grey600}>
              {t('Patient name')}
            </Typography>
            <PatientsTableHeaderSortButton />
          </TableHeaderCellWrap>
        </TableHeaderCell>
        <TableHeaderCell columnWidthPercent={20}>
          <TableHeaderCellWrap>
            <Typography color={theme.colors.grey.grey600}>
              {t('Date of birth')}
            </Typography>
            <PatientsTableHeaderSortButton />
          </TableHeaderCellWrap>
        </TableHeaderCell>
        <TableHeaderCell columnWidthPercent={20}>
          <TableHeaderCellWrap>
            <Typography color={theme.colors.grey.grey600}>
              {t('Address')}
            </Typography>
            <PatientsTableHeaderSortButton />
          </TableHeaderCellWrap>
        </TableHeaderCell>
        <TableHeaderCell columnWidthPercent={20}>
          <TableHeaderCellWrap>
            <Typography color={theme.colors.grey.grey600}>
              {t('Last appointment')}
            </Typography>
            <PatientsTableHeaderSortButton />
          </TableHeaderCellWrap>
        </TableHeaderCell>
        <TableHeaderCell columnWidthPercent={10}>
          <TableHeaderCellWrap last />
        </TableHeaderCell>
      </tr>
    </thead>
  )
}
