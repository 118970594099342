import * as React from 'react'

import {IconProps} from '.'

function Diet({color, size, isFilled}: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill={isFilled ? color : 'none'}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.6507 16.9197C10.8056 17.3059 9.8871 17.5049 8.95796 17.5033C5.35394 17.5005 2.433 14.5796 2.43024 10.9756C2.43024 8.1244 3.73912 6.07355 6.34854 6.07355V6.08188C7.22685 6.16101 8.0987 6.30028 8.95796 6.49872C9.81721 6.30028 10.6891 6.16101 11.5674 6.08188V6.07355C14.1768 6.07355 15.4773 8.10773 15.4857 10.9422"
        stroke={color}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="14.5852"
        cy="14.5853"
        r="3.75156"
        stroke={color}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.8499 13.9543L14.2701 15.5383L13.3205 14.5854"
        stroke={color}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.702 5.83173H9.16638V4.29609C9.16616 3.59777 9.44347 2.92799 9.93726 2.4342C10.431 1.94042 11.1008 1.66311 11.7991 1.66333H13.3348V3.19897C13.3343 4.65281 12.1559 5.83127 10.702 5.83173V5.83173Z"
        stroke={color}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.16629 5.83173V5.41489C9.16629 4.26382 8.23317 3.33069 7.08209 3.33069H6.66525"
        stroke={color}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default Diet
