import React from 'react'

import {TablePagination, TablePaginationProps} from './TablePagination'
import {StyledTable, TableCellLoader} from './Table.styled'

import {Loading} from '~/components/general/loading/Loading'

type TableProps = {
  rows: React.ReactNode[]
  header: React.ReactNode
  isLoading?: boolean
  loaderColSpan?: number
} & TablePaginationProps

export const Table: React.FC<TableProps> = ({
  rows,
  onPageClick,
  header,
  hideUnusedPagination,
  isLoading,
  loaderColSpan = 0,
  paginationData,
}) => (
  <>
    <StyledTable>
      {header}
      <tbody>
        {isLoading ? (
          <tr>
            <TableCellLoader
              colSpan={loaderColSpan}
              rowSpan={rows?.length ?? 3}>
              <Loading />
            </TableCellLoader>
          </tr>
        ) : (
          rows
        )}
      </tbody>
    </StyledTable>
    <TablePagination
      paginationData={paginationData}
      onPageClick={onPageClick}
      hideUnusedPagination={hideUnusedPagination}
    />
  </>
)
