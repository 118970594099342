/* tslint:disable */
/* eslint-disable */
/**
 * Cushla API
 * REST API for Cushla
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * The way the step was completed.
 * @export
 * @enum {string}
 */

export enum JourneySetupCompletionDto {
  COMPLETED_BY_USER = 'COMPLETED_BY_USER',
  COMPLETED_BY_SYSTEM = 'COMPLETED_BY_SYSTEM',
  SKIPPED_BY_USER = 'SKIPPED_BY_USER',
  SKIPPED_BY_SYSTEM = 'SKIPPED_BY_SYSTEM',
}
