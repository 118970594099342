import React, {useContext, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {matchPath, useLocation} from 'react-router-dom'
import {Sex} from '@common/models'

import {Loading} from '../general/loading/Loading'
import {
  InnerSideNav,
  InnerSideNavItem,
} from '../layout/sideNavs/innerSideNav/InnerSideNav'
import {PatientsContext} from './PatientsContext'
import {useFeatureFlags} from '../general/FeatureFlagContext'

export type PatientsSidebarNavData = {
  id: string
  label: string
  parentId?: string
  patientsCount?: number
  redBadge?: boolean
}

type PatientsSidebarNavProps = {
  fullPath: string
  isLoading?: boolean
  onClick: (id: string) => void
}

export const PatientsSidebarNav: React.FC<PatientsSidebarNavProps> = ({
  fullPath,
  isLoading,
  onClick,
}) => {
  const {t} = useTranslation()
  const location = useLocation()
  const {patient} = useContext(PatientsContext)
  const {preventiveMeasuresEnabled} = useFeatureFlags()

  const navDataList: PatientsSidebarNavData[] = useMemo(
    () => [
      {
        id: 'dashboard',
        label: t('Patient dashboard'),
      },
      {
        id: 'timeline',
        label: t('Health timeline'),
      },
      {
        id: 'trends',
        label: t('Health trends'),
      },
      {
        id: 'medical/conditions',
        parentId: 'medical',
        label: t('Medical conditions'),
      },
      {
        id: 'medical/allergies',
        parentId: 'medical',
        label: t('Allergies'),
      },
      {
        id: 'medical/medications',
        parentId: 'medical',
        label: t('Medications'),
      },
      {
        id: 'medical/vaccinations',
        parentId: 'medical',
        label: t('Vaccinations'),
      },
      {
        id: 'medical/family-history',
        parentId: 'medical',
        label: t('Family history'),
      },
      {
        id: 'medical/surgeries',
        parentId: 'medical',
        label: t('Surgeries & Procedures'),
      },
      {
        id: 'medical/tests',
        parentId: 'medical',
        label: t('Tests & Imaging'),
      },
      {
        id: 'medical/cholesterol',
        parentId: 'medical',
        label: t('Cholesterol'),
      },
      ...(patient?.gender === Sex.MALE
        ? []
        : [
            {
              id: 'medical/obstetrics',
              parentId: 'medical',
              label: t('Obstetrics'),
            },
          ]),
      {
        id: 'lifestyle',
        label: t('Lifestyle information'),
      },
      ...(preventiveMeasuresEnabled
        ? [
            {
              id: 'preventive',
              label: t('Preventive Activities'),
            },
          ]
        : []),
      {
        id: 'personal',
        label: t('Personal information'),
      },
      {
        id: 'profile',
        label: t('Personal details'),
      },
      {
        id: 'milestones',
        label: t('Life milestones'),
      },
      {
        id: 'questionnaires',
        label: t('Questionnaires'),
      },
      {
        id: 'materials',
        label: t('Shared materials'),
      },
      {
        id: 'physitrack',
        label: t('Physitrack programs'),
      },
    ],
    [patient?.gender, t, preventiveMeasuresEnabled],
  )

  const activeRouteId = useMemo(
    () =>
      navDataList
        .map(({id}) => matchPath(`${fullPath}${id}`, location.pathname) && id)
        .find((result) => !!result) || navDataList[0].id,
    [navDataList, fullPath, location.pathname],
  )
  const activeSideNavDataList = useMemo(() => {
    const activeItem = navDataList.find((item) => item.id === activeRouteId)

    return navDataList.filter(
      (item) =>
        !item.parentId ||
        item.parentId === activeItem?.parentId ||
        item.parentId === activeItem?.id,
    )
  }, [navDataList, activeRouteId])

  return (
    <InnerSideNav>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {activeSideNavDataList.map((data) => (
            <InnerSideNavItem
              active={activeRouteId === data.id}
              isChild={!!data.parentId}
              badge={data.patientsCount}
              redBadge={data.redBadge}
              key={data.id}
              onClick={() => onClick(data.id)}>
              {data.label}
            </InnerSideNavItem>
          ))}
        </>
      )}
    </InnerSideNav>
  )
}
