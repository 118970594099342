import styled, {keyframes} from 'styled-components'

const load7 = keyframes`
  0%,
  80%,
  100% {
    box-shadow: 0 8px 0 -4px;
  }
  40% {
    box-shadow: 0 8px 0 0;
  }
`

export const StyledLoading = styled.div`
  color: ${({theme}) => theme.colors.primary.main};
  margin: 0 auto ${({theme}) => theme.spacing.md} auto;
  position: relative;
  transform: translateZ(0);
  animation-delay: -0.16s;
  border-radius: 50%;
  width: ${({theme}) => theme.spacing.xs};
  height: ${({theme}) => theme.spacing.xs};
  animation-fill-mode: both;
  animation: ${load7} 1.8s infinite ease-in-out;

  &:after,
  &:before {
    content: '';
    position: absolute;
    top: 0;
    border-radius: 50%;
    width: ${({theme}) => theme.spacing.xs};
    height: ${({theme}) => theme.spacing.xs};
    animation-fill-mode: both;
    animation: ${load7} 1.8s infinite ease-in-out;
  }

  &:before {
    left: -${({theme}) => theme.spacing.sm};
    animation-delay: -0.32s;
  }

  &:after {
    left: ${({theme}) => theme.spacing.sm};
  }
`
