import React from 'react'

import {StyledLoading} from './Loading.styled'

type LoadingProps = {
  className?: string
}

export const Loading: React.FC<LoadingProps> = ({className}) => (
  <StyledLoading className={className} />
)
