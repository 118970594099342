import styled from 'styled-components'

import {Material} from '~/components/educationalMaterials/materials/Material'
import {Typography} from '~/components/general/typography/Typography'

export const MaterialsSideBarContainer = styled.div`
  position: relative;
  background-color: #fafbfd;
  width: 432px;
  border-left: 1px solid ${({theme}) => theme.colors.grey.grey40};
  flex-shrink: 0;
`

export const MaterialsTopBar = styled.div`
  height: ${({theme}) => theme.height.topBar};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 ${({theme}) => theme.spacing.xxl};
  border-bottom: 1px solid ${({theme}) => theme.colors.grey.grey40};
`

export const MaterialsSideBarContent = styled.div`
  position: absolute;
  top: ${({theme}) => theme.height.topBar};
  left: 0;
  right: 0;
  height: ${({theme}) => `calc(100vh - ${theme.height.topBar})`};
  padding: ${({theme}) => theme.spacing.xxl};
  overflow-y: auto;
`

export const StyledSection = styled.div`
  margin-bottom: ${({theme}) => theme.spacing.lg};
`

export const SectionTitle = styled(Typography)`
  padding-bottom: ${({theme}) => theme.spacing.xs};
  margin-bottom: ${({theme}) => theme.spacing.md};
  border-bottom: 1px solid ${({theme}) => theme.colors.grey.grey40};
`

export const SelectedMaterial = styled(Material)`
  width: 100%;
  padding: 0;
`
