import React, {useContext, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {AppointmentForPractitioner} from '@common/models'
import {useNavigate} from 'react-router-dom'

import {Layout} from '../layout/Layout'
import {PractitionerContext} from '../profile/PractitionerContext'
import {PractitionerAppointmentsTable} from './PractitionerAppointmentsTable'
import {Container} from '../general/container/Container'
import {DatePicker} from '../general/datePicker/DatePicker'
import {Button} from '../general/button/Button'
import {Icon} from '../general/icon/Icon'
import AddAppointmentModal from './screening/components/AddAppointmentModal'
import FilteredSelect from '../general/select/FilteredSelect'

import {useGetAppointmentsForPractitioner} from '~/api'
import {theme} from '~/styles/theme'

export const PractitionerAppointmentsPage: React.FC = () => {
  const navigate = useNavigate()
  const {t} = useTranslation()
  const {selectedEmployment} = useContext(PractitionerContext)
  const [openModal, setOpenModal] = useState(false)

  const [employmentFilter, setEmploymentFilter] = useState<string | undefined>()
  const [dateFilter, setDateFilter] = useState(new Date())

  const {data, isLoading} = useGetAppointmentsForPractitioner({
    facilityId: selectedEmployment?.facilityId,
    date: dateFilter.toISOString().split('T')[0],
    department: employmentFilter,
  })

  const navigateToScreeningPage = (appointment: AppointmentForPractitioner) => {
    navigate(`${appointment.id}/${appointment.patient.id}`)
  }

  const handleFilterSelected = (value: string | undefined) => {
    setEmploymentFilter(value)
  }

  return (
    <>
      <Layout
        navMenu={
          <Container flex align="center" spacing={1}>
            {selectedEmployment?.departments?.length ? (
              <FilteredSelect
                width={600}
                size="small"
                placeholder={t('Select Location')}
                options={selectedEmployment?.departments.map((department) => ({
                  label: department,
                  value: department,
                }))}
                onChange={handleFilterSelected}
              />
            ) : null}
            <DatePicker
              selected={dateFilter}
              onChange={(newDate) => setDateFilter(newDate ?? new Date())}
              fixedHeight
            />
            <Button
              size="sm"
              startIcon={
                <Icon name="Plus" color={theme.colors.white} size="sm" />
              }
              onClick={() => setOpenModal(true)}>
              {t('Add Appointment')}
            </Button>
          </Container>
        }>
        <PractitionerAppointmentsTable
          data={data}
          isLoading={isLoading}
          onRowSelected={navigateToScreeningPage}
        />
      </Layout>
      <AddAppointmentModal
        isVisible={openModal}
        onClose={() => {
          setOpenModal(false)
        }}
      />
    </>
  )
}
