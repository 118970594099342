import {
  AppointmentType,
  HealthDataResolution,
  HealthDataType,
  HealthJourneyType,
  LifeMilestoneImpact,
  MonthlySurveyType,
  PatientReportConfirmationStatus,
  QuestionnaireStatus,
  QuestionnaireType,
  SettingsType,
  Specialization,
  TimelineItemType,
} from '../models'

export enum OrderDirection {
  ascending = 'ASC',
  descending = 'DESC',
}

export enum QuestionnaireOrder {
  DATE_COMPLETED = 'DATE_COMPLETED',
  DATE_DUE = 'DATE_DUE',
  DATE_RECEIVED = 'DATE_RECEIVED',
}

export type QueryUrlParam = {
  query?: string
}

export type PagingUrlParams = {
  page?: number
  perPage?: number
}

export type IdUrlParam = {
  id: string
}

export type SlugUrlParam = {
  slug: string
}

export type OptionalPatientIdUrlParam = {
  patientId?: string
}

export type OrderDirectionUrlParam = {
  orderDirection?: OrderDirection
}

export type OptionalHealthJourneyIdUrlParam = {
  healthJourneyId?: string
}

export type HealthRecordsUrlParams = OptionalPatientIdUrlParam &
  OptionalHealthJourneyIdUrlParam &
  PagingUrlParams

export type ActiveRecordsUrlParams = HealthRecordsUrlParams & {
  isActive?: boolean
}

export type TriageQuestionnaireUrlParams = {
  appointmentType: AppointmentType
  facilityId: string
}

export type QuestionnaireUrlParams = {
  assignedQuestionnaireId: string
}

export type QuestionnairesUrlParams = {
  questionnaireId?: string
  questionnaireStatus?: QuestionnaireStatus
  questionnaireType?: QuestionnaireType
}

export type PractitionerQuestionnairesUrlParams = {
  facilityId: string
  questionnaireStatus?: QuestionnaireStatus
  patientId?: string
  questionnaireId?: string
  orderBy?: QuestionnaireOrder
} & PagingUrlParams &
  OrderDirectionUrlParam

export type AppointmentSlotsUrlParams = {
  facilityId: string
  appointmentType: AppointmentType
}

export type BookmarkMaterialUrlParams = {
  materialId: string
  bookmark: boolean
}

export type DataRequestUrlParams = {
  practitionerId?: string
  patientId: string
}

export type DeviceTokenUrlParam = {
  token: string
}

export type FacilitiesUrlParams = {
  specialization?: Specialization
} & QueryUrlParam

export type SplitFacilitiesUrlParams = QueryUrlParam & PagingUrlParams

export type PractitionerPatientUrlParams = {
  facilityId: string
  patientId: string
}

export type PractitionerPatientsUrlParams = {
  facilityId: string
} & QueryUrlParam &
  PagingUrlParams

export type PatientIdUrlParam = {
  patientId: string
}

export type PatientTimelineParams = {
  patientId: string
  searchTerm?: string
  typeFilter?: TimelineItemType
}

export type PatientSharedMaterialsUrlParams = {
  unreadOnly?: boolean
  bookmarkedOnly?: boolean
  condition?: string
  facilityId?: string
  practitionerId?: string
  category?: string
}

export type AllergensUrlParams = {
  category?: string
} & QueryUrlParam

export type FacilityIdParam = {
  facilityId: string
}

export type FacilityIdAppointmentIdParams = {
  facilityId: string
  appointmentId: string
}

export type OptionalFacilityIdParam = {
  facilityId?: string
}

export type AggregatedActivitiesUrlParams = {
  granularity: HealthDataResolution
  fields: HealthDataType[]
  fromDate: string
  toDateInclusive: string
}

export type LatestActivitiesUrlParams = {
  fields: HealthDataType[]
}

export type PatientAggregatedActivitiesUrlParams =
  AggregatedActivitiesUrlParams & PatientIdUrlParam

export type PatientLatestActivitiesUrlParams = LatestActivitiesUrlParams &
  PatientIdUrlParam

export type SurveyQuestionnaireUrlParams = {
  surveyType: MonthlySurveyType
}

export type MeasurementsUrlParams = {
  surveyType?: MonthlySurveyType
  patientId?: string
  questionnaireId?: string
} & PagingUrlParams

export type SharingLinkParams = {
  password: string
  token: string
}

export type SharingLinkMeasurementsParams = {
  surveyType: MonthlySurveyType
} & SharingLinkParams

export type SharingLinkAggregatedActivitiesParams =
  AggregatedActivitiesUrlParams & SharingLinkParams

export type SharingLinkLatestActivitiesParams = LatestActivitiesUrlParams &
  SharingLinkParams

export type FileUrlParams = {
  id: string
  patientId?: string
}

export type FirstResponderAuthParams = {
  code: string
}

export type FirstResponderFindPatientParams = {
  surname?: string
  phoneNumber?: string
  patientId?: string
} & FirstResponderAuthParams

export type FirstResponderSummaryParams = {
  patientId: string
} & FirstResponderAuthParams

export type FirstResponderAggregatedActivitiesParams =
  AggregatedActivitiesUrlParams & FirstResponderSummaryParams

export type FirstResponderLatestActivitiesParams = LatestActivitiesUrlParams &
  FirstResponderSummaryParams

export type FirstResponderMeasurementsParams = {
  surveyType: MonthlySurveyType
} & FirstResponderSummaryParams

export type DrugBarcodeUrlParam = {
  barcode?: string
}

export type DrugsUrlParams = DrugBarcodeUrlParam | QueryUrlParam

export type SettingsUrlParams = {
  type: SettingsType
} & QueryUrlParam

export type LifeMilestonesUrlParams = HealthRecordsUrlParams & {
  impact?: LifeMilestoneImpact
}

export type LifeMilestonesSummaryUrlParams = {
  patientId?: string
  includeHidden?: boolean
}

export type ConfirmPatientReportUrlParams = {
  uploadId: string
  confirm: PatientReportConfirmationStatus
}

export type PregnancyWeekByMenstrualPeriodUrlParams = {
  cycleLength?: number
  lastPeriodDate: string
} & IdUrlParam

export type PregnancyWeekByDeliveryDateUrlParams = {
  deliveryDate: string
} & IdUrlParam

export type JourneyNoteUrlParams = {
  healthJourneyId: string
  noteId: string
}

export type JourneyTypeUrlParams = {
  journeyType: HealthJourneyType
}

export type FileNameUrlParam = {
  fileName: string
}

export type PregnancyJourneyFileUrlParams = {
  healthJourneyId: string
  fileId: string
}

export type PregnancyJourneyEndUrlParams = {
  healthJourneyId: string
  closeJourney?: boolean
}

export type PatientJourneyUrlParams = {
  patientId: string
  healthJourneyId: string
}

export type ObstetricsFileUrlParams = {
  fileLinkId: string
  patientId: string
}

export type AppointmentFileUrlParams = {
  appointmentId: string
  fileId: string
}

export type AppointmentsUrlParams = {
  facilityId?: string
  healthJourneyId?: string
}

export type AppointmentNoteUrlParams = {
  appointmentId: string
  noteId: string
}

export type PatientAppointmentsUrlParams = PatientIdUrlParam & PagingUrlParams

export type PatientAppointmentUrlParams = {
  patientId: string
  appointmentId: string
}

export type PatientAppointmentFileUrlParams = PatientAppointmentUrlParams & {
  fileId: string
}

export type PatientHealthJourneyUrlParams = {
  patientId: string
  healthJourneyId: string
}

export type PatientCriticalNoteUrlParams = {
  patientId: string
  code?: string
}

export type FirstResponderCriticalNoteUrlParams = PatientIdUrlParam &
  FirstResponderAuthParams

export type CampaignInviteAcceptanceParams = {
  inviteId: string
  acceptInvite: boolean
}

export type AppointmentsForPractitionerParams = {
  facilityId?: string
  date?: string
  department?: string
}

// Surgical
export type SurgeryWeekByDateUrlParams = {
  surgeryDate: string
} & IdUrlParam

export type AddSurgeryDateParams = {
  id: string
  surgeryDate: string
}

export type AddPainScoreParams = {
  id: string
  painScore: string
}

export type JourneyIdAndDateParams = {
  healthJourneyId: string
  date: string
}

export type ArthritisSymptomsAggregatedParams = {
  healthJourneyId: string
  granularity: HealthDataResolution
  fromDate: string
  toDate: string
}
