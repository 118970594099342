import * as React from 'react'

import {IconProps} from '.'

function Person({color, size, isFilled}: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={isFilled ? color : 'none'}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.9893 6.75C13.439 6.75 14.6143 5.57475 14.6143 4.125C14.6143 2.67525 13.439 1.5 11.9893 1.5C10.5395 1.5 9.36426 2.67525 9.36426 4.125C9.36426 5.57475 10.5395 6.75 11.9893 6.75Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.4291 12.6295L17.6866 15.1631C17.8976 15.3741 18.1837 15.4926 18.4821 15.4926C18.7805 15.4926 19.0666 15.3741 19.2776 15.1631C19.4886 14.9522 19.6071 14.666 19.6071 14.3676C19.6071 14.0693 19.4886 13.7831 19.2776 13.5721L15.5653 8.79918C15.3914 8.62481 15.1847 8.48653 14.9572 8.39228C14.7297 8.29803 14.4857 8.24968 14.2395 8.25H9.73947C9.49319 8.24968 9.24928 8.29803 9.02175 8.39228C8.79422 8.48652 8.58756 8.62481 8.41364 8.79918L4.70133 13.5721C4.59687 13.6766 4.514 13.8006 4.45746 13.9371C4.40093 14.0736 4.37183 14.2199 4.37183 14.3676C4.37183 14.5154 4.40093 14.6617 4.45746 14.7982C4.514 14.9347 4.59687 15.0587 4.70133 15.1631C4.8058 15.2676 4.92982 15.3505 5.06631 15.407C5.2028 15.4635 5.34909 15.4926 5.49683 15.4926C5.64456 15.4926 5.79085 15.4635 5.92735 15.407C6.06384 15.3505 6.18786 15.2676 6.29232 15.1631L9.54981 12.6295L6.94979 20.8415C6.82369 21.1119 6.81018 21.4213 6.91223 21.7017C7.01428 21.9821 7.22353 22.2104 7.49394 22.3365C7.76435 22.4626 8.07378 22.4761 8.35416 22.3741C8.63453 22.272 8.86289 22.0628 8.98898 21.7924L11.9895 17.0701L14.99 21.7924C15.1164 22.0621 15.3448 22.2707 15.6249 22.3724C15.905 22.474 16.214 22.4603 16.484 22.3344C16.7541 22.2085 16.9631 21.9806 17.0653 21.7007C17.1675 21.4208 17.1545 21.1118 17.0291 20.8415L14.4291 12.6295Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default Person
