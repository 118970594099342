export enum SettingsType {
  FAMILY_HISTORY = 'FAMILY_HISTORY',
  MEDICAL_TEST = 'MEDICAL_TEST',
  SURGERY = 'SURGERY',
  LIFESTYLE_DIET = 'LIFESTYLE_DIET',
  CHOLESTEROL_TEST = 'CHOLESTEROL_TEST',
  OCCUPATION = 'OCCUPATION',
}

export type GeneralItemTypeValue = {
  label: string
}

export type GeneralItemType<T = GeneralItemTypeValue> = {
  alias: string
  type?: SettingsType
  value: T
}

export type MedicalTestTypeValue = {
  label: string
  isCholesterol?: boolean
}

export type CholesterolTestTypeValue = {
  title: string
  description: string
  price: number
  url?: string
}

export type FamilyHistoryTypeValue = {
  title: string
  highlight: string
}
