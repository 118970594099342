import React, {useContext} from 'react'

import {BasicHealthInformationTimeline} from './BasicHealthInformationTimeline'

import {PatientsContext} from '~/components/patients/PatientsContext'

export const BasicHealthInformation: React.FC = () => {
  const {patient} = useContext(PatientsContext)

  if (!patient) {
    // Handled in PatientDetail
    return null
  }

  return <BasicHealthInformationTimeline patient={patient} />
}
