import {MaterialsItem, MaterialsItemType} from '@common/models'
import React from 'react'

import {
  FolderIcon,
  MaterialContent,
  MaterialWrapper,
  RightIcon,
  StyledMaterial,
} from './Material.styled'

import {Icon, IconName} from '~/components/general/icon/Icon'
import {Typography} from '~/components/general/typography/Typography'
import {theme} from '~/styles/theme'

type MaterialProps = {
  className?: string
  iconName?: IconName
  item: Omit<MaterialsItem, 'id'>
  onClick?: () => void
  onIconClick?: () => void
}

export const Material: React.FC<MaterialProps> = ({
  className,
  iconName = 'Share',
  item,
  onClick,
  onIconClick,
}) => (
  <MaterialWrapper className={className}>
    <StyledMaterial onClick={onClick}>
      <FolderIcon>
        <Icon
          size="md"
          name={item.type === MaterialsItemType.FOLDER ? 'Folder' : 'BookOpen'}
        />
      </FolderIcon>
      <MaterialContent>
        <Typography>{item.name}</Typography>
      </MaterialContent>
      <RightIcon
        onClick={(e) => {
          if (onIconClick) {
            onIconClick()
            e.stopPropagation()
          }
        }}>
        <Icon
          size="sm"
          name={iconName}
          circled="md"
          circledBgd={theme.colors.grey.grey150}
        />
      </RightIcon>
    </StyledMaterial>
  </MaterialWrapper>
)
