import React, {useContext, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {MaterialsItemType} from '@common/models'

import {MaterialsSelectionSection} from './MaterialsSelectionSection'
import {
  MaterialsSideBarContainer,
  MaterialsSideBarContent,
  MaterialsTopBar,
} from './MaterialsSideBar.styled'

import {MaterialsContext} from '~/components/educationalMaterials/MaterialsContext'
import {ShareMaterialsModal} from '~/components/educationalMaterials/shareModal/ShareMaterialsModal'
import {Button} from '~/components/general/button/Button'
import {Typography} from '~/components/general/typography/Typography'
import {Icon} from '~/components/general/icon/Icon'
import {theme} from '~/styles/theme'

export const SelectedMaterials: React.FC = () => {
  const {t} = useTranslation()
  const {selectedMaterials} = useContext(MaterialsContext)
  const [isShareModalOpen, setIsShareModalOpen] = useState(false)

  return (
    <MaterialsSideBarContainer>
      <MaterialsTopBar>
        <h4>{t('Selected materials')}</h4>
        <Button
          disabled={!selectedMaterials.length}
          onClick={() => setIsShareModalOpen(true)}
          endIcon={<Icon name="Share" color={theme.colors.white} size="sm" />}>
          {t('Share')}
        </Button>
      </MaterialsTopBar>
      <MaterialsSideBarContent>
        <MaterialsSelectionSection
          title={t('Topics')}
          selection={selectedMaterials.filter(
            (item) => item.type === MaterialsItemType.FOLDER,
          )}
        />
        <MaterialsSelectionSection
          title={t('Resources')}
          selection={selectedMaterials.filter(
            (item) => item.type === MaterialsItemType.FILE,
          )}
        />
        {!selectedMaterials.length && (
          <Typography color={theme.colors.grey.main}>
            {t(
              'All topics and individual resources you select will appear here. ',
            )}
          </Typography>
        )}
      </MaterialsSideBarContent>
      <ShareMaterialsModal
        isVisible={isShareModalOpen}
        onClose={() => setIsShareModalOpen(false)}
      />
    </MaterialsSideBarContainer>
  )
}
