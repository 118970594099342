import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {ellipsizeText} from '@common/utils'

import {Icon} from '../icon/Icon'

import {Button} from '~/components/general/button/Button'

export type ExpandableTextProps = {
  text: string
  shortenedLength: number
  disableExpansion?: boolean
}

export const ExpandableText = ({
  text,
  shortenedLength,
  disableExpansion,
}: ExpandableTextProps) => {
  const {t} = useTranslation()
  const [isExpanded, setIsExpanded] = useState(false)

  if (disableExpansion) {
    return <span>{text}</span>
  }

  const canBeExpanded = text.length > shortenedLength

  const textToDisplay = isExpanded ? text : ellipsizeText(text, shortenedLength)

  const onClick = () => {
    setIsExpanded(!isExpanded)
  }

  return (
    <span>
      {textToDisplay}
      {canBeExpanded && (
        <Button
          onClick={onClick}
          size="xs"
          color="primary"
          startIcon={
            isExpanded ? (
              <Icon name="Minus" size="xs" />
            ) : (
              <Icon name="Plus" size="xs" />
            )
          }>
          {isExpanded ? t('less') : t('more')}
        </Button>
      )}
    </span>
  )
}
