import {
  MaterialsItem,
  MaterialsCondition,
  PractitionersPatient,
  MaterialsItemType,
} from '@common/models'
import React, {createContext, useCallback, useMemo, useState} from 'react'

import {useGetMaterialsContents} from '~/api'

type MaterialsContextProps = {
  selectedMaterials: MaterialsItem[]
  selectedMaterialsCount: number
  addItem: (item: MaterialsItem) => void
  removeItem: (item: MaterialsItem) => void
  contents: MaterialsCondition[] | undefined
  itemIdMap: Record<string, MaterialsItem>
  setSelectedPatient: (patient?: PractitionersPatient) => void
  selectedPatient?: PractitionersPatient
}

export const getFilesFromFolders = (materialItems: MaterialsItem[] = []) =>
  materialItems.reduce((materialsSet, currentItem) => {
    if (currentItem.type === MaterialsItemType.FOLDER) {
      getFilesFromFolders(currentItem.children).forEach((item) =>
        materialsSet.add(item),
      )
      return materialsSet
    }

    materialsSet.add(currentItem.id)
    return materialsSet
  }, new Set<string>())

export const MaterialsContext = createContext({} as MaterialsContextProps)

const loadSelection = () => {
  const storedValue = window.sessionStorage.getItem('selectedMaterials')
  return storedValue ? JSON.parse(storedValue) : []
}

const storeSelection = (selection: MaterialsItem[]) =>
  window.sessionStorage.setItem('selectedMaterials', JSON.stringify(selection))

type MaterialsContextProviderProps = {
  children: React.ReactNode
}

export const MaterialsContextProvider: React.FC<
  MaterialsContextProviderProps
> = ({children}) => {
  const {data: contents} = useGetMaterialsContents()
  const [selectedMaterials, setSelectedMaterials] = useState<MaterialsItem[]>(
    loadSelection(),
  )
  const [selectedPatient, setSelectedPatient] = useState<PractitionersPatient>()
  const [selectedMaterialsCount, setSelectedMaterialsCount] = useState(
    getFilesFromFolders(loadSelection()).size,
  )

  const itemIdMap = useMemo(() => {
    const map = {} as Record<string, MaterialsItem>

    const addItem = (
      item: MaterialsItem & {children?: MaterialsItem[] | undefined},
    ) => {
      map[item.id] = item
      item.children?.forEach(addItem)
    }

    contents?.forEach((condition) =>
      condition.structure.categories.forEach((category) =>
        category.subCategories.forEach(addItem),
      ),
    )

    return map
  }, [contents])

  const isSelected = useCallback(
    (item: MaterialsItem) =>
      selectedMaterials.some((selectedItem) => selectedItem.id === item.id),
    [selectedMaterials],
  )

  const addItem = useCallback(
    (item: MaterialsItem) => {
      if (!isSelected(item)) {
        const newSelection = [...selectedMaterials, item]
        setSelectedMaterials(newSelection)
        storeSelection(newSelection)
        setSelectedMaterialsCount(getFilesFromFolders(newSelection).size)
      }
    },
    [
      setSelectedMaterials,
      setSelectedMaterialsCount,
      isSelected,
      selectedMaterials,
    ],
  )

  const removeItem = useCallback(
    (item: MaterialsItem) => {
      if (isSelected(item)) {
        const newSelection = selectedMaterials.filter(
          (selectedItem) => selectedItem.id !== item.id,
        )
        setSelectedMaterials(newSelection)
        storeSelection(newSelection)
        setSelectedMaterialsCount(getFilesFromFolders(newSelection).size)
      }
    },
    [
      setSelectedMaterials,
      setSelectedMaterialsCount,
      isSelected,
      selectedMaterials,
    ],
  )

  const contextValue = useMemo(
    () => ({
      selectedMaterials,
      selectedMaterialsCount,
      addItem,
      removeItem,
      contents,
      itemIdMap,
      setSelectedPatient,
      selectedPatient,
    }),
    [
      selectedMaterials,
      selectedMaterialsCount,
      contents,
      itemIdMap,
      selectedPatient,
      addItem,
      removeItem,
    ],
  )

  return (
    <MaterialsContext.Provider value={contextValue}>
      {children}
    </MaterialsContext.Provider>
  )
}
