import React from 'react'

import {StyledCard, CardIcon} from './LibraryCard.styled'

import {Icon, IconName} from '~/components/general/icon/Icon'
import {Typography} from '~/components/general/typography/Typography'
import {Container} from '~/components/general/container/Container'
import {Chip} from '~/components/general/chip/Chip'
import {theme} from '~/styles/theme'

type LibraryCardProps = {
  title: string
  icon?: IconName
  content: string
  chips?: string[]
  onClick?: () => void
  onIconClick?: () => void
  spaceAfter?: boolean
}

export const LibraryCard: React.FC<LibraryCardProps> = ({
  title,
  icon = 'User',
  content,
  chips,
  onClick,
  onIconClick,
  spaceAfter,
}) => (
  <StyledCard onClick={onClick} spaceAfter={spaceAfter}>
    <Container flex direction="column" align="flex-start" spacing={2}>
      <Container flex align="center" spacing={2}>
        <CardIcon>
          <Icon name={icon} size="md" />
        </CardIcon>
        <Typography size="lg" weight="medium">
          {title}
        </Typography>
      </Container>
      <Typography color={theme.colors.grey.main}>{content}</Typography>
      <Container flex direction="row" align="flex-start" spacing={1}>
        {chips?.map((chip, index) => (
          <Chip text={chip} key={`${chip}-${index}`} />
        ))}
      </Container>
    </Container>
    <Icon
      name="Send"
      size="sm"
      circled="md"
      onClick={(e) => {
        if (onIconClick) {
          onIconClick()
          e.stopPropagation()
        }
      }}
    />
  </StyledCard>
)
