import {RowGroup} from '@common/models'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {EditableTrendTypes} from '../utils'
import {editorMap} from './TrendEditors'

import {Container} from '~/components/general/container/Container'
import {Button} from '~/components/general/button/Button'

export type AddTrendDataCardProps = {
  healthDataType: EditableTrendTypes
  onUpdate: (newData: RowGroup[]) => void
  onCancel: () => void
}

export const AddTrendDataCard: React.FC<AddTrendDataCardProps> = ({
  healthDataType,
  onUpdate,
  onCancel,
}) => {
  const {t} = useTranslation()
  const [data, setData] = React.useState<RowGroup[]>([])
  const EditorComponent = editorMap[healthDataType]

  return (
    <Container direction="row" flex>
      <EditorComponent setData={setData} />
      <Container direction="row" flex>
        <Button
          onClick={() => onUpdate(data)}
          color="secondary"
          style={{marginLeft: 'auto'}}
          size="sm">
          {t('Save ')}
        </Button>
        <Button
          onClick={onCancel}
          color="primary"
          style={{marginLeft: 'auto'}}
          size="sm">
          {t('Cancel')}
        </Button>
      </Container>
    </Container>
  )
}
