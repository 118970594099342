import React from 'react'
import {HealthDataGrading} from '@common/models'

import {
  DataAccordionContentWrapper,
  DataAccordionEntryWrapper,
} from './DataAccordionContent.styled'
import {GradeIndicators} from '../gradeIndicator/GradeIndicators'
import {Container} from '../container/Container'

import {Typography} from '~/components/general/typography/Typography'
import {theme} from '~/styles/theme'

export type DataAccordionEntry = Partial<Omit<HealthDataGrading, 'value'>> & {
  title: string
  value?: string[] | string | number | null
}

type DataAccordionContentProps = {
  data: DataAccordionEntry[]
  showGrading?: boolean
}

export const DataAccordionContent: React.FC<DataAccordionContentProps> = ({
  data,
  showGrading,
}) => (
  <DataAccordionContentWrapper>
    {data.map(({grade, title, trend, trendGrade, value}) => (
      <DataAccordionEntryWrapper key={`${title}${value}`}>
        <Container flex align="center" spacing={2}>
          {showGrading && (
            <GradeIndicators
              grade={grade}
              trend={trend}
              trendGrade={trendGrade}
            />
          )}
          <div>
            <Typography color={theme.colors.grey.main} size="sm">
              {title}
            </Typography>
            {Array.isArray(value) ? (
              value.map((item, index) => (
                <Typography key={index}>{item}</Typography>
              ))
            ) : (
              <Typography>{value || '-'}</Typography>
            )}
          </div>
        </Container>
      </DataAccordionEntryWrapper>
    ))}
  </DataAccordionContentWrapper>
)
