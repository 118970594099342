import styled from 'styled-components'

import {Card} from '~/components/general/card/Card'

export const StyledPregnancyCard = styled(Card)`
  flex: 1;
  padding-top: ${({theme}) => theme.spacing.md};
`

export const StyledPregnancyInnerCardTable = styled.table`
  width: 100%;
`

export const StyledPregnancyInnerCardTableBody = styled.tbody`
  width: 100%;
`

export const StyledPregnancyInnerCardTableCell = styled.td`
  width: 25%;
  vertical-align: top;

  &:first-child {
    white-space: nowrap;
  }

  &:not(:last-child) {
    padding-right: ${({theme}) => theme.spacing.md};
  }

  *:not(:last-child) > & {
    padding-bottom: ${({theme}) => theme.spacing.xs};
  }
`
