import React from 'react'

import {ComponentValue} from '../fieldConfig'

import {StyledDashboardInnerCardTableCell} from '~/components/facility/GeneralDataCard.styled'
import {TextArea} from '~/components/general/textArea/TextArea'
import {Typography} from '~/components/general/typography/Typography'

export const TextAreaDataInput: React.FC<{
  title: string
  name: string
  initialValue: string | undefined
  onUpdate: (newData: ComponentValue[]) => void
}> = ({title, name, initialValue, onUpdate}) => {
  const [value, setValue] = React.useState<string>(initialValue || '')

  const handleValueChange = (newValue: string) => {
    setValue(newValue)
    onUpdate([{name, value: newValue}])
  }

  return (
    <StyledDashboardInnerCardTableCell colSpan={2}>
      <Typography weight="medium">{title}</Typography>
      <TextArea
        placeholder="Enter value"
        value={value}
        onChange={(e) => handleValueChange(e.target.value)}
      />
    </StyledDashboardInnerCardTableCell>
  )
}
