import React, {useContext} from 'react'
import {useNavigate} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import {DateTime} from 'luxon'
import {MaterialsItemType} from '@common/models'

import {StyledAccordion} from './PatientSharedMaterials.styled'
import {PatientSharedMaterialsHeader} from './PatientSharedMaterialsHeader'

import {useGetPatientSharedMaterials} from '~/api'
import {Material} from '~/components/educationalMaterials/materials/Material'
import {Loading} from '~/components/general/loading/Loading'
import {Typography} from '~/components/general/typography/Typography'
import {MaterialsContent} from '~/components/educationalMaterials/materials/Materials.styled'
import {PatientsContext} from '~/components/patients/PatientsContext'
import {Container} from '~/components/general/container/Container'

const PatientSharedMaterialsContent: React.FC = () => {
  const navigate = useNavigate()
  const {t} = useTranslation()

  const {patient} = useContext(PatientsContext)
  // Missing id is handled in PatientDetail
  const patientId = patient?.patientId as string

  const {data: sharedMaterials, isInitialLoading} =
    useGetPatientSharedMaterials({
      patientId,
    })

  if (isInitialLoading) {
    return <Loading />
  }

  if (!sharedMaterials || !Object.keys(sharedMaterials).length) {
    return <Typography>{t('No materials have been shared yet.')}</Typography>
  }

  return (
    <Container spacing={1} direction="column">
      {Object.keys(sharedMaterials).map((date) => (
        <StyledAccordion
          key={date}
          title={t('Shared on {{date}}', {
            date: DateTime.fromISO(date).toLocaleString(DateTime.DATE_SHORT),
          })}>
          <MaterialsContent>
            {sharedMaterials[date].map((sharedFile) => (
              <Material
                key={sharedFile.fileId}
                onClick={() =>
                  navigate(`/app/materials/file/${sharedFile.fileId}`)
                }
                item={{
                  type: MaterialsItemType.FILE,
                  name: sharedFile.fileName,
                }}
              />
            ))}
          </MaterialsContent>
        </StyledAccordion>
      ))}
    </Container>
  )
}

export const PatientSharedMaterials: React.FC = () => (
  <>
    <PatientSharedMaterialsHeader />
    <PatientSharedMaterialsContent />
  </>
)
