import {HealthDataType, PermissionCategory} from '@common/models'

import {LinkSharingContentProps} from './LinkSharingContent'

import {
  useGetSharingLinkLatestActivities,
  useGetSharingLinkBasicHealthInfo,
} from '~/api/linkSharing'

export const useGetLinkSharingData = ({
  password,
  response,
  token,
}: LinkSharingContentProps) => {
  const {permissions} = response

  const urlParams = {
    token,
    password,
  }

  const hasActivityPermission = permissions?.includes(
    PermissionCategory.ACTIVITY,
  )

  const {data: basicHealthInfo, isInitialLoading: isBasicHealthInfoLoading} =
    useGetSharingLinkBasicHealthInfo(urlParams)

  const {data: latestActivities, isInitialLoading: isActivitiesDataLoading} =
    useGetSharingLinkLatestActivities(
      {
        ...urlParams,
        fields: Object.values(HealthDataType),
      },
      {
        enabled: hasActivityPermission,
      },
    )

  const isLoading = isBasicHealthInfoLoading || isActivitiesDataLoading

  return {
    isLoading,
    basicHealthInfo,
    latestActivities,
  }
}
