import React from 'react'

import {
  StyledInnerSideNav,
  StyledInnerSideNavItem,
  Badge,
} from './InnerSideNav.styled'

import {Typography} from '~/components/general/typography/Typography'
import {theme} from '~/styles/theme'

type ItemProps = {
  badge?: string | number
  children: React.ReactNode
  redBadge?: boolean
  isChild?: boolean
  active?: boolean
  onClick?: React.MouseEventHandler<HTMLDivElement>
}

export const InnerSideNavItem: React.FC<ItemProps> = ({
  children,
  active,
  badge,
  isChild,
  redBadge,
  onClick,
}) => (
  <StyledInnerSideNavItem active={active} onClick={onClick} isChild={isChild}>
    <Typography
      color={active ? theme.colors.primary.main : theme.colors.grey.main}
      weight={active ? 'medium' : 'normal'}
      maxWidth={170}>
      {children}
    </Typography>
    {badge && (
      <Badge active={active} redBadge={redBadge}>
        {badge}
      </Badge>
    )}
  </StyledInnerSideNavItem>
)

type InnerSideNavProps = {
  children: React.ReactNode
}

export const InnerSideNav: React.FC<InnerSideNavProps> = ({children}) => (
  <StyledInnerSideNav>{children}</StyledInnerSideNav>
)
