import React from 'react'

import {ComponentValue} from '../fieldConfig'

import {Typography} from '~/components/general/typography/Typography'
import {Input} from '~/components/general/input/Input'
import {Container} from '~/components/general/container/Container'
import {StyledDashboardInnerCardTableCell} from '~/components/facility/GeneralDataCard.styled'

export const TextDataInput: React.FC<{
  title: string
  name: string
  initialValue: string | undefined
  inputType?: React.HTMLInputTypeAttribute
  onUpdate: (newData: ComponentValue[]) => void
}> = ({title, name, initialValue, inputType = 'text', onUpdate}) => {
  const [value, setValue] = React.useState<string>(initialValue || '')

  const handleValueChange = (newValue: string) => {
    setValue(newValue)
    onUpdate([{name, value: newValue}])
  }

  return (
    <StyledDashboardInnerCardTableCell>
      <Container direction="column">
        <Typography weight="medium" size="sm">
          {title}
        </Typography>
        <Input
          type={inputType}
          size="small"
          placeholder="Enter value"
          value={value}
          onChange={(e) => handleValueChange(e.target.value)}
        />
      </Container>
    </StyledDashboardInnerCardTableCell>
  )
}
