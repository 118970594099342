import * as React from 'react'

import {IconProps} from '.'

function WaterDrop({color, size, isFilled}: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={isFilled ? color : 'none'}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.3636 13.4615C18.3636 7.57691 12.1818 3 12.1818 3C12.1818 3 6 7.57691 6 13.4615C6 15.1956 6.65129 16.8587 7.81061 18.0849C8.96992 19.3111 10.5423 20 12.1818 20C13.8213 20 15.3937 19.3111 16.553 18.0849C17.7123 16.8587 18.3636 15.1956 18.3636 13.4615Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.51078 14.5197C6.30001 13.5652 6.8286 12.602 7.74704 12.2672C8.39467 12.0311 9.11883 12.1544 9.65179 12.5916L12.1337 14.6276C12.2603 14.7314 12.3992 14.8193 12.5473 14.8894L14.9228 16.0125C15.9551 16.5005 17.1858 16.0267 17.6243 14.9724L18.5062 12.8521L17.1578 18.0138C16.9153 18.9422 16.0477 19.5675 15.0903 19.504L8.997 19.0994C8.10992 19.0405 7.36821 18.4031 7.17653 17.535L6.51078 14.5197Z"
        fill={color}
      />
    </svg>
  )
}

export default WaterDrop
