import * as React from 'react'

export const AuthLayout = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="612"
    height="1024"
    viewBox="0 0 612 1024"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <g clipPath="url(#clip0_1095_11849)">
      <rect width="616" height="1025" fill="#FAFBFD" />
      <path
        d="M0.000854492 820.001C54.0172 820.001 105.821 798.402 144.016 759.957C182.212 721.512 203.67 669.369 203.67 614.999H0.000854492"
        fill="#EEF0F5"
      />
      <path
        d="M204.659 410C204.659 355.631 183.201 303.488 145.005 265.043C106.81 226.598 55.0055 205 0.988741 205L0.988739 410"
        fill="#EEF0F5"
      />
      <path d="M612 0H408.329V205H612V0Z" fill="#F56F50" />
      <path d="M408.333 410H204.662V615H408.333V410Z" fill="#EEF0F5" />
      <path
        d="M204.659 410C258.676 410 310.48 388.402 348.676 349.957C386.872 311.512 408.33 259.369 408.33 205H204.659"
        fill="#69D2E7"
      />
      <path
        d="M550.524 263.762C485.895 200.796 408.438 205.061 408.438 205.061C408.438 205.061 403.254 278.702 464.705 345.938C526.156 413.175 611.975 409.976 611.975 409.976C611.975 409.976 615.153 326.728 550.524 263.762Z"
        fill="#002A7E"
      />
      <path
        d="M265.268 673.762C329.897 610.796 407.355 615.061 407.355 615.061C407.355 615.061 412.538 688.702 351.088 755.938C289.637 823.175 203.818 819.976 203.818 819.976C203.818 819.976 200.639 736.728 265.268 673.762Z"
        fill="#69D2E7"
      />
      <path
        d="M0.000854492 1025C54.0172 1025 105.821 1003.4 144.016 964.957C182.212 926.512 203.67 874.369 203.67 819.999H0.000854492"
        fill="#EEF0F5"
      />
    </g>
    <defs>
      <clipPath id="clip0_1095_11849">
        <rect width="612" height="1025" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
