import * as React from 'react'

import {IconProps} from '.'

function Edit({color, size, isFilled}: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={isFilled ? color : 'none'}
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.013 4.75C4.32052 4.75 3.75 5.31689 3.75 6.028V18.972C3.75 19.6831 4.32052 20.25 5.013 20.25H18.987C19.6795 20.25 20.25 19.6831 20.25 18.972V13.067C20.25 12.6528 20.5858 12.317 21 12.317C21.4142 12.317 21.75 12.6528 21.75 13.067V18.972C21.75 20.5009 20.5185 21.75 18.987 21.75H5.013C3.48148 21.75 2.25 20.5009 2.25 18.972V6.028C2.25 4.49911 3.48148 3.25 5.013 3.25H12C12.4142 3.25 12.75 3.58579 12.75 4C12.75 4.41421 12.4142 4.75 12 4.75H5.013Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.8837 4.05591C17.9576 2.98201 19.6985 2.98201 20.7724 4.05591L20.9444 4.22791C22.0183 5.3018 22.0183 7.04268 20.9444 8.11657L12.5544 16.5066L12.5523 16.5086C12.3291 16.7301 12.05 16.8896 11.741 16.9668C11.741 16.9668 11.741 16.9668 11.741 16.9668L8.02381 17.8964C7.96076 17.9135 7.89501 17.9225 7.82806 17.9224C7.62685 17.9226 7.43645 17.8412 7.2977 17.7027C7.15918 17.5639 7.07773 17.3735 7.07788 17.1723C7.07783 17.1053 7.08681 17.0395 7.10392 16.9765L8.03345 13.2593C8.03346 13.2593 8.03345 13.2594 8.03345 13.2593C8.11029 12.952 8.26923 12.6704 8.49373 12.4459L16.8837 4.05591ZM8.85894 16.1414L11.3771 15.5116C11.4198 15.501 11.4605 15.4786 11.4951 15.4445C11.4953 15.4443 11.4949 15.4448 11.4951 15.4445L19.8837 7.05591C20.3718 6.5678 20.3718 5.77668 19.8837 5.28857L19.7117 5.11657C19.2236 4.62846 18.4325 4.62846 17.9444 5.11657L9.55439 13.5066C9.52289 13.5381 9.49983 13.5785 9.48867 13.6231L8.85894 16.1414Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.2997 5.64008C15.5926 5.34719 16.0675 5.34719 16.3604 5.64008L19.3604 8.64008C19.6533 8.93297 19.6533 9.40785 19.3604 9.70074C19.0675 9.99363 18.5926 9.99363 18.2997 9.70074L15.2997 6.70074C15.0069 6.40785 15.0069 5.93297 15.2997 5.64008Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.70097 12.7042C8.77805 12.6934 8.93486 12.6714 9.07816 12.6714C10.8734 12.6714 12.3282 14.1262 12.3282 15.9214C12.3282 16.0702 12.3066 16.2212 12.2948 16.3044C12.2927 16.3185 12.291 16.3307 12.2897 16.3406C12.2355 16.7512 11.8586 17.0402 11.448 16.9859C11.0373 16.9317 10.7484 16.5548 10.8026 16.1442C10.8073 16.109 10.8112 16.0805 10.8144 16.0567C10.8244 15.9833 10.8282 15.956 10.8282 15.9214C10.8282 14.9546 10.0449 14.1714 9.07816 14.1714C9.04737 14.1714 9.02813 14.174 8.97811 14.1809C8.94855 14.1849 8.90824 14.1905 8.84845 14.1978C8.43732 14.2482 8.06316 13.9558 8.01273 13.5447C7.96231 13.1336 8.25473 12.7594 8.66586 12.709C8.67413 12.708 8.68606 12.7063 8.70097 12.7042Z"
        fill={color}
      />
    </svg>
  )
}

export default Edit
