import styled from 'styled-components'

export const StyledMaterials = styled.div`
  margin-bottom: ${({theme}) => theme.spacing.xxl};
`

export const MaterialsTitle = styled.h3`
  padding-bottom: ${({theme}) => theme.spacing.md};
  margin-bottom: ${({theme}) => theme.spacing.lg};
  border-bottom: 1px solid ${({theme}) => theme.colors.grey.grey40};
`

export const MaterialsContent = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-left: -${({theme}) => theme.spacing.xs};
  margin-right: -${({theme}) => theme.spacing.xs};
`
