import * as React from 'react'

import {IconProps} from '.'

function Moon({color, size, isFilled}: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill={isFilled ? color : 'none'}
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 10.0001C8.42042 8.37826 8.03005 5.93889 9.02461 3.90508C9.08684 3.75702 9.05847 3.58637 8.95168 3.46641C8.84489 3.34645 8.67868 3.29851 8.52441 3.34318C7.33645 3.64939 6.2521 4.26799 5.38393 5.13476C2.69687 7.7525 2.64014 12.0527 5.25721 14.7404C7.94469 17.3577 12.2449 17.3014 14.8629 14.6145C15.7302 13.747 16.3491 12.6628 16.6553 11.4749C16.7002 11.3204 16.6522 11.1539 16.5319 11.047C16.4117 10.9401 16.2407 10.912 16.0926 10.9747C14.0596 11.9688 11.6214 11.5788 10 10.0001Z"
        stroke={color}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default Moon
