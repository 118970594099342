import * as React from 'react'

import {IconProps} from '.'

function Ruler({color, size, isFilled}: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={isFilled ? color : 'none'}
      xmlns="http://www.w3.org/2000/svg">
      <rect
        x="2"
        y="9"
        width="20"
        height="5"
        rx="1"
        stroke={color}
        strokeWidth="1.4"
      />
      <rect x="5" y="11" width="1" height="3" fill={color} />
      <rect x="9" y="11" width="1" height="3" fill={color} />
      <rect x="13" y="11" width="1" height="3" fill={color} />
      <rect x="17" y="11" width="1" height="3" fill={color} />
      <rect x="7" y="12" width="1" height="2" fill={color} />
      <rect x="11" y="12" width="1" height="2" fill={color} />
      <rect x="15" y="12" width="1" height="2" fill={color} />
      <rect x="19" y="12" width="1" height="2" fill={color} />
    </svg>
  )
}

export default Ruler
