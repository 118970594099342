// @flow

import {createGlobalStyle} from 'styled-components'

import {theme} from './theme'

export const GlobalStyle = createGlobalStyle`
  html {
    height: 100%;
    min-height: 100%;
    -webkit-overflow-scrolling: touch;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    box-sizing: border-box;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  body {
    position: relative;
    box-sizing: border-box;
    min-height: 100%;
    margin: 0;
    overflow-x: hidden;
    font-family: 'Inter', sans-serif;
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    margin: 0;
    color: inherit;
    font: inherit;
  }

  button {
    display: block;
    padding: 0;
    border: 0;
  }

  p {
    margin: 0;
    line-height: ${theme.lineHeight.md};
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 0;
  }

  ul {
    margin: 0;
    padding: 0;
    background: none;
    border: 0;
    box-shadow: none;
  }

  ul li {
    display: block;
    width: auto;
    margin: 0;
    padding: 0;
    background: none;
    border: 0;
  }

  input {
    line-height: normal;
  }

  input:disabled {
    background-color: rgba(0, 0, 0, 0);
  }

  form,
  fieldset {
    margin: 0;
    padding: 0;
    border: 0;
  }

  html,
  body,
  input,
  textarea,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  button {
    color: ${theme.colors.primary.main};
    font-weight: normal;
    font-size: ${theme.fontSize.md};
    font-variant-ligatures: no-common-ligatures;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  button:focus {
    outline: 0;
  }

  h1 {
    font-size: ${theme.fontSize.h1};
    line-height: ${theme.lineHeight.h1};
    font-weight: ${theme.fontWeight.normal};
  }

  h2 {
    font-size: ${theme.fontSize.h2};
    line-height:  ${theme.lineHeight.h2};
    font-weight: ${theme.fontWeight.medium};
  }

  h3 {
    font-size: ${theme.fontSize.h3};
    line-height: ${theme.lineHeight.h3};
    font-weight: ${theme.fontWeight.medium};
  }

  h4 {
    font-size: ${theme.fontSize.xl};
    line-height: ${theme.lineHeight.xl};
    font-weight: ${theme.fontWeight.medium};
  }

  h5 {
    font-size: ${theme.fontSize.lg};
    line-height: ${theme.lineHeight.lg};
    font-weight: ${theme.fontWeight.medium};
  }

  label {
    display: block;
    line-height: ${theme.lineHeight.md};
  }

  a:link,
  a:visited {
    color: ${theme.colors.secondary.main};
    text-decoration: none;
  }

  a:hover,
  a:active {
    color: ${theme.colors.secondary.orange600};
    text-decoration: none;
  }
  
  .educational-content {
    h1 {
      font-size: ${theme.fontSize.h3};
      line-height: ${theme.lineHeight.h3};
      font-weight: ${theme.fontWeight.medium};
      margin-bottom: ${theme.spacing.md};
    }
    h1:not(:first-child) {
      margin-top: ${theme.spacing.xxl};
    }
    h2 {
      font-size: ${theme.fontSize.xxl};
      line-height: ${theme.lineHeight.xxl};
      font-weight: ${theme.fontWeight.medium};
      margin-bottom: ${theme.spacing.md};
    }
    h2:not(:first-child) {
      margin-top: ${theme.spacing.xl};
    }
    h3 {
      font-size: ${theme.fontSize.xl};
      line-height: ${theme.lineHeight.xl};
      font-weight: ${theme.fontWeight.medium};
      margin-bottom: ${theme.spacing.xs};
    }
    h3:not(:first-child) {
      margin-top: ${theme.spacing.lg};
    }
    h4, h5, h6 {
      font-size: ${theme.fontSize.lg};
      line-height: ${theme.lineHeight.lg};
      font-weight: ${theme.fontWeight.medium};
      margin-top: ${theme.spacing.md};
      margin-bottom: ${theme.spacing.xxs};
    }
    p {
      margin-bottom: 1em;
    }
    ul, ol {
      margin-bottom: 1em;
      margin-top: 1em;
      padding-left: ${theme.spacing.xl};
    }
    ul ul, ul ol, ol ul, ol ol {
      margin-bottom: 0;
      margin-top: 0;
    }
    li {
      display: list-item;
    }
  } 
`
