import React, {useCallback, useContext, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useParams} from 'react-router'
import {
  EnumType,
  MISSING_ID,
  ellipsizeText,
  formatDate,
  useEnumTranslations,
} from '@common/utils'
import {DateTime} from 'luxon'
import styled from 'styled-components'
import {staticFileNormaliser} from '@common/normalisers'

import {PatientsContext} from '../PatientsContext'
import {
  HealthRecordModal,
  HealthRecordModalData,
} from '../common/healthRecordModal/HealthRecordModal'
import {HealthRecordFileEndpointType} from '../common/healthRecordModal/HealthRecordAttachmentButton'

import {
  Timeline,
  TimelineCardWithSubCardProps,
} from '~/components/general/timeline/Timeline'
import {
  useApi,
  useFetchData,
  useGetAssignedQuestionnaire,
  useGetPatientAppointment,
  useGetPatientAppointmentSummary,
  useGetPatientHealthJourney,
} from '~/api'
import {Loading} from '~/components/general/loading/Loading'
import {Container} from '~/components/general/container/Container'
import {Typography} from '~/components/general/typography/Typography'
import {Chip} from '~/components/general/chip/Chip'
import {useModalData} from '~/utils'

const Heading = styled(Typography)`
  margin-top: ${({theme}) => theme.spacing.xl};
`

type TimelineCardData = {
  date: string
  data: TimelineCardWithSubCardProps
}

export const AppointmentDetailPage: React.FC = () => {
  const {t} = useTranslation()
  const {translateEnumValue} = useEnumTranslations()
  const {appointmentId} = useParams()
  const {patient} = useContext(PatientsContext)
  const [modalData, setModalData] = useState<HealthRecordModalData>()
  const [modalFileEndpoint, setModalFileEndpoint] =
    useState<HealthRecordFileEndpointType>()

  const {
    getAppointmentSummaryModalData,
    getAppointmentTriageModalData,
    getConsultationModalData,
  } = useModalData()

  const fetchData = useFetchData()
  const endpoints = useApi()

  const {data: appointment, isInitialLoading: isAppointmentLoading} =
    useGetPatientAppointment({
      patientId: patient?.patientId ?? MISSING_ID,
      appointmentId: appointmentId ?? MISSING_ID,
    })
  const {data: summary, isInitialLoading: isSummaryLoading} =
    useGetPatientAppointmentSummary({
      patientId: patient?.patientId ?? MISSING_ID,
      appointmentId: appointmentId ?? MISSING_ID,
    })
  const {data: triage, isInitialLoading: isTriageLoading} =
    useGetAssignedQuestionnaire(
      {
        assignedQuestionnaireId:
          appointment?.questionnaireAssignmentId ?? MISSING_ID,
      },
      {enabled: !!appointment?.questionnaireAssignmentId},
    )
  const {data: healthJourney, isInitialLoading: isHealthJourneyLoading} =
    useGetPatientHealthJourney(
      {
        patientId: patient?.patientId ?? MISSING_ID,
        healthJourneyId: appointment?.linkedJourneyId ?? MISSING_ID,
      },
      {enabled: !!appointment?.linkedJourneyId},
    )

  const handleFileClick = useCallback(
    (fileId: string) => async () => {
      if (!appointmentId || !patient?.patientId) {
        return
      }

      const {url} = await fetchData(
        endpoints.patientAppointmentFile({
          patientId: patient.patientId,
          appointmentId,
          fileId,
        }),
        staticFileNormaliser,
      )

      window.open(url, '_blank', 'noopener,noreferrer')
    },
    [appointmentId, patient?.patientId, fetchData, endpoints],
  )

  const rawTimelineCardsData: TimelineCardData[] = useMemo(() => {
    if (!appointment) {
      return []
    }

    const {consultations, files} = appointment

    const fileItems: TimelineCardData[] = files.map((file) => ({
      date: file.uploadedAt,
      data: {
        title: t('Uploaded file'),
        subtitle: file.name,
        icon: file.contentType.startsWith('image') ? 'Image' : 'Document',
        note: formatDate(DateTime.DATE_SHORT, file.uploadedAt),
        onClick: handleFileClick(file.id),
      },
    }))

    const triageItems: TimelineCardData[] = triage?.dateCompleted
      ? [
          {
            date: triage.dateCompleted,
            data: {
              title: t('Triage completed'),
              subtitle: triage.questionnaire.title,
              icon: 'Questionnaires',
              note: formatDate(DateTime.DATE_SHORT, triage.dateCompleted),
              onClick: () =>
                setModalData(
                  getAppointmentTriageModalData(triage, appointment),
                ),
            },
          },
        ]
      : []

    const summaryItems: TimelineCardData[] = summary
      ? [
          {
            date: summary.createdAt,
            data: {
              title: t('Appointment summary'),
              subtitle: ellipsizeText(summary.summary, 100),
              note: formatDate(DateTime.DATE_SHORT, summary.createdAt),
              icon: 'MonitoringNote',
              onClick: () => {
                setModalFileEndpoint('appointmentSummary')
                setModalData(
                  getAppointmentSummaryModalData(summary, appointment),
                )
              },
            },
          },
        ]
      : []

    const consultationNoteItems: TimelineCardData[] = consultations.map(
      (consultation) => ({
        date: consultation.date,
        data: {
          title: t('Consultation note'),
          subtitle: consultation.note,
          note: formatDate(DateTime.DATE_SHORT, consultation.date),
          icon: 'Monitoring',
          onClick: () => {
            setModalFileEndpoint('consultation')
            setModalData(getConsultationModalData(consultation))
          },
        },
      }),
    )

    return [
      ...fileItems,
      ...triageItems,
      ...summaryItems,
      ...consultationNoteItems,
    ]
  }, [
    appointment,
    getAppointmentSummaryModalData,
    getAppointmentTriageModalData,
    getConsultationModalData,
    handleFileClick,
    summary,
    triage,
    t,
  ])

  const timelineCards = useMemo(
    () =>
      rawTimelineCardsData
        .slice()
        .sort((a, b) => (a.date > b.date ? -1 : 1))
        .map(({data}) => data),
    [rawTimelineCardsData],
  )

  const healthJourneyChipText = t('{{healthJourney}} journey', {
    healthJourney: translateEnumValue(
      EnumType.HealthJourneyType,
      healthJourney?.type as any,
    ),
  })

  const isLoading =
    isHealthJourneyLoading ||
    isAppointmentLoading ||
    isSummaryLoading ||
    isTriageLoading

  if (isLoading) {
    return <Loading />
  }

  return (
    <Container spacing={2} flex direction="column">
      <Heading size="h3" weight="medium">
        {t('Appointment detail')}
      </Heading>
      <Container spacing={1} flex>
        <Chip
          text={formatDate(DateTime.DATETIME_MED, appointment?.startTime, {
            zone: appointment?.facility?.timeZone,
          })}
        />
        <Chip text={appointment?.facility?.name || t('Unknown facility')} />
        {healthJourney && <Chip color="green" text={healthJourneyChipText} />}
      </Container>
      <Heading size="xxl" weight="medium">
        {t('Appointment feed')}
      </Heading>
      <Timeline cards={timelineCards} />
      <HealthRecordModal
        isVisible={!!modalData}
        onClose={() => setModalData(undefined)}
        data={modalData}
        fileEndpoint={modalFileEndpoint}
      />
    </Container>
  )
}
