import styled from 'styled-components'

import {HealthDataBadge} from './HealthDataBadge'

export const StyledHealthDataBadge = styled(HealthDataBadge)`
  width: 250px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
`

export const ChartBackground = styled.div`
  border-top-right-radius: ${({theme}) => theme.borderRadius.sm};
  border-bottom-right-radius: ${({theme}) => theme.borderRadius.sm};
  background-color: ${({theme}) => theme.colors.grey.grey20};
  flex-grow: 1;
  display: flex;
`

export const ChartContainer = styled.div`
  flex-grow: 1;
  position: relative;
`

export const DomainValuesContainer = styled.div`
  width: 70px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  padding-left: ${({theme}) => theme.spacing.sm};
  background-color: ${({theme}) => theme.colors.grey.grey20};
`
