import {AssertTypeFn} from './general'
import {
  AggregatedActivity,
  LatestActivity,
  LatestBloodPressure,
} from '../models'
import {
  assertArray,
  assertNumber,
  assertNumberOrNull,
  assertString,
} from '../utils'

export const aggregatedActivityNormaliser: AssertTypeFn<AggregatedActivity> = (
  obj,
) => ({
  activelyBurnedCalories: assertNumberOrNull(obj.activelyBurnedCalories),
  alcoholScore: assertNumberOrNull(obj.alcoholScore),
  bloodPressureDiastolic: assertNumberOrNull(obj.bloodPressureDiastolic),
  bloodPressureSystolic: assertNumberOrNull(obj.bloodPressureSystolic),
  bmi: assertNumberOrNull(obj.bmi),
  bodyFatPercentage: assertNumberOrNull(obj.bodyFatPercentage),
  cholesterolHdlRatio: assertNumberOrNull(obj.cholesterolHdlRatio),
  dietScore: assertNumberOrNull(obj.dietScore),
  heartAgeYears: assertNumberOrNull(obj.heartAgeYears),
  heightCm: assertNumberOrNull(obj.heightCm),
  moodScore: assertNumberOrNull(obj.moodScore),
  movementScore: assertNumberOrNull(obj.movementScore),
  period: assertString(obj.period),
  qualitySleepSeconds: assertNumberOrNull(obj.qualitySleepSeconds),
  restingHeartRate: assertNumberOrNull(obj.restingHeartRate),
  sleepScore: assertNumberOrNull(obj.sleepScore),
  steps: assertNumberOrNull(obj.steps),
  tobaccoScore: assertNumberOrNull(obj.tobaccoScore),
  activeMinutes: assertNumberOrNull(obj.activeMinutes),
  waistCircumferenceCm: assertNumberOrNull(obj.waistCircumferenceCm),
  waterConsumptionMl: assertNumberOrNull(obj.waterConsumptionMl),
  weightKg: assertNumberOrNull(obj.weightKg),
})

export const aggregatedActivitiesNormaliser: AssertTypeFn<
  AggregatedActivity[]
> = (arr) => assertArray(arr, aggregatedActivityNormaliser)

type GeneralLatestActivityItem = {
  date: string
  value: number
}

export const generalLatestActivityItemNormaliser: AssertTypeFn<
  GeneralLatestActivityItem
> = (obj) => ({
  date: assertString(obj.date),
  value: assertNumber(obj.value),
})

export const generalLatestActivityItemsNormaliser: AssertTypeFn<
  GeneralLatestActivityItem[]
> = (arr) => assertArray(arr, generalLatestActivityItemNormaliser)

export const latestBloodPressureNormaliser: AssertTypeFn<
  LatestBloodPressure
> = (obj) => ({
  date: assertString(obj.date),
  diastolic: assertNumber(obj.diastolic),
  systolic: assertNumber(obj.systolic),
})

export const latestActivityNormaliser: AssertTypeFn<LatestActivity> = (
  obj,
) => ({
  activelyBurnedCalories: generalLatestActivityItemsNormaliser(
    obj.activelyBurnedCalories,
  ),
  alcoholScore: generalLatestActivityItemsNormaliser(obj.alcoholScore),
  bloodPressure: assertArray(obj.bloodPressure, latestBloodPressureNormaliser),
  bmi: generalLatestActivityItemsNormaliser(obj.bmi),
  bodyFatPercentage: generalLatestActivityItemsNormaliser(
    obj.bodyFatPercentage,
  ),
  cholesterolHdlRatio: generalLatestActivityItemsNormaliser(
    obj.cholesterolHdlRatio,
  ),
  dietScore: generalLatestActivityItemsNormaliser(obj.dietScore),
  heartAgeYears: generalLatestActivityItemsNormaliser(obj.heartAgeYears),
  heightCm: generalLatestActivityItemsNormaliser(obj.heightCm),
  moodScore: generalLatestActivityItemsNormaliser(obj.moodScore),
  movementScore: generalLatestActivityItemsNormaliser(obj.movementScore),
  peakFlow: generalLatestActivityItemsNormaliser(obj.peakFlow),
  qualitySleepSeconds: generalLatestActivityItemsNormaliser(
    obj.qualitySleepSeconds,
  ),
  restingHeartRate: generalLatestActivityItemsNormaliser(obj.restingHeartRate),
  sleepScore: generalLatestActivityItemsNormaliser(obj.sleepScore),
  steps: generalLatestActivityItemsNormaliser(obj.steps),
  tobaccoScore: generalLatestActivityItemsNormaliser(obj.tobaccoScore),
  vo2Max: generalLatestActivityItemsNormaliser(obj.vo2Max),
  activeMinutes: generalLatestActivityItemsNormaliser(obj.activeMinutes),
  waistCircumferenceCm: generalLatestActivityItemsNormaliser(
    obj.waistCircumferenceCm,
  ),
  waterConsumptionMl: generalLatestActivityItemsNormaliser(
    obj.waterConsumptionMl,
  ),
  weightKg: generalLatestActivityItemsNormaliser(obj.weightKg),
})
