import * as React from 'react'

import {IconProps} from '.'

function Blood({color, size, isFilled}: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={isFilled ? color : 'none'}
      xmlns="http://www.w3.org/2000/svg">
      <ellipse
        cx="16.0017"
        cy="5.99749"
        rx="4.00167"
        ry="4.00167"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.0037 10.9996L18.8308 8.82668"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.0012 21.0038H18.0025C19.66 21.0038 21.0037 19.66 21.0037 18.0025V15.0013"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.99622 9.99916V6.99791C2.99622 5.34036 4.33992 3.99666 5.99747 3.99666H7.9983"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.92727 19.7194V19.7194C3.68652 18.3014 3.68652 16.1841 4.92727 14.766L7.07745 12.3087C7.2491 12.1125 7.49707 12 7.75773 12C8.01839 12 8.26636 12.1125 8.43801 12.3087L10.5882 14.766C11.829 16.184 11.829 18.3014 10.5882 19.7194V19.7194C9.87401 20.5356 8.84227 21.0037 7.75773 21.0037C6.67319 21.0037 5.64145 20.5356 4.92727 19.7194Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default Blood
