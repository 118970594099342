import {Address, PractitionersPatient} from '@common/models'
import {findPostalAddress, findPrimaryAddress} from '@common/utils'
import React, {useCallback, useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {useGetPatientAddress} from '~/api'
import {DataAccordionContent} from '~/components/general/accordion/DataAccordionContent'
import {Loading} from '~/components/general/loading/Loading'
import {Container} from '~/components/general/container/Container'
import {Typography} from '~/components/general/typography/Typography'

type AddressAccordionContentProps = {
  patient: PractitionersPatient
}

export const AddressAccordionContent: React.FC<
  AddressAccordionContentProps
> = ({patient}) => {
  const {t} = useTranslation()

  const {data, isInitialLoading} = useGetPatientAddress({
    patientId: patient.patientId,
  })

  const homeAddress = useMemo(() => findPrimaryAddress(data), [data])
  const postalAddress = useMemo(() => findPostalAddress(data), [data])

  const getAccordionData = useCallback(
    (address?: Address) => [
      {
        title: t('Address line 1'),
        value: address?.addressLine || '-',
      },
      {
        title: t('Address line 2'),
        value: address?.addressLine2 || '-',
      },
      {
        title: t('Town'),
        value: address?.town || '-',
      },
      {
        title: t('District / County'),
        value: address?.county || '-',
      },
      {
        title: t('Postcode'),
        value: address?.postCode || '-',
      },
      {
        title: t('Country'),
        value: address?.country || '-',
      },
    ],
    [t],
  )

  const homeAddressData = useMemo(
    () => getAccordionData(homeAddress),
    [getAccordionData, homeAddress],
  )
  const postalAddressData = useMemo(
    () => getAccordionData(postalAddress),
    [getAccordionData, postalAddress],
  )

  if (isInitialLoading) {
    return <Loading />
  }

  return (
    <Container direction="column" spacing={5}>
      <DataAccordionContent data={homeAddressData} />
      {!!postalAddress && (
        <Container direction="column" spacing={1}>
          <Typography weight="medium">
            {t('Address for correspondence')}
          </Typography>
          <DataAccordionContent data={postalAddressData} />
        </Container>
      )}
    </Container>
  )
}
