import {PermissionCategory} from '@common/models'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {PatientDataAccordion} from '../common/patientDataAccordion/PatientDataAccordion'
import {
  ViewAllLink,
  ViewAllLinkWrapper,
} from './BasicHealthInformationTimeline.styled'

import {IconName} from '~/components/general/icon/Icon'
import {
  Timeline,
  TimelineCardWithSubCardProps,
} from '~/components/general/timeline/Timeline'
import {Typography} from '~/components/general/typography/Typography'
import {theme} from '~/styles/theme'

export type BasicHealthAccordionProps = {
  title: string
  iconName: IconName
  cards: TimelineCardWithSubCardProps[]
  permission: PermissionCategory
  showMoreLink?: string
  totalCount?: number
}

export const BasicHealthAccordion: React.FC<BasicHealthAccordionProps> = ({
  cards,
  iconName,
  permission,
  showMoreLink,
  title,
  totalCount,
}) => {
  const {t} = useTranslation()

  const accordionRightText = cards.length ? undefined : t('No data')

  const linkText =
    totalCount == null || cards.length === totalCount
      ? t('View Details')
      : t('View All ({{number}})', {
          number: totalCount,
        })

  return (
    <PatientDataAccordion
      disabled={!cards.length}
      rightText={accordionRightText}
      permission={permission}
      iconName={iconName}
      title={title}
      key={title}>
      <Timeline cards={cards} direction="column" />
      {!!showMoreLink && (
        <ViewAllLinkWrapper>
          <ViewAllLink to={showMoreLink}>
            <Typography color={theme.colors.primary.blue900} weight="medium">
              {linkText}
            </Typography>
          </ViewAllLink>
        </ViewAllLinkWrapper>
      )}
    </PatientDataAccordion>
  )
}
