import {
  DiagnosedCondition,
  HealthCategory,
  MedicalTest,
  Medication,
  PatientAllergy,
  PatientCholesterol,
  PatientVaccination,
  Surgery,
} from './medical'
import {Appointment, Consultation} from './appointment'
import {LifeMilestone} from './personal'

type CommonTimelineItemProps = {
  date?: string
}
export {TimelineItemType} from '@generated/models'

export type AllergyTimelineItem = {
  type: HealthCategory.ALLERGY
  item: PatientAllergy
} & CommonTimelineItemProps

export type MedicalTestTimelineItem = {
  type: HealthCategory.MEDICAL_TEST
  item: MedicalTest
} & CommonTimelineItemProps

export type CholesterolTimelineItem = {
  type: HealthCategory.CHOLESTEROL
  item: PatientCholesterol
} & CommonTimelineItemProps

export type ConsultationTimelineItem = {
  type: HealthCategory.CONSULTATION
  item: Consultation
} & CommonTimelineItemProps

export type DiagnosedConditionTimelineItem = {
  type: HealthCategory.DIAGNOSED_CONDITION
  item: DiagnosedCondition
} & CommonTimelineItemProps

export type MedicationTimelineItem = {
  type: HealthCategory.MEDICATION
  item: Medication
} & CommonTimelineItemProps

export type SurgeryTimelineItem = {
  type: HealthCategory.SURGERY
  item: Surgery
} & CommonTimelineItemProps

export type VaccinationTimelineItem = {
  type: HealthCategory.VACCINATION
  item: PatientVaccination
} & CommonTimelineItemProps

export type AppointmentTimelineItem = {
  type: HealthCategory.APPOINTMENT
  item: Appointment
}

export type LifeMilestoneTimelineItem = {
  type: HealthCategory.LIFE_MILESTONE
  item: LifeMilestone
}

export type PatientTimelineItem =
  | AllergyTimelineItem
  | ConsultationTimelineItem
  | CholesterolTimelineItem
  | DiagnosedConditionTimelineItem
  | MedicalTestTimelineItem
  | MedicationTimelineItem
  | SurgeryTimelineItem
  | VaccinationTimelineItem
  | AppointmentTimelineItem
  | LifeMilestoneTimelineItem
