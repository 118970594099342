/* tslint:disable */
/* eslint-disable */
/**
 * Cushla API
 * REST API for Cushla
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */

export enum ActivityFieldDto {
  WATER_CONSUMPTION_ML = 'WATER_CONSUMPTION_ML',
  WAIST_CIRCUMFERENCE_CM = 'WAIST_CIRCUMFERENCE_CM',
  BODY_FAT_PERCENTAGE = 'BODY_FAT_PERCENTAGE',
  WEIGHT_KG = 'WEIGHT_KG',
  HEIGHT_CM = 'HEIGHT_CM',
  BMI = 'BMI',
  STEPS = 'STEPS',
  ACTIVELY_BURNED_CALORIES = 'ACTIVELY_BURNED_CALORIES',
  ACTIVE_MINUTES = 'ACTIVE_MINUTES',
  RESTING_HEART_RATE = 'RESTING_HEART_RATE',
  BLOOD_PRESSURE = 'BLOOD_PRESSURE',
  CHOLESTEROL_HDL_RATIO = 'CHOLESTEROL_HDL_RATIO',
  QUALITY_SLEEP_SECONDS = 'QUALITY_SLEEP_SECONDS',
  SLEEP_SCORE = 'SLEEP_SCORE',
  ALCOHOL_SCORE = 'ALCOHOL_SCORE',
  TOBACCO_SCORE = 'TOBACCO_SCORE',
  DIET_SCORE = 'DIET_SCORE',
  MOOD_SCORE = 'MOOD_SCORE',
  HEART_AGE_YEARS = 'HEART_AGE_YEARS',
  MOVEMENT_SCORE = 'MOVEMENT_SCORE',
  VO2_MAX = 'VO2_MAX',
  PEAK_FLOW = 'PEAK_FLOW',
}
