/* tslint:disable */
/* eslint-disable */
/**
 * Cushla API
 * REST API for Cushla
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */

export enum Specialization {
  GP = 'GP',
  DENTIST = 'DENTIST',
  PHYSIOTHERAPIST = 'PHYSIOTHERAPIST',
  HOSPITAL = 'HOSPITAL',
  CONSULTANT = 'CONSULTANT',
  PHYSICAL_THERAPY = 'PHYSICAL_THERAPY',
  OPTICIAN = 'OPTICIAN',
  PELVIC_HEALTH = 'PELVIC_HEALTH',
}
