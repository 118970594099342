import * as React from 'react'

import {IconProps} from '.'

function HeartBeat({color, size, isFilled}: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={isFilled ? color : 'none'}
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 4.925C12.775 4.018 14.116 3 16.158 3C19.729 3 22.125 6.352 22.125 9.474C22.125 16 14 21 12 21C10 21 1.875 16 1.875 9.474C1.875 6.352 4.271 3 7.842 3C9.884 3 11.225 4.018 12 4.925Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 11.5H9.222L10.889 9L13.111 14L14.778 11.5H17"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default HeartBeat
