import {useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {DateTime} from 'luxon'

import {
  AggregatedActivity,
  BarChartData,
  BasicPatientData,
  ChartData,
  HealthDataResolution,
  HealthDataType,
} from '../models'
import {getResolutionDateDiff, resolutionUnitMap} from './dates'
import {getHealthDataGrade} from './grades'
import {convertHealthDataValue} from './healthData'

export const useResolutionOptions = () => {
  const {t} = useTranslation()

  return useMemo(
    () => [
      {
        value: HealthDataResolution.MONTH,
        label: t('Month'),
        shortLabel: t('M', {context: 'month'}),
      },
      {
        value: HealthDataResolution.WEEK,
        label: t('Week'),
        shortLabel: t('W', {context: 'week'}),
      },
      {
        value: HealthDataResolution.DAY,
        label: t('Day'),
        shortLabel: t('D', {context: 'day'}),
      },
    ],
    [t],
  )
}

export const getChartTickValues: (
  resolution: HealthDataResolution,
  startDate: DateTime,
  dataPointCount: number,
) => Date[] = (resolution, startDate, dataPointCount) =>
  [...Array(dataPointCount - 1)].reduce(
    (arr) => {
      const tick = DateTime.fromJSDate(arr[arr.length - 1])
        .plus(getResolutionDateDiff(resolution))
        .toJSDate()

      arr.push(tick)
      return arr
    },
    [startDate.startOf(resolutionUnitMap[resolution]).toJSDate()],
  )

const healthDataPropertyNameMap: Record<
  HealthDataType,
  keyof Omit<AggregatedActivity, 'period'>
> = {
  [HealthDataType.ACTIVELY_BURNED_CALORIES]: 'activelyBurnedCalories',
  [HealthDataType.ALCOHOL_SCORE]: 'alcoholScore',
  [HealthDataType.BLOOD_PRESSURE]: 'bloodPressureSystolic',
  [HealthDataType.BMI]: 'bmi',
  [HealthDataType.DIET_SCORE]: 'dietScore',
  [HealthDataType.BODY_FAT_PERCENTAGE]: 'bodyFatPercentage',
  [HealthDataType.CHOLESTEROL_HDL_RATIO]: 'cholesterolHdlRatio',
  [HealthDataType.HEART_AGE_YEARS]: 'heartAgeYears',
  [HealthDataType.HEIGHT_CM]: 'heightCm',
  [HealthDataType.MOOD_SCORE]: 'moodScore',
  [HealthDataType.MOVEMENT_SCORE]: 'movementScore',
  [HealthDataType.PEAK_FLOW]: 'peakFlow',
  [HealthDataType.RESTING_HEART_RATE]: 'restingHeartRate',
  [HealthDataType.SLEEP_SCORE]: 'sleepScore',
  [HealthDataType.QUALITY_SLEEP_SECONDS]: 'qualitySleepSeconds',
  [HealthDataType.STEPS]: 'steps',
  [HealthDataType.TOBACCO_SCORE]: 'tobaccoScore',
  [HealthDataType.VO2_MAX]: 'vo2Max',
  [HealthDataType.ACTIVE_MINUTES]: 'activeMinutes',
  [HealthDataType.WAIST_CIRCUMFERENCE_CM]: 'waistCircumferenceCm',
  [HealthDataType.WATER_CONSUMPTION_ML]: 'waterConsumptionMl',
  [HealthDataType.WEIGHT_KG]: 'weightKg',
}

export const getHealthDataSelector =
  (healthDataType: HealthDataType) => (data: AggregatedActivity) => {
    const key = healthDataPropertyNameMap[healthDataType]
    return data[key]
  }

export const formatChartData = (
  data: AggregatedActivity[],
  healthDataType: HealthDataType,
  user?: BasicPatientData,
) => {
  const dataSelector = getHealthDataSelector(healthDataType)
  const bmiDataSelector = getHealthDataSelector(HealthDataType.BMI)

  const getGrade = (record: AggregatedActivity) =>
    healthDataType === HealthDataType.WEIGHT_KG
      ? getHealthDataGrade(HealthDataType.BMI, bmiDataSelector(record), user)
      : getHealthDataGrade(healthDataType, dataSelector(record), user)

  return data
    .map((record) => ({
      y: convertHealthDataValue(dataSelector(record), healthDataType),
      date: DateTime.fromISO(record.period).toJSDate(),
      grade: getGrade(record),
    }))
    .filter((item) => item.y != null) as ChartData
}

export const formatBloodPressureChartData = (
  data: AggregatedActivity[],
  user?: BasicPatientData,
) =>
  data
    .map((record) => ({
      date: DateTime.fromISO(record.period).toJSDate(),
      y: record.bloodPressureSystolic,
      y0: record.bloodPressureDiastolic,
      grade: getHealthDataGrade(
        HealthDataType.BLOOD_PRESSURE,
        record.bloodPressureSystolic,
        user,
      ),
    }))
    .filter((item) => item.y != null && item.y0 != null) as BarChartData
