import styled from 'styled-components'

export const StyledQuestionnairesColumn = styled.div`
  max-width: 337px;
  width: 100%;
  padding: ${({theme}) => `${theme.spacing.md} ${theme.spacing.lg}`};
  border-right: 1px solid ${({theme}) => theme.colors.grey.grey40};

  & > * {
    border-bottom: 1px solid ${({theme}) => theme.colors.grey.grey50};
  }

  & > *:last-child {
    border-bottom: none;
  }
`

export const QuestionnairesColumnItem = styled.div`
  padding: ${({theme}) => theme.spacing.sm} 0;

  & > * {
    margin-bottom: ${({theme}) => theme.spacing.xxs};
  }

  & > *:last-child {
    margin-bottom: 0;
  }
`
