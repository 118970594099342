import React from 'react'
import {useTranslation} from 'react-i18next'

import {
  MaterialsSideBarContainer,
  MaterialsSideBarContent,
  MaterialsTopBar,
} from './MaterialsSideBar.styled'

import {TextArea} from '~/components/general/textArea/TextArea'

type MaterialPreviewInputProps = {
  value: string
  onChange: React.ChangeEventHandler<HTMLTextAreaElement>
}

export const MaterialPreviewInput: React.FC<MaterialPreviewInputProps> = ({
  onChange,
  value,
}) => {
  const {t} = useTranslation()

  return (
    <MaterialsSideBarContainer>
      <MaterialsTopBar>
        <h4>{t('Input file')}</h4>
      </MaterialsTopBar>
      <MaterialsSideBarContent>
        <TextArea
          onChange={onChange}
          value={value}
          fontSize="sm"
          height="100%"
          placeholder={t('Enter HTML here')}
        />
      </MaterialsSideBarContent>
    </MaterialsSideBarContainer>
  )
}
