import {useTranslation} from 'react-i18next'
import React, {useContext, useEffect, useState} from 'react'
import {Outlet, useParams, NavLink} from 'react-router-dom'
import {MISSING_ID} from '@common/utils'

import {QuestionnairesContext} from '../QuestionnairesContext'

import {Typography} from '~/components/general/typography/Typography'
import {Layout} from '~/components/layout/Layout'
import {
  QuestionnairesHeader,
  StyledHeaderText,
} from '~/components/questionnaires/Questionnaire.styled'
import {Container} from '~/components/general/container/Container'
import {Button} from '~/components/general/button/Button'
import {Icon} from '~/components/general/icon/Icon'
import {theme} from '~/styles/theme'
import {ShareQuestionnaireModal} from '~/components/questionnaires/shareModal/ShareQuestionnaireModal'
import {useGetQuestionnaire} from '~/api'
import {Loading} from '~/components/general/loading/Loading'

export const Questionnaire: React.FC = () => {
  const {questionnaireId} = useParams()
  const {t} = useTranslation()
  const {questionnaire, setQuestionnaire} = useContext(QuestionnairesContext)

  const [isShareModalVisible, setIsShareModalVisible] = useState(false)

  const {data: questionnaireData, isInitialLoading} = useGetQuestionnaire({
    id: questionnaireId ?? MISSING_ID,
  })

  useEffect(() => {
    setQuestionnaire(questionnaireData)
  }, [questionnaireData, setQuestionnaire])

  const isQuestionnairesContextUpdated =
    !!questionnaireId && questionnaire?.id === questionnaireId

  if (isInitialLoading) {
    return (
      <Layout questionnaires>
        <Loading />
      </Layout>
    )
  }

  if (!isQuestionnairesContextUpdated) {
    return (
      <Layout questionnaires>
        <Typography>{t('Missing questionnaire data.')}</Typography>
      </Layout>
    )
  }

  return (
    <Layout
      questionnaires
      navMenu={
        <Button
          onClick={() => setIsShareModalVisible(true)}
          startIcon={
            <Icon name="Send" color={theme.colors.primary.blue50} size="sm" />
          }>
          {t('Send to patient')}
        </Button>
      }>
      <QuestionnairesHeader>
        <Container flex align="center" spacing={5}>
          <NavLink to="details">
            <StyledHeaderText size="lg" color={theme.colors.grey.main}>
              {t('Details')}
            </StyledHeaderText>
          </NavLink>
          <NavLink to="sent">
            <StyledHeaderText size="lg" color={theme.colors.grey.main}>
              {t('Sent questionnaires')}
            </StyledHeaderText>
          </NavLink>
        </Container>
      </QuestionnairesHeader>
      <Outlet />
      <ShareQuestionnaireModal
        isVisible={isShareModalVisible}
        onClose={() => setIsShareModalVisible(false)}
      />
    </Layout>
  )
}
