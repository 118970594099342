import * as React from 'react'

import {IconProps} from '.'

function Toast({color, size, isFilled}: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill={isFilled ? color : 'none'}
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.9607 5.99147C14.6017 5.7595 15.3169 6.03102 15.6404 6.6326L17.746 10.5413C18.6161 12.1556 17.8497 14.1671 16.1258 14.7933L15.9409 14.2841L15.7561 13.775C16.8587 13.3744 17.3488 12.0876 16.7924 11.0553L14.6865 7.14603C14.6184 7.01935 14.4669 6.96052 14.3296 7.01006L12.4192 7.70409C12.2815 7.75405 12.2018 7.89914 12.2342 8.04282L13.2223 12.3928C13.48 13.5218 14.6682 14.1697 15.7561 13.775C15.7562 13.7749 15.7561 13.775 15.7561 13.775L15.9409 14.2841L16.1258 14.7933C14.4254 15.4102 12.5685 14.3979 12.1661 12.6338L11.1777 8.28246C11.0252 7.60794 11.3971 6.9226 12.0493 6.68586L13.9607 5.99147Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.8422 16.2893C18.9448 16.5703 18.8001 16.8812 18.5191 16.9838L15.7108 18.0088C15.4298 18.1114 15.1188 17.9667 15.0162 17.6857C14.9137 17.4047 15.0583 17.0937 15.3393 16.9911L18.1477 15.9661C18.4287 15.8636 18.7397 16.0082 18.8422 16.2893Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7001 10.5083C11.7001 10.2092 11.9426 9.96667 12.2417 9.96667H17.1084C17.4075 9.96667 17.6501 10.2092 17.6501 10.5083C17.6501 10.8075 17.4075 11.05 17.1084 11.05H12.2417C11.9426 11.05 11.7001 10.8075 11.7001 10.5083Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.7569 13.7742C16.0381 13.6721 16.3488 13.8173 16.4509 14.0985L17.4342 16.8068C17.5363 17.088 17.3911 17.3987 17.1099 17.5008C16.8287 17.6029 16.518 17.4577 16.4159 17.1765L15.4326 14.4682C15.3305 14.187 15.4757 13.8763 15.7569 13.7742Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.67046 7.01006C5.53318 6.96052 5.38188 7.019 5.31376 7.14568L3.20774 11.0552C2.6513 12.0875 3.14134 13.3744 4.24394 13.775M5.67046 7.01006C5.67038 7.01003 5.67054 7.01009 5.67046 7.01006V7.01006ZM4.35967 6.63254C4.68318 6.031 5.39838 5.75951 6.03935 5.99147L7.95063 6.68579C7.95069 6.68582 7.95057 6.68577 7.95063 6.68579C8.60275 6.92253 8.97502 7.60739 8.82253 8.28191L7.83407 12.6333C7.43165 14.3975 5.5748 15.4103 3.87445 14.7933C2.15055 14.1672 1.3839 12.1557 2.25399 10.5414C2.25401 10.5414 2.25396 10.5415 2.25399 10.5414L4.35967 6.63254C4.35966 6.63256 4.35968 6.63252 4.35967 6.63254ZM4.24394 13.775C5.33176 14.1695 6.51995 13.5221 6.77768 12.3932C6.77771 12.3931 6.77765 12.3933 6.77768 12.3932L7.76586 8.04303C7.76588 8.04294 7.76584 8.04313 7.76586 8.04303C7.79818 7.89938 7.71876 7.75411 7.5811 7.70415L5.67046 7.01006"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.15789 16.2893C1.26046 16.0082 1.57142 15.8636 1.85244 15.9661L4.66077 16.9911C4.94179 17.0937 5.08646 17.4047 4.98389 17.6857C4.88132 17.9667 4.57036 18.1114 4.28934 18.0088L1.48101 16.9838C1.19998 16.8812 1.05532 16.5703 1.15789 16.2893Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.34174 10.5083C2.34174 10.2092 2.58426 9.96667 2.88341 9.96667H7.75841C8.05756 9.96667 8.30008 10.2092 8.30008 10.5083C8.30008 10.8075 8.05756 11.05 7.75841 11.05H2.88341C2.58426 11.05 2.34174 10.8075 2.34174 10.5083Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.2432 13.7742C4.5244 13.8763 4.66958 14.187 4.56749 14.4682L3.58416 17.1765C3.48206 17.4577 3.17134 17.6029 2.89015 17.5008C2.60896 17.3987 2.46377 17.088 2.56586 16.8068L3.5492 14.0985C3.65129 13.8173 3.96201 13.6721 4.2432 13.7742Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.7164 2.95034C13.9279 3.16187 13.9279 3.50484 13.7164 3.71637L12.883 4.5497C12.6715 4.76124 12.3285 4.76124 12.117 4.5497C11.9055 4.33817 11.9055 3.9952 12.117 3.78367L12.9503 2.95034C13.1619 2.7388 13.5048 2.7388 13.7164 2.95034Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.28366 2.95034C6.49519 2.7388 6.83816 2.7388 7.04969 2.95034L7.88302 3.78367C8.09456 3.9952 8.09456 4.33817 7.88302 4.5497C7.67149 4.76124 7.32853 4.76124 7.11699 4.5497L6.28366 3.71637C6.07212 3.50484 6.07212 3.16187 6.28366 2.95034Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 1.95837C10.2992 1.95837 10.5417 2.20089 10.5417 2.50004V3.33337C10.5417 3.63253 10.2992 3.87504 10 3.87504C9.70086 3.87504 9.45834 3.63253 9.45834 3.33337V2.50004C9.45834 2.20089 9.70086 1.95837 10 1.95837Z"
        fill={color}
      />
    </svg>
  )
}

export default Toast
