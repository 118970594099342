import {useTranslation} from 'react-i18next'
import React, {useMemo} from 'react'
import {useOutletContext} from 'react-router-dom'
import {AssignedQuestionnaire} from '@common/models'
import {EnumType, useEnumTranslations} from '@common/utils'

import {QuestionnairesColumn} from '~/components/questionnaires/questionnairesColumn/QuestionnairesColumn'
import {QuestionnairePreview} from '~/components/questionnaires/questionnairePreview/QuestionnairePreview'
import {QuestionnairesContent} from '~/components/questionnaires/Questionnaire.styled'
import {getTableDateValue} from '~/utils'

export const PatientQuestionnaireResponse: React.FC = () => {
  const {t} = useTranslation()
  const data = useOutletContext<AssignedQuestionnaire>()
  const {translateEnumValue} = useEnumTranslations()

  const colItems = useMemo(
    () => [
      {
        id: '0',
        title: t('Title'),
        description: data?.title || '-',
      },
      {
        id: '1',
        title: t('Type'),
        description:
          translateEnumValue(EnumType.QuestionnaireType, data?.type) || '-',
      },
      {
        id: '2',
        title: t('Answered on'),
        description: getTableDateValue(data?.dateCompleted),
      },
      {
        id: '3',
        title: t('Sent on'),
        description: getTableDateValue(data?.dateReceived),
      },
    ],
    [t, translateEnumValue, data],
  )

  return (
    <QuestionnairesContent>
      <QuestionnairesColumn items={colItems} />
      <QuestionnairePreview
        answers={data.answers}
        questions={data.questionnaire.questions}
        title={t("Patient's responses")}
      />
    </QuestionnairesContent>
  )
}
