/* tslint:disable */
/* eslint-disable */
/**
 * Cushla API
 * REST API for Cushla
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */

export enum VaginalBirthPerinealTraumaDto {
  NO = 'NO',
  UNKNOWN_DEGREE = 'UNKNOWN_DEGREE',
  FIRST_DEGREE = 'FIRST_DEGREE',
  SECOND_DEGREE = 'SECOND_DEGREE',
  THIRD_DEGREE = 'THIRD_DEGREE',
  FOURTH_DEGREE = 'FOURTH_DEGREE',
  EPISIOTOMY = 'EPISIOTOMY',
}
