import React from 'react'

import {
  StyledMaterials,
  MaterialsTitle,
  MaterialsContent,
} from './Materials.styled'

type MaterialsProps = {
  children: React.ReactNode
  title?: string
}

export const Materials: React.FC<MaterialsProps> = ({title, children}) => (
  <StyledMaterials>
    {!!title && <MaterialsTitle>{title}</MaterialsTitle>}
    <MaterialsContent>{children}</MaterialsContent>
  </StyledMaterials>
)
