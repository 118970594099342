import {endpointKeys} from '@common/api'
import {
  facilityDocumentTypesNormaliser,
  facilityNormaliser,
  getPagedResponseNormaliser,
} from '@common/normalisers'
import {Facility, FacilityDocumentType, PagedResponse} from '@common/models'

import {useWebInfiniteGet, useApi, useWebGet} from './helpers'

export const useGetFacilities = () =>
  useWebInfiniteGet<PagedResponse<Facility>>(
    [endpointKeys.facilities],
    useApi().facilities({}),
    getPagedResponseNormaliser(facilityNormaliser),
  )

export const useGetFacilityDocumentTypes = () =>
  useWebGet<FacilityDocumentType[]>(
    [endpointKeys.facilityDocumentTypes, {}],
    useApi().facilityDocumentTypes(),
    facilityDocumentTypesNormaliser,
  )
