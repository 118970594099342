import {endpointKeys} from '@common/api'
import {
  cholesterolNormaliser,
  diagnosedConditionNormaliser,
  getPagedResponseNormaliser,
  medicalTestNormaliser,
  medicationNormaliser,
  patientAllergyNormaliser,
  surgeryNormaliser,
  vaccinationNormaliser,
} from '@common/normalisers'
import {
  DiagnosedCondition,
  MedicalPermissionSubcategory,
  MedicalTest,
  Medication,
  PatientAllergy,
  PatientCholesterol,
  PatientVaccination,
  RecordType,
  Surgery,
} from '@common/models'
import {useTranslation} from 'react-i18next'
import React from 'react'
import {
  EnumType,
  formatExactOrEstimatedDate,
  formatLocaleNumber,
  formatSurgeryTitle,
  useEnumTranslations,
} from '@common/utils'

import {
  HealthCategoryPage,
  HealthCategoryPageProps,
} from './HealthCategoriesPage'

import {useApi} from '~/api'
import {ONE_THIRD_IN_PERCENT} from '~/components/general/table/SimpleTableHeader'
import {useModalData} from '~/utils'

export enum HealthCategoriesPagesEnum {
  ALLERGIES = 'allergies',
  CHOLESTEROL = 'cholesterol',
  CONDITIONS = 'conditions',
  FAMILY_HISTORY = 'family-history',
  MEDICAL_TESTS = 'tests',
  MEDICATIONS = 'medications',
  OBSTETRICS = 'obstetrics',
  SURGERIES = 'surgeries',
  VACCINATIONS = 'vaccinations',
}

const useHealthCategoryTablePagesData = () => {
  const {t} = useTranslation()
  const api = useApi()
  const {translateEnumValue} = useEnumTranslations()
  const {
    getAllergyModalData,
    getCholesterolModalData,
    getConditionModalData,
    getMedicalTestModalData,
    getMedicationModalData,
    getSurgeryModalData,
    getVaccinationModalData,
  } = useModalData()

  const ROWS_PER_PAGE = 10

  const allergiesProps: HealthCategoryPageProps<PatientAllergy> = {
    endpointKey: endpointKeys.patientAllergies,
    getUrl: (params) =>
      api.patientAllergies({...params, perPage: ROWS_PER_PAGE}),
    header: {
      columns: [
        {title: t('Allergy'), columnWidthPercent: ONE_THIRD_IN_PERCENT},
        {title: t('Treatment'), columnWidthPercent: ONE_THIRD_IN_PERCENT},
        {title: t('Date'), columnWidthPercent: ONE_THIRD_IN_PERCENT},
      ],
    },
    getRows: (data) =>
      data.records.map((record) => ({
        columns: [
          record.allergen.name,
          record.treatment ?? '-',
          formatExactOrEstimatedDate(record.date) || '-',
        ],
      })),
    getModalData: (index, data) => getAllergyModalData(data.records[index]),
    normalizer: getPagedResponseNormaliser(patientAllergyNormaliser),
    permission: MedicalPermissionSubcategory.ALLERGY,
  }

  const conditionsProps: HealthCategoryPageProps<DiagnosedCondition> = {
    endpointKey: endpointKeys.diagnosedConditions,
    getUrl: (params) =>
      api.diagnosedConditions({...params, perPage: ROWS_PER_PAGE}),
    header: {
      columns: [
        {title: t('Condition'), columnWidthPercent: 35},
        {
          title: t('Date of diagnosis'),
          columnWidthPercent: 20,
        },
        {title: t('Status'), columnWidthPercent: 15},
        {title: t('Facility'), columnWidthPercent: 30},
      ],
    },
    getRows: (data) =>
      data.records.map((record) => ({
        columns: [
          record.condition || record.customName || '-',
          formatExactOrEstimatedDate(record.date) || '-',
          record.endDate ? t('Inactive') : t('Active'),
          record.facility?.name || '-',
        ],
      })),
    getModalData: (index, data) => getConditionModalData(data.records[index]),
    normalizer: getPagedResponseNormaliser(diagnosedConditionNormaliser),
    permission: MedicalPermissionSubcategory.DIAGNOSED_CONDITIONS,
  }

  const cholesterolProps: HealthCategoryPageProps<PatientCholesterol> = {
    endpointKey: endpointKeys.patientCholesterolRecords,
    getUrl: (params) =>
      api.patientCholesterolRecords({...params, perPage: ROWS_PER_PAGE}),
    header: {
      columns: [
        {title: t('Date'), columnWidthPercent: 20},
        {title: t('Total'), columnWidthPercent: 10},
        {title: t('HDL'), columnWidthPercent: 10},
        {title: t('LDL'), columnWidthPercent: 10},
        {title: t('HDL ratio'), columnWidthPercent: 15},
        {title: t('Facility'), columnWidthPercent: 35},
      ],
    },
    getRows: (data) =>
      data.records.map((record) => ({
        columns: [
          formatExactOrEstimatedDate(record.date) || '-',
          formatLocaleNumber(record.totalAmount, 1) || '-',
          formatLocaleNumber(record.hdl, 1) || '-',
          formatLocaleNumber(record.ldl, 1) || '-',
          formatLocaleNumber(record.hdlRatio, 1) || '-',
          record.facility?.name || '-',
        ],
      })),
    getModalData: (index, data) => getCholesterolModalData(data.records[index]),
    normalizer: getPagedResponseNormaliser(cholesterolNormaliser),
    permission: MedicalPermissionSubcategory.CHOLESTEROL,
  }

  const medicalTestsProps: HealthCategoryPageProps<MedicalTest> = {
    endpointKey: endpointKeys.patientMedicalTests,
    getUrl: (params) =>
      api.patientMedicalTests({...params, perPage: ROWS_PER_PAGE}),
    header: {
      columns: [
        {title: t('Type'), columnWidthPercent: ONE_THIRD_IN_PERCENT},
        {title: t('Date'), columnWidthPercent: ONE_THIRD_IN_PERCENT},
        {title: t('Facility'), columnWidthPercent: ONE_THIRD_IN_PERCENT},
      ],
    },
    getRows: (data) =>
      data.records.map((record) => ({
        hasParsedData: record.recordType === RecordType.PARSED,
        columns: [
          record.recordType === RecordType.PARSED
            ? translateEnumValue(EnumType.ParsedMedicalTestType, record.type)
            : record.type.value.label || '-',
          formatExactOrEstimatedDate(record.date),
          record.facility?.name ?? '-',
        ],
      })),
    getModalData: (index, data) => getMedicalTestModalData(data.records[index]),
    normalizer: getPagedResponseNormaliser(medicalTestNormaliser),
    permission: MedicalPermissionSubcategory.MEDICAL_TEST,
  }

  const medicationsProps: HealthCategoryPageProps<Medication> = {
    endpointKey: endpointKeys.patientMedications,
    getUrl: (params) =>
      api.patientMedications({...params, perPage: ROWS_PER_PAGE}),
    header: {
      columns: [
        {title: t('Medications'), columnWidthPercent: 60},
        {title: t('Start date'), columnWidthPercent: 20},
        {title: t('End date'), columnWidthPercent: 20},
      ],
    },
    getRows: (data) =>
      data.records.map((record) => {
        const isParsed = record.recordType === RecordType.PARSED

        return {
          hasParsedData: isParsed,
          columns: isParsed
            ? [
                record.drugs.join(', ') ?? '-',
                formatExactOrEstimatedDate(record.date) || '-',
                '-',
              ]
            : [
                record.drug?.tradeName || record.name || '-',
                formatExactOrEstimatedDate(record.startDate) || '-',
                formatExactOrEstimatedDate(record.endDate) ||
                  t('Currently taking'),
              ],
        }
      }),
    getModalData: (index, data) => getMedicationModalData(data.records[index]),
    normalizer: getPagedResponseNormaliser(medicationNormaliser),
    permission: MedicalPermissionSubcategory.MEDICATION,
  }

  const surgeriesProps: HealthCategoryPageProps<Surgery> = {
    endpointKey: endpointKeys.patientSurgeries,
    getUrl: (params) =>
      api.patientSurgeries({...params, perPage: ROWS_PER_PAGE}),
    header: {
      columns: [
        {title: t('Procedure'), columnWidthPercent: 50},
        {title: t('Date'), columnWidthPercent: 20},
        {title: t('Facility'), columnWidthPercent: 30},
      ],
    },
    getRows: (data) =>
      data.records.map((record) => ({
        hasParsedData: record.recordType === RecordType.PARSED,
        columns: [
          formatSurgeryTitle(record, t) || '-',
          formatExactOrEstimatedDate(record.date) || '-',
          record.facility?.name ?? '-',
        ],
      })),
    getModalData: (index, data) => getSurgeryModalData(data.records[index]),
    normalizer: getPagedResponseNormaliser(surgeryNormaliser),
    permission: MedicalPermissionSubcategory.SURGERY,
  }

  const vaccinationsProps: HealthCategoryPageProps<PatientVaccination> = {
    endpointKey: endpointKeys.patientVaccinations,
    getUrl: (params) =>
      api.patientVaccinations({...params, perPage: ROWS_PER_PAGE}),
    header: {
      columns: [
        {title: t('Name'), columnWidthPercent: 30},
        {title: t('Manufacturer'), columnWidthPercent: 30},
        {title: t('Expiry date'), columnWidthPercent: 20},
        {title: t('Date of vaccination'), columnWidthPercent: 20},
      ],
    },
    getRows: (data) =>
      data.records.map((record) => {
        const isParsed = record.recordType === RecordType.PARSED

        return {
          hasParsedData: isParsed,
          columns: isParsed
            ? [
                record.shotName ?? '-',
                record.manufacturer ?? '-',
                formatExactOrEstimatedDate(record.expiry) || '-',
                formatExactOrEstimatedDate(record.date) || '-',
              ]
            : [
                record.vaccine.name,
                '-',
                formatExactOrEstimatedDate(record.expiry) || '-',
                formatExactOrEstimatedDate(record.date) || '-',
              ],
        }
      }),
    getModalData: (index, data) => getVaccinationModalData(data.records[index]),
    normalizer: getPagedResponseNormaliser(vaccinationNormaliser),
    permission: MedicalPermissionSubcategory.VACCINATION,
  }

  return {
    [HealthCategoriesPagesEnum.ALLERGIES]: allergiesProps,
    [HealthCategoriesPagesEnum.MEDICAL_TESTS]: medicalTestsProps,
    [HealthCategoriesPagesEnum.CONDITIONS]: conditionsProps,
    [HealthCategoriesPagesEnum.CHOLESTEROL]: cholesterolProps,
    [HealthCategoriesPagesEnum.MEDICATIONS]: medicationsProps,
    [HealthCategoriesPagesEnum.SURGERIES]: surgeriesProps,
    [HealthCategoriesPagesEnum.VACCINATIONS]: vaccinationsProps,
  }
}

export const AllergiesPage = () => {
  const pages = useHealthCategoryTablePagesData()
  return <HealthCategoryPage {...pages.allergies} />
}

export const CholesterolPage = () => {
  const pages = useHealthCategoryTablePagesData()
  return <HealthCategoryPage {...pages.cholesterol} />
}

export const ConditionsPage = () => {
  const pages = useHealthCategoryTablePagesData()
  return <HealthCategoryPage {...pages.conditions} />
}

export const MedicalTestsPage = () => {
  const pages = useHealthCategoryTablePagesData()
  return <HealthCategoryPage {...pages.tests} />
}

export const MedicationsPage = () => {
  const pages = useHealthCategoryTablePagesData()
  return <HealthCategoryPage {...pages.medications} />
}

export const SurgeriesPage = () => {
  const pages = useHealthCategoryTablePagesData()
  return <HealthCategoryPage {...pages.surgeries} />
}

export const VaccinesPage = () => {
  const pages = useHealthCategoryTablePagesData()
  return <HealthCategoryPage {...pages.vaccinations} />
}
