import * as React from 'react'

import {IconProps} from '.'

function Image({color, size, isFilled}: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={isFilled ? color : 'none'}
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.998 3.74609C5.20216 3.74609 3.74634 5.20191 3.74634 6.99776V17.0019C3.74634 18.7978 5.20216 20.2536 6.998 20.2536H17.0022C18.798 20.2536 20.2538 18.7978 20.2538 17.0019V6.99776C20.2538 5.20191 18.798 3.74609 17.0022 3.74609H6.998ZM2.24634 6.99776C2.24634 4.37349 4.37373 2.24609 6.998 2.24609H17.0022C19.6264 2.24609 21.7538 4.37349 21.7538 6.99776V17.0019C21.7538 19.6262 19.6264 21.7536 17.0022 21.7536H6.998C4.37373 21.7536 2.24634 19.6262 2.24634 17.0019V6.99776Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.1182 11.9438C17.1181 11.9439 17.1182 11.9438 17.1182 11.9438L12.5303 16.5317C12.2374 16.8245 11.7626 16.8245 11.4697 16.5317C11.1768 16.2388 11.1768 15.7639 11.4697 15.471L16.0576 10.8831C17.1318 9.80921 18.8731 9.80913 19.9473 10.883L21.5341 12.4697C21.827 12.7626 21.827 13.2375 21.5341 13.5304C21.2412 13.8233 20.7663 13.8233 20.4734 13.5304L18.8868 11.9438C18.8868 11.9438 18.8869 11.9439 18.8868 11.9438C18.3984 11.4556 17.6066 11.4556 17.1182 11.9438Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.49818 6.74756C7.08362 6.74756 6.74756 7.08362 6.74756 7.49818C6.74756 7.91274 7.08362 8.24881 7.49818 8.24881C7.91274 8.24881 8.24881 7.91274 8.24881 7.49818C8.24881 7.08362 7.91274 6.74756 7.49818 6.74756ZM5.24756 7.49818C5.24756 6.2552 6.2552 5.24756 7.49818 5.24756C8.74117 5.24756 9.74881 6.2552 9.74881 7.49818C9.74881 8.74117 8.74117 9.74881 7.49818 9.74881C6.2552 9.74881 5.24756 8.74117 5.24756 7.49818Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.11138 14.9453C8.11135 14.9453 8.1114 14.9453 8.11138 14.9453L3.8797 19.177C3.5868 19.4699 3.11193 19.4699 2.81904 19.177C2.52614 18.8841 2.52614 18.4092 2.81904 18.1163L7.0508 13.8845C8.125 12.8107 9.86636 12.8106 10.9406 13.8845L17.3603 20.3042C17.6532 20.5971 17.6532 21.072 17.3603 21.3649C17.0674 21.6578 16.5925 21.6578 16.2997 21.3649L9.88006 14.9453C9.88003 14.9453 9.88008 14.9453 9.88006 14.9453C9.39163 14.4571 8.59981 14.4571 8.11138 14.9453Z"
        fill={color}
      />
    </svg>
  )
}

export default Image
