import styled from 'styled-components'

import {theme as globalTheme} from '~/styles/theme'

export type StyledTextProps = {
  className?: string
  size?: keyof typeof globalTheme.fontSize
  color?: string
  weight?: keyof typeof globalTheme.fontWeight
  maxWidth?: number
  ellipsis?: boolean
  textAlign?: 'left' | 'right' | 'center' | 'justify' | 'initial' | 'inherit'
  uppercase?: boolean
  flexShrink?: number
  flexGrow?: number
}

export const StyledParagraph = styled.p<StyledTextProps>`
  white-space: pre-line;
  font-size: ${({size = 'md', theme}) => theme.fontSize[size]};
  line-height: ${({size = 'md', theme}) => theme.lineHeight[size]};
  color: ${({color, theme}) => color || theme.colors.primary.main};
  font-weight: ${({weight = 'normal', theme}) => theme.fontWeight[weight]};
  ${({ellipsis}) =>
    ellipsis &&
    `text-overflow: ellipsis; overflow: hidden; white-space: nowrap`};

  ${({maxWidth}) =>
    maxWidth &&
    `max-width: ${maxWidth}px; text-overflow: ellipsis; overflow: hidden; white-space: nowrap`};
  ${({uppercase}) => uppercase && 'text-transform: uppercase'};
  ${({textAlign}) => textAlign && `text-align: ${textAlign}`};
  ${({flexGrow}) => flexGrow != null && `flex-grow: ${flexGrow}`};
  ${({flexShrink}) => flexShrink != null && `flex-shrink: ${flexShrink}`};

  ${({onClick}) => !!onClick && `cursor: pointer;`}
`

export const StyledText = styled(StyledParagraph).attrs({
  as: 'span',
})``
