import {
  assignedQuestionnaireInfoNormaliser,
  assignedQuestionnaireNormaliser,
  getPagedResponseNormaliser,
  measurementNormaliser,
  questionnaireNormaliser,
  questionnairesInfoNormaliser,
} from '@common/normalisers'
import {
  endpointKeys,
  FacilityIdParam,
  IdUrlParam,
  MeasurementsUrlParams,
  PractitionerQuestionnairesUrlParams,
  QuestionnaireUrlParams,
} from '@common/api'
import {
  PagedResponse,
  AssignedQuestionnaire,
  AssignedQuestionnaireInfo,
  AssignQuestionnaireRequest,
  Questionnaire,
  QuestionnaireInfo,
  QuestionnaireMeasurement,
} from '@common/models'
import {UseInfiniteQueryOptions, UseQueryOptions} from '@tanstack/react-query'

import {useApi, useWebGet, useWebInfiniteGet, useWebSet} from './helpers'

export const useGetQuestionnaires = (params: FacilityIdParam) =>
  useWebGet<QuestionnaireInfo[]>(
    [endpointKeys.questionnaires, params],
    useApi().questionnaires(params),
    questionnairesInfoNormaliser,
  )

export const useGetQuestionnaire = (params: IdUrlParam) =>
  useWebGet<Questionnaire>(
    [endpointKeys.questionnaire, params],
    useApi().questionnaire(params),
    questionnaireNormaliser,
  )

export const useGetAssignedQuestionnaires = (
  params: PractitionerQuestionnairesUrlParams,
) =>
  useWebGet<PagedResponse<AssignedQuestionnaireInfo>>(
    [endpointKeys.practitionerAssignedQuestionnaires, params],
    useApi().practitionerAssignedQuestionnaires(params),
    getPagedResponseNormaliser(assignedQuestionnaireInfoNormaliser),
  )

export const useGetDetailedAssignedQuestionnaires = (
  params: PractitionerQuestionnairesUrlParams,
) =>
  useWebGet<PagedResponse<AssignedQuestionnaire>>(
    [endpointKeys.detailedAssignedQuestionnaires, params],
    useApi().detailedAssignedQuestionnaires(params),
    getPagedResponseNormaliser(assignedQuestionnaireNormaliser),
  )

export const useGetAssignedQuestionnaire = (
  params: QuestionnaireUrlParams,
  options?: UseQueryOptions<AssignedQuestionnaire, Error>,
) =>
  useWebGet<AssignedQuestionnaire>(
    [endpointKeys.assignedQuestionnaire, params],
    useApi().assignedQuestionnaire(params),
    assignedQuestionnaireNormaliser,
    options,
  )

export const useAssignQuestionnaire = () =>
  useWebSet<AssignQuestionnaireRequest>(
    [endpointKeys.assignedQuestionnaires],
    useApi().assignedQuestionnaires({}),
    'POST',
    undefined,
    [
      [endpointKeys.assignedQuestionnaires],
      [endpointKeys.detailedAssignedQuestionnaires],
      [endpointKeys.practitionerAssignedQuestionnaires],
    ],
  )

export const useInfiniteGetMeasurements = (
  params: MeasurementsUrlParams,
  options?: UseInfiniteQueryOptions<
    PagedResponse<QuestionnaireMeasurement>,
    Error,
    PagedResponse<QuestionnaireMeasurement>
  >,
) =>
  useWebInfiniteGet<PagedResponse<QuestionnaireMeasurement>>(
    [endpointKeys.measurements, params],
    useApi().measurements(params),
    getPagedResponseNormaliser(measurementNormaliser),
    options,
  )
