import styled from 'styled-components'

export const PatientDetailsContainer = styled.div`
display: flex;
flex-align: stretch;
width: 100%
display: inline-flex;
align-items: center;
margin-right: 0;
padding: 6px;
flex: 1;
justify-content: space-between;

&:hover {
    ${({onClick}) => !!onClick && 'cursor: pointer'};
  box-shadow: ${({onClick, theme}) => onClick && theme.boxShadow.card};
  }
`

export const AddAppointmentModalCard = styled.div`
  padding: ${({theme}) => theme.spacing.lg};
  border-radius: ${({theme}) => theme.borderRadius.sm};
  box-shadow: ${({theme}) => theme.boxShadow.card};
  background-color: ${({theme}) => theme.colors.white};
  margin-top: ${({theme}) => theme.spacing.md};
`
