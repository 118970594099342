import {PermissionCategory, PractitionersPatient} from '@common/models'
import {useTranslation} from 'react-i18next'
import React, {useMemo} from 'react'

import {RequestDataButton} from './RequestDataButton'

import {Typography} from '~/components/general/typography/Typography'
import {Container} from '~/components/general/container/Container'
import {formatName} from '~/utils'

type RequestDataProps = {
  inline?: boolean
  patient: PractitionersPatient | undefined
  permission: PermissionCategory
  text?: string
}

export const RequestData: React.FC<RequestDataProps> = ({
  inline,
  patient,
  permission,
  text,
}) => {
  const {t} = useTranslation()

  const defaultText = useMemo(
    () =>
      t("{{patientName}} hasn't shared this data with your facility yet.", {
        patientName: formatName(patient, t('Patient')),
      }),
    [patient, t],
  )

  return (
    <Container
      flex
      align={inline ? 'center' : 'flex-start'}
      spacing={3}
      direction={inline ? 'row' : 'column'}>
      <Typography>{text || defaultText}</Typography>
      <RequestDataButton title={t('Request data')} permission={permission} />
    </Container>
  )
}
