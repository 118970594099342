import {assertEnum, assertObjectOrUndefined} from '../utils'
import {
  FirstResponderAuthStatus,
  FacilityDetails,
  FirstResponderDetails,
} from '../models'
import {AssertTypeFn} from './general'
import {facilityDetailsNormaliser} from './patient'

export const FirstResponderValidationResponseNormaliser: AssertTypeFn<
  FirstResponderDetails
> = (obj) => ({
  status: assertEnum(obj.status, FirstResponderAuthStatus),
  facilityDetails: assertObjectOrUndefined<FacilityDetails>(
    obj.facilityDetails,
    facilityDetailsNormaliser,
  ),
})
