import {
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseInfiniteQueryOptions,
} from '@tanstack/react-query'
import {
  getFetchFn,
  getApiEndpoints,
  MutationRequestMethod,
  ResponseNormaliserFn,
  useGet,
  useInfiniteGet,
  useSet,
} from '@common/api'
import {AuthError, GeneralPagedReponse} from '@common/models'
import {useAccount, useMsal} from '@azure/msal-react'
import {InteractionRequiredAuthError} from '@azure/msal-common'
import {InteractionStatus} from '@azure/msal-browser'
import {useCallback, useContext} from 'react'
import {useCustomErrorMessage} from '@common/utils'

import {isDevelopment} from '~/utils'
import {b2cScopes, loginRequest} from '~/auth/AuthConfig'
import {
  NotificationContext,
  NotificationType,
} from '~/components/notifications/NotificationContext'

export const useApi = () => {
  const apiUrl = `${window.location.origin}/api/v1/`
  const localUserId = process.env.LOCAL_USER_ID

  const {accounts} = useMsal()
  const practitionerId = isDevelopment
    ? localUserId
    : accounts[0]?.localAccountId

  return getApiEndpoints({apiUrl, practitionerId})
}

export const useHeaders = (addPractitionerAuthHeaders = true) => {
  const localUserId = process.env.LOCAL_USER_ID

  const {instance, accounts, inProgress} = useMsal()
  const account = useAccount(accounts[0] || {})

  return useCallback(async () => {
    if (!addPractitionerAuthHeaders) {
      return {}
    }

    if (isDevelopment) {
      return {Authorization: `Bearer ${localUserId}`}
    }

    try {
      if (!account) {
        throw new Error('Account is missing or is not loaded yet.')
      }

      const {accessToken} = await instance.acquireTokenSilent({
        account,
        scopes: b2cScopes,
      })
      return {Authorization: `Bearer ${accessToken}`}
    } catch (error) {
      // [CUSH-655] FIXME: Add error logging
      // eslint-disable-next-line no-console
      console.warn(error)

      if (error instanceof InteractionRequiredAuthError) {
        if (account && inProgress === InteractionStatus.None) {
          try {
            await instance.loginRedirect(loginRequest)
          } catch (err: unknown) {
            // [CUSH-655] FIXME: Add error logging
            // eslint-disable-next-line no-console
            console.error(err)
          }
        }
      }
      return Promise.reject(new AuthError())
    }
  }, [account, addPractitionerAuthHeaders, inProgress, instance, localUserId])
}

const useErrorMessage = () => {
  const {addNotification} = useContext(NotificationContext)
  const getCustomErrorMessage = useCustomErrorMessage()

  return (error: Error) =>
    addNotification(
      getCustomErrorMessage(error) || error.message,
      NotificationType.Error,
    )
}
export const useWebGet = <T>(
  key: QueryKey,
  endpoint: URL,
  normaliser: (data: T) => T,
  options: UseQueryOptions<T, Error, T> = {},
  addPractitionerAuthHeaders = true,
) => {
  const getHeader = useHeaders(addPractitionerAuthHeaders)
  const showErrorMessage = useErrorMessage()

  return useGet(getHeader, key, endpoint, normaliser, {
    onError: showErrorMessage,
    ...options,
  })
}

export const useWebInfiniteGet = <T extends GeneralPagedReponse>(
  key: QueryKey,
  endpoint: URL,
  normaliser: (data: T) => T,
  options?: UseInfiniteQueryOptions<T, Error, T>,
) => {
  const getHeader = useHeaders()
  const showErrorMessage = useErrorMessage()

  return useInfiniteGet(getHeader, key, endpoint, normaliser, {
    onError: showErrorMessage,
    ...options,
  })
}

export const useWebSet = <RequestType, ResponseType = unknown>(
  key: QueryKey,
  endpoint: URL,
  method: MutationRequestMethod,
  normaliser: ResponseNormaliserFn<ResponseType>,
  invalidationKeys?: QueryKey[],
  options: UseMutationOptions<ResponseType, Error, RequestType> = {},
) => {
  const getHeader = useHeaders()
  const showErrorMessage = useErrorMessage()

  return useSet(
    getHeader,
    key,
    endpoint,
    method,
    normaliser,
    {
      onError: showErrorMessage,
      ...options,
    },
    invalidationKeys,
  )
}

export const useFetchData = () => {
  const getHeader = useHeaders()

  return useCallback(
    <T>(endpoint: URL, normaliser: (data: T) => T) =>
      getFetchFn(getHeader, endpoint, normaliser)(),
    [getHeader],
  )
}
