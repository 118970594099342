import {PractitionersPatient} from '@common/models'
import {EnumType, useEnumTranslations} from '@common/utils'
import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {useGetPatientInsuranceDetails} from '~/api'
import {DataAccordionContent} from '~/components/general/accordion/DataAccordionContent'
import {Loading} from '~/components/general/loading/Loading'

type InsuranceAccordionContentProps = {
  patient: PractitionersPatient
}

export const InsuranceAccordionContent: React.FC<
  InsuranceAccordionContentProps
> = ({patient}) => {
  const {t} = useTranslation()
  const {translateEnumValue} = useEnumTranslations()

  const {data, isInitialLoading} = useGetPatientInsuranceDetails({
    patientId: patient.patientId,
  })

  const accordionData = useMemo(
    () => [
      {
        title: t('Patient category'),
        value:
          translateEnumValue(EnumType.PatientCategory, data?.patientCategory) ||
          '-',
      },
      {
        title: t('PPS number'),
        value: data?.ppsn || '-',
      },
      {
        title: t('National identification number'),
        value: data?.healthIdentifierNumber || '-',
      },
      {
        title: t('Health identifier type'),
        value:
          translateEnumValue(
            EnumType.HealthIdentifierType,
            data?.healthIdentifierType,
          ) || '-',
      },
      {
        title: t('Name of provider / insurer'),
        value: data?.healthInsuranceProvider || '-',
      },
      {
        title: t('Health insurance number'),
        value: data?.healthInsuranceNumber || '-',
      },
    ],
    [data, t, translateEnumValue],
  )

  if (isInitialLoading) {
    return <Loading />
  }

  return <DataAccordionContent data={accordionData} />
}
