import React, {useState} from 'react'

import {Select, Option, InputProps} from './Select'
import {Typography} from '../typography/Typography'

interface Option<T> {
  label: string
  value: T
}

type FilteredSelectProps<T> = {
  options: Option<T>[]
  onChange: (selectedOption: T | undefined) => void
} & Omit<InputProps, 'onChange' | 'children'>

const FilteredSelect = <T,>({
  options,
  onChange,
  ...props
}: FilteredSelectProps<T>) => {
  const [filter, setFilter] = useState('')

  const handleFilterChange = (value: string) => {
    if (options === undefined) {
      return
    }

    if (value === '') {
      setFilter(value)
      onChange(undefined)
      return
    }

    setFilter(value)
  }

  const handleOptionClick = (option: Option<T>) => {
    setFilter(option.label)
    onChange(option.value)
  }

  const filteredOptions = options.filter((option) =>
    option.label.toLowerCase().includes(filter.toLowerCase()),
  )

  return (
    <Select
      {...props}
      search
      onChange={(event) => handleFilterChange(event.target.value)}
      value={filter}>
      {filteredOptions?.map((option) => (
        <Option key={option.label} onClick={() => handleOptionClick(option)}>
          <Typography>{option.label}</Typography>
        </Option>
      ))}
    </Select>
  )
}

export default FilteredSelect
