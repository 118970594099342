import * as React from 'react'

import {IconProps} from '.'

function Drop({color, size, isFilled}: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill={isFilled ? color : 'none'}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.25 11.25C16.25 5.625 10 1.25 10 1.25C10 1.25 3.75 5.625 3.75 11.25C3.75 12.9076 4.40848 14.4973 5.58058 15.6694C6.75269 16.8415 8.3424 17.5 10 17.5C11.6576 17.5 13.2473 16.8415 14.4194 15.6694C15.5915 14.4973 16.25 12.9076 16.25 11.25Z"
        stroke={color}
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.6343 14.938C11.3987 14.8081 12.104 14.444 12.6526 13.896C13.2013 13.348 13.5663 12.6432 13.6971 11.8789"
        stroke={color}
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default Drop
