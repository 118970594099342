import styled from 'styled-components'

export const DataAccordionContentWrapper = styled.div`
  width: 50%;
`

export const DataAccordionEntryWrapper = styled.div`
  padding: ${({theme}) => theme.spacing.xs};

  & + & {
    border-top: solid 1px ${({theme}) => theme.colors.grey.grey50};
  }
`
