import {PractitionersPatient} from '@common/models'
import {EnumType, formatDate, useEnumTranslations} from '@common/utils'
import {DateTime} from 'luxon'
import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {useGetPatientDemographicDetails} from '~/api'
import {DataAccordionContent} from '~/components/general/accordion/DataAccordionContent'
import {Loading} from '~/components/general/loading/Loading'

type DemographicDetailsAccordionContentProps = {
  patient: PractitionersPatient
}

export const DemographicDetailsAccordionContent: React.FC<
  DemographicDetailsAccordionContentProps
> = ({patient}) => {
  const {t} = useTranslation()
  const {translateEnumValue} = useEnumTranslations()

  const {data, isInitialLoading} = useGetPatientDemographicDetails({
    patientId: patient.patientId,
  })

  const accordionData = useMemo(
    () => [
      {
        title: t('Date of birth'),
        value: formatDate(DateTime.DATE_MED, data?.dateOfBirth) || '-',
      },
      {
        title: t('Place of birth'),
        value: data?.placeOfBirth || '-',
      },
      {
        title: t('Gender at birth'),
        value: translateEnumValue(EnumType.Sex, data?.gender) || '-',
      },
      {
        title: t('Preferred pronouns'),
        value:
          translateEnumValue(EnumType.Pronoun, data?.preferredPronoun) || '-',
      },
      {
        title: t('Nationality'),
        value: data?.nationality || '-',
      },
      {
        title: t('Ocupation'),
        value: data?.occupation?.value.label || '-',
      },
      {
        title: t('Hobbies'),
        value: data?.hobbies?.join(', ') || '-',
      },
    ],
    [data, t, translateEnumValue],
  )

  if (isInitialLoading) {
    return <Loading />
  }

  return <DataAccordionContent data={accordionData} />
}
