import {PractitionersPatient} from '@common/models'
import {DEBOUNCE_TIME, MISSING_ID} from '@common/utils'
import React, {useContext, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useDebounce} from 'use-debounce'

import PatientDetailsShort from './PatientDetailsShort'
import {AddAppointmentModalCard} from './AddAppointmentModal.styled'
import AddPatientCard from './AddPatientCard'

import {useGetPractitionerPatients} from '~/api'
import {Input} from '~/components/general/input/Input'
import {PractitionerContext} from '~/components/profile/PractitionerContext'
import {Button} from '~/components/general/button/Button'
import {Container} from '~/components/general/container/Container'
import {Loading} from '~/components/general/loading/Loading'
import {IconButton} from '~/components/general/iconButton/IconButton'
import {Typography} from '~/components/general/typography/Typography'

interface FindPatientCardProps {
  onSelectPatient: (patient: PractitionersPatient | null) => void
}

const PATIENTS_PER_TABLE_PAGE = 10

export const FindPatientCard: React.FC<FindPatientCardProps> = ({
  onSelectPatient,
}) => {
  const {t} = useTranslation()
  const {selectedEmployment} = useContext(PractitionerContext)
  const [currentTablePageIndex, setCurrentTablePageIndex] = useState(0)
  const [query, setQuery] = useState('')
  const [debouncedQuery] = useDebounce(query, DEBOUNCE_TIME)
  const [selectedPatient, setSelectedPatient] =
    useState<PractitionersPatient | null>(null)
  const [isAddingNewPatient, setIsAddingNewPatient] = useState(false)

  const {data: patientsData, isInitialLoading: isLoadingPatientsData} =
    useGetPractitionerPatients({
      perPage: PATIENTS_PER_TABLE_PAGE,
      page: currentTablePageIndex,
      facilityId: selectedEmployment?.facilityId ?? MISSING_ID,
      query: debouncedQuery,
    })

  const handleSearch: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    setQuery(event.target.value)
  }

  const handleSelectPatient = (patient: PractitionersPatient) => {
    setSelectedPatient(patient)
    onSelectPatient(patient)
    setIsAddingNewPatient(false)
  }

  const handleRemovePatient = () => {
    setSelectedPatient(null)
    onSelectPatient(null)
  }

  const handleAddNew = () => {
    setIsAddingNewPatient(true)
  }

  const canGoNext = patientsData
    ? currentTablePageIndex < patientsData.page.totalPages
    : false
  const canGoPrev = currentTablePageIndex > 0

  if (isAddingNewPatient) {
    return (
      <AddAppointmentModalCard>
        <Container flex direction="column" spacing={2}>
          <Typography size="md" weight="medium">
            {t('Add New Patient')}
          </Typography>
        </Container>
        <AddPatientCard
          onPatientCreated={handleSelectPatient}
          onCancel={() => setIsAddingNewPatient(false)}
        />
      </AddAppointmentModalCard>
    )
  }

  if (selectedPatient) {
    return (
      <AddAppointmentModalCard>
        <Container flex direction="column">
          <Typography size="md" weight="medium">
            {t('Patient')}
          </Typography>
        </Container>
        <PatientDetailsShort
          patient={selectedPatient}
          onRemove={handleRemovePatient}
        />
      </AddAppointmentModalCard>
    )
  }

  return (
    <AddAppointmentModalCard>
      <Container flex direction="column">
        <Typography size="md" weight="medium">
          {t('Find Patient')}
        </Typography>
      </Container>
      <Container flex direction="column" spacing={2}>
        <Container flex direction="row">
          <Input
            placeholder={t('Search patients...')}
            startIcon="Search"
            onChange={handleSearch}
            noBorder
          />
          <IconButton
            name="ArrowLeft"
            color="secondary"
            disabled={!canGoPrev}
            onClick={() => setCurrentTablePageIndex(currentTablePageIndex - 1)}
          />
          <IconButton
            name="ArrowRight"
            color="secondary"
            disabled={!canGoNext}
            onClick={() => setCurrentTablePageIndex(currentTablePageIndex + 1)}
          />
        </Container>
        {isLoadingPatientsData && <Loading />}
        <ul>
          {patientsData?.records?.map((patient) => (
            <li key={patient.patientId}>
              <PatientDetailsShort
                patient={patient}
                onClick={handleSelectPatient}
              />
            </li>
          ))}
        </ul>
        <Container flex justify="flex-end">
          <Button onClick={handleAddNew} size="sm">
            {t('Add New Patient')}
          </Button>
        </Container>
      </Container>
    </AddAppointmentModalCard>
  )
}
