import {QuestionnaireStatus} from '@common/models'
import {useTranslation} from 'react-i18next'
import {EnumType, MISSING_ID, useEnumTranslations} from '@common/utils'
import React, {useContext, useMemo, useState} from 'react'
import {useNavigate} from 'react-router-dom'

import {
  EmptyTableText,
  SectionWrapper,
  StyledTitle,
} from './PatientQuestionnaires.styled'

import {useGetAssignedQuestionnaires} from '~/api'
import {PatientsContext} from '~/components/patients/PatientsContext'
import {SimplePaginatedTable} from '~/components/general/table/SimplePaginatedTable'
import {getTableDateValue} from '~/utils'
import {theme} from '~/styles/theme'

const PENDING_QUESTIONNAIRES_PER_TABLE_PAGE = 10
const COMPLETED_QUESTIONNAIRES_PER_TABLE_PAGE = 10

export const PatientQuestionnaires: React.FC = () => {
  const {t} = useTranslation()
  const {translateEnumValue} = useEnumTranslations()
  const navigate = useNavigate()
  const {patient} = useContext(PatientsContext)

  const [pendingQuestionnairesPageIndex, setPendingQuestionnairesPageIndex] =
    useState(0)
  const [
    completedQuestionnairesPageIndex,
    setCompletedQuestionnairesPageIndex,
  ] = useState(0)

  const {
    data: pendingQuestionnairesData,
    isInitialLoading: isLoadingPendingQuestionnaires,
  } = useGetAssignedQuestionnaires({
    patientId: patient?.patientId ?? MISSING_ID,
    facilityId: patient?.facilityId ?? MISSING_ID,
    questionnaireStatus: QuestionnaireStatus.NOT_COMPLETED,
    page: pendingQuestionnairesPageIndex,
    perPage: PENDING_QUESTIONNAIRES_PER_TABLE_PAGE,
  })
  const {
    data: completedQuestionnairesData,
    isInitialLoading: isLoadingCompletedQuestionnaires,
  } = useGetAssignedQuestionnaires({
    patientId: patient?.patientId ?? MISSING_ID,
    facilityId: patient?.facilityId ?? MISSING_ID,
    questionnaireStatus: QuestionnaireStatus.COMPLETED,
    page: completedQuestionnairesPageIndex,
    perPage: COMPLETED_QUESTIONNAIRES_PER_TABLE_PAGE,
  })

  const pendingQuestionnairesTableHeader = useMemo(
    () => ({
      columns: [
        {
          title: t('Title'),
          columnWidthPercent: 35,
        },
        {
          title: t('Type'),
          columnWidthPercent: 25,
        },
        {
          title: t('Date sent'),
          columnWidthPercent: 40,
        },
      ],
    }),
    [t],
  )

  const pendingQuestionnairesTableRows = useMemo(
    () =>
      pendingQuestionnairesData?.records.map((record) => ({
        columns: [
          record.title,
          translateEnumValue(EnumType.QuestionnaireType, record.type),
          getTableDateValue(record.dateReceived),
        ],
      })),
    [pendingQuestionnairesData?.records, translateEnumValue],
  )

  const completedQuestionnairesTableHeader = useMemo(
    () => ({
      columns: [
        {
          title: t('Title'),
          columnWidthPercent: 35,
        },
        {
          title: t('Type'),
          columnWidthPercent: 25,
        },
        {
          title: t('Date sent'),
          columnWidthPercent: 20,
        },
        {
          title: t('Date answered'),
          columnWidthPercent: 20,
        },
      ],
    }),
    [t],
  )

  const completedQuestionnairesTableRows = useMemo(
    () =>
      completedQuestionnairesData?.records.map((record) => ({
        onRowClick: () => navigate(record.id),
        columns: [
          record.title,
          translateEnumValue(EnumType.QuestionnaireType, record.type),
          getTableDateValue(record.dateReceived),
          getTableDateValue(record.dateCompleted),
        ],
      })),
    [completedQuestionnairesData?.records, navigate, translateEnumValue],
  )

  const pendingQuestionnairesRowCount =
    pendingQuestionnairesData?.page.totalElements
  const completedQuestionnairesRowCount =
    completedQuestionnairesData?.page.totalElements

  const pendingQuestionnairesText =
    pendingQuestionnairesRowCount == null
      ? t('Unanswered questionnaires')
      : t('Unanswered questionnaires ({{number}})', {
          number: pendingQuestionnairesRowCount,
        })
  const completedQuestionnairesText =
    pendingQuestionnairesRowCount == null
      ? t('Answered questionnaires')
      : t('Answered questionnaires ({{number}})', {
          number: completedQuestionnairesRowCount,
        })

  return (
    <>
      <SectionWrapper>
        <StyledTitle weight="medium">{pendingQuestionnairesText}</StyledTitle>
        {pendingQuestionnairesRowCount ? (
          <SimplePaginatedTable
            header={pendingQuestionnairesTableHeader}
            isLoading={isLoadingPendingQuestionnaires}
            onPageClick={setPendingQuestionnairesPageIndex}
            rows={pendingQuestionnairesTableRows ?? []}
            paginationData={pendingQuestionnairesData?.page}
            hideUnusedPagination
          />
        ) : (
          <EmptyTableText color={theme.colors.grey.main}>
            {t('No unanswered questionnaires')}
          </EmptyTableText>
        )}
      </SectionWrapper>
      <SectionWrapper>
        <StyledTitle weight="medium">{completedQuestionnairesText}</StyledTitle>
        {completedQuestionnairesRowCount ? (
          <SimplePaginatedTable
            header={completedQuestionnairesTableHeader}
            isLoading={isLoadingCompletedQuestionnaires}
            onPageClick={setCompletedQuestionnairesPageIndex}
            rows={completedQuestionnairesTableRows ?? []}
            paginationData={completedQuestionnairesData?.page}
            hideUnusedPagination
          />
        ) : (
          <EmptyTableText color={theme.colors.grey.main}>
            {t('No answered questionnaires')}
          </EmptyTableText>
        )}
      </SectionWrapper>
    </>
  )
}
