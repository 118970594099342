import * as React from 'react'

import {IconProps} from '.'

function World({color, size, isFilled}: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 21 21"
      fill={isFilled ? color : 'none'}
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.0043 8.66406C13.0043 8.66406 16.0442 6.1684 16.0442 3.88976C16.0442 2.21181 14.6831 0.851562 13.0043 0.851562C11.3255 0.851562 9.96436 2.21181 9.96436 3.88976C9.96436 6.1684 13.0043 8.66406 13.0043 8.66406Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.0052 3.75803C13.125 3.75803 13.2222 3.85525 13.2214 3.97504C13.2214 4.09483 13.1241 4.19206 13.0043 4.19206C12.8845 4.19206 12.7873 4.09483 12.7873 3.97504C12.7873 3.85525 12.8845 3.75803 13.0052 3.75803"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.99303 3.47852C5.8689 3.69206 2.58765 7.09223 2.58765 11.2693C2.58765 15.5844 6.08504 19.0818 10.4001 19.0818C14.7153 19.0818 18.2126 15.5844 18.2126 11.2693C18.2126 8.96723 17.2126 6.90473 15.6284 5.47852"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.92776 18.2713V18.2096C6.92776 16.4735 10.4 16.6784 10.4 14.7374C10.4 13.0013 7.79582 13.0013 7.79582 10.3971C7.79582 7.94227 6.05971 7.79297 3.45554 7.79297H3.40259"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.5823 17.1018C15.4512 17.0514 15.321 16.9985 15.1968 16.9256C13.9503 16.1964 13.5319 14.5957 14.2602 13.3492C14.9894 12.1027 16.5901 11.6843 17.8366 12.4126C17.9286 12.4664 18.0059 12.5341 18.0892 12.5966"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default World
