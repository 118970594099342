import React, {useContext} from 'react'
import {useTranslation} from 'react-i18next'
import {PermissionCategory} from '@common/models'

import {
  PatientDataAccordionWrapper,
  PatientDataAccordionShareSection,
  PatientDataAccordionNotSharedBadge,
} from './PatientDataAccordion.styled'
import {RequestDataButton} from '../requestData/RequestDataButton'

import {Accordion} from '~/components/general/accordion/Accordion'
import {IconName} from '~/components/general/icon/Icon'
import {Typography} from '~/components/general/typography/Typography'
import {PatientsContext} from '~/components/patients/PatientsContext'
import {theme} from '~/styles/theme'

type PatientDataAccordionTitleProps = {
  isShared: boolean
  permission: PermissionCategory
  title: string
}

const PatientDataAccordionTitle: React.FC<PatientDataAccordionTitleProps> = ({
  isShared,
  permission,
  title,
}) => {
  const {t} = useTranslation()

  return (
    <PatientDataAccordionWrapper>
      <Typography
        size="lg"
        weight="medium"
        color={isShared ? theme.colors.primary.main : theme.colors.grey.main}>
        {title}
      </Typography>
      {!isShared && (
        <PatientDataAccordionShareSection>
          <PatientDataAccordionNotSharedBadge>
            {t('Not shared')}
          </PatientDataAccordionNotSharedBadge>
          <RequestDataButton size="sm" permission={permission} />
        </PatientDataAccordionShareSection>
      )}
    </PatientDataAccordionWrapper>
  )
}

type PatientDataAccordionProps = {
  disabled?: boolean
  children: React.ReactNode
  iconName?: IconName
  isOpenByDefault?: boolean
  permission: PermissionCategory
  rightText?: string
  title: string
}

export const PatientDataAccordion: React.FC<PatientDataAccordionProps> = ({
  disabled,
  children,
  iconName,
  isOpenByDefault,
  permission,
  rightText,
  title,
}) => {
  const {patient} = useContext(PatientsContext)
  const isShared = !!patient?.permissions.includes(permission)

  return (
    <Accordion
      icon={iconName}
      isOpenByDefault={isOpenByDefault}
      rightText={isShared ? rightText : undefined}
      title={
        <PatientDataAccordionTitle
          isShared={isShared}
          title={title}
          permission={permission}
        />
      }
      disabled={!isShared || disabled}>
      {children}
    </Accordion>
  )
}
