import React from 'react'
import {HealthDataType, HealthDataGrading} from '@common/models'
import {
  formatDate,
  formatLocaleNumber,
  healthDataFractionDigitsMap,
} from '@common/utils'
import {DateTime} from 'luxon'

import {
  StyledBadgeIconBackground,
  StyledIndicatorBadgeCard,
  ValueContainer,
} from './HealthDataBadge.styled'
import {HealthDataTooltip} from './HealthDataTooltip'

import {Icon} from '~/components/general/icon/Icon'
import {Container} from '~/components/general/container/Container'
import {Typography} from '~/components/general/typography/Typography'
import {getGradeIndicatorColor, healthDataIconMap} from '~/utils'
import {theme} from '~/styles/theme'
import {TrendIndicator} from '~/components/general/gradeIndicator/TrendIndicator'
import {Popup} from '~/components/general/popup/Popup'

type HealthDataScoreProps = {
  className?: string
  date: string | undefined
  healthDataType: HealthDataType
  title: string
  unit: string | undefined
} & HealthDataGrading

export const HealthDataBadge: React.FC<HealthDataScoreProps> = ({
  className,
  date,
  grade,
  healthDataType,
  trend,
  trendGrade,
  title,
  unit,
  value,
}) => {
  const valueColor =
    theme.colors.indicator.body[getGradeIndicatorColor(grade) ?? 'grey']

  const badgeFractionDigits = Math.max(
    healthDataFractionDigitsMap[healthDataType] - 1,
    0,
  )

  const getValueText = (fractionDigits: number, shortenValue?: boolean) => {
    if (value == null) {
      return '-'
    }

    if (typeof value === 'string') {
      return value
    }

    return value >= 10000 && shortenValue
      ? `${Math.round(value / 1000)} k`
      : formatLocaleNumber(value, fractionDigits)
  }

  return (
    <StyledIndicatorBadgeCard className={className}>
      <Container flex align="center" spacing={1.5}>
        <StyledBadgeIconBackground>
          <Icon name={healthDataIconMap[healthDataType]} size="md" />
        </StyledBadgeIconBackground>
        <Container
          flex
          justify="space-between"
          spacing={1}
          align="center"
          flexNumber={1}>
          <Typography weight="medium" size="md">
            {title}
          </Typography>
          <Container flex spacing={0.5} align="center">
            <Popup
              content={
                <HealthDataTooltip
                  dateText={formatDate(DateTime.DATE_SHORT, date)}
                  value={getValueText(
                    healthDataFractionDigitsMap[healthDataType],
                  )}
                  unit={unit}
                />
              }>
              <ValueContainer>
                <Typography
                  size="md"
                  weight="bold"
                  color={valueColor}
                  textAlign="center">
                  {getValueText(badgeFractionDigits, true)}
                </Typography>
              </ValueContainer>
            </Popup>
            <TrendIndicator
              trend={trend}
              colorVariant={getGradeIndicatorColor(trendGrade)}
            />
          </Container>
        </Container>
      </Container>
    </StyledIndicatorBadgeCard>
  )
}
