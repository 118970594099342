import * as React from 'react'

import {IconProps} from '.'

function Baby({color, size, isFilled}: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill={isFilled ? color : 'none'}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z"
        stroke={color}
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.1875 10.9375C7.70527 10.9375 8.125 10.5178 8.125 10C8.125 9.48223 7.70527 9.0625 7.1875 9.0625C6.66973 9.0625 6.25 9.48223 6.25 10C6.25 10.5178 6.66973 10.9375 7.1875 10.9375Z"
        fill={color}
      />
      <path
        d="M12.8125 10.9375C13.3303 10.9375 13.75 10.5178 13.75 10C13.75 9.48223 13.3303 9.0625 12.8125 9.0625C12.2947 9.0625 11.875 9.48223 11.875 10C11.875 10.5178 12.2947 10.9375 12.8125 10.9375Z"
        fill={color}
      />
      <path
        d="M12.0732 13.125C11.4586 13.5325 10.7375 13.7498 10 13.7498C9.26255 13.7498 8.54144 13.5325 7.92676 13.1251"
        stroke={color}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 2.5C8.75 4.0625 8.75 5.625 8.75 5.625C8.75 5.95652 8.8817 6.27446 9.11612 6.50888C9.35054 6.7433 9.66848 6.875 10 6.875C10.3315 6.875 10.6495 6.7433 10.8839 6.50888C11.1183 6.27446 11.25 5.95652 11.25 5.625"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default Baby
