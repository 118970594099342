import styled from 'styled-components'

export type GradeIndicatorColor = 'red' | 'orange' | 'green' | 'grey'

type IndicatorProps = {
  colorVariant?: GradeIndicatorColor
}

export const GradeIndicator = styled.div<IndicatorProps>`
  height: 24px;
  width: 24px;
  border-radius: 12px;
  -moz-background-clip: content;
  -webkit-background-clip: content;
  background-clip: content-box;

  ${({theme, colorVariant = 'grey'}) =>
    `background-color: ${theme.colors.indicator.body[colorVariant]};`}
  ${({theme, colorVariant = 'grey'}) =>
    `border: 6px ${theme.colors.indicator.border[colorVariant]} solid;`}
`
