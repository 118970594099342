/* tslint:disable */
/* eslint-disable */
/**
 * Cushla API
 * REST API for Cushla
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */

export enum FamilyRelation {
  FATHER = 'FATHER',
  MOTHER = 'MOTHER',
  PATERNAL_GRANDFATHER = 'PATERNAL_GRANDFATHER',
  PATERNAL_GRANDMOTHER = 'PATERNAL_GRANDMOTHER',
  MATERNAL_GRANDFATHER = 'MATERNAL_GRANDFATHER',
  MATERNAL_GRANDMOTHER = 'MATERNAL_GRANDMOTHER',
  MALE_SIBLING = 'MALE_SIBLING',
  FEMALE_SIBLING = 'FEMALE_SIBLING',
}
