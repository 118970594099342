import {BasePermissionCategory} from '@common/models'
import {MISSING_ID, SUPPORT_MAIL_ADDRESS} from '@common/utils'
import React, {useContext, useState} from 'react'
import {Trans, useTranslation} from 'react-i18next'

import {PatientsContext} from '~/components/patients/PatientsContext'
import {
  useGetConsultationFileUrl,
  useGetMedicalRecordFileUrl,
  useGetPatientAppointmentSummaryFile,
  useGetSourceFileUrl,
} from '~/api'
import {Button} from '~/components/general/button/Button'
import {Icon} from '~/components/general/icon/Icon'
import {Loading} from '~/components/general/loading/Loading'
import {Typography} from '~/components/general/typography/Typography'
import {openInNewTab} from '~/utils'
import {Container} from '~/components/general/container/Container'
import {theme} from '~/styles/theme'

export type HealthRecordFileEndpointType =
  | 'consultation'
  | 'medicalRecord'
  | 'appointmentSummary'
  | 'sourceFile'

type HealthRecordAttachmentButtonProps = {
  id: string
  endpoint: HealthRecordFileEndpointType
}

export const HealthRecordAttachmentButton: React.FC<
  HealthRecordAttachmentButtonProps
> = ({id, endpoint}) => {
  const {t} = useTranslation()
  const {patient} = useContext(PatientsContext)

  const [isTextShown, setIsTextShown] = useState(false)

  const hasAppointmentPermission = patient?.permissions?.includes(
    BasePermissionCategory.APPOINTMENT,
  )

  const {data: consultationFile, isFetching: isConsultationFileLoading} =
    useGetConsultationFileUrl(
      {
        id,
        patientId: patient?.patientId ?? MISSING_ID,
      },
      {enabled: endpoint === 'consultation'},
    )
  const {data: medicalRecordFile, isFetching: isMedicalRecordFileLoading} =
    useGetMedicalRecordFileUrl(
      {
        id,
        patientId: patient?.patientId ?? MISSING_ID,
      },
      {enabled: endpoint === 'medicalRecord'},
    )
  const {
    data: appointmentSummaryFile,
    isFetching: isAppointmentSummaryFileLoading,
  } = useGetPatientAppointmentSummaryFile(
    {
      patientId: patient?.patientId ?? MISSING_ID,
      appointmentId: id,
    },
    {enabled: endpoint === 'appointmentSummary'},
  )
  const {data: sourceFile, isFetching: isSourceFileLoading} =
    useGetSourceFileUrl(
      {
        id,
        patientId: patient?.patientId ?? MISSING_ID,
      },
      {enabled: endpoint === 'sourceFile' && hasAppointmentPermission},
    )

  const url = (
    medicalRecordFile ??
    consultationFile ??
    sourceFile ??
    appointmentSummaryFile
  )?.url

  const isLoading =
    isConsultationFileLoading ||
    isMedicalRecordFileLoading ||
    isSourceFileLoading ||
    isAppointmentSummaryFileLoading

  const handleButtonClick = () => {
    if (endpoint === 'consultation' && !hasAppointmentPermission) {
      setIsTextShown(true)
      return
    }

    if (url) {
      openInNewTab(url)
    }
  }

  if (isLoading) {
    return <Loading />
  }

  if (endpoint && !url) {
    return null
  }

  return (
    <Container direction="column" spacing={2}>
      <Button
        color="primary"
        size="sm"
        startIcon={<Icon name="Document" />}
        onClick={handleButtonClick}>
        {t('Show attachment')}
      </Button>
      {isTextShown && (
        <Typography color={theme.colors.grey.grey600}>
          <Trans>
            Due to limitations on how data is received from the GP systems, it
            is not possible to split data for sharing. Please contact{' '}
            <a href={`mailto:${SUPPORT_MAIL_ADDRESS}`}>support@cushla.io</a> for
            a copy of these records.
          </Trans>
        </Typography>
      )}
    </Container>
  )
}
