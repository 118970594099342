import {HealthDataResolution} from '@common/models'
import {getChartTickValues} from '@common/utils'
import {DateTime} from 'luxon'
import {DomainPropType} from 'victory'

export const dataPointCountMap = {
  [HealthDataResolution.MONTH]: 12,
  [HealthDataResolution.WEEK]: 14,
  [HealthDataResolution.DAY]: 14,
}

const getWebChartTickValues = (
  resolution: HealthDataResolution,
  startDate: DateTime,
) => getChartTickValues(resolution, startDate, dataPointCountMap[resolution])

export const getChartDomain: (
  resolution: HealthDataResolution,
  startDate: DateTime,
) => DomainPropType = (resolution, startDate) => {
  const ticks = getWebChartTickValues(resolution, startDate)
  return {x: [ticks[0], ticks[ticks.length - 1]]}
}

export const getChartTickFormatProps = (
  resolution: HealthDataResolution,
  startDate: DateTime,
) => {
  switch (resolution) {
    case HealthDataResolution.MONTH:
      return {
        tickValues: getWebChartTickValues(resolution, startDate),
        tickFormat: (value: Date) =>
          DateTime.fromJSDate(value).toLocaleString({
            month: 'short',
            year: '2-digit',
          }),
      }

    case HealthDataResolution.WEEK:
    case HealthDataResolution.DAY:
      return {
        tickValues: getWebChartTickValues(resolution, startDate),
        tickFormat: (value: Date) =>
          DateTime.fromJSDate(value).toLocaleString({
            day: '2-digit',
            month: '2-digit',
          }),
      }
  }
}
