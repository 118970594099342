import {LifeMilestoneType} from '@generated/models'

export type {
  GetLifeMilestoneDto as LifeMilestone,
  LivingSituationDto as LivingSituation,
  PersonalInformationDto as PersonalInformation,
  PersonalNetworkDto as PersonalNetwork,
  SaveLifeMilestoneDto as AddLifeMilestoneRequest,
} from '@generated/models'

export {
  CaresFor as CaresForOption,
  HomeSharedWith as HomeSharedWithOption,
  HomeType,
  LifeMilestoneImpact,
  LifeMilestoneSourceTypeDto as LifeMilestoneSource,
  LifeMilestoneType,
  MaritalStatus,
} from '@generated/models'

export type LifeMilestonesSummaryCounts = Record<
  LifeMilestoneType,
  number | undefined
>

export type LifeMilestonesSummary = {
  counts: LifeMilestonesSummaryCounts
}
