/* tslint:disable */
/* eslint-disable */
/**
 * Cushla API
 * REST API for Cushla
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */

export enum FrontendScreenName {
  APPOINTMENTS = 'APPOINTMENTS',
  PREGNANCY_JOURNEY_NOTES_SCREEN = 'PREGNANCY_JOURNEY_NOTES_SCREEN',
  PREGNANCY_DASHBOARD = 'PREGNANCY_DASHBOARD',
  BIRTH_PREFERENCES_SCREEN = 'BIRTH_PREFERENCES_SCREEN',
}
