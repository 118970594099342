import {
  ActivePhysitrackProgramNotificationContent,
  AppointmentCancellationReason,
  CancelledAppointmentNotificationContent,
  DataAccessNotificationContent,
  DeviceToken,
  DeviceType,
  FileSharingNotificationContent,
  FrontendScreenName,
  MissingInformationNotificationContent,
  NewFeatureNotificationContent,
  NewPhysitrackProgramNotificationContent,
  NewQuestionnaireNotificationContent,
  Notification,
  NotificationContent,
  NotificationType,
  PatientReportMismatchNotificationContent,
  PeriodicNotificationContent,
  PeriodicNotificationType,
  PermissionCategory,
  PregnancyArticle,
  PregnancyJourneyNotificationContent,
  QuestionnaireReminderNotificationContent,
  QuestionnaireType,
  RescheduledAppointmentNotificationContent,
  ReviewAppointmentNotificationContent,
  TriageReminderNotificationContent,
  UpcomingAppointmentNotificationContent,
} from '../models'
import {
  assertArray,
  assertEnum,
  assertEnumOrNull,
  assertNumber,
  assertObjectOrUndefined,
  assertString,
  assertStringOrNull,
  assertValue,
  getAssertEnum,
} from '../utils'
import {AssertTypeFn} from './general'

export const deviceTokenNormaliser: AssertTypeFn<DeviceToken> = (obj) => ({
  patientId: assertString(obj.patientId),
  token: assertString(obj.token),
  type: assertEnum(obj.type, DeviceType),
})

export const deviceTokensNormaliser: AssertTypeFn<DeviceToken[]> = (arr) =>
  assertArray(arr, deviceTokenNormaliser)

export const activePhysitrackProgramNotificationContentNormaliser: AssertTypeFn<
  ActivePhysitrackProgramNotificationContent
> = (obj) => ({
  physitrackProgramId: assertString(obj.physitrackProgramId),
  programName: assertString(obj.programName),
  type: assertValue(obj.type, NotificationType.ACTIVE_PHYSITRACK_PROGRAM),
})

export const cancelledAppointmentNotificationContentNormaliser: AssertTypeFn<
  CancelledAppointmentNotificationContent
> = (obj) => ({
  appointmentId: assertString(obj.appointmentId),
  cancellationNote: assertStringOrNull(obj.cancellationNote),
  cancellationReason: assertEnum(
    obj.cancellationReason,
    AppointmentCancellationReason,
  ),
  facilityId: assertString(obj.facilityId),
  facilityName: assertString(obj.facilityName),
  facilityTimeZone: assertStringOrNull(obj.facilityTimeZone),
  type: assertValue(obj.type, NotificationType.APPOINTMENT_CANCELLED),
})

export const rescheduledAppointmentNotificationContentNormaliser: AssertTypeFn<
  RescheduledAppointmentNotificationContent
> = (obj) => ({
  appointmentId: assertString(obj.appointmentId),
  facilityId: assertString(obj.facilityId),
  facilityName: assertString(obj.facilityName),
  facilityTimeZone: assertStringOrNull(obj.facilityTimeZone),
  newStartTime: assertString(obj.newStartTime),
  oldStartTime: assertString(obj.oldStartTime),
  type: assertValue(obj.type, NotificationType.APPOINTMENT_RESCHEDULED),
})

export const dataAccessNotificationContentNormaliser: AssertTypeFn<
  DataAccessNotificationContent
> = (obj) => ({
  facilityId: assertString(obj.facilityId),
  facilityName: assertString(obj.facilityName),
  facilityTimeZone: assertStringOrNull(obj.facilityTimeZone),
  permissions: assertArray(obj.permissions, getAssertEnum(PermissionCategory)),
  type: assertValue(obj.type, NotificationType.DATA_ACCESS_REQUEST),
})

export const fileSharingNotificationContentNormaliser: AssertTypeFn<
  FileSharingNotificationContent
> = (obj) => ({
  note: assertStringOrNull(obj.note),
  practitionerName: assertString(obj.practitionerName),
  sharedFileIds: assertArray(obj.sharedFileIds, assertString),
  type: assertValue(obj.type, NotificationType.NEW_FILE_SHARED),
})

export const missingInformationNotificationContentNormaliser: AssertTypeFn<
  MissingInformationNotificationContent
> = (obj) => ({
  date: assertString(obj.date),
  patientId: assertString(obj.patientId),
  type: assertValue(obj.type, NotificationType.MISSING_INFORMATION),
})

export const newFeatureNotificationContentNormaliser: AssertTypeFn<
  NewFeatureNotificationContent
> = (obj) => ({
  message: assertString(obj.message),
  title: assertString(obj.title),
  type: assertValue(obj.type, NotificationType.NEW_FEATURE),
})

export const newPhysitrackProgramNotificationContentNormaliser: AssertTypeFn<
  NewPhysitrackProgramNotificationContent
> = (obj) => ({
  facilityId: assertString(obj.facilityId),
  facilityName: assertString(obj.facilityName),
  facilityTimeZone: assertStringOrNull(obj.facilityTimeZone),
  physitrackProgramId: assertString(obj.physitrackProgramId),
  type: assertValue(obj.type, NotificationType.NEW_PHYSITRACK_PROGRAM),
})

export const newQuestionnaireNotificationContentNormaliser: AssertTypeFn<
  NewQuestionnaireNotificationContent
> = (obj) => ({
  assignedQuestionnaireId: assertString(obj.assignedQuestionnaireId),
  facilityName: assertStringOrNull(obj.facilityName),
  facilityTimeZone: assertStringOrNull(obj.facilityTimeZone),
  questionnaireTitle: assertString(obj.questionnaireTitle),
  questionnaireType: assertEnumOrNull(obj.questionnaireType, QuestionnaireType),
  type: assertValue(obj.type, NotificationType.NEW_QUESTIONNAIRE),
})

export const patientReportMismatchNotificationContentNormaliser: AssertTypeFn<
  PatientReportMismatchNotificationContent
> = (obj) => ({
  dateOfBirth: assertString(obj.dateOfBirth),
  fileUploadedAt: assertString(obj.fileUploadedAt),
  fileUploadId: assertString(obj.fileUploadId),
  firstName: assertString(obj.firstName),
  surname: assertString(obj.surname),
  type: assertValue(obj.type, NotificationType.PATIENT_REPORT_MISMATCH),
})

export const periodicNotificationContentNormaliser: AssertTypeFn<
  PeriodicNotificationContent
> = (obj) => ({
  intervalDaysBetweenNotifications: assertNumber(
    obj.intervalDaysBetweenNotifications,
  ),
  notificationScheduledAt: assertString(obj.notificationScheduledAt),
  notificationType: assertEnum(obj.notificationType, PeriodicNotificationType),
  patientId: assertString(obj.patientId),
  type: assertValue(obj.type, NotificationType.PERIODIC_NOTIFICATION),
})

export const pregnancyArticleNormaliser: AssertTypeFn<PregnancyArticle> = (
  obj,
) => ({
  fileId: assertString(obj.fileId),
  fileName: assertString(obj.fileName),
})

export const pregnancyJourneyNotificationContentNormaliser: AssertTypeFn<
  PregnancyJourneyNotificationContent
> = (obj) => ({
  journeyId: assertString(obj.journeyId),
  pregnancyArticle: assertObjectOrUndefined(
    obj.pregnancyArticle,
    pregnancyArticleNormaliser,
  ),
  screenName: assertEnumOrNull(obj.screenName, FrontendScreenName),
  pregnancyNotificationSpecId: assertString(obj.pregnancyNotificationSpecId),
  type: assertValue(obj.type, NotificationType.PREGNANCY_NOTIFICATION),
})

export const questionnaireReminderNotificationContentNormaliser: AssertTypeFn<
  QuestionnaireReminderNotificationContent
> = (obj) => ({
  assignedQuestionnaireId: assertString(obj.assignedQuestionnaireId),
  facilityName: assertStringOrNull(obj.facilityName),
  facilityTimeZone: assertStringOrNull(obj.facilityTimeZone),
  questionnaireTitle: assertString(obj.questionnaireTitle),
  questionnaireType: assertEnum(obj.questionnaireType, QuestionnaireType),
  type: assertValue(obj.type, NotificationType.QUESTIONNAIRE_REMINDER),
})

export const reviewAppointmentNotificationContentNormaliser: AssertTypeFn<
  ReviewAppointmentNotificationContent
> = (obj) => ({
  appointmentId: assertString(obj.appointmentId),
  facilityName: assertString(obj.facilityName),
  facilityTimeZone: assertStringOrNull(obj.facilityTimeZone),
  startTime: assertString(obj.startTime),
  type: assertValue(obj.type, NotificationType.REVIEW_APPOINTMENT),
})

export const triageReminderNotificationContentNormaliser: AssertTypeFn<
  TriageReminderNotificationContent
> = (obj) => ({
  appointmentDateTime: assertString(obj.appointmentDateTime),
  assignedQuestionnaireId: assertString(obj.assignedQuestionnaireId),
  facilityName: assertStringOrNull(obj.facilityName),
  facilityTimeZone: assertStringOrNull(obj.facilityTimeZone),
  questionnaireTitle: assertString(obj.questionnaireTitle),
  type: assertValue(obj.type, NotificationType.TRIAGE_REMINDER),
})

export const upcomingAppointmentNotificationContentNormaliser: AssertTypeFn<
  UpcomingAppointmentNotificationContent
> = (obj) => ({
  appointmentId: assertString(obj.appointmentId),
  facilityName: assertString(obj.facilityName),
  facilityTimeZone: assertStringOrNull(obj.facilityTimeZone),
  startTime: assertString(obj.startTime),
  type: assertValue(obj.type, NotificationType.UPCOMING_APPOINTMENT),
})

export const notificationContentNormaliser: AssertTypeFn<
  NotificationContent
> = (obj) => {
  switch (obj.type) {
    case NotificationType.ACTIVE_PHYSITRACK_PROGRAM:
      return activePhysitrackProgramNotificationContentNormaliser(obj)
    case NotificationType.APPOINTMENT_CANCELLED:
      return cancelledAppointmentNotificationContentNormaliser(obj)
    case NotificationType.APPOINTMENT_RESCHEDULED:
      return rescheduledAppointmentNotificationContentNormaliser(obj)
    case NotificationType.DATA_ACCESS_REQUEST:
      return dataAccessNotificationContentNormaliser(obj)
    case NotificationType.MISSING_INFORMATION:
      return missingInformationNotificationContentNormaliser(obj)
    case NotificationType.NEW_FEATURE:
      return newFeatureNotificationContentNormaliser(obj)
    case NotificationType.NEW_FILE_SHARED:
      return fileSharingNotificationContentNormaliser(obj)
    case NotificationType.NEW_PHYSITRACK_PROGRAM:
      return newPhysitrackProgramNotificationContentNormaliser(obj)
    case NotificationType.NEW_QUESTIONNAIRE:
      return newQuestionnaireNotificationContentNormaliser(obj)
    case NotificationType.PATIENT_REPORT_MISMATCH:
      return patientReportMismatchNotificationContentNormaliser(obj)
    case NotificationType.PERIODIC_NOTIFICATION:
      return periodicNotificationContentNormaliser(obj)
    case NotificationType.QUESTIONNAIRE_REMINDER:
      return questionnaireReminderNotificationContentNormaliser(obj)
    case NotificationType.REVIEW_APPOINTMENT:
      return reviewAppointmentNotificationContentNormaliser(obj)
    case NotificationType.TRIAGE_REMINDER:
      return triageReminderNotificationContentNormaliser(obj)
    case NotificationType.UPCOMING_APPOINTMENT:
      return upcomingAppointmentNotificationContentNormaliser(obj)
    default:
      return obj
  }
}

export const notificationNormaliser: AssertTypeFn<Notification> = (obj) => ({
  content: notificationContentNormaliser(obj.content),
  createdAt: assertString(obj.createdAt),
  id: assertString(obj.id),
  patientId: assertString(obj.patientId),
})

export const notificationsNormaliser: AssertTypeFn<Notification[]> = (arr) =>
  assertArray(arr, notificationNormaliser)
