/* tslint:disable */
/* eslint-disable */
/**
 * Cushla API
 * REST API for Cushla
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Preferred positions
 * @export
 * @enum {string}
 */

export enum BirthPreferredPositionDto {
  SQUATTING = 'SQUATTING',
  KNEELING = 'KNEELING',
  STANDING = 'STANDING',
  LYING_ON_SIDE = 'LYING_ON_SIDE',
  KNEELING_ON_ALL_FOURS = 'KNEELING_ON_ALL_FOURS',
  WATER_BIRTH = 'WATER_BIRTH',
}
