import React, {ReactNode} from 'react'

import {Icon, IconName} from '../icon/Icon'
import {StyledIconButton} from './IconButton.styled'

type Props = React.HTMLAttributes<HTMLButtonElement> & {
  children?: ReactNode
  name: IconName
  color?: 'primary' | 'secondary'
  disabled?: boolean
  onClick?: React.MouseEventHandler<HTMLButtonElement>
}

export const IconButton: React.FC<Props> = ({
  name,
  color = 'primary',
  disabled = false,
  onClick,
  ...buttonProps
}) => (
  <StyledIconButton
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...buttonProps}
    name={name}
    color={color}
    disabled={disabled}
    onClick={onClick}>
    <Icon name={name} size="sm" />
  </StyledIconButton>
)
