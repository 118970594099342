import {
  getPagedResponseNormaliser,
  lifestyleInformationNormaliser,
  practitionersPatientNormaliser,
  staticFileNormaliser,
  staticFileOrNullNormaliser,
} from '@common/normalisers'
import {
  DataRequestUrlParams,
  endpointKeys,
  FileUrlParams,
  PatientIdUrlParam,
  PractitionerPatientsUrlParams,
  PractitionerPatientUrlParams,
} from '@common/api'
import {
  DataPermissionsRequest,
  PractitionersPatient,
  PagedResponse,
  LifestyleInformation,
  StaticFile,
} from '@common/models'
import {UseQueryOptions} from '@tanstack/react-query'

import {useApi, useWebGet, useWebInfiniteGet, useWebSet} from './helpers'

export const useGetPractitionerPatients = (
  params: PractitionerPatientsUrlParams,
) =>
  useWebGet<PagedResponse<PractitionersPatient>>(
    [endpointKeys.practitionerPatients, params],
    useApi().practitionerPatients(params),
    getPagedResponseNormaliser(practitionersPatientNormaliser),
  )

export const useInfiniteGetPractitionerPatients = (
  params: PractitionerPatientsUrlParams,
) =>
  useWebInfiniteGet<PagedResponse<PractitionersPatient>>(
    [endpointKeys.practitionerPatients, params],
    useApi().practitionerPatients(params),
    getPagedResponseNormaliser(practitionersPatientNormaliser),
  )

export const useGetPractitionerPatient = (
  params: PractitionerPatientUrlParams,
) =>
  useWebGet<PractitionersPatient>(
    [endpointKeys.practitionerPatient, params],
    useApi().practitionerPatient(params),
    practitionersPatientNormaliser,
  )

export const useRequestPatientData = (params: DataRequestUrlParams) =>
  useWebSet<DataPermissionsRequest>(
    [endpointKeys.dataRequest, params],
    useApi().dataRequest(params),
    'POST',
    undefined,
    [[endpointKeys.practitionerPatient], [endpointKeys.practitionerPatients]],
  )

export const useGetPatientLifestyleInformation = (params: PatientIdUrlParam) =>
  useWebGet<LifestyleInformation>(
    [endpointKeys.patientLifestyleInformation, params],
    useApi().patientLifestyleInformation(params),
    lifestyleInformationNormaliser,
  )

export const useGetConsultationFileUrl = (
  params: FileUrlParams,
  options: UseQueryOptions<StaticFile, Error>,
) =>
  useWebGet<StaticFile>(
    [endpointKeys.consultationFile, params],
    useApi().consultationFile(params),
    staticFileNormaliser,
    {
      cacheTime: 0,
      ...options,
    },
  )

export const useGetMedicalRecordFileUrl = (
  params: FileUrlParams,
  options: UseQueryOptions<StaticFile, Error>,
) =>
  useWebGet<StaticFile>(
    [endpointKeys.medicalRecordFile, params],
    useApi().medicalRecordFile(params),
    staticFileNormaliser,
    {
      cacheTime: 0,
      ...options,
    },
  )

export const useGetSourceFileUrl = (
  params: FileUrlParams,
  options: UseQueryOptions<StaticFile, Error>,
) =>
  useWebGet<StaticFile>(
    [endpointKeys.fileUploadSource, params],
    useApi().fileUploadSource(params),
    staticFileNormaliser,
    {
      cacheTime: 0,
      ...options,
    },
  )

export const useGetPatientProfilePicture = (
  params: PatientIdUrlParam,
  options?: UseQueryOptions<StaticFile | null, Error>,
) =>
  useWebGet<StaticFile | null>(
    [endpointKeys.patientProfilePicture, params],
    useApi().patientProfilePicture(params),
    staticFileOrNullNormaliser,
    options,
  )
