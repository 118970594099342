import * as React from 'react'

import {IconProps} from '.'

function Shield({color, size, isFilled}: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={isFilled ? color : 'none'}
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 11.1822C20 15.7232 16.587 19.9692 12 21.0002C7.413 19.9692 4 15.7232 4 11.1822V7.24324C4 6.43124 4.491 5.69924 5.243 5.39224L10.243 3.34624C11.369 2.88524 12.631 2.88524 13.757 3.34624L18.757 5.39224C19.509 5.70024 20 6.43124 20 7.24324V11.1822V11.1822Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 9.642C12.268 9.34 12.731 9 13.437 9C14.671 9 15.5 10.117 15.5 11.158C15.5 13.333 12.692 15 12 15C11.308 15 8.5 13.333 8.5 11.158C8.5 10.117 9.328 9 10.563 9C11.269 9 11.732 9.34 12 9.642Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default Shield
