import styled from 'styled-components'

import {Accordion} from '~/components/general/accordion/Accordion'
import {Typography} from '~/components/general/typography/Typography'

export const StyledAccordion = styled(Accordion)`
  margin-bottom: ${({theme}) => theme.spacing.md};
`

export const StyledHeading = styled(Typography)`
  margin-top: ${({theme}) => theme.spacing.xl};
  margin-bottom: ${({theme}) => theme.spacing.lg};
`
