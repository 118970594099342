import React, {useContext, useMemo, useState} from 'react'
import {HealthRecordsUrlParams} from '@common/api/types'
import {PagedResponse, PermissionCategory} from '@common/models'

import {StyledAccordion} from './HealthCategoriesPage.styled'

import {PermissionCheck} from '~/components/patients/common/requestData/PermissionCheck'
import {
  HealthRecordModal,
  HealthRecordModalData,
} from '~/components/patients/common/healthRecordModal/HealthRecordModal'
import {PatientsContext} from '~/components/patients/PatientsContext'
import {useWebGet} from '~/api'
import {SimplePaginatedTable} from '~/components/general/table/SimplePaginatedTable'
import {Loading} from '~/components/general/loading/Loading'
import {SimpleTableHeaderProps} from '~/components/general/table/SimpleTableHeader'
import {SimpleTableRowProps} from '~/components/general/table/SimpleTableRow'
import {
  DataAccordionContent,
  DataAccordionEntry,
} from '~/components/general/accordion/DataAccordionContent'

type AccordionData = {
  title: string
  data: DataAccordionEntry[]
}

type HealthCategoryPageContentProps<T extends PagedResponse<unknown>> = {
  endpointKey: string
  getUrl: (params: HealthRecordsUrlParams) => URL
  header: SimpleTableHeaderProps
  getRows: (data: T) => SimpleTableRowProps[]
  getAccordionData?: (data?: T) => AccordionData
  getModalData?: (index: number, data: T) => HealthRecordModalData
  normalizer: (data: T) => T
}

export type HealthCategoryPageProps<T> = {
  permission: PermissionCategory
} & HealthCategoryPageContentProps<PagedResponse<T>>

const HealthCategoryPageContent = <T extends PagedResponse<unknown>>({
  endpointKey,
  getUrl,
  getRows,
  header,
  getAccordionData,
  getModalData,
  normalizer,
}: HealthCategoryPageContentProps<T>) => {
  const {patient} = useContext(PatientsContext)
  // Missing id is handled in PatientDetail
  const patientId = patient?.patientId as string

  const [currentPageIndex, setCurrentPageIndex] = useState(0)
  const [isRecordModalVisible, setIsRecordModalVisible] = useState(false)
  const [selectedModalData, setSelectedModalData] =
    useState<HealthRecordModalData>()

  const {data, isInitialLoading, isFetching} = useWebGet<T>(
    [endpointKey, patientId, currentPageIndex],
    getUrl({
      patientId,
      page: currentPageIndex,
    }),
    normalizer,
  )

  const accordionData = useMemo(
    () => getAccordionData && getAccordionData(data),
    [data, getAccordionData],
  )

  const rowsData = useMemo(() => {
    if (!data) {
      return []
    }

    if (!getModalData) {
      return getRows(data)
    }

    return getRows(data).map((row, index) => ({
      ...row,
      onRowClick: () => {
        setSelectedModalData(getModalData(index, data))
        setIsRecordModalVisible(true)
      },
    }))
  }, [data, getModalData, getRows])

  if (isInitialLoading) {
    return <Loading />
  }

  return (
    <>
      {!!accordionData && (
        <StyledAccordion title={accordionData.title}>
          <DataAccordionContent data={accordionData.data} />
        </StyledAccordion>
      )}
      <SimplePaginatedTable
        header={header}
        rows={rowsData}
        isLoading={isFetching}
        paginationData={data?.page}
        onPageClick={setCurrentPageIndex}
        showSourceIcons
      />
      <HealthRecordModal
        isVisible={isRecordModalVisible}
        onClose={() => setIsRecordModalVisible(false)}
        data={selectedModalData}
        fileEndpoint="medicalRecord"
      />
    </>
  )
}

export const HealthCategoryPage = <T,>({
  permission,
  ...props
}: HealthCategoryPageProps<T>) => (
  <PermissionCheck permission={permission}>
    <HealthCategoryPageContent {...props} />
  </PermissionCheck>
)
