import React, {useMemo} from 'react'
import {CompletedQuestionnaireAnswer, Question} from '@common/models'
import {parseQuestionnaireAnswers} from '@common/utils'

import {
  QuestionnairePreviewWrapper,
  StyledQuestionnairePreview,
} from './QuestionnairePreview.styled'
import {QuestionCard} from './QuestionCard'

import {Typography} from '~/components/general/typography/Typography'
import {LayoutContainer} from '~/components/layout/container/LayoutContainer'
import {Container} from '~/components/general/container/Container'
import {theme} from '~/styles/theme'

type QuestionnairePreviewProps = {
  questions: Question[]
  answers?: CompletedQuestionnaireAnswer[] | null
  title?: string
}

export const QuestionnairePreview: React.FC<QuestionnairePreviewProps> = ({
  answers,
  questions,
  title,
}) => {
  const parsedAnswers = useMemo(
    () => parseQuestionnaireAnswers(questions, answers ?? []),
    [questions, answers],
  )

  return (
    <QuestionnairePreviewWrapper>
      <LayoutContainer size="small">
        {!!title && (
          <Typography color={theme.colors.grey.main}>{title}</Typography>
        )}
        <StyledQuestionnairePreview>
          <Container flex direction="column" spacing={2}>
            {questions.map((question) => (
              <QuestionCard
                key={question.id}
                question={question}
                answer={parsedAnswers[question.id] ?? undefined}
              />
            ))}
          </Container>
        </StyledQuestionnairePreview>
      </LayoutContainer>
    </QuestionnairePreviewWrapper>
  )
}
