import {
  MyProfilePermissionSubcategory,
  PermissionCategory,
  PractitionersPatient,
} from '@common/models'
import React, {useContext, useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {PatientDataAccordion} from '../common/patientDataAccordion/PatientDataAccordion'
import {NameAccordionContent} from './NameAccordionContent'
import {ContactDetailsAccordionContent} from './ContactDetailsAccordionContent'
import {AddressAccordionContent} from './AddressAccordionContent'
import {DemographicDetailsAccordionContent} from './DemographicDetailsAccordionContent'
import {NextOfKinAccordionContent} from './NextOfKinAccordionContent'
import {OrganDonorAccordionContent} from './OrganDonorAccordionContent'
import {InsuranceAccordionContent} from './InsuranceAccordionContent'

import {IconName} from '~/components/general/icon/Icon'
import {PatientsContext} from '~/components/patients/PatientsContext'
import {Container} from '~/components/general/container/Container'

type AccordionData = {
  title: string
  iconName: IconName
  permission: PermissionCategory
  ContentComponent: React.FC<{patient: PractitionersPatient}>
}

export const PersonalDetail: React.FC = () => {
  const {t} = useTranslation()
  const {patient} = useContext(PatientsContext)

  const accordionData: AccordionData[] = useMemo(
    () => [
      {
        title: t('Name details'),
        permission: MyProfilePermissionSubcategory.NAME_DETAILS,
        iconName: 'User',
        ContentComponent: NameAccordionContent,
      },
      {
        title: t('Contact details'),
        permission: MyProfilePermissionSubcategory.CONTACT_DETAILS,
        iconName: 'Contact',
        ContentComponent: ContactDetailsAccordionContent,
      },
      {
        title: t('Address'),
        permission: MyProfilePermissionSubcategory.ADDRESS,
        iconName: 'Home',
        ContentComponent: AddressAccordionContent,
      },
      {
        title: t('Demographic details'),
        permission: MyProfilePermissionSubcategory.DEMOGRAPHIC_DETAILS,
        iconName: 'World',
        ContentComponent: DemographicDetailsAccordionContent,
      },
      {
        title: t('Emergency contacts'),
        permission: MyProfilePermissionSubcategory.NEXT_OF_KIN,
        iconName: 'UserGroup',
        ContentComponent: NextOfKinAccordionContent,
      },
      {
        title: t('Insurance & Identifiers'),
        permission: MyProfilePermissionSubcategory.HEALTH_INSURANCE,
        iconName: 'DocumentList',
        ContentComponent: InsuranceAccordionContent,
      },
      {
        title: t('Organ donor consent'),
        permission: MyProfilePermissionSubcategory.ORGAN_DONOR,
        iconName: 'HeartBeat',
        ContentComponent: OrganDonorAccordionContent,
      },
    ],
    [t],
  )

  if (!patient) {
    return null
  }

  return (
    <Container flex direction="column" spacing={2}>
      {accordionData.map((category) => (
        <PatientDataAccordion
          key={category.title}
          iconName={category.iconName}
          title={category.title}
          permission={category.permission}>
          <category.ContentComponent patient={patient} />
        </PatientDataAccordion>
      ))}
    </Container>
  )
}
