import {
  AddAppointmentSummaryToAppointmentDto,
  SaveAppointmentSummaryDto,
} from '@generated/models'

import {UploadFile} from './files'

export type {
  GetAppointmentDto as Appointment,
  AppointmentLinkJourneyDto as AppointmentHealthJourneyLinkRequest,
  GetAppointmentNoteDto as AppointmentNote,
  GetAppointmentSummaryDto as AppointmentSummary,
  AddAppointmentSummaryMedicationDto as AddAppointmentSummaryMedication,
  GetAppointmentSummaryMedicationDto as AppointmentSummaryMedication,
  GetAppointmentSurveyDto as AppointmentSurvey,
  GetScreeningConsultationDto as ScreeningConsultation,
  SaveAppointmentRequest as AddAppointmentRequest,
  SaveAppointmentSurveyDto as AddAppointmentSurveyRequest,
  SaveAppointmentSummaryDto as AddAppointmentSummaryRequestBody,
  AddAppointmentSummaryToAppointmentDto as AddSummaryToAppointmentRequestBody,
  SaveClinikoAppointmentRequest as AddClinikoAppointmentRequest,
  AvailableSlotsDto as AppointmentSlots,
  AvailableSlotDto as AppointmentSlot,
  CancelAppointmentDto as CancelAppointmentRequest,
  ChangeAppointmentFacilityDto as ChangeAppointmentFacilityRequest,
  ClinikoAppointmentDto as ClinikoAppointment,
  ClinikoAppointmentPhysitrackProgramDto as ClinikoAppointmentPhysitrackProgram,
  ConsultationDto as Consultation,
  RescheduleAppointmentRequest,
  SaveAppointmentNoteDto as SaveAppointmentNoteRequest,
  UpdateConsultationRequest as UpdateConsultation,
  PractitionerCreateAppointmentRequest,
  PractitionerCreateAppointmentResponse,
} from '@generated/models'

export {
  AppointmentTypeDto as AppointmentType,
  AppointmentCancellationReasonDto as AppointmentCancellationReason,
  ConsultationSourceDto as ConsultationSource,
  DataOrigin,
} from '@generated/models'

export type AddAppointmentSummaryRequest = {
  body: SaveAppointmentSummaryDto
  files: UploadFile[]
}

export type AddSummaryToAppointmentRequest = {
  body: AddAppointmentSummaryToAppointmentDto
  files: UploadFile[]
}
