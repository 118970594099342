import * as React from 'react'

import {IconProps} from '.'

function HDLDrop({color, size, isFilled}: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={isFilled ? color : 'none'}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.25 13.25C18.25 7.625 12 3.25 12 3.25C12 3.25 5.75 7.625 5.75 13.25C5.75 14.9076 6.40848 16.4973 7.58058 17.6694C8.75269 18.8415 10.3424 19.5 12 19.5C13.6576 19.5 15.2473 18.8415 16.4194 17.6694C17.5915 16.4973 18.25 14.9076 18.25 13.25Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.19825 16V13.22H8.79425V14.316H10.0303V13.22H10.6263V16H10.0303V14.86H8.79425V16H8.19825ZM11.21 16V13.22H12.306C12.5593 13.22 12.7926 13.272 13.006 13.376C13.2193 13.4773 13.3953 13.636 13.534 13.852C13.6726 14.068 13.742 14.3213 13.742 14.612C13.742 14.9027 13.6726 15.156 13.534 15.372C13.3953 15.5853 13.2193 15.744 13.006 15.848C12.7926 15.9493 12.5593 16 12.306 16H11.21ZM11.806 15.456H12.242C12.4926 15.456 12.702 15.388 12.87 15.252C13.038 15.1133 13.122 14.8987 13.122 14.608C13.122 14.32 13.038 14.108 12.87 13.972C12.702 13.8333 12.4926 13.764 12.242 13.764H11.806V15.456ZM14.1904 16V13.22H14.7864V15.456H16.0544V16H14.1904Z"
        fill={color}
      />
    </svg>
  )
}

export default HDLDrop
