import * as React from 'react'

import {IconProps} from '.'

function Clinic({color, size, isFilled}: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={isFilled ? color : 'none'}
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.2517 9.99951C15.2517 9.5853 15.5875 9.24951 16.0017 9.24951H20.0034C20.9701 9.24951 21.7538 10.0332 21.7538 10.9999V21.0041C21.7538 21.4183 21.418 21.7541 21.0038 21.7541C20.5896 21.7541 20.2538 21.4183 20.2538 21.0041V10.9999C20.2538 10.8616 20.1417 10.7495 20.0034 10.7495H16.0017C15.5875 10.7495 15.2517 10.4137 15.2517 9.99951Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.99675 4.74658C3.85845 4.74658 3.74634 4.8587 3.74634 4.997V21.0037C3.74634 21.4179 3.41055 21.7537 2.99634 21.7537C2.58212 21.7537 2.24634 21.4179 2.24634 21.0037V4.997C2.24634 4.03027 3.03003 3.24658 3.99675 3.24658H15.0013C15.9681 3.24658 16.7518 4.03027 16.7518 4.997V21.0037C16.7518 21.4179 16.416 21.7537 16.0018 21.7537C15.5875 21.7537 15.2518 21.4179 15.2518 21.0037V4.997C15.2518 4.8587 15.1396 4.74658 15.0013 4.74658H3.99675Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.0002 21.0039C13.0002 21.4181 12.6645 21.7539 12.2502 21.7539H6.74795C6.33374 21.7539 5.99795 21.4181 5.99795 21.0039L5.99795 17.0022C5.99795 16.588 6.33374 16.2522 6.74795 16.2522H12.2502C12.6645 16.2522 13.0002 16.588 13.0002 17.0022V21.0039ZM11.5002 20.2539V17.7522H7.49795L7.49795 20.2539H11.5002Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.49902 16.2524C9.91324 16.2524 10.249 16.5882 10.249 17.0024V21.0041C10.249 21.4183 9.91324 21.7541 9.49902 21.7541C9.08481 21.7541 8.74902 21.4183 8.74902 21.0041V17.0024C8.74902 16.5882 9.08481 16.2524 9.49902 16.2524Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.24609 21.0039C1.24609 20.5897 1.58188 20.2539 1.99609 20.2539H22.0044C22.4186 20.2539 22.7544 20.5897 22.7544 21.0039C22.7544 21.4181 22.4186 21.7539 22.0044 21.7539H1.99609C1.58188 21.7539 1.24609 21.4181 1.24609 21.0039Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.49902 8.24902C9.91324 8.24902 10.249 8.58481 10.249 8.99902V12.0003C10.249 12.4145 9.91324 12.7503 9.49902 12.7503C9.08481 12.7503 8.74902 12.4145 8.74902 12.0003V8.99902C8.74902 8.58481 9.08481 8.24902 9.49902 8.24902Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.24854 10.4995C7.24854 10.0853 7.58432 9.74951 7.99854 9.74951H10.9998C11.414 9.74951 11.7498 10.0853 11.7498 10.4995C11.7498 10.9137 11.414 11.2495 10.9998 11.2495H7.99854C7.58432 11.2495 7.24854 10.9137 7.24854 10.4995Z"
        fill={color}
      />
    </svg>
  )
}

export default Clinic
