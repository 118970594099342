import React, {MouseEventHandler} from 'react'
import {useTranslation} from 'react-i18next'

import {
  StyledTimelineCard,
  TimelineDotWrap,
  TimelineDot,
  TimelineIcon,
} from './TimelineCard.styled'

import {Icon, IconName} from '~/components/general/icon/Icon'
import {Typography} from '~/components/general/typography/Typography'
import {Container} from '~/components/general/container/Container'
import {theme} from '~/styles/theme'
import {manualRecordIconName} from '~/icons'

export type TimelineCardProps = {
  title: string
  subtitle?: string | null
  note?: string
  icon?: IconName
  isParsed?: boolean
  subcard?: boolean
  danger?: boolean
  direction?: 'row' | 'column'
  url?: string
  onClick?: MouseEventHandler<HTMLDivElement>
}

export const TimelineCard: React.FC<TimelineCardProps> = ({
  title,
  subtitle,
  note,
  icon,
  isParsed,
  subcard,
  danger,
  direction,
  url,
  onClick,
}) => {
  const {t} = useTranslation()

  return (
    <StyledTimelineCard subcard={subcard} danger={danger} onClick={onClick}>
      <TimelineDotWrap subcard={subcard}>
        <TimelineDot subcard={subcard} danger={danger} />
      </TimelineDotWrap>
      <Container flex align="center" spacing={1.5}>
        {icon && (
          <TimelineIcon>
            <Icon name={icon} size="md" />
          </TimelineIcon>
        )}
        <Container
          flex
          direction={direction}
          align={direction === 'row' ? 'center' : 'flex-start'}
          spacing={direction === 'row' ? 2 : 0.5}>
          <Typography weight="medium">
            {url && (
              <a href={url} target="_blank" rel="noreferrer">
                {title}
              </a>
            )}
            {!url && title}
          </Typography>
          <Typography color={theme.colors.grey.main} size="sm">
            {subtitle}
          </Typography>
        </Container>
      </Container>
      <Container flex direction="row" spacing={1} align="center">
        {note && (
          <Typography color={theme.colors.grey.main} size="sm">
            {note}
          </Typography>
        )}
        <Icon
          name={isParsed ? 'Pdf' : manualRecordIconName}
          title={isParsed ? t('Parsed from pdf') : t('Added manually')}
        />
      </Container>
    </StyledTimelineCard>
  )
}
