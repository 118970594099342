import {useTranslation} from 'react-i18next'
import React, {useContext, useMemo} from 'react'
import {
  calculateAlcoholUnits,
  EnumType,
  formatAlcoholText,
  formatDate,
  formatLocaleNumber,
  formatWaterConsumptionText,
  getHealthDataGrades,
  getTobaccoGrade,
  MISSING_ID,
  monthYearDateFormat,
  useAlcoholConsumptionTexts,
  useEnumTranslations,
  useTranslateBooleanValue,
} from '@common/utils'
import {
  BasePermissionCategory,
  HealthDataType,
  IsSmoker,
  MonthlySurveyType,
} from '@common/models'

import {StyledAccordion} from './HealthCategoriesPage.styled'

import {PatientsContext} from '~/components/patients/PatientsContext'
import {Loading} from '~/components/general/loading/Loading'
import {DataAccordionContent} from '~/components/general/accordion/DataAccordionContent'
import {PermissionCheck} from '~/components/patients/common/requestData/PermissionCheck'
import {
  useGetPatientLifestyleInformation,
  useInfiniteGetMeasurements,
} from '~/api'

const LifestyleInformationPageContent: React.FC = () => {
  const {t} = useTranslation()
  const translateBooleanValue = useTranslateBooleanValue()
  const {patient} = useContext(PatientsContext)
  const {translateEnumValue} = useEnumTranslations()

  const {data: lifestyleData, isInitialLoading: isLifestyleDataLoading} =
    useGetPatientLifestyleInformation({
      patientId: patient?.patientId ?? MISSING_ID,
    })

  const {data: sleepData, isInitialLoading: isSleepDataLoading} =
    useInfiniteGetMeasurements({
      patientId: patient?.patientId,
      surveyType: MonthlySurveyType.SLEEP,
    })

  const {data: moodData, isInitialLoading: isMoodDataLoading} =
    useInfiniteGetMeasurements({
      patientId: patient?.patientId,
      surveyType: MonthlySurveyType.MOOD,
    })

  const {data: eatingHabitsData, isInitialLoading: isEatingHabitsDataLoading} =
    useInfiniteGetMeasurements({
      patientId: patient?.patientId,
      surveyType: MonthlySurveyType.EATING_HABITS,
    })

  const anchor = window.location.hash.slice(1)
  const alcoholConsumptionValues = useAlcoholConsumptionTexts(
    lifestyleData?.alcohol,
  )

  const accordionData = useMemo(() => {
    const sleepRecords = sleepData?.pages[0]?.records
    const moodRecords = moodData?.pages[0]?.records
    const eatingHabitsRecords = eatingHabitsData?.pages[0]?.records

    const latestSleepValue = sleepRecords?.[0]?.value
    const previousSleepValue = sleepRecords?.[1]?.value

    const latestMoodValue = moodRecords?.[0]?.value
    const previousMoodValue = moodRecords?.[1]?.value

    const latestEatingHabitsValue = eatingHabitsRecords?.[0]?.value
    const previousEatingHabitsValue = eatingHabitsRecords?.[1]?.value

    const stoppedSmokingRow =
      lifestyleData?.tobacco?.smoker === IsSmoker.NOT_ANYMORE
        ? [
            {
              title: t('Stopped smoking'),
              value: formatDate(
                monthYearDateFormat,
                lifestyleData?.tobacco.stoppedYearMonth,
              ),
            },
          ]
        : []

    return [
      {
        id: 'body-measurements',
        title: t('Body measurements'),
        data: [
          {
            title: t('Height'),
            value: lifestyleData?.measurements?.height
              ? `${formatLocaleNumber(lifestyleData.measurements.height, 1)} cm`
              : '-',
          },
          {
            title: t('Weight'),
            value: lifestyleData?.measurements?.weight
              ? `${formatLocaleNumber(lifestyleData.measurements.weight, 1)} kg`
              : '-',
          },
          {
            title: t('Waist circumference'),
            ...getHealthDataGrades(
              HealthDataType.WAIST_CIRCUMFERENCE_CM,
              lifestyleData?.measurements?.waistCircumference,
              undefined,
              patient,
            ),
            value: lifestyleData?.measurements?.waistCircumference
              ? `${formatLocaleNumber(
                  lifestyleData.measurements.waistCircumference,
                  1,
                )} cm`
              : '-',
          },
          {
            title: t('BMI'),
            ...getHealthDataGrades(
              HealthDataType.BMI,
              lifestyleData?.measurements?.bmi,
              undefined,
              patient,
            ),
            value: lifestyleData?.measurements?.bmi
              ? `${formatLocaleNumber(
                  lifestyleData.measurements.bmi,
                  1,
                )} kg/m\u00B2`
              : '-',
          },
        ],
      },
      {
        id: 'alcohol',
        title: t('Alcohol'),
        data: [
          {
            title: alcoholConsumptionValues.length
              ? t('Alcohol consumption per week')
              : t('Not drinking alcohol'),
            value: alcoholConsumptionValues,
          },
          {
            title: t('Units per week'),
            ...getHealthDataGrades(
              HealthDataType.ALCOHOL_SCORE,
              calculateAlcoholUnits(lifestyleData?.alcohol),
              undefined,
              patient,
            ),
            value: formatAlcoholText(t, lifestyleData?.alcohol),
          },
        ],
      },
      {
        id: 'tobacco',
        title: t('Tobacco usage'),
        data: [
          {
            title: t('Smoker'),
            value: translateEnumValue(
              EnumType.IsSmoker,
              lifestyleData?.tobacco?.smoker,
            ),
            grade: getTobaccoGrade(lifestyleData?.tobacco),
          },
          ...stoppedSmokingRow,
          {
            title: t('How much do you smoke per day?'),
            value: lifestyleData?.tobacco?.amount?.toLocaleString(),
          },
          {
            title: t('Tobacco type'),
            value: translateEnumValue(
              EnumType.TobaccoType,
              lifestyleData?.tobacco?.tobaccoType,
            ),
          },
          {
            title: t('Years smoking'),
            value: lifestyleData?.tobacco?.period?.toLocaleString(),
          },
        ],
      },
      {
        id: 'sleep',
        title: t('Sleep'),
        data: [
          {
            title: t('Sleep survey score'),
            ...getHealthDataGrades(
              HealthDataType.SLEEP_SCORE,
              latestSleepValue,
              previousSleepValue,
              patient,
            ),
            value: latestSleepValue?.toLocaleString(),
          },
          {
            title: t('How many hours sleep do you normally get?'),
            value: lifestyleData?.sleepOverview?.hoursSlept?.toLocaleString(),
          },
          {
            title: t(
              'Do you usually feel refreshed when you wake up in the morning?',
            ),
            value: translateBooleanValue(
              lifestyleData?.sleepOverview?.doesFeelRefreshed,
            ),
          },
          {
            title: t(
              'Do you work shift patterns or have a lifestyle which disrupts your sleep routine?',
            ),
            value: translateBooleanValue(
              lifestyleData?.sleepOverview?.doesWorkDisrupt,
            ),
          },
        ],
      },
      {
        id: 'diet',
        title: t('Eating habits'),
        data: [
          {
            title: t('Eating habits survey score'),
            ...getHealthDataGrades(
              HealthDataType.DIET_SCORE,
              latestEatingHabitsValue,
              previousEatingHabitsValue,
              patient,
            ),
            value: latestEatingHabitsValue?.toLocaleString(),
          },
          {
            title: t('How would you describe your diet?'),
            value: lifestyleData?.diet?.value.label,
          },
          {
            title: t('Daily water consumption'),
            ...getHealthDataGrades(
              HealthDataType.WATER_CONSUMPTION_ML,
              lifestyleData?.measurements?.waterConsumption,
              undefined,
              patient,
            ),
            value: formatWaterConsumptionText(
              t,
              lifestyleData?.measurements?.waterConsumption,
            ),
          },
        ],
      },
      {
        id: 'mood',
        title: t('Mood'),
        data: [
          {
            title: t('Mood score'),
            ...getHealthDataGrades(
              HealthDataType.MOOD_SCORE,
              latestMoodValue,
              previousMoodValue,
              patient,
            ),
            value: latestSleepValue?.toLocaleString(),
          },
        ],
      },
    ]
  }, [
    alcoholConsumptionValues,
    eatingHabitsData,
    lifestyleData,
    moodData,
    patient,
    sleepData,
    t,
    translateBooleanValue,
    translateEnumValue,
  ])

  const isLoading =
    isLifestyleDataLoading ||
    isSleepDataLoading ||
    isEatingHabitsDataLoading ||
    isMoodDataLoading

  if (isLoading) {
    return <Loading />
  }

  return (
    <>
      {accordionData.map((accordionItem) => (
        <StyledAccordion
          isOpenByDefault={accordionItem.id === anchor}
          title={accordionItem.title}
          key={accordionItem.id}>
          <DataAccordionContent data={accordionItem.data} showGrading />
        </StyledAccordion>
      ))}
    </>
  )
}

export const LifestyleInformationPage: React.FC = () => (
  <PermissionCheck permission={BasePermissionCategory.LIFESTYLE_INFORMATION}>
    <LifestyleInformationPageContent />
  </PermissionCheck>
)
