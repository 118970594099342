const SPACING_UNIT = 8

export const spacing = (multiplier: number) =>
  Math.floor(multiplier * SPACING_UNIT)

export const theme = {
  height: {
    input: {
      small: '35px',
      normal: '56px',
      large: '64px',
    },
    topBar: '80px',
    tableHeader: '48px',
    tableRow: '64px',
    menuItem: '48px',
  },
  spacing: {
    xxs: `${spacing(0.5)}px`,
    xs: `${spacing(1)}px`,
    sm: `${spacing(1.5)}px`,
    md: `${spacing(2)}px`,
    lg: `${spacing(3)}px`,
    xl: `${spacing(4)}px`,
    xxl: `${spacing(5)}px`,
    xxxl: `${spacing(6)}px`,
  },
  colors: {
    primary: {
      main: '#002A7E',
      blue900: '#0A2458',
      blue200: '#A3BEF5',
      blue100: '#D1DFFA',
      blue50: '#EEF1FA',
      blue25: '#F4F6FB',
      orange50: '#FDF0ED',
      orange300: '#F4A592',
    },
    secondary: {
      main: '#F56F50',
      orange600: '#DB5738',
      orange200: '#FAB7A7',
      orange50: '#FDEFEC',
    },
    grey: {
      main: '#71809C',
      grey600: '#5B677B',
      grey500: '#8494B4',
      grey400: '#8B95A7',
      grey300: '#A5AEC0',
      grey150: '#DBE0EB',
      grey100: '#E5E9F1',
      grey90: '#E7EAF2',
      grey50: '#EFF1F6',
      grey40: '#F4F5F9',
      grey20: '#FAFBFD',
    },
    accent: {
      warning100: '#FAF0D5',
    },
    other: {
      graphic: '#69D2E7',
    },
    buttonHover: '#E1E5F2',
    inputText: '#0E1E40',
    inputPlaceholder: '#657490',
    black: '#2E353E',
    red: '#E83D17',
    white: '#FFFFFF',
    brown: '#9C7171',
    inputBorder: '#E1E5F2',
    modalOverlay: 'rgba(0, 0, 0, 0.24)',
    shadow: 'rgba(46, 50, 145, 0.06)',
    chip: {
      text: {
        red: '#E80000',
        yellow: '#A77800',
        green: '#008056',
        black: '#2E353E',
      },
      background: {
        red: '#FEF3F1',
        yellow: '#FDF8ED',
        green: '#EBFAF5',
        black: '#EEF0F5',
      },
    },
    indicator: {
      body: {
        red: '#E83D17',
        orange: '#E7A900',
        green: '#67CE67',
        grey: '#71809C',
        blue: '#002A7E',
      },
      border: {
        red: 'rgba(232, 61, 23, 0.5)',
        orange: 'rgba(231, 169, 0, 0.5)',
        green: 'rgba(103, 206, 103, 0.5)',
        grey: 'rgba(113, 128, 156, 0.5)',
        blue: 'rgba(0, 42, 126, 0.5)',
      },
    },
  },
  borderRadius: {
    xxs: '4px',
    xs: '8px',
    sm: '12px',
    md: '16px',
    lg: '40px',
    xl: '48px',
    circled: '50%',
  },
  fontSize: {
    xxs: '7px',
    xs: '9px',
    sm: '12px',
    md: '14px',
    lg: '16px',
    xl: '18px',
    xxl: '20px',
    h3: '24px',
    h2: '40px',
    h1: '48px',
  },
  lineHeight: {
    xxs: '10px',
    xs: '14px',
    sm: '18px',
    md: '20px',
    lg: '24px',
    xl: '24px',
    xxl: '24px',
    h3: '30px',
    h2: '48px',
    h1: '56px',
  },
  fontWeight: {
    normal: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
  },
  boxShadow: {
    popup: '10px 10px 48px #E7E8EA',
    card: '0px 4px 40px #EBECEF',
    select: '10px 10px 48px rgba(51, 65, 82, 0.12)',
    tooltip: '0px 4px 4px rgba(0, 42, 126, 0.13)',
  },
}
