import React from 'react'

import {TableCellIconButtonPopup} from './PatientsTable.styled'

import {Typography} from '~/components/general/typography/Typography'
import {TableRow, TableCell} from '~/components/general/table/Table.styled'
import {PopupMenuItem} from '~/components/general/popup/PopupMenu'
import {theme} from '~/styles/theme'

export type PatientsTableRowData = {
  patientId: string
  patientName: string
  lastAppointmentDate: string
  dateOfBirth: string
  address: string
  menuItems: PopupMenuItem[]
}

export type PatientsTableRowHandlers = {
  onRowClick: (patientId: string) => void
}

type PatientsTableRowProps = PatientsTableRowHandlers & PatientsTableRowData

export const PatientsTableRow: React.FC<PatientsTableRowProps> = ({
  patientName,
  lastAppointmentDate,
  dateOfBirth,
  address,
  patientId,
  onRowClick,
  menuItems,
}) => {
  const unread = true
  return (
    <TableRow onClick={() => onRowClick(patientId)}>
      <TableCell title={patientName}>
        <Typography
          ellipsis
          color={
            unread ? theme.colors.primary.blue900 : theme.colors.grey.grey600
          }
          weight={unread ? 'medium' : 'normal'}>
          {patientName}
        </Typography>
      </TableCell>
      <TableCell title={dateOfBirth}>
        <Typography
          ellipsis
          color={
            unread ? theme.colors.primary.blue900 : theme.colors.grey.grey600
          }
          weight={unread ? 'medium' : 'normal'}>
          {dateOfBirth}
        </Typography>
      </TableCell>
      <TableCell title={address}>
        <Typography
          ellipsis
          color={
            unread ? theme.colors.primary.blue900 : theme.colors.grey.grey600
          }
          weight={unread ? 'medium' : 'normal'}>
          {address}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography
          ellipsis
          color={
            unread ? theme.colors.primary.blue900 : theme.colors.grey.grey600
          }
          weight={unread ? 'medium' : 'normal'}>
          {lastAppointmentDate}
        </Typography>
      </TableCell>
      {menuItems.length > 0 && (
        <TableCell>
          <TableCellIconButtonPopup menuItems={menuItems} />
        </TableCell>
      )}
    </TableRow>
  )
}
