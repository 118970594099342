import * as React from 'react'

import {IconProps} from '.'

function CheckboxOff({color, size, isFilled}: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={isFilled ? color : 'none'}
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.24609 7.99658C2.24609 4.82094 4.82046 2.24658 7.99609 2.24658H16.0036C19.1792 2.24658 21.7536 4.82095 21.7536 7.99658V16.0041C21.7536 19.1797 19.1792 21.7541 16.0036 21.7541H7.99609C4.82045 21.7541 2.24609 19.1797 2.24609 16.0041V7.99658ZM7.99609 3.74658C5.64888 3.74658 3.74609 5.64937 3.74609 7.99658V16.0041C3.74609 18.3513 5.64888 20.2541 7.99609 20.2541H16.0036C18.3508 20.2541 20.2536 18.3513 20.2536 16.0041V7.99658C20.2536 5.64937 18.3508 3.74658 16.0036 3.74658H7.99609Z"
        fill={color}
      />
    </svg>
  )
}

export default CheckboxOff
