/* tslint:disable */
/* eslint-disable */
/**
 * Cushla API
 * REST API for Cushla
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Vitamin K after birth
 * @export
 * @enum {string}
 */

export enum BirthPreferencesVitaminKDto {
  INJECTION = 'INJECTION',
  ORALLY = 'ORALLY',
  NO = 'NO',
}
