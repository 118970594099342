import {RowGroup, RowGroupType} from '@common/models'
import React from 'react'

import {StyledTable} from './HealthRecordModal.styled'

import {Typography} from '~/components/general/typography/Typography'
import {theme} from '~/styles/theme'

type RowGroupValueProps = {
  row: Partial<RowGroup>
}

const RowGroupValue: React.FC<RowGroupValueProps> = ({row}) => {
  switch (row.type) {
    case RowGroupType.MULTILINE:
      if (!row.values) {
        return null
      }
      return (
        <>
          {row.values.map((value, index) => (
            <Typography color={theme.colors.grey.main} key={index}>
              {value}
            </Typography>
          ))}
        </>
      )
    case RowGroupType.LINE:
      return (
        <Typography color={theme.colors.grey.main}>{row.subLine}</Typography>
      )
    case RowGroupType.TABLE:
      return (
        <StyledTable>
          <tbody>
            {row.rows?.map((tableRow, rowIndex) => (
              <tr key={rowIndex}>
                <td>
                  <Typography ellipsis>{tableRow.field}</Typography>
                </td>
                {tableRow.cells.map((cell, index) => (
                  <td key={`${cell}${index}`}>
                    <Typography>{cell}</Typography>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </StyledTable>
      )
    default:
      return (
        <Typography color={theme.colors.primary.blue900}>
          {row.value}
        </Typography>
      )
  }
}

export default RowGroupValue
