import {useTranslation} from 'react-i18next'
import React, {useState} from 'react'

import {StyledSelect} from './ShareQuestionnaireModal.styled'

import {Container} from '~/components/general/container/Container'
import {Typography} from '~/components/general/typography/Typography'
import {Radio} from '~/components/general/radio/Radio'
import {TextArea} from '~/components/general/textArea/TextArea'
import {theme} from '~/styles/theme'
import {Option} from '~/components/general/select/Select'

type ShareQuestionnaireAddMessageProps = {
  setMessage: (message: string) => void
  repeatNote: boolean | undefined
  setRepeatNote: (repeat: boolean) => void
}

export const ShareQuestionnaireAddMessage: React.FC<
  ShareQuestionnaireAddMessageProps
> = ({setMessage, repeatNote, setRepeatNote}) => {
  const {t} = useTranslation()
  const [isTextInputEnabled, setIsTextInputEnabled] = useState(true)
  const [inputText, setInputText] = useState('')

  const getRepeatNoteText = (value?: boolean) => {
    if (value) {
      return t('Every time')
    }
    if (value === false) {
      return t('First time only')
    }
    return ''
  }

  return (
    <Container flex direction="column" spacing={3}>
      <Typography color={theme.colors.grey.main}>
        {t('Would you like to send a message with this questionnaire?')}
      </Typography>
      <Container flex spacing={5}>
        <Radio
          label={t('Yes')}
          checked={isTextInputEnabled}
          onChange={() => {
            setIsTextInputEnabled(true)
            setMessage(inputText)
          }}
        />
        <Radio
          label={t('No')}
          checked={!isTextInputEnabled}
          onChange={() => {
            setIsTextInputEnabled(false)
            setMessage('')
          }}
        />
      </Container>
      <TextArea
        value={inputText}
        onChange={(event) => {
          setInputText(event.target.value)
          setMessage(event.target.value)
        }}
        disabled={!isTextInputEnabled}
        placeholder={
          isTextInputEnabled ? t('Write your message here') : undefined
        }
        color={theme.colors.primary.blue900}
      />
      <Container flex direction="column" spacing={2}>
        <Typography color={theme.colors.grey.main} weight="medium">
          {t('Send: ')}
        </Typography>
        <StyledSelect
          size="small"
          disabled={!isTextInputEnabled}
          placeholder={t('Select')}
          value={getRepeatNoteText(repeatNote)}>
          <Option size="small" onClick={() => setRepeatNote(false)}>
            <Typography color={theme.colors.grey.main} size="sm">
              {getRepeatNoteText(false)}
            </Typography>
          </Option>
          <Option size="small" onClick={() => setRepeatNote(true)}>
            <Typography color={theme.colors.grey.main} size="sm">
              {getRepeatNoteText(true)}
            </Typography>
          </Option>
        </StyledSelect>
      </Container>
    </Container>
  )
}
