import React from 'react'

import {StyledCard} from './Card.styled'

type CardProps = {
  children: React.ReactNode
  className?: string
}

export const Card: React.FC<CardProps> = ({children, className}) => (
  <StyledCard className={className}>{children}</StyledCard>
)
