import DocViewer, {
  BMPRenderer,
  HTMLRenderer,
  JPGRenderer,
  PDFRenderer,
  PNGRenderer,
  TIFFRenderer,
  TXTRenderer,
  CSVRenderer,
  GIFRenderer,
  VideoRenderer,
  IDocument,
} from '@cyntler/react-doc-viewer'
import React from 'react'

import {theme} from '~/styles/theme'
import {FullscreenModal} from '~/components/general/modal/FullscreenModal'

// Basically all the supported renderers except the MSDOC one cause that
// send the data out to officeapps.live.com.
const DocViewerRenderers = [
  BMPRenderer,
  HTMLRenderer,
  JPGRenderer,
  PDFRenderer,
  PNGRenderer,
  TIFFRenderer,
  TXTRenderer,
  CSVRenderer,
  GIFRenderer,
  VideoRenderer,
]

export type AttachmentsModalProps = {
  isVisible: boolean
  onClose: () => void
  files: Array<IDocument>
  initialIndex: number
}

export const AttachmentsModal = ({
  isVisible,
  onClose,
  files,
  initialIndex,
}: AttachmentsModalProps) => {
  if (!files || files.length === 0 || !isVisible) return null

  return (
    <FullscreenModal
      isVisible={isVisible}
      onClose={onClose}
      headerText="Attachments"
      useCompactLayout>
      <DocViewer
        documents={files}
        pluginRenderers={DocViewerRenderers}
        initialActiveDocument={files[initialIndex]}
        theme={{
          primary: theme.colors.primary.blue25,
          secondary: theme.colors.secondary.main,
          textPrimary: theme.colors.grey.main,
        }}
      />
    </FullscreenModal>
  )
}
