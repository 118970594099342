import React from 'react'

import {StyledChipText} from './Chip.styled'

export type ChipColorVariant = 'black' | 'green' | 'yellow' | 'red'

type ChipProps = {
  className?: string
  text?: string | null
  color?: ChipColorVariant
}

export const Chip: React.FC<ChipProps> = ({
  className,
  color = 'black',
  text,
}) => {
  if (!text) {
    return null
  }

  return (
    <StyledChipText ellipsis className={className} colorVariant={color}>
      {text}
    </StyledChipText>
  )
}
