import * as React from 'react'

import {IconProps} from '.'

function Home({color, size, isFilled}: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill={isFilled ? color : 'none'}
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 17.5C16.3808 17.5 17.5 16.3808 17.5 15V8.88929C17.5 8.11763 17.1442 7.38931 16.535 6.91598L11.535 3.02687C10.6325 2.32438 9.36833 2.32438 8.465 3.02687L3.465 6.91598C2.85583 7.38931 2.5 8.11763 2.5 8.88929V15C2.5 16.3808 3.61917 17.5 5 17.5"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.33359 9.16602V9.9997"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.6668 9.16602V9.9997"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.098 12.957C13.098 12.957 12.0042 14.0508 10.1801 14.0508C8.356 14.0508 7.26221 12.957 7.26221 12.957"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default Home
