import {useTranslation} from 'react-i18next'
import React, {useMemo} from 'react'
import {
  AssignedQuestionnaireInfo,
  PagedResponse,
  QuestionnaireStatus,
} from '@common/models'
import {QuestionnaireOrder} from '@common/api/types'
import {useNavigate} from 'react-router-dom'

import {getTableDateValue, getTablePatientName} from '~/utils'
import {AssignedQuestionnaires} from '~/components/questionnaires/AssignedQuestionnaires'

export const QuestionnaireResponses: React.FC = () => {
  const {t} = useTranslation()
  const navigate = useNavigate()

  const tableHeaders = useMemo(
    () => ({
      columns: [
        {
          title: t('Patient name'),
          columnWidthPercent: 30,
        },
        {
          title: t('Questionnaire title'),
          columnWidthPercent: 30,
        },
        {
          title: t('Date sent'),
          columnWidthPercent: 20,
        },
        {
          title: t('Date answered'),
          columnWidthPercent: 20,
        },
      ],
    }),
    [t],
  )

  const getTableRows = (data?: PagedResponse<AssignedQuestionnaireInfo>) =>
    data?.records.map((record) => ({
      onRowClick: () => !!record.id && navigate(record.id),
      columns: [
        getTablePatientName(record.patientDetails),
        record.title,
        getTableDateValue(record.dateReceived),
        getTableDateValue(record.dateCompleted),
      ],
    })) ?? []

  return (
    <AssignedQuestionnaires
      headers={tableHeaders}
      getRows={getTableRows}
      orderBy={QuestionnaireOrder.DATE_COMPLETED}
      questionnaireStatus={QuestionnaireStatus.COMPLETED}
      showBanner
    />
  )
}
