import * as React from 'react'

import {IconProps} from '.'

function ArrowDown({color, size, isFilled}: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={isFilled ? color : 'none'}
      xmlns="http://www.w3.org/2000/svg">
      <path d="M7 10H17L12 15L7 10Z" fill={color} />
    </svg>
  )
}

export default ArrowDown
