import * as React from 'react'

import {IconProps} from '.'

function Monitoring({color, size, isFilled}: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill={isFilled ? color : 'none'}
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.66553 3.32947L7.74931 1.97474C7.9076 1.77704 8.14716 1.66201 8.40042 1.66211H11.6009C11.8542 1.66201 12.0937 1.77704 12.252 1.97474L13.335 3.32947V3.32947V4.16315C13.335 4.62358 12.9617 4.99683 12.5013 4.99683H7.49921C7.03878 4.99683 6.66553 4.62358 6.66553 4.16315V3.32947Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.05497 7.77539L6.66522 9.16513L5.83154 8.33146"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.8335 8.33286H13.3345"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.16633 16.669H4.99793C4.07707 16.669 3.33057 15.9225 3.33057 15.0016V4.99744C3.33057 4.07658 4.07707 3.33008 4.99793 3.33008H6.66529"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.2054 17.072C14.9304 17.3471 14.5573 17.5016 14.1683 17.5014V17.5014C13.7793 17.5016 13.4063 17.3471 13.1312 17.072L11.3738 15.3146C10.6784 14.5902 10.6901 13.4425 11.4002 12.7324C12.1103 12.0224 13.2579 12.0106 13.9824 12.706L14.1683 12.892L14.3542 12.706C14.8169 12.224 15.504 12.0298 16.1506 12.1984C16.7971 12.3669 17.302 12.8718 17.4705 13.5183C17.639 14.1648 17.4448 14.852 16.9628 15.3146L15.2054 17.072Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.335 3.33008H15.0023C15.9232 3.33008 16.6697 4.07658 16.6697 4.99744V9.99952"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.83154 12.5008H7.4989"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default Monitoring
