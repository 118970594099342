import styled from 'styled-components'

type TimelineProps = {
  subcard?: boolean
}

export const StyledTimeline = styled.div`
  position: relative;
  margin-left: 6px;
`

export const TimelineLine = styled.div<TimelineProps>`
  width: 1px;
  background-color: ${({theme}) => theme.colors.primary.blue100};
  position: absolute;
  top: ${({subcard}) => (subcard ? '0' : '34px')};
  bottom: 26px;
`

export const TimelineSubcards = styled.div`
  position: relative;
  margin-left: ${({theme}) => theme.spacing.xxxl};
`
