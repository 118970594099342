import styled from 'styled-components'

export const MaterialWrapper = styled.div`
  width: 50%;
  padding-left: ${({theme}) => theme.spacing.xs};
  padding-right: ${({theme}) => theme.spacing.xs};
`

export const StyledMaterial = styled.div`
  height: 72px;
  background-color: ${({theme}) => theme.colors.grey.grey50};
  display: flex;
  align-items: center;
  border-radius: ${({theme}) => theme.borderRadius.md};
  margin-bottom: ${({theme}) => theme.spacing.md};

  &:hover {
    ${({onClick}) => !!onClick && 'cursor: pointer'};
  }
`

export const FolderIcon = styled.div`
  width: 60px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid ${({theme}) => theme.colors.grey.grey150};
`

export const MaterialContent = styled.div`
  flex: 1;
  font-weight: ${({theme}) => theme.fontWeight.medium};
  padding-left: ${({theme}) => theme.spacing.sm};
`

export const RightIcon = styled.div`
  margin: 0 ${({theme}) => theme.spacing.md};

  &:hover {
    cursor: pointer;
  }
`
