import styled from 'styled-components'

import {Typography} from '../typography/Typography'
import {ChipColorVariant} from './Chip'

export type StyledChipTextProps = {
  colorVariant: ChipColorVariant
}

export const StyledChipText = styled(Typography)<StyledChipTextProps>`
  background-color: ${({colorVariant, theme}) =>
    theme.colors.chip.background[colorVariant]};
  color: ${({colorVariant, theme}) => theme.colors.chip.text[colorVariant]};
  border-radius: ${({theme}) => theme.borderRadius.xs};
  padding: ${({theme}) => theme.spacing.xxs} ${({theme}) => theme.spacing.xs};
  float: left;
  max-width: 230px;
  display: inline;
`
