import React from 'react'

import {StyledParagraph, StyledText, StyledTextProps} from './Typography.styled'

type TypographyProps = {
  children: React.ReactNode
  className?: string
  onClick?: React.MouseEventHandler<HTMLParagraphElement>
  inline?: boolean
} & StyledTextProps

export const Typography: React.FC<TypographyProps> = ({
  children,
  inline,
  ...props
}) => {
  const TextComponent = inline ? StyledText : StyledParagraph
  return <TextComponent {...props}>{children}</TextComponent>
}
