import React, {useContext, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {PractitionersPatient, QuestionnaireRepeatPeriod} from '@common/models'

import {FooterWrapper} from './ShareQuestionnaireModal.styled'
import {ShareQuestionnaireSelectPatients} from './ShareQuestionnaireSelectPatients'
import {ShareQuestionnaireScheduling} from './ShareQuestionnaireScheduling'
import {ShareQuestionnaireAddMessage} from './ShareQuestionnaireAddMessage'

import {Button} from '~/components/general/button/Button'
import {Modal} from '~/components/general/modal/Modal'
import {QuestionnairesContext} from '~/components/questionnaires/QuestionnairesContext'
import {useAssignQuestionnaire} from '~/api'
import {PractitionerContext} from '~/components/profile/PractitionerContext'

export type RawQuestionnaireSchedule = {
  dateDue?: Date | null
  startDate?: Date | null
  endDate?: Date | null
  repeatPeriod?: QuestionnaireRepeatPeriod
}

const getPatientIds = (patients: PractitionersPatient[]) =>
  patients.map((patient) => patient.patientId)

const useShareQuestionnaireModalForm = (onClose: () => void) => {
  const {t} = useTranslation()
  const {selectedEmployment, practitioner} = useContext(PractitionerContext)
  const {questionnaire, selectedPatients, setSelectedPatients} = useContext(
    QuestionnairesContext,
  )
  const [stepIndex, setStepIndex] = useState(0)
  const [message, setMessage] = useState('')
  const [repeatNote, setRepeatNote] = useState<boolean>()
  const [schedule, setSchedule] = useState<RawQuestionnaireSchedule>({})

  const {mutate, isLoading} = useAssignQuestionnaire()

  const closeModalAndClearData = () => {
    onClose()
    setStepIndex(0)
    setSelectedPatients([])
    setMessage('')
    setRepeatNote(undefined)
    setSchedule({})
  }

  const sendQuestionnaire = () => {
    const facilityId = selectedEmployment?.facilityId
    const practitionerId = practitioner?.id
    const questionnaireId = questionnaire?.id

    if (facilityId && practitionerId && questionnaireId) {
      mutate(
        {
          facilityId,
          patientIds: getPatientIds(selectedPatients),
          practitionerId,
          questionnaireId,
          note: message.trim() || undefined,
          repeatNote: repeatNote ?? false,
          repeatPeriod: schedule.repeatPeriod,
          startDate: schedule.startDate?.toISOString(),
          endDate: schedule.endDate?.toISOString(),
          dateDue: schedule.dateDue?.toISOString(),
        },
        {
          onSuccess: closeModalAndClearData,
        },
      )
    } else {
      // [CUSH-655] FIXME: Add error logging
      // eslint-disable-next-line no-console
      console.error(t('Unable to make request due to missing ids'))
    }
  }

  const modalProps = [
    {
      buttonTitle: t('Next'),
      content: <ShareQuestionnaireSelectPatients />,
      isButtonDisabled: !selectedPatients.length,
      onButtonPress: () => setStepIndex(1),
      title: t('Send {{title}}', {
        title: questionnaire?.title || t('Questionnaire'),
      }),
    },
    {
      buttonTitle: t('Next'),
      content: (
        <ShareQuestionnaireScheduling
          schedule={schedule}
          setSchedule={setSchedule}
        />
      ),
      onButtonPress: () => setStepIndex(2),
      title: t('Set schedule'),
    },
    {
      buttonTitle: t('Send'),
      content: (
        <ShareQuestionnaireAddMessage
          setMessage={setMessage}
          repeatNote={repeatNote}
          setRepeatNote={setRepeatNote}
        />
      ),
      isButtonDisabled: isLoading,
      onButtonPress: sendQuestionnaire,
      title: t('Add message'),
    },
  ]

  return {...modalProps[stepIndex], closeModalAndClearData}
}

type ShareQuestionnairesModalProps = {
  onClose: () => void
  isVisible: boolean
}

export const ShareQuestionnaireModal: React.FC<
  ShareQuestionnairesModalProps
> = ({isVisible, onClose}) => {
  const {
    buttonTitle,
    closeModalAndClearData,
    content,
    isButtonDisabled,
    onButtonPress,
    title,
  } = useShareQuestionnaireModalForm(onClose)

  const modalFooter = (
    <FooterWrapper>
      <Button onClick={onButtonPress} disabled={isButtonDisabled}>
        {buttonTitle}
      </Button>
    </FooterWrapper>
  )

  return (
    <Modal
      onClose={closeModalAndClearData}
      isVisible={isVisible}
      footer={modalFooter}
      header={title}>
      {content}
    </Modal>
  )
}
