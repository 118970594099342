import React, {useState} from 'react'
import * as DomPurify from 'dompurify'

import {MaterialPreviewInput} from '../sideBar/MaterialPreviewInput'
import {domPurifyConfig} from './MaterialViewerPage'

import {Layout} from '~/components/layout/Layout'

export const MaterialPreviewPage: React.FC = () => {
  const [htmlContent, setHtmlContent] = useState('')

  return (
    <Layout
      rightSide={
        <MaterialPreviewInput
          value={htmlContent}
          onChange={(e) => setHtmlContent(e.target.value)}
        />
      }>
      <div
        className="educational-content"
        // [CUSH-656] TODO: Investigate how to not use dangerouslySetInnerHTML
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: DomPurify.sanitize(htmlContent, domPurifyConfig),
        }}
      />
    </Layout>
  )
}
