import {AssertTypeFn} from './general'
import {
  LinkValidationResponse,
  PermissionCategory,
  SharingLink,
  SharingLinkStatus,
} from '../models'
import {
  assertArray,
  assertArrayOrNull,
  assertEnum,
  assertObjectOrUndefined,
  assertString,
  assertStringOrNull,
  getAssertEnum,
} from '../utils'
import {patientShortNormaliser} from './patient'

export const sharingLinkNormaliser: AssertTypeFn<SharingLink> = (obj) => ({
  createdAt: assertString(obj.createdAt),
  expiration: assertString(obj.expiration),
  id: assertString(obj.id),
  link: assertString(obj.link),
  permissions: assertArray(obj.permissions, getAssertEnum(PermissionCategory)),
})

export const sharingLinksNormaliser: AssertTypeFn<SharingLink[]> = (arr) =>
  assertArray(arr, sharingLinkNormaliser)

export const linkValidationResponseNormaliser: AssertTypeFn<
  LinkValidationResponse
> = (obj) => ({
  status: assertEnum(obj.status, SharingLinkStatus),
  expiration: assertStringOrNull(obj.expiration),
  patient: assertObjectOrUndefined(obj.patient, patientShortNormaliser),
  permissions: assertArrayOrNull(
    obj.permissions,
    getAssertEnum(PermissionCategory),
  ),
  token: assertStringOrNull(obj.token),
})
