import React from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'

import {Button} from '~/components/general/button/Button'
import {Container} from '~/components/general/container/Container'
import {Typography} from '~/components/general/typography/Typography'
import {AuthLayout} from '~/components/layout/authLayout/AuthLayout'

export const NotFoundPage: React.FC = () => {
  const {t} = useTranslation()
  const navigate = useNavigate()

  return (
    <AuthLayout>
      <Container spacing={5} direction="column">
        <div>
          <h1>404</h1>
          <Typography size="xl">{t('Page not found')}</Typography>
        </div>
        <Button onClick={() => navigate('/', {replace: true})}>
          {t('Go home')}
        </Button>
      </Container>
    </AuthLayout>
  )
}
