import {facilityNormaliser} from './facilities'
import {AssertTypeFn} from './general'
import {appointmentFileNormaliser, rowGroupNormaliser} from './files'
import {
  Appointment,
  AppointmentCancellationReason,
  AppointmentNote,
  AppointmentSlot,
  AppointmentSlots,
  AppointmentSummary,
  AppointmentSummaryMedication,
  AppointmentSurvey,
  AppointmentType,
  ClinikoAppointment,
  ClinikoAppointmentPhysitrackProgram,
  Consultation,
  ConsultationSource,
  DataOrigin,
  ScreeningConsultation,
} from '../models'
import {
  assertArray,
  assertEnum,
  assertEnumOrNull,
  assertNumberOrNull,
  assertObjectOrNull,
  assertObjectOrUndefined,
  assertString,
  assertStringOrNull,
} from '../utils'

export const consultationNormaliser: AssertTypeFn<Consultation> = (obj) => ({
  data: assertArray(obj.data, rowGroupNormaliser),
  date: assertString(obj.date),
  filePath: assertStringOrNull(obj.filePath),
  id: assertString(obj.id),
  note: assertStringOrNull(obj.note),
  source: assertEnum(obj.source, ConsultationSource),
  title: assertString(obj.title),
})

export const screeningConsultationNormaliser: AssertTypeFn<
  ScreeningConsultation
> = (obj) => ({
  rowGroups: assertArray(obj.rowGroups, rowGroupNormaliser),
})

export const clinikoAppointmentNormaliser: AssertTypeFn<ClinikoAppointment> = (
  obj,
) => ({
  duration: assertString(obj.duration),
  type: assertEnum(obj.type, AppointmentType),
})

export const clinikoAppointmentPhysitrackProgramNormaliser: AssertTypeFn<
  ClinikoAppointmentPhysitrackProgram
> = (obj) => ({
  accessCode: assertString(obj.accessCode),
  assignedAt: assertString(obj.assignedAt),
  createdAt: assertString(obj.createdAt),
  endDate: assertString(obj.endDate),
  id: assertString(obj.id),
  name: assertString(obj.name),
  scheduledDays: assertArray(obj.scheduledDays, assertString),
  ssoUrl: assertString(obj.ssoUrl),
  startDate: assertString(obj.startDate),
})

export const appointmentNoteNormaliser: AssertTypeFn<AppointmentNote> = (
  obj,
) => ({
  content: assertString(obj.content),
  createdAt: assertString(obj.createdAt),
  id: assertString(obj.id),
})

export const appointmentNotesNormaliser: AssertTypeFn<AppointmentNote[]> = (
  arr,
) => assertArray(arr, appointmentNoteNormaliser)

export const appointmentSurveyNormaliser: AssertTypeFn<AppointmentSurvey> = (
  obj,
) => ({
  conditionImprovement: assertNumberOrNull(obj.conditionImprovement),
  satisfaction: assertNumberOrNull(obj.satisfaction),
  uploadedAt: assertString(obj.uploadedAt),
})

export const appointmentSlotNormaliser: AssertTypeFn<AppointmentSlot> = (
  obj,
) => ({
  startTime: assertString(obj.startTime),
})

export const appointmentSlotsNormaliser: AssertTypeFn<AppointmentSlots> = (
  obj,
) => ({
  duration: assertString(obj.duration),
  slots: assertArray(obj.slots, appointmentSlotNormaliser),
})

export const appointmentSummaryMedicationNormaliser: AssertTypeFn<
  AppointmentSummaryMedication
> = (obj) => ({
  dosage: assertStringOrNull(obj.dosage),
  frequency: assertStringOrNull(obj.frequency),
  prescriptionEndDate: assertStringOrNull(obj.prescriptionEndDate),
  productId: assertStringOrNull(obj.productId),
  productName: assertString(obj.productName),
})

export const appointmentSummaryNormaliser: AssertTypeFn<AppointmentSummary> = (
  obj,
) => ({
  appointmentId: assertString(obj.appointmentId),
  createdAt: assertString(obj.createdAt),
  file: assertObjectOrUndefined(obj.file, appointmentFileNormaliser),
  prescribedMedications: assertArray(
    obj.prescribedMedications,
    appointmentSummaryMedicationNormaliser,
  ),
  summary: assertString(obj.summary),
})

export const appointmentSummaryOrNullNormaliser: AssertTypeFn<
  AppointmentSummary | null
> = (obj) => assertObjectOrNull(obj, appointmentSummaryNormaliser)

export const appointmentNormaliser: AssertTypeFn<Appointment> = (obj) => ({
  cancellationNote: assertStringOrNull(obj.cancellationNote),
  cancellationReason: assertEnumOrNull(
    obj.cancellationReason,
    AppointmentCancellationReason,
  ),
  clinikoAppointment: assertObjectOrUndefined(
    obj.clinikoAppointment,
    clinikoAppointmentNormaliser,
  ),
  consultations: assertArray(obj.consultations, consultationNormaliser),
  facility: assertObjectOrUndefined(obj.facility, facilityNormaliser),
  department: assertStringOrNull(obj.department),
  files: assertArray(obj.files, appointmentFileNormaliser),
  id: assertString(obj.id),
  linkedJourneyId: assertStringOrNull(obj.linkedJourneyId),
  physitrackProgram: assertObjectOrUndefined(
    obj.physitrackProgram,
    clinikoAppointmentPhysitrackProgramNormaliser,
  ),
  questionnaireAssignmentCompletedAt: assertStringOrNull(
    obj.questionnaireAssignmentCompletedAt,
  ),
  questionnaireAssignmentId: assertStringOrNull(obj.questionnaireAssignmentId),
  startTime: assertString(obj.startTime),
  survey: assertObjectOrUndefined(obj.survey, appointmentSurveyNormaliser),
  notes: assertArray(obj.notes, appointmentNoteNormaliser),
  summary: assertObjectOrUndefined(obj.summary, appointmentSummaryNormaliser),
  recordType: assertEnum(obj.recordType, DataOrigin),
})

export const appointmentsNormaliser: AssertTypeFn<Appointment[]> = (arr) =>
  assertArray(arr, appointmentNormaliser)
