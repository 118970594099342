import {PractitionersPatient} from '@common/models'
import {useTranslateBooleanValue} from '@common/utils'
import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {useGetPatientOrganDonorConsent} from '~/api'
import {DataAccordionContent} from '~/components/general/accordion/DataAccordionContent'
import {Loading} from '~/components/general/loading/Loading'

type OrganDonorAccordionContentProps = {
  patient: PractitionersPatient
}

export const OrganDonorAccordionContent: React.FC<
  OrganDonorAccordionContentProps
> = ({patient}) => {
  const {t} = useTranslation()
  const translateBooleanValue = useTranslateBooleanValue()

  const {data, isInitialLoading} = useGetPatientOrganDonorConsent({
    patientId: patient.patientId,
  })

  const accordionData = useMemo(
    () => [
      {
        title: t('Organ donor consent'),
        value: translateBooleanValue(data?.organDonor) || '-',
      },
    ],
    [data, t, translateBooleanValue],
  )

  if (isInitialLoading) {
    return <Loading />
  }

  return <DataAccordionContent data={accordionData} />
}
