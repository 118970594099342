import styled from 'styled-components'

type DisabledProps = {
  disabled?: boolean
}

type RadioProps = {
  checked?: boolean
} & DisabledProps

export const StyledRadio = styled.label<DisabledProps>`
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 28px;
  user-select: none;

  ${({disabled}) => !disabled && 'cursor: pointer;'}
`

export const RadioInput = styled.input`
  position: absolute;
  opacity: 0;

  ${({readOnly}) => !readOnly && 'cursor: pointer;'}
`

export const RadioCheckmark = styled.div<RadioProps>`
  position: absolute;
  left: 0;
  height: ${({theme}) => theme.spacing.md};
  width: ${({theme}) => theme.spacing.md};
  border: 1px solid
    ${({theme, checked, disabled}) =>
      checked && disabled
        ? theme.colors.primary.main
        : theme.colors.grey.grey90};
  border-radius: 50%;

  &:after {
    content: '';
    position: absolute;
    background-color: ${({theme, disabled}) =>
      disabled ? theme.colors.primary.main : theme.colors.secondary.main};
    display: ${({checked}) => (checked ? 'block' : 'none')};
    top: 3px;
    left: 3px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
  }
`
