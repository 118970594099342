import React from 'react'
import {useTranslation} from 'react-i18next'
import {useFormattedMedicalData} from '@common/utils'
import {BasicHealthInfoResponse, PatientHealthJourney} from '@common/models'

import {PatientDashboardCard} from './PatientDashboardCard'
import {DashboardSummaryItemPill} from './DashboardSummaryItemPill'

import {Container} from '~/components/general/container/Container'
import {Typography} from '~/components/general/typography/Typography'

type PatientDashboardSummaryCardProps = {
  healthJourneysData: PatientHealthJourney[] | undefined
  healthSummaryData: BasicHealthInfoResponse | undefined
}

export const PatientDashboardSummaryCard: React.FC<
  PatientDashboardSummaryCardProps
> = ({healthSummaryData, healthJourneysData}) => {
  const {t} = useTranslation()

  const formattedMedicalData = useFormattedMedicalData({
    basicHealthData: healthSummaryData,
    healthJourneysData,
  })

  const diagnosedConditionsListItems =
    formattedMedicalData.conditions.values.map((conditionName, index) => (
      <li key={index}>{conditionName}</li>
    ))
  const medicationListItems = formattedMedicalData.medications.values.map(
    (medicationName, index) => <li key={index}>{medicationName}</li>,
  )

  const surgeriesListItems = formattedMedicalData.surgeries.values.map(
    (surgeryTitle, index) => (
      <li key={index}>
        <Typography>{surgeryTitle}</Typography>
      </li>
    ),
  )
  const allergiesListItems = formattedMedicalData.allergies.values.map(
    (allergyName, index) => (
      <li key={index}>
        <Typography>{allergyName}</Typography>
      </li>
    ),
  )
  const hereditaryConditionsListItems =
    formattedMedicalData.familyHistory.values.map((conditionTitle, index) => (
      <li key={index}>
        <Typography>{conditionTitle}</Typography>
      </li>
    ))
  const healthJourneyListItems = formattedMedicalData.healthJourneys.values.map(
    (healthJourneyTitle, index) => (
      <li key={index}>
        <Typography>{healthJourneyTitle}</Typography>
      </li>
    ),
  )

  return (
    <PatientDashboardCard title="Medical">
      <Container flex wrap justify="space-between">
        <DashboardSummaryItemPill
          title={t('Active Conditions')}
          iconName="DocumentList"
          rightNumber={formattedMedicalData.conditions.values.length}
          bottomContent={
            diagnosedConditionsListItems.length !== 0 && (
              <ul>{diagnosedConditionsListItems}</ul>
            )
          }
          linkPath="../medical/conditions"
        />
        <DashboardSummaryItemPill
          title={t('Active Medications')}
          iconName="Pills"
          rightNumber={formattedMedicalData.medications.values.length}
          bottomContent={
            medicationListItems.length !== 0 && <ul>{medicationListItems}</ul>
          }
          linkPath="../medical/medications"
        />
        <DashboardSummaryItemPill
          title={t('Health Journey')}
          iconName="HeartCheck"
          rightNumber={formattedMedicalData.healthJourneys.totalCount}
          linkPath="" // [CUSH-234] Add a correct Link when the Health Journeys page exists
          bottomContent={
            healthJourneyListItems.length !== 0 && (
              <ul>{healthJourneyListItems}</ul>
            )
          }
        />
        <DashboardSummaryItemPill
          title={t('Surgeries')}
          iconName="Surgery"
          rightNumber={formattedMedicalData.surgeries.totalCount}
          bottomContent={
            surgeriesListItems.length !== 0 && <ul>{surgeriesListItems}</ul>
          }
          linkPath="../medical/surgeries"
        />
        <DashboardSummaryItemPill
          title={t('Allergies')}
          iconName="Shield"
          rightNumber={formattedMedicalData.allergies.totalCount}
          bottomContent={
            allergiesListItems.length !== 0 && <ul>{allergiesListItems}</ul>
          }
          linkPath="../medical/allergies"
        />
        <DashboardSummaryItemPill
          title={t('Hereditary Conditions')}
          iconName="Person"
          rightNumber={formattedMedicalData.familyHistory.totalCount}
          linkPath="../medical/family-history"
          bottomContent={
            hereditaryConditionsListItems.length !== 0 && (
              <ul>{hereditaryConditionsListItems}</ul>
            )
          }
        />
      </Container>
    </PatientDashboardCard>
  )
}
