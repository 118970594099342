import {PermissionCategory} from '@common/models'
import React, {useContext, useMemo} from 'react'

import {RequestData} from './RequestData'

import {PatientsContext} from '~/components/patients/PatientsContext'

type PermissionCheckProps = {
  children: React.ReactNode
  permission: PermissionCategory
}

export const PermissionCheck: React.FC<PermissionCheckProps> = ({
  children,
  permission,
}) => {
  const {patient} = useContext(PatientsContext)

  const hasGivenPermission = useMemo(
    () => patient?.permissions.includes(permission),
    [patient?.permissions, permission],
  )

  return hasGivenPermission ? (
    <div>{children}</div>
  ) : (
    <RequestData patient={patient} permission={permission} />
  )
}
