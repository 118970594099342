import React from 'react'

import {PatientsTableHeader} from './PatientsTableHeader'
import {
  PatientsTableRow,
  PatientsTableRowData,
  PatientsTableRowHandlers,
} from './PatientsTableRow'

import {Table} from '~/components/general/table/Table'
import {TablePaginationProps} from '~/components/general/table/TablePagination'

type PatientsTableProps = {
  rows: PatientsTableRowData[]
  isLoading?: boolean
  loaderColSpan?: number
} & PatientsTableRowHandlers &
  TablePaginationProps

export const PatientsTable: React.FC<PatientsTableProps> = ({
  rows,
  onRowClick,
  onPageClick,
  isLoading,
  loaderColSpan = 0,
  paginationData,
}) => (
  <Table
    onPageClick={onPageClick}
    isLoading={isLoading}
    loaderColSpan={loaderColSpan}
    paginationData={paginationData}
    rows={rows.map(
      ({
        patientName,
        dateOfBirth,
        address,
        lastAppointmentDate,
        patientId,
        menuItems,
      }) => (
        <PatientsTableRow
          key={patientId}
          patientId={patientId}
          onRowClick={onRowClick}
          patientName={patientName}
          dateOfBirth={dateOfBirth}
          address={address}
          lastAppointmentDate={lastAppointmentDate}
          menuItems={menuItems}
        />
      ),
    )}
    header={<PatientsTableHeader />}
  />
)
