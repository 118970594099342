/* tslint:disable */
/* eslint-disable */
/**
 * Cushla API
 * REST API for Cushla
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * When set the entry was not created manually by user but rather by system.
 * @export
 * @enum {string}
 */

export enum ExternalObstetricsSourceDto {
  PREGNANCY_JOURNEY = 'CUSHLA_PREGNANCY_JOURNEY',
  UPLOADED_REPORT = 'CUSHLA_UPLOADED_REPORT',
}
