import {SplitFacilitiesResponse} from '@generated/models'

export type {
  CreateHealthServiceDto as AddFacilityRequest,
  DeleteHealthServiceDto as RemoveFacilityRequest,
  FacilityDocumentTypeDto as FacilityDocumentType,
  GetFacilityDto as Facility,
  HealthcareNetworkDto as HealthcareNetworkDashboard,
  SplitFacilitiesResponse,
  FacilityDetails,
} from '@generated/models'

export {Specialization} from '@generated/models'

export type SplitFacilities = Omit<SplitFacilitiesResponse, 'page'>
