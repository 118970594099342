import {PractitionersPatient} from '@common/models'
import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {useGetPatientNameDetails} from '~/api'
import {DataAccordionContent} from '~/components/general/accordion/DataAccordionContent'
import {Loading} from '~/components/general/loading/Loading'

type NameAccordionContentProps = {
  patient: PractitionersPatient
}

export const NameAccordionContent: React.FC<NameAccordionContentProps> = ({
  patient,
}) => {
  const {t} = useTranslation()

  const {data, isInitialLoading} = useGetPatientNameDetails({
    patientId: patient.patientId,
  })

  const accordionData = useMemo(
    () => [
      {
        title: t('Title'),
        value: data?.title || '-',
      },
      {
        title: t('First name'),
        value: data?.firstName || '-',
      },
      {
        title: t('Surname'),
        value: data?.secondName || '-',
      },
      {
        title: t('Known By'),
        value: data?.knownBy,
      },
      {
        title: t('Maiden name'),
        value: data?.maidenName || '-',
      },
      {
        title: t('Suffix'),
        value: data?.suffix || '-',
      },
    ],
    [data, t],
  )

  if (isInitialLoading) {
    return <Loading />
  }

  return <DataAccordionContent data={accordionData} />
}
