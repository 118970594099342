import * as React from 'react'

import {IconProps} from '.'

function UsersThree({color, size, isFilled}: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill={isFilled ? color : 'none'}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 14.0625C11.7259 14.0625 13.125 12.6634 13.125 10.9375C13.125 9.21161 11.7259 7.8125 10 7.8125C8.27411 7.8125 6.875 9.21161 6.875 10.9375C6.875 12.6634 8.27411 14.0625 10 14.0625Z"
        stroke={color}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.3125 9.0625C16.0403 9.0619 16.7582 9.23102 17.4092 9.55645C18.0602 9.88188 18.6263 10.3546 19.0626 10.9372"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.937012 10.9373C1.37329 10.3548 1.93943 9.88196 2.59044 9.5565C3.24146 9.23104 3.9594 9.0619 4.68723 9.0625"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.50244 16.8749C5.913 16.0317 6.55238 15.3208 7.3476 14.8235C8.14282 14.3262 9.06184 14.0625 9.99975 14.0625C10.9377 14.0625 11.8567 14.3262 12.6519 14.8235C13.4471 15.3208 14.0865 16.0316 14.4971 16.8749"
        stroke={color}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.68752 9.0625C4.21318 9.06241 3.74865 8.92738 3.34817 8.67316C2.9477 8.41895 2.62781 8.05605 2.42587 7.62684C2.22392 7.19763 2.14825 6.71982 2.2077 6.24921C2.26714 5.77861 2.45924 5.33462 2.76157 4.96911C3.06389 4.60359 3.46397 4.33163 3.91508 4.18498C4.36618 4.03833 4.8497 4.02304 5.30917 4.1409C5.76864 4.25876 6.1851 4.50491 6.50991 4.8506C6.83472 5.19629 7.05449 5.62725 7.14354 6.09316"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.8564 6.09362C12.9455 5.62764 13.1652 5.1966 13.49 4.85083C13.8149 4.50507 14.2313 4.25886 14.6909 4.14095C15.1504 4.02304 15.634 4.03831 16.0851 4.18496C16.5363 4.33162 16.9365 4.60361 17.2388 4.96917C17.5412 5.33473 17.7333 5.77878 17.7928 6.24945C17.8522 6.72012 17.7765 7.19798 17.5745 7.62724C17.3725 8.0565 17.0526 8.41943 16.652 8.67365C16.2515 8.92787 15.7869 9.06288 15.3125 9.06292"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default UsersThree
