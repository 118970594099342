import styled from 'styled-components'

import {PopupButton} from '~/components/general/popup/PopupButton'

export const TableHeaderSort = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${({theme}) => theme.spacing.md};

  & > * {
    margin-bottom: ${({theme}) => theme.spacing.xxs};
  }

  & > *:last-child {
    margin-bottom: 0;
  }
`
export const TableCellIconButtonPopup = styled(PopupButton)`
  display: flex;
  justify-content: flex-end;
`
