import * as React from 'react'

import {IconProps} from '.'

function User({color, size, isFilled}: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 21 21"
      fill={isFilled ? color : 'none'}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.3331 4.50719C19.5874 7.76156 19.5874 13.0379 16.3331 16.2923C13.0787 19.5467 7.80231 19.5467 4.54796 16.2923C1.29358 13.0379 1.29358 7.76154 4.54796 4.50719C7.80233 1.25281 13.0787 1.25281 16.3331 4.50719"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.243 16.1574L14.9514 15.4249C14.8039 15.0533 14.5747 14.7199 14.2814 14.4483L14.228 14.3991C13.7664 13.9708 13.1597 13.7324 12.5297 13.7324H8.35137C7.72137 13.7324 7.11471 13.9708 6.65221 14.3983L6.59887 14.4483C6.30554 14.7199 6.07721 15.0533 5.92887 15.4249L5.63721 16.1574"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.503 6.25467C13.642 7.3937 13.642 9.24042 12.503 10.3795C11.364 11.5185 9.51723 11.5185 8.3782 10.3795C7.23917 9.24042 7.23917 7.3937 8.3782 6.25467C9.51723 5.11563 11.364 5.11563 12.503 6.25467"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default User
