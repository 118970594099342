import {
  HealthCategory,
  AllergyTimelineItem,
  CholesterolTimelineItem,
  ConsultationTimelineItem,
  DiagnosedConditionTimelineItem,
  MedicalTestTimelineItem,
  MedicationTimelineItem,
  PatientTimelineItem,
  SurgeryTimelineItem,
  VaccinationTimelineItem,
} from '../models'
import {AssertTypeFn} from './general'
import {
  cholesterolNormaliser,
  diagnosedConditionNormaliser,
  medicalTestNormaliser,
  medicationNormaliser,
  patientAllergyNormaliser,
  surgeryNormaliser,
  vaccinationNormaliser,
} from './medical'
import {assertStringOrNull, assertValue} from '../utils'
import {consultationNormaliser} from './appointments'

const allergyTimelineItemNormaliser: AssertTypeFn<AllergyTimelineItem> = (
  obj,
) => ({
  type: assertValue(obj.type, HealthCategory.ALLERGY),
  item: patientAllergyNormaliser(obj.item),
  date: assertStringOrNull(obj.date),
})

const medicalTestTimelineItemNormaliser: AssertTypeFn<
  MedicalTestTimelineItem
> = (obj) => ({
  type: assertValue(obj.type, HealthCategory.MEDICAL_TEST),
  item: medicalTestNormaliser(obj.item),
  date: assertStringOrNull(obj.date),
})

const cholesterolTimelineItemNormaliser: AssertTypeFn<
  CholesterolTimelineItem
> = (obj) => ({
  type: assertValue(obj.type, HealthCategory.CHOLESTEROL),
  item: cholesterolNormaliser(obj.item),
  date: assertStringOrNull(obj.date),
})

const consultationTimelineItemNormaliser: AssertTypeFn<
  ConsultationTimelineItem
> = (obj) => ({
  type: assertValue(obj.type, HealthCategory.CONSULTATION),
  item: consultationNormaliser(obj.item),
  date: assertStringOrNull(obj.date),
})

const diagnosedConditionTimelineItemNormaliser: AssertTypeFn<
  DiagnosedConditionTimelineItem
> = (obj) => ({
  type: assertValue(obj.type, HealthCategory.DIAGNOSED_CONDITION),
  item: diagnosedConditionNormaliser(obj.item),
  date: assertStringOrNull(obj.date),
})

const medicationTimelineItemNormaliser: AssertTypeFn<MedicationTimelineItem> = (
  obj,
) => ({
  type: assertValue(obj.type, HealthCategory.MEDICATION),
  item: medicationNormaliser(obj.item),
  date: assertStringOrNull(obj.date),
})

const surgeryTimelineItemNormaliser: AssertTypeFn<SurgeryTimelineItem> = (
  obj,
) => ({
  type: assertValue(obj.type, HealthCategory.SURGERY),
  item: surgeryNormaliser(obj.item),
  date: assertStringOrNull(obj.date),
})

const vaccinationTimelineItemNormaliser: AssertTypeFn<
  VaccinationTimelineItem
> = (obj) => ({
  type: assertValue(obj.type, HealthCategory.VACCINATION),
  item: vaccinationNormaliser(obj.item),
  date: assertStringOrNull(obj.date),
})

export const patientTimelineItemNormaliser: AssertTypeFn<
  PatientTimelineItem
> = (obj) => {
  switch (obj.type) {
    case HealthCategory.ALLERGY:
      return allergyTimelineItemNormaliser(obj)
    case HealthCategory.MEDICAL_TEST:
      return medicalTestTimelineItemNormaliser(obj)
    case HealthCategory.CHOLESTEROL:
      return cholesterolTimelineItemNormaliser(obj)
    case HealthCategory.CONSULTATION:
      return consultationTimelineItemNormaliser(obj)
    case HealthCategory.DIAGNOSED_CONDITION:
      return diagnosedConditionTimelineItemNormaliser(obj)
    case HealthCategory.MEDICATION:
      return medicationTimelineItemNormaliser(obj)
    case HealthCategory.SURGERY:
      return surgeryTimelineItemNormaliser(obj)
    case HealthCategory.VACCINATION:
      return vaccinationTimelineItemNormaliser(obj)
    default:
      return obj
  }
}
