import * as React from 'react'

import {IconProps} from '.'

function Steps({color, size, isFilled}: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={isFilled ? color : 'none'}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.00439 6.5548C4.00439 7.87283 4.73664 10.3991 5.10276 11.4974C5.46888 11.4974 8.39784 10.9482 8.39784 10.9482C7.84866 9.3007 9.4962 7.10398 9.4962 6.00562C9.4962 4.90726 9.4962 1.06299 6.7503 1.06299C4.00439 1.06299 4.00439 4.90726 4.00439 6.5548Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.06555 13.042L5.6416 13.6945C5.6416 14.4267 5.88911 15.2371 6.20112 15.8911C6.51312 16.5451 7.064 17.212 8.22366 16.9499C9.38332 16.6878 9.566 15.8141 9.4962 15.3419C9.4264 14.8698 9.06555 13.042 9.06555 13.042Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.0913 12.2735C20.0913 13.5854 19.3625 16.1 18.998 17.1932C18.6336 17.1932 15.7182 16.6466 15.7182 16.6466C16.2648 15.0067 14.6249 12.8201 14.6249 11.7269C14.6249 10.6336 14.6249 6.80713 17.3581 6.80713C20.0913 6.80713 20.0913 10.6336 20.0913 12.2735Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.0663 18.9292L18.4744 19.5787C18.4744 20.3075 18.228 21.1141 17.9174 21.7651C17.6069 22.4161 17.0586 23.0799 15.9043 22.819C14.75 22.5581 14.5681 21.6884 14.6376 21.2185C14.7071 20.7485 15.0663 18.9292 15.0663 18.9292Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default Steps
