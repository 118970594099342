import styled from 'styled-components'

import {Card} from '~/components/general/card/Card'

export const TooltipCard = styled(Card)`
  border: 1px solid ${({theme}) => theme.colors.grey.grey100};
  align-items: center;
  width: 78px;
  padding: 6px;
  box-shadow: ${({theme}) => theme.boxShadow.tooltip};
`

export const ValueText = styled.span`
  font-weight: ${({theme}) => theme.fontWeight.bold};
`
