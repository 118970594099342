import React from 'react'
import {
  BasicPatientData,
  HealthDataType,
  LatestActivity,
  LifestyleInformation,
} from '@common/models'
import {Link, useNavigate} from 'react-router-dom'
import {useLatestHealthDataDetails} from '@common/utils'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'

import {HealthDataBadge} from '../trends/HealthDataBadge'
import {StyledCardContainer} from './PatientDashboardCard.styled'
import {PatientDashboardCard} from './PatientDashboardCard'

import {theme} from '~/styles/theme'
import {Container} from '~/components/general/container/Container'
import {Button} from '~/components/general/button/Button'

type PatientDashboardTrendsCardProps = {
  latestActivities: LatestActivity | undefined
  lifestyleData: LifestyleInformation | undefined
  disableBadgeLinks?: boolean
  patient?: BasicPatientData
  hideShowAllButton?: boolean
}

const Spacer = styled.div`
  background-color: ${theme.colors.grey.grey100};
  width: ${theme.spacing.xxs};
  border-radius: ${theme.borderRadius.xs};
`

export const PatientDashboardTrendsCard: React.FC<
  PatientDashboardTrendsCardProps
> = ({
  disableBadgeLinks,
  hideShowAllButton,
  latestActivities,
  lifestyleData,
  patient,
}) => {
  const latestDataDetails = useLatestHealthDataDetails({
    latestActivities,
    lifestyleData,
    patient,
  })

  const {t} = useTranslation()
  const navigate = useNavigate()

  return (
    <PatientDashboardCard
      title={t('Health Data')}
      topRightComponent={
        !hideShowAllButton && (
          <Button color="primary" onClick={() => navigate('../trends')}>
            {t('Go to Health Trends')}
          </Button>
        )
      }>
      <StyledCardContainer
        flex
        spacing={1.5}
        align="stretch"
        disableLinks={disableBadgeLinks}>
        <Container flex direction="column" spacing={1.5} flexNumber={1}>
          <Link to="../lifestyle#sleep">
            <HealthDataBadge
              {...latestDataDetails[HealthDataType.SLEEP_SCORE]}
              healthDataType={HealthDataType.SLEEP_SCORE}
            />
          </Link>
          <Link to="../lifestyle#mood">
            <HealthDataBadge
              {...latestDataDetails[HealthDataType.MOOD_SCORE]}
              healthDataType={HealthDataType.MOOD_SCORE}
            />
          </Link>
          <Link to="../lifestyle#alcohol">
            <HealthDataBadge
              {...latestDataDetails[HealthDataType.ALCOHOL_SCORE]}
              healthDataType={HealthDataType.ALCOHOL_SCORE}
            />
          </Link>
        </Container>
        <Container flex direction="column" spacing={1.5} flexNumber={1}>
          <Link to="../lifestyle#diet">
            <HealthDataBadge
              {...latestDataDetails[HealthDataType.DIET_SCORE]}
              healthDataType={HealthDataType.DIET_SCORE}
            />
          </Link>
          <Link to="../lifestyle#body-measurements">
            <HealthDataBadge
              {...latestDataDetails[HealthDataType.WEIGHT_KG]}
              healthDataType={HealthDataType.WEIGHT_KG}
            />
          </Link>
          <Link to="../lifestyle#tobacco">
            <HealthDataBadge
              {...latestDataDetails[HealthDataType.TOBACCO_SCORE]}
              healthDataType={HealthDataType.TOBACCO_SCORE}
            />
          </Link>
        </Container>
        <Spacer />
        <Container flex direction="column" spacing={1.5} flexNumber={1}>
          <Link to="../trends">
            <HealthDataBadge
              {...latestDataDetails[HealthDataType.BLOOD_PRESSURE]}
              healthDataType={HealthDataType.BLOOD_PRESSURE}
            />
          </Link>
          <Link to="../trends">
            <HealthDataBadge
              {...latestDataDetails[HealthDataType.RESTING_HEART_RATE]}
              title={t('Heart rate')}
              healthDataType={HealthDataType.RESTING_HEART_RATE}
            />
          </Link>
          <Link to="../trends">
            <HealthDataBadge
              {...latestDataDetails[HealthDataType.HEART_AGE_YEARS]}
              healthDataType={HealthDataType.HEART_AGE_YEARS}
            />
          </Link>
        </Container>
        <Container flex direction="column" spacing={1.5} flexNumber={1}>
          <Link to="../trends">
            <HealthDataBadge
              {...latestDataDetails[HealthDataType.MOVEMENT_SCORE]}
              healthDataType={HealthDataType.MOVEMENT_SCORE}
            />
          </Link>
          <Link to="../trends">
            <HealthDataBadge
              {...latestDataDetails[HealthDataType.STEPS]}
              healthDataType={HealthDataType.STEPS}
            />
          </Link>
          <Link to="../trends">
            <HealthDataBadge
              {...latestDataDetails[HealthDataType.BMI]}
              healthDataType={HealthDataType.BMI}
            />
          </Link>
        </Container>
      </StyledCardContainer>
    </PatientDashboardCard>
  )
}
