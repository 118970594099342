import React, {ReactNode} from 'react'

import {Breadcrumbs} from './Breadcrumbs'
import {StyledTopBar} from './TopBar.styled'

type Props = {
  navMenu?: ReactNode
  disableBreadcrumbs?: boolean
}

export const TopBar: React.FC<Props> = ({
  disableBreadcrumbs = false,
  navMenu,
}) => (
  <StyledTopBar>
    <Breadcrumbs clickable={!disableBreadcrumbs} />
    {navMenu}
  </StyledTopBar>
)
