import React, {useContext, useEffect, useState} from 'react'
import {Trans, useTranslation} from 'react-i18next'
import {MISSING_ID, SUPPORT_MAIL_ADDRESS} from '@common/utils'
import {BasePermissionCategory} from '@common/models'

import {PatientsContext} from '../../PatientsContext'
import {StyledButton} from './PatientTimelineModal.styled'

import {Container} from '~/components/general/container/Container'
import {Icon} from '~/components/general/icon/Icon'
import {Typography} from '~/components/general/typography/Typography'
import {theme} from '~/styles/theme'
import {
  useGetConsultationFileUrl,
  useGetMedicalRecordFileUrl,
  useGetPatientAppointmentFile,
} from '~/api'
import {Loading} from '~/components/general/loading/Loading'

export type PatientTimelineFileEndpointType =
  | 'consultation'
  | 'medicalRecord'
  | 'appointment'
  | 'appointmentSummary'
  | 'sourceFile'

export type AttachmentButtonProps = {
  fileName?: string
  showWarningOnClick?: boolean
  endpoint: PatientTimelineFileEndpointType
  parentObjectId: string
  fileId?: string
  onClick: () => void
  onUrlLoaded: (url: string) => void
}

export const AttachmentButton = ({
  fileName,
  showWarningOnClick,
  endpoint,
  parentObjectId,
  fileId,
  onClick,
  onUrlLoaded,
}: AttachmentButtonProps) => {
  const {t} = useTranslation()
  const {patient} = useContext(PatientsContext)
  const [urlIsLoaded, setUrlIsLoaded] = useState(false)
  const [isTextShown, setIsTextShown] = useState(false)

  const patientId = patient?.patientId ?? MISSING_ID

  const hasAppointmentPermission = patient?.permissions?.includes(
    BasePermissionCategory.APPOINTMENT,
  )

  const {data: consultationFile, isFetching: isConsultationFileLoading} =
    useGetConsultationFileUrl(
      {
        id: fileId ?? MISSING_ID,
        patientId,
      },
      {enabled: endpoint === 'consultation'},
    )
  const {data: medicalRecordFile, isFetching: isMedicalRecordFileLoading} =
    useGetMedicalRecordFileUrl(
      {
        id: parentObjectId,
        patientId,
      },
      {enabled: endpoint === 'medicalRecord'},
    )
  const {data: appointmentFile, isFetching: isAppointmentFileLoading} =
    useGetPatientAppointmentFile(
      {
        fileId: fileId ?? MISSING_ID,
        patientId,
        appointmentId: parentObjectId,
      },
      {enabled: endpoint === 'appointment' && hasAppointmentPermission},
    )

  const handleButtonClick = () => {
    if (showWarningOnClick) {
      setIsTextShown(true)
      return
    }
    onClick()
  }

  useEffect(() => {
    if (
      isConsultationFileLoading ||
      isMedicalRecordFileLoading ||
      isAppointmentFileLoading ||
      urlIsLoaded
    ) {
      return
    }

    const url = (medicalRecordFile ?? consultationFile ?? appointmentFile)?.url

    if (url) {
      onUrlLoaded(url)
      setUrlIsLoaded(true)
    }
  }, [
    isConsultationFileLoading,
    isMedicalRecordFileLoading,
    isAppointmentFileLoading,
    onUrlLoaded,
    setUrlIsLoaded,
    appointmentFile,
    consultationFile,
    medicalRecordFile,
    urlIsLoaded,
  ])

  const getFileNameComponents = (fName: string) => {
    const parts = fName.split('.')
    const name = parts[0]
    const fileType = parts.length > 0 ? parts.at(-1) : t('file')

    return (
      <Container direction="column">
        <Container direction="row" spacing={0.1}>
          <Typography inline weight="bold">
            {t('File: ')}
          </Typography>
          <Typography inline>{name}</Typography>
        </Container>
        <Container direction="row" spacing={0.1}>
          <Typography weight="bold" inline>
            {t('Type: ')}
          </Typography>
          <Typography inline>{fileType}</Typography>
        </Container>
      </Container>
    )
  }

  return urlIsLoaded ? (
    <>
      <StyledButton color="primary" onClick={handleButtonClick}>
        <Icon name="Document" size="xl" />
        {fileName ? getFileNameComponents(fileName) : t('Show attachment')}
      </StyledButton>
      {isTextShown && (
        <Typography color={theme.colors.grey.grey600}>
          <Trans>
            Due to limitations on how data is received from the GP systems, it
            is not possible to split data for sharing. Please contact{' '}
            <a href={`mailto:${SUPPORT_MAIL_ADDRESS}`}>support@cushla.io</a> for
            a copy of these records.
          </Trans>
        </Typography>
      )}
    </>
  ) : (
    <Loading />
  )
}
