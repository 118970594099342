export const TERMS_AND_PRIVACY_URL = 'https://www.cushla.io/privacy-policy'

export const MISSING_ID = ''

export const SUPPORT_MAIL_ADDRESS = 'support@cushla.io'

export enum APP_DEVELOPER_MODE_STATUS {
  ENABLED = '1',
  DISABLED = '0',
}
export const APP_DEVELOPER_MODE_STATUS_KEY = 'appDeveloperModeEnabled'

export const DEBOUNCE_TIME = 500

export const DEFAULT_COUNTRY_CODE = 'IE'
