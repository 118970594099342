import React, {useContext} from 'react'
import {BasePermissionCategory} from '@common/models'

import {PatientTrendsCard} from './PatientTrendsCard'
import {PermissionCheck} from '../common/requestData/PermissionCheck'

import {PatientsContext} from '~/components/patients/PatientsContext'

export const TrendsPage: React.FC = () => {
  const {patient} = useContext(PatientsContext)

  if (!patient?.patientId) {
    // Handled in PatientDetail
    return null
  }

  return (
    <PermissionCheck permission={BasePermissionCategory.ACTIVITY}>
      <PatientTrendsCard patient={patient} />
    </PermissionCheck>
  )
}
