import React, {useEffect, useMemo, useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {LinkValidationResponse, SharingLinkStatus} from '@common/models'
import {useTranslation} from 'react-i18next'
import {DateTime} from 'luxon'
import {formatDate} from '@common/utils'

import {LinkSharingPasswordModal} from './LinkSharingPasswordModal'
import {Container} from '../general/container/Container'
import {Button} from '../general/button/Button'
import {Typography} from '../general/typography/Typography'
import {LinkSharingContent} from './LinkSharingContent'
import {NotFoundPage} from '../website/NotFoundPage'

import {Layout} from '~/components/layout/Layout'
import {theme} from '~/styles/theme'

const VALIDATION_STORAGE_KEY = 'linkSharingValidation'

type StoredValidationData = {
  password: string
  validationResponse: LinkValidationResponse
}

export const LinkSharingPage: React.FC = () => {
  const {t} = useTranslation()
  const navigate = useNavigate()

  const [validatedPassword, setValidatedPassword] = useState<string>()
  const [response, setResponse] = useState<LinkValidationResponse>()
  const {search} = useLocation()

  const token = useMemo(
    () => new URLSearchParams(search).get('token'),
    [search],
  )

  const onVerifySuccess = (
    password: string,
    validationResponse: LinkValidationResponse,
  ) => {
    setValidatedPassword(password)
    setResponse(validationResponse)
    window.sessionStorage.setItem(
      VALIDATION_STORAGE_KEY,
      JSON.stringify({password, validationResponse}),
    )
  }

  useEffect(() => {
    const storedData = window.sessionStorage.getItem(VALIDATION_STORAGE_KEY)

    if (!storedData || !token) {
      return
    }

    try {
      const {password, validationResponse} = JSON.parse(
        storedData,
      ) as StoredValidationData

      if (validationResponse.token === token) {
        setValidatedPassword(password)
        setResponse(validationResponse)
      }
    } catch (error) {
      // [CUSH-655] FIXME: Add error logging
      // eslint-disable-next-line no-console
      console.error('Could not load stored validation data', error)
    }
  }, [token])

  const expirationDate = formatDate(DateTime.DATETIME_MED, response?.expiration)

  const expirationText =
    response?.status === SharingLinkStatus.VALID
      ? t('Expires on {{expirationDate}}', {
          expirationDate,
        })
      : t('Expired on {{expirationDate}}', {
          expirationDate,
        })

  const renderNavMenu = () =>
    response ? (
      <Container direction="row" flex align="center" spacing={3}>
        <Typography color={theme.colors.grey.grey600}>
          {expirationText}
        </Typography>
        <Button onClick={() => navigate('/login-redirect')}>
          {t('Sign in')}
        </Button>
      </Container>
    ) : undefined

  if (!token) {
    return <NotFoundPage />
  }

  return (
    <Layout hideSideNavButtons disableBreadcrumbs navMenu={renderNavMenu()}>
      {!!validatedPassword && response && (
        <LinkSharingContent
          token={token}
          password={validatedPassword}
          response={response}
        />
      )}
      {!validatedPassword && (
        <LinkSharingPasswordModal
          onVerifySuccess={onVerifySuccess}
          token={token}
        />
      )}
    </Layout>
  )
}
