import {
  HealthDataResolution,
  HealthDataType,
  LifeMilestoneImpact,
  MonthlySurveyType,
  PatientReportConfirmationStatus,
  QuestionnaireStatus,
  QuestionnaireType,
  Specialization,
} from '../models'
import {OrderDirection} from './types'

type UrlSearchParams = {
  acceptInvite?: boolean
  appointmentType?: string
  assignedQuestionnaireId?: string
  barcode?: string
  bookmark?: boolean
  bookmarkedOnly?: boolean
  category?: string
  closeJourney?: boolean
  code?: string
  condition?: string
  confirm?: PatientReportConfirmationStatus
  cycleLength?: number
  deliveryDate?: string
  surgeryDate?: string
  painScore?: string
  facilityId?: string
  fields?: HealthDataType[]
  fromDate?: string
  granularity?: HealthDataResolution
  impact?: LifeMilestoneImpact
  includeHidden?: boolean
  instanceId?: string
  isActive?: boolean
  lastPeriodDate?: string
  orderBy?: string
  orderDirection?: OrderDirection
  patientId?: string
  page?: number
  password?: string
  perPage?: number
  phoneNumber?: string
  practitionerId?: string
  query?: string
  questionnaireId?: string
  questionnaireStatus?: QuestionnaireStatus
  questionnaireType?: QuestionnaireType
  resolution?: HealthDataResolution
  specialization?: Specialization
  surname?: string
  surveyType?: MonthlySurveyType
  toDateInclusive?: string
  toDate?: string
  token?: string
  unreadOnly?: boolean
}

export const getUrlWithParams = (
  urlBase: string,
  endpoint: string,
  {
    assignedQuestionnaireId,
    closeJourney,
    fields,
    fromDate,
    includeHidden,
    perPage,
    query,
    specialization,
    surveyType,
    toDateInclusive,
    toDate,
    ...searchParams
  }: UrlSearchParams,
) => {
  const url = new URL(endpoint, urlBase)

  Object.entries(searchParams)
    .filter((entry) => entry[1] !== undefined)
    .forEach(([key, value]) => {
      url.searchParams.append(key, value.toString())
    })

  if (query) {
    url.searchParams.append('q', query)
  }
  if (perPage) {
    url.searchParams.append('per_page', perPage.toString())
  }
  if (assignedQuestionnaireId) {
    url.searchParams.append('questionnaireId', assignedQuestionnaireId)
  }
  if (fields) {
    url.searchParams.append('fields', fields.join(','))
  }
  if (specialization) {
    url.searchParams.append('specialisation', specialization)
  }
  if (surveyType) {
    url.searchParams.append('quantity', surveyType)
  }
  if (fromDate) {
    url.searchParams.append('from_date', fromDate)
  }
  if (toDateInclusive) {
    url.searchParams.append('to_date_inclusive', toDateInclusive)
  }
  if (toDate) {
    url.searchParams.append('to_date', toDate)
  }
  if (closeJourney != null) {
    url.searchParams.append('close-journey', closeJourney.toString())
  }
  if (includeHidden != null) {
    url.searchParams.append('include-hidden', includeHidden.toString())
  }

  return url
}

export const getUrlWithAnyParams = (
  urlBase: string,
  endpoint: string,
  searchParams: Record<string, any>,
) => {
  const url = new URL(endpoint, urlBase)

  Object.entries(searchParams).forEach(([key, value]) => {
    url.searchParams.append(key, value.toString())
  })

  return url
}
