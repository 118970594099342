/* tslint:disable */
/* eslint-disable */
/**
 * Cushla API
 * REST API for Cushla
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */

export enum ConsultationSourceDto {
  UNKNOWN = 'UNKNOWN',
  CLINIKO = 'CLINIKO',
  SOCRATES = 'SOCRATES',
  HEALTH_ONE = 'HEALTH_ONE',
  FIRST_RESPONDER = 'FIRST_RESPONDER',
  FHIR = 'FHIR',
  ZOHO = 'ZOHO',
  CUSHLA = 'CUSHLA',
}
