import React, {ReactNode, useState} from 'react'

import {Icon} from '~/components/general/icon/Icon'
import {Image} from '~/components/general/image/Image'
import {Typography} from '~/components/general/typography/Typography'
import {
  StyledBanner,
  BannerContent,
  BannerTitle,
  BannerClose,
} from '~/components/general/banner/Banner.styled'
import {theme} from '~/styles/theme'

type Props = {
  title: string
  content: ReactNode
  closable?: boolean
}

export const Banner: React.FC<Props> = ({title, content, closable}) => {
  const [showBanner, setShowBanner] = useState<boolean>(true)
  return showBanner ? (
    <StyledBanner>
      <Image name="BannerBgd" />
      <BannerContent>
        <BannerTitle>{title}</BannerTitle>
        <Typography color={theme.colors.grey.main}>{content}</Typography>
      </BannerContent>
      {closable && (
        <BannerClose>
          <Icon
            name="Close"
            circled="sm"
            size="sm"
            circledBgd={theme.colors.grey.grey150}
            onClick={() => setShowBanner(false)}
          />
        </BannerClose>
      )}
    </StyledBanner>
  ) : null
}
