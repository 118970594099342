import React, {useContext, useMemo} from 'react'
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from '@azure/msal-react'
import {useTranslation} from 'react-i18next'
import {Outlet} from 'react-router-dom'
import {UserRole} from '@common/models'

import {AuthLayout} from '../layout/authLayout/AuthLayout'
import {StyledButton} from './ProtectedPageTemplate.styled'

import {Typography} from '~/components/general/typography/Typography'
import {AuthContext} from '~/auth/AuthContext'
import {MaterialsContextProvider} from '~/components/educationalMaterials/MaterialsContext'
import {PatientsContextProvider} from '~/components/patients/PatientsContext'
import {PractitionerContextProvider} from '~/components/profile/PractitionerContext'
import {QuestionnairesContextProvider} from '~/components/questionnaires/QuestionnairesContext'

export const ProtectedPageTemplate: React.FC = () => {
  const authContext = useContext(AuthContext)
  const {t} = useTranslation()
  const {accounts} = useMsal()

  const roles =
    (accounts[0]?.idTokenClaims as Record<string, string>)?.extension_Roles ||
    ''

  const isPractitioner = useMemo(
    () => roles.split(',').includes(UserRole.PRACTITIONER),
    [roles],
  )

  return (
    <>
      <AuthenticatedTemplate>
        {isPractitioner ? (
          <PractitionerContextProvider>
            <MaterialsContextProvider>
              <QuestionnairesContextProvider>
                <PatientsContextProvider>
                  <Outlet />
                </PatientsContextProvider>
              </QuestionnairesContextProvider>
            </MaterialsContextProvider>
          </PractitionerContextProvider>
        ) : (
          <AuthLayout>
            <Typography>
              {t(
                'If you are a patient who would like to see their health data, please download our mobile application and log in to it.',
              )}
            </Typography>
            <StyledButton onClick={authContext.signOut}>
              {t('Sign out')}
            </StyledButton>
          </AuthLayout>
        )}
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <AuthLayout>
          <Typography>{t('You need to login to access this page.')}</Typography>
          <StyledButton onClick={authContext.signIn}>{t('Login')}</StyledButton>
        </AuthLayout>
      </UnauthenticatedTemplate>
    </>
  )
}
