import styled from 'styled-components'

import {Typography} from '../general/typography/Typography'

export const QuestionnairesHeader = styled.div`
  height: 57px;
  padding: ${({theme}) => `${theme.spacing.md} ${theme.spacing.xxl}`};
  border-bottom: 1px solid ${({theme}) => theme.colors.grey.grey40};
`

export const QuestionnairesContent = styled.div`
  height: calc(100% - 57px);
  display: flex;
`

export const StyledHeaderText = styled(Typography)`
  .active > & {
    color: ${({theme}) => theme.colors.primary.main};
    font-weight: ${({theme}) => theme.fontWeight.medium};
  }
`
