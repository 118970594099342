import React from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'

import {PatientDashboardCard} from './PatientDashboardCard'
import {
  StyledDashboardInnerCard,
  StyledDashboardInnerCardTable,
  StyledDashboardInnerCardTableCell,
} from './PatientDashboardCard.styled'

import {Typography} from '~/components/general/typography/Typography'
import {theme} from '~/styles/theme'
import {Button} from '~/components/general/button/Button'

export const PatientDashboardScreeningCard = () => {
  const {t} = useTranslation()
  const navigate = useNavigate()

  const cardData = [
    {title: 'Latest Eye Test', values: '4/4/2023', isOkay: true},
    {title: 'Latest Dental Exam', values: '5/7/2022', isOkay: false},
    {title: 'Latest Colorectal Exam', values: '-', isOkay: false},
    {title: 'Latest Gait Assessment', values: '5/6/2023', isOkay: true},
  ]

  return (
    <PatientDashboardCard
      title={t('Screening Status')}
      topRightComponent={
        <Button color="primary" onClick={() => navigate('../trends')}>
          {t('Go to Health Trends')}
        </Button>
      }>
      <StyledDashboardInnerCard>
        <StyledDashboardInnerCardTable>
          <tbody>
            {cardData.map((section) => (
              <tr key={section.title}>
                <StyledDashboardInnerCardTableCell>
                  <Typography
                    weight="medium"
                    color={
                      section.isOkay ? theme.colors.grey.main : theme.colors.red
                    }>
                    {section.title}
                  </Typography>
                </StyledDashboardInnerCardTableCell>
                <StyledDashboardInnerCardTableCell>
                  <Typography
                    color={
                      section.isOkay ? theme.colors.grey.main : theme.colors.red
                    }>
                    {section.values}
                  </Typography>
                </StyledDashboardInnerCardTableCell>
              </tr>
            ))}
          </tbody>
        </StyledDashboardInnerCardTable>
      </StyledDashboardInnerCard>
    </PatientDashboardCard>
  )
}
