import {PermissionCategory, PersonalInformation} from '@common/models'
import {useTranslation} from 'react-i18next'
import React from 'react'
import {useNavigate} from 'react-router-dom'
import {usePersonalCardData} from '@common/utils'

import {
  StyledDashboardInnerCard,
  StyledDashboardInnerCardTable,
  StyledDashboardInnerCardTableCell,
} from './PatientDashboardCard.styled'
import {PatientDashboardCard} from './PatientDashboardCard'

import {Typography} from '~/components/general/typography/Typography'
import {theme} from '~/styles/theme'
import {Button} from '~/components/general/button/Button'

type PatientDashboardPersonalCardProps = {
  personalData?: PersonalInformation | null
  permissions: PermissionCategory[]
  hideShowAllButton?: boolean
}

type CardDataItemValuesProps = {
  values: string[] | string
}

const CardDataItemValues: React.FC<CardDataItemValuesProps> = ({values}) =>
  Array.isArray(values) ? (
    <Typography>{values.join(', ')}</Typography>
  ) : (
    <Typography color={theme.colors.grey.main}>{values}</Typography>
  )

export const PatientDashboardPersonalCard: React.FC<
  PatientDashboardPersonalCardProps
> = ({hideShowAllButton, permissions, personalData}) => {
  const {t} = useTranslation()
  const navigate = useNavigate()

  const cardData = usePersonalCardData({permissions, data: personalData})

  if (!cardData) {
    return <Typography>{t('Missing data')}</Typography>
  }

  return (
    <PatientDashboardCard
      title={t('Personal')}
      topRightComponent={
        !hideShowAllButton && (
          <Button color="primary" onClick={() => navigate('../personal')}>
            {t('Go to Personal Information')}
          </Button>
        )
      }>
      <StyledDashboardInnerCard>
        <StyledDashboardInnerCardTable>
          <tbody>
            {cardData.map((section) => (
              <tr key={section.title}>
                <StyledDashboardInnerCardTableCell>
                  <Typography weight="medium">{section.title}</Typography>
                </StyledDashboardInnerCardTableCell>
                <StyledDashboardInnerCardTableCell>
                  <CardDataItemValues values={section.values} />
                </StyledDashboardInnerCardTableCell>
              </tr>
            ))}
          </tbody>
        </StyledDashboardInnerCardTable>
      </StyledDashboardInnerCard>
    </PatientDashboardCard>
  )
}
