import React from 'react'

import {
  TimelineHeaderCellInsides,
  TimelineHeaderCellStyled,
} from './TimelineTable.styled'
import {Typography} from '../typography/Typography'
import {PopupButton} from '../popup/PopupButton'
import {PopupMenuItem} from '../popup/PopupMenu'
import {PopupSide} from '../popup/Popup.styled'

export type TimelineHeaderCellProps = {
  title: string
  menuItems?: PopupMenuItem[]
  hasValue?: boolean
}

export const TimelineHeaderCell = ({
  title,
  menuItems,
  hasValue = false,
}: TimelineHeaderCellProps) => (
  <TimelineHeaderCellStyled>
    <TimelineHeaderCellInsides>
      <Typography textAlign="left">{title}</Typography>
      {menuItems && (
        <PopupButton
          menuItems={menuItems}
          iconName="Filter"
          showAsButton={false}
          isSelected={hasValue}
          popupSide={PopupSide.RIGHT}
        />
      )}
    </TimelineHeaderCellInsides>
  </TimelineHeaderCellStyled>
)
