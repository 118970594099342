import {PractitionersPatient} from '@common/models'
import React, {createContext, useState, useMemo} from 'react'

type PatientContext = {
  patient?: PractitionersPatient
  setPatient: (patient?: PractitionersPatient) => void
}

export const PatientsContext = createContext({} as PatientContext)

type PatientsContextProviderProps = {
  children: React.ReactNode
}

export const PatientsContextProvider: React.FC<
  PatientsContextProviderProps
> = ({children}) => {
  const [patient, setPatient] = useState<PractitionersPatient>()

  const contextValue = useMemo(
    () => ({
      patient,
      setPatient,
    }),
    [patient],
  )

  return (
    <PatientsContext.Provider value={contextValue}>
      {children}
    </PatientsContext.Provider>
  )
}
