import styled from 'styled-components'

type Wrapper = {
  inner?: boolean
}

export const StyledLayout = styled.div`
  height: 100%;
  min-height: 100vh;
  display: flex;
`

export const LayoutWrapper = styled.div<Wrapper>`
  position: ${({inner}) => (inner ? 'absolute' : 'relative')};
  ${({inner, theme}) =>
    inner && `top: ${theme.height.topBar}; right: 0; overflow-y: auto`};
  display: ${({inner}) => (inner ? 'flex' : 'block')};
  height: ${({inner, theme}) =>
    inner ? `calc(100vh - ${theme.height.topBar})` : '100%'};
  margin-left: ${({inner, theme}) => (inner ? '0px' : theme.height.topBar)};
  width: 100%;
`

export const LayoutContent = styled.div`
  overflow-y: auto;
  flex: 1;
`

export const SideNavWrapper = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
`
