import {Name} from '@common/models'
import {formatDate} from '@common/utils'
import {DateTime} from 'luxon'
import React, {useCallback, useEffect, useRef, useState} from 'react'

export const isDevelopment = process.env.APP_ENV === 'development'

export const browserLanguage = window.navigator.language.split('-')[0]

export const getTableDateValue = (date?: string | null) =>
  date ? formatDate(DateTime.DATE_FULL, date) : '-'

export const formatName = (name?: Name | null, defaultValue = '') => {
  if (!name?.firstName && !name?.secondName && !name?.knownBy) {
    return defaultValue
  }

  return [
    name.title,
    name.firstName,
    name.knownBy ? `(${name.knownBy})` : null,
    name.secondName,
  ]
    .filter(Boolean)
    .join(' ')
}

export const getTablePatientName = (name?: Name | null) => formatName(name, '-')

// to prevent phising vulnerability https://www.jitbit.com/alexblog/256-targetblank---the-most-underestimated-vulnerability-ever/
export const openInNewTab = (url: string) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')

  if (newWindow) {
    newWindow.opener = null
  }
}

export const useKeyPress = (
  keyPressMap: Record<string, (() => void) | undefined>,
) => {
  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      const onPress = keyPressMap[event.key]

      if (onPress) {
        onPress()
      }
    }

    document.addEventListener('keydown', handleKeyPress)
    return () => {
      document.removeEventListener('keydown', handleKeyPress)
    }
  }, [keyPressMap])
}

export const useSaveShortcut = (onSave: () => void) => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      const isMac = /Mac/.test(navigator.userAgent)
      if (
        (isMac && event.metaKey && event.key === 's') ||
        (!isMac && event.ctrlKey && event.key === 's')
      ) {
        event.preventDefault()
        onSave()
      }
    }
    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [onSave])
}

export const useOutsideClickClose = (onClose: () => void) => {
  const ref = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const handleEscapeClose = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose()
      }
    }

    const handleClickOutside = (event: MouseEvent) => {
      const isClickOutsideElement =
        ref.current &&
        event.target instanceof Element &&
        !ref.current.contains(event.target)

      if (isClickOutsideElement) {
        onClose()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    document.addEventListener('keydown', handleEscapeClose)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
      document.removeEventListener('keydown', handleEscapeClose)
    }
  }, [ref, onClose])

  return ref
}

export const useInitialDimensions = () => {
  const [dimensions, setDimensions] = useState<DOMRect>()

  const ref = useCallback<React.RefCallback<HTMLDivElement>>((node) => {
    setDimensions(node?.getBoundingClientRect())
  }, [])

  return {dimensions, ref}
}

export const useDimensions = () => {
  const ref = useRef<HTMLDivElement | null>(null)
  const [dimensions, setDimensions] = useState<DOMRectReadOnly>()

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      const {contentRect} = entries[0]
      setDimensions(contentRect)
    })

    if (ref.current) {
      resizeObserver.observe(ref.current)
    }
  }, [])

  return {dimensions, ref}
}
