import React from 'react'

import {Icon, IconName} from '../icon/Icon'
import {
  InputWrapper,
  StyledInput,
  InputIcon,
  ErrorText,
  InputContainer,
} from './Input.styled'

import {theme} from '~/styles/theme'

type InputProps = {
  className?: string
  disabled?: boolean
  errorMessage?: string
  size?: 'large' | 'normal' | 'small'
  startIcon?: IconName
  endIcon?: IconName
  placeholder?: string
  value?: string
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  onBlur?: React.FocusEventHandler<HTMLInputElement>
  onFocus?: React.FocusEventHandler<HTMLInputElement>
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>
  noBorder?: boolean
  type?: React.HTMLInputTypeAttribute
}

export const Input: React.FC<InputProps> = ({
  className,
  disabled,
  size = 'normal',
  startIcon,
  endIcon,
  errorMessage,
  placeholder,
  value,
  onChange,
  onBlur,
  onFocus,
  onKeyDown,
  noBorder,
  type = 'text',
}) => (
  <InputContainer>
    <InputWrapper
      size={size}
      startIcon={!!startIcon}
      endIcon={!!endIcon}
      className={className}
      noBorder={noBorder}>
      {startIcon && (
        <InputIcon type="startIcon" size={size}>
          <Icon name={startIcon} color={theme.colors.grey.grey500} />
        </InputIcon>
      )}
      <StyledInput
        type={type}
        disabled={disabled}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
      />
      {endIcon && (
        <InputIcon type="endIcon" size={size}>
          <Icon name={endIcon} color={theme.colors.grey.grey500} />
        </InputIcon>
      )}
    </InputWrapper>
    {!!errorMessage && <ErrorText size="sm">{errorMessage}</ErrorText>}
  </InputContainer>
)
