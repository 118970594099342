import * as React from 'react'

import {IconProps} from '.'

function Pills({color, size, isFilled}: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill={isFilled ? color : 'none'}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.5565 2.27621C14.3701 3.0898 14.3701 4.40889 13.5565 5.22249C12.7429 6.03608 11.4238 6.03608 10.6102 5.22249C9.7966 4.40889 9.7966 3.0898 10.6102 2.27621C11.4238 1.46262 12.7429 1.46262 13.5565 2.27621"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.35686 5.97631C8.65861 7.27806 8.65861 9.38861 7.35686 10.6904C6.05511 11.9921 3.94456 11.9921 2.64282 10.6904C1.34107 9.3886 1.34107 7.27805 2.64282 5.97631C3.94457 4.67456 6.05512 4.67456 7.35686 5.97631"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.33317 8.33268H1.6665"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.5959 12.6276L13.4625 16.7609C12.4792 17.7442 10.8859 17.7442 9.90254 16.7609V16.7609C8.91921 15.7776 8.91921 14.1842 9.90254 13.2009L14.0359 9.06758C15.0192 8.08424 16.6125 8.08424 17.5959 9.06758V9.06758C18.5792 10.0509 18.5792 11.6451 17.5959 12.6276Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.9834 11.1484L15.5167 14.6818"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default Pills
