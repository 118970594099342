import React, {ReactNode} from 'react'

import {Typography} from '~/components/general/typography/Typography'
import {StyledButton} from '~/components/general/button/Button.styled'
import {theme} from '~/styles/theme'

export type ButtonSize = 'xl' | 'lg' | 'md' | 'sm' | 'xs'

type Props = React.HTMLAttributes<HTMLButtonElement> & {
  children?: ReactNode
  color?: 'primary' | 'secondary'
  disabled?: boolean
  endIcon?: ReactNode
  onClick: React.MouseEventHandler<HTMLButtonElement>
  size?: ButtonSize
  startIcon?: ReactNode
}

export const Button: React.FC<Props> = ({
  children,
  color = 'secondary',
  disabled = false,
  endIcon,
  onClick,
  size = 'md',
  startIcon,
  ...buttonProps
}) => (
  <StyledButton
    {...buttonProps}
    size={size}
    color={color}
    disabled={disabled}
    onClick={onClick}>
    {startIcon}
    <Typography
      color={
        color === 'primary' ? theme.colors.primary.main : theme.colors.white
      }
      weight="medium">
      {children}
    </Typography>
    {endIcon}
  </StyledButton>
)
