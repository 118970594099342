import {
  AppointmentsForPractitionerParams,
  FacilityIdAppointmentIdParams,
  FacilityIdParam,
  endpointKeys,
} from '@common/api'
import {
  AddPatientForFacility,
  AppointmentForPractitioner,
  Practitioner,
  PractitionersPatient,
  SetDefaultPatientPage,
  RescheduleAppointment,
} from '@common/models'
import {
  appointmentsForPractitionerNormaliser,
  practitionerNormaliser,
  practitionersPatientNormaliser,
} from '@common/normalisers'

import {useWebGet, useApi, useWebSet} from './helpers'

export const useGetCurrentPractitioner = () =>
  useWebGet<Practitioner>(
    [endpointKeys.practitioner],
    useApi().practitioner(),
    practitionerNormaliser,
  )

export const useSetDefaultPatientPage = () =>
  useWebSet<SetDefaultPatientPage, Practitioner>(
    [endpointKeys.practitioner],
    useApi().setDefaultPatientPage(),
    'POST',
    practitionerNormaliser,
    [[endpointKeys.practitioner]],
  )

export const useGetAppointmentsForPractitioner = (
  params: AppointmentsForPractitionerParams,
) =>
  useWebGet<AppointmentForPractitioner[]>(
    [endpointKeys.appointments, params],
    useApi().appointmentsForPractitioner(params),
    appointmentsForPractitionerNormaliser,
  )

export const useAddPatientForFacility = (params: FacilityIdParam) =>
  useWebSet<AddPatientForFacility, PractitionersPatient>(
    [endpointKeys.patients],
    useApi().addPatientForFacility(params),
    'POST',
    practitionersPatientNormaliser,
    [[endpointKeys.patients]],
  )

export const useRescheduleAppointmentForFacility = (
  params: FacilityIdAppointmentIdParams,
) =>
  useWebSet<RescheduleAppointment>(
    [endpointKeys.rescheduleAppointmentForFacility],
    useApi().rescheduleAppointmentForFacility(params),
    'PUT',
    undefined,
    [
      [endpointKeys.rescheduleAppointmentForFacility],
      [endpointKeys.appointments],
    ],
  )

export const useCancelAppointmentForFacility = (
  params: FacilityIdAppointmentIdParams,
) =>
  useWebSet<void>(
    [endpointKeys.rescheduleAppointmentForFacility],
    useApi().rescheduleAppointmentForFacility(params),
    'DELETE',
    undefined,
    [
      [endpointKeys.rescheduleAppointmentForFacility],
      [endpointKeys.appointments],
    ],
  )
