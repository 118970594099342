import React, {useContext} from 'react'
import {MISSING_ID} from '@common/utils'

import {PatientTimeline} from './PatientTimeline'
import {PatientHeader} from '../dashboard/PatientHeader'

import {PatientsContext} from '~/components/patients/PatientsContext'
import {useGetPatientCriticalNote} from '~/api'
import {Loading} from '~/components/general/loading/Loading'
import {Container} from '~/components/general/container/Container'

export const PatientTimelinePage: React.FC = () => {
  const {patient} = useContext(PatientsContext)

  const {data, isInitialLoading} = useGetPatientCriticalNote({
    patientId: patient?.patientId ?? MISSING_ID,
  })

  if (!patient?.patientId) {
    // Handled in PatientDetail
    return null
  }

  if (isInitialLoading) {
    return <Loading />
  }

  return (
    <Container flex direction="column" spacing={4}>
      <PatientHeader patient={patient} criticalNote={data} />
      <PatientTimeline patient={patient} />
    </Container>
  )
}
