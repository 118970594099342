import styled from 'styled-components'

import {Typography} from '../typography/Typography'

export const ModalBody = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;

  inset: 0px;
  z-index: 1000;
  outline: none;
  overflow: hidden;
  background-color: ${({theme}) => theme.colors.modalOverlay};
`

export const StyledModal = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1vw;
  width: 98vw;
  height: 98vh;
  background-color: ${({theme}) => theme.colors.white};
  border-radius: ${({theme}) => `${theme.borderRadius.md}`};
`

export const ModalHeaderNormal = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${({theme}) =>
    `${theme.spacing.xxl} ${theme.spacing.xl} ${theme.spacing.xl} ${theme.spacing.xxl}`};
  border-bottom: 1px solid ${({theme}) => theme.colors.grey.grey40};
`

export const ModalHeaderCompact = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${({theme}) =>
    `${theme.spacing.md} ${theme.spacing.xl} ${theme.spacing.sm} ${theme.spacing.xl}`};
  border-bottom: 1px solid ${({theme}) => theme.colors.grey.grey40};
`

export const ModalContentNormal = styled.div`
  padding: ${({theme}) => theme.spacing.xxl};
  overflow-x: hidden;
  overflow-y: auto;
`

export const ModalContentCompact = styled.div`
  padding: ${({theme}) => theme.spacing.md};
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
`

export const StyledHeaderText = styled(Typography)`
  margin: 0 ${({theme}) => theme.spacing.xs} ${({theme}) => theme.spacing.md} 0;
`
