import React, {SVGProps} from 'react'

import {imageNameMap} from '~/images'

export type ImageName = keyof typeof imageNameMap

type ImageProps = {
  name: ImageName
} & SVGProps<SVGSVGElement>

export const Image: React.FC<ImageProps> = ({name, ...props}) => {
  const Component = imageNameMap[name]
  return <Component {...props} />
}
