import * as React from 'react'

import {IconProps} from '.'

function Plaster({color, size, isFilled}: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={isFilled ? color : 'none'}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.0798 4.97713C17.5761 3.47255 15.1368 3.47234 13.6319 4.97727L4.9763 13.6329C3.47172 15.1366 3.47136 17.576 4.9763 19.0809C6.48002 20.5847 8.91937 20.5849 10.4243 19.0809C10.4244 19.0809 10.4243 19.081 10.4243 19.0809L19.08 10.4253C20.5845 8.92159 20.5847 6.48206 19.0798 4.97713ZM20.1406 3.91661C18.0508 1.82584 14.6618 1.8261 12.5712 3.91661L3.91579 12.5721C1.82486 14.6618 1.82508 18.051 3.91564 20.1416C6.00536 22.2313 9.39433 22.2311 11.4849 20.1417L20.1405 11.4861C20.1404 11.4862 20.1405 11.4861 20.1405 11.4861C22.2312 9.39636 22.2311 6.00713 20.1406 3.91661Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.96916 9.96918C10.2621 9.67629 10.7369 9.67629 11.0298 9.96918L14.0311 12.9704C14.324 13.2633 14.324 13.7382 14.0311 14.0311C13.7382 14.324 13.2633 14.324 12.9704 14.0311L9.96916 11.0298C9.67627 10.7369 9.67627 10.2621 9.96916 9.96918Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5013 6.75485C16.5013 6.31263 16.8591 5.95483 17.3013 5.95483C17.7435 5.95483 18.1013 6.31263 18.1013 6.75485C18.1013 7.19708 17.7435 7.55488 17.3013 7.55488C16.8591 7.55488 16.5013 7.19708 16.5013 6.75485Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.7071 6.71884C13.7071 6.27662 14.0649 5.91882 14.5072 5.91882C14.9494 5.91882 15.3072 6.27662 15.3072 6.71884C15.3072 7.16107 14.9494 7.51886 14.5072 7.51886C14.0649 7.51886 13.7071 7.16107 13.7071 6.71884Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5373 9.54904C16.5373 9.10682 16.8951 8.74902 17.3373 8.74902C17.7688 8.74902 18.1374 9.0971 18.1374 9.54904C18.1374 9.99127 17.7796 10.3491 17.3373 10.3491C16.8951 10.3491 16.5373 9.99127 16.5373 9.54904Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.74907 17.3374C8.74907 16.8951 9.10687 16.5374 9.54909 16.5374C9.99132 16.5374 10.3491 16.8951 10.3491 17.3374C10.3491 17.7796 9.99132 18.1374 9.54909 18.1374C9.10687 18.1374 8.74907 17.7796 8.74907 17.3374Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.95491 17.3012C5.95491 16.859 6.31271 16.5012 6.75493 16.5012C7.19716 16.5012 7.55495 16.859 7.55495 17.3012C7.55495 17.7435 7.19716 18.1013 6.75493 18.1013C6.3127 18.1013 5.95491 17.7435 5.95491 17.3012Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.91889 14.5072C5.91889 14.0649 6.27669 13.7072 6.71891 13.7072C7.16114 13.7072 7.51893 14.0649 7.51893 14.5072C7.51893 14.9494 7.16114 15.3072 6.71891 15.3072C6.27669 15.3072 5.91889 14.9494 5.91889 14.5072Z"
        fill={color}
      />
    </svg>
  )
}

export default Plaster
