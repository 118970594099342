import {QuestionnaireRepeatPeriod} from '@common/models'
import {EnumType, useEnumTranslations} from '@common/utils'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {ShareQuestionnaireSchedulingProps} from './ShareQuestionnaireScheduling'

import {Option, Select} from '~/components/general/select/Select'
import {Typography} from '~/components/general/typography/Typography'
import {theme} from '~/styles/theme'

const RepeatPeriodOptions: React.FC<ShareQuestionnaireSchedulingProps> = ({
  schedule,
  setSchedule,
}) => {
  const {translateEnumValue} = useEnumTranslations()

  return (
    <>
      {Object.values(QuestionnaireRepeatPeriod).map((repeatPeriod) => (
        <Option
          size="small"
          key={repeatPeriod}
          onClick={() => setSchedule({...schedule, repeatPeriod})}>
          <Typography color={theme.colors.grey.main} size="sm">
            {translateEnumValue(
              EnumType.QuestionnaireRepeatPeriod,
              repeatPeriod,
            )}
          </Typography>
        </Option>
      ))}
    </>
  )
}

export const SelectQuestionnaireRepeatPeriod: React.FC<
  ShareQuestionnaireSchedulingProps
> = ({schedule, setSchedule}) => {
  const {translateEnumValue} = useEnumTranslations()
  const {t} = useTranslation()

  const noRepeatText = t('None')

  return (
    <Select
      size="small"
      value={
        translateEnumValue(
          EnumType.QuestionnaireRepeatPeriod,
          schedule.repeatPeriod,
        ) || noRepeatText
      }>
      <Option
        size="small"
        onClick={() =>
          setSchedule({
            ...schedule,
            repeatPeriod: undefined,
            endDate: undefined,
          })
        }>
        <Typography color={theme.colors.grey.main} size="sm">
          {noRepeatText}
        </Typography>
      </Option>
      <RepeatPeriodOptions schedule={schedule} setSchedule={setSchedule} />
    </Select>
  )
}
