import styled from 'styled-components'

import {Typography} from '~/components/general/typography/Typography'

export const QuestionnairePreviewWrapper = styled.div`
  overflow: overlay;
  width: 100%;
  background-color: ${({theme}) => theme.colors.grey.grey40};
`

export const StyledQuestionnairePreview = styled.div`
  background-color: ${({theme}) => theme.colors.white};
  border-radius: ${({theme}) => theme.borderRadius.xxs};
  padding: ${({theme}) => theme.spacing.xxl};
  margin-top: ${({theme}) => theme.spacing.md};
`

export const QuestionnairePreviewCard = styled.div`
  border: 1px solid ${({theme}) => theme.colors.grey.grey50};
  border-radius: ${({theme}) => theme.borderRadius.xs};
  padding: ${({theme}) => theme.spacing.xxl};
`

export const QuestionnairePreviewAnswerWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: ${({theme}) => theme.spacing.lg};
`

export const StyledTextArea = styled.textarea`
  background: none;
  display: block;
  width: 100%;
  font-size: ${({theme}) => theme && theme.fontSize.md};
  line-height: ${({theme}) => theme && theme.lineHeight.lg};
  color: ${({theme}) => theme.colors.grey.main};
  border: 0;
  outline: 0;
  resize: none;

  &::placeholder {
    color: ${({theme}) => theme.colors.inputPlaceholder};
  }
`

export const TextAreaWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 140px;
  display: inline-flex;
  position: relative;
  border: 1px solid ${({theme}) => theme.colors.inputBorder};
  border-radius: ${({theme}) => theme.borderRadius.xs};

  ${StyledTextArea} {
    padding: 15px;
  }
`

export const StyledSlider = styled.input`
  -webkit-appearance: none;
  width: 100%;
  margin: 0 ${({theme}) => theme.spacing.md};

  &:focus {
    outline: none;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    margin-top: -4px;
    background: ${({theme}) => theme.colors.primary.main};
    border-radius: ${({theme}) => theme.borderRadius.xs};
    height: ${({theme}) => theme.spacing.md};
    width: ${({theme}) => theme.spacing.md};
  }

  &::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    width: 100%;
    height: ${({theme}) => theme.spacing.xs};
    background: ${({theme}) => theme.colors.grey.grey150};
    border-radius: ${({theme}) => theme.borderRadius.xxs};
  }

  &::-moz-range-thumb {
    margin-top: -4px;
    background: ${({theme}) => theme.colors.primary.main};
    border-radius: ${({theme}) => theme.borderRadius.xs};
    height: ${({theme}) => theme.spacing.md};
    width: ${({theme}) => theme.spacing.md};
  }

  &::-moz-range-track {
    width: 100%;
    height: ${({theme}) => theme.spacing.xs};
    background: ${({theme}) => theme.colors.grey.grey150};
    border-radius: ${({theme}) => theme.borderRadius.xxs};
  }
`

export const SliderStyledText = styled(Typography)`
  margin-top: ${({theme}) => theme.spacing.md};
  width: 100%;
  text-align: center;
`
