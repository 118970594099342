import React from 'react'

import {QuestionnairesSidebarTitle} from './QuestionnairesSidebar.styled'

import {Loading} from '~/components/general/loading/Loading'
import {
  InnerSideNav,
  InnerSideNavItem,
} from '~/components/layout/sideNavs/innerSideNav/InnerSideNav'
import {Container} from '~/components/general/container/Container'
import {theme} from '~/styles/theme'

export type QuestionnairesSidebarDataList = {
  id: string
  label: string
  count?: number
  redBadge?: boolean
}

export type QuestionnairesSidebarData = {
  title: string
  list: QuestionnairesSidebarDataList[]
}

type QuestionnairesSidebarProps = {
  sidebarDataList: QuestionnairesSidebarData[]
  isLoading?: boolean
  activeId: string
  onClick: (id: string) => void
}

export const QuestionnairesSidebar: React.FC<QuestionnairesSidebarProps> = ({
  sidebarDataList,
  isLoading,
  activeId,
  onClick,
}) => (
  <InnerSideNav>
    {isLoading ? (
      <Loading />
    ) : (
      <Container direction="column" spacing={3}>
        {sidebarDataList.map((data) => (
          <div key={data.title}>
            <QuestionnairesSidebarTitle
              color={theme.colors.grey.main}
              size="sm"
              weight="medium"
              uppercase>
              {data.title}
            </QuestionnairesSidebarTitle>
            {data.list.map((item) => (
              <InnerSideNavItem
                active={activeId === item.id}
                badge={item.count}
                redBadge={item.redBadge}
                key={item.id}
                onClick={() => onClick(item.id)}>
                {item.label}
              </InnerSideNavItem>
            ))}
          </div>
        ))}
      </Container>
    )}
  </InnerSideNav>
)
