import {
  BasePermissionCategory,
  HealthDataGrade,
  HealthDataType,
} from '@common/models'
import {useContext} from 'react'
import {MISSING_ID} from '@common/utils'

import {useGetLatestActivities} from '~/api/activities'
import {useGetPatientHealthSummary} from '~/api/medical'
import {GradeIndicatorColor} from '~/components/general/gradeIndicator/GradeIndicator'
import {IconName} from '~/components/general/icon/Icon'
import {PatientsContext} from '~/components/patients/PatientsContext'

const badgeColorGradeMap: Record<HealthDataGrade, GradeIndicatorColor> = {
  [HealthDataGrade.GOOD]: 'green',
  [HealthDataGrade.AVERAGE]: 'orange',
  [HealthDataGrade.BAD]: 'red',
}

export const getGradeIndicatorColor = (grade?: HealthDataGrade | null) =>
  grade ? badgeColorGradeMap[grade] : undefined

export const healthDataIconMap: Record<HealthDataType, IconName> = {
  [HealthDataType.ACTIVELY_BURNED_CALORIES]: 'UserRunning',
  [HealthDataType.ALCOHOL_SCORE]: 'Toast',
  [HealthDataType.BLOOD_PRESSURE]: 'Blood',
  [HealthDataType.BMI]: 'Weight',
  [HealthDataType.DIET_SCORE]: 'Diet',
  [HealthDataType.BODY_FAT_PERCENTAGE]: 'UserPercentage',
  [HealthDataType.CHOLESTEROL_HDL_RATIO]: 'HDLDrop',
  [HealthDataType.HEART_AGE_YEARS]: 'HeartCheck',
  [HealthDataType.HEIGHT_CM]: 'Person',
  [HealthDataType.MOOD_SCORE]: 'User',
  [HealthDataType.MOVEMENT_SCORE]: 'Distance',
  [HealthDataType.PEAK_FLOW]: 'Monitoring', // TODO: replace with actual icon
  [HealthDataType.RESTING_HEART_RATE]: 'HeartBeat',
  [HealthDataType.SLEEP_SCORE]: 'Moon',
  [HealthDataType.QUALITY_SLEEP_SECONDS]: 'Clock',
  [HealthDataType.STEPS]: 'Steps',
  [HealthDataType.TOBACCO_SCORE]: 'Smoking',
  [HealthDataType.VO2_MAX]: 'MonitoringNote', // TODO: replace with actual icon
  [HealthDataType.ACTIVE_MINUTES]: 'UserActive',
  [HealthDataType.WAIST_CIRCUMFERENCE_CM]: 'Ruler',
  [HealthDataType.WATER_CONSUMPTION_ML]: 'WaterDrop',
  [HealthDataType.WEIGHT_KG]: 'Weight',
}

export const useHealthData = () => {
  const {patient} = useContext(PatientsContext)

  const permissions = patient?.permissions

  const hasActivityPermission = permissions?.includes(
    BasePermissionCategory.ACTIVITY,
  )

  const {data: healthSummaryData, isInitialLoading: isHealthSummaryLoading} =
    useGetPatientHealthSummary({
      patientId: patient?.patientId ?? MISSING_ID,
    })

  const {data: latestActivities, isInitialLoading: areLatestActivitiesLoading} =
    useGetLatestActivities(
      {
        patientId: patient?.patientId ?? MISSING_ID,
        fields: Object.values(HealthDataType),
      },
      {enabled: hasActivityPermission},
    )

  return {
    isLoading: areLatestActivitiesLoading || isHealthSummaryLoading,
    latestActivities,
    healthSummaryData,
  }
}
