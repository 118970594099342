import styled from 'styled-components'

import {Input} from '../input/Input'
import {IconButton} from '../iconButton/IconButton'

export const SearchForm = styled.form`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  border: 1px solid grey;
  border-radius: ${({theme}) => theme.borderRadius.xs};
  padding-bottom: 1px;
  width: 30%;
`

export const SearchInput = styled(Input)`
  flex-grow: 2;
  border: none;
`

export const SearchButton = styled(IconButton)`
  border-radius: ${({theme}) => theme.borderRadius.xs};
  height: ${({theme}) => theme.height.input.normal};
  width: ${({theme}) => theme.height.input.normal};
`
