import React from 'react'
import {useTranslation} from 'react-i18next'
import {RecordType} from '@common/models'

import {Icon, IconProps} from '../icon/Icon'

import {iconNameMap} from '~/icons'

const iconMap: Record<RecordType, keyof typeof iconNameMap> = {
  [RecordType.MANUAL]: 'SelectHand',
  [RecordType.PARSED]: 'Pdf',
  [RecordType.IMPORTED]: 'Share',
}

type DataSourceIndicatorProps = {
  source?: RecordType
  iconProps?: Omit<IconProps, 'name' | 'title'>
}

export const DataSourceIndicator = ({
  source,
  iconProps,
}: DataSourceIndicatorProps) => {
  const {t} = useTranslation()

  if (!source) {
    return null
  }

  const titleMap: Record<RecordType, string> = {
    [RecordType.MANUAL]: t('Added manually'),
    [RecordType.PARSED]: t('Parsed from pdf'),
    [RecordType.IMPORTED]: t('Imported from external system'),
  }

  return <Icon name={iconMap[source]} title={titleMap[source]} {...iconProps} />
}
