import React, {useContext, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {
  PractitionerCreateAppointmentResponse,
  PractitionersPatient,
} from '@common/models'
import {MISSING_ID} from '@common/utils'
import {useNavigate} from 'react-router-dom'

import {FindPatientCard} from './FindPatientCard'
import AddAppointmentCard from './AddAppointmentCard'

import {SideModal} from '~/components/general/modal/SideModal'
import {Typography} from '~/components/general/typography/Typography'
import {PractitionerContext} from '~/components/profile/PractitionerContext'
import {useCreatePatientAppointment} from '~/api'

interface AddAppointmentModalProps {
  isVisible: boolean
  onClose: () => void
}

const AddAppointmentModal: React.FC<AddAppointmentModalProps> = ({
  isVisible,
  onClose,
}) => {
  const navigate = useNavigate()
  const {t} = useTranslation()
  const {selectedEmployment} = useContext(PractitionerContext)
  const [selectedPatient, setSelectedPatient] =
    useState<PractitionersPatient | null>(null)
  const {mutate} = useCreatePatientAppointment({
    facilityId: selectedEmployment?.facilityId || MISSING_ID,
  })

  const createPatientAppointment = (
    appointmentDate: Date,
    location: string | null,
  ) => {
    if (!selectedPatient) {
      return
    }

    mutate(
      {
        patientId: selectedPatient.patientId,
        appointmentDate: appointmentDate.toISOString(),
        department: location || undefined,
      },
      {
        onSuccess: (response: PractitionerCreateAppointmentResponse) => {
          navigate(`${response.appointmentId}/${selectedPatient.patientId}`)
        },
      },
    )
  }

  return (
    <SideModal
      isVisible={isVisible}
      onClose={onClose}
      header={
        <div>
          <Typography size="lg" weight="medium">
            {t('Add Appointment')}
          </Typography>
        </div>
      }>
      <FindPatientCard
        onSelectPatient={(patient) => {
          setSelectedPatient(patient)
        }}
      />
      {selectedPatient && (
        <AddAppointmentCard
          locations={selectedEmployment?.departments || []}
          onAppointmentCreate={createPatientAppointment}
        />
      )}
    </SideModal>
  )
}

export default AddAppointmentModal
