import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {useParams} from 'react-router-dom'
import {MISSING_ID} from '@common/utils'

import {QuestionnairesContent} from './Questionnaire.styled'
import {QuestionnairePreview} from './questionnairePreview/QuestionnairePreview'
import {QuestionnairesColumn} from './questionnairesColumn/QuestionnairesColumn'
import {Loading} from '../general/loading/Loading'

import {Typography} from '~/components/general/typography/Typography'
import {useGetQuestionnaire} from '~/api'

export const QuestionnaireDetail: React.FC = () => {
  const {t} = useTranslation()
  const {questionnaireId} = useParams()

  const {data, isInitialLoading} = useGetQuestionnaire({
    id: questionnaireId || MISSING_ID,
  })

  const colItems = useMemo(
    () => [
      {
        id: '0',
        title: t('Alias'),
        description: data?.alias || '-',
      },
      {
        id: '1',
        title: t('Description'),
        description: data?.description || '-',
      },
      {
        id: '2',
        title: t('Published by'),
        description: data?.publishedBy || '-',
      },
      {
        id: '3',
        title: t('Licensed by'),
        description: data?.licensedBy || '-',
      },
      {
        id: '4',
        title: t('Tags'),
        description: data?.tags.join(', ') || '-',
      },
    ],
    [t, data],
  )

  if (isInitialLoading) {
    return <Loading />
  }

  if (!data) {
    return <Typography>{t('Missing questionnaire data')}</Typography>
  }

  return (
    <QuestionnairesContent>
      <QuestionnairesColumn items={colItems} />
      <QuestionnairePreview questions={data.questions} title={t('Preview')} />
    </QuestionnairesContent>
  )
}
