import styled from 'styled-components'

export const StyledIndicatorBadgeCard = styled.div`
  align-self: stretch;
  background-color: ${({theme}) => theme.colors.grey.grey20};
  border-radius: ${({theme}) => theme.borderRadius.sm};
  padding: ${({theme}) => theme.spacing.xs};
`

export const StyledBadgeIconBackground = styled.div`
  background-color: ${({theme}) => theme.colors.grey.grey100};
  border-radius: ${({theme}) => theme.borderRadius.xs};
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ValueContainer = styled.div`
  width: 40px;
  cursor: pointer;
`
