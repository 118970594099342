import React, {useEffect} from 'react'
import {RowGroup, RowGroupType} from '@common/models'

import {Container} from '~/components/general/container/Container'
import {Input} from '~/components/general/input/Input'

export type TrendEditorItemProps = {
  label: string
  name: string
  inputType: React.HTMLInputTypeAttribute
}

export type BaseTrendEditorProps = {
  setData: (newData: RowGroup[]) => void
  fields: TrendEditorItemProps[]
}

export type TrendEditorProps = {
  setData: (newData: RowGroup[]) => void
}

const makeRowGroup = (field: string, value: string): RowGroup => ({
  type: RowGroupType.FIELD,
  field,
  value,
})

export const BaseTrendEditor: React.FC<BaseTrendEditorProps> = ({
  setData,
  fields,
}) => {
  const [values, setValues] = React.useState(
    Array<string>(fields.length).fill(''),
  )

  // TODO: Need to implement a validation function to check if the data is valid before updating

  useEffect(() => {
    setData(
      fields.map((field, index) => makeRowGroup(field.name, values[index])),
    )
  }, [values, fields, setData])

  const handleChange =
    (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValues = [...values]
      newValues[index] = e.target.value
      setValues(newValues)
    }

  return (
    <Container direction="row" flex>
      {fields.map((field, index) => (
        <Input
          key={index}
          type="number"
          placeholder={field.label}
          value={values[index]}
          onChange={handleChange(index)}
        />
      ))}
    </Container>
  )
}
