import React, {useEffect, useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {TableHeaderCellWrap, TableHeaderCell} from './Table.styled'

import {Typography} from '~/components/general/typography/Typography'
import {theme} from '~/styles/theme'

export const ONE_THIRD_IN_PERCENT = 33.3333

export type SimpleTableHeaderProps = {
  columns: {
    title: string
    columnWidthPercent?: number
  }[]
  showSourceIcons?: boolean
}

export const SimpleTableHeader: React.FC<SimpleTableHeaderProps> = ({
  columns,
  showSourceIcons,
}) => {
  const {t} = useTranslation()

  useEffect(() => {
    const columnsWidthSum = columns.reduce(
      (prev, column) => prev + (column.columnWidthPercent ?? 0),
      0,
    )

    if (Math.abs(columnsWidthSum - 100) > 0.1) {
      // [CUSH-655] FIXME: Add error logging
      // eslint-disable-next-line no-console
      console.warn(
        `SimpleTableHeader total columns width is ${columnsWidthSum}%, should be 100%`,
      )
    }
  }, [columns])

  const columnsToDisplay = useMemo(
    () =>
      showSourceIcons
        ? [
            ...columns,
            {
              title: t('Source'),
              columnWidthPercent: 10,
            },
          ]
        : columns,
    [columns, showSourceIcons, t],
  )

  return (
    <thead>
      <tr>
        {columnsToDisplay.map((column, index) => {
          const first = index === 0
          const last = index === columnsToDisplay.length - 1

          return (
            <TableHeaderCell
              columnWidthPercent={column.columnWidthPercent}
              key={`${column.title}${index}`}>
              <TableHeaderCellWrap first={first} last={last}>
                <Typography color={theme.colors.grey.grey600}>
                  {column.title}
                </Typography>
              </TableHeaderCellWrap>
            </TableHeaderCell>
          )
        })}
      </tr>
    </thead>
  )
}
