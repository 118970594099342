import {
  PagedResponse,
  AssignedQuestionnaireInfo,
  QuestionnaireStatus,
} from '@common/models'
import {useTranslation} from 'react-i18next'
import React, {useMemo} from 'react'

import {AssignedQuestionnaires} from '~/components/questionnaires/AssignedQuestionnaires'
import {getTableDateValue, getTablePatientName} from '~/utils'

export const UnansweredQuestionnaires: React.FC = () => {
  const {t} = useTranslation()

  const tableHeaders = useMemo(
    () => ({
      columns: [
        {
          title: t('Patient name'),
          columnWidthPercent: 40,
        },
        {
          title: t('Questionnaire title'),
          columnWidthPercent: 40,
        },
        {
          title: t('Date sent'),
          columnWidthPercent: 20,
        },
      ],
    }),
    [t],
  )

  const getTableRows = (data?: PagedResponse<AssignedQuestionnaireInfo>) =>
    data?.records.map((record) => ({
      columns: [
        getTablePatientName(record.patientDetails),
        record.title,
        getTableDateValue(record.dateReceived),
      ],
    })) ?? []

  return (
    <AssignedQuestionnaires
      headers={tableHeaders}
      getRows={getTableRows}
      questionnaireStatus={QuestionnaireStatus.NOT_COMPLETED}
    />
  )
}
