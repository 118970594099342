import styled from 'styled-components'

type HeaderCellWrap = {
  first?: boolean
  last?: boolean
}

type TableCellLoaderProps = {
  rowSpan?: number
}

type TableHeaderCellProps = {
  columnWidthPercent?: number
}

export const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  text-align: left;
  table-layout: fixed;
`

export const TableHeaderCell = styled.th<TableHeaderCellProps>`
  padding: 0;
  ${({columnWidthPercent}) =>
    columnWidthPercent && `width: ${columnWidthPercent}%`};
`

export const TableHeaderCellWrap = styled.div<HeaderCellWrap>`
  height: ${({theme}) => theme.height.tableHeader};
  background-color: ${({theme}) => theme.colors.grey.grey20};
  display: flex;
  align-items: center;
  padding: 0 ${({theme}) => theme.spacing.lg};
  border-color: ${({theme}) => theme.colors.grey.grey50};
  border-style: solid;
  border-width: 1px 0 1px 0;

  ${({first, theme}) =>
    first &&
    `border-top-left-radius: ${theme.borderRadius.xs}; border-bottom-left-radius: ${theme.borderRadius.xs}; border-left-width: 1px`};
  ${({last, theme}) =>
    last &&
    `border-top-right-radius: ${theme.borderRadius.xs}; border-bottom-right-radius: ${theme.borderRadius.xs}; border-right-width: 1px`};
`

export const TableCellLoader = styled.td<TableCellLoaderProps>`
  height: calc(
    ${({theme}) => theme.height.tableRow} * ${({rowSpan}) => rowSpan ?? 1}
  );
`

export const TableRow = styled.tr`
  height: ${({theme}) => theme.height.tableRow};
  border-bottom: 1px solid ${({theme}) => theme.colors.grey.grey40};

  ${({onClick}) => !!onClick && `cursor: pointer;`}
`

export const TableCell = styled.td`
  padding: 0 ${({theme}) => theme.spacing.lg};
`

export const BorderedTableCell = styled.td`
  padding: 0 ${({theme}) => theme.spacing.lg};
  border: 1px solid ${({theme}) => theme.colors.grey.grey40};
`
