import React from 'react'

type AuthContextType = {
  setLoadingFalse: () => void
  signIn: () => Promise<void> // Signs the user in -> loads accessToken to user context
  signUp: () => Promise<void> // Signs up new user -> loads accessToken to user context
  signOut: () => Promise<void> // Signs the user out -> revokes tokens on the server, deletes user context accessToken
  startSignUp: () => Promise<void> // Sets signUpInProgress to true, so the stack navigator can render sign up screens
  endSignUp: () => Promise<void> // Sets signUpInProgress to false, so the stack navigator can render sign up screens
  changePassword: () => Promise<void>
  isLoading: boolean // Is AuthContext loading?
  signUpInProgress: boolean // Has the user started sign up process? (needed for stack navigator to show sign up screens)
}

export const AuthContext = React.createContext<AuthContextType>({
  setLoadingFalse: () => undefined,
  signIn: async () => undefined,
  signUp: async () => undefined,
  signOut: async () => undefined,
  startSignUp: async () => undefined,
  endSignUp: async () => undefined,
  changePassword: async () => undefined,
  isLoading: false,
  signUpInProgress: false,
})
