import React, {ReactNode} from 'react'
import {useTranslation} from 'react-i18next'

import {
  StyledMaterialsSelect,
  MaterialsSelectWrapper,
  StyledSelect,
} from './MaterialsSelect.styled'

import {Typography} from '~/components/general/typography/Typography'
import {theme} from '~/styles/theme'

type Props = {
  children?: ReactNode
  selectedOption?: string
}

export const MaterialsSelect: React.FC<Props> = ({
  children,
  selectedOption,
}) => {
  const {t} = useTranslation()
  return (
    <StyledMaterialsSelect>
      <Typography color={theme.colors.grey.main}>
        {t('Selected condition:')}
      </Typography>
      <MaterialsSelectWrapper>
        <StyledSelect
          noBorder
          noIcon
          placeholder="Select condition"
          showOutsideIcon
          selectedOption={selectedOption}>
          {children}
        </StyledSelect>
      </MaterialsSelectWrapper>
    </StyledMaterialsSelect>
  )
}
