import styled from 'styled-components'

type CardProps = {
  subcard?: boolean
  danger?: boolean
}

export const TimelineDotWrap = styled.div<CardProps>`
  height: ${({subcard}) => (subcard ? '19px' : '23px')};
  width: ${({subcard}) => (subcard ? '7px' : '11px')};
  background-color: ${({theme}) => theme.colors.white};
  display: flex;
  align-items: center;
  position: absolute;
  top: ${({subcard}) => (subcard ? '17px' : 'calc(60% - 12px)')};
  left: ${({subcard}) => (subcard ? '-19px' : '-21px')};
  z-index: 10;
`

export const TimelineDot = styled.div<CardProps>`
  width: ${({subcard}) => (subcard ? '7px' : '11px')};
  height: ${({subcard}) => (subcard ? '7px' : '11px')};
  background-color: ${({danger, theme}) =>
    danger ? theme.colors.red : theme.colors.primary.blue900};
  border-radius: ${({theme}) => theme.borderRadius.circled};
`

export const StyledTimelineCard = styled.div<CardProps>`
  position: relative;
  background-color: ${({danger, theme}) =>
    danger ? theme.colors.secondary.orange50 : theme.colors.primary.blue25};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({theme}) => theme.spacing.md};
  margin-left: ${({theme}) => theme.spacing.md};
  margin-bottom: ${({theme}) => theme.spacing.sm};
  border-radius: ${({theme}) => theme.borderRadius.sm};
  ${({onClick}) => !!onClick && 'cursor: pointer;'}

  &::before {
    content: '';
    position: absolute;
    top: ${({subcard}) => (subcard ? 'calc(50% - 6px)' : 'calc(60% - 6px)')};
    left: -6px;
    width: 0;
    height: 0;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-right: 6px solid
      ${({danger, theme}) =>
        danger ? theme.colors.secondary.orange50 : theme.colors.primary.blue25};
  }
`

export const TimelineIcon = styled.div`
  width: ${({theme}) => theme.spacing.xl};
  height: ${({theme}) => theme.spacing.xl};
  background-color: ${({theme}) => theme.colors.grey.grey100};
  border-radius: ${({theme}) => theme.borderRadius.xs};
  display: flex;
  align-items: center;
  justify-content: center;
`
