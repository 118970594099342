import {HealthDataGrading} from '@common/models'
import styled from 'styled-components'
import React from 'react'

import {GradeIndicator} from './GradeIndicator'
import {TrendIndicator} from './TrendIndicator'
import {Container} from '../container/Container'

import {getGradeIndicatorColor} from '~/utils'

const EmptySpace = styled.div`
  width: 24px;
`

type GradeIndicatorsProps = {
  alwaysShowTrend?: boolean
  spacing?: number
} & HealthDataGrading

export const GradeIndicators: React.FC<GradeIndicatorsProps> = ({
  alwaysShowTrend,
  grade,
  spacing = 1,
  trend,
  trendGrade,
}) => {
  const showGradeIndicator = grade !== undefined
  const showTrendIndicator = alwaysShowTrend || trend

  return (
    <Container flex spacing={spacing}>
      {showGradeIndicator ? (
        <GradeIndicator colorVariant={getGradeIndicatorColor(grade)} />
      ) : (
        <EmptySpace />
      )}
      {showTrendIndicator ? (
        <TrendIndicator
          trend={trend}
          circled
          colorVariant={getGradeIndicatorColor(trendGrade)}
        />
      ) : (
        <EmptySpace />
      )}
    </Container>
  )
}
