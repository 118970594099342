import styled from 'styled-components'

type DisabledProps = {
  disabled?: boolean
}

export const StyledCheckbox = styled.label<DisabledProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  user-select: none;
  column-gap: ${({theme}) => theme.spacing.sm};

  ${({disabled}) => !disabled && 'cursor: pointer;'}
`

export const CheckboxInput = styled.input`
  position: absolute;
  opacity: 0;

  ${({readOnly}) => !readOnly && 'cursor: pointer;'}
`
