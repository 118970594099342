/* tslint:disable */
/* eslint-disable */
/**
 * Cushla API
 * REST API for Cushla
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */

export enum OnboardingStep {
  START = 'START',
  CONNECT_DEVICE = 'CONNECT_DEVICE',
  MEDICAL_CONDITIONS = 'MEDICAL_CONDITIONS',
  SURGERIES = 'SURGERIES',
  TESTS = 'TESTS',
  MEDICATIONS = 'MEDICATIONS',
  ALLERGIES = 'ALLERGIES',
  FAMILY_HISTORY = 'FAMILY_HISTORY',
  LIFESTYLE = 'LIFESTYLE',
  MOOD = 'MOOD',
  REQUEST_RECORDS = 'REQUEST_RECORDS',
}
