import {
  AllergyEffect,
  Appointment,
  AppointmentSummary,
  AssignedQuestionnaire,
  Birth,
  DiagnosedCondition,
  ExactOrEstimatedDate,
  LifeMilestone,
  MedicalTest,
  Medication,
  PatientAllergy,
  PatientCholesterol,
  PatientVaccination,
  RecordType,
  RowGroupType,
  Sex,
  Surgery,
} from '@common/models'
import {
  capitalizeFirstLetter,
  createExactOrEstimatedDate,
  EnumType,
  formatConditionStatus,
  formatDate,
  formatExactOrEstimatedDate,
  formatLocaleNumber,
  formatQuestionnaireAnswersForDisplay,
  formatSurgeryTitle,
  isTruthy,
  parseQuestionnaireAnswers,
  useEnumTranslations,
} from '@common/utils'
import {useTranslation} from 'react-i18next'
import {useCallback} from 'react'
import {DateTime} from 'luxon'
import {TFunction} from 'i18next'

import {PatientTimelineModalData} from '~/components/patients/timeline/components/PatientTimelineModal'
import {AttachedFile} from '~/components/patients/timeline/components/AttachmentsContainer'

export enum TimelineTableItemType {
  UNKNOWN = 'UNKNOWN',
  ALLERGY = 'ALLERGY',
  BIRTH = 'BIRTH',
  CHOLESTEROL = 'CHOLESTEROL',
  CONDITION = 'CONDITION',
  MEDICAL_TEST = 'MEDICAL_TEST',
  MEDICATION = 'MEDICATION',
  SURGERY = 'SURGERY',
  VACCINATION = 'VACCINATION',
  APPOINTMENT = 'APPOINTMENT',
  LIFE_MILESTONE = 'LIFE_MILESTONE',
}

export type TimelineItem = {
  itemType: TimelineTableItemType
  itemDate?: ExactOrEstimatedDate // TODO: MAKE THIS NOT NULLABLE
  description: string
  facilityName?: string
  healthJourneyType?: string
  source?: RecordType
}

type GetTimelineDataFn<T> = (item: T) => TimelineItem

export const formatCholesterolValue = (value?: number | null) =>
  value == null ? '-' : `${formatLocaleNumber(value, 1)} mmol/l`

const formatGenderIndicator = (t: TFunction, value: Sex | undefined) => {
  if (!value) {
    return ''
  }
  if (value === Sex.FEMALE) {
    return t('(F)', {context: 'Female'})
  }
  return t('(M)', {context: 'Male'})
}

export const useTimelineCardData = () => {
  const {t} = useTranslation()
  const {translateEnumValue} = useEnumTranslations()

  const getAllergyTimelineCardData: GetTimelineDataFn<PatientAllergy> =
    useCallback(
      (allergy) => ({
        itemType: TimelineTableItemType.ALLERGY,
        itemDate: allergy.date,
        description: allergy.allergen.name,
        source: allergy.recordType,
      }),
      [],
    )

  const getBirthTimelineCardData: GetTimelineDataFn<Birth> = useCallback(
    (birth) => ({
      itemType: TimelineTableItemType.BIRTH,
      itemDate: {date: birth.dateOfBirth, isEstimated: false},
      description: `${birth.name} ${formatGenderIndicator(t, birth.gender)}`,
    }),
    [t],
  )

  const getCholesterolTimelineCardData: GetTimelineDataFn<PatientCholesterol> =
    useCallback(
      (cholesterol) => {
        const getCholesterolScore = () =>
          cholesterol.hdlRatio
            ? t('Cholesterol Test - HDL ratio: {{hdlRatio}}', {
                hdlRatio: formatLocaleNumber(cholesterol.hdlRatio, 1),
              })
            : t('Cholesterol Test')
        return {
          itemType: TimelineTableItemType.CHOLESTEROL,
          itemDate: cholesterol.date,
          description: getCholesterolScore(),
          facilityName: cholesterol.facility?.name,
          source: cholesterol.sourceDocument
            ? RecordType.PARSED
            : RecordType.MANUAL,
        }
      },
      [t],
    )

  const getConditionTimelineCardData: GetTimelineDataFn<DiagnosedCondition> =
    useCallback(
      (condition) => ({
        itemType: TimelineTableItemType.CONDITION,
        itemDate: condition.date,
        description:
          condition.condition || condition.customName || t('Medical condition'),
        facilityName: condition.facility?.name,
        source: condition.recordType,
      }),
      [t],
    )

  const getMedicalTestTimelineCardData: GetTimelineDataFn<MedicalTest> =
    useCallback(
      (test) => ({
        itemType: TimelineTableItemType.MEDICAL_TEST,
        itemDate: test.date,
        description:
          test.recordType === RecordType.PARSED
            ? translateEnumValue(EnumType.ParsedMedicalTestType, test.type)
            : test.type.value.label || t('Medical test'),
        facilityName: test.facility?.name,
        source: test.recordType,
      }),
      [translateEnumValue, t],
    )

  const getMedicationTimelineCardData: GetTimelineDataFn<Medication> =
    useCallback(
      (medication) => {
        const differingData =
          medication.recordType === RecordType.PARSED
            ? {
                description: medication.drugs?.join(', ') || t('Medication'),
                itemDate: medication.date,
                facility: medication.facility?.name,
              }
            : {
                description:
                  medication.drug?.tradeName ||
                  medication.name ||
                  t('Medication'),
                itemDate: medication.startDate,
              }

        return {
          itemType: TimelineTableItemType.MEDICATION,
          source: medication.recordType,
          ...differingData,
        }
      },
      [t],
    )

  const getSurgeryTimelineCardData: GetTimelineDataFn<Surgery> = useCallback(
    (surgery) => ({
      itemType: TimelineTableItemType.SURGERY,
      itemDate: surgery.date,
      description: formatSurgeryTitle(surgery, t) || t('Surgery'),
      facilityName: surgery.facility?.name,
      source: surgery.recordType,
    }),
    [t],
  )

  const getVaccinationTimelineCardData: GetTimelineDataFn<PatientVaccination> =
    useCallback(
      (vaccination) => ({
        itemType: TimelineTableItemType.VACCINATION,
        itemDate: vaccination.date,
        description:
          vaccination.recordType === RecordType.PARSED
            ? vaccination.shotName
            : vaccination.vaccine.name,
        facilityName: vaccination.facility?.name,
        source: vaccination.recordType,
      }),
      [],
    )

  const getAppointmentTimelineCardData: GetTimelineDataFn<Appointment> =
    useCallback(
      (appointment) => ({
        itemType: TimelineTableItemType.APPOINTMENT,
        itemDate: {date: appointment.startTime, isEstimated: false},
        description: appointment.department || 'Appointment',
        facilityName: appointment.facility?.name,
        source: RecordType.MANUAL, // TODO: Check if this is correct
      }),
      [],
    )

  const getLifeMilestoneTimelineCardData: GetTimelineDataFn<LifeMilestone> =
    useCallback(
      (lifeMilestone) => ({
        itemType: TimelineTableItemType.LIFE_MILESTONE,
        itemDate: lifeMilestone.date,
        description: `${
          capitalizeFirstLetter(lifeMilestone.milestone) ||
          translateEnumValue(
            EnumType.LifeMilestoneType,
            lifeMilestone.type,
            t('Life Milestone'),
          )
        }: ${lifeMilestone.description}`,
        source: RecordType.MANUAL, // Life milestones have a source of either Manual or Obstetrics, which are added manually anyway.
      }),
      [t, translateEnumValue],
    )

  return {
    getAllergyTimelineCardData,
    getBirthTimelineCardData,
    getCholesterolTimelineCardData,
    getConditionTimelineCardData,
    getMedicalTestTimelineCardData,
    getMedicationTimelineCardData,
    getSurgeryTimelineCardData,
    getVaccinationTimelineCardData,
    getAppointmentTimelineCardData,
    getLifeMilestoneTimelineCardData,
  }
}

export const useModalData = () => {
  const {t} = useTranslation()
  const {translateEnumValue} = useEnumTranslations()

  const getFormattedAllergyEffects = useCallback(
    (effects?: AllergyEffect[] | null, description?: string | null) => {
      if (!effects) {
        return [description || '-']
      }

      const translatedEffects = effects
        .filter((effect) => effect !== AllergyEffect.OTHER)
        .map((effect) => translateEnumValue(EnumType.AllergyEffect, effect))

      return description
        ? [...translatedEffects, description]
        : translatedEffects
    },
    [translateEnumValue],
  )

  const getAllergyModalData = useCallback(
    (allergy: PatientAllergy): PatientTimelineModalData => {
      const {
        allergen,
        date,
        description,
        effects,
        id,
        sourceDocument,
        treatment,
      } = allergy

      return {
        id,
        fileEndpointId: id,
        header: t('Allergy'),
        date,
        hasAttachment: !!sourceDocument,
        rows: [
          {
            field: t('Allergy'),
            value: allergen.name,
          },
          {
            field: t('Allergy category'),
            value: allergen.category,
          },
          {
            field: t('Date detected'),
            value: formatExactOrEstimatedDate(date) || '-',
          },
          {
            field: t('Effects'),
            type: RowGroupType.MULTILINE,
            values: getFormattedAllergyEffects(effects, description),
          },
          {
            field: t('Treatment'),
            value: treatment ?? '-',
          },
        ],
        files: sourceDocument && [
          {
            endpointType: 'medicalRecord',
            fileId: id,
            fileName: sourceDocument.fileName || '',
          },
        ],
      }
    },
    [getFormattedAllergyEffects, t],
  )

  const getCholesterolModalData = useCallback(
    (cholesterol: PatientCholesterol): PatientTimelineModalData => {
      const {
        date,
        facility,
        hdl,
        hdlRatio,
        ldl,
        totalAmount,
        triglycerides,
        id,
        sourceDocument,
      } = cholesterol

      return {
        id,
        fileEndpointId: id,
        header: t('Cholesterol'),
        facility,
        hasAttachment: !!sourceDocument,
        date,
        rows: [
          {
            field: t('Date of test'),
            value: formatExactOrEstimatedDate(date) || '-',
          },
          {
            field: t('Total amount'),
            value: formatCholesterolValue(totalAmount),
          },
          {
            field: t('HDL'),
            value: formatCholesterolValue(hdl),
          },
          {
            field: t('HDL ratio'),
            value: formatLocaleNumber(hdlRatio, 2) || '-',
          },
          {
            field: t('LDL'),
            value: formatCholesterolValue(ldl),
          },
          {
            field: t('Triglycerides'),
            value: formatCholesterolValue(triglycerides),
          },
        ],
        files: sourceDocument && [
          {
            endpointType: 'medicalRecord',
            fileId: id,
            fileName: sourceDocument.fileName || '',
          },
        ],
      }
    },
    [t],
  )

  const getConditionModalData = useCallback(
    (diagnosedCondition: DiagnosedCondition): PatientTimelineModalData => {
      const {
        condition,
        customName,
        date,
        endDate,
        facility,
        id,
        sourceDocument,
      } = diagnosedCondition

      return {
        id,
        fileEndpointId: id,
        header: t('Medical condition'),
        facility,
        hasAttachment: !!sourceDocument,
        date,
        rows: [
          {
            field: t('Condition'),
            value: condition || customName || '-',
          },
          {
            field: t('Date of diagnosis'),
            value: formatExactOrEstimatedDate(date) || '-',
          },
          {
            field: t('Current status'),
            value: formatConditionStatus(t, endDate),
          },
          {
            field: t('Diagnosed or treated by a professional?'),
            value: facility ? t('Yes') : t('No'),
          },
        ],
        files: sourceDocument && [
          {
            endpointType: 'medicalRecord',
            fileId: id,
            fileName: sourceDocument.fileName || '',
          },
        ],
      }
    },
    [t],
  )

  const getLifeMilestoneModalData = useCallback(
    (lifeMilestone: LifeMilestone): PatientTimelineModalData => {
      const {date, description, id, impact, milestone, type} = lifeMilestone

      return {
        id,
        header: t('Life milestone'),
        fileEndpointId: id,
        date,
        rows: [
          {
            field: t('Milestone'),
            value:
              capitalizeFirstLetter(milestone) ||
              translateEnumValue(EnumType.LifeMilestoneType, type),
          },
          {
            field: t('Impact'),
            value:
              translateEnumValue(EnumType.LifeMilestoneImpact, impact) || '-',
          },
          {
            field: t('Description'),
            value: description || '-',
          },
        ],
      }
    },
    [t, translateEnumValue],
  )

  const getMedicalTestModalData = useCallback(
    (test: MedicalTest): PatientTimelineModalData => {
      const isParsed = test.recordType === RecordType.PARSED
      const hasAttachment = isParsed ? !!test.uploadId : !!test.sourceDocument
      const fileId = isParsed ? test.uploadId : test.id
      let fileName = ''
      if (hasAttachment) {
        if (isParsed) fileName = t('Uploaded')
        else if (test.sourceDocument) fileName = test.sourceDocument.fileName
      }

      return {
        id: test.id,
        header: t('Medical test'),
        facility: test.facility,
        fileEndpointId: test.id,
        hasAttachment,
        date: test.date,
        rows: isParsed
          ? [
              {
                field: t('Medical test'),
                value: test.header,
              },
              ...test.rowGroups,
            ]
          : [
              {
                field: t('Type'),
                value: test.type.value.label || '-',
              },
              {
                field: t('Result / description'),
                value: test.description || '-',
              },
            ],
        uploadId: isParsed ? test.uploadId : undefined,
        files: hasAttachment
          ? [
              {
                endpointType: isParsed ? 'sourceFile' : 'medicalRecord',
                fileId,
                fileName,
              },
            ]
          : undefined,
      }
    },
    [t],
  )

  const getMedicationModalData = useCallback(
    (medication: Medication): PatientTimelineModalData => {
      const isParsed = medication.recordType === RecordType.PARSED
      const hasAttachment = isParsed
        ? !!medication.uploadId
        : !!medication.sourceDocument

      return {
        id: medication.id,
        fileEndpointId: medication.id,
        header: t('Medication'),
        hasAttachment,
        facility: isParsed ? medication.facility : null,
        date: isParsed ? medication.date : medication.startDate,
        uploadId: isParsed ? medication.uploadId : undefined,
        rows: isParsed
          ? [
              {
                field: t('Drugs'),
                type: RowGroupType.MULTILINE,
                values: medication.drugs,
              },
              {
                field: t('Dosage'),
                value: medication.dosage || '-',
              },
            ]
          : [
              {
                field: t('Medication'),
                value: medication.drug?.tradeName || medication.name || '-',
              },
              {
                field: t('Dosage'),
                value: medication.dosage || '-',
              },
              {
                field: t('Frequency'),
                value: medication.frequency || '-',
              },
              {
                field: t('Started taking'),
                value: formatExactOrEstimatedDate(medication.startDate) || '-',
              },
              {
                field: t('End date'),
                value:
                  formatExactOrEstimatedDate(medication.endDate) ||
                  t('Currently taking'),
              },
            ],
      }
    },
    [t],
  )

  const getSurgeryModalData = useCallback(
    (surgery: Surgery): PatientTimelineModalData => {
      const isParsed = surgery.recordType === RecordType.PARSED

      return {
        id: surgery.id,
        fileEndpointId: surgery.id,
        header: t('Procedure'),
        facility: surgery.facility,
        date: surgery.date,
        hasAttachment: isParsed ? !!surgery.uploadId : !!surgery.sourceDocument,
        uploadId: isParsed ? surgery.uploadId : undefined,
        rows: [
          {
            field: t('Type'),
            value: isParsed ? '-' : surgery.type?.value.label || '-',
          },
          {
            field: t('Description'),
            value: surgery.description,
          },
        ],
      }
    },
    [t],
  )

  const getVaccinationModalData = useCallback(
    (vaccination: PatientVaccination): PatientTimelineModalData => {
      const isParsed = vaccination.recordType === RecordType.PARSED

      return {
        id: vaccination.id,
        fileEndpointId: vaccination.id,
        header: t('Vaccination'),
        facility: vaccination.facility,
        date: vaccination.date,
        hasAttachment: isParsed
          ? !!vaccination.uploadId
          : !!vaccination.sourceDocument,
        uploadId: isParsed ? vaccination.uploadId : undefined,
        rows: [
          {
            field: t('Name'),
            value: isParsed ? vaccination.shotName : vaccination.vaccine.name,
          },
          {
            field: t('Shot description'),
            value: (isParsed && vaccination.shotDescription) || '-',
          },
          {
            field: t('Manufacturer'),
            value: (isParsed && vaccination.manufacturer) || '-',
          },
          {
            field: t('Batch'),
            value: (isParsed && vaccination.batch) || '-',
          },
          {
            field: t('Expiry date'),
            value:
              (isParsed && formatExactOrEstimatedDate(vaccination.expiry)) ||
              '-',
          },
        ],
      }
    },
    [t],
  )

  const getAppointmentSummaryModalData = useCallback(
    (
      summary: AppointmentSummary,
      appointment?: Appointment,
    ): PatientTimelineModalData => ({
      id: summary.appointmentId,
      fileEndpointId: appointment?.id,
      header: t('Appointment summary'),
      facility: appointment?.facility,
      date: createExactOrEstimatedDate(summary.createdAt),
      hasAttachment: !!summary.file,
      rows: [
        {
          field: t('Key patient messages'),
          value: summary.summary || '-',
        },
        {
          type: RowGroupType.LINE,
          line: t('Prescribed medications'),
        },
        ...summary.prescribedMedications.map(
          ({dosage, prescriptionEndDate, frequency, productName}) => ({
            type: RowGroupType.MULTILINE,
            field: productName,
            values: [
              dosage,
              frequency,
              prescriptionEndDate
                ? t('Until {{date}}', {
                    date: formatDate(DateTime.DATE_MED, prescriptionEndDate),
                  })
                : null,
            ].filter(isTruthy),
          }),
        ),
      ],
    }),
    [t],
  )

  const getAppointmentModalData = useCallback(
    (appointment: Appointment): PatientTimelineModalData => {
      const {summary, consultations, department} = appointment

      const departmentField = department
        ? [
            {
              field: t('Department'),
              value: department,
              type: RowGroupType.FIELD,
            },
          ]
        : []

      const summaryNote = summary
        ? [
            {
              field: t('Practitioner Summary'),
              type: RowGroupType.LINE,
              subLine: summary.summary,
              expandable: true,
            },
          ]
        : []

      const prescribedMedications = summary?.prescribedMedications.length
        ? [
            {
              field: t('Prescribed Medications'),
              type: RowGroupType.MULTILINE,
              values: summary.prescribedMedications.map(
                ({dosage, prescriptionEndDate, frequency, productName}) =>
                  `${productName}: ${dosage} ${frequency} ${
                    prescriptionEndDate
                      ? t('Until {{date}}', {
                          date: formatDate(
                            DateTime.DATE_MED,
                            prescriptionEndDate,
                          ),
                        })
                      : ''
                  }`,
              ),
            },
          ]
        : []

      const consultationNotes = consultations.length
        ? [
            {
              field: t('Consultation Notes'),
              type: RowGroupType.MULTILINE,
              expandable: true,
              values: consultations.map((value) => value.note).filter(isTruthy),
            },
          ]
        : []

      const consultationData = consultations.map((c) => c.data || []).flat()

      const appointmentFiles: AttachedFile[] = appointment.files
        ? appointment.files.map((f) => ({
            endpointType: 'appointment',
            fileId: f.id,
            fileName: f.name,
          }))
        : []

      const consultationFiles: AttachedFile[] = consultations.length
        ? consultations
            .filter((c) => c.filePath)
            .map((c) => ({
              endpointType: 'consultation',
              fileId: c.id,
              fileName: c.title, //
            }))
        : []

      return {
        id: appointment.id,
        fileEndpointId: appointment?.id,
        header: t('Appointment summary'),
        facility: appointment?.facility,
        date: createExactOrEstimatedDate(appointment.startTime),
        hasAttachment: !!appointment?.files?.length,
        rows: [
          ...departmentField,
          ...summaryNote,
          ...prescribedMedications,
          ...consultationNotes,
          ...consultationData,
        ],
        files:
          consultationFiles.length + appointmentFiles.length
            ? [...appointmentFiles, ...consultationFiles]
            : undefined,
      }
    },
    [t],
  )

  const getAppointmentTriageModalData = useCallback(
    (
      triage: AssignedQuestionnaire,
      appointment?: Appointment,
    ): PatientTimelineModalData => {
      const {questions} = triage.questionnaire
      const parsedAnswers = parseQuestionnaireAnswers(
        questions,
        triage.answers ?? [],
      )

      const formattedAnswers = formatQuestionnaireAnswersForDisplay(
        parsedAnswers,
        questions,
      )

      return {
        id: triage.id,
        header: t('Appointment triage'),
        facility: appointment?.facility,
        date: createExactOrEstimatedDate(triage.dateCompleted),
        rows: [
          {
            type: RowGroupType.LINE,
            line: t('Answers'),
          },
          ...formattedAnswers.map(({title, value}) => ({
            field: title,
            value: value ?? '-',
          })),
        ],
      }
    },
    [t],
  )

  return {
    getAllergyModalData,
    getCholesterolModalData,
    getAppointmentSummaryModalData,
    getAppointmentModalData,
    getAppointmentTriageModalData,
    getConditionModalData,
    getLifeMilestoneModalData,
    getMedicalTestModalData,
    getMedicationModalData,
    getSurgeryModalData,
    getVaccinationModalData,
  }
}
