import {appointmentNormaliser} from './appointments'
import {sharedMaterialNormaliser} from './educationalMaterials'
import {AssertTypeFn} from './general'
import {assertArray, assertBoolean, assertNumber} from '../utils'
import {Dashboard} from '../models'
import {notificationsNormaliser} from './notifications'

export const dashboardNormaliser: AssertTypeFn<Dashboard> = (obj) => ({
  appointmentsToReviewCount: assertNumber(obj.appointmentsToReviewCount),
  futureAppointments: assertArray(
    obj.futureAppointments,
    appointmentNormaliser,
  ),
  hasHealthJourney: assertBoolean(obj.hasHealthJourney),
  notifications: notificationsNormaliser(obj.notifications),
  unreadMaterials: assertArray(obj.unreadMaterials, sharedMaterialNormaliser),
})
