import React from 'react'

import {StyledTextArea, TextAreaWrapper} from './TextArea.styled'

import {theme} from '~/styles/theme'

type TextAreaProps = {
  className?: string
  height?: string
  fontSize?: keyof typeof theme.fontSize
} & React.TextareaHTMLAttributes<HTMLTextAreaElement>

export const TextArea: React.FC<TextAreaProps> = ({
  className,
  fontSize = 'lg',
  height = '300px',
  ...props
}) => (
  <TextAreaWrapper height={height} className={className}>
    <StyledTextArea fontSize={fontSize} {...props} />
  </TextAreaWrapper>
)
