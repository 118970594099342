import React, {useContext, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {MISSING_ID, formatDate} from '@common/utils'
import {PermissionCategory} from '@common/models'
import {DateTime} from 'luxon'
import {useNavigate} from 'react-router'

import {PatientsContext} from '../PatientsContext'
import {Loading} from '../../general/loading/Loading'
import {RequestData} from '../common/requestData/RequestData'

import {useGetPatientAppointments} from '~/api'
import {SimplePaginatedTable} from '~/components/general/table/SimplePaginatedTable'
import {Container} from '~/components/general/container/Container'
import {formatName} from '~/utils'

const APPOINTMENTS_PER_TABLE_PAGE = 10

export const AppointmentsPage: React.FC = () => {
  const {t} = useTranslation()
  const {patient} = useContext(PatientsContext)
  const navigate = useNavigate()

  const hasAppointmentPermission = patient?.permissions.includes(
    PermissionCategory.APPOINTMENT,
  )

  const [currentPageIndex, setCurrentPageIndex] = useState(0)

  const {data, isInitialLoading, isFetching} = useGetPatientAppointments({
    patientId: patient?.patientId ?? MISSING_ID,
    page: currentPageIndex,
    perPage: APPOINTMENTS_PER_TABLE_PAGE,
  })

  const headerData = useMemo(
    () => ({
      columns: [
        {title: t('Date'), columnWidthPercent: 30},
        {title: t('Facility'), columnWidthPercent: 40},
        {title: t('Specialization'), columnWidthPercent: 30},
      ],
    }),
    [t],
  )

  const rowsData = useMemo(
    () =>
      data?.records.map((appointment) => ({
        hasParsedData: false, // TODO CUSH-247
        columns: [
          formatDate(DateTime.DATETIME_MED, appointment.startTime, {
            zone: appointment.facility?.timeZone,
          }),
          appointment.facility?.name || '-',
          appointment.facility?.specializations[0] || '-',
        ],
        onRowClick: () => {
          navigate(`./${appointment.id}`)
        },
      })) ?? [],
    [data, navigate],
  )

  if (isInitialLoading) {
    return <Loading />
  }

  return (
    <Container direction="column" flex spacing={4}>
      {!hasAppointmentPermission && (
        <RequestData
          patient={patient}
          permission={PermissionCategory.APPOINTMENT}
          text={t(
            "Only showing appointments at your facility, as {{patientName}} hasn't shared their appointment data with you.",
            {patientName: formatName(patient, t('patient'))},
          )}
        />
      )}
      <SimplePaginatedTable
        header={headerData}
        rows={rowsData}
        isLoading={isFetching}
        paginationData={data?.page}
        onPageClick={setCurrentPageIndex}
        showSourceIcons
      />
    </Container>
  )
}
