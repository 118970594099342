import React, {useContext} from 'react'
import {useTranslation} from 'react-i18next'

import {AuthContext} from '~/auth/AuthContext'
import {Button} from '~/components/general/button/Button'
import {AuthLayout} from '~/components/layout/authLayout/AuthLayout'

export const LoginPage: React.FC = () => {
  const {t} = useTranslation()
  const {signIn} = useContext(AuthContext)

  return (
    <AuthLayout>
      <Button onClick={signIn}>{t('Sign in')}</Button>
    </AuthLayout>
  )
}
