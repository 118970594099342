import React, {useContext, useMemo} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {MaterialsItem, MaterialsItemType} from '@common/models'
import {useTranslation} from 'react-i18next'

import {MaterialsContext} from '../MaterialsContext'
import {SelectedMaterials} from '../sideBar/SelectedMaterials'

import {Materials} from '~/components/educationalMaterials/materials/Materials'
import {Material} from '~/components/educationalMaterials/materials/Material'
import {Banner} from '~/components/general/banner/Banner'
import {Layout} from '~/components/layout/Layout'
import {MaterialsSelect} from '~/components/educationalMaterials/materials/MaterialsSelect'
import {Option} from '~/components/general/select/Select'
import {Typography} from '~/components/general/typography/Typography'

export const MaterialsConditionPage: React.FC = () => {
  const {t} = useTranslation()
  const {conditionId: paramsConditionId} = useParams()
  const {contents, addItem} = useContext(MaterialsContext)
  const navigate = useNavigate()

  const conditionId = useMemo(
    () => paramsConditionId ?? contents?.[0]?.structure.id,
    [paramsConditionId, contents],
  )

  const contentToDisplay = useMemo(
    () =>
      contents?.find((condition) => condition.structure.id === conditionId)
        ?.structure,
    [contents, conditionId],
  )

  const handleMaterialClick = (item: MaterialsItem) => {
    if (item.type === MaterialsItemType.FOLDER) {
      navigate(`/app/materials/${conditionId}/${item.id}`)
    } else {
      navigate(`/app/materials/file/${item.id}`)
    }
  }

  return (
    <Layout rightSide={<SelectedMaterials />}>
      <Banner
        title={t('Help your patients navigate their healthcare journey')}
        closable
        content={t(
          'Choose a relevant condition, browse our curated materials and assemble personalized information packs for your patients.',
        )}
      />
      <MaterialsSelect selectedOption={contentToDisplay?.condition || ''}>
        {contents?.map((condition) => (
          <Option
            key={condition.name}
            onClick={() =>
              navigate(`/app/materials/${condition.structure.id}`)
            }>
            <Typography weight="medium">
              {condition.structure.condition}
            </Typography>
          </Option>
        ))}
      </MaterialsSelect>
      {contentToDisplay?.categories?.map((category) => (
        <Materials title={category.name} key={category.name}>
          {category.subCategories?.map((subcategory) => (
            <Material
              key={subcategory.id}
              item={subcategory}
              onClick={() => handleMaterialClick(subcategory)}
              onIconClick={() => {
                addItem(subcategory)
              }}
            />
          ))}
        </Materials>
      ))}
    </Layout>
  )
}
