export enum HealthDataGrade {
  GOOD = 'GOOD',
  AVERAGE = 'AVERAGE',
  BAD = 'BAD',
}

export enum TrendValue {
  UP = 'UP',
  DOWN = 'DOWN',
}

export type HealthDataGrading = {
  grade: HealthDataGrade | undefined
  trend: TrendValue | undefined
  trendGrade: HealthDataGrade | undefined
  value?: number | string
}

export type HealthDataTypeProperties = {
  fractionDigits: number
  isSummable: boolean
  title: string
  unit: string | undefined
}

export type LatestHealthDataDetails = HealthDataGrading &
  HealthDataTypeProperties & {date: string | undefined}

export type ChartDataPoint = {
  date: Date
  y: number
  grade?: HealthDataGrade | null
}

export type BarChartDataPoint = ChartDataPoint & {
  y0?: number
}

export type ChartData = ChartDataPoint[]
export type BarChartData = BarChartDataPoint[]

export type {
  AggregatedActivityDto as AggregatedActivity,
  LatestActivityDto as LatestActivity,
  LatestActivityCaloriesDto as LatestCalories,
  LatestActivityAlcoholScoreDto as LatestAlcoholScore,
  LatestActivityBloodPressureDto as LatestBloodPressure,
  LatestActivityBmiDto as LatestBmi,
  LatestActivityBodyFatPercentageDto as LatestFatPercentage,
  LatestActivityCholesterolRatioDto as LatestCholesterolRatio,
  LatestActivityDietScoreDto as LatestDietScore,
  LatestActivityHeartAgeDto as LatestHeartAge,
  LatestActivityHeightDto as LatestHeight,
  LatestActivityMoodScoreDto as LatestMoodScore,
  LatestActivityMovementDto as LatestMovement,
  LatestActivitySleepSecondsDto as LatestSleepSeconds,
  LatestActivityHeartRateDto as LatestRestingHeartRate,
  LatestActivitySleepScoreDto as LatestSleepScore,
  LatestActivityStepsDto as LatestSteps,
  LatestActivityTobaccoScoreDto as LatestTobaccoScore,
  LatestActivityActiveMinutesDto as LatestActiveMinutes,
  LatestActivityWaistCircumferenceDto as LatestWaistCircumference,
  LatestActivityWaterConsumptionDto as LatestWaterConsumption,
  LatestActivityWeightDto as LatestWeight,
  ManuallyAddActivityDto as AddActivityDataRequest,
  ManuallyAddBodyFatPercentageDto as AddBodyFatPercentageRequest,
  ManuallyAddBloodPressureDto as AddBloodPressureRequest,
  ManuallyAddHeartRateBpmDto as AddRestingHeartRateRequest,
  ManuallyAddWaterConsumptionMlDto as AddWaterConsumptionRequest,
} from '@generated/models'

export {
  ActivityAggregationGranularityDto as HealthDataResolution,
  ActivityFieldDto as HealthDataType,
  ActivityMovementTypeDto as ActivityMovementType,
} from '@generated/models'
