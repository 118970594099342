import styled from 'styled-components'

import {NotificationType} from './NotificationContext'

type StyledNotificationBannerProps = {
  isVisible: boolean
  notificationType: NotificationType
}

export const StyledNotificationBannersParent = styled.div`
  position: fixed;
  z-index: 800;
  width: 300px;
  display: flex;
  flex-direction: column-reverse;
  bottom: 0;
  right: ${({theme}) => theme.spacing.xl};
  height: 100vh;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  pointer-events: none;

  &::-webkit-scrollbar {
    display: none;
  }
`

export const StyledNotificationBannersContainer = styled.div`
  pointer-events: auto;
  padding: ${({theme}) => theme.spacing.md} 0;
`

export const StyledNotificationBanner = styled.div<StyledNotificationBannerProps>`
  display: flex;
  flex-direction: column;
  transition: opacity 0.5s ease-in-out 0s;
  opacity: ${({isVisible}) => (isVisible ? '1' : '0')};
  padding: ${({theme}) => theme.spacing.sm};
  background-color: ${({theme, notificationType}) => {
    switch (notificationType) {
      case NotificationType.Error:
        return theme.colors.indicator.body.red
      case NotificationType.Success:
        return theme.colors.indicator.body.green
      default:
        return theme.colors.indicator.body.blue
    }
  }};
  width: 100%;
  border-radius: ${({theme}) => `${theme.borderRadius.md}`};

  &:not(:last-child) {
    margin-bottom: ${({theme}) => theme.spacing.xs};
  }
`
