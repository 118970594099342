import {Answer, Question, QuestionType} from '@common/models'
import {formatDate, formatLocaleNumber} from '@common/utils'
import {DateTime, DateTimeFormatOptions} from 'luxon'
import React from 'react'

import {MultiChoiceQuestion} from './MultiChoiceQuestion'
import {SelectQuestion} from './SelectQuestion'
import {SliderQuestion} from './SliderQuestion'
import {TextQuestion} from './TextQuestion'

export type QuestionProps = {
  question: Question
  answer?: Answer
}

const formatDateTimeAnswer = (format: DateTimeFormatOptions, answer?: Answer) =>
  typeof answer === 'string' ? formatDate(format, answer) : ''

const formatWeightAnswer = (answer?: Answer) =>
  typeof answer === 'number' ? `${formatLocaleNumber(answer, 1)} kg` : ''

const formatHeightAnswer = (answer?: Answer) =>
  typeof answer === 'number' ? `${formatLocaleNumber(answer, 1)} cm` : ''

export const QuestionCard: React.FC<QuestionProps> = ({answer, question}) => {
  switch (question.type) {
    case QuestionType.CHOICE:
      return <SelectQuestion question={question} answer={answer} />
    case QuestionType.MULTI_CHOICE:
      return <MultiChoiceQuestion question={question} answer={answer} />
    case QuestionType.TEXT:
      return <TextQuestion question={question} answer={answer} />
    case QuestionType.NUMBER:
      return <TextQuestion question={question} answer={answer} />
    case QuestionType.TIME:
      return (
        <TextQuestion
          question={question}
          answer={formatDateTimeAnswer(DateTime.TIME_SIMPLE, answer)}
        />
      )
    case QuestionType.DATE:
      return (
        <TextQuestion
          question={question}
          answer={formatDateTimeAnswer(DateTime.DATE_FULL, answer)}
        />
      )
    case QuestionType.DATE_TIME:
      return (
        <TextQuestion
          question={question}
          answer={formatDateTimeAnswer(DateTime.DATETIME_SHORT, answer)}
        />
      )
    case QuestionType.HEIGHT:
      return (
        <TextQuestion question={question} answer={formatHeightAnswer(answer)} />
      )
    case QuestionType.WEIGHT:
      return (
        <TextQuestion question={question} answer={formatWeightAnswer(answer)} />
      )
    case QuestionType.SLIDER:
      return <SliderQuestion question={question} answer={answer} />
    default:
      return null
  }
}
