import styled from 'styled-components'

export const ModalBody = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  inset: 0px;
  z-index: 800;
  outline: none;
  overflow: hidden;
  background-color: ${({theme}) => theme.colors.modalOverlay};
`

export const StyledModal = styled.div`
  display: flex;
  flex-direction: column;
  transition: top 0.3s ease-in-out 0s;
  margin: 0px 0px 0px auto;
  background-color: ${({theme}) => theme.colors.white};
  max-width: 572px;
  height: 100vh;
  border-radius: ${({theme}) =>
    `${theme.borderRadius.md} 0 0 ${theme.borderRadius.md}`};
`

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${({theme}) =>
    `${theme.spacing.xxl} ${theme.spacing.xl} ${theme.spacing.xl} ${theme.spacing.xxl}`};
  border-bottom: 1px solid ${({theme}) => theme.colors.grey.grey40};
`

export const ModalContent = styled.div`
  padding: ${({theme}) => theme.spacing.xxl};
  overflow-x: hidden;
  overflow-y: auto;
`
