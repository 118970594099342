import styled from 'styled-components'

import {Typography} from '../typography/Typography'

type Input = {
  size: 'large' | 'normal' | 'small'
  startIcon?: boolean
  endIcon?: boolean
  noBorder?: boolean
}

type Icon = {
  type: 'startIcon' | 'endIcon'
  size: 'large' | 'normal' | 'small'
}

export const InputIcon = styled.div<Icon>`
  position: absolute;
  display: flex;
  top: 50%;
  transform: translateY(-50%);
  ${({type, size, theme}) =>
    type === 'startIcon' &&
    `left: ${
      {
        large: '20px',
        normal: theme.spacing.md,
        small: theme.spacing.sm,
      }[size]
    }`};
  ${({type, size, theme}) =>
    type === 'endIcon' &&
    `right: ${size === 'large' ? '20px' : theme.spacing.md}`};
`

export const StyledInput = styled.input`
  background: none;
  display: block;
  width: 100%;
  font-size: ${({theme}) => theme && theme.fontSize.lg};
  line-height: ${({theme}) => theme && theme.lineHeight.lg};
  color: ${({theme}) => theme.colors.inputText};
  border: 0;
  outline: 0;

  &::placeholder {
    color: ${({theme}) => theme.colors.inputPlaceholder};
  }
`

export const InputWrapper = styled.div<Input>`
  position: relative;
  width: 100%;
  height: ${({theme, size}) =>
    ({
      large: theme.height.input.large,
      normal: theme.height.input.normal,
      small: theme.height.input.small,
    }[size])};
  display: inline-flex;
  align-items: center;
  ${({noBorder, theme}) =>
    !noBorder && `border: 1px solid ${theme.colors.inputBorder}`};
  border-radius: ${({theme}) => theme.borderRadius.xs};

  ${StyledInput} {
    padding: ${({size}) => (size === 'large' ? '19px' : '15px')};
    ${({startIcon, size}) =>
      startIcon && `padding-left: ${size === 'large' ? '56px' : '52px'}`};
    ${({endIcon, size}) =>
      endIcon && `padding-right: ${size === 'large' ? '56px' : '52px'}`};
  }
`

export const InputContainer = styled.div`
  width: 100%;
`

export const ErrorText = styled(Typography)`
  margin: ${({theme}) => `${theme.spacing.xs} 0 0 ${theme.spacing.sm} `};
  color: ${({theme}) => theme.colors.red};
`
