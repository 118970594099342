import React, {useState, useContext} from 'react'
import {useTranslation} from 'react-i18next'

import {Container} from '../general/container/Container'
import {Typography} from '../general/typography/Typography'
import {Input} from '../general/input/Input'
import {Button} from '../general/button/Button'
import {SideModal} from '../general/modal/SideModal'
import {useRescheduleAppointmentForFacility} from '../../api/practitioner'
import {
  NotificationContext,
  NotificationType,
} from '../notifications/NotificationContext'

interface RescheduleAppointmentModalProps {
  isVisible: boolean
  onClose: () => void
  appointmentId: string
  facilityId: string
  originalDate: string
  patientName: string
  patientDob: string | null
}

const formatDateToLocalInput = (date: Date) => {
  const pad = (num: number): string => num.toString().padStart(2, '0')
  const year = date.getFullYear()
  const month = pad(date.getMonth() + 1)
  const day = pad(date.getDate())
  const hours = pad(date.getHours())
  const minutes = pad(date.getMinutes())

  return `${year}-${month}-${day}T${hours}:${minutes}`
}

export const RescheduleAppointmentModal: React.FC<
  RescheduleAppointmentModalProps
> = ({
  isVisible,
  onClose,
  appointmentId,
  facilityId,
  originalDate,
  patientName,
  patientDob,
}) => {
  const {t} = useTranslation()
  const {addNotification} = useContext(NotificationContext)
  const [selectedDate, setSelectedDate] = useState<string>(() => {
    const timeNow = new Date()
    const lastTenMinRounded = new Date(
      timeNow.setMinutes(Math.floor(timeNow.getMinutes() / 10) * 10),
    )
    return formatDateToLocalInput(lastTenMinRounded)
  })

  const {mutateAsync: rescheduleAppointment, isLoading} =
    useRescheduleAppointmentForFacility({
      facilityId,
      appointmentId,
    })

  const handleConfirm = async () => {
    try {
      const [datePart, timePart] = selectedDate.split('T')
      const [year, month, day] = datePart.split('-').map(Number)
      const [hours, minutes] = timePart.split(':').map(Number)
      const newDate = new Date(year, month - 1, day, hours, minutes)

      if (
        window.confirm(
          `${t('Are you sure you want to reschedule this appointment?')}\n\n${t(
            'From: {{from}}\nTo: {{to}}',
            {
              from: new Date(originalDate).toLocaleString(),
              to: newDate.toLocaleString(),
            },
          )}`,
        )
      ) {
        await rescheduleAppointment({
          newAppointmentDate: newDate.toISOString(),
        })

        addNotification(
          `${t('Appointment successfully rescheduled')}\n${t(
            'From: {{from}}\nTo: {{to}}',
            {
              from: new Date(originalDate).toLocaleString(),
              to: newDate.toLocaleString(),
            },
          )}`,
          NotificationType.Success,
        )

        onClose()
      }
    } catch (error) {
      addNotification(
        `${t('Error rescheduling appointment')}\n${
          error instanceof Error ? error.message : t('Unknown error occurred')
        }`,
        NotificationType.Error,
      )
      console.error('Error rescheduling appointment:', error)
    }
  }

  return (
    <SideModal
      isVisible={isVisible}
      onClose={onClose}
      header={
        <div>
          <Typography size="lg" weight="medium">
            {t('Reschedule Appointment')}
          </Typography>
        </div>
      }>
      <Container flex direction="column" spacing={2}>
        <Typography weight="medium" size="lg">
          {t('Patient Information')}
        </Typography>
        <Container flex direction="column" spacing={1}>
          <Typography weight="medium" size="md">
            {t('Name')}
          </Typography>
          <Typography color="secondary" size="sm">
            {patientName}
          </Typography>

          {patientDob && (
            <>
              <Typography weight="medium" size="md">
                {t('Date of Birth')}
              </Typography>
              <Typography color="secondary" size="sm">
                {new Date(patientDob).toLocaleDateString()}
              </Typography>
            </>
          )}
        </Container>

        <div style={{height: '16px'}} />

        <Container flex direction="column" spacing={1}>
          <Typography weight="medium" size="lg">
            {t('Appointment Details')}
          </Typography>
          <Container flex direction="column" spacing={1}>
            <Typography weight="medium" size="md">
              {t('Current appointment date')}
            </Typography>
            <Typography color="secondary" size="sm">
              {new Date(originalDate).toLocaleString()}
            </Typography>

            <Typography weight="medium" size="md">
              {t('New date and time')}
            </Typography>
            <Input
              type="datetime-local"
              value={selectedDate}
              onChange={(e) => {
                setSelectedDate(e.target.value)
              }}
            />
          </Container>

          <Container flex justify="flex-end" spacing={2}>
            <Button onClick={handleConfirm} disabled={isLoading}>
              {t('Confirm')}
            </Button>
          </Container>
        </Container>
      </Container>
    </SideModal>
  )
}
