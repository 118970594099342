import {
  ExactOrEstimatedDate,
  Facility,
  Practitioner,
  RowGroup,
} from '@common/models'
import {useTranslation} from 'react-i18next'
import React, {useContext} from 'react'
import {
  capitalizeFirstLetter,
  EnumType,
  formatExactOrEstimatedDate,
  useEnumTranslations,
} from '@common/utils'

import {
  ContentColumn,
  RowHeader,
  StyledChip,
  StyledHeaderText,
} from './PatientTimelineModal.styled'
import RowGroupValue from './RowGroupValue'
import {AttachedFile, AttachmentsContainer} from './AttachmentsContainer'

import {SideModal} from '~/components/general/modal/SideModal'
import {Typography} from '~/components/general/typography/Typography'
import {Chip} from '~/components/general/chip/Chip'
import {Container} from '~/components/general/container/Container'
import {PatientsContext} from '~/components/patients/PatientsContext'
import {formatName} from '~/utils'
import {theme} from '~/styles/theme'

export type ExtendedRowGroup = RowGroup & {
  expandable?: boolean
}

type PatientTimelineModalContentProps = {
  rows: Partial<RowGroup>[]
}

export type PatientTimelineModalData = {
  id: string
  date?: ExactOrEstimatedDate | null
  facility?: Facility | null
  hasAttachment?: boolean
  header?: string | null
  fileEndpointId?: string | null
  practitioner?: Practitioner
  rows: Partial<ExtendedRowGroup>[]
  uploadId?: string | null
  files?: AttachedFile[]
}

type PatientTimelineModalProps = {
  data?: PatientTimelineModalData
  isVisible: boolean
  onClose: () => void
}

const PatientTimelineContent: React.FC<PatientTimelineModalContentProps> = ({
  rows,
}) => (
  <div>
    {rows.map((row, index) => (
      <React.Fragment key={index}>
        <RowHeader weight="medium" color={theme.colors.primary.main}>
          {capitalizeFirstLetter(row.field ?? row.line)}
        </RowHeader>
        <RowGroupValue row={row} />
      </React.Fragment>
    ))}
  </div>
)

export const PatientTimelineModal: React.FC<PatientTimelineModalProps> = ({
  data,
  isVisible,
  onClose,
}) => {
  const {t} = useTranslation()
  const {patient} = useContext(PatientsContext)
  const {translateEnumValue} = useEnumTranslations()

  if (!data) {
    return null
  }

  const {date, facility, practitioner, rows, uploadId, files} = data

  const headerText = capitalizeFirstLetter(data.header) ?? t('Health record')

  const header = (
    <div>
      <StyledHeaderText size="lg" weight="medium">
        {headerText}
      </StyledHeaderText>
      <Container spacing={1}>
        {!!date && <Chip text={formatExactOrEstimatedDate(date)} />}
        {uploadId ? (
          <Chip text={t('Parsed')} color="green" />
        ) : (
          <Chip text={t('Manually added')} color="yellow" />
        )}
      </Container>
    </div>
  )

  return (
    <SideModal isVisible={isVisible} onClose={onClose} header={header}>
      <Container flex>
        <ContentColumn>
          <Typography color={theme.colors.grey.main}>
            {t("Patient's name")}
          </Typography>
          <StyledChip text={formatName(patient)} />
        </ContentColumn>
        {!!practitioner && (
          <ContentColumn>
            <Typography color={theme.colors.grey.main}>
              {t("Practitioner's name")}
            </Typography>
            <StyledChip text={formatName(practitioner)} />
          </ContentColumn>
        )}
      </Container>
      {!!facility && (
        <Container flex>
          <ContentColumn>
            <Typography color={theme.colors.grey.main}>
              {t('Facility')}
            </Typography>
            <StyledChip text={facility?.name} />
          </ContentColumn>
          <ContentColumn>
            <Typography color={theme.colors.grey.main}>
              {t('Specialty')}
            </Typography>
            <StyledChip
              text={translateEnumValue(
                EnumType.Specialization,
                facility?.specializations[0],
              )}
            />
          </ContentColumn>
        </Container>
      )}
      <PatientTimelineContent rows={rows} />
      {files && <AttachmentsContainer parentObjectId={data.id} files={files} />}
    </SideModal>
  )
}
