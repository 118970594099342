import {
  AgeRange,
  Allergen,
  AllergyEffect,
  BasicHealthInfoResponse,
  Birth,
  ParsedMedicalTestType,
  BloodType,
  BloodTypeDetails,
  CriticalNote,
  DeliveryType,
  DiagnosedCondition,
  Drug,
  DrugCode,
  FamilyHistory,
  FamilyHistoryRecord,
  FamilyMember,
  FamilyMemberRelation,
  ManualMedicalTest,
  ManualMedication,
  ManualSurgery,
  ManualVaccination,
  MedicalCondition,
  MedicalSummary,
  MedicalTest,
  Medication,
  Obstetrics,
  ObstetricsSource,
  PainRelief,
  ParsedMedicalTest,
  ParsedMedication,
  ParsedSurgery,
  ParsedVaccination,
  PatientAllergy,
  PatientCholesterol,
  PatientVaccination,
  PerinealTrauma,
  PregnancyTerminationReason,
  RecordType,
  Sex,
  SourceDocument,
  Surgery,
  TerminatedPregnancy,
  Vaccine,
  DataOrigin,
} from '../models'
import {
  assertArray,
  assertArrayOrNull,
  assertBoolean,
  assertBooleanOrNull,
  assertEnum,
  assertEnumOrNull,
  assertNumber,
  assertNumberOrNull,
  assertObjectOrNull,
  assertObjectOrUndefined,
  assertString,
  assertStringOrNull,
  assertValue,
  assertValues,
  getAssertEnum,
} from '../utils'
import {facilityNormaliser} from './facilities'
import {AssertNullableTypeFn, AssertTypeFn, dateNormaliser} from './general'
import {lifestyleInformationNormaliser} from './lifestyle'
import {
  familyHistoryTypeValueNormaliser,
  generalItemTypeNormaliser,
  getItemTypeNormaliser,
  medicalTestTypeValueNormaliser,
} from './medicalLists'
import {personalInformationNormaliser} from './personal'
import {obstetricsFileNormaliser, rowGroupNormaliser} from './files'

const handleBigDecimals = (value?: string | number) => {
  if (typeof value === 'string') {
    return parseFloat(value)
  }
  return value
}

export const sourceDocumentNormaliser: AssertTypeFn<SourceDocument> = (
  obj,
) => ({
  fileName: assertString(obj.fileName),
  filePath: assertString(obj.filePath),
  fileType: assertString(obj.fileType),
})

export const allergenNormaliser: AssertTypeFn<Allergen> = (obj) => ({
  category: assertString(obj.category),
  id: assertString(obj.id),
  name: assertString(obj.name),
})

export const medicalConditionNormaliser: AssertTypeFn<MedicalCondition> = (
  obj,
) => ({
  name: assertString(obj.name),
})

export const patientAllergyNormaliser: AssertTypeFn<PatientAllergy> = (
  obj,
) => ({
  allergen: allergenNormaliser(obj.allergen),
  date: assertObjectOrUndefined(obj.date, dateNormaliser),
  description: assertStringOrNull(obj.description),
  effects: assertArrayOrNull(obj.effects, getAssertEnum(AllergyEffect)),
  id: assertString(obj.id),
  recordType: assertEnum(obj.recordType, RecordType),
  sourceDocument: assertObjectOrUndefined(
    obj.sourceDocument,
    sourceDocumentNormaliser,
  ),
  treatment: assertStringOrNull(obj.treatment),
})

export const medicalSummaryNormaliser: AssertTypeFn<MedicalSummary> = (
  obj,
) => ({
  allergies: assertNumberOrNull(obj.allergies),
  bloodType: assertEnumOrNull(obj.bloodType, BloodType),
  cholesterols: assertNumberOrNull(obj.cholesterols),
  diagnosedConditions: assertNumber(obj.diagnosedConditions),
  familyHistory: assertNumberOrNull(obj.familyHistory),
  medicalTests: assertNumberOrNull(obj.medicalTests),
  medications: assertNumber(obj.medications),
  obstetrics: assertNumberOrNull(obj.obstetrics),
  surgeries: assertNumberOrNull(obj.surgeries),
  vaccinations: assertNumberOrNull(obj.vaccinations),
})

export const parsedSurgeryNormaliser: AssertTypeFn<ParsedSurgery> = (obj) => ({
  date: dateNormaliser(obj.date),
  description: assertString(obj.description),
  facility: assertObjectOrUndefined(obj.facility, facilityNormaliser),
  id: assertString(obj.id),
  patientId: assertString(obj.id),
  recordType: assertValue(obj.recordType, RecordType.PARSED),
  uploadId: assertString(obj.uploadId),
  uploadPageNumber: assertNumber(obj.uploadPageNumber),
})

export const manualSurgeryNormaliser: AssertTypeFn<ManualSurgery> = (obj) => ({
  date: assertObjectOrUndefined(obj.date, dateNormaliser),
  description: assertStringOrNull(obj.description),
  facility: assertObjectOrUndefined(obj.facility, facilityNormaliser),
  id: assertString(obj.id),
  recordType: assertValue(obj.recordType, RecordType.MANUAL),
  sourceDocument: assertObjectOrUndefined(
    obj.sourceDocument,
    sourceDocumentNormaliser,
  ),
  type: assertObjectOrUndefined(obj.type, generalItemTypeNormaliser),
})

export const surgeryNormaliser: AssertTypeFn<Surgery> = (obj) =>
  obj.recordType === RecordType.PARSED
    ? parsedSurgeryNormaliser(obj)
    : manualSurgeryNormaliser(obj)

export const vaccineNormaliser: AssertTypeFn<Vaccine> = (obj) => ({
  id: assertString(obj.id),
  isCovid: assertBoolean(obj.isCovid),
  name: assertString(obj.name),
})

export const parsedVaccinationNormaliser: AssertTypeFn<ParsedVaccination> = (
  obj,
) => ({
  batch: assertStringOrNull(obj.batch),
  date: dateNormaliser(obj.date),
  expiry: assertObjectOrUndefined(obj.expiry, dateNormaliser),
  facility: assertObjectOrUndefined(obj.facility, facilityNormaliser),
  id: assertString(obj.id),
  manufacturer: assertStringOrNull(obj.manufacturer),
  recordType: assertValue(obj.recordType, RecordType.PARSED),
  rowGroups: assertArray(obj.rowGroups, rowGroupNormaliser),
  shotDescription: assertStringOrNull(obj.shotDescription),
  shotName: assertString(obj.shotName),
  uploadId: assertString(obj.uploadId),
  uploadPageNumber: assertNumber(obj.uploadPageNumber),
})

export const manualVaccinationNormaliser: AssertTypeFn<ManualVaccination> = (
  obj,
) => ({
  date: dateNormaliser(obj.date),
  expiry: assertObjectOrUndefined(obj.expiry, dateNormaliser),
  facility: assertObjectOrUndefined(obj.facility, facilityNormaliser),
  id: assertString(obj.id),
  recordType: assertValue(obj.recordType, RecordType.MANUAL),
  sourceDocument: assertObjectOrUndefined(
    obj.sourceDocument,
    sourceDocumentNormaliser,
  ),
  vaccine: vaccineNormaliser(obj.vaccine),
})

export const vaccinationNormaliser: AssertTypeFn<PatientVaccination> = (obj) =>
  obj.recordType === RecordType.PARSED
    ? parsedVaccinationNormaliser(obj)
    : manualVaccinationNormaliser(obj)

export const drugCodeNormaliser: AssertTypeFn<DrugCode> = (obj) => ({
  code: assertString(obj.code),
  message: assertString(obj.message),
})

export const drugNormaliser: AssertTypeFn<Drug> = (obj) => ({
  barcodes: assertArray(obj.barcodes, assertString),
  counsellingCodes: assertArray(obj.counsellingCodes, drugCodeNormaliser),
  form: assertString(obj.form),
  genericName: assertString(obj.genericName),
  ingredients: assertArray(obj.ingredients, assertString),
  manufacturer: assertString(obj.manufacturer),
  packSize: assertString(obj.packSize),
  productId: assertString(obj.productId),
  tradeName: assertString(obj.tradeName),
  warningCodes: assertArray(obj.warningCodes, drugCodeNormaliser),
})

export const parsedMedicationNormaliser: AssertTypeFn<ParsedMedication> = (
  obj,
) => ({
  date: assertObjectOrUndefined(obj.date, dateNormaliser),
  dosage: assertStringOrNull(obj.dosage),
  drugs: assertArray(obj.drugs, assertString),
  facility: assertObjectOrUndefined(obj.facility, facilityNormaliser),
  id: assertString(obj.id),
  recordType: assertValue(obj.recordType, RecordType.PARSED),
  uploadId: assertString(obj.uploadId),
  uploadPageNumber: assertNumber(obj.uploadPageNumber),
})

export const manualMedicationNormaliser: AssertTypeFn<ManualMedication> = (
  obj,
) => ({
  id: assertString(obj.id),
  name: assertStringOrNull(obj.name),
  drug: assertObjectOrUndefined(obj.drug, drugNormaliser),
  dosage: assertStringOrNull(obj.dosage),
  frequency: assertStringOrNull(obj.frequency),
  startDate: assertObjectOrUndefined(obj.startDate, dateNormaliser),
  endDate: assertObjectOrUndefined(obj.endDate, dateNormaliser),
  sourceDocument: assertObjectOrUndefined(
    obj.sourceDocument,
    sourceDocumentNormaliser,
  ),
  recordType: assertValue(obj.recordType, RecordType.MANUAL),
})

export const medicationNormaliser: AssertTypeFn<Medication> = (obj) =>
  obj.recordType === RecordType.PARSED
    ? parsedMedicationNormaliser(obj)
    : manualMedicationNormaliser(obj)

export const diagnosedConditionNormaliser: AssertTypeFn<DiagnosedCondition> = (
  obj,
) => ({
  condition: assertStringOrNull(obj.condition),
  customName: assertStringOrNull(obj.customName),
  date: assertObjectOrUndefined(obj.date, dateNormaliser),
  endDate: assertObjectOrUndefined(obj.endDate, dateNormaliser),
  facility: assertObjectOrUndefined(obj.facility, facilityNormaliser),
  id: assertString(obj.id),
  isTreated: assertBooleanOrNull(obj.isTreated),
  recordType: assertEnum(obj.recordType, RecordType),
  sourceDocument: assertObjectOrUndefined(
    obj.sourceDocument,
    sourceDocumentNormaliser,
  ),
})

export const parsedMedicalTestNormaliser: AssertTypeFn<ParsedMedicalTest> = (
  obj,
) => ({
  attachmentPageNumber: assertNumberOrNull(obj.attachmentPageNumber),
  attachmentsCount: assertNumber(obj.attachmentsCount),
  date: dateNormaliser(obj.date),
  facility: assertObjectOrUndefined(obj.facility, facilityNormaliser),
  header: assertString(obj.header),
  id: assertString(obj.id),
  recordType: assertValue(obj.recordType, RecordType.PARSED),
  rowGroups: assertArray(obj.rowGroups, rowGroupNormaliser),
  type: assertEnum(obj.type, ParsedMedicalTestType),
  uploadId: assertString(obj.uploadId),
  uploadPageNumber: assertNumber(obj.uploadPageNumber),
})

export const manualMedicalTestNormaliser: AssertTypeFn<ManualMedicalTest> = (
  obj,
) => ({
  date: assertObjectOrUndefined(obj.date, dateNormaliser),
  description: assertStringOrNull(obj.description),
  facility: assertObjectOrUndefined(obj.facility, facilityNormaliser),
  id: assertString(obj.id),
  recordType: assertValues(obj.recordType, [
    RecordType.MANUAL,
    RecordType.IMPORTED,
  ]),
  sourceDocument: assertObjectOrUndefined(
    obj.sourceDocument,
    sourceDocumentNormaliser,
  ),
  type: getItemTypeNormaliser(medicalTestTypeValueNormaliser)(obj.type),
})

export const medicalTestNormaliser: AssertTypeFn<MedicalTest> = (obj) =>
  obj.recordType === RecordType.PARSED
    ? parsedMedicalTestNormaliser(obj)
    : manualMedicalTestNormaliser(obj)

export const birthNormaliser: AssertTypeFn<Birth> = (obj) => ({
  childCondition: assertStringOrNull(obj.childCondition),
  dateOfBirth: assertString(obj.dateOfBirth),
  delivery: assertEnumOrNull(obj.delivery, DeliveryType),
  externalSourceId: assertStringOrNull(obj.externalSourceId),
  externalSourceType: assertEnumOrNull(
    obj.externalSourceType,
    ObstetricsSource,
  ),
  files: assertArray(obj.files, obstetricsFileNormaliser),
  gender: assertEnumOrNull(obj.gender, Sex),
  gestationalAgeInWeeks: assertNumberOrNull(obj.gestationalAgeInWeeks),
  id: assertString(obj.id),
  isChildWell: assertBooleanOrNull(obj.isChildWell),
  name: assertStringOrNull(obj.name),
  notes: assertStringOrNull(obj.notes),
  painRelief: assertArrayOrNull(obj.painRelief, getAssertEnum(PainRelief)),
  perinealTrauma: assertEnumOrNull(obj.perinealTrauma, PerinealTrauma),
  reasonForType: assertStringOrNull(obj.reasonForType),
  weight: assertNumberOrNull(handleBigDecimals(obj.weight)),
})

export const terminatedPregnancyNormaliser: AssertTypeFn<
  TerminatedPregnancy
> = (obj) => ({
  externalSourceId: assertStringOrNull(obj.externalSourceId),
  externalSourceType: assertEnumOrNull(
    obj.externalSourceType,
    ObstetricsSource,
  ),
  files: assertArray(obj.files, obstetricsFileNormaliser),
  hideFromPatientLifeMilestones: assertBoolean(
    obj.hideFromPatientLifeMilestones,
  ),
  id: assertString(obj.id),
  notes: assertStringOrNull(obj.notes),
  pregnancyLengthWeeks: assertNumberOrNull(obj.pregnancyLengthWeeks),
  reason: assertEnumOrNull(obj.reason, PregnancyTerminationReason),
  terminationDate: assertString(obj.terminationDate),
  withSurgery: assertBooleanOrNull(obj.withSurgery),
})

export const obstetricsNormaliser: AssertTypeFn<Obstetrics> = (obj) => ({
  birthCount: assertNumberOrNull(obj.birthCount),
  births: assertArrayOrNull(obj.births, birthNormaliser),
  terminatedPregnancies: assertArrayOrNull(
    obj.terminatedPregnancies,
    terminatedPregnancyNormaliser,
  ),
  terminatedPregnancyCount: assertNumberOrNull(obj.terminatedPregnancyCount),
})

export const obstetricsOrNullNormaliser: AssertTypeFn<Obstetrics | null> = (
  obj,
) => assertObjectOrNull(obj, obstetricsNormaliser)

export const bloodTypeNormaliser: AssertTypeFn<BloodTypeDetails> = (obj) => ({
  bloodType: assertEnumOrNull(obj.bloodType, BloodType),
  patientId: assertString(obj.patientId),
})

export const familyMemberNormaliser: AssertTypeFn<FamilyMember> = (obj) => ({
  ageRange: assertEnum(obj.ageRange, AgeRange),
  relation: assertEnum(obj.relation, FamilyMemberRelation),
})

export const familyHistoryRecordNormaliser: AssertTypeFn<
  FamilyHistoryRecord
> = (obj) => ({
  disease: getItemTypeNormaliser(familyHistoryTypeValueNormaliser)(obj.disease),
  familyMembers: assertArrayOrNull(obj.familyMembers, familyMemberNormaliser),
})

export const familyHistoryNormaliser: AssertNullableTypeFn<FamilyHistory> = (
  arr,
) => assertArray(arr, familyHistoryRecordNormaliser)

export const cholesterolNormaliser: AssertTypeFn<PatientCholesterol> = (
  obj,
) => ({
  date: assertObjectOrUndefined(obj.date, dateNormaliser),
  facility: assertObjectOrUndefined(obj.facility, facilityNormaliser),
  hdl: assertNumberOrNull(obj.hdl),
  hdlRatio: assertNumberOrNull(obj.hdlRatio),
  id: assertString(obj.id),
  ldl: assertNumberOrNull(obj.ldl),
  sourceDocument: assertObjectOrUndefined(
    obj.sourceDocument,
    sourceDocumentNormaliser,
  ),
  totalAmount: assertNumberOrNull(obj.totalAmount),
  triglycerides: assertNumberOrNull(obj.triglycerides),
  recordType: assertEnum(obj.recordType, DataOrigin),
})

export const basicHealthInfoNormaliser: AssertTypeFn<
  BasicHealthInfoResponse
> = (obj) => ({
  activeDiagnosedConditions: assertArrayOrNull(
    obj.activeDiagnosedConditions,
    diagnosedConditionNormaliser,
  ),
  activeDiagnosedConditionsCount: assertNumberOrNull(
    obj.activeDiagnosedConditionsCount,
  ),
  activeMedications: assertArrayOrNull(
    obj.activeMedications,
    medicationNormaliser,
  ),
  activeMedicationsCount: assertNumberOrNull(obj.activeMedicationsCount),
  allergies: assertArrayOrNull(obj.allergies, patientAllergyNormaliser),
  allergiesCount: assertNumberOrNull(obj.allergiesCount),
  bloodType: assertEnumOrNull(obj.bloodType, BloodType),
  cholesterol: assertObjectOrUndefined(obj.cholesterol, cholesterolNormaliser),
  familyHistory: assertArrayOrNull(
    obj.familyHistory,
    familyHistoryRecordNormaliser,
  ),
  lifestyleInformation: assertObjectOrUndefined(
    obj.lifestyleInformation,
    lifestyleInformationNormaliser,
  ),
  medicalTests: assertArrayOrNull(obj.medicalTests, medicalTestNormaliser),
  medicalTestsCount: assertNumberOrNull(obj.medicalTestsCount),
  obstetrics: assertObjectOrUndefined(obj.obstetrics, obstetricsNormaliser),
  personalInformation: assertObjectOrUndefined(
    obj.personalInformation,
    personalInformationNormaliser,
  ),
  surgeries: assertArrayOrNull(obj.surgeries, surgeryNormaliser),
  surgeriesCount: assertNumberOrNull(obj.surgeriesCount),
  vaccinations: assertArrayOrNull(obj.vaccinations, vaccinationNormaliser),
  vaccinationsCount: assertNumberOrNull(obj.vaccinationsCount),
})

export const criticalNoteNormaliser: AssertTypeFn<CriticalNote> = (obj) => ({
  content: assertString(obj.content),
})

export const criticalNoteOrNullNormaliser: AssertTypeFn<CriticalNote | null> = (
  obj,
) => assertObjectOrNull(obj, criticalNoteNormaliser)
