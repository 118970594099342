import styled from 'styled-components'

export const StyledDashboardInnerCard = styled.div`
  background-color: ${({theme}) => theme.colors.grey.grey20};
  border-radius: ${({theme}) => theme.borderRadius.sm};
  padding: ${({theme}) => theme.spacing.lg};
`

export const StyledDashboardInnerCardTable = styled.table`
  width: 100%;
`

export const StyledDashboardInnerCardTableCell = styled.td`
  vertical-align: middle;

  &:first-child {
    white-space: nowrap;
  }

  &:not(:last-child) {
    padding-right: ${({theme}) => theme.spacing.md};
  }

  *:not(:last-child) > & {
    padding-bottom: ${({theme}) => theme.spacing.xs};
  }
`
