import React from 'react'
import {DateTime, DurationLikeObject} from 'luxon'
import {HealthDataResolution} from '@common/models'
import {
  formatDateInterval,
  getAlignedHealthDataIntervalStart,
  resolutionUnitMap,
} from '@common/utils'

import {DateTextContainer} from './TrendChartDateSelect.styled'

import {Container} from '~/components/general/container/Container'
import {Icon} from '~/components/general/icon/Icon'
import {Typography} from '~/components/general/typography/Typography'
import {theme} from '~/styles/theme'
import {dataPointCountMap} from '~/utils'

const DATE_INTERVAL_STEP_SIZE = 5

type TrendChartDateSelectProps = {
  endDate: DateTime
  resolution: HealthDataResolution
  startDate: DateTime
  setStartDate: (date: DateTime) => void
}

export const TrendChartDateSelect: React.FC<TrendChartDateSelectProps> = ({
  endDate,
  resolution,
  startDate,
  setStartDate,
}) => {
  const dateDiff: DurationLikeObject = {
    [resolutionUnitMap[resolution]]: DATE_INTERVAL_STEP_SIZE,
  }

  const setNewStartDate = (increaseDate: boolean) => {
    const maxStartDate = getAlignedHealthDataIntervalStart(
      DateTime.now(),
      resolution,
      dataPointCountMap[resolution],
    )

    const newDate = increaseDate
      ? startDate.plus(dateDiff)
      : startDate.minus(dateDiff)

    setStartDate(maxStartDate < newDate ? maxStartDate : newDate)
  }

  const isRightArrowDisabled = DateTime.now().startOf('day') <= endDate

  return (
    <Container flex align="center">
      <Icon name="ArrowLeft" onClick={() => setNewStartDate(false)} />
      <DateTextContainer>
        <Typography>
          {formatDateInterval(startDate, endDate, DateTime.DATE_MED)}
        </Typography>
      </DateTextContainer>
      <Icon
        name="ArrowRight"
        onClick={isRightArrowDisabled ? undefined : () => setNewStartDate(true)}
        color={
          isRightArrowDisabled
            ? theme.colors.grey.main
            : theme.colors.primary.main
        }
      />
    </Container>
  )
}
