import {useCallback} from 'react'

import {Header} from '../api'
import {
  ApiErrorType,
  ApiRequestError,
  CustomApiErrorBody,
  CustomApiRequestError,
  ValidationApiErrorBody,
  ValidationApiRequestError,
} from '../models'
import {useCustomApiErrorTranslations} from './translations'

export const isCustomApiErrorResponse = (
  response: unknown,
): response is CustomApiErrorBody =>
  !!response &&
  typeof response === 'object' &&
  'errorCode' in response &&
  typeof response.errorCode === 'string' &&
  'type' in response &&
  typeof response.type === 'string' &&
  'description' in response &&
  typeof response.description === 'string'

const checkValidationApiErrorBodyItem = (item: unknown) =>
  !!item &&
  typeof item === 'object' &&
  'fieldName' in item &&
  typeof item.fieldName === 'string' &&
  'objectName' in item &&
  typeof item.objectName === 'string' &&
  'value' in item

export const isValidationApiErrorResponse = (
  response: unknown,
): response is ValidationApiErrorBody =>
  Array.isArray(response)
    ? response.every(checkValidationApiErrorBodyItem)
    : false

export const getApiRequestError = async (
  response: Response,
  endpoint: string,
  headers: Header,
) => {
  const errorCode = response.status

  switch (errorCode) {
    case 400:
      const body = await response.json()

      const errorProps = {
        message: `API query failed. Bad request. Error code ${errorCode}.`,
        headers,
        endpoint,
      }

      if (isCustomApiErrorResponse(body)) {
        return new CustomApiRequestError({
          body,
          ...errorProps,
        })
      }

      if (isValidationApiErrorResponse(body)) {
        return new ValidationApiRequestError({
          body,
          ...errorProps,
        })
      }

      return new ApiRequestError(errorProps)

    case 401:
      return new ApiRequestError({
        message: `API query failed. Authorization information is missing, invalid or expired. Try repeating the request. Error code ${errorCode}.`,
        endpoint,
        headers,
      })

    case 403:
      return new ApiRequestError({
        message: `API query failed. Caller does not have permissions for the resource. Error code ${errorCode}.`,
        endpoint,
        headers,
      })

    case 404:
      return new ApiRequestError({
        message: `API query failed. Resource not found. Error code ${errorCode}.`,
        endpoint,
        headers,
      })

    case 422:
      return new ApiRequestError({
        message: `API query failed. Validation error, input is invalid. Error code ${errorCode}.`,
        endpoint,
        headers,
      })

    default:
      return new ApiRequestError({
        message: `API query failed. Error code ${errorCode}.`,
        endpoint,
        headers,
      })
  }
}

export const isUserValidationError = (error: Error) =>
  error instanceof ValidationApiRequestError ||
  (error instanceof CustomApiRequestError &&
    error.body?.type === ApiErrorType.CUSTOMER)

export const formatValidationApiRequestErrorMessage = (
  error: ValidationApiRequestError,
) => `Field "${error.body[0].fieldName}" - ${error.body[0].message}`

export const useCustomErrorMessage = () => {
  const translateCustomApiError = useCustomApiErrorTranslations()

  return useCallback(
    (error: Error) => {
      if (
        error instanceof ValidationApiRequestError &&
        error.body[0]?.message
      ) {
        return formatValidationApiRequestErrorMessage(error)
      }

      if (error instanceof CustomApiRequestError) {
        return translateCustomApiError(error.body.errorCode)
      }

      return undefined
    },
    [translateCustomApiError],
  )
}
