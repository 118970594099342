import {HealthDataType} from '@common/models'
import {useEffect, useState} from 'react'

const HEALTH_DATA_TYPES_STORAGE_KEY = 'selectedHealthDataTypes'

const healthDataTypesOrder = [
  HealthDataType.BLOOD_PRESSURE,
  HealthDataType.RESTING_HEART_RATE,
  HealthDataType.HEART_AGE_YEARS,
  HealthDataType.WEIGHT_KG,
  HealthDataType.HEIGHT_CM,
  HealthDataType.WAIST_CIRCUMFERENCE_CM,
  HealthDataType.BMI,
  HealthDataType.BODY_FAT_PERCENTAGE,
  HealthDataType.CHOLESTEROL_HDL_RATIO,
  HealthDataType.MOOD_SCORE,
  HealthDataType.QUALITY_SLEEP_SECONDS,
  HealthDataType.SLEEP_SCORE,
  HealthDataType.ACTIVE_MINUTES,
  HealthDataType.STEPS,
  HealthDataType.ACTIVELY_BURNED_CALORIES,
  HealthDataType.MOVEMENT_SCORE,
  HealthDataType.DIET_SCORE,
  HealthDataType.WATER_CONSUMPTION_ML,
  HealthDataType.ALCOHOL_SCORE,
  HealthDataType.TOBACCO_SCORE,
]

const healthDataTypesOrderMap = healthDataTypesOrder.reduce(
  (orderMap, healthDataType) => ({
    ...orderMap,
    [healthDataType]: healthDataTypesOrder.indexOf(healthDataType),
  }),
  {} as Record<HealthDataType, number>,
)

const defautHiddenHealthDataTypes = [
  HealthDataType.HEIGHT_CM,
  HealthDataType.BMI,
]

const sortHealthDataTypes = (healthDataTypes: HealthDataType[]) =>
  healthDataTypes
    .slice()
    .sort((a, b) => healthDataTypesOrderMap[a] - healthDataTypesOrderMap[b])

const defaultHealthDataTypes = sortHealthDataTypes(
  Object.values(HealthDataType).filter(
    (healthDataType) => !defautHiddenHealthDataTypes.includes(healthDataType),
  ),
)

export const useSelectedHealthDataTypes = () => {
  const [selectedHealthDataTypes, setSelectedHealthDataTypes] = useState(
    defaultHealthDataTypes,
  )

  const selectHealthDataTypes = (healthDataTypes: HealthDataType[]) => {
    setSelectedHealthDataTypes(sortHealthDataTypes(healthDataTypes))

    window.localStorage.setItem(
      HEALTH_DATA_TYPES_STORAGE_KEY,
      JSON.stringify(healthDataTypes),
    )
  }

  useEffect(() => {
    const storedHealthDataTypes = window.localStorage.getItem(
      HEALTH_DATA_TYPES_STORAGE_KEY,
    )
    if (storedHealthDataTypes) {
      const sortedHealthDataTypes = sortHealthDataTypes(
        JSON.parse(storedHealthDataTypes),
      )
      setSelectedHealthDataTypes(sortedHealthDataTypes)
    }
  }, [])

  return {selectedHealthDataTypes, selectHealthDataTypes}
}
