import React from 'react'

import {AppNotification} from './NotificationContext'
import {NotificationBanner} from './NotificationBanner'
import {
  StyledNotificationBannersContainer,
  StyledNotificationBannersParent,
} from './NotificationBanner.styled'

type NotificationBannersContainerProps = {
  notifications: AppNotification[]
  dismissNotification: (notificationId: string) => void
}

export const NotificationBannersContainer: React.FC<
  NotificationBannersContainerProps
> = ({notifications, dismissNotification}) => {
  if (!notifications.length) {
    return null
  }

  return (
    <StyledNotificationBannersParent>
      <StyledNotificationBannersContainer>
        {notifications.map((notification) => (
          <NotificationBanner
            key={notification.id}
            onClose={() => dismissNotification(notification.id)}
            text={notification.text}
            notificationType={notification.notificationType}
          />
        ))}
      </StyledNotificationBannersContainer>
    </StyledNotificationBannersParent>
  )
}
