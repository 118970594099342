import styled from 'styled-components'

export const StyledTopBar = styled.nav`
  height: ${({theme}) => theme.height.topBar};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 ${({theme}) => theme.spacing.xxl};
  border-bottom: 1px solid ${({theme}) => theme.colors.grey.grey40};
`
