import * as React from 'react'

import {IconProps} from '.'

function Logo({size}: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.1849 7.5C20.1089 7.5 23.3564 8.81465 25.5732 11.1728C23.6518 10.7081 21.5585 11.2846 20.0634 12.9025C19.7792 13.2099 19.5299 13.5407 19.3153 13.8891C18.4687 13.5382 17.5153 13.3548 16.4552 13.3747C13.0762 13.3747 10.6883 15.9758 10.6883 19.8325C10.6883 23.734 13.0762 26.3799 16.4552 26.3799C18.7676 26.3799 20.6091 25.5598 21.8334 23.9197L22.5462 24.6906C23.6954 25.934 25.0472 26.8245 26.4866 27.3621C24.386 30.3722 20.7592 32.1201 16.0948 32.1201C8.751 32.1201 3.75 27.1871 3.75 19.8773C3.75 12.4778 8.751 7.5 16.1849 7.5Z"
        fill="#002A7E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.8726 14.3557C30.6276 12.4383 33.4996 12.4492 35.2419 14.3884C36.9325 16.2699 36.9325 19.2764 35.2418 21.1579L33.892 22.6602C31.1334 25.7302 26.6103 25.7285 23.8532 22.6602L22.5033 21.1579C20.8127 19.2764 20.8127 16.2701 22.5032 14.3886M28.8726 14.3557C27.1176 12.4385 24.2454 12.4494 22.5032 14.3886L28.8726 14.3557Z"
        fill="#F56F50"
      />
    </svg>
  )
}

export default Logo
