/* tslint:disable */
/* eslint-disable */
/**
 * Cushla API
 * REST API for Cushla
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */

export enum LifeMilestoneType {
  NEW_BABY = 'NEW_BABY',
  PREGNANCY_END_WITHOUT_BABY = 'PREGNANCY_END_WITHOUT_BABY',
  NEW_HOME = 'NEW_HOME',
  MARRIAGE = 'MARRIAGE',
  NEW_JOB = 'NEW_JOB',
  DIVORCE_BREAK_UP = 'DIVORCE_BREAK_UP',
  CHILD_LEAVING_HOME = 'CHILD_LEAVING_HOME',
  DEATH_OF_A_LOVED_ONE = 'DEATH_OF_A_LOVED_ONE',
  OTHER = 'OTHER',
}
