import {
  ApiStatus,
  ExactOrEstimatedDate,
  PagedResponse,
  PagingData,
  RecordsSorting,
  SimpleResponse,
} from '../models'
import {
  assertArray,
  assertBoolean,
  assertBooleanOrNull,
  assertNumber,
  assertNumberOrNull,
  assertObject,
  assertObjectOrUndefined,
  assertString,
} from '../utils'

export type AssertTypeFn<T> = (obj: T) => T

export type AssertNullableTypeFn<T> = (obj: Partial<T>) => T

export const pagingNormaliser: AssertTypeFn<PagingData> = (obj) => ({
  next: assertNumberOrNull(obj.next),
  prev: assertNumberOrNull(obj.prev),
  current: assertNumber(obj.current),
  pageSize: assertNumber(obj.pageSize),
  totalElements: assertNumber(obj.totalElements),
  totalPages: assertNumber(obj.totalPages),
  isPaged: assertBoolean(obj.isPaged),
})

export const recordsSortingNormaliser: AssertTypeFn<RecordsSorting> = (
  obj,
) => ({
  sorted: assertBoolean(obj.sorted),
})

export const getPagedResponseNormaliser: <T>(
  recordNormaliser: AssertTypeFn<T>,
) => AssertTypeFn<PagedResponse<T>> = (recordNormaliser) => (obj) => ({
  page: pagingNormaliser(obj.page),
  sorting: assertObjectOrUndefined(obj.sorting, recordsSortingNormaliser),
  records: assertArray(obj.records, recordNormaliser),
})

export const dateNormaliser: AssertTypeFn<ExactOrEstimatedDate> = (obj) => ({
  isEstimated: assertBoolean(obj.isEstimated),
  date: assertString(obj.date),
})

const featureFlagsNormaliser: AssertTypeFn<
  Record<string, boolean | undefined>
> = (obj) => {
  Object.values(obj).forEach(assertBooleanOrNull)
  return obj
}

export const apiStatusNormaliser: AssertTypeFn<ApiStatus> = (obj) => ({
  apiVersion: assertString(obj.apiVersion),
  minimumAppVersion: assertString(obj.minimumAppVersion),
  featureFlags: assertObject(obj.featureFlags, featureFlagsNormaliser),
})

export const simpleResponseNormaliser: AssertTypeFn<SimpleResponse> = (
  obj,
) => ({
  message: assertString(obj.message),
})
