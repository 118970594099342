/* eslint-disable @typescript-eslint/no-explicit-any */

export enum EnumType {
  AddressType = 'AddressType',
  Relationship = 'Relationship',
  Sex = 'Sex',
  Pronoun = 'Pronoun',
  MaritalStatus = 'MaritalStatus',
  HealthIdentifierType = 'HealthIdentifierType',
  PatientCategory = 'PatientCategory',
  DeliveryType = 'DeliveryType',
  PregnancyTerminationReason = 'PregnancyTerminationReason',
  HomeType = 'HomeType',
  HomeSharedWithOption = 'HomeSharedWithOption',
  CaresForOption = 'CaresForOption',
  TobaccoType = 'TobaccoType',
  IsSmoker = 'IsSmoker',
  LifeMilestoneImpact = 'LifeMilestoneImpact',
  AllergyEffect = 'AllergyEffect',
  AppointmentType = 'AppointmentType',
  Specialization = 'Specialization',
  ParsedMedicalTestType = 'ParsedMedicalTestType',
  AgeRange = 'AgeRange',
  FamilyMemberRelation = 'FamilyMemberRelation',
  QuestionnaireType = 'QuestionnaireType',
  QuestionnaireRepeatPeriod = 'QuestionnaireRepeatPeriod',
  LifeMilestoneType = 'LifeMilestoneType',
  HealthJourneyType = 'HealthJourneyType',
  EstimatedDeliveryDateSource = 'EstimatedDeliveryDateSource',
  PregnancyFeeling = 'PregnancyFeeling',
  PregnancyIntention = 'PregnancyIntention',
  PregnancyFamilyHistoryCondition = 'PregnancyFamilyHistoryCondition',
  LabourPainRelief = 'LabourPainRelief',
  FeedingPlan = 'FeedingPlan',
  LabourPosition = 'LabourPosition',
  LabourType = 'LabourType',
  VitaminKPreference = 'VitaminKPreference',
  AnaesthesiaType = 'AnaesthesiaType',
  BloodLossAmount = 'BloodLossAmount',
  CSectionType = 'CSectionType',
  VaginalBirthType = 'VaginalBirthType',
  PregnancyEndType = 'PregnancyEndType',
  PainRelief = 'PainRelief',
  PerinealTrauma = 'PerinealTrauma',
  ActivityMovementType = 'ActivityMovementType',
  HealthDataType = 'HealthDataType',
  AppointmentCancellationReason = 'AppointmentCancellationReason',
  TimelineItemType = 'TimelineItemType',
}

export const assertNull = (value: undefined | null) => {
  if (value == null) {
    return undefined
  }
  throw new TypeError(
    `Wrong type - got "${value}", expected "null | undefined"`,
  )
}

export const assertString = (value: string) => {
  if (typeof value !== 'string') {
    throw new TypeError(`Wrong type - got "${value}", expected "string"`)
  }
  return value
}

export const assertStringOrNull = (value: string | undefined) => {
  if (value == null) {
    return undefined
  }
  return assertString(value)
}

export const assertBoolean = (value: boolean) => {
  if (typeof value !== 'boolean') {
    throw new TypeError(`Wrong type - got "${value}", expected "boolean"`)
  }
  return value
}

export const assertBooleanOrNull = (value: boolean | undefined) => {
  if (value == null) {
    return undefined
  }
  return assertBoolean(value)
}

export const assertNumber = (value: number | string) => {
  if (value === 'Infinity') {
    return Number.POSITIVE_INFINITY
  }
  if (value === '-Infinity') {
    return Number.NEGATIVE_INFINITY
  }
  if (typeof value !== 'number') {
    throw new TypeError(`Wrong type - got "${value}", expected "number"`)
  }
  return value
}

export const assertNumberOrNull = (value: number | undefined) => {
  if (value == null) {
    return undefined
  }
  return assertNumber(value)
}

export const assertValue: <T>(value: unknown, expectedValue: T) => T = (
  value,
  expectedValue,
) => {
  if (value === expectedValue) {
    return expectedValue
  }
  throw new TypeError(
    `Wrong type - got "${value}", expected "${expectedValue}"`,
  )
}

export const assertValues: <T>(value: T, expectedValues: T[]) => T = (
  value,
  expectedValues,
) => {
  if (expectedValues.includes(value)) {
    return value
  }
  throw new TypeError(
    `Wrong type - got "${value}", expected one of "${expectedValues.join(
      ', ',
    )}"`,
  )
}

export const assertEnum = <T>(value: string, enumType: object) => {
  if (!Object.values(enumType).includes(value)) {
    throw new TypeError(`Wrong type - got "${value}", expected enum value`)
  }
  return value as T
}

export const assertEnumOrNull: <T>(
  value: string | undefined,
  enumType: object,
) => T | undefined = (value, enumType) => {
  if (value == null) {
    return undefined
  }
  return assertEnum(value, enumType)
}

export const getAssertEnum: <T>(enumType: object) => (value: string) => T =
  (enumType) => (value) =>
    assertEnum(value, enumType)

export const assertArray: <T>(
  value: unknown,
  assertType: (value: any) => T,
) => T[] = (value, assertType) => {
  if (!Array.isArray(value)) {
    throw new TypeError(`Wrong type - value "${value}" is not an array`)
  }
  return value.map(assertType)
}

export const assertArrayOrNull: <T>(
  value: unknown,
  assertType: (value: any) => T,
) => T[] | undefined = (value, assertType) => {
  if (value == null) {
    return undefined
  }
  return assertArray(value, assertType)
}

export const assertObject: <T extends Record<string, any>>(
  value: T,
  assertType?: (value: any) => T,
) => T = (value, assertType = (val) => val) => {
  if (typeof value !== 'object' || Array.isArray(value) || value == null) {
    throw new TypeError(`Wrong type - value "${value}" is not an object`)
  }
  return assertType(value)
}

export const assertObjectOrNull: <T extends Record<string, any>>(
  value: T | null,
  assertType?: (value: any) => T,
) => T | null = (value, assertType) => {
  if (value == null) {
    return null
  }
  return assertObject(value, assertType)
}

export const assertObjectOrUndefined: <T extends Record<string, any>>(
  value: T | undefined,
  assertType?: (value: any) => T,
) => T | undefined = (value, assertType) => {
  if (value == null) {
    return undefined
  }
  return assertObject(value, assertType)
}

// cast functions - use when BE returns all values as strings
export const castBoolean = (value: unknown) => {
  if (typeof value !== 'string') {
    throw new TypeError(`Wrong type - got "${value}", expected "string"`)
  }

  if (value === 'true') {
    return true
  }
  if (value === 'false') {
    return false
  }
  throw new TypeError(
    `Wrong type - value ${value} cannot be cast to a boolean.`,
  )
}

export const castBooleanOrNull = (value: unknown) => {
  if (value == null) {
    return undefined
  }
  return castBoolean(value)
}

export const castNumber = (value: unknown) => {
  if (typeof value !== 'string') {
    throw new TypeError(`Wrong type - got "${value}", expected "string"`)
  }

  const number = Number(value)

  if (Number.isNaN(number)) {
    throw new TypeError(
      `Wrong type - value ${value} cannot be cast to a number.`,
    )
  }
  return number
}

export const castNumberOrNull = (value: unknown) => {
  if (value == null) {
    return undefined
  }
  return castNumber(value)
}
