import React from 'react'

import {TooltipCard, ValueText} from './HealthDataTooltip.styled'

import {Container} from '~/components/general/container/Container'
import {Typography} from '~/components/general/typography/Typography'

type HealthDataTooltipProps = {
  className?: string
  dateText: string
  unit: string | undefined
  value: string
}

export const HealthDataTooltip: React.FC<HealthDataTooltipProps> = ({
  className,
  dateText,
  value,
  unit,
}) => (
  <TooltipCard className={className}>
    <Container flex direction="column" spacing={1} align="center" fullWidth>
      <Typography size="xxs" textAlign="center">
        {dateText}
      </Typography>
      <Typography size="xs" textAlign="center">
        <ValueText>{value}</ValueText>
        {!!unit && ` ${unit}`}
      </Typography>
    </Container>
  </TooltipCard>
)
