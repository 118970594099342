// dotenv-webpack does not support variable destructuring
const appId = process.env.AZURE_APP_ID || ''
const endpointBase = process.env.AZURE_ENDPOINT_BASE
const redirectUri = process.env.AZURE_REDIRECT_URL
const signInFlow = process.env.AZURE_SIGN_IN_FLOW
const signUpFlow = process.env.AZURE_SIGN_UP_FLOW
// const changePasswordFlow = process.env.AZURE_CHANGE_PASSWORD_FLOW

const b2cPolicies = {
  names: {
    signIn: signInFlow,
    signUp: signUpFlow,
  },
  authorities: {
    signIn: {
      authority: `${endpointBase}/${signInFlow}`,
    },
    signUp: {
      authority: `${endpointBase}/${signUpFlow}`,
    },
  },
  authorityDomains: [
    'cushladev.b2clogin.com',
    'cushlaprod.b2clogin.com',
    'login.dev.cushla.io',
    'login.stage.cushla.io',
    'login.cushla.io',
    'cushlamultidev.b2clogin.com',
    'login.multidev.cushla.io',
    'cushlamultistage.b2clogin.com',
    'login.multistage.cushla.io',
    'cushlamultiprod.b2clogin.com',
    'login.multi.cushla.io',
  ],
}

export const b2cScopes = ['openid', appId, 'offline_access']

export const msalConfig = {
  auth: {
    clientId: appId,
    authority: b2cPolicies.authorities.signIn.authority,
    knownAuthorities: b2cPolicies.authorityDomains,
    redirectUri,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
}

export const loginRequest = {
  scopes: b2cScopes,
}
