import React from 'react'
import {ReactDatePickerProps} from 'react-datepicker'

import {Icon} from '../icon/Icon'
import {StyledDatePicker, StyledDatePickerWrapper} from './DatePicker.styled'

import {theme} from '~/styles/theme'

import './datePickerStyle'

export const DatePicker: React.FC<ReactDatePickerProps> = (props) => {
  const {showTimeSelectOnly} = props

  return (
    <StyledDatePickerWrapper>
      <StyledDatePicker
        dateFormat={showTimeSelectOnly ? 'h:mm aa' : 'dd/MM/yyyy'}
        showPopperArrow={false}
        {...props}
      />
      <Icon
        size="sm"
        name={showTimeSelectOnly ? 'Clock' : 'Calendar'}
        color={theme.colors.grey.main}
      />
    </StyledDatePickerWrapper>
  )
}
