import styled, {DefaultTheme} from 'styled-components'

type Button = {
  size: 'xl' | 'lg' | 'md' | 'sm' | 'xs'
  color: 'primary' | 'secondary'
  disabled: boolean
}

const getHeight = (size: string) => {
  switch (size) {
    case 'xs':
      return '20px'
    case 'sm':
      return '40px'
    case 'md':
      return '48px'
    case 'lg':
      return '56px'
    default:
      return '64px'
  }
}

const getBgdColor = (color: string, hover: boolean, theme: DefaultTheme) => {
  switch (color) {
    case 'primary':
      return hover ? theme.colors.buttonHover : theme.colors.primary.blue50
    default:
      return hover
        ? theme.colors.secondary.orange600
        : theme.colors.secondary.main
  }
}

const getSidePadding = (size: string, theme: DefaultTheme) =>
  size === 'xs' ? theme.spacing.xs : theme.spacing.lg

export const StyledButton = styled.button<Button>`
  background-color: ${({color, theme}) => getBgdColor(color, false, theme)};
  ${({disabled}) => disabled && 'opacity: 0.5'};
  height: ${({size}) => getHeight(size)};
  display: flex;
  align-items: center;
  font-size: ${({theme}) => theme.fontSize.md};
  border-radius: ${({theme}) => theme.borderRadius.lg};
  padding: 0 ${({size, theme}) => getSidePadding(size, theme)};
  cursor: ${({disabled}) => (disabled ? 'default' : 'pointer')};
  transition: background-color 350ms;

  &:hover {
    background-color: ${({color, disabled, theme}) =>
      !disabled && getBgdColor(color, true, theme)};
  }

  & > * {
    margin-right: ${({theme}) => theme.spacing.xs};
  }

  & > *:last-child {
    margin-right: 0;
  }
`
