import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {
  BasicHealthInfoResponse,
  MedicalPermissionSubcategory,
  PractitionersPatient,
  Sex,
} from '@common/models'
import {
  capitalizeFirstLetter,
  formatBloodType,
  formatLocaleNumber,
  useFormatFamilyHistoryResponses,
} from '@common/utils'

import {
  BasicHealthAccordion,
  BasicHealthAccordionProps,
} from './BasicHealthAccordion'

import {Container} from '~/components/general/container/Container'
import {useGetPatientHealthSummary} from '~/api'
import {Loading} from '~/components/general/loading/Loading'
import {useTimelineCardData} from '~/utils'

type BasicHealthInformationTimelineProps = {
  patient: PractitionersPatient
}

const useCategoriesData = (
  healthSummaryData: BasicHealthInfoResponse | undefined,
  gender: Sex | undefined,
): BasicHealthAccordionProps[] => {
  const {t} = useTranslation()
  const formatFamilyHistoryResponses = useFormatFamilyHistoryResponses()

  const {
    getAllergyTimelineCardData,
    getBirthTimelineCardData,
    getConditionTimelineCardData,
    getMedicalTestTimelineCardData,
    getMedicationTimelineCardData,
    getSurgeryTimelineCardData,
    getVaccinationTimelineCardData,
  } = useTimelineCardData()

  const familyHistoryRows = useMemo(
    () =>
      healthSummaryData?.familyHistory
        ?.filter((item) => !!item.familyMembers?.length)
        .map((item) => ({
          title: capitalizeFirstLetter(item.disease.value?.highlight) ?? '',
          subtitle: formatFamilyHistoryResponses(item.familyMembers),
        })) ?? [],
    [formatFamilyHistoryResponses, healthSummaryData?.familyHistory],
  )

  return [
    {
      title: t('Medical conditions'),
      iconName: 'Search',
      cards:
        healthSummaryData?.activeDiagnosedConditions?.map(
          getConditionTimelineCardData,
        ) ?? [],
      permission: MedicalPermissionSubcategory.DIAGNOSED_CONDITIONS,
      showMoreLink: 'conditions',
    },
    {
      title: t('Allergies'),
      iconName: 'Shield',
      cards:
        healthSummaryData?.allergies?.map(getAllergyTimelineCardData) ?? [],
      permission: MedicalPermissionSubcategory.ALLERGY,
      showMoreLink: 'allergies',
      totalCount: healthSummaryData?.allergiesCount ?? 0,
    },
    {
      title: t('Medications'),
      iconName: 'Pills',
      cards:
        healthSummaryData?.activeMedications?.map(
          getMedicationTimelineCardData,
        ) ?? [],
      permission: MedicalPermissionSubcategory.MEDICATION,
      showMoreLink: 'medications',
    },
    {
      title: t('Vaccinations'),
      iconName: 'Vaccination',
      cards:
        healthSummaryData?.vaccinations?.map(getVaccinationTimelineCardData) ??
        [],
      permission: MedicalPermissionSubcategory.VACCINATION,
      showMoreLink: 'vaccinations',
      totalCount: healthSummaryData?.vaccinationsCount ?? 0,
    },
    {
      title: t('Family history'),
      iconName: 'DocumentList',
      cards: familyHistoryRows,
      permission: MedicalPermissionSubcategory.FAMILY_HISTORY,
      showMoreLink: 'family-history',
      totalCount: familyHistoryRows.length,
    },
    {
      title: t('Surgeries'),
      iconName: 'Surgery',
      cards:
        healthSummaryData?.surgeries?.map(getSurgeryTimelineCardData) ?? [],
      permission: MedicalPermissionSubcategory.SURGERY,
      showMoreLink: 'surgeries',
      totalCount: healthSummaryData?.surgeriesCount ?? 0,
    },
    {
      title: t('Tests & Imaging'),
      iconName: 'Drop',
      cards:
        healthSummaryData?.medicalTests?.map(getMedicalTestTimelineCardData) ??
        [],
      permission: MedicalPermissionSubcategory.MEDICAL_TEST,
      showMoreLink: 'tests',
      totalCount: healthSummaryData?.medicalTestsCount ?? 0,
    },
    {
      title: t('Cholesterol'),
      iconName: 'HeartCheck',
      cards: [
        {
          title: t('HDL ratio'),
          subtitle:
            formatLocaleNumber(healthSummaryData?.cholesterol?.hdlRatio, 2) ||
            '-',
        },
      ],
      permission: MedicalPermissionSubcategory.CHOLESTEROL,
      showMoreLink: 'cholesterol',
    },
    {
      title: t('Blood type'),
      iconName: 'Blood',
      cards: [
        {
          title: t('Blood type'),
          subtitle: formatBloodType(healthSummaryData?.bloodType) ?? '-',
        },
      ],
      permission: MedicalPermissionSubcategory.BLOOD_TYPE,
    },
    ...(gender === Sex.MALE
      ? []
      : ([
          {
            title: t('Obstetrics'),
            iconName: 'Baby',
            cards:
              healthSummaryData?.obstetrics?.births?.map(
                getBirthTimelineCardData,
              ) ?? [],
            permission: MedicalPermissionSubcategory.OBSTETRICS,
            showMoreLink: 'obstetrics',
          },
        ] as const)),
  ]
}

export const BasicHealthInformationTimeline: React.FC<
  BasicHealthInformationTimelineProps
> = ({patient}) => {
  const {isInitialLoading, data} = useGetPatientHealthSummary({
    patientId: patient.patientId,
  })

  const categoriesData = useCategoriesData(data, patient.gender)

  return (
    <Container flex direction="column" spacing={2}>
      {isInitialLoading ? (
        <Loading />
      ) : (
        categoriesData.map((category, index) => (
          <BasicHealthAccordion {...category} key={index} />
        ))
      )}
    </Container>
  )
}
