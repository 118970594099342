import {
  AllergyDto,
  ParsedMedicalTestType,
  BloodType,
  CholesterolDto,
  DiagnosedConditionDto,
  DrugDto,
  FamilyMember,
  GetObstetricsDto,
  MedicalRecordType,
  RowGroupDto,
  SourceDocumentDto,
  VaccineDto,
} from '@generated/models'

import {Facility} from './facility'
import {LifestyleInformation} from './lifestyle'
import {PersonalInformation} from './personal'
import {
  FamilyHistoryTypeValue,
  GeneralItemType,
  MedicalTestTypeValue,
} from './medicalLists'
import {ExactOrEstimatedDate} from './utils'

export type {
  AddMedicationDto as AddMedicationRequest,
  AllergenDto as Allergen,
  AllergyDto as PatientAllergy,
  AllergyRequest as AddAllergyRequest,
  BloodTypeProfile as BloodTypeDetails,
  CriticalNoteDto as CriticalNote,
  DiagnosedConditionDto as DiagnosedCondition,
  DiagnosedConditionRequest as AddDiagnosedConditionRequest,
  DrugCodeDto as DrugCode,
  DrugDto as Drug,
  FamilyMember,
  CholesterolDto as PatientCholesterol,
  CholesterolRequest as AddCholesterolRequest,
  MedicalConditionDto as MedicalCondition,
  MedicalTestRequest as AddMedicalTestRequest,
  MedicalSummaryDto as MedicalSummary,
  GetBirthDto as Birth,
  GetObstetricsDto as Obstetrics,
  GetTerminatedPregnancyDto as TerminatedPregnancy,
  SaveObstetricsDto as SaveObstetricsRequest,
  RowGroupDto as RowGroup,
  RowGroupTableRowDto as RowGroupTableItem,
  SaveBirthDto,
  SaveTerminatedPregnancyDto,
  SourceDocumentDto as SourceDocument,
  SurgeryRequest as AddSurgeryRequest,
  VaccineDto as Vaccine,
  VaccinationRequest as AddVaccinationRequest,
} from '@generated/models'

export {
  AgeRange,
  AllergyEffect,
  ParsedMedicalTestType,
  BloodType,
  DeliveryType,
  ExternalObstetricsSourceDto as ObstetricsSource,
  FamilyRelation as FamilyMemberRelation,
  GenderDto as Sex,
  MedicalRecordType as RecordType,
  PainRelief,
  VaginalBirthPerinealTraumaDto as PerinealTrauma,
  RowGroupType,
  TerminationReason as PregnancyTerminationReason,
} from '@generated/models'

export type ParsedMedication = {
  date?: ExactOrEstimatedDate
  dosage?: string
  drugs: string[]
  facility?: Facility
  id: string
  recordType: MedicalRecordType.PARSED
  uploadId: string
  uploadPageNumber: number
}

export type ManualMedication = {
  id: string
  name?: string
  drug?: DrugDto
  dosage?: string
  frequency?: string
  startDate?: ExactOrEstimatedDate
  endDate?: ExactOrEstimatedDate
  sourceDocument?: SourceDocumentDto
  recordType: MedicalRecordType.MANUAL
}

export type Medication = ParsedMedication | ManualMedication

export type ManualSurgery = {
  date?: ExactOrEstimatedDate
  description?: string
  facility?: Facility
  id: string
  recordType: MedicalRecordType.MANUAL
  sourceDocument?: SourceDocumentDto
  type?: GeneralItemType
}

export type ParsedSurgery = {
  date: ExactOrEstimatedDate
  description: string
  facility?: Facility
  id: string
  recordType: MedicalRecordType.PARSED
  uploadId: string
  uploadPageNumber: number
}

export type Surgery = ParsedSurgery | ManualSurgery

export type ParsedVaccination = {
  batch?: string
  date: ExactOrEstimatedDate
  expiry?: ExactOrEstimatedDate
  facility?: Facility
  id: string
  manufacturer?: string
  recordType: MedicalRecordType.PARSED
  rowGroups: RowGroupDto[]
  shotDescription?: string
  shotName: string
  uploadId: string
  uploadPageNumber: number
}

export type ManualVaccination = {
  date: ExactOrEstimatedDate
  expiry?: ExactOrEstimatedDate
  facility?: Facility
  id: string
  recordType: MedicalRecordType.MANUAL
  sourceDocument?: SourceDocumentDto
  vaccine: VaccineDto
}

export type PatientVaccination = ParsedVaccination | ManualVaccination

export type FamilyHistoryRecord = {
  disease: GeneralItemType<FamilyHistoryTypeValue>
  familyMembers?: FamilyMember[]
}

export type FamilyHistory = FamilyHistoryRecord[]

export type ManualMedicalTest = {
  date?: ExactOrEstimatedDate
  description?: string
  facility?: Facility
  id: string
  recordType: MedicalRecordType.MANUAL | MedicalRecordType.IMPORTED
  sourceDocument?: SourceDocumentDto
  type: GeneralItemType<MedicalTestTypeValue>
}

export type MedicalTestInfo = {
  attachmentPageNumber?: number
  attachmentsCount: number
  date: ExactOrEstimatedDate
  facility?: Facility
  header: string
  id: string
  recordType: MedicalRecordType.PARSED
  type: ParsedMedicalTestType
  uploadId: string
  uploadPageNumber: number
}

export type ParsedMedicalTest = {
  rowGroups: RowGroupDto[]
} & MedicalTestInfo

export type MedicalTest = ManualMedicalTest | ParsedMedicalTest

export type BasicHealthInfoResponse = {
  activeDiagnosedConditions?: DiagnosedConditionDto[]
  activeDiagnosedConditionsCount?: number
  activeMedications?: Medication[]
  activeMedicationsCount?: number
  allergies?: AllergyDto[]
  allergiesCount?: number
  bloodType?: BloodType
  cholesterol?: CholesterolDto
  familyHistory?: FamilyHistory
  lifestyleInformation?: LifestyleInformation
  medicalTests?: MedicalTest[]
  medicalTestsCount?: number
  obstetrics?: GetObstetricsDto
  personalInformation?: PersonalInformation
  surgeries?: Surgery[]
  surgeriesCount?: number
  vaccinations?: PatientVaccination[]
  vaccinationsCount?: number
}

export enum HealthCategory {
  ALLERGY = 'ALLERGY',
  CHOLESTEROL = 'CHOLESTEROL',
  CONSULTATION = 'CONSULTATION',
  DIAGNOSED_CONDITION = 'DIAGNOSED_CONDITION',
  MEDICAL_TEST = 'MEDICAL_TEST',
  MEDICATION = 'MEDICATION',
  SURGERY = 'SURGERY',
  VACCINATION = 'VACCINATION',
  APPOINTMENT = 'APPOINTMENT',
  LIFE_MILESTONE = 'LIFE_MILESTONE',
}
