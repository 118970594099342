import styled from 'styled-components'

export const StyledAuthLayout = styled.div`
  display: flex;
  min-height: 100vh;
  overflow: hidden;
`

export const AuthLayoutBg = styled.div`
  display: flex;
  height: 100vh;
`

export const AuthLayoutContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
