import {FeatureFlag} from '../models'
import {assertArray, assertBoolean, assertString} from '../utils'
import {AssertTypeFn} from './general'

export const featureFlagNormaliser: AssertTypeFn<FeatureFlag> = (obj) => ({
  name: assertString(obj.name),
  enabled: assertBoolean(obj.enabled),
})

export const featureFlagsNormaliser: AssertTypeFn<FeatureFlag[]> = (arr) =>
  assertArray(arr, featureFlagNormaliser)
