import {DateTime} from 'luxon'

import {
  BasicPatientData,
  HealthDataGrade,
  HealthDataType,
  IsSmoker,
  Sex,
  TobaccoUsage,
  TrendValue,
} from '../models'
import {calculateAgeFromDateOfBirth} from './helpers'

const DEFAULT_USER_AGE = 45

const BLOOD_PRESSURE_GOOD_BOUND = 130
const BLOOD_PRESSURE_BAD_BOUND = 140

const ALCOHOL_GOOD_BOUND = 14
const ALCOHOL_BAD_BOUND = 23

const BMI_BAD_BOUND = 29
const BMI_UPPER_GOOD_BOUND = 25
const BMI_LOWER_GOOD_BOUND = 18.5

const HDL_GOOD_BOUND = 3.5
const HDL_BAD_BOUND = 4

const WATER_CONSUMPTION_GOOD_BOUND = 2000
const WATER_CONSUMPTION_BAD_BOUND = 500

const DAILY_STEPS_GOOD_BOUND = 7000
const DAILY_STEPS_BAD_BOUND = 5000

const DAILY_ACTIVE_MINUTES_BAD_BOUND = 90 / 7
const DAILY_ACTIVE_MINUTES_GOOD_BOUND = 150 / 7

const EATING_HABITS_BAD_BOUND = -20
const EATING_HABITS_GOOD_BOUND = 0

const QUALITY_SLEEP_SECONDS_BAD_BOUND = 6 * 3600
const QUALITY_SLEEP_SECONDS_GOOD_BOUND = 7 * 3600

const SLEEP_BOUND = 5

const MOOD_BAD_BOUND = 40
const MOOD_GOOD_BOUND = 46

const HEART_AGE_DIFF_BOUND = 1

const heartAgeMap = {
  [Sex.MALE]: {
    39: [76, 84],
    59: [77, 85],
    79: [75, 84],
  },
  [Sex.FEMALE]: {
    39: [82, 89],
    59: [79, 86],
    79: [78, 86],
  },
}

const fatAgeMap = {
  [Sex.FEMALE]: {
    39: [33, 39],
    59: [34, 40],
    79: [36, 41],
  },
  [Sex.MALE]: {
    39: [21, 25],
    59: [22, 28],
    79: [25, 30],
  },
}

const waistCircumferenceGradeMap = {
  [Sex.MALE]: [94, 102],
  [Sex.FEMALE]: [80, 88],
}

const findEntryInAgeMap: <T>(ageMap: Record<string, T>, age: number) => T = (
  ageMap,
  age,
) => {
  const mapEntries = Object.entries(ageMap)

  return (
    mapEntries.find((entry) => parseInt(entry[0], 10) >= age)?.[1] ??
    mapEntries[mapEntries.length - 1][1]
  )
}

const getFatGrade = (value: number, user?: BasicPatientData | null) => {
  const userAge =
    calculateAgeFromDateOfBirth(user?.dateOfBirth) ?? DEFAULT_USER_AGE
  const ageMap = fatAgeMap[user?.gender ?? Sex.MALE]
  const gradeBoundaries = findEntryInAgeMap(ageMap, userAge)

  if (value < gradeBoundaries[0]) {
    return HealthDataGrade.GOOD
  }
  if (value <= gradeBoundaries[1]) {
    return HealthDataGrade.AVERAGE
  }
  return HealthDataGrade.BAD
}

const getRestingHeartRateGrade = (
  value: number,
  user?: BasicPatientData | null,
) => {
  const heartRateAgeMap = heartAgeMap[user?.gender ?? Sex.FEMALE]

  const userAge =
    calculateAgeFromDateOfBirth(user?.dateOfBirth) ?? DEFAULT_USER_AGE
  const gradeBoundaries = findEntryInAgeMap(heartRateAgeMap, userAge)

  if (value < gradeBoundaries[0]) {
    return HealthDataGrade.GOOD
  }
  if (value < gradeBoundaries[1]) {
    return HealthDataGrade.AVERAGE
  }
  return HealthDataGrade.BAD
}

const getWaistCircumferenceGrade = (value: number, user?: BasicPatientData) => {
  const gradeBoundaries = waistCircumferenceGradeMap[user?.gender ?? Sex.FEMALE]

  if (value < gradeBoundaries[0]) {
    return HealthDataGrade.GOOD
  }
  if (value <= gradeBoundaries[1]) {
    return HealthDataGrade.AVERAGE
  }
  return HealthDataGrade.BAD
}

export const getHealthDataGrade: (
  healthDataType: HealthDataType,
  value: number | undefined,
  user?: BasicPatientData,
) => HealthDataGrade | undefined = (healthDataType, value, user) => {
  if (value == null) {
    return undefined
  }

  switch (healthDataType) {
    case HealthDataType.STEPS:
      if (value <= DAILY_STEPS_BAD_BOUND) {
        return HealthDataGrade.BAD
      }
      if (value <= DAILY_STEPS_GOOD_BOUND) {
        return HealthDataGrade.AVERAGE
      }
      return HealthDataGrade.GOOD

    case HealthDataType.ACTIVE_MINUTES:
      if (value <= DAILY_ACTIVE_MINUTES_BAD_BOUND) {
        return HealthDataGrade.BAD
      }
      if (value <= DAILY_ACTIVE_MINUTES_GOOD_BOUND) {
        return HealthDataGrade.AVERAGE
      }
      return HealthDataGrade.GOOD

    case HealthDataType.ALCOHOL_SCORE:
      if (value < ALCOHOL_GOOD_BOUND) {
        return HealthDataGrade.GOOD
      }
      if (value < ALCOHOL_BAD_BOUND) {
        return HealthDataGrade.AVERAGE
      }
      return HealthDataGrade.BAD

    case HealthDataType.BLOOD_PRESSURE:
      if (value < BLOOD_PRESSURE_GOOD_BOUND) {
        return HealthDataGrade.GOOD
      }
      if (value < BLOOD_PRESSURE_BAD_BOUND) {
        return HealthDataGrade.AVERAGE
      }
      return HealthDataGrade.BAD

    case HealthDataType.BMI:
      if (value > BMI_BAD_BOUND) {
        return HealthDataGrade.BAD
      }
      if (value <= BMI_UPPER_GOOD_BOUND && value >= BMI_LOWER_GOOD_BOUND) {
        return HealthDataGrade.GOOD
      }
      return HealthDataGrade.AVERAGE

    case HealthDataType.BODY_FAT_PERCENTAGE: {
      return getFatGrade(value, user)
    }

    case HealthDataType.CHOLESTEROL_HDL_RATIO:
      if (value < HDL_GOOD_BOUND) {
        return HealthDataGrade.GOOD
      }
      if (value < HDL_BAD_BOUND) {
        return HealthDataGrade.AVERAGE
      }
      return HealthDataGrade.BAD

    case HealthDataType.WAIST_CIRCUMFERENCE_CM:
      return getWaistCircumferenceGrade(value, user)

    case HealthDataType.WATER_CONSUMPTION_ML:
      if (value >= WATER_CONSUMPTION_GOOD_BOUND) {
        return HealthDataGrade.GOOD
      }
      if (value > WATER_CONSUMPTION_BAD_BOUND) {
        return HealthDataGrade.AVERAGE
      }
      return HealthDataGrade.BAD

    case HealthDataType.RESTING_HEART_RATE:
      return getRestingHeartRateGrade(value, user)

    case HealthDataType.DIET_SCORE:
      if (value < EATING_HABITS_BAD_BOUND) {
        return HealthDataGrade.BAD
      }
      if (value < EATING_HABITS_GOOD_BOUND) {
        return HealthDataGrade.AVERAGE
      }
      return HealthDataGrade.GOOD

    case HealthDataType.SLEEP_SCORE:
      return value > SLEEP_BOUND ? HealthDataGrade.BAD : HealthDataGrade.GOOD

    case HealthDataType.QUALITY_SLEEP_SECONDS:
      if (value < QUALITY_SLEEP_SECONDS_BAD_BOUND) {
        return HealthDataGrade.BAD
      }
      if (value > QUALITY_SLEEP_SECONDS_GOOD_BOUND) {
        return HealthDataGrade.GOOD
      }
      return HealthDataGrade.AVERAGE

    case HealthDataType.MOOD_SCORE:
      if (value < MOOD_BAD_BOUND) {
        return HealthDataGrade.BAD
      }
      if (value < MOOD_GOOD_BOUND) {
        return HealthDataGrade.AVERAGE
      }
      return HealthDataGrade.GOOD

    case HealthDataType.HEART_AGE_YEARS:
      const userAge = calculateAgeFromDateOfBirth(user?.dateOfBirth)

      if (userAge == null) {
        return undefined
      }
      if (Math.abs(userAge - value) <= HEART_AGE_DIFF_BOUND) {
        return HealthDataGrade.AVERAGE
      }
      return value < userAge ? HealthDataGrade.GOOD : HealthDataGrade.BAD

    default:
      return undefined
  }
}

export const getTrendValue = (
  lastValue?: number | null,
  previousValue?: number | null,
) => {
  const isMissingData = lastValue == null || previousValue == null

  if (isMissingData || lastValue === previousValue) {
    return undefined
  }
  return lastValue > previousValue ? TrendValue.UP : TrendValue.DOWN
}

const INFINITY = 10 ** 10 // we can't use Infinity, because we need to compute the difference

const healthDataBestValue: Record<HealthDataType, number | undefined> = {
  [HealthDataType.ACTIVELY_BURNED_CALORIES]: INFINITY,
  [HealthDataType.ALCOHOL_SCORE]: 0,
  [HealthDataType.BLOOD_PRESSURE]: 0,
  [HealthDataType.BMI]: 21.75,
  [HealthDataType.BODY_FAT_PERCENTAGE]: 0,
  [HealthDataType.DIET_SCORE]: INFINITY,
  [HealthDataType.CHOLESTEROL_HDL_RATIO]: 0,
  [HealthDataType.HEART_AGE_YEARS]: 0,
  [HealthDataType.HEIGHT_CM]: undefined,
  [HealthDataType.MOOD_SCORE]: INFINITY,
  [HealthDataType.MOVEMENT_SCORE]: INFINITY,
  [HealthDataType.PEAK_FLOW]: 0,
  [HealthDataType.QUALITY_SLEEP_SECONDS]: INFINITY,
  [HealthDataType.RESTING_HEART_RATE]: 0,
  [HealthDataType.SLEEP_SCORE]: 0,
  [HealthDataType.STEPS]: INFINITY,
  [HealthDataType.TOBACCO_SCORE]: 0,
  [HealthDataType.VO2_MAX]: 0,
  [HealthDataType.ACTIVE_MINUTES]: INFINITY,
  [HealthDataType.WAIST_CIRCUMFERENCE_CM]: 0,
  [HealthDataType.WATER_CONSUMPTION_ML]: INFINITY,
  [HealthDataType.WEIGHT_KG]: 0,
}

export const getTrendGrade = (
  activityType: HealthDataType,
  lastValue: number | undefined,
  previousValue: number | undefined,
  patient?: BasicPatientData,
) => {
  const bestValue = healthDataBestValue[activityType]
  const isIncompleteData =
    lastValue == null || previousValue == null || bestValue == null

  if (isIncompleteData || lastValue === previousValue) {
    return undefined
  }

  const lastValueGrade = getHealthDataGrade(activityType, lastValue, patient)

  const isImproving =
    Math.abs(lastValue - bestValue) < Math.abs(previousValue - bestValue)

  switch (lastValueGrade) {
    case HealthDataGrade.GOOD:
      return isImproving ? HealthDataGrade.GOOD : HealthDataGrade.AVERAGE
    case HealthDataGrade.BAD:
      return isImproving ? HealthDataGrade.AVERAGE : HealthDataGrade.BAD
    default:
      return isImproving ? HealthDataGrade.GOOD : HealthDataGrade.BAD
  }
}

export const getHealthDataGrades = (
  healthDataType: HealthDataType,
  lastValue: number | undefined,
  previousValue: number | undefined,
  patient?: BasicPatientData,
) => {
  const grade = getHealthDataGrade(healthDataType, lastValue, patient)
  const trend = getTrendValue(lastValue, previousValue)

  const trendGrade = getTrendGrade(
    healthDataType,
    lastValue,
    previousValue,
    patient,
  )

  return {
    grade,
    trend,
    trendGrade,
  }
}

export const getTobaccoGrade = (tobaccoUsage?: TobaccoUsage | null) => {
  if (tobaccoUsage?.smoker == null) {
    return undefined
  }
  if (tobaccoUsage.smoker === IsSmoker.YES) {
    return HealthDataGrade.BAD
  }
  if (tobaccoUsage.smoker === IsSmoker.NEVER) {
    return HealthDataGrade.GOOD
  }

  const sixMonthsAgo = DateTime.local().minus({months: 6})
  const isNotSmokingSixMonths = tobaccoUsage.stoppedYearMonth
    ? DateTime.fromISO(tobaccoUsage.stoppedYearMonth) < sixMonthsAgo
    : false

  return isNotSmokingSixMonths ? HealthDataGrade.GOOD : HealthDataGrade.AVERAGE
}
