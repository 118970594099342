import styled from 'styled-components'

export type IconSize = 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl'
export type IconCircleSize = 'lg' | 'md' | 'sm' | 'xs'

type Icon = {
  clickable: boolean
  flip?: boolean
}

type CircledIcon = {
  circled: IconCircleSize
  circledBgd?: string
} & Icon

export const getIconSize = (size: IconCircleSize) => {
  switch (size) {
    case 'xs':
      return {
        width: '24px',
        height: '24px',
        // prevent squashing by neighbours
        'min-width': '24px',
        'min-height': '24px',
      }
    case 'sm':
      return {
        width: '32px',
        height: '32px',
        'min-width': '32px',
        'min-height': '32px',
      }
    case 'md':
      return {
        width: '40px',
        height: '40px',
        'min-width': '40px',
        'min-height': '40px',
      }
    default:
      return {
        width: '48px',
        height: '48px',
        'min-width': '48px',
        'min-height': '48px',
      }
  }
}

export const CircledBgd = styled.div<CircledIcon>`
  ${({circled}) => getIconSize(circled)};
  background-color: ${({circledBgd}) => circledBgd};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${({theme}) => theme.borderRadius.circled};
  ${({clickable}) => clickable && 'cursor: pointer'};
  ${({flip}) => flip && 'transform: scaleY(-1)'};
`

export const IconWrap = styled.span<Icon>`
  display: inline-flex;
  ${({clickable}) => clickable && 'cursor: pointer'};
  ${({flip}) => flip && 'transform: scaleY(-1)'};
`
