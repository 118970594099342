import styled from 'styled-components'

import {Select} from '~/components/general/select/Select'

type TableCellProps = {
  columnWidthPercent: number
}

export const FooterWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`

export const BoldText = styled.span`
  font-weight: ${({theme}) => theme.fontWeight.bold};
`

export const StyledSelect = styled(Select)`
  width: 35%;
`

export const SelectedPatientsTableWrapper = styled.div`
  min-height: 220px;
  margin: ${({theme}) => theme.spacing.lg} ${({theme}) => theme.spacing.md};
`

export const SelectedPatientsTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
`

export const SelectedPatientsTableRow = styled.tr`
  height: 48px;
  border-bottom: 1px solid ${({theme}) => theme.colors.grey.grey90};
`

export const SelectedPatientsTableCell = styled.td<TableCellProps>`
  padding-right: ${({theme}) => theme.spacing.sm};
  ${({columnWidthPercent}) =>
    columnWidthPercent && `width: ${columnWidthPercent}%`};
`

export const HalfWidthWrapper = styled.div`
  width: calc((100% - ${({theme}) => theme.spacing.lg}) / 2);
`
