import React, {useEffect} from 'react'
import styled from 'styled-components'

import {ComponentValue} from '../fieldConfig'

import {Typography} from '~/components/general/typography/Typography'
import {Container} from '~/components/general/container/Container'
import {StyledDashboardInnerCardTableCell} from '~/components/facility/GeneralDataCard.styled'

const CalculatedValue = styled.div`
  padding: ${({theme}) => `${theme.spacing.xxs} ${theme.spacing.md}`};
  border: 1px solid ${({theme}) => theme.colors.inputBorder};
  border-radius: ${({theme}) => theme.borderRadius.xs};
  background-color: ${({theme}) => theme.colors.grey.grey50};
`

export const CalculatedField: React.FC<{
  title: string
  currentData: ComponentValue[]
  calculation: (data: ComponentValue[]) => string | undefined
  onUpdate: (newData: ComponentValue[]) => void
}> = ({title, currentData, calculation, onUpdate}) => {
  const [value, setValue] = React.useState<string | undefined>(undefined)

  useEffect(() => {
    const newValue = calculation(currentData)
    setValue(newValue || '-')

    if (
      !currentData.some(
        (data) => data.name === title && data.value === newValue,
      )
    ) {
      onUpdate([{name: title, value: newValue}])
    }
  }, [currentData, setValue, onUpdate, calculation, title])

  return (
    <StyledDashboardInnerCardTableCell>
      <Container direction="column">
        <Typography weight="medium" size="sm">
          {title}
        </Typography>
        <CalculatedValue>
          <Typography size="lg">{value || '-'}</Typography>
        </CalculatedValue>
      </Container>
    </StyledDashboardInnerCardTableCell>
  )
}
