import {PractitionersPatient} from '@common/models'
import React from 'react'

import {PatientDetailsContainer} from './AddAppointmentModal.styled'

import {Avatar} from '~/components/general/avatar/Avatar'
import {Typography} from '~/components/general/typography/Typography'
import {IconButton} from '~/components/general/iconButton/IconButton'

interface PatientDetailsShortProps {
  patient: PractitionersPatient
  onClick?: (patient: PractitionersPatient) => void
  onRemove?: (patient: PractitionersPatient) => void
}

const PatientDetailsShort: React.FC<PatientDetailsShortProps> = ({
  patient,
  onClick,
  onRemove,
}) => (
  <PatientDetailsContainer onClick={onClick && (() => onClick(patient))}>
    <Avatar size="sm" name={patient} patientId={patient.patientId} />
    <PatientDetailsContainer>
      <PatientDetailsContainer>
        <Typography size="md" weight="medium">
          {patient.firstName} {patient.secondName}
        </Typography>{' '}
        <Typography size="sm" color="grey" flexShrink={0}>
          {patient.dateOfBirth}
        </Typography>
      </PatientDetailsContainer>
      {onRemove && (
        <IconButton
          name="Close"
          color="secondary"
          onClick={() => onRemove(patient)}
        />
      )}
    </PatientDetailsContainer>
  </PatientDetailsContainer>
)

export default PatientDetailsShort
