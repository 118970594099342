import {HealthDataType} from '@common/models'

export type EditableTrendTypes = Extract<
  HealthDataType,
  | HealthDataType.BLOOD_PRESSURE
  | HealthDataType.RESTING_HEART_RATE
  | HealthDataType.WEIGHT_KG
  | HealthDataType.HEIGHT_CM
  | HealthDataType.WAIST_CIRCUMFERENCE_CM
  | HealthDataType.BODY_FAT_PERCENTAGE
  | HealthDataType.CHOLESTEROL_HDL_RATIO
  | HealthDataType.QUALITY_SLEEP_SECONDS // TODO: IS THIS CORRECT?
  | HealthDataType.ACTIVE_MINUTES
  | HealthDataType.STEPS
  | HealthDataType.WATER_CONSUMPTION_ML
  | HealthDataType.ALCOHOL_SCORE
  | HealthDataType.TOBACCO_SCORE
  | HealthDataType.VO2_MAX
  | HealthDataType.PEAK_FLOW
  // TODO: NEED TO ADD HB1AC IN HERE.
>

export const isEditableTrendType = (
  type: HealthDataType,
): type is EditableTrendTypes =>
  [
    HealthDataType.BLOOD_PRESSURE,
    HealthDataType.RESTING_HEART_RATE,
    HealthDataType.WEIGHT_KG,
    HealthDataType.HEIGHT_CM,
    HealthDataType.WAIST_CIRCUMFERENCE_CM,
    HealthDataType.BODY_FAT_PERCENTAGE,
    HealthDataType.CHOLESTEROL_HDL_RATIO,
    HealthDataType.QUALITY_SLEEP_SECONDS,
    HealthDataType.ACTIVE_MINUTES,
    HealthDataType.STEPS,
    HealthDataType.WATER_CONSUMPTION_ML,
    HealthDataType.ALCOHOL_SCORE,
    HealthDataType.TOBACCO_SCORE,
    HealthDataType.VO2_MAX,
    HealthDataType.PEAK_FLOW,
  ].includes(type)
