import React, {useMemo} from 'react'
import {isValidMultiChoiceAnswer} from '@common/utils'

import {
  QuestionnairePreviewCard,
  QuestionnairePreviewAnswerWrap,
} from './QuestionnairePreview.styled'
import {QuestionProps} from './QuestionCard'

import {Typography} from '~/components/general/typography/Typography'
import {Checkbox} from '~/components/general/checkbox/Checkbox'

export const MultiChoiceQuestion: React.FC<QuestionProps> = ({
  question,
  answer,
}) => {
  const answerArray = useMemo(() => {
    if (!answer || !isValidMultiChoiceAnswer(question, answer)) {
      return undefined
    }

    return answer
  }, [answer, question])

  return (
    <QuestionnairePreviewCard>
      <Typography weight="medium">{question.title}</Typography>
      <Typography>{question.subtitle}</Typography>
      <QuestionnairePreviewAnswerWrap>
        {question.choices?.map((choice, index) => (
          <Checkbox
            label={choice.label}
            checked={answerArray?.[index]}
            key={choice.id}
          />
        ))}
      </QuestionnairePreviewAnswerWrap>
    </QuestionnairePreviewCard>
  )
}
