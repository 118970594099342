/* eslint-disable default-param-last */

import {
  QueryKey,
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
} from '@tanstack/react-query'
import {DateTime} from 'luxon'

import {getApiRequestError, objectToForm} from '../utils'
import {GeneralPagedReponse, NetworkError, UploadFile} from '../models'

export type Header = {
  Authorization?: string
  'App-Version'?: string
  'Content-Type'?: string
}

export type MutationRequestMethod = 'POST' | 'PUT' | 'PATCH' | 'DELETE'

export type MultiPartMutationParams<RequestType> = {
  body: RequestType
  file?: UploadFile | null
  clearFile?: boolean
}

export type ResponseNormaliserFn<ResponseType> = unknown extends ResponseType
  ? undefined
  : (data: ResponseType) => ResponseType

const tryFetch = async (url: string, init?: RequestInit) => {
  try {
    return await fetch(url, init)
  } catch (e) {
    const message =
      e instanceof Error
        ? e.message
        : 'API query failed due to a network error.'
    return Promise.reject(new NetworkError(message))
  }
}

export const isFormData = (object: unknown): object is FormData =>
  typeof object === 'object' &&
  object !== null &&
  object.constructor === FormData

export const getContentType = (object: unknown) =>
  isFormData(object) ? 'multipart/form-data' : 'application/json'

export const getMutationRequestBody = (requestBody: unknown) => {
  if (!requestBody) {
    return undefined
  }

  return isFormData(requestBody) ? requestBody : JSON.stringify(requestBody)
}

const parseResponseBody = async (response: Response) => {
  const contentLengthHeader = response.headers.get('content-length')
  const contentLength = contentLengthHeader && parseInt(contentLengthHeader, 10)

  if (contentLength === 0 || response.status === 204) {
    return null
  }

  const isResponseJSON = response.headers
    .get('content-type')
    ?.startsWith('application/json')

  return isResponseJSON ? response.json() : response.text()
}

const parseResponse = async <T>(
  response: Response,
  normaliser: (data: T) => T = (data) => data,
) => normaliser(await parseResponseBody(response))

export const getFetchFn =
  <T>(
    getHeader: () => Promise<Header>,
    endpoint: URL,
    normaliser: (data: T) => T = (data) => data,
  ) =>
  async () => {
    const headers = await getHeader()
    const response = await tryFetch(endpoint.href, {
      method: 'GET',
      headers,
    })

    if (!response.ok) {
      return Promise.reject(
        await getApiRequestError(response, endpoint.href, headers),
      )
    }

    return parseResponse(response, normaliser)
  }

export const useGet = <T>(
  getHeader: () => Promise<Header>,
  key: QueryKey,
  endpoint: URL,
  normaliser: (data: T) => T,
  options: UseQueryOptions<T, Error, T> = {},
) =>
  useQuery<T, Error>(key, getFetchFn(getHeader, endpoint, normaliser), {
    retry: false,
    ...options,
  })

export const useInfiniteGet = <T extends GeneralPagedReponse>(
  getHeader: () => Promise<Header>,
  key: QueryKey,
  endpoint: URL,
  normaliser: (data: T) => T,
  options: UseInfiniteQueryOptions<T, Error, T> = {},
) =>
  useInfiniteQuery<T, Error>(
    [...key, 'infinite'], // to prevent conflicts with regular queries with same endpointKey
    async ({pageParam = 0}) => {
      endpoint.searchParams.set('page', pageParam.toString())

      const headers = await getHeader()
      const response = await tryFetch(endpoint.href, {
        method: 'GET',
        headers,
      })

      if (!response.ok) {
        return Promise.reject(
          await getApiRequestError(response, endpoint.href, headers),
        )
      }

      return parseResponse(response, normaliser)
    },
    {
      retry: false,
      getNextPageParam: ({page}) => page.next ?? undefined,
      ...options,
    },
  )

type TimeSlotData<T> = T & {
  next?: string
  previous?: string
}

const getPreviousPageParamDate = (
  pageParamDate: DateTime,
  zone: string | undefined,
) => {
  const currentDay = DateTime.local({zone})
  if (currentDay.toISODate() === pageParamDate.toISODate()) {
    return undefined
  }

  const previousWeekDate = pageParamDate.minus({days: 7})
  return previousWeekDate > currentDay ? previousWeekDate : currentDay
}

export const useInfiniteTimeSlotGet = <T>(
  getHeader: () => Promise<Header>,
  key: QueryKey,
  endpoint: URL,
  normaliser: (data: T) => T,
  initialDate: string,
  timeZone: string | undefined,
  options: UseInfiniteQueryOptions<
    TimeSlotData<T>,
    Error,
    TimeSlotData<T>
  > = {},
) =>
  useInfiniteQuery<TimeSlotData<T>, Error>(
    key,
    async ({pageParam = initialDate}) => {
      const pageParamDate = DateTime.fromISO(pageParam)

      endpoint.searchParams.set('from', pageParam)
      endpoint.searchParams.set('to', pageParamDate.plus({days: 6}).toISODate())

      const headers = await getHeader()
      const response = await tryFetch(endpoint.href, {
        method: 'GET',
        headers,
      })

      if (!response.ok) {
        return Promise.reject(
          await getApiRequestError(response, endpoint.href, headers),
        )
      }

      return {
        ...(await parseResponse(response, normaliser)),
        next: pageParamDate.plus({days: 7}).toISODate(),
        previous: getPreviousPageParamDate(
          pageParamDate,
          timeZone,
        )?.toISODate(),
      }
    },
    {
      cacheTime: 0,
      retry: false,
      getNextPageParam: (data) => data.next,
      getPreviousPageParam: (data) => data.previous,
      ...options,
    },
  )

export const useSet = <RequestType, ResponseType>(
  getHeader: () => Promise<Header>,
  key: QueryKey,
  endpoint: URL,
  method: MutationRequestMethod,
  normaliser: ResponseNormaliserFn<ResponseType>,
  options: UseMutationOptions<ResponseType, Error, RequestType> = {},
  invalidationKeys: QueryKey[] = [],
) => {
  const client = useQueryClient()

  return useMutation(
    key,
    async (data: RequestType) => {
      const authHeaders = await getHeader()

      const headers = {
        ...authHeaders,
        'Content-Type': getContentType(data),
      }

      const response = await tryFetch(endpoint.href, {
        method,
        headers,
        body: getMutationRequestBody(data),
      })

      if (!response.ok) {
        return Promise.reject(
          await getApiRequestError(response, endpoint.href, headers),
        )
      }

      return parseResponse(response, normaliser)
    },
    {
      onSuccess: () => {
        invalidationKeys.forEach((_key) => client.invalidateQueries(_key)) // typescript cries on invalidationKeys.forEach(client.invalidateQueries)
      },
      ...options,
    },
  )
}

export const useMultiPartFormSet = <
  RequestType extends Object,
  ResponseType = unknown,
>(
  getHeader: () => Promise<Header>,
  key: QueryKey,
  endpoint: URL,
  method: MutationRequestMethod,
  normaliser: ResponseNormaliserFn<ResponseType>,
  options: UseMutationOptions<
    ResponseType,
    Error,
    MultiPartMutationParams<RequestType>
  > = {},
  invalidationKeys: QueryKey[] = [],
) => {
  const client = useQueryClient()

  return useMutation(
    key,
    async ({
      body,
      file,
      clearFile = false,
    }: MultiPartMutationParams<RequestType>) => {
      const authHeaders = await getHeader()
      const headers = {
        ...authHeaders,
        'Content-Type': 'multipart/form-data',
      }

      const formData = objectToForm({
        body: {
          // we have to do it like this in order to assign different Content-Type to FormData parts - https://stackoverflow.com/a/55787979
          string: JSON.stringify(body),
          type: 'application/json',
        },
        file,
        clearFile: {
          string: JSON.stringify(clearFile),
          type: 'application/json',
        },
      })

      const response = await tryFetch(endpoint.href, {
        method,
        headers,
        body: formData,
      })

      if (!response.ok) {
        return Promise.reject(
          await getApiRequestError(response, endpoint.href, headers),
        )
      }

      return parseResponse(response, normaliser)
    },
    {
      onSuccess: () => {
        invalidationKeys.forEach((_key) => client.invalidateQueries(_key)) // typescript cries on invalidationKeys.forEach(client.invalidateQueries)
      },
      ...options,
    },
  )
}

export const useVariableUrlSet = <
  UrlParams extends Record<string, string | boolean | null>,
  RequestType extends Record<string, string | null>,
  ResponseType,
>(
  getHeader: () => Promise<Header>,
  key: QueryKey,
  getEndpoint: (params: UrlParams) => URL,
  method: MutationRequestMethod,
  normaliser: ResponseNormaliserFn<ResponseType>,
  options: UseMutationOptions<
    ResponseType,
    Error,
    {urlParams: UrlParams; requestBody?: RequestType}
  > = {},
  invalidationKeys: QueryKey[] = [],
) => {
  const client = useQueryClient()

  return useMutation(
    key,
    async ({
      urlParams,
      requestBody = {} as RequestType,
    }: {
      urlParams: UrlParams
      requestBody?: RequestType
    }) => {
      const endpoint = getEndpoint(urlParams)
      const authHeaders = await getHeader()

      const headers = {
        ...authHeaders,
        'Content-Type': getContentType(requestBody),
      }

      const response = await tryFetch(endpoint.href, {
        method,
        headers,
        body: getMutationRequestBody(requestBody),
      })

      if (!response.ok) {
        return Promise.reject(
          await getApiRequestError(response, endpoint.href, headers),
        )
      }

      return parseResponse(response, normaliser)
    },
    {
      onSuccess: () => {
        invalidationKeys.forEach((_key) => client.invalidateQueries(_key)) // typescript cries on invalidationKeys.forEach(client.invalidateQueries)
      },
      ...options,
    },
  )
}
