import {useTranslation} from 'react-i18next'
import React, {useContext, useMemo, useState} from 'react'
import {
  AssignedQuestionnaireInfo,
  PagedResponse,
  QuestionnaireStatus,
} from '@common/models'
import {MISSING_ID} from '@common/utils'
import {QuestionnaireOrder} from '@common/api/types'

import {PractitionerContext} from '../profile/PractitionerContext'
import {LayoutContainer} from '../layout/container/LayoutContainer'

import {Container} from '~/components/general/container/Container'
import {Banner} from '~/components/general/banner/Banner'
import {SimplePaginatedTable} from '~/components/general/table/SimplePaginatedTable'
import {Loading} from '~/components/general/loading/Loading'
import {useGetAssignedQuestionnaires} from '~/api'
import {SimpleTableHeaderProps} from '~/components/general/table/SimpleTableHeader'
import {SimpleTableRowProps} from '~/components/general/table/SimpleTableRow'

const RESPONSES_PER_TABLE_PAGE = 10

type AssignedQuestionnairesProps = {
  headers: SimpleTableHeaderProps
  getRows: (
    data?: PagedResponse<AssignedQuestionnaireInfo>,
  ) => SimpleTableRowProps[]
  orderBy?: QuestionnaireOrder
  questionnaireId?: string
  questionnaireStatus?: QuestionnaireStatus
  showBanner?: boolean
}

export const AssignedQuestionnaires: React.FC<AssignedQuestionnairesProps> = ({
  headers,
  getRows,
  orderBy,
  questionnaireId,
  questionnaireStatus,
  showBanner,
}) => {
  const {t} = useTranslation()
  const {selectedEmployment} = useContext(PractitionerContext)
  const [currentPageIndex, setCurrentPageIndex] = useState(0)

  const {data, isInitialLoading} = useGetAssignedQuestionnaires({
    questionnaireStatus,
    questionnaireId,
    facilityId: selectedEmployment?.facilityId ?? MISSING_ID,
    orderBy,
    page: currentPageIndex,
    perPage: RESPONSES_PER_TABLE_PAGE,
  })

  const tableRows = useMemo(() => getRows(data), [data, getRows])

  if (isInitialLoading) {
    return (
      <LayoutContainer>
        <Loading />
      </LayoutContainer>
    )
  }

  return (
    <LayoutContainer>
      <Container direction="column" spacing={3}>
        {showBanner && (
          <Banner
            title={t(
              'Track treatment’s progress and get feedback from your patients',
            )}
            closable
            content={t(
              'Use pre-existing templates (like Patient-reported Outcome Measures) to track their symptoms during treatments or create your own surveys to send before or after the appointments.',
            )}
          />
        )}
        <SimplePaginatedTable
          header={headers}
          rows={tableRows}
          onPageClick={setCurrentPageIndex}
          paginationData={data?.page}
        />
      </Container>
    </LayoutContainer>
  )
}
