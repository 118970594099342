import styled from 'styled-components'

import {Chip} from '~/components/general/chip/Chip'
import {Typography} from '~/components/general/typography/Typography'

export const StyledHeaderText = styled(Typography)`
  margin: 0 ${({theme}) => theme.spacing.xs} ${({theme}) => theme.spacing.md} 0;
`

export const ContentColumn = styled.div`
  width: 50%;
`

export const StyledChip = styled(Chip)`
  margin: ${({theme}) => theme.spacing.xs} 0 ${({theme}) => theme.spacing.md} 0;
`

export const RowHeader = styled(Typography)`
  margin-top: ${({theme}) => theme.spacing.lg};
`

export const StyledTable = styled.table`
  width: 100%;
`

export const StyledButton = styled.button`
  background-color: ${({theme}) => theme.colors.white};
  ${({disabled}) => disabled && 'opacity: 0.5'};
  height: 80px;
  display: flex;
  width: 100%;
  align-items: center;
  font-size: ${({theme}) => theme.fontSize.md};
  border: solid 1px ${({theme}) => theme.colors.grey.grey300};
  border-radius: ${({theme}) => theme.borderRadius.xxs};
  padding: 0 10px;
  cursor: ${({disabled, onClick}) =>
    disabled || !onClick ? 'default' : 'pointer'};
  transition: background-color 350ms;
  margin-bottom: 10px;
  text-align: left;
  &:hover {
    background-color: ${({disabled, onClick, theme}) =>
      !disabled && onClick && theme.colors.grey.grey150};
  }
  & > * {
    margin-right: ${({theme}) => theme.spacing.xs};
  }
  & > *:last-child {
    margin-right: 0;
  }
`
