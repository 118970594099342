import React, {ReactNode} from 'react'

import {BaseModal} from './BaseModal'

import {
  ModalHeader,
  ModalContent,
  ModalFooter,
} from '~/components/general/modal/Modal.styled'
import {Typography} from '~/components/general/typography/Typography'
import {Icon} from '~/components/general/icon/Icon'

type ModalProps = {
  children: React.ReactNode
  header: string
  footer: string | ReactNode
  actionButtons?: ReactNode
  onClose: () => void
  isVisible: boolean
}

export const Modal: React.FC<ModalProps> = ({
  header,
  children,
  footer,
  actionButtons,
  onClose,
  isVisible,
}) => (
  <BaseModal isVisible={isVisible} onClose={onClose}>
    {header && (
      <ModalHeader>
        <Typography size="lg" weight="medium">
          {header}
        </Typography>
        <Icon onClick={onClose} circled="md" name="Close" size="sm" />
      </ModalHeader>
    )}
    <ModalContent>{children}</ModalContent>
    {footer && (
      <ModalFooter>
        {footer}
        {actionButtons}
      </ModalFooter>
    )}
  </BaseModal>
)
