import * as React from 'react'

import {IconProps} from '.'

function TriangleUp({color, isFilled}: IconProps) {
  return (
    <svg
      width={8}
      height={5}
      viewBox="0 0 8 5"
      fill={isFilled ? color : 'none'}
      xmlns="http://www.w3.org/2000/svg">
      <path d="M8 5L4.76837e-07 5L4 5.79005e-07L8 5Z" fill={color} />
    </svg>
  )
}

export default TriangleUp
