import styled from 'styled-components'

type Avatar = {
  size: string
  default?: boolean
}

export const StyledAvatar = styled.div<Avatar>`
  height: ${({size}) => size};
  width: ${({size}) => size};
  background-color: ${({theme}) => theme.colors.grey.grey40};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${({theme}) => theme.borderRadius.circled};
  overflow: hidden;
  flex-shrink: 0;
`
