import {PractitionersPatient} from '@common/models'
import {DEBOUNCE_TIME, MISSING_ID} from '@common/utils'
import React, {useContext, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useDebouncedCallback} from 'use-debounce'

import {useAddPatientForFacility} from '~/api'
import {Button} from '~/components/general/button/Button'
import {Container} from '~/components/general/container/Container'
import {Input} from '~/components/general/input/Input'
import {Typography} from '~/components/general/typography/Typography'
import {
  NotificationContext,
  NotificationType,
} from '~/components/notifications/NotificationContext'
import {PractitionerContext} from '~/components/profile/PractitionerContext'

export type AddPatientCardProps = {
  onPatientCreated: (patient: PractitionersPatient) => void
  onCancel: () => void
}

const AddPatientCard: React.FC<AddPatientCardProps> = ({
  onPatientCreated,
  onCancel,
}) => {
  const {t} = useTranslation()
  const {selectedEmployment} = useContext(PractitionerContext)
  const {addNotification} = useContext(NotificationContext)
  const [email, setEmail] = useState('')
  const [isValidEmail, setIsValidEmail] = useState(true)
  const [firstName, setFirstName] = useState('')
  const [secondName, setSecondName] = useState('')
  const [dateOfBirth, setDateOfBirth] = useState('')
  const {mutate} = useAddPatientForFacility({
    facilityId: selectedEmployment?.facilityId || MISSING_ID,
  })

  const validateEmail = (emailString: string) => {
    if (!emailString?.trim()) {
      setIsValidEmail(true)
      return
    }
    const re = /\S+@\S+\.\S+/
    setIsValidEmail(re.test(emailString))
  }

  const debounced = useDebouncedCallback((value) => {
    validateEmail(value)
  }, DEBOUNCE_TIME)

  const handleEmailChange = (newValue: string) => {
    setEmail(newValue)
    debounced(newValue)
  }

  const handleAddPatient = () => {
    mutate(
      {
        email,
        firstName,
        secondName,
        dateOfBirth,
      },
      {
        onSuccess: (data) => onPatientCreated(data),
        onError: () =>
          addNotification('Failed to save data', NotificationType.Error),
      },
    )
  }

  const canAddPatient =
    isValidEmail &&
    email.trim() &&
    firstName.trim() &&
    secondName.trim() &&
    dateOfBirth.trim()

  return (
    <Container flex direction="column" spacing={2}>
      <Container flex direction="column">
        <Typography size="md">{t('Email')}</Typography>
        <Input
          type="email"
          value={email}
          size="small"
          errorMessage={!isValidEmail ? t('Invalid email') : undefined}
          onChange={(e) => handleEmailChange(e.target.value)}
        />
      </Container>
      <Container flex direction="column">
        <Typography size="md">{t('First Name')}</Typography>
        <Input
          type="text"
          value={firstName}
          size="small"
          onChange={(e) => setFirstName(e.target.value)}
        />
      </Container>
      <Container flex direction="column">
        <Typography size="md">{t('Last Name')}</Typography>
        <Input
          type="text"
          value={secondName}
          size="small"
          onChange={(e) => setSecondName(e.target.value)}
        />
      </Container>
      <Container>
        <Typography size="md">{t('Date of Birth')}</Typography>
        <Input
          type="date"
          value={dateOfBirth}
          size="small"
          onChange={(e) => setDateOfBirth(e.target.value)}
        />
      </Container>
      <Container flex direction="row" justify="flex-end">
        <Button onClick={onCancel} color="primary" size="sm">
          {t('Cancel')}
        </Button>
        <Button onClick={handleAddPatient} disabled={!canAddPatient} size="sm">
          {t('Add Patient')}
        </Button>
      </Container>
    </Container>
  )
}

export default AddPatientCard
