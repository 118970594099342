import {AssertNullableTypeFn, AssertTypeFn, dateNormaliser} from './general'
import {
  CaresForOption,
  HomeSharedWithOption,
  HomeType,
  LifeMilestone,
  LifeMilestoneImpact,
  LifeMilestoneSource,
  LifeMilestonesSummary,
  LifeMilestonesSummaryCounts,
  LifeMilestoneType,
  LivingSituation,
  MaritalStatus,
  PersonalInformation,
  PersonalNetwork,
} from '../models'
import {
  assertArrayOrNull,
  assertBoolean,
  assertEnum,
  assertEnumOrNull,
  assertNumberOrNull,
  assertObjectOrNull,
  assertObjectOrUndefined,
  assertString,
  assertStringOrNull,
  getAssertEnum,
} from '../utils'

export const livingSituationNormaliser: AssertNullableTypeFn<
  LivingSituation
> = (obj) => ({
  homeType: assertEnumOrNull(obj.homeType, HomeType),
  homeSharedWith: assertArrayOrNull(
    obj.homeSharedWith,
    getAssertEnum(HomeSharedWithOption),
  ),
})

export const personalNetworkNormaliser: AssertNullableTypeFn<
  PersonalNetwork
> = (obj) => ({
  caresFor: assertArrayOrNull(obj.caresFor, getAssertEnum(CaresForOption)),
  children: assertArrayOrNull(obj.children, assertString),
  maritalStatus: assertEnumOrNull(obj.maritalStatus, MaritalStatus),
  spouseName: assertStringOrNull(obj.spouseName),
  yearsOfMarriage: assertNumberOrNull(obj.yearsOfMarriage),
})

export const personalInformationNormaliser: AssertTypeFn<
  PersonalInformation
> = (obj) => ({
  livingSituation: assertObjectOrUndefined(
    obj.livingSituation,
    livingSituationNormaliser,
  ),
  personalNetwork: assertObjectOrUndefined(
    obj.personalNetwork,
    personalNetworkNormaliser,
  ),
})

export const personalInformationOrNullNormaliser: AssertTypeFn<
  PersonalInformation | null
> = (obj) => assertObjectOrNull(obj, personalInformationNormaliser)

export const lifeMilestoneNormaliser: AssertTypeFn<LifeMilestone> = (obj) => ({
  date: dateNormaliser(obj.date),
  description: assertStringOrNull(obj.description),
  hideFromPatient: assertBoolean(obj.hideFromPatient),
  id: assertString(obj.id),
  impact: assertEnumOrNull(obj.impact, LifeMilestoneImpact),
  milestone: assertStringOrNull(obj.milestone),
  source: assertEnum(obj.source, LifeMilestoneSource),
  type: assertEnum(obj.type, LifeMilestoneType),
})

const lifeMilestonesSummaryCountsNormaliser: AssertTypeFn<
  LifeMilestonesSummaryCounts
> = (obj) => ({
  [LifeMilestoneType.CHILD_LEAVING_HOME]: assertNumberOrNull(
    obj[LifeMilestoneType.CHILD_LEAVING_HOME],
  ),
  [LifeMilestoneType.DEATH_OF_A_LOVED_ONE]: assertNumberOrNull(
    obj[LifeMilestoneType.DEATH_OF_A_LOVED_ONE],
  ),
  [LifeMilestoneType.DIVORCE_BREAK_UP]: assertNumberOrNull(
    obj[LifeMilestoneType.DIVORCE_BREAK_UP],
  ),
  [LifeMilestoneType.MARRIAGE]: assertNumberOrNull(
    obj[LifeMilestoneType.MARRIAGE],
  ),
  [LifeMilestoneType.NEW_BABY]: assertNumberOrNull(
    obj[LifeMilestoneType.NEW_BABY],
  ),
  [LifeMilestoneType.NEW_HOME]: assertNumberOrNull(
    obj[LifeMilestoneType.NEW_HOME],
  ),
  [LifeMilestoneType.NEW_JOB]: assertNumberOrNull(
    obj[LifeMilestoneType.NEW_JOB],
  ),
  [LifeMilestoneType.PREGNANCY_END_WITHOUT_BABY]: assertNumberOrNull(
    obj[LifeMilestoneType.PREGNANCY_END_WITHOUT_BABY],
  ),
  [LifeMilestoneType.OTHER]: assertNumberOrNull(obj[LifeMilestoneType.OTHER]),
})

export const lifeMilestonesSummaryNormaliser: AssertTypeFn<
  LifeMilestonesSummary
> = (obj) => ({
  counts: lifeMilestonesSummaryCountsNormaliser(obj.counts),
})
