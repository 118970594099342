import styled from 'styled-components'

import {Select} from '~/components/general/select/Select'

export const StyledMaterialsSelect = styled.div`
  margin-top: ${({theme}) => theme.spacing.xxl};
`

export const MaterialsSelectWrapper = styled.div`
  max-width: 450px;
  display: flex;
  align-items: center;
  margin-bottom: 72px;
`

export const StyledSelect = styled(Select)`
  & > div:first-child > div {
    border-radius: 0;

    & > *:first-child {
      padding: 0;
      border-bottom: 2px dotted ${({theme}) => theme.colors.grey.main};
    }
  }

  span {
    left: 0;
    right: 0;
    padding-right: 0;
    font-size: 40px;
    line-height: 50px;
    font-weight: 400;
    color: ${({theme}) => theme.colors.primary.main};
  }

  input {
    font-size: 40px;
  }
`
