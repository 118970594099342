export type UploadFile = {
  name: string
  uri: string
  type: string
}

export type MedicalRecordUploadRequest = {
  facilityId: string | null
  file: UploadFile
  specialization: string | null
}

export type CombineFilesToPdfRequest = {
  filename: string
  files: UploadFile[]
  note: string | null
  facilityId: string | null
  department: string | null
}

export type {
  AzureStorageFileDto as StaticFile,
  FileUpload as Upload,
  PregnancyGetFileDto as PregnancyJourneyFile,
  ObstetricsFileDto as ObstetricsFile,
  GetAppointmentFileDto as AppointmentFile,
} from '@generated/models'

export {
  PatientReportConfirmation as PatientReportConfirmationStatus,
  UploadStatus,
  UploadType,
} from '@generated/models'
