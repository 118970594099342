import React from 'react'
import {useTranslation} from 'react-i18next'

import {
  StyledPregnancyInnerCardTable,
  StyledPregnancyInnerCardTableCell,
  StyledPregnancyCard,
  StyledPregnancyInnerCardTableBody,
} from './PregnancyCard.styled'
import {Typography} from '../../general/typography/Typography'
import {ExpandableText} from '../../general/expandableText/ExpandableText'

import {Container} from '~/components/general/container/Container'
import {theme} from '~/styles/theme'

export type PregnancyCardRowProps = {
  title: string
  value: React.ReactNode
}

const PregnancyCardItem = ({title, value}: PregnancyCardRowProps) => (
  <>
    <StyledPregnancyInnerCardTableCell>
      <Typography weight="medium">{title}</Typography>
    </StyledPregnancyInnerCardTableCell>
    <StyledPregnancyInnerCardTableCell>
      {value}
    </StyledPregnancyInnerCardTableCell>
  </>
)

const PregnancyCardNotes = ({notes}: {notes: string}) => {
  const {t} = useTranslation()
  return (
    <>
      <StyledPregnancyInnerCardTableCell>
        <Typography weight="medium">{t('Notes')}</Typography>
      </StyledPregnancyInnerCardTableCell>
      <StyledPregnancyInnerCardTableCell colSpan={3}>
        <Typography>
          <ExpandableText text={notes} shortenedLength={400} />
        </Typography>
      </StyledPregnancyInnerCardTableCell>
    </>
  )
}

type PregnancyCardProps = {
  title: string
  date: string
  rows: PregnancyCardRowProps[]
  notes?: string
}

const pairItems = <T,>(originalArray: Array<T>): Array<Array<T>> =>
  originalArray.reduce<Array<Array<T>>>((prev, curr, index) => {
    if (index % 2 === 0) {
      prev.push([curr])
    } else {
      prev[prev.length - 1].push(curr)
    }

    return prev
  }, [])

export const PregnancyCard = ({
  title,
  date,
  rows,
  notes,
}: PregnancyCardProps) => (
  <StyledPregnancyCard>
    <Container flex direction="column" spacing={1.5}>
      <Container flex justify="space-between" spacing={2} align="center">
        <Container flex direction="row">
          <Typography
            size="xl"
            weight="medium"
            color={theme.colors.secondary.main}>
            {title}
          </Typography>
        </Container>
        <Container>
          <Typography
            size="md"
            weight="medium"
            color={theme.colors.secondary.main}>
            {date}
          </Typography>
        </Container>
      </Container>
      <StyledPregnancyInnerCardTable>
        <StyledPregnancyInnerCardTableBody>
          {pairItems(rows).map((items, index) => (
            <tr key={index}>
              {items.map((item) => (
                <PregnancyCardItem {...item} key={item.title} />
              ))}
            </tr>
          ))}
          {notes && (
            <tr>
              <PregnancyCardNotes notes={notes} />
            </tr>
          )}
        </StyledPregnancyInnerCardTableBody>
      </StyledPregnancyInnerCardTable>
    </Container>
  </StyledPregnancyCard>
)
