import React from 'react'

import {SimpleTableProps} from './SimpleTable'
import {SimpleTableHeader} from './SimpleTableHeader'
import {SimpleTableRow} from './SimpleTableRow'
import {Table} from './Table'
import {TablePaginationProps} from './TablePagination'
import {getHasRowParsedData} from './utils'

export type SimplePaginatedTableProps = SimpleTableProps & TablePaginationProps

export const SimplePaginatedTable: React.FC<SimplePaginatedTableProps> = ({
  rows,
  showSourceIcons,
  onPageClick,
  header,
  hideUnusedPagination,
  isLoading,
  paginationData,
}) => (
  <Table
    paginationData={paginationData}
    onPageClick={onPageClick}
    isLoading={isLoading}
    loaderColSpan={header.columns.length}
    hideUnusedPagination={hideUnusedPagination}
    rows={rows.map((row, index) => (
      <SimpleTableRow
        onRowClick={row.onRowClick}
        columns={row.columns}
        hasParsedData={getHasRowParsedData(showSourceIcons, row.hasParsedData)}
        key={index}
      />
    ))}
    header={
      <SimpleTableHeader
        columns={header.columns}
        showSourceIcons={showSourceIcons}
      />
    }
  />
)
