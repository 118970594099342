import {MaterialsItem} from '@common/models'
import React, {useContext} from 'react'

import {MaterialsContext} from '../MaterialsContext'
import {
  SectionTitle,
  SelectedMaterial,
  StyledSection,
} from './MaterialsSideBar.styled'

import {theme} from '~/styles/theme'

type MaterialsSelectionSectionProps = {
  selection: MaterialsItem[]
  title: string
}

export const MaterialsSelectionSection: React.FC<
  MaterialsSelectionSectionProps
> = ({selection, title}) => {
  const {removeItem} = useContext(MaterialsContext)

  if (!selection.length) {
    return null
  }

  return (
    <StyledSection>
      <SectionTitle size="sm" color={theme.colors.grey.main}>
        {title}
      </SectionTitle>
      {selection.map((item) => (
        <SelectedMaterial
          key={item.id}
          item={item}
          onIconClick={() => removeItem(item)}
          iconName="Close"
        />
      ))}
    </StyledSection>
  )
}
