import {TFunction} from 'i18next'

import {formatExactOrEstimatedDate} from './dates'
import {ExactOrEstimatedDate, RecordType, Surgery, Medication} from '../models'

export const formatMedicationEndDate = (
  t: TFunction,
  endDate?: ExactOrEstimatedDate | null,
) =>
  endDate
    ? t('Taken until {{date}}', {date: formatExactOrEstimatedDate(endDate)})
    : t('Currently taking')

export const formatSurgeryTitle = (surgery: Surgery, t: TFunction) => {
  if (surgery.recordType === RecordType.PARSED) {
    return surgery.description
  }
  if (surgery.type?.value.label) {
    return surgery.type?.value.label
  }
  if (surgery.description) {
    return surgery.description
  }
  return t('Missing surgery description')
}

// This always returns an array because parsed medication can contain multiple drugs
export const formatMedicationTitle = (
  medicationItem: Medication,
  t: TFunction,
): string[] => {
  if (medicationItem.recordType === RecordType.PARSED) {
    return medicationItem.drugs
  }
  if (medicationItem.drug) {
    return [medicationItem.drug.tradeName]
  }
  if (medicationItem.name) {
    return [medicationItem.name]
  }
  return [t('Missing medication name')]
}

export const formatConditionStatus = (
  t: TFunction,
  endDate?: ExactOrEstimatedDate | null,
) => {
  if (!endDate) {
    return t('Active')
  }
  return endDate.isEstimated
    ? t('Ended in {{date}}', {date: formatExactOrEstimatedDate(endDate)})
    : t('Ended on {{date}}', {date: formatExactOrEstimatedDate(endDate)})
}

export const formatBloodType = (value: string | null | undefined) => {
  if (!value) {
    return null
  }
  const [type, sign] = value.split('_')
  return (type === 'ZERO' ? '0' : type) + (sign === 'POS' ? '+' : '-')
}
