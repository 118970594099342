export type ComponentType =
  | 'TextDataInput'
  | 'TextAreaDataInput'
  | 'SelectDataInput'
  | 'PlaceHolder'
  | 'CalculatedField'

export interface ComponentConfig {
  component: ComponentType
  name: string
  otherProps?: Record<string, any>
}

export interface ComponentValue {
  name: string
  value?: string
}

export interface Config {
  [key: string]: ComponentConfig[]
}

// Configuration object
export const config: Config = {
  Subjective: [
    {
      component: 'TextDataInput',
      name: 'Family History',
      otherProps: {
        inputType: 'text',
      },
    },
    {
      component: 'TextDataInput',
      name: 'Medical History',
      otherProps: {
        inputType: 'text',
      },
    },
    // {
    //   component: 'TextDataInput',
    //   name: 'Red Flags',
    //   otherProps: {
    //     inputType: 'text',
    //   },
    // },
    {
      component: 'TextDataInput',
      name: 'Active Medications',
      otherProps: {
        inputType: 'text',
      },
    },
    // {
    //   component: 'TextDataInput',
    //   name: 'Stress',
    //   otherProps: {
    //     inputType: 'number',
    //   },
    // },
    {
      component: 'PlaceHolder',
      name: '',
    },
    {
      component: 'SelectDataInput',
      name: 'Smoker',
      otherProps: {
        values: ['Yes', 'No', 'Not any more'],
      },
    },
    /*     {
      component: 'SelectDataInput',
      name: 'Smoking Type',
      otherProps: {
        values: [
          'Cigarette',
          'Vape/E-Cigarette',
          'Pipe',
          'Cigar',
          'Marijuana',
          'Other',
        ],
      },
    },
 */ {
      component: 'TextDataInput',
      name: 'Number Per Day',
      otherProps: {
        inputType: 'number',
      },
    },
    {
      component: 'SelectDataInput',
      name: 'Vape',
      otherProps: {
        values: ['Yes - Daily', 'Yes - Occasionally', 'Not any more', 'No'],
      },
    },
    {
      component: 'PlaceHolder',
      name: '',
    },
    {
      component: 'TextDataInput',
      name: 'Alcohol (units/week)',
      otherProps: {
        inputType: 'number',
      },
    },
    {
      component: 'SelectDataInput',
      name: 'Recreational Drugs',
      otherProps: {
        values: ['Yes', 'No', 'Not any more'],
      },
    },
    {
      component: 'SelectDataInput',
      name: 'Energy Drinks',
      otherProps: {
        values: ['Yes - Daily', 'Yes - Occasionally', 'Not any more', 'No'],
      },
    },
    {
      component: 'PlaceHolder',
      name: '',
    },
    {
      component: 'TextDataInput',
      name: 'Exercise (mins/week outside work)',
      otherProps: {
        inputType: 'number',
      },
    },
    {
      component: 'TextDataInput',
      name: 'Exercise Type',
      otherProps: {
        inputType: 'text',
      },
    },
    {
      component: 'TextDataInput',
      name: 'Cough',
      otherProps: {
        inputType: 'text',
      },
    },
  ],
  Objective: [
    {
      component: 'TextDataInput',
      name: 'Height (cm)',
      otherProps: {
        inputType: 'number',
      },
    },
    {
      component: 'TextDataInput',
      name: 'Weight (kg)',
      otherProps: {
        inputType: 'number',
      },
    },
    {
      component: 'CalculatedField',
      name: 'BMI',
      otherProps: {
        calculation: (data: ComponentValue[]) => {
          const height = data.find((item) => item.name === 'Height (cm)')?.value
          const weight = data.find((item) => item.name === 'Weight (kg)')?.value
          if (!height || !weight) return undefined
          return (
            Number(weight) /
            ((Number(height) / 100) * (Number(height) / 100))
          ).toFixed(2)
        },
      },
    },
    {
      component: 'PlaceHolder',
      name: '',
    },
    {
      component: 'TextDataInput',
      name: 'Blood Pressure 1 - Sys',
      otherProps: {
        inputType: 'number',
      },
    },
    {
      component: 'TextDataInput',
      name: 'Blood Pressure 1 - Dia',
      otherProps: {
        inputType: 'number',
      },
    },
    {
      component: 'SelectDataInput',
      name: 'Blood Pressure 1 - Arm',
      otherProps: {
        values: ['Left', 'Right'],
      },
    },
    {
      component: 'PlaceHolder',
      name: '',
    },
    {
      component: 'TextDataInput',
      name: 'Blood Pressure 2 - Sys',
      otherProps: {
        inputType: 'number',
      },
    },
    {
      component: 'TextDataInput',
      name: 'Blood Pressure 2 - Dia',
      otherProps: {
        inputType: 'number',
      },
    },
    {
      component: 'SelectDataInput',
      name: 'Blood Pressure 2 - Arm',
      otherProps: {
        values: ['Left', 'Right'],
      },
    },
    {
      component: 'PlaceHolder',
      name: '',
    },
    {
      component: 'TextDataInput',
      name: 'Blood Pressure 3 - Sys',
      otherProps: {
        inputType: 'number',
      },
    },
    {
      component: 'TextDataInput',
      name: 'Blood Pressure 3 - Dia',
      otherProps: {
        inputType: 'number',
      },
    },
    {
      component: 'SelectDataInput',
      name: 'Blood Pressure 3 - Arm',
      otherProps: {
        values: ['Left', 'Right'],
      },
    },
    {
      component: 'PlaceHolder',
      name: '',
    },
    {
      component: 'TextDataInput',
      name: 'Oxygen Saturation %',
      otherProps: {
        inputType: 'number',
      },
    },
    {
      component: 'TextDataInput',
      name: 'Pulse (Resting heart rate)',
      otherProps: {
        inputType: 'number',
      },
    },
    {
      component: 'SelectDataInput',
      name: 'Pulse Rhythm',
      otherProps: {
        values: ['Regular', 'Irregular'],
      },
    },
    {
      component: 'TextDataInput',
      name: 'Respitory Rate',
      otherProps: {
        inputType: 'number',
      },
    },
    {
      component: 'TextDataInput',
      name: 'HBA1C',
      otherProps: {
        inputType: 'number',
      },
    },
    {
      component: 'TextDataInput',
      name: 'Random BM (if applicable)',
      otherProps: {
        inputType: 'number',
      },
    },
    {
      component: 'TextDataInput',
      name: 'Ketones (if applicable)',
      otherProps: {
        inputType: 'number',
      },
    },
    {
      component: 'TextDataInput',
      name: 'Total Cholesterol',
      otherProps: {
        inputType: 'number',
      },
    },
    {
      component: 'TextDataInput',
      name: 'Triglycerides',
      otherProps: {
        inputType: 'number',
      },
    },
    {
      component: 'TextDataInput',
      name: 'HDL',
      otherProps: {
        inputType: 'number',
      },
    },
    {
      component: 'TextDataInput',
      name: 'LDL',
      otherProps: {
        inputType: 'number',
      },
    },
    {
      component: 'TextDataInput',
      name: 'Non-HDL',
      otherProps: {
        inputType: 'number',
      },
    },
    {
      component: 'TextDataInput',
      name: 'Cholesterol LDL:HDL Ratio',
      otherProps: {
        inputType: 'number',
      },
    },
    {
      component: 'TextDataInput',
      name: 'Temperature (°C)',
      otherProps: {
        inputType: 'number',
      },
    },
    /*     {
      component: 'TextDataInput',
      name: 'Skin Assessment (lumps, moles, sun damage)',
      otherProps: {
        inputType: 'text',
      },
    }, */
    {
      component: 'TextDataInput',
      name: 'Lung Sounds (any wheeze, reduced air entry)',
      otherProps: {
        inputType: 'text',
      },
    },
    {
      component: 'TextDataInput',
      name: 'Peak Flow 1 (if applicable)',
      otherProps: {
        inputType: 'number',
      },
    },
    {
      component: 'TextDataInput',
      name: 'Peak Flow 2 (if applicable)',
      otherProps: {
        inputType: 'number',
      },
    },
    {
      component: 'TextDataInput',
      name: 'Peak Flow 3 (if applicable)',
      otherProps: {
        inputType: 'number',
      },
    },
  ],
  Assessment: [
    {
      component: 'TextAreaDataInput',
      name: 'Overall Assessment',
    },
  ],
  Plan: [
    {
      component: 'SelectDataInput',
      name: 'Lifestyle advice only',
      otherProps: {
        values: ['Yes', 'No'],
      },
    },
    {
      component: 'SelectDataInput',
      name: 'Lifestyle advice and future GP visit',
      otherProps: {
        values: ['Yes', 'No'],
      },
    },
    {
      component: 'SelectDataInput',
      name: 'Recommend visit to GP (within 3-4 weeks)',
      otherProps: {
        values: ['Own', 'Centric Health', 'No'],
      },
    },
    {
      component: 'TextDataInput',
      name: 'Recommend visit to GP (within 3-4 weeks) - Details',
      otherProps: {
        inputType: 'text',
      },
    },
    {
      component: 'SelectDataInput',
      name: 'Recommend visit to Emergency Department',
      otherProps: {
        values: ['Yes', 'No'],
      },
    },
    {
      component: 'TextDataInput',
      name: 'Recommend visit to Emergency Department - Details',
      otherProps: {
        inputType: 'text',
      },
    },
    {
      component: 'TextAreaDataInput',
      name: 'Other',
    },
  ],
}
