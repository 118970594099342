import {useCallback} from 'react'
import {useTranslation} from 'react-i18next'

import {
  FamilyHistory,
  FamilyHistoryTypeValue,
  FamilyMember,
  GeneralItemType,
} from '../models'
import {capitalizeFirstLetter} from './helpers'

type FamilyHistoryItem = {
  alias: string
  title: string
  responses?: FamilyMember[] | null
}

export const getFamilyHistoryCardData: (
  data?: FamilyHistory | null,
  diseases?: GeneralItemType<FamilyHistoryTypeValue>[],
) => FamilyHistoryItem[] = (data, diseases = []) =>
  diseases.map((disease) => ({
    alias: disease.alias,
    title: capitalizeFirstLetter(disease.value?.highlight) || '',
    responses: data?.find((record) => record.disease.alias === disease.alias)
      ?.familyMembers,
  }))

export const useFormatFamilyHistoryResponses = () => {
  const {t} = useTranslation()

  return useCallback(
    (responses?: FamilyMember[] | null) => {
      if (responses === undefined) {
        return ''
      }
      if (responses === null) {
        return t("Don't know")
      }
      return responses.length
        ? t('{{count}} family member', {count: responses.length})
        : t('None')
    },
    [t],
  )
}
