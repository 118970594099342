import {AssertTypeFn, pagingNormaliser} from './general'
import {
  Facility,
  FacilityDocumentType,
  HealthcareNetworkDashboard,
  PermissionCategory,
  Specialization,
  SplitFacilitiesResponse,
} from '../models'
import {
  assertArray,
  assertArrayOrNull,
  assertBoolean,
  assertNumber,
  assertString,
  assertStringOrNull,
  getAssertEnum,
} from '../utils'
import {sharedMaterialNormaliser} from './educationalMaterials'

export const facilityNormaliser: AssertTypeFn<Facility> = (obj) => ({
  addressLine: assertString(obj.addressLine),
  city: assertString(obj.city),
  clinikoTenantId: assertStringOrNull(obj.clinikoTenantId),
  country: assertString(obj.country),
  county: assertString(obj.county),
  email: assertStringOrNull(obj.email),
  healthNetworkPermissions: assertArrayOrNull(
    obj.healthNetworkPermissions,
    getAssertEnum(PermissionCategory),
  ),
  healthNetworkSpecializations: assertArrayOrNull(
    obj.healthNetworkSpecializations,
    getAssertEnum(Specialization),
  ),
  id: assertString(obj.id),
  name: assertString(obj.name),
  notInHealthNetworkSpecializations: assertArrayOrNull(
    obj.notInHealthNetworkSpecializations,
    getAssertEnum(Specialization),
  ),
  phoneNumber: assertStringOrNull(obj.phoneNumber),
  specializations: assertArray(
    obj.specializations,
    getAssertEnum(Specialization),
  ),
  timeZone: assertString(obj.timeZone),
  website: assertStringOrNull(obj.website),
  departments: assertArray(obj.departments, assertString),
})

export const splitFacilitiesNormaliser: AssertTypeFn<
  SplitFacilitiesResponse
> = (obj) => ({
  availableOnCushla: assertArray(obj.availableOnCushla, facilityNormaliser),
  inHealthcareNetwork: assertArray(obj.inHealthcareNetwork, facilityNormaliser),
  page: pagingNormaliser(obj.page),
})

export const healthcareNetworkDashboardNormaliser: AssertTypeFn<
  HealthcareNetworkDashboard
> = (obj) => ({
  pendingQuestionnaireCount: assertNumber(obj.pendingQuestionnaireCount),
  hasConsultations: assertBoolean(obj.hasConsultations),
  unreadMaterials: assertArray(obj.unreadMaterials, sharedMaterialNormaliser),
})

export const facilityDocumentTypeNormaliser: AssertTypeFn<
  FacilityDocumentType
> = (obj) => ({
  title: assertString(obj.title),
})

export const facilityDocumentTypesNormaliser: AssertTypeFn<
  FacilityDocumentType[]
> = (arr) => assertArray(arr, facilityDocumentTypeNormaliser)
