import {useTranslation} from 'react-i18next'
import React, {Dispatch, SetStateAction, useContext} from 'react'
import {
  EnumType,
  getMaxDate,
  getMinDate,
  useEnumTranslations,
} from '@common/utils'
import {DateTime} from 'luxon'

import {BoldText, HalfWidthWrapper} from './ShareQuestionnaireModal.styled'
import {RawQuestionnaireSchedule} from './ShareQuestionnaireModal'
import {SelectQuestionnaireRepeatPeriod} from './ShareQuestionnaireRepeatPeriod'

import {QuestionnairesContext} from '~/components/questionnaires/QuestionnairesContext'
import {Typography} from '~/components/general/typography/Typography'
import {Container} from '~/components/general/container/Container'
import {DatePicker} from '~/components/general/datePicker/DatePicker'
import {theme} from '~/styles/theme'

export type ShareQuestionnaireSchedulingProps = {
  schedule: RawQuestionnaireSchedule
  setSchedule: Dispatch<SetStateAction<RawQuestionnaireSchedule>>
}

const setDefaultTime = (
  JSDate: Date | null,
  minJSDate: Date,
  maxJSDate?: Date,
) => {
  if (!JSDate) {
    return null
  }
  const minDate = DateTime.fromJSDate(minJSDate)
  const date = DateTime.fromJSDate(JSDate)

  if (maxJSDate && DateTime.fromJSDate(maxJSDate) < date) {
    const maxDate = DateTime.fromJSDate(maxJSDate)

    return maxDate.minute >= 30
      ? date.set({minute: 30, hour: maxDate.hour}).toJSDate()
      : date.set({minute: 0, hour: maxDate.hour}).toJSDate()
  }

  if (date < minDate) {
    return minDate.minute < 30
      ? date.set({minute: 30, hour: minDate.hour}).toJSDate()
      : date
          .startOf('day')
          .plus({hour: minDate.hour + 1})
          .toJSDate()
  }

  return JSDate
}

const dateFormatOptions: Intl.DateTimeFormatOptions = {
  weekday: 'long',
  year: 'numeric',
  month: 'long',
  day: 'numeric',
}

export const ShareQuestionnaireScheduling: React.FC<
  ShareQuestionnaireSchedulingProps
> = ({schedule, setSchedule}) => {
  const {t} = useTranslation()
  const {questionnaire} = useContext(QuestionnairesContext)
  const {translateEnumValue} = useEnumTranslations()

  const getScheduleText = () => {
    if (!schedule.startDate && !schedule.repeatPeriod) {
      return t('Now')
    }
    if (!schedule.startDate) {
      return t('{{period}}, starting from now', {
        period: translateEnumValue(
          EnumType.QuestionnaireRepeatPeriod,
          schedule.repeatPeriod,
        ),
      })
    }
    if (!schedule.repeatPeriod) {
      return t('Once on {{date}}', {
        date: schedule.startDate.toLocaleDateString('en', dateFormatOptions),
      })
    }
    return t('{{period}} starting on {{date}}', {
      period: schedule.repeatPeriod,
      date: schedule.startDate.toLocaleDateString('en', dateFormatOptions),
    })
  }

  const getTimeFilter = (minDate?: Date, maxDate?: Date) => (time: Date) => {
    if (maxDate && maxDate < time) {
      return false
    }
    return !minDate || time > minDate
  }

  return (
    <Container flex spacing={5} direction="column">
      <Container flex spacing={2} direction="column">
        <Typography color={theme.colors.grey.main}>
          <BoldText>{questionnaire?.title}</BoldText>
          {t(' questionnaire will be sent')}
        </Typography>
        <Typography color={theme.colors.grey.main}>
          {getScheduleText()}
        </Typography>
      </Container>
      <Container flex spacing={3} direction="column">
        <Container flex spacing={2} direction="column">
          <Typography color={theme.colors.grey.main}>
            {t('Start Date:')}
          </Typography>
          <Container flex spacing={3}>
            <DatePicker
              minDate={new Date()}
              maxDate={getMinDate([schedule.dateDue, schedule.endDate])}
              selected={schedule.startDate}
              onChange={(startDate) =>
                setSchedule({
                  ...schedule,
                  startDate: setDefaultTime(
                    startDate,
                    new Date(),
                    getMinDate([schedule.dateDue, schedule.endDate]),
                  ),
                })
              }
            />
            <DatePicker
              disabled={!schedule.startDate}
              filterTime={getTimeFilter(
                new Date(),
                getMinDate([schedule.dateDue, schedule.endDate]),
              )}
              showTimeSelect
              showTimeSelectOnly
              selected={schedule.startDate}
              onChange={(startDate) =>
                setSchedule({
                  ...schedule,
                  startDate,
                })
              }
            />
          </Container>
        </Container>
        <Container flex spacing={2} direction="column">
          <Typography color={theme.colors.grey.main}>
            {t('Due Date:')}
          </Typography>
          <Container flex spacing={3}>
            <DatePicker
              minDate={getMaxDate([schedule.startDate, new Date()])}
              selected={schedule.dateDue}
              onChange={(dateDue) =>
                setSchedule({
                  ...schedule,
                  dateDue: setDefaultTime(
                    dateDue,
                    schedule.startDate || new Date(),
                  ),
                })
              }
            />
            <DatePicker
              disabled={!schedule.dateDue}
              filterTime={getTimeFilter(
                getMaxDate([schedule.startDate, new Date()]),
              )}
              showTimeSelect
              showTimeSelectOnly
              selected={schedule.dateDue}
              onChange={(dateDue) =>
                setSchedule({
                  ...schedule,
                  dateDue,
                })
              }
            />
          </Container>
        </Container>
        <Container flex spacing={2} direction="column">
          <Typography color={theme.colors.grey.main}>
            {t('Repeat: ')}
          </Typography>
          <HalfWidthWrapper>
            <SelectQuestionnaireRepeatPeriod
              schedule={schedule}
              setSchedule={setSchedule}
            />
          </HalfWidthWrapper>
        </Container>
        <Container flex spacing={2} direction="column">
          <Typography color={theme.colors.grey.main}>
            {t('End Date:')}
          </Typography>
          <HalfWidthWrapper>
            <DatePicker
              disabled={!schedule.repeatPeriod}
              selected={schedule.endDate}
              onChange={(endDate) =>
                setSchedule({
                  ...schedule,
                  endDate:
                    endDate &&
                    DateTime.fromJSDate(endDate).endOf('day').toJSDate(),
                })
              }
              minDate={schedule.startDate || new Date()}
            />
          </HalfWidthWrapper>
        </Container>
      </Container>
    </Container>
  )
}
