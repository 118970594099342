import React from 'react'

import {Typography} from '~/components/general/typography/Typography'
import {Container} from '~/components/general/container/Container'
import {StyledDashboardInnerCardTableCell} from '~/components/facility/GeneralDataCard.styled'

export const PlaceHolder: React.FC<{
  title?: string
}> = ({title}) => (
  <StyledDashboardInnerCardTableCell>
    <Container direction="column">
      <Typography weight="medium" size="sm">
        {title}
      </Typography>
    </Container>
  </StyledDashboardInnerCardTableCell>
)
