import {PreventiveActivity} from '~/types/preventive'

/**
 * Determines if a preventive activity is in good standing (OK) or needs attention
 * @param activity The preventive activity to evaluate
 * @returns true if the activity is completed or in good standing, false if it requires attention
 */
export const isActivityOkay = (activity: PreventiveActivity): boolean => {
  const isCompleted =
    activity.practitionerAction === '1' || activity.practitionerAction === '3'

  if (isCompleted) return true
  if (
    !isCompleted &&
    activity.reminderStatus === '0' &&
    new Date(activity.dueDate) < new Date()
  ) {
    return false
  }
  return true
}

/**
 * Gets the background color corresponding to a preventive activity
 * @param activity The preventive activity
 * @returns The background color: green (#4CAF50) if OK, red (#F44336) if it requires attention
 */
export const getActivityBackgroundColor = (
  activity: PreventiveActivity,
): string => (isActivityOkay(activity) ? '#4CAF50' : '#F44336')
