import React from 'react'
import {useTranslation} from 'react-i18next'
import {useSearchParams} from 'react-router-dom'
import {TimelineItemType} from '@common/models'
import {EnumType, useEnumTranslations} from '@common/utils'

import {TimelineHeaderRow} from './TimelineTable.styled'
import {TimelineHeaderCell} from './TimelineHeaderCell'

const TYPE_FILTER_NAME = 'typeFilter'

export const TimelineHeader = () => {
  const {t} = useTranslation()
  const {translateEnumValue} = useEnumTranslations()
  const [searchParams, setSearchParams] = useSearchParams()

  const filterByType = (typeFilter?: TimelineItemType) => {
    setSearchParams((params) => {
      if (typeFilter) {
        params.set(TYPE_FILTER_NAME, typeFilter)
      } else {
        params.delete(TYPE_FILTER_NAME)
      }
      return params
    })
  }

  return (
    <thead>
      <TimelineHeaderRow>
        <th aria-label="Timeline Row" />
        <TimelineHeaderCell
          title={t('Type')}
          hasValue={Boolean(searchParams.get(TYPE_FILTER_NAME))}
          menuItems={[
            {label: t('All'), onClick: () => filterByType(undefined)},
            ...Object.values(TimelineItemType).map((v) => ({
              label: translateEnumValue(EnumType.TimelineItemType, v),
              onClick: () => {
                filterByType(v)
              },
            })),
          ]}
        />
        <TimelineHeaderCell title={t('Date')} />
        <TimelineHeaderCell title={t('Description')} />
        <TimelineHeaderCell title={t('Facility')} />
        <TimelineHeaderCell title={t('Health Journey')} />
        <TimelineHeaderCell title={t('Source')} />
      </TimelineHeaderRow>
    </thead>
  )
}
