import {useCallback, useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {
  BasicHealthInfoResponse,
  PermissionCategory,
  PatientHealthJourney,
} from '../../models'
import {capitalizeFirstLetter, isTruthy} from '../helpers'
import {formatMedicationTitle, formatSurgeryTitle} from '../medical'

export const useFormattedMedicalData = ({
  basicHealthData,
  healthJourneysData,
}: {
  basicHealthData: BasicHealthInfoResponse | undefined
  healthJourneysData?: PatientHealthJourney[]
}) => {
  const {t} = useTranslation()

  const familyHistoryRows = useMemo(
    () =>
      basicHealthData?.familyHistory
        ?.filter((item) => !!item.familyMembers?.length)
        .map((item) => capitalizeFirstLetter(item.disease.value?.highlight)),
    [basicHealthData?.familyHistory],
  )

  return useMemo(
    () => ({
      conditions: {
        totalCount: basicHealthData?.activeDiagnosedConditionsCount,
        values:
          basicHealthData?.activeDiagnosedConditions?.map(
            (item) => item.condition || item.customName,
          ) ?? [],
      },
      allergies: {
        totalCount: basicHealthData?.allergiesCount,
        values:
          basicHealthData?.allergies?.map((item) => item.allergen.name) ?? [],
      },
      medications: {
        totalCount: basicHealthData?.activeMedicationsCount,
        values:
          basicHealthData?.activeMedications?.flatMap((item) =>
            formatMedicationTitle(item, t),
          ) ?? [],
      },
      surgeries: {
        totalCount: basicHealthData?.surgeriesCount,
        values:
          basicHealthData?.surgeries?.map((item) =>
            formatSurgeryTitle(item, t),
          ) ?? [],
      },
      familyHistory: {
        totalCount: familyHistoryRows?.length,
        values: familyHistoryRows ?? [],
      },
      healthJourneys: {
        totalCount: healthJourneysData?.length,
        values:
          healthJourneysData?.map((healthJourney) => healthJourney.type) ?? [],
      },
    }),
    [basicHealthData, healthJourneysData, familyHistoryRows, t],
  )
}

export const useMedicalCardData = ({
  data,
  permissions,
}: {
  data?: BasicHealthInfoResponse
  permissions?: PermissionCategory[]
}) => {
  const {t} = useTranslation()

  const {allergies, conditions, medications, familyHistory} =
    useFormattedMedicalData({basicHealthData: data})

  const getCardItemValues = useCallback(
    (items: (string | null | undefined)[], permission: PermissionCategory) => {
      if (permissions && !permissions.includes(permission)) {
        return t('Not shared')
      }

      const filteredItems = items.filter(isTruthy)
      return filteredItems.length ? filteredItems : t('None')
    },
    [t, permissions],
  )

  return useMemo(() => {
    if (!data) {
      return undefined
    }

    return [
      {
        title: t('Medical conditions'),
        totalCount: data.activeDiagnosedConditionsCount,
        values: getCardItemValues(
          conditions.values,
          PermissionCategory.DIAGNOSED_CONDITIONS,
        ),
      },
      {
        title: t('Allergies'),
        totalCount: data.allergiesCount,
        values: getCardItemValues(allergies.values, PermissionCategory.ALLERGY),
      },
      {
        title: t('Active medications'),
        totalCount: data.activeMedicationsCount,
        values: getCardItemValues(
          medications.values,
          PermissionCategory.MEDICATION,
        ),
      },
      {
        title: t('Family history'),
        totalCount: familyHistory.totalCount,
        values: getCardItemValues(
          familyHistory.values,
          PermissionCategory.FAMILY_HISTORY,
        ),
      },
    ]
  }, [
    data,
    allergies,
    medications,
    familyHistory,
    conditions,
    getCardItemValues,
    t,
  ])
}
