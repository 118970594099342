/* tslint:disable */
/* eslint-disable */
/**
 * Cushla API
 * REST API for Cushla
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */

export enum TobaccoType {
  CIGARS = 'CIGARS',
  UNFILTERED_CIGARETTES = 'UNFILTERED_CIGARETTES',
  FILTERED_CIGARETTES = 'FILTERED_CIGARETTES',
  NORMAL_CIGARETTES = 'NORMAL_CIGARETTES',
  LIGHT_CIGARETTES = 'LIGHT_CIGARETTES',
  PIPE_WITH_TOBACCO = 'PIPE_WITH_TOBACCO',
  CHEWING_TOBACCO = 'CHEWING_TOBACCO',
  VAPE = 'VAPE',
  MARIJUANA = 'MARIJUANA',
  NONE = 'NONE',
}
