import styled from 'styled-components'

import {Icon} from '../icon/Icon'

type Select = {
  sizeVariant?: 'large' | 'normal' | 'small'
  search?: boolean
  select?: boolean
  noBorder?: boolean
  showOptions?: boolean
  disabled?: boolean
  showInput?: boolean
  width?: number
}

type Option = {
  size?: 'large' | 'normal' | 'small'
}

export const StyledSelect = styled.div<Select>`
  width: ${({width}) => (width == null ? '100%' : `${width}px`)};
  height: ${({theme, sizeVariant = 'normal'}) =>
    theme.height.input[sizeVariant]};
  display: inline-flex;
  cursor: pointer;
`

export const SelectorWrapper = styled.div`
  width: 100%;
  position: relative;
`

export const SelectSelector = styled.div<Select>`
  width: 100%;
  position: absolute;
  ${({showOptions, theme}) =>
    showOptions && `box-shadow: ${theme.boxShadow.select}`};
  background-color: ${({theme}) => theme.colors.white};
  ${({noBorder, theme}) =>
    !noBorder && `border: 1px solid ${theme.colors.inputBorder}`};
  border-radius: ${({theme}) => theme.borderRadius.xs};
  overflow: hidden;
  ${({showOptions}) => `z-index: ${showOptions ? 100 : 10}`};
`

export const SelectInputWrapper = styled.div<Select>`
  background-color: ${({disabled, theme}) =>
    disabled ? theme.colors.grey.grey20 : theme.colors.white};
  height: ${({theme, sizeVariant = 'normal'}) =>
    theme.height.input[sizeVariant]};
  position: relative;
  display: flex;
  align-items: center;
  padding: 0
    ${({sizeVariant, theme}) =>
      sizeVariant === 'large' ? '19px' : theme.spacing.md};
`

export const SelectSelectionSearch = styled.span<Select>`
  position: absolute;
  display: inline-flex;
  align-items: center;
  height: 100%;
  z-index: 1;
  ${({search, sizeVariant, theme}) =>
    search
      ? `right: ${sizeVariant === 'large' ? '19px' : theme.spacing.md}; left: ${
          sizeVariant === 'large' ? '43px' : theme.spacing.xxl
        }`
      : `left: ${sizeVariant === 'large' ? '19px' : theme.spacing.md}; right: ${
          sizeVariant === 'large' ? '43px' : theme.spacing.xxl
        }`};
`

export const SelectInput = styled.input<Select>`
  width: 100%;
  height: 100%;
  font-size: ${({sizeVariant, theme}) =>
    sizeVariant === 'small' ? theme.fontSize.sm : theme.fontSize.lg};
  line-height: ${({theme}) => theme && theme.lineHeight.lg};
  padding: 0;
  border: 0;
  outline: 0;
  opacity: ${({showInput}) => (showInput ? 1 : 0)};
  cursor: pointer;
`

export const SelectSelectionItem = styled.span<Select>`
  display: inline-flex;
  font-size: ${({theme}) => theme && theme.fontSize.lg};
  line-height: ${({theme}) => theme && theme.lineHeight.lg};
  color: ${({theme}) => theme.colors.inputText};
  ${({search, theme}) =>
    search
      ? `padding-left: ${theme.spacing.lg}`
      : `padding-right: ${theme.spacing.lg}`};
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  z-index: 20;
`

export const SelectIcon = styled(Icon)<Select>`
  position: absolute;
  top: 50%;
  ${({search, theme}) =>
    search ? `left: ${theme.spacing.sm}` : `right: ${theme.spacing.sm}`};
  right: ${({theme}) => theme.spacing.sm};
  transform: translateY(-50%)
    ${({showOptions, search}) =>
      showOptions && !search ? 'rotate(180deg)' : 'rotate(0)'};
`

export const Options = styled.div`
  width: 100%;
  background-color: ${({theme}) => theme.colors.white};
`

export const StyledOption = styled.div<Option>`
  display: flex;
  align-items: center;
  padding: ${({theme}) => theme.spacing.md};
  cursor: pointer;
  height: ${({theme, size = 'normal'}) => theme.height.input[size]};

  &:nth-child(odd) {
    background-color: ${({theme}) => theme.colors.grey.grey20};
  }

  &:hover {
    background-color: ${({theme}) => theme.colors.buttonHover};
  }
`

export const SelectDropDownIconWrapper = styled.div`
  margin-left: ${({theme}) => theme.spacing.xl};
`
