/* tslint:disable */
/* eslint-disable */
/**
 * Cushla API
 * REST API for Cushla
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */

export enum AgeRange {
  AGE_0_19 = 'AGE_0_19',
  AGE_20_29 = 'AGE_20_29',
  AGE_30_39 = 'AGE_30_39',
  AGE_40_49 = 'AGE_40_49',
  AGE_50_59 = 'AGE_50_59',
  AGE_60_69 = 'AGE_60_69',
  AGE_70_79 = 'AGE_70_79',
  AGE_80_89 = 'AGE_80_89',
  DONT_KNOW = 'DONT_KNOW',
}
