import React, {useState, ReactNode} from 'react'

import {
  StyledAccordion,
  AccordionHeader,
  AccordionIcon,
  AccordionContentWrap,
  AccordionContent,
  AccordionCollapsedIcon,
  AccordionRightText,
} from './Accordion.styled'

import {Typography} from '~/components/general/typography/Typography'
import {Icon, IconName} from '~/components/general/icon/Icon'
import {theme} from '~/styles/theme'

type AccordionProps = {
  /**
   * Passing in string will use default styling.
   * Passing in Element will render just the element
   */
  title: ReactNode
  children?: ReactNode
  className?: string
  icon?: IconName
  isOpenByDefault?: boolean
  /** Disables expanding, changes color, removes caret */
  disabled?: boolean
  rightText?: string
}

export const Accordion: React.FC<AccordionProps> = ({
  title,
  children,
  className,
  icon,
  isOpenByDefault = false,
  disabled,
  rightText,
}) => {
  const [isOpen, setIsOpen] = useState(isOpenByDefault)

  const handleHeaderClick = () => {
    if (disabled) {
      return
    }
    setIsOpen(!isOpen)
  }

  const renderTitle = () => {
    if (typeof title !== 'string') {
      return title
    }
    return (
      <Typography
        size="lg"
        weight="medium"
        color={disabled ? theme.colors.grey.main : theme.colors.primary.main}>
        {title}
      </Typography>
    )
  }

  return (
    <StyledAccordion className={className}>
      <AccordionHeader disabled={disabled} onClick={handleHeaderClick}>
        {icon && (
          <AccordionIcon disabled={disabled}>
            <Icon
              size="md"
              color={disabled ? theme.colors.grey.grey300 : undefined}
              name={icon}
            />
          </AccordionIcon>
        )}
        {renderTitle()}
        {!!rightText && (
          <AccordionRightText>
            <Typography color={theme.colors.grey.main}>{rightText}</Typography>
          </AccordionRightText>
        )}
        {!disabled && (
          <AccordionCollapsedIcon collapsed={!isOpen}>
            <Icon name="ChevronDown" color={theme.colors.grey.grey300} />
          </AccordionCollapsedIcon>
        )}
      </AccordionHeader>
      {!disabled && (
        <AccordionContentWrap collapsed={!isOpen}>
          <AccordionContent>{children}</AccordionContent>
        </AccordionContentWrap>
      )}
    </StyledAccordion>
  )
}
