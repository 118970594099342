import {
  endpointKeys,
  PatientAggregatedActivitiesUrlParams,
  PatientLatestActivitiesUrlParams,
} from '@common/api'
import {AggregatedActivity, LatestActivity} from '@common/models'
import {
  aggregatedActivitiesNormaliser,
  latestActivityNormaliser,
} from '@common/normalisers'
import {UseQueryOptions} from '@tanstack/react-query'

import {useApi, useWebGet} from './helpers'

export const useGetAggregatedActivities = (
  params: PatientAggregatedActivitiesUrlParams,
  options?: UseQueryOptions<AggregatedActivity[], Error>,
) =>
  useWebGet<AggregatedActivity[]>(
    [endpointKeys.patientAggregatedActivities, params],
    useApi().patientAggregatedActivities(params),
    aggregatedActivitiesNormaliser,
    {
      keepPreviousData: false,
      ...options,
    },
  )

export const useGetLatestActivities = (
  params: PatientLatestActivitiesUrlParams,
  options?: UseQueryOptions<LatestActivity, Error>,
) =>
  useWebGet<LatestActivity>(
    [endpointKeys.patientLatestActivities, params],
    useApi().patientLatestActivities(params),
    latestActivityNormaliser,
    {
      keepPreviousData: false,
      ...options,
    },
  )
