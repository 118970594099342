import styled from 'styled-components'

type LayoutContainerProps = {
  size?: 'normal' | 'small'
}

export const StyledLayoutContainer = styled.div<LayoutContainerProps>`
  width: 100%;
  max-width: ${({size}) => (size === 'small' ? '888px' : '1072px')};
  padding: ${({theme, size}) =>
    `${theme.spacing.lg} ${
      size === 'small' ? theme.spacing.xs : theme.spacing.sm
    }`};
  margin: 0 auto;
`
