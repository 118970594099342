import styled from 'styled-components'

type AccordionProps = {
  collapsed?: boolean
}

type DisabledElementProps = {
  disabled?: boolean
}

export const StyledAccordion = styled.div`
  border: 1px solid ${({theme}) => theme.colors.grey.grey50};
  border-radius: ${({theme}) => theme.borderRadius.xxs};
`

export const AccordionHeader = styled.div<DisabledElementProps>`
  display: flex;
  align-items: center;
  padding: ${({theme}) => theme.spacing.md};
  cursor: ${({disabled}) => (disabled ? 'default' : 'pointer')};
`

export const AccordionIcon = styled.div<DisabledElementProps>`
  width: ${({theme}) => theme.spacing.xl};
  height: ${({theme}) => theme.spacing.xl};
  background-color: ${({theme}) => theme.colors.primary.blue50};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${({theme}) => theme.spacing.md};
  border-radius: ${({theme}) => theme.borderRadius.xxs};
  ${({disabled}) => (disabled ? 'opacity: 0.6' : '')}
`

export const AccordionRightText = styled.div<AccordionProps>`
  margin-left: auto;
`

export const AccordionCollapsedIcon = styled.div<AccordionProps>`
  margin-left: auto;
  padding-left: ${({theme}) => theme.spacing.sm};
  svg {
    transform: rotate(${({collapsed}) => (collapsed ? '0' : '180')}deg);
    transition: transform 0.35s;
  }
`

export const AccordionContentWrap = styled.div<AccordionProps>`
  overflow: hidden;
  height: auto;
  max-height: ${({collapsed}) => (collapsed ? '0px' : '9999px')};
  transition: max-height
    ${({collapsed}) =>
      collapsed
        ? '0.35s cubic-bezier(0, 1, 0, 1)'
        : '0.3s cubic-bezier(1, 0, 1, 0)'};
`

export const AccordionContent = styled.div`
  padding: ${({theme}) =>
    `${theme.spacing.xs} ${theme.spacing.md} ${theme.spacing.md}`};
`
