import * as React from 'react'

import {IconProps} from '.'

function Questionnaires({color, size, isFilled}: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={isFilled ? color : 'none'}
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1715_2890)">
        <path
          d="M2.2002 20V4C2.2002 3.20435 2.51627 2.44129 3.07887 1.87868C3.64148 1.31607 4.40455 1 5.20019 1H15.2002C15.4654 1 15.7198 1.10536 15.9073 1.29289C16.0948 1.48043 16.2002 1.73478 16.2002 2C16.2002 2.26522 16.0948 2.51957 15.9073 2.70711C15.7198 2.89464 15.4654 3 15.2002 3H5.20019C4.93498 3 4.68062 3.10536 4.49309 3.29289C4.30555 3.48043 4.20019 3.73478 4.20019 4V20C4.20019 20.2652 4.30555 20.5196 4.49309 20.7071C4.68062 20.8946 4.93498 21 5.20019 21H17.2002C17.4654 21 17.7198 20.8946 17.9073 20.7071C18.0948 20.5196 18.2002 20.2652 18.2002 20V14C18.2002 13.7348 18.3055 13.4804 18.4931 13.2929C18.6806 13.1054 18.935 13 19.2002 13C19.4654 13 19.7198 13.1054 19.9073 13.2929C20.0948 13.4804 20.2002 13.7348 20.2002 14V20C20.2002 20.7956 19.8841 21.5587 19.3215 22.1213C18.7589 22.6839 17.9958 23 17.2002 23H5.20019C4.40455 23 3.64148 22.6839 3.07887 22.1213C2.51627 21.5587 2.2002 20.7956 2.2002 20ZM10.2002 18C10.2002 18.1978 10.2588 18.3911 10.3687 18.5556C10.4786 18.72 10.6348 18.8482 10.8175 18.9239C11.0002 18.9996 11.2013 19.0194 11.3953 18.9808C11.5893 18.9422 11.7674 18.847 11.9073 18.7071C12.0472 18.5673 12.1424 18.3891 12.181 18.1951C12.2196 18.0011 12.1998 17.8 12.1241 17.6173C12.0484 17.4346 11.9202 17.2784 11.7558 17.1685C11.5913 17.0586 11.398 17 11.2002 17C10.935 17 10.6806 17.1054 10.4931 17.2929C10.3056 17.4804 10.2002 17.7348 10.2002 18ZM23.9072 3.959C24.0002 4.05187 24.0739 4.16216 24.1243 4.28356C24.1746 4.40496 24.2005 4.53508 24.2005 4.6665C24.2005 4.79792 24.1746 4.92804 24.1243 5.04944C24.0739 5.17084 24.0002 5.28113 23.9072 5.374L16.5732 12.707C16.3857 12.8945 16.1314 12.9999 15.8662 13H13.2002C12.935 13 12.6806 12.8946 12.4931 12.7071C12.3056 12.5196 12.2002 12.2652 12.2002 12V9.333C12.2003 9.06781 12.3056 8.81349 12.4932 8.626L19.8262 1.293C20.0137 1.10553 20.268 1.00021 20.5332 1.00021C20.7984 1.00021 21.0527 1.10553 21.2402 1.293L23.9072 3.959ZM21.7862 4.667L20.5332 3.414L14.2002 9.748V11H15.4522L21.7862 4.667Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_1715_2890">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Questionnaires
