/* tslint:disable */
/* eslint-disable */
/**
 * Cushla API
 * REST API for Cushla
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Blood loss
 * @export
 * @enum {string}
 */

export enum BloodLossDto {
  LESS_THAN_500_ML = 'LESS_THAN_500ML',
  BETWEEN_500_ML_AND_1_L = 'BETWEEN_500ML_AND_1L',
  BETWEEN_1_L_AND_2_L = 'BETWEEN_1L_AND_2L',
  MORE_THAN_2_L = 'MORE_THAN_2L',
}
