import React from 'react'
import styled from 'styled-components'
import {Birth, TerminatedPregnancy} from '@common/models'

import {BirthItem} from './BirthItem'
import {TerminatedPregnancyItem} from './TerminatedPregnancyItem'
import {StyledCardContainer} from '../dashboard/PatientDashboardCard.styled'

import {theme} from '~/styles/theme'

export type Pregnancy = Birth | TerminatedPregnancy

export const isBirth = (pregnancy: Pregnancy): pregnancy is Birth =>
  'dateOfBirth' in pregnancy

const ListCard = styled(StyledCardContainer)`
  width: 100%;
  margin-bottom: ${theme.spacing.md};
  padding: 0;
  padding-horizontal: ${theme.spacing.md};
`

type PregnancyContainerProps = {
  data: Pregnancy
}

export const PregnancyContainer = ({
  data: pregnancy,
}: PregnancyContainerProps) => (
  <ListCard flex direction="column">
    {isBirth(pregnancy) ? (
      <BirthItem {...pregnancy} />
    ) : (
      <TerminatedPregnancyItem {...pregnancy} />
    )}
  </ListCard>
)
