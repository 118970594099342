import * as React from 'react'

import {IconProps} from '.'

function Filter({color, size, isFilled}: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill={isFilled ? color : 'none'}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.63037 3H13.3698C13.4667 3 13.5615 3.02814 13.6426 3.08099C13.7238 3.13384 13.7879 3.20914 13.8271 3.29772C13.8663 3.38631 13.8789 3.48437 13.8634 3.57999C13.8479 3.6756 13.8049 3.76466 13.7398 3.83634L9.63013 8.35697C9.54646 8.449 9.5001 8.56892 9.5001 8.6933V12.2324C9.5001 12.3147 9.47977 12.3958 9.44093 12.4683C9.40209 12.5409 9.34594 12.6028 9.27745 12.6484L7.27745 13.9818C7.20215 14.032 7.11463 14.0608 7.02424 14.0652C6.93384 14.0695 6.84396 14.0493 6.76417 14.0066C6.68438 13.9639 6.61767 13.9003 6.57116 13.8227C6.52466 13.745 6.5001 13.6562 6.5001 13.5657V8.6933C6.5001 8.56892 6.45374 8.449 6.37007 8.35697L2.2604 3.83634C2.19524 3.76466 2.15231 3.6756 2.13681 3.57999C2.12131 3.48437 2.13393 3.38631 2.17311 3.29772C2.2123 3.20914 2.27638 3.13384 2.35755 3.08099C2.43873 3.02814 2.53351 3 2.63037 3V3Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default Filter
