import {useTranslation} from 'react-i18next'
import React, {useContext, useMemo, useState} from 'react'
import {MISSING_ID} from '@common/utils'
import {BasePermissionCategory} from '@common/models'

import {PermissionCheck} from '~/components/patients/common/requestData/PermissionCheck'
import {SimplePaginatedTable} from '~/components/general/table/SimplePaginatedTable'
import {useGetPhysitrackPrograms} from '~/api'
import {PatientsContext} from '~/components/patients/PatientsContext'
import {Loading} from '~/components/general/loading/Loading'
import {getTableDateValue} from '~/utils'

const PROGRAMS_PER_TABLE_PAGE = 10

const PatientPhysitrackProgramsContent: React.FC = () => {
  const {t} = useTranslation()
  const {patient} = useContext(PatientsContext)
  const [currentPageIndex, setCurrentPageIndex] = useState(0)

  const {data, isInitialLoading, isFetching} = useGetPhysitrackPrograms({
    patientId: patient?.patientId ?? MISSING_ID,
    page: currentPageIndex,
    perPage: PROGRAMS_PER_TABLE_PAGE,
  })

  const headerData = useMemo(
    () => ({
      columns: [
        {title: t('Program'), columnWidthPercent: 30},
        {title: t('Facility'), columnWidthPercent: 30},
        {title: t('Start date'), columnWidthPercent: 20},
        {title: t('End date'), columnWidthPercent: 20},
      ],
    }),
    [t],
  )

  const rowsData = useMemo(
    () =>
      data?.records?.map((record) => ({
        columns: [
          record.name,
          record.facilityName || '-',
          getTableDateValue(record.startDate),
          getTableDateValue(record.endDate),
        ],
      })) ?? [],
    [data],
  )

  if (isInitialLoading) {
    return <Loading />
  }

  return (
    <SimplePaginatedTable
      rows={rowsData}
      header={headerData}
      isLoading={isFetching}
      paginationData={data?.page}
      onPageClick={setCurrentPageIndex}
    />
  )
}

export const PatientPhysitrackPrograms: React.FC = () => (
  <PermissionCheck permission={BasePermissionCategory.PHYSITRACK_PROGRAMS}>
    <PatientPhysitrackProgramsContent />
  </PermissionCheck>
)
