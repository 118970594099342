import * as React from 'react'

import {IconProps} from '.'

function FirstAid({color, size, isFilled}: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill={isFilled ? color : 'none'}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.5 12.5H3.125C2.95924 12.5 2.80027 12.4342 2.68306 12.3169C2.56585 12.1997 2.5 12.0408 2.5 11.875V8.125C2.5 7.95924 2.56585 7.80027 2.68306 7.68306C2.80027 7.56585 2.95924 7.5 3.125 7.5H7.5V3.125C7.5 2.95924 7.56585 2.80027 7.68306 2.68306C7.80027 2.56585 7.95924 2.5 8.125 2.5H11.875C12.0408 2.5 12.1997 2.56585 12.3169 2.68306C12.4342 2.80027 12.5 2.95924 12.5 3.125V7.5H16.875C17.0408 7.5 17.1997 7.56585 17.3169 7.68306C17.4342 7.80027 17.5 7.95924 17.5 8.125V11.875C17.5 12.0408 17.4342 12.1997 17.3169 12.3169C17.1997 12.4342 17.0408 12.5 16.875 12.5H12.5V16.875C12.5 17.0408 12.4342 17.1997 12.3169 17.3169C12.1997 17.4342 12.0408 17.5 11.875 17.5H8.125C7.95924 17.5 7.80027 17.4342 7.68306 17.3169C7.56585 17.1997 7.5 17.0408 7.5 16.875V12.5Z"
        stroke={color}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default FirstAid
