import React from 'react'
import {TerminatedPregnancy} from '@common/models'
import {useTranslation} from 'react-i18next'
import {DateTime} from 'luxon'
import {formatDate, useTranslateBooleanValue} from '@common/utils'

import {PregnancyCard, PregnancyCardRowProps} from './PregnancyCard'

export const TerminatedPregnancyItem = (
  terminationData: TerminatedPregnancy,
) => {
  const {t} = useTranslation()
  const translateBooleanValue = useTranslateBooleanValue()
  const rows: PregnancyCardRowProps[] = [
    {
      title: t('Gestational Age (weeks)'),
      value: terminationData.pregnancyLengthWeeks || '-',
    },
    {
      title: t('Surgery Required'),
      value: translateBooleanValue(terminationData.withSurgery, '-'),
    },
  ]

  return (
    <PregnancyCard
      title={t('Termination')}
      date={formatDate(
        DateTime.DATE_FULL,
        terminationData.terminationDate,
        undefined,
        '-',
      )}
      rows={rows}
      notes={terminationData.notes}
    />
  )
}
