import {
  HealthDataResolution,
  HealthDataType,
  PermissionCategory,
  PractitionersPatient,
  RowGroup,
} from '@common/models'
import {useTranslation} from 'react-i18next'
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {
  EnumType,
  getAlignedHealthDataIntervalStart,
  getHealthDataIntervalEnd,
  getResolutionDateDiff,
  resolutionUnitMap,
  useEnumTranslations,
  useLatestHealthDataDetails,
} from '@common/utils'
import {DateTime} from 'luxon'

import {StyledHorizontalRule} from './PatientTrendsCard.styled'
import {TrendRow} from '../TrendRow'
import {TrendChartAxis} from '../TrendChartAxis'
// import {TrendsLifeMilestones} from '../TrendsLifeMilestones'
import {TrendChartDateSelect} from '../TrendChartDateSelect'
import {TrendChartResolutionSelect} from '../TrendChartResolutionSelect'
import {useSelectedHealthDataTypes} from './hooks'

import {Card} from '~/components/general/card/Card'
import {Container} from '~/components/general/container/Container'
import {Typography} from '~/components/general/typography/Typography'
import {theme} from '~/styles/theme'
import {dataPointCountMap, useDimensions, useHealthData} from '~/utils'
import {
  useGetAggregatedActivities,
  useInfiniteGetPatientLifeMilestones,
} from '~/api'
import {MultiSelect} from '~/components/general/multiSelect/MultiSelect'

const getDefaultStartDate = (resolution: HealthDataResolution) =>
  DateTime.now()
    .startOf(resolutionUnitMap[resolution])
    .minus(getResolutionDateDiff(resolution, dataPointCountMap[resolution] - 1))

type PatientTrendCardProps = {
  patient: PractitionersPatient
  onUpdate?: (newData: RowGroup[]) => void
}

export const PatientTrendsCard: React.FC<PatientTrendCardProps> = ({
  patient,
  onUpdate,
}) => {
  const {t} = useTranslation()
  const {ref} = useDimensions()
  const {selectOptions} = useEnumTranslations()

  const {selectedHealthDataTypes, selectHealthDataTypes} =
    useSelectedHealthDataTypes()

  const {permissions} = patient
  const hasLifeMilestonesPermission = permissions.includes(
    PermissionCategory.LIFE_MILESTONES,
  )

  const [resolution, setResolution] = useState(HealthDataResolution.MONTH)
  const [startDate, setStartDate] = useState(getDefaultStartDate(resolution))

  const endDate = useMemo(
    () =>
      getHealthDataIntervalEnd(
        startDate,
        resolution,
        dataPointCountMap[resolution],
      ),
    [startDate, resolution],
  )

  const handleResolutionChange = useCallback(
    (newResolution: HealthDataResolution) => {
      const now = DateTime.now()
      const newEndDate = endDate < now ? endDate : now

      const newStartDate = getAlignedHealthDataIntervalStart(
        newEndDate,
        newResolution,
        dataPointCountMap[newResolution],
      )

      setResolution(newResolution)
      setStartDate(newStartDate)
    },
    [endDate],
  )

  const {
    data: aggregatedActivities,
    isInitialLoading: areAggregatedActivitiesLoading,
  } = useGetAggregatedActivities({
    granularity: resolution,
    patientId: patient.patientId,
    toDateInclusive: endDate.toISODate(),
    fromDate: startDate.toISODate(),
    fields: Object.values(HealthDataType),
  })

  const {
    data: lifeMilestonesData,
    isInitialLoading: areLifeMilestonesLoading,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteGetPatientLifeMilestones(
    {patientId: patient.patientId},
    {enabled: hasLifeMilestonesPermission},
  )

  const lifeMilestones = useMemo(
    () => lifeMilestonesData?.pages.flatMap(({records}) => records),
    [lifeMilestonesData],
  )

  useEffect(() => {
    if (!lifeMilestones?.length) {
      return
    }

    const lastLifeMilestoneDate =
      lifeMilestones[lifeMilestones.length - 1].date.date

    if (hasNextPage && DateTime.fromISO(lastLifeMilestoneDate) >= startDate) {
      fetchNextPage()
    }
  }, [lifeMilestones, startDate, hasNextPage, fetchNextPage])

  const {
    isLoading: areHealthDataLoading,
    healthSummaryData,
    latestActivities,
  } = useHealthData()

  const latestDataDetails = useLatestHealthDataDetails({
    latestActivities,
    lifestyleData: healthSummaryData?.lifestyleInformation,
    patient,
  })

  const isLoading =
    areAggregatedActivitiesLoading ||
    areHealthDataLoading ||
    areLifeMilestonesLoading

  return (
    <Card>
      <Container flex spacing={2} align="center" justify="space-between">
        <Typography
          weight="medium"
          size="xl"
          color={theme.colors.secondary.main}>
          {t('Health Trends')}
        </Typography>
        <Container flex spacing={2}>
          <TrendChartDateSelect
            endDate={endDate}
            startDate={startDate}
            setStartDate={setStartDate}
            resolution={resolution}
          />
          <MultiSelect
            options={selectOptions[EnumType.HealthDataType]}
            initialValues={selectedHealthDataTypes}
            onChange={selectHealthDataTypes}
            title={t('Data shown')}
            width={200}
          />
          <TrendChartResolutionSelect
            onChange={handleResolutionChange}
            resolution={resolution}
          />
        </Container>
      </Container>

      {/*
      // REINSTATE THIS BEFORE MERGING. FIGURE OUT HOW TO STOP IT FROM BLOCKING THE CLICK EVENTS.
      
      <TrendsLifeMilestones
        data={lifeMilestones ?? []}
        height={dimensions?.height}
        startDate={startDate}
        resolution={resolution}
      /> */}
      <Container flex spacing={1} direction="column" ref={ref}>
        <TrendChartAxis resolution={resolution} startDate={startDate} />
        {selectedHealthDataTypes.map((healthDataType, index) => (
          <React.Fragment key={healthDataType}>
            {!!index && <StyledHorizontalRule />}
            <TrendRow
              isLoading={isLoading}
              aggregatedData={aggregatedActivities}
              latestDataDetails={latestDataDetails[healthDataType]}
              healthDataType={healthDataType}
              resolution={resolution}
              startDate={startDate}
              onUpdate={onUpdate}
            />
          </React.Fragment>
        ))}
      </Container>
    </Card>
  )
}
