import styled from 'styled-components'

export const PatientsSuggestionsListWrapper = styled.div`
  max-height: 250px;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`

export const PatientsSuggestionsLoadingWrapper = styled.div`
  margin: ${({theme}) => theme.spacing.md};
`
