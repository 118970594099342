import React from 'react'

import {StyledDashboardCard} from './PatientDashboardCard.styled'

import {PopupMenuItem} from '~/components/general/popup/PopupMenu'
import {Container} from '~/components/general/container/Container'
import {Typography} from '~/components/general/typography/Typography'
import {PopupButton} from '~/components/general/popup/PopupButton'
import {theme} from '~/styles/theme'

type PatientDashboardCardProps = {
  children: React.ReactNode
  menuItems?: PopupMenuItem[]
  topRightComponent?: React.ReactNode
  title: string
}

export const PatientDashboardCard: React.FC<PatientDashboardCardProps> = ({
  children,
  menuItems,
  topRightComponent,
  title,
}) => (
  <StyledDashboardCard>
    <Container flex direction="column" spacing={1.5}>
      <Container flex justify="space-between" spacing={2} align="center">
        <Typography
          size="xl"
          weight="medium"
          color={theme.colors.secondary.main}>
          {title}
        </Typography>
        {menuItems && <PopupButton menuItems={menuItems} />}
        {topRightComponent}
      </Container>
      {children}
    </Container>
  </StyledDashboardCard>
)
