import * as React from 'react'

import {IconProps} from '.'

function HeartCheck({color, size, isFilled}: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={isFilled ? color : 'none'}
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4237 6.1884C10.654 5.26382 9.50922 4.73446 8.30624 4.74679L8.29855 4.74687V4.74683C5.65065 4.74683 3.74634 7.2739 3.74634 9.75423C3.74634 12.3455 5.3709 14.7211 7.3346 16.4992C8.3049 17.3778 9.32658 18.0807 10.2028 18.5602C10.6411 18.8 11.0337 18.9788 11.3574 19.0958C11.6962 19.2182 11.9071 19.2535 12.0001 19.2535C12.0931 19.2535 12.304 19.2182 12.6428 19.0958C12.9665 18.9788 13.3591 18.8 13.7973 18.5602C14.6736 18.0807 15.6953 17.3778 16.6656 16.4992C18.6293 14.7211 20.2538 12.3455 20.2538 9.75423C20.2538 7.2739 18.3495 4.74683 15.7016 4.74683H15.6939V4.74679C14.491 4.73446 13.3462 5.26383 12.5765 6.1884C12.434 6.35957 12.2228 6.45855 12.0001 6.45855C11.7774 6.45855 11.5662 6.35957 11.4237 6.1884ZM12.0001 4.63371C10.987 3.7345 9.66858 3.23371 8.29472 3.24683C4.60217 3.24921 2.24634 6.68331 2.24634 9.75423C2.24634 12.9664 4.23428 15.7154 6.32778 17.6111C7.38607 18.5694 8.50415 19.3406 9.48284 19.8761C9.97211 20.1438 10.4358 20.3577 10.8476 20.5065C11.2445 20.6499 11.6484 20.7535 12.0001 20.7535C12.3517 20.7535 12.7557 20.6499 13.1525 20.5065C13.5644 20.3577 14.0281 20.1438 14.5173 19.8761C15.496 19.3406 16.6141 18.5694 17.6724 17.6111C19.7659 15.7154 21.7538 12.9664 21.7538 9.75423C21.7538 6.68331 19.398 3.24921 15.7055 3.24683C14.3316 3.23371 13.0132 3.7345 12.0001 4.63371Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.3849 10.0441C14.6779 10.3369 14.6781 10.8118 14.3854 11.1048L12.0684 13.4238C11.9277 13.5646 11.7369 13.6437 11.5379 13.6437C11.3389 13.6437 11.148 13.5646 11.0073 13.4238L9.61474 12.0302C9.32196 11.7372 9.32213 11.2624 9.61512 10.9696C9.90812 10.6768 10.383 10.677 10.6758 10.97L11.5378 11.8326L13.3242 10.0446C13.617 9.75159 14.0919 9.75138 14.3849 10.0441Z"
        fill={color}
      />
    </svg>
  )
}

export default HeartCheck
