import {MeasurementQuantityType} from '@generated/models'

export type {
  AnswerDto as CompletedQuestionnaireAnswer,
  AssignedQuestionnaireDto as AssignedQuestionnaire,
  AssignedQuestionnaireShortDto as AssignedQuestionnaireInfo,
  AssignQuestionnaireDto as AssignQuestionnaireRequest,
  ManualMeasurementDto as QuestionnaireMeasurementRequest,
  MeasurementRecordDto as QuestionnaireMeasurement,
  NoteDto as AssignedQuestionnaireNote,
  QuestionChoiceDto as QuestionChoice,
  QuestionDto as Question,
  QuestionnaireDto as Questionnaire,
  QuestionnaireSection,
  QuestionnaireShortDto as QuestionnaireInfo,
} from '@generated/models'

export {
  AssignedQuestionnaireStatusDto as QuestionnaireStatus,
  MeasurementQuantityType as MonthlySurveyType,
  QuestionType,
  QuestionnaireSource,
  QuestionnaireType,
  RepeatPeriod as QuestionnaireRepeatPeriod,
} from '@generated/models'

export type SurveyTypeWithScores = Exclude<
  MeasurementQuantityType,
  MeasurementQuantityType.BASIC_HEALTH
>

export type Answer = string | boolean[] | number

export type QuestionnaireAnswers = Record<string, Answer | null>

export type RawQuestionnaireAnswer = {
  questionId: string
  choices?: string[]
  value?: string
}
