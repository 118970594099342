import styled from 'styled-components'

export const PatientDataAccordionWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
`

export const PatientDataAccordionShareSection = styled.span`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
`

export const PatientDataAccordionNotSharedBadge = styled.span`
  margin-right: ${({theme}) => theme.spacing.md};
  color: ${({theme}) => theme.colors.grey.main};
`
