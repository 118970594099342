import styled from 'styled-components'

import {Chip} from '~/components/general/chip/Chip'
import {Typography} from '~/components/general/typography/Typography'

export const StyledHeaderText = styled(Typography)`
  margin: 0 ${({theme}) => theme.spacing.xs} ${({theme}) => theme.spacing.md} 0;
`

export const ContentColumn = styled.div`
  width: 50%;
`

export const StyledChip = styled(Chip)`
  margin: ${({theme}) => theme.spacing.xs} 0 ${({theme}) => theme.spacing.md} 0;
`

export const RowHeader = styled(Typography)`
  margin-top: ${({theme}) => theme.spacing.lg};
`

export const StyledTable = styled.table`
  width: 100%;
`
