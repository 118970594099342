import * as React from 'react'

import {IconProps} from '.'

function Surgery({color, size, isFilled}: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill={isFilled ? color : 'none'}
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.44766 9.27868L2.833 12.3159C2.5865 13.5365 3.63814 14.6265 4.86745 14.4224L7.26125 14.0263C7.83909 13.9302 8.42814 13.9302 9.00598 14.0263L11.3862 14.4208C12.6219 14.6257 13.6799 13.5301 13.4318 12.3031L12.8204 9.27948C12.6795 8.58159 12.6795 7.86289 12.8204 7.165L13.4318 4.14134C13.6799 2.91443 12.6219 1.81877 11.3862 2.02366L9.00598 2.41822C8.42814 2.51426 7.83909 2.51426 7.26125 2.41822L4.88106 2.02286C3.64534 1.81797 2.5881 2.91363 2.83621 4.14054L3.44766 7.1642C3.58852 7.86209 3.58852 8.58159 3.44766 9.27868Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.203 9.20117V14.8035C17.203 16.5714 15.7696 18.0048 14.0017 18.0048H11.3341C9.56621 18.0048 8.13281 16.5714 8.13281 14.8035V13.956"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.1772 5.40017C11.7839 5.85956 9.52612 5.85956 8.13354 5.40017C6.74096 4.94078 4.48322 4.94078 3.08984 5.40017"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.35547 8.66579H9.91295"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.35547 10.8884H9.91295"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default Surgery
