import {useTranslation} from 'react-i18next'
import React, {useContext, useEffect} from 'react'
import {Outlet, NavLink, useParams} from 'react-router-dom'
import {MISSING_ID} from '@common/utils'
import {AssignedQuestionnaire} from '@common/models'

import {Layout} from '~/components/layout/Layout'
import {
  QuestionnairesHeader,
  StyledHeaderText,
} from '~/components/questionnaires/Questionnaire.styled'
import {Container} from '~/components/general/container/Container'
import {useGetAssignedQuestionnaire} from '~/api'
import {Loading} from '~/components/general/loading/Loading'
import {Typography} from '~/components/general/typography/Typography'
import {QuestionnairesContext} from '~/components/questionnaires/QuestionnairesContext'
import {theme} from '~/styles/theme'

type ChildProps = {
  isLoading: boolean
  data: AssignedQuestionnaire | undefined
}

const Child: React.FC<ChildProps> = ({isLoading, data}) => {
  const {t} = useTranslation()

  if (isLoading) {
    return <Loading />
  }
  if (!data) {
    return <Typography>{t('Missing questionnaire data')}</Typography>
  }
  return <Outlet context={data} />
}

export const PatientQuestionnaire: React.FC = () => {
  const {t} = useTranslation()
  const {assignedQuestionnaireId} = useParams()
  const {setQuestionnaire} = useContext(QuestionnairesContext)

  const {data, isInitialLoading} = useGetAssignedQuestionnaire({
    assignedQuestionnaireId: assignedQuestionnaireId || MISSING_ID,
  })

  useEffect(
    () => setQuestionnaire(data?.questionnaire),
    [data?.questionnaire, setQuestionnaire],
  )

  return (
    <Layout questionnaires>
      <QuestionnairesHeader>
        <Container flex align="center" spacing={5}>
          <NavLink to="details">
            <StyledHeaderText size="lg" color={theme.colors.grey.main}>
              {t('Details')}
            </StyledHeaderText>
          </NavLink>
          <NavLink to="history">
            <StyledHeaderText size="lg" color={theme.colors.grey.main}>
              {t('History')}
            </StyledHeaderText>
          </NavLink>
        </Container>
      </QuestionnairesHeader>
      <Child isLoading={isInitialLoading} data={data} />
    </Layout>
  )
}
