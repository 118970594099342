import React from 'react'

import {StyledTimeline, TimelineLine, TimelineSubcards} from './Timeline.styled'

import {
  TimelineCard,
  TimelineCardProps,
} from '~/components/general/timeline/TimelineCard'

type SubCard = {
  title: string
  subtitle?: string | null
  danger?: boolean
}

export type TimelineCardWithSubCardProps = TimelineCardProps & {
  subcards?: SubCard[]
}
type TimelineProps = {
  cards: TimelineCardWithSubCardProps[]
  direction?: 'row' | 'column'
}

export const Timeline: React.FC<TimelineProps> = ({
  cards,
  direction = 'row',
}) => (
  <StyledTimeline>
    <TimelineLine />
    {cards.map(({subcards, ...cardProps}, index) => (
      <React.Fragment key={`${cardProps.title}${index}`}>
        <TimelineCard {...cardProps} direction={direction} />
        {!!subcards && (
          <TimelineSubcards>
            <TimelineLine subcard />
            {subcards.map((subcard, subIndex) => (
              <TimelineCard
                key={`${subcard.title}${subIndex}`}
                title={subcard.title}
                subtitle={subcard.subtitle}
                subcard
                danger={subcard.danger}
              />
            ))}
          </TimelineSubcards>
        )}
      </React.Fragment>
    ))}
  </StyledTimeline>
)
