import * as React from 'react'

import {IconProps} from '.'

function SelectHand({color, size, isFilled}: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={isFilled ? color : 'none'}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.554 11H4C2.895 11 2 10.105 2 9V4C2 2.895 2.895 2 4 2H20C21.105 2 22 2.895 22 4V9C22 10.105 21.105 11 20 11H10.554"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5539 13V8.5C10.5539 7.672 9.88194 7 9.05394 7V7C8.22594 7 7.55394 7.672 7.55394 8.5V11V16L6.65294 15.099C6.04594 14.492 5.06194 14.492 4.45494 15.099V15.099C3.93094 15.623 3.84994 16.444 4.26094 17.06L6.95994 21.109C7.33094 21.666 7.95594 22 8.62494 22H13.9129C14.9219 22 15.7719 21.249 15.8979 20.248L16.4399 15.911C16.5729 14.844 15.8389 13.862 14.7779 13.689L10.5539 13Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.5 5.5L16 8L14.5 6.5"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SelectHand
