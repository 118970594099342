import React from 'react'
import {isTruthy} from '@common/utils'

import {StyledAvatar} from './Avatar.styled'

import {Typography} from '~/components/general/typography/Typography'
import {theme} from '~/styles/theme'
import {useGetPatientProfilePicture} from '~/api'

type Name = {
  firstName?: string | null
  secondName?: string | null
}

type AvatarSize = 'sm' | 'md' | 'lg'

type Props = {
  size?: AvatarSize
  name: Name | undefined
  patientId?: string
}

const getSize = (size: AvatarSize) => {
  switch (size) {
    case 'lg':
      return '96px'
    case 'sm':
      return theme.spacing.xl
    default:
      return theme.spacing.xxxl
  }
}

const getInitials = ({firstName, secondName}: Name) =>
  [firstName, secondName]
    .filter(isTruthy)
    .map((word) => word[0])
    .join('')
    .toUpperCase()

export const Avatar: React.FC<Props> = ({
  size = 'md',
  name = {},
  patientId = '',
}) => {
  const sizeInPx = getSize(size)
  const {data} = useGetPatientProfilePicture(
    {patientId},
    {enabled: !!patientId},
  )

  const profilePictureUrl = data?.url

  return (
    <StyledAvatar default={!profilePictureUrl} size={sizeInPx}>
      {profilePictureUrl ? (
        <img src={profilePictureUrl} alt="avatar" width="100%" />
      ) : (
        <Typography size="lg">{getInitials(name)}</Typography>
      )}
    </StyledAvatar>
  )
}
