import {PractitionersPatient} from '@common/models'
import React, {useContext, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {MISSING_ID} from '@common/utils'

import {
  FooterContainer,
  StyledText,
  PatientBadgeWrapper,
  StyledBadgeText,
  StyledSelect,
} from './ShareMaterialsModal.styled'

import {useShareMaterials} from '~/api'
import {
  getFilesFromFolders,
  MaterialsContext,
} from '~/components/educationalMaterials/MaterialsContext'
import {Avatar} from '~/components/general/avatar/Avatar'
import {Button} from '~/components/general/button/Button'
import {Icon} from '~/components/general/icon/Icon'
import {Modal} from '~/components/general/modal/Modal'
import {Typography} from '~/components/general/typography/Typography'
import {TextArea} from '~/components/general/textArea/TextArea'
import {theme} from '~/styles/theme'
import {PatientsSuggestionsList} from '~/components/general/select/PatientsSuggestionsList'
import {formatName} from '~/utils'

type PatientBadgeProps = {
  patient: PractitionersPatient
  removePatient: () => void
}

const PatientBadge: React.FC<PatientBadgeProps> = ({
  patient,
  removePatient,
}) => (
  <PatientBadgeWrapper>
    <Avatar name={patient} patientId={patient.patientId} size="sm" />
    <StyledBadgeText maxWidth={300} weight="medium">
      {formatName(patient)}
    </StyledBadgeText>
    <Icon
      color={theme.colors.grey.main}
      name="Close"
      size="xs"
      onClick={removePatient}
    />
  </PatientBadgeWrapper>
)

type ShareMaterialsModalProps = {
  onClose: () => void
  isVisible: boolean
}

export const ShareMaterialsModal: React.FC<ShareMaterialsModalProps> = ({
  isVisible,
  onClose,
}) => {
  const {t} = useTranslation()
  const materialsContext = useContext(MaterialsContext)

  const [displayMissingPatientText, setDisplayMissingPatientText] =
    useState(false)
  const [selectedPatient, setSelectedPatient] = useState<
    PractitionersPatient | undefined
  >(materialsContext.selectedPatient)
  const [query, setQuery] = useState('')
  const [note, setNote] = useState('')

  useEffect(() => setDisplayMissingPatientText(false), [isVisible])

  const {mutate} = useShareMaterials()

  const handlePatientClick = (patient: PractitionersPatient) => {
    setQuery('')
    setSelectedPatient(patient)
  }

  const onShare = () => {
    if (!selectedPatient?.patientId) {
      setDisplayMissingPatientText(true)
      return
    }

    mutate(
      {
        patientIds: [selectedPatient?.patientId ?? MISSING_ID],
        fileIds: [...getFilesFromFolders(materialsContext.selectedMaterials)],
        note,
      },
      {
        onSuccess: () => {
          setSelectedPatient(undefined)
          setNote('')
          onClose()
        },
        onError: (error) => {
          // [CUSH-655] FIXME: Add error logging
          // eslint-disable-next-line no-console
          console.error(error)
        },
      },
    )
  }

  const materialsCountText = (
    <>
      {t('You are sharing ')}
      <StyledText>
        {t('{{count}} material', {
          count: materialsContext.selectedMaterialsCount,
        })}
      </StyledText>
    </>
  )

  const modalFooter = (
    <FooterContainer>
      <Typography
        color={
          displayMissingPatientText ? theme.colors.red : theme.colors.grey.main
        }>
        {displayMissingPatientText
          ? t('No patient selected.')
          : materialsCountText}
      </Typography>
      <Button onClick={onShare}>{t('Share')}</Button>
    </FooterContainer>
  )

  return (
    <Modal
      onClose={onClose}
      isVisible={isVisible}
      footer={modalFooter}
      header={t('Share materials')}>
      <Typography color={theme.colors.grey.main}>
        {t(
          'Choose the patient you want to share these materials with. You can also attach your own message or use the one below.',
        )}
      </Typography>
      <StyledSelect
        search
        disabled={!!selectedPatient}
        placeholder={t('Start typing name or surname')}
        onChange={(event) => setQuery(event.target.value)}
        value={query}
        onFocus={() => setDisplayMissingPatientText(false)}
        selectedOption={
          !!selectedPatient && (
            <PatientBadge
              patient={selectedPatient}
              removePatient={() => setSelectedPatient(undefined)}
            />
          )
        }>
        <PatientsSuggestionsList
          onPatientClick={handlePatientClick}
          query={query}
        />
      </StyledSelect>
      <TextArea
        value={note}
        onChange={(event) => setNote(event.target.value)}
      />
    </Modal>
  )
}
