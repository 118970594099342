import React from 'react'
import {formatExactOrEstimatedDate} from '@common/utils'

import {
  TimelineDataCell,
  TimelineDot,
  TimelineDotCell,
  TimelineDataRow,
  TimelineIcon,
} from './TimelineTable.styled'
import {Icon} from '../icon/Icon'
import {DataSourceIndicator} from '../datasourceIndicator/DatasourceIndicator'
import {Typography} from '../typography/Typography'

import {theme} from '~/styles/theme'
import {iconNameMap} from '~/icons'
import {TimelineItem, TimelineTableItemType} from '~/utils/timeline'

type TimelineRowProps = {
  data: TimelineItem
  onClick: () => void
}

type TimelineIconSpec = {
  iconName: keyof typeof iconNameMap
  backgroundColor: string
}

const typeToIconMap: Record<TimelineTableItemType, TimelineIconSpec> = {
  [TimelineTableItemType.UNKNOWN]: {
    iconName: 'SelectHand',
    backgroundColor: theme.colors.chip.background.green,
  },
  [TimelineTableItemType.ALLERGY]: {
    iconName: 'Shield',
    backgroundColor: theme.colors.other.graphic,
  },
  [TimelineTableItemType.BIRTH]: {
    iconName: 'ArrowDown',
    backgroundColor: theme.colors.grey.grey20,
  },
  [TimelineTableItemType.CHOLESTEROL]: {
    iconName: 'Shield',
    backgroundColor: theme.colors.secondary.orange50,
  },
  [TimelineTableItemType.CONDITION]: {
    iconName: 'FirstAid',
    backgroundColor: theme.colors.accent.warning100,
  },
  [TimelineTableItemType.MEDICAL_TEST]: {
    iconName: 'Drop',
    backgroundColor: theme.colors.primary.orange50,
  },
  [TimelineTableItemType.MEDICATION]: {
    iconName: 'Pills',
    backgroundColor: theme.colors.primary.blue200,
  },
  [TimelineTableItemType.SURGERY]: {
    iconName: 'Surgery',
    backgroundColor: theme.colors.chip.background.green,
  },
  [TimelineTableItemType.VACCINATION]: {
    iconName: 'Vaccination',
    backgroundColor: theme.colors.secondary.orange200,
  },
  [TimelineTableItemType.APPOINTMENT]: {
    iconName: 'Calendar',
    backgroundColor: theme.colors.indicator.body.green,
  },
  [TimelineTableItemType.LIFE_MILESTONE]: {
    iconName: 'Clock',
    backgroundColor: theme.colors.grey.grey150,
  },
}

export const TimelineRow = ({data, onClick}: TimelineRowProps) => (
  <TimelineDataRow onClick={onClick}>
    <TimelineDotCell>
      <TimelineDot />
    </TimelineDotCell>
    <TimelineDataCell>
      {data.itemType && (
        <TimelineIcon
          backgroundColor={typeToIconMap[data.itemType].backgroundColor}>
          <Icon name={typeToIconMap[data.itemType].iconName} size="md" />
        </TimelineIcon>
      )}
    </TimelineDataCell>
    <TimelineDataCell nowrap>
      <Typography color={theme.colors.grey.main} size="sm">
        {formatExactOrEstimatedDate(data.itemDate)}
      </Typography>
    </TimelineDataCell>
    <TimelineDataCell>{data.description}</TimelineDataCell>
    <TimelineDataCell>{data.facilityName}</TimelineDataCell>
    <TimelineDataCell>{data.healthJourneyType}</TimelineDataCell>
    <TimelineDataCell>
      <DataSourceIndicator source={data.source} />
    </TimelineDataCell>
  </TimelineDataRow>
)
