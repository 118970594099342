import * as React from 'react'

import {IconProps} from '.'

function DocumentList({color, size, isFilled}: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={isFilled ? color : 'none'}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.24805 16.5018C6.24805 16.0876 6.58383 15.7518 6.99805 15.7518H10.9997C11.4139 15.7518 11.7497 16.0876 11.7497 16.5018C11.7497 16.916 11.4139 17.2518 10.9997 17.2518H6.99805C6.58383 17.2518 6.24805 16.916 6.24805 16.5018Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.0326 15.0333C18.3254 15.3262 18.3254 15.801 18.0326 16.0939L16.1562 17.9703C16.0155 18.111 15.8247 18.19 15.6257 18.19C15.4267 18.1899 15.2359 18.1108 15.0953 17.9701L13.9704 16.8443C13.6777 16.5512 13.6779 16.0764 13.9709 15.7836C14.2639 15.4908 14.7388 15.491 15.0315 15.784L15.6261 16.3791L16.9719 15.0333C17.2648 14.7404 17.7397 14.7404 18.0326 15.0333Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.24609 6.99621C2.24609 4.37286 4.37274 2.24622 6.99609 2.24622H17.0036C19.6269 2.24622 21.7536 4.37286 21.7536 6.99622V17.0037C21.7536 19.6271 19.6269 21.7537 17.0036 21.7537H6.99609C4.37274 21.7537 2.24609 19.6271 2.24609 17.0037V6.99621ZM6.99609 3.74622C5.20117 3.74622 3.74609 5.20129 3.74609 6.99621V17.0037C3.74609 18.7986 5.20117 20.2537 6.99609 20.2537H17.0036C18.7985 20.2537 20.2536 18.7986 20.2536 17.0037V6.99622C20.2536 5.20129 18.7985 3.74622 17.0036 3.74622H6.99609Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.24805 13.0004C6.24805 12.5862 6.58383 12.2504 6.99805 12.2504H15.0014C15.4156 12.2504 15.7514 12.5862 15.7514 13.0004C15.7514 13.4146 15.4156 13.7504 15.0014 13.7504H6.99805C6.58383 13.7504 6.24805 13.4146 6.24805 13.0004Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.49805 5.74768C8.91226 5.74768 9.24805 6.08347 9.24805 6.49768V9.49893C9.24805 9.91314 8.91226 10.2489 8.49805 10.2489C8.08383 10.2489 7.74805 9.91314 7.74805 9.49893V6.49768C7.74805 6.08347 8.08383 5.74768 8.49805 5.74768Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.24805 7.99829C6.24805 7.58408 6.58383 7.24829 6.99805 7.24829H9.9993C10.4135 7.24829 10.7493 7.58408 10.7493 7.99829C10.7493 8.4125 10.4135 8.74829 9.9993 8.74829H6.99805C6.58383 8.74829 6.24805 8.4125 6.24805 7.99829Z"
        fill={color}
      />
    </svg>
  )
}

export default DocumentList
