import styled from 'styled-components'

import {getIconSize} from '../icon/Icon.styled'

export const TrendIndicatorContainer = styled.div`
  ${getIconSize('xs')};
  display: flex;
  align-items: center;
  justify-content: center;
`
