import {endpointKeys} from '@common/api'
import {
  getPagedResponseNormaliser,
  patientsSharedMaterialsOverviewNormaliser,
  physitrackProgramNormaliser,
  sharedMaterialsContentsNormaliser,
  staticFileNormaliser,
} from '@common/normalisers'
import {
  HealthRecordsUrlParams,
  IdUrlParam,
  PatientIdUrlParam,
} from '@common/api/types'
import {
  MaterialsCondition,
  PagedResponse,
  PatientsSharedMaterialsOverview,
  PhysitrackProgram,
  ShareMaterialsRequest,
  StaticFile,
} from '@common/models'

import {useApi, useWebGet, useWebSet} from './helpers'

export const useGetMaterial = (params: IdUrlParam) =>
  useWebGet<StaticFile>(
    [endpointKeys.educationalMaterial, params.id],
    useApi().educationalMaterial(params),
    staticFileNormaliser,
    {cacheTime: 0},
  )

export const useGetMaterialsContents = () =>
  useWebGet<MaterialsCondition[]>(
    [endpointKeys.educationalMaterials],
    useApi().educationalMaterials(),
    sharedMaterialsContentsNormaliser,
  )

export const useShareMaterials = () =>
  useWebSet<ShareMaterialsRequest>(
    [endpointKeys.shareMaterials],
    useApi().shareMaterials(),
    'POST',
    undefined,
    [
      [endpointKeys.shareMaterials],
      [endpointKeys.sharedMaterials],
      [endpointKeys.patientSharedMaterials],
      [endpointKeys.sharedMaterialsContents],
    ],
  )

export const useGetPatientSharedMaterials = (params: PatientIdUrlParam) =>
  useWebGet<PatientsSharedMaterialsOverview>(
    [endpointKeys.sharedMaterials, params],
    useApi().sharedMaterials(params),
    patientsSharedMaterialsOverviewNormaliser,
  )

export const useGetPhysitrackPrograms = (params: HealthRecordsUrlParams) =>
  useWebGet<PagedResponse<PhysitrackProgram>>(
    [endpointKeys.physitrackPrograms, params],
    useApi().physitrackPrograms(params),
    getPagedResponseNormaliser(physitrackProgramNormaliser),
  )
