import {AssertTypeFn} from './general'
import {
  AppointmentFile,
  ObstetricsFile,
  PregnancyJourneyFile,
  RowGroup,
  RowGroupTableItem,
  RowGroupType,
  StaticFile,
  Upload,
  UploadStatus,
  UploadType,
} from '../models'
import {
  assertArray,
  assertArrayOrNull,
  assertBoolean,
  assertEnum,
  assertObjectOrNull,
  assertString,
  assertStringOrNull,
} from '../utils'

export const rowNormaliser: AssertTypeFn<RowGroupTableItem> = (obj) => ({
  field: assertString(obj.field),
  cells: assertArray(obj.cells, assertString),
})

export const rowGroupNormaliser: AssertTypeFn<RowGroup> = (obj) => ({
  field: assertStringOrNull(obj.field),
  type: assertEnum(obj.type, RowGroupType),
  value: assertStringOrNull(obj.value),
  line: assertStringOrNull(obj.line),
  subLine: assertStringOrNull(obj.subLine),
  values: assertArrayOrNull(obj.values, assertString),
  rows: assertArrayOrNull(obj.rows, rowNormaliser),
})

export const uploadNormaliser: AssertTypeFn<Upload> = (obj) => ({
  id: assertString(obj.id),
  path: assertString(obj.path),
  patientId: assertString(obj.patientId),
  status: assertEnum(obj.status, UploadStatus),
  statusAcknowledged: assertBoolean(obj.statusAcknowledged),
  uploaded: assertString(obj.uploaded),
  type: assertEnum(obj.type, UploadType),
})

export const staticFileNormaliser: AssertTypeFn<StaticFile> = (obj) => ({
  expiresAt: assertString(obj.expiresAt),
  url: assertString(obj.url),
})

export const staticFileOrNullNormaliser: AssertTypeFn<StaticFile | null> = (
  obj,
) => assertObjectOrNull(obj, staticFileNormaliser)

export const pregnancyJourneyFileNormaliser: AssertTypeFn<
  PregnancyJourneyFile
> = (obj) => ({
  contentType: assertString(obj.contentType),
  id: assertString(obj.id),
  name: assertString(obj.name),
  note: assertStringOrNull(obj.note),
  uploadedAt: assertString(obj.uploadedAt),
})

export const pregnancyJourneyFilesNormaliser: AssertTypeFn<
  PregnancyJourneyFile[]
> = (arr) => assertArray(arr, pregnancyJourneyFileNormaliser)

export const obstetricsFileNormaliser: AssertTypeFn<ObstetricsFile> = (
  obj,
) => ({
  contentType: assertString(obj.contentType),
  fileLinkId: assertString(obj.fileLinkId),
  id: assertString(obj.id),
  name: assertString(obj.name),
  note: assertStringOrNull(obj.note),
  uploadedAt: assertString(obj.uploadedAt),
})

export const appointmentFileNormaliser: AssertTypeFn<AppointmentFile> = (
  obj,
) => ({
  contentType: assertString(obj.contentType),
  id: assertString(obj.id),
  name: assertString(obj.name),
  uploadedAt: assertString(obj.uploadedAt),
})
