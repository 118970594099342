import React, {useContext} from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'

import {PatientSharedMaterialsHeaderWrapper} from './PatientSharedMaterialsHeader.styled'

import {Button} from '~/components/general/button/Button'
import {MaterialsContext} from '~/components/educationalMaterials/MaterialsContext'
import {PatientsContext} from '~/components/patients/PatientsContext'

export const PatientSharedMaterialsHeader: React.FC = () => {
  const {t} = useTranslation()
  const {setSelectedPatient} = useContext(MaterialsContext)
  const {patient} = useContext(PatientsContext)
  const navigate = useNavigate()

  const handleShareMaterialsClick = () => {
    setSelectedPatient(patient)
    navigate('/app/materials')
  }

  return (
    <PatientSharedMaterialsHeaderWrapper>
      <Button size="sm" onClick={handleShareMaterialsClick}>
        {t('Share new materials')}
      </Button>
    </PatientSharedMaterialsHeaderWrapper>
  )
}
