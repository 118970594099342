/* eslint-disable @typescript-eslint/no-use-before-define */
import {AssertTypeFn} from './general'
import {
  CategoryContents,
  ConditionContents,
  MaterialsCondition,
  MaterialsFile,
  MaterialsFolder,
  MaterialsItem,
  MaterialsItemType,
  PatientsSharedMaterialsOverview,
  PhysitrackProgram,
  PhysitrackProgramSource,
  JourneyArticle,
  JourneyArticles,
  SharedMaterial,
  SharedMaterialSource,
} from '../models'
import {
  assertArray,
  assertBoolean,
  assertEnumOrNull,
  assertNumber,
  assertString,
  assertStringOrNull,
  assertValue,
} from '../utils'

export const sharedMaterialNormaliser: AssertTypeFn<SharedMaterial> = (
  obj,
) => ({
  bookmarked: assertBoolean(obj.bookmarked),
  condition: assertString(obj.condition),
  fileId: assertString(obj.fileId),
  fileName: assertString(obj.fileName),
  patientId: assertString(obj.patientId),
  previewImageUrl: assertStringOrNull(obj.previewImageUrl),
  read: assertBoolean(obj.read),
  sharedBy: assertArray(obj.sharedBy, assertString),
})

export const sharedMaterialsNormaliser: AssertTypeFn<SharedMaterial[]> = (
  arr,
) => assertArray(arr, sharedMaterialNormaliser)

export const sharedMaterialSourceNormaliser: AssertTypeFn<
  SharedMaterialSource
> = (obj) => ({
  expiresAt: assertString(obj.expiresAt),
  externalId: assertStringOrNull(obj.externalId),
  fileId: assertString(obj.fileId),
  fileUrl: assertString(obj.fileUrl),
})

export const physitrackProgramNormaliser: AssertTypeFn<PhysitrackProgram> = (
  obj,
) => ({
  accessCode: assertString(obj.accessCode),
  appointmentId: assertStringOrNull(obj.appointmentId),
  assignedAt: assertString(obj.assignedAt),
  endDate: assertString(obj.endDate),
  externalSourceId: assertStringOrNull(obj.externalSourceId),
  externalSourceType: assertEnumOrNull(
    obj.externalSourceType,
    PhysitrackProgramSource,
  ),
  facilityName: assertStringOrNull(obj.facilityName),
  id: assertString(obj.id),
  name: assertString(obj.name),
  ssoUrl: assertString(obj.ssoUrl),
  startDate: assertString(obj.startDate),
  yearOfBirth: assertNumber(obj.yearOfBirth),
})

export const physitrackProgramsNormaliser: AssertTypeFn<PhysitrackProgram[]> = (
  arr,
) => assertArray(arr, physitrackProgramNormaliser)

const materialsFileNormaliser: AssertTypeFn<MaterialsFile> = (obj) => ({
  id: assertString(obj.id),
  image: assertStringOrNull(obj.image),
  name: assertString(obj.name),
  type: assertValue(obj.type, MaterialsItemType.FILE),
})

const materialsFolderNormaliser: AssertTypeFn<MaterialsFolder> = (obj) => ({
  id: assertString(obj.id),
  name: assertString(obj.name),
  type: assertValue(obj.type, MaterialsItemType.FOLDER),
  children: obj.children
    ? assertArray(obj.children, materialsItemNormaliser)
    : undefined,
})

const materialsItemNormaliser: AssertTypeFn<MaterialsItem> = (obj) =>
  obj.type === MaterialsItemType.FILE
    ? materialsFileNormaliser(obj)
    : materialsFolderNormaliser(obj)

const categoryContentsNormaliser: AssertTypeFn<CategoryContents> = (obj) => ({
  name: assertString(obj.name),
  subCategories: assertArray(obj.subCategories, materialsItemNormaliser),
})

const conditionContentsNormaliser: AssertTypeFn<ConditionContents> = (obj) => ({
  condition: assertString(obj.condition),
  id: assertString(obj.id),
  categories: assertArray(obj.categories, categoryContentsNormaliser),
})

const materialsConditionNormaliser: AssertTypeFn<MaterialsCondition> = (
  obj,
) => ({
  name: assertString(obj.name),
  structure: conditionContentsNormaliser(obj.structure),
})

export const sharedMaterialsContentsNormaliser: AssertTypeFn<
  MaterialsCondition[]
> = (arr) => assertArray(arr, materialsConditionNormaliser)

export const patientsSharedMaterialsOverviewNormaliser: AssertTypeFn<
  PatientsSharedMaterialsOverview
> = (obj) =>
  Object.keys(obj).reduce((res, key) => {
    res[key] = sharedMaterialsNormaliser(obj[key])
    return res
  }, {} as PatientsSharedMaterialsOverview)

export const journeyArticleNormaliser: AssertTypeFn<JourneyArticle> = (
  obj,
) => ({
  id: assertString(obj.id),
  name: assertString(obj.name),
  previewImageUrl: assertStringOrNull(obj.previewImageUrl),
})

export const journeyArticlesNormaliser: AssertTypeFn<JourneyArticles> = (
  obj,
) => ({
  articles: assertArray(obj.articles, journeyArticleNormaliser),
})
