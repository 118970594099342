import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {LinkValidationResponse, SharingLinkStatus} from '@common/models'

import {Container} from '../general/container/Container'
import {StyledContentContainer} from './LinkSharingPasswordModal.styled'
import {Loading} from '../general/loading/Loading'

import {Button} from '~/components/general/button/Button'
import {Input} from '~/components/general/input/Input'
import {BaseModal} from '~/components/general/modal/BaseModal'
import {Typography} from '~/components/general/typography/Typography'
import {useValidateLinkPassword} from '~/api/linkSharing'

type LinkSharingPasswordModalProps = {
  onVerifySuccess: (password: string, response: LinkValidationResponse) => void
  token: string
}

export const LinkSharingPasswordModal: React.FC<
  LinkSharingPasswordModalProps
> = ({onVerifySuccess, token}) => {
  const {t} = useTranslation()
  const [password, setPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState<string>()

  const {isFetching, refetch} = useValidateLinkPassword(
    {token, password},
    {enabled: false},
  )

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setErrorMessage(undefined)
    setPassword(event.target.value)
  }

  const verifyPassword = async () => {
    if (!password) {
      return
    }

    const response = (await refetch()).data
    const isVerificationSuccessful =
      response?.status === SharingLinkStatus.VALID ||
      response?.status === SharingLinkStatus.EXPIRED

    if (isVerificationSuccessful) {
      onVerifySuccess(password, response)
    } else if (response?.status === SharingLinkStatus.INVALID) {
      setErrorMessage(t('Wrong password or link url'))
    }
  }

  const handleEnterPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      verifyPassword()
    }
  }

  return (
    <BaseModal isVisible>
      <StyledContentContainer>
        <Container flex direction="column" spacing={2} align="flex-start">
          <Typography size="lg" weight="medium">
            {t('Enter password')}
          </Typography>
          <Input
            type="password"
            value={password}
            onChange={handleInputChange}
            errorMessage={errorMessage}
            onKeyDown={handleEnterPress}
          />
          {isFetching && <Loading />}
          <Button onClick={verifyPassword} disabled={!password || isFetching}>
            {t('Continue')}
          </Button>
        </Container>
      </StyledContentContainer>
    </BaseModal>
  )
}
