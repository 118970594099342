import React from 'react'

import {IconButton} from '../iconButton/IconButton'
import {Popup} from './Popup'
import {PopupMenu, PopupMenuItem} from './PopupMenu'
import {Icon, IconName} from '../icon/Icon'
import {PopupSide} from './Popup.styled'

type PopupButtonProps = {
  className?: string
  menuItems: PopupMenuItem[]
  iconName?: IconName
  showAsButton?: boolean
  isSelected?: boolean
  popupSide?: PopupSide
}

export const PopupButton: React.FC<PopupButtonProps> = ({
  className,
  menuItems,
  iconName = 'Dots',
  showAsButton = true,
  isSelected = false,
  popupSide,
}) => (
  <Popup
    className={className}
    mode="click"
    content={<PopupMenu menuItems={menuItems} />}
    popupSide={popupSide}>
    {showAsButton ? (
      <IconButton name={iconName} color="secondary" tabIndex={0} />
    ) : (
      <Icon name={iconName} size="sm" isFilled={isSelected} />
    )}
  </Popup>
)
