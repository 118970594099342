import {AssertNullableTypeFn, AssertTypeFn} from './general'
import {
  Alcohol,
  BodyMeasurements,
  IsSmoker,
  LifestyleInformation,
  SleepOverview,
  TobaccoType,
  TobaccoUsage,
} from '../models'
import {
  assertBooleanOrNull,
  assertEnumOrNull,
  assertNumberOrNull,
  assertObjectOrUndefined,
  assertStringOrNull,
} from '../utils'
import {generalItemTypeNormaliser} from './medicalLists'

export const tobaccoNormaliser: AssertNullableTypeFn<TobaccoUsage> = (obj) => ({
  amount: assertNumberOrNull(obj.amount),
  period: assertNumberOrNull(obj.period),
  smoker: assertEnumOrNull(obj.smoker, IsSmoker),
  stoppedYearMonth: assertStringOrNull(obj.stoppedYearMonth),
  tobaccoType: assertEnumOrNull(obj.tobaccoType, TobaccoType),
})

export const alcoholNormaliser: AssertNullableTypeFn<Alcohol> = (obj) => ({
  beerBottle: assertNumberOrNull(obj.beerBottle),
  beerPint: assertNumberOrNull(obj.beerPint),
  cocktail: assertNumberOrNull(obj.cocktail),
  spiritBottle: assertNumberOrNull(obj.spiritBottle),
  spiritShot: assertNumberOrNull(obj.spiritShot),
  wineBottle: assertNumberOrNull(obj.wineBottle),
  wineGlass: assertNumberOrNull(obj.wineGlass),
})

export const bodyMeasurementsNormaliser: AssertNullableTypeFn<
  BodyMeasurements
> = (obj) => ({
  bloodPressureDiastolic: assertNumberOrNull(obj.bloodPressureDiastolic),
  bloodPressureSystolic: assertNumberOrNull(obj.bloodPressureSystolic),
  bmi: assertNumberOrNull(obj.bmi),
  height: assertNumberOrNull(obj.height),
  restingHeartRate: assertNumberOrNull(obj.restingHeartRate),
  waistCircumference: assertNumberOrNull(obj.waistCircumference),
  waterConsumption: assertNumberOrNull(obj.waterConsumption),
  weight: assertNumberOrNull(obj.weight),
})

export const sleepOverviewNormaliser: AssertNullableTypeFn<SleepOverview> = (
  obj,
) => ({
  doesFeelRefreshed: assertBooleanOrNull(obj.doesFeelRefreshed),
  doesWorkDisrupt: assertBooleanOrNull(obj.doesWorkDisrupt),
  hoursSlept: assertNumberOrNull(obj.hoursSlept),
})

export const lifestyleInformationNormaliser: AssertTypeFn<
  LifestyleInformation
> = (obj) => ({
  alcohol: assertObjectOrUndefined(obj.alcohol, alcoholNormaliser),
  diet: assertObjectOrUndefined(obj.diet, generalItemTypeNormaliser),
  measurements: assertObjectOrUndefined(
    obj.measurements,
    bodyMeasurementsNormaliser,
  ),
  sleepOverview: assertObjectOrUndefined(
    obj.sleepOverview,
    sleepOverviewNormaliser,
  ),
  tobacco: assertObjectOrUndefined(obj.tobacco, tobaccoNormaliser),
})
