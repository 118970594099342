import * as React from 'react'

import {IconProps} from '.'

function UserRunning({color, size, isFilled}: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={isFilled ? color : 'none'}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.2174 6.34884C15.1739 6.34884 15.9565 5.59535 15.9565 4.67442C15.9565 3.75349 15.1739 3 14.2174 3C13.2609 3 12.4783 3.75349 12.4783 4.67442C12.4783 5.59535 13.2609 6.34884 14.2174 6.34884ZM9.95661 17.7684C10.5883 17.89 11.2036 17.4919 11.3514 16.8657L11.504 16.2193C11.7067 15.3608 12.7581 15.0373 13.4083 15.6335C13.6468 15.8522 13.7826 16.161 13.7826 16.4846V20.1304C13.7826 20.6107 14.1719 21 14.6522 21C15.1324 21 15.5217 20.6107 15.5217 20.1304V15.2487C15.5217 14.9124 15.3806 14.5915 15.1327 14.3642L14.1182 13.434C13.8562 13.1937 13.74 12.8332 13.8123 12.4851C13.9804 11.6758 15.0476 11.3653 15.7662 11.7738C16.484 12.1818 17.2984 12.463 18.165 12.5744C18.6236 12.6334 19 12.2531 19 11.7907C19 11.3283 18.6221 10.9615 18.1669 10.8807C16.8865 10.6533 15.836 9.91317 15.2609 8.94419L14.3913 7.60465C14.0435 7.10233 13.5217 6.76744 12.913 6.76744C12.7374 6.76744 12.6011 6.80541 12.4512 6.8302C12.2981 6.85554 12.1435 6.88127 11.9997 6.93982L8.44297 8.38862C7.99109 8.57268 7.69565 9.01203 7.69565 9.49996V11.7583C7.69565 12.2386 8.08497 12.6279 8.56522 12.6279C9.04546 12.6279 9.43478 12.2386 9.43478 11.7583V10.2446C9.43478 9.966 9.60767 9.71666 9.86856 9.61898C10.3605 9.43479 10.8628 9.86427 10.7572 10.3788L9.84787 14.811C9.71545 15.4564 9.08746 15.8743 8.44099 15.7473L6.17621 15.3023C5.71727 15.2121 5.27125 15.5082 5.17612 15.9661C5.07927 16.4324 5.38272 16.8876 5.85031 16.9777L9.95661 17.7684Z"
        fill={color}
      />
    </svg>
  )
}

export default UserRunning
