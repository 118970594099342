import {PermissionCategory} from '@common/models'
import React, {useContext, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {MISSING_ID} from '@common/utils'

import {PatientsContext} from '../../PatientsContext'

import {Button, ButtonSize} from '~/components/general/button/Button'
import {Icon} from '~/components/general/icon/Icon'
import {useRequestPatientData} from '~/api'

type RequestDataButtonProps = {
  permission: PermissionCategory
  size?: ButtonSize
  title?: string
}

export const RequestDataButton: React.FC<RequestDataButtonProps> = ({
  permission,
  size,
  title,
}) => {
  const {t} = useTranslation()

  const {patient} = useContext(PatientsContext)

  const {mutate, isLoading} = useRequestPatientData({
    patientId: patient?.patientId ?? MISSING_ID,
  })

  const [isRequested, setIsRequested] = useState(
    patient?.requestedPermissions.includes(permission),
  )

  const requestData = () =>
    mutate(
      {
        permissions: [permission],
        facilityId: patient?.facilityId ?? MISSING_ID,
      },
      {
        onSuccess: () => setIsRequested(true),
      },
    )

  const buttonTitle = useMemo(() => {
    if (isRequested) {
      return t('Requested')
    }
    return title ?? t('Request')
  }, [isRequested, t, title])

  return (
    <Button
      color="primary"
      size={size}
      onClick={requestData}
      disabled={isLoading || isRequested}
      startIcon={!isRequested && <Icon name="ShareIcon" size="sm" />}
      endIcon={isRequested && <Icon name="Check" size="sm" />}>
      {buttonTitle}
    </Button>
  )
}
