import styled from 'styled-components'

export const TimelineDataTable = styled.table`
  border-spacing: 0px 15px;
  width: 100%;
`

// Creates the line that goes through the dots on the timeline
// (there's no border on a tbody). Works by creating a very small
// shadow on the left and reducing the height top and bottom a bit
// with the spread.
export const TimelineDataTableBody = styled.tbody`
  box-shadow: -30px 0px 1px -30px black;
`

type TimelineItemProps = {
  danger?: boolean
  nowrap?: boolean
}

export type TimelineIconProps = {
  backgroundColor: string
}

export const TimelineHeaderRow = styled.tr`
  border-spacing: 0px;
  border-left: 2px solid black;
  cursor: pointer;
`

export const TimelineHeaderCellStyled = styled.th`
  background-color: ${({theme}) => theme.colors.primary.blue25};
  padding: ${({theme}) => theme.spacing.md};
  margin-left: ${({theme}) => theme.spacing.md};
  margin-bottom: ${({theme}) => theme.spacing.sm};

  &:nth-child(2) {
    border-top-left-radius: ${({theme}) => theme.borderRadius.sm};
    border-bottom-left-radius: ${({theme}) => theme.borderRadius.sm};
  }

  &:last-child {
    border-top-right-radius: ${({theme}) => theme.borderRadius.sm};
    border-bottom-right-radius: ${({theme}) => theme.borderRadius.sm};
  }
`

export const TimelineHeaderCellInsides = styled.div`
  display: flex;
  flex-direction: row;
`

export const TimelineDataRow = styled.tr<TimelineItemProps>`
  border-spacing: 0px;
  border-left: 2px solid black;
  cursor: pointer;
`

export const TimelineDataCell = styled.td<TimelineItemProps>`
  background-color: ${({danger, theme}) =>
    danger ? theme.colors.secondary.orange50 : theme.colors.primary.blue25};
  padding: ${({theme}) => theme.spacing.md};
  margin-left: ${({theme}) => theme.spacing.md};
  margin-bottom: ${({theme}) => theme.spacing.sm};
  overflow-x: auto;
  white-space: ${({nowrap}) => (nowrap ? 'nowrap' : 'normal')}

  &:nth-child(2) {
    border-top-left-radius: ${({theme}) => theme.borderRadius.sm};
    border-bottom-left-radius: ${({theme}) => theme.borderRadius.sm};
  }

  &:last-child {
    border-top-right-radius: ${({theme}) => theme.borderRadius.sm};
    border-bottom-right-radius: ${({theme}) => theme.borderRadius.sm};
  }
`

// TODO: REINSTATE DANGER prop
export const TimelineDot = styled.div`
  width: 11px;
  height: 11px;
  background-color: ${({theme}) => theme.colors.primary.blue900};
  border-radius: ${({theme}) => theme.borderRadius.circled};
  position: relative;
  left: -7px;
`

export const TimelineDotCell = styled.td`
  // background: linear-gradient(#000, #000) no-repeat left/2px 100%;
  &.parent:first-child {
    background-color: red;
  }
`

export const TimelineIcon = styled.div<TimelineIconProps>`
  width: ${({theme}) => theme.spacing.xl};
  height: ${({theme}) => theme.spacing.xl};
  background-color: ${({backgroundColor, theme}) =>
    backgroundColor ?? theme.colors.grey.grey100};
  border-radius: ${({theme}) => theme.borderRadius.xs};
  display: flex;
  align-items: center;
  justify-content: center;
`
