/* tslint:disable */
/* eslint-disable */
/**
 * Cushla API
 * REST API for Cushla
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */

export enum TimelineItemType {
  ALLERGY = 'ALLERGY',
  MEDICAL_TEST = 'MEDICAL_TEST',
  DIAGNOSED_CONDITION = 'DIAGNOSED_CONDITION',
  MEDICATION = 'MEDICATION',
  SURGERY = 'SURGERY',
  VACCINATION = 'VACCINATION',
  CHOLESTEROL = 'CHOLESTEROL',
  APPOINTMENT = 'APPOINTMENT',
  LIFE_MILESTONE = 'LIFE_MILESTONE',
}
