export enum VitalDataSource {
  OURA = 'oura',
  FITBIT = 'fitbit',
  GARMIN = 'garmin',
  STRAVA = 'strava',
  WITHINGS = 'withings',
  GOOGLE_FIT = 'google_fit',
  APPLE_HEALTH = 'apple_health_kit',
  HEALTH_CONNECT = 'health_connect',
}

export type {
  GetVitalTokenRequestDto as VitalTokenRequest,
  GetVitalTokenResponseDto as VitalToken,
  VitalGetProviderDto as VitalProvider,
  VitalGetProvidersDto as VitalProviders,
  VitalSDKConfigDto as VitalConfig,
} from '@generated/models'
