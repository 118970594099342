import {BasicHealthInfoResponse, PermissionCategory} from '@common/models'
import {useTranslation} from 'react-i18next'
import React, {useMemo, useState} from 'react'
import {Link} from 'react-router-dom'
import {useMedicalCardData} from '@common/utils'

import {
  StyledDashboardInnerCard,
  StyledDashboardInnerCardTable,
  StyledDashboardInnerCardTableCell,
  StyledTableText,
} from './PatientDashboardCard.styled'

import {Typography} from '~/components/general/typography/Typography'
import {theme} from '~/styles/theme'

const DEFAULT_ITEMS_TO_SHOW = 3

type PatientDashboardMedicalCardProps = {
  data?: BasicHealthInfoResponse
  permissions: PermissionCategory[]
}

type CardDataItemValuesProps = {
  showAllPath?: string
  totalCount?: number
  values: string[] | string
}

const CardDataItemValues: React.FC<CardDataItemValuesProps> = ({
  showAllPath,
  totalCount = 0,
  values,
}) => {
  const {t} = useTranslation()
  const [isExpanded, setIsExpanded] = useState(false)

  const valuesToShow = useMemo(
    () => (isExpanded ? values : values.slice(0, DEFAULT_ITEMS_TO_SHOW)),
    [isExpanded, values],
  )

  const isShowAllLinkVisible =
    isExpanded && totalCount > values.length && showAllPath

  if (!Array.isArray(valuesToShow)) {
    return <Typography color={theme.colors.grey.main}>{values}</Typography>
  }

  return (
    <>
      {isExpanded ? (
        valuesToShow.map((value, index) => (
          <StyledTableText key={index}>{value}</StyledTableText>
        ))
      ) : (
        <Typography>{valuesToShow.join(', ')}</Typography>
      )}
      {values.length > DEFAULT_ITEMS_TO_SHOW && (
        <Typography
          color={theme.colors.secondary.main}
          onClick={() => setIsExpanded(!isExpanded)}>
          {isExpanded ? t('show less') : t('show more')}
        </Typography>
      )}
      {isShowAllLinkVisible && (
        <Link to={showAllPath}>
          <Typography color={theme.colors.secondary.main}>
            {t('show all')}
          </Typography>
        </Link>
      )}
    </>
  )
}

export const PatientDashboardMedicalCard: React.FC<
  PatientDashboardMedicalCardProps
> = ({data, permissions}) => {
  const {t} = useTranslation()
  const cardData = useMedicalCardData({data, permissions})

  if (!cardData) {
    return <Typography>{t('Missing data')}</Typography>
  }

  const tableRows = cardData.map((section) => (
    <tr key={section.title}>
      <StyledDashboardInnerCardTableCell>
        <Typography weight="medium">{section.title}</Typography>
      </StyledDashboardInnerCardTableCell>
      <StyledDashboardInnerCardTableCell>
        <CardDataItemValues
          totalCount={section.totalCount ?? 0}
          values={section.values}
        />
      </StyledDashboardInnerCardTableCell>
    </tr>
  ))

  return (
    <StyledDashboardInnerCard>
      <StyledDashboardInnerCardTable>
        <tbody>{tableRows}</tbody>
      </StyledDashboardInnerCardTable>
    </StyledDashboardInnerCard>
  )
}
