import {
  BarChartDataPoint,
  ChartDataPoint,
  HealthDataResolution,
} from '@common/models'
import {DateTime} from 'luxon'
import React from 'react'
import {EventPropTypeInterface, VictoryPortal} from 'victory'
import {formatHealthDataDatePeriod} from '@common/utils'

import {StyledTooltip, TOOLTIP_SHADOW_MARGIN} from './TrendChartTooltip.styled'

export const tooltipEvents: EventPropTypeInterface<'data', string>[] = [
  {
    target: 'data',
    eventHandlers: {
      onClick: () => ({
        target: 'labels',
        mutation: ({active}) => ({
          active: active === 'PINNED' ? false : 'PINNED',
        }),
      }),
      onMouseEnter: () => ({
        target: 'labels',
        mutation: ({active}) => ({
          active: active === 'PINNED' ? 'PINNED' : true,
        }),
      }),
      onMouseLeave: () => ({
        target: 'labels',
        mutation: ({active}) => ({
          active: active === 'PINNED' ? 'PINNED' : false,
        }),
      }),
    },
  },
]

type TrendChartTooltipProps<T> = {
  active?: boolean | 'PINNED'
  datum?: T
  dx?: number
  dy?: number
  formatValue: (datum: T) => string
  resolution?: HealthDataResolution
  unit?: string
  x?: number
  y?: number
}

export const TrendChartTooltip = <
  T extends ChartDataPoint | BarChartDataPoint,
>({
  active,
  datum,
  dx = 0,
  dy = 0,
  formatValue,
  resolution,
  unit = '',
  x = 0,
  y = 0,
}: TrendChartTooltipProps<T>) => {
  if (!active || !datum) {
    return null
  }

  return (
    <VictoryPortal>
      <foreignObject
        x={x - dx - TOOLTIP_SHADOW_MARGIN}
        y={y - dy - TOOLTIP_SHADOW_MARGIN}
        width={78 + 2 * TOOLTIP_SHADOW_MARGIN}
        height={78 + 2 * TOOLTIP_SHADOW_MARGIN}>
        <StyledTooltip
          value={formatValue(datum)}
          unit={unit}
          dateText={formatHealthDataDatePeriod(
            DateTime.fromJSDate(datum.date).toISODate(),
            resolution,
          )}
        />
      </foreignObject>
    </VictoryPortal>
  )
}
