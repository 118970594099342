import React, {useContext, useEffect} from 'react'
import {Outlet, useParams} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import {MISSING_ID} from '@common/utils'

import {PractitionerContext} from '../profile/PractitionerContext'
import {PatientsContext} from './PatientsContext'

import {useGetPractitionerPatient} from '~/api'
import {Loading} from '~/components/general/loading/Loading'
import {Typography} from '~/components/general/typography/Typography'
import {Layout} from '~/components/layout/Layout'

export const PatientDataWrapper: React.FC = () => {
  const {t} = useTranslation()
  const {patientId} = useParams()
  const {selectedEmployment} = useContext(PractitionerContext)
  const {patient, setPatient} = useContext(PatientsContext)

  const {data: patientData, isInitialLoading} = useGetPractitionerPatient({
    patientId: patientId ?? MISSING_ID,
    facilityId: selectedEmployment?.facilityId ?? MISSING_ID,
  })

  useEffect(() => {
    setPatient(patientData)
  }, [patientData, setPatient])

  const isPatientContextUpdated =
    !!patientId && patient?.patientId === patientId

  if (isPatientContextUpdated) {
    return <Outlet />
  }

  return (
    <Layout>
      {isInitialLoading ? (
        <Loading />
      ) : (
        <Typography>{t("Missing patient's data")}</Typography>
      )}
    </Layout>
  )
}
