import React from 'react'
import {DateTime} from 'luxon'
import {useTranslation} from 'react-i18next'
import {Birth} from '@common/models'
import {
  EnumType,
  formatDate,
  formatLocaleBirthWeight,
  useEnumTranslations,
  useTranslateBooleanValue,
} from '@common/utils'

import {PregnancyCard, PregnancyCardRowProps} from './PregnancyCard'

export const BirthItem = (birthData: Birth) => {
  const {t} = useTranslation()
  const {translateEnumValue} = useEnumTranslations()
  const translateBooleanValue = useTranslateBooleanValue()
  const rows: PregnancyCardRowProps[] = [
    {title: t("Child's Name"), value: birthData.name || '-'},
    {
      title: t('Gestational Age (weeks)'),
      value: birthData.gestationalAgeInWeeks || '-',
    },
    {
      title: t('Sex'),
      value: translateEnumValue(EnumType.Sex, birthData.gender, '-'),
    },
    {
      title: t('Type of Birth'),
      value: translateEnumValue(EnumType.DeliveryType, birthData.delivery, '-'),
    },
    {
      title: t('Pain Relief/Anaesthesia'),
      value: translateEnumValue(EnumType.PainRelief, birthData.painRelief, '-'),
    },
    {
      title: t('Perineal Trauma'),
      value: translateEnumValue(
        EnumType.PerinealTrauma,
        birthData.perinealTrauma,
        '-',
      ),
    },
    {
      title: t('Birth Weight'),
      value: formatLocaleBirthWeight(birthData.weight),
    },
    {
      title: t('Is This Child Currently Well?'),
      value: translateBooleanValue(birthData.isChildWell, '-'),
    },
  ]

  return (
    <PregnancyCard
      title={t('Birth')}
      date={formatDate(
        DateTime.DATE_FULL,
        birthData.dateOfBirth,
        undefined,
        '-',
      )}
      rows={rows}
      notes={birthData.notes}
    />
  )
}
