import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {AppointmentForPractitioner} from '@common/models'

import {Container} from '../general/container/Container'
import {SimpleTable} from '../general/table/SimpleTable'
import {RescheduleAppointmentModal} from './RescheduleAppointmentModal'
import {CancelAppointmentModal} from './CancelAppointmentModal'
import {TableCellIconButtonPopup} from '../patients/common/patientsTable/PatientsTable.styled'

import {formatName} from '~/utils'

const calculateStatus = (appointment: AppointmentForPractitioner) => {
  if (appointment.cancellationReason) {
    return 'Cancelled'
  }
  if (new Date(appointment.startTime).getDate() < new Date().getDate()) {
    return 'Completed'
  }
  return 'Pending'
}

export type PractitionerAppointmentsTableProps = {
  data: AppointmentForPractitioner[] | undefined
  isLoading: boolean
  onRowSelected?: (appointment: AppointmentForPractitioner) => void
}

export const PractitionerAppointmentsTable = ({
  data,
  isLoading,
  onRowSelected,
}: PractitionerAppointmentsTableProps): JSX.Element => {
  const {t} = useTranslation()
  const [selectedAppointment, setSelectedAppointment] = useState<{
    id: string
    facilityId: string
    startTime: string
    patientName: string
    patientDob: string | null
    action: 'reschedule' | 'cancel'
  } | null>(null)

  const rowsData =
    data?.map((appointment: AppointmentForPractitioner) => {
      const isCancelled = !!appointment.cancellationReason

      const menuItems = isCancelled
        ? []
        : [
            {
              label: t('Reschedule'),
              onClick: () =>
                setSelectedAppointment({
                  id: appointment.id,
                  facilityId: appointment.facility?.id ?? '',
                  startTime: appointment.startTime,
                  patientName: formatName(appointment.patient),
                  patientDob: appointment.patient.dateOfBirth ?? null,
                  action: 'reschedule',
                }),
            },
            {
              label: t('Cancel'),
              onClick: () =>
                setSelectedAppointment({
                  id: appointment.id,
                  facilityId: appointment.facility?.id ?? '',
                  startTime: appointment.startTime,
                  patientName: formatName(appointment.patient),
                  patientDob: appointment.patient.dateOfBirth ?? null,
                  action: 'cancel',
                }),
            },
          ]

      return {
        hasParsedData: false,
        columns: [
          new Date(appointment.startTime).toLocaleTimeString([], {
            hour: '2-digit',
            minute: '2-digit',
          }),
          formatName(appointment.patient),
          appointment.patient.dateOfBirth
            ? new Date(appointment.patient.dateOfBirth).toLocaleDateString()
            : '',
          calculateStatus(appointment),
          appointment.department ?? '',
        ],
        onRowClick: () => {
          if (onRowSelected) onRowSelected(appointment)
        },
        actionComponent:
          menuItems.length > 0 ? (
            <TableCellIconButtonPopup menuItems={menuItems} />
          ) : null,
      }
    }) ?? []

  const headerData = {
    columns: [
      {title: t('Start Time'), columnWidthPercent: 15},
      {title: t('Patient Name'), columnWidthPercent: 25},
      {title: t('Date of Birth'), columnWidthPercent: 15},
      {title: t('Status'), columnWidthPercent: 10},
      {title: t('Location'), columnWidthPercent: 20},
      {title: '', columnWidthPercent: 15},
    ],
  }

  return (
    <>
      <Container direction="column" flex spacing={4}>
        <SimpleTable
          header={headerData}
          rows={rowsData}
          isLoading={isLoading}
        />
      </Container>
      {selectedAppointment && (
        <>
          <RescheduleAppointmentModal
            isVisible={selectedAppointment.action === 'reschedule'}
            onClose={() => setSelectedAppointment(null)}
            appointmentId={selectedAppointment.id}
            facilityId={selectedAppointment.facilityId}
            originalDate={selectedAppointment.startTime}
            patientName={selectedAppointment.patientName}
            patientDob={selectedAppointment.patientDob}
          />
          <CancelAppointmentModal
            isVisible={selectedAppointment.action === 'cancel'}
            onClose={() => setSelectedAppointment(null)}
            appointmentId={selectedAppointment.id}
            facilityId={selectedAppointment.facilityId}
            originalDate={selectedAppointment.startTime}
            patientName={selectedAppointment.patientName}
            patientDob={selectedAppointment.patientDob}
          />
        </>
      )}
    </>
  )
}
