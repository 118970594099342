import React from 'react'
import {Link} from 'react-router-dom'
import styled from 'styled-components'
import {useTranslation} from 'react-i18next'

import {theme} from '~/styles/theme'
import {Typography} from '~/components/general/typography/Typography'
import {Icon, IconName} from '~/components/general/icon/Icon'
import {Container} from '~/components/general/container/Container'

const PillContainer = styled.div`
  width: 320px;
`

const PillTop = styled.span`
  background-color: ${theme.colors.white};
  border-top-left-radius: ${theme.borderRadius.sm};
  border-top-right-radius: ${theme.borderRadius.sm};
  box-shadow: ${theme.boxShadow.card};
  padding: ${theme.spacing.sm};
  display: ${'flex'};
  align-items: ${'center'};
  margin-top: ${theme.spacing.sm};
`

const PillBottom = styled(Container)`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${theme.colors.primary.blue50};
  border-bottom-left-radius: ${theme.borderRadius.sm};
  border-bottom-right-radius: ${theme.borderRadius.sm};
  box-shadow: ${theme.boxShadow.card};
  padding: ${theme.spacing.sm};
`

const PillTitle = styled.span`
  padding-left: ${theme.spacing.xs};
  padding-right: ${theme.spacing.xs};
`

const PillMainNumber = styled.span`
  background-color: ${theme.colors.grey.grey20};
  margin-left: auto;
  padding-left: ${theme.spacing.xl};
  padding-right: ${theme.spacing.xl};
  padding-top: ${theme.spacing.xs};
  padding-bottom: ${theme.spacing.xs};
  border-radius: ${theme.borderRadius.xs};
`

const SeeMoreText = styled.span`
  margin-right: ${theme.spacing.xs};
`

const SeeAllLink = styled(Link)`
  display: flex;
  align-items: center;
`

type DashboardSummaryItemPillProps = {
  title: string
  iconName: IconName
  rightNumber: number | undefined
  bottomContent?: React.ReactNode
  linkPath: string
}

type PillBottomContentProps = {
  hasPatientShared: boolean
  content: React.ReactNode
  linkPath: string
}

const PillBottomContent: React.FC<PillBottomContentProps> = ({
  hasPatientShared,
  content,
  linkPath,
}) => {
  const {t} = useTranslation()

  if (!hasPatientShared) {
    return (
      <Container>
        <div style={{textAlign: 'center'}}>
          <Typography size="xl">{t('Not shared.')}</Typography>
        </div>
        <div style={{textAlign: 'center'}}>
          <Typography>
            {t('You can request this information from your patient in the')}
          </Typography>
          <Link to="../medical">
            <Typography color={theme.colors.secondary.main}>
              {t('Medical information section')}
            </Typography>
          </Link>
        </div>
      </Container>
    )
  }
  if (content) {
    return (
      <>
        {content}
        {!!linkPath && (
          <Container flex justify="flex-end">
            <SeeAllLink to={linkPath}>
              <SeeMoreText>{t('See more')}</SeeMoreText>
              <Icon name="ArrowRight" color={theme.colors.secondary.main} />
            </SeeAllLink>
          </Container>
        )}
      </>
    )
  }
  return (
    <Container flex fullWidth justify="center" align="center" spacing={1}>
      <Typography>{t('Nothing to see here')}</Typography>
      <Icon name="Baby" size="xl" />
    </Container>
  )
}

export const DashboardSummaryItemPill: React.FC<
  DashboardSummaryItemPillProps
> = ({title, iconName, rightNumber, bottomContent, linkPath}) => {
  const mainNumber = rightNumber ?? '-'
  const hasPatientShared = rightNumber != null

  return (
    <PillContainer>
      <PillTop>
        <Icon size="xl" name={iconName} />
        <PillTitle>
          <Typography size="xxl" color={theme.colors.primary.main}>
            {title}
          </Typography>
        </PillTitle>
        <PillMainNumber>
          <Typography
            size="h2"
            weight="bold"
            color={theme.colors.secondary.main}>
            {mainNumber}
          </Typography>
        </PillMainNumber>
      </PillTop>
      <PillBottom>
        <PillBottomContent
          content={bottomContent}
          hasPatientShared={hasPatientShared}
          linkPath={linkPath}
        />
      </PillBottom>
    </PillContainer>
  )
}
