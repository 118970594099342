import React, {useContext} from 'react'
import {Navigate} from 'react-router-dom'

import {PractitionerContext} from '~/components/profile/PractitionerContext'

export const DefaultPageRoute: React.FC = () => {
  const {practitioner} = useContext(PractitionerContext)

  const defaultPatientPage = practitioner?.defaultPatientPage ?? 'dashboard'

  return <Navigate replace to={defaultPatientPage} />
}
