import React, {useCallback, useContext, useEffect, useMemo, useRef} from 'react'
import {PractitionersPatient} from '@common/models'
import {MISSING_ID} from '@common/utils'

import {Option} from './Select'
import {Typography} from '../typography/Typography'
import {
  PatientsSuggestionsListWrapper,
  PatientsSuggestionsLoadingWrapper,
} from './PatientsSuggestionsList.styled'

import {formatName} from '~/utils'
import {Loading} from '~/components/general/loading/Loading'
import {useInfiniteGetPractitionerPatients} from '~/api'
import {PractitionerContext} from '~/components/profile/PractitionerContext'

type PatientsSuggestionsListProps = {
  onPatientClick: (patient: PractitionersPatient) => void
  filteredPatients?: PractitionersPatient[]
  query: string
}

export const PatientsSuggestionsList: React.FC<
  PatientsSuggestionsListProps
> = ({filteredPatients = [], onPatientClick, query}) => {
  const loader = useRef(null)
  const {selectedEmployment} = useContext(PractitionerContext)

  const {data, hasNextPage, fetchNextPage, isFetching} =
    useInfiniteGetPractitionerPatients({
      query,
      facilityId: selectedEmployment?.facilityId ?? MISSING_ID,
    })

  const filteredPatientIds = useMemo(
    () => filteredPatients.map((patient) => patient.patientId),
    [filteredPatients],
  )

  const patients = useMemo(
    () =>
      data?.pages
        .flatMap(({records}) => records)
        .filter((patient) => !filteredPatientIds.includes(patient.patientId)),
    [data, filteredPatientIds],
  )

  const handleObserver = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const target = entries[0]
      if (target.isIntersecting && hasNextPage) {
        fetchNextPage()
      }
    },
    [fetchNextPage, hasNextPage],
  )

  useEffect(() => {
    const options = {
      root: document.getElementById('patientsSuggestionsListWrapper'),
      rootMargin: '30px',
    }
    const observer = new IntersectionObserver(handleObserver, options)

    if (loader.current) {
      observer.observe(loader.current)
    }
  }, [handleObserver])

  return (
    <PatientsSuggestionsListWrapper id="patientsSuggestionsListWrapper">
      {patients?.map((patient) => (
        <Option key={patient.patientId} onClick={() => onPatientClick(patient)}>
          <Typography weight="medium">{formatName(patient)}</Typography>
        </Option>
      ))}
      {isFetching && (
        <PatientsSuggestionsLoadingWrapper>
          <Loading />
        </PatientsSuggestionsLoadingWrapper>
      )}
      <div ref={loader} />
    </PatientsSuggestionsListWrapper>
  )
}
