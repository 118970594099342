import React, {ReactNode, useState} from 'react'

import {PopupSide, StyledPopup, StyledPopupContainer} from './Popup.styled'

import {useOutsideClickClose} from '~/utils'

type PopupProps = {
  children: ReactNode
  content: ReactNode
  className?: string
  popupSide?: PopupSide
  mode?: 'hover' | 'click' | 'both'
}

export const Popup: React.FC<PopupProps> = ({
  children,
  content,
  className,
  mode = 'both',
  popupSide = PopupSide.LEFT,
}) => {
  const [isClicked, setIsClicked] = useState(false)
  const [isHovered, setIsHovered] = useState(false)

  const ref = useOutsideClickClose(() => setIsClicked(false))

  const onClick: React.MouseEventHandler<HTMLDivElement> = (event) => {
    event.stopPropagation()
    event.preventDefault()
    setIsClicked(!isClicked)
  }

  const isPopupOpen =
    (mode !== 'hover' && isClicked) || (mode !== 'click' && isHovered)

  return (
    <StyledPopupContainer
      ref={ref}
      className={className}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={onClick}>
      {children}
      {isPopupOpen && <StyledPopup side={popupSide}>{content}</StyledPopup>}
    </StyledPopupContainer>
  )
}
