/* tslint:disable */
/* eslint-disable */
/**
 * Cushla API
 * REST API for Cushla
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */

export enum ParsedMedicalTestType {
  BLOOD_BIOCHEMISTRY = 'BLOOD_BIOCHEMISTRY',
  BLOOD_HAEMATOLOGY = 'BLOOD_HAEMATOLOGY',
  BLOOD_GENERAL_PRACTICE = 'BLOOD_GENERAL_PRACTICE',
  UNKNOWN = 'UNKNOWN',
}
