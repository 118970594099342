import styled from 'styled-components'

type ItemProp = {
  active?: boolean
  isChild?: boolean
  redBadge?: boolean
}

export const StyledInnerSideNav = styled.div`
  width: 288px;
  padding: ${({theme}) => theme.spacing.lg};
  border-right: 1px solid ${({theme}) => theme.colors.grey.grey40};
  flex-shrink: 0;
`

export const StyledInnerSideNavItem = styled.div<ItemProp>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({active, theme}) =>
    active ? theme.colors.primary.blue25 : theme.colors.white};
  padding: ${({isChild, theme}) =>
    `${theme.spacing.sm} ${theme.spacing.sm} ${theme.spacing.sm} ${
      isChild ? theme.spacing.xxxl : theme.spacing.lg
    }`};
  border-radius: ${({theme}) => theme.borderRadius.lg};
  cursor: ${({active}) => (active ? 'default' : 'pointer')};

  & > * {
    margin-right: ${({theme}) => theme.spacing.xs};
  }

  & > *:last-child {
    margin-right: 0;
  }
`

export const Badge = styled.div<ItemProp>`
  min-width: ${({redBadge, theme}) => (redBadge ? '20px' : theme.spacing.lg)};
  height: ${({redBadge, theme}) => (redBadge ? '20px' : theme.spacing.lg)};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({active, redBadge, theme}) =>
    redBadge
      ? theme.colors.secondary.main
      : `${active ? theme.colors.grey.grey150 : theme.colors.primary.blue25}`};
  font-size: ${({theme}) => theme.fontSize.sm};
  color: ${({active, redBadge, theme}) =>
    redBadge
      ? theme.colors.white
      : `${active ? theme.colors.primary.main : theme.colors.grey.main}`};
  font-weight: ${({active, redBadge, theme}) =>
    active || redBadge ? theme.fontWeight.medium : theme.fontWeight.normal};
  line-height: 1;
  padding: 0
    ${({redBadge, theme}) => (redBadge ? theme.spacing.xxs : theme.spacing.xs)};
  border-radius: ${({theme}) => theme.borderRadius.lg};
`
