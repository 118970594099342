import * as React from 'react'

import {IconProps} from '.'

function Document({color, size, isFilled}: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={isFilled ? color : 'none'}
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.25 8C6.25 7.58579 6.58579 7.25 7 7.25H15C15.4142 7.25 15.75 7.58579 15.75 8C15.75 8.41421 15.4142 8.75 15 8.75H7C6.58579 8.75 6.25 8.41421 6.25 8Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.4907 13.2147C17.6425 12.1474 19.4267 12.1049 20.6381 13.1466L20.6811 13.1836C21.904 14.2352 22.1222 16.0849 21.1042 17.3708C19.9129 18.8754 18.5225 20.3955 16.9442 21.5962C16.6758 21.8004 16.3041 21.8003 16.0357 21.596C14.4963 20.4236 13.1346 18.9481 11.9638 17.48M16.4907 13.2147C15.3405 12.1478 13.558 12.1008 12.3511 13.1386C11.06 14.25 10.9154 16.1655 11.9638 17.48M13.3294 14.2757C13.9821 13.7147 14.9596 13.7664 15.5497 14.3931C15.5497 14.393 15.5498 14.3932 15.5497 14.3931L15.9437 14.8121C16.0854 14.9627 16.2829 15.0482 16.4897 15.0483C16.6964 15.0484 16.8941 14.9632 17.0359 14.8127L17.4299 14.3947C18.0183 13.7696 18.9983 13.715 19.6599 14.2838C19.6599 14.2837 19.66 14.2838 19.6599 14.2838L19.7029 14.3208C20.3361 14.8652 20.43 15.8057 19.9281 16.4398C18.8997 17.7387 17.7507 19.0061 16.4903 20.0427C15.2642 19.0336 14.1427 17.8064 13.1365 16.5447C12.577 15.8432 12.6668 14.8463 13.3294 14.2757Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 3.75C4.30921 3.75 3.75 4.30921 3.75 5V19C3.75 19.6908 4.30921 20.25 5 20.25H10C10.4142 20.25 10.75 20.5858 10.75 21C10.75 21.4142 10.4142 21.75 10 21.75H5C3.48079 21.75 2.25 20.5192 2.25 19V5C2.25 3.48079 3.48079 2.25 5 2.25H17C18.5192 2.25 19.75 3.48079 19.75 5V9C19.75 9.41421 19.4142 9.75 19 9.75C18.5858 9.75 18.25 9.41421 18.25 9V5C18.25 4.30921 17.6908 3.75 17 3.75H5Z"
        fill={color}
      />
    </svg>
  )
}

export default Document
