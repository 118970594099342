import {useTranslation} from 'react-i18next'
import {MISSING_ID} from '@common/utils'
import React from 'react'
import {useParams} from 'react-router-dom'

import {useGetAssignedQuestionnaire} from '~/api'
import {Loading} from '~/components/general/loading/Loading'
import {Typography} from '~/components/general/typography/Typography'
import {QuestionnairePreview} from '~/components/questionnaires/questionnairePreview/QuestionnairePreview'

export const QuestionnaireResponse: React.FC = () => {
  const {assignedQuestionnaireId} = useParams()
  const {t} = useTranslation()

  const {data, isInitialLoading} = useGetAssignedQuestionnaire({
    assignedQuestionnaireId: assignedQuestionnaireId ?? MISSING_ID,
  })

  if (isInitialLoading) {
    return <Loading />
  }

  if (!data) {
    return <Typography>{t('Missing questionnaire data')}</Typography>
  }

  return (
    <QuestionnairePreview
      questions={data.questionnaire.questions}
      answers={data.answers}
      title={t(`Patient's responses`)}
    />
  )
}
