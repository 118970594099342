import React from 'react'
import {VictoryAxis, VictoryBar, VictoryChart} from 'victory'
import {VictoryStringOrNumberCallback} from 'victory-core'
import {DateTime} from 'luxon'
import {
  BarChartData,
  BarChartDataPoint,
  HealthDataGrade,
  HealthDataResolution,
} from '@common/models'

import {TrendChartTooltip, tooltipEvents} from './TrendChartTooltip'
import {TrendChartContainer} from './TrendChart.styled'

import {getChartDomain, useInitialDimensions} from '~/utils'
import {theme} from '~/styles/theme'

type BloodPressureTrendChartProps = {
  data: BarChartData
  resolution: HealthDataResolution
  startDate: DateTime
  unit: string | undefined
}

const fillColors = {
  [HealthDataGrade.GOOD]: theme.colors.indicator.body.green,
  [HealthDataGrade.AVERAGE]: theme.colors.indicator.body.orange,
  [HealthDataGrade.BAD]: theme.colors.indicator.body.red,
}

const getFillColor: VictoryStringOrNumberCallback = ({datum}) =>
  datum.grade && datum.grade in HealthDataGrade
    ? fillColors[datum.grade as HealthDataGrade]
    : theme.colors.indicator.body.blue

export const BloodPressureTrendChart: React.FC<
  BloodPressureTrendChartProps
> = ({data, resolution, startDate, unit}) => {
  const {dimensions, ref} = useInitialDimensions()

  return (
    <TrendChartContainer ref={ref} fullWidth>
      <VictoryChart
        domainPadding={{x: 8, y: 10}}
        padding={{left: 10, right: 10, bottom: 0, top: 0}}
        height={dimensions?.height}
        width={dimensions?.width}
        scale={{x: 'time', y: 'linear'}}
        domain={getChartDomain(resolution, startDate)}>
        <VictoryBar
          barWidth={16}
          data={data}
          events={tooltipEvents}
          labels={({datum}) => datum.date}
          labelComponent={
            <TrendChartTooltip
              unit={unit}
              dy={-8}
              resolution={resolution}
              formatValue={(datum: BarChartDataPoint) =>
                `${datum.y}\n${datum.y0}`
              }
            />
          }
          x="date"
          cornerRadius={{top: 4, bottom: 4}}
          style={{
            data: {fill: getFillColor},
          }}
        />
        <VictoryAxis
          dependentAxis
          style={{
            axis: {stroke: 'transparent'},
            ticks: {stroke: 'transparent'},
            tickLabels: {fill: 'transparent'},
          }}
        />
      </VictoryChart>
    </TrendChartContainer>
  )
}
