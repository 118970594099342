import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {Icon} from '../general/icon/Icon'
import {Container} from '../general/container/Container'
import {Typography} from '../general/typography/Typography'
import {StyledNotificationBanner} from './NotificationBanner.styled'
import {NotificationType} from './NotificationContext'

import {theme} from '~/styles/theme'

type NotificationBannerProps = {
  onClose: () => void
  text: string
  title?: string
  notificationType: NotificationType
}

export const NotificationBanner: React.FC<NotificationBannerProps> = ({
  onClose,
  text,
  title,
  notificationType,
}) => {
  const [isVisible, setIsVisible] = useState(false)
  const {t} = useTranslation()

  useEffect(() => {
    setTimeout(() => setIsVisible(true), 500)
  }, [])

  const closeBanner: React.MouseEventHandler<HTMLDivElement> = () => {
    setIsVisible(false)
    setTimeout(onClose, 500)
  }

  return (
    <StyledNotificationBanner
      isVisible={isVisible}
      onClick={(e) => e.stopPropagation()}
      notificationType={notificationType}>
      <Container flex spacing={1} direction="column">
        <Container flex spacing={2} justify="space-between" align="center">
          <Typography weight="medium" color={theme.colors.white}>
            {title ||
              (notificationType === NotificationType.Success && t('Success')) ||
              t('Error')}
          </Typography>
          <Icon
            name="Close"
            size="sm"
            onClick={closeBanner}
            color={theme.colors.white}
          />
        </Container>
        <Typography color={theme.colors.white} size="sm">
          {text}
        </Typography>
      </Container>
    </StyledNotificationBanner>
  )
}
