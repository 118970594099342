import {
  AssignedQuestionnaire,
  AssignedQuestionnaireInfo,
  AssignedQuestionnaireNote,
  CompletedQuestionnaireAnswer,
  Question,
  QuestionChoice,
  Questionnaire,
  QuestionnaireInfo,
  QuestionnaireMeasurement,
  QuestionnaireSection,
  QuestionnaireSource,
  QuestionnaireType,
  QuestionType,
} from '../models'
import {
  assertArray,
  assertArrayOrNull,
  assertBoolean,
  assertEnum,
  assertNumber,
  assertNumberOrNull,
  assertObjectOrUndefined,
  assertString,
  assertStringOrNull,
} from '../utils'
import {AssertTypeFn} from './general'
import {patientShortNormaliser} from './patient'

export const questionnaireNoteNormaliser: AssertTypeFn<
  AssignedQuestionnaireNote
> = (obj) => ({
  date: assertString(obj.date),
  text: assertString(obj.text),
})

export const questionnaireInfoNormaliser: AssertTypeFn<QuestionnaireInfo> = (
  obj,
) => ({
  id: assertString(obj.id),
  alias: assertString(obj.alias),
  description: assertStringOrNull(obj.description),
  licensedBy: assertStringOrNull(obj.licensedBy),
  publishedBy: assertStringOrNull(obj.publishedBy),
  source: assertEnum(obj.source, QuestionnaireSource),
  tags: assertArray(obj.tags, assertString),
  title: assertString(obj.title),
  type: assertEnum(obj.type, QuestionnaireType),
})

export const questionnairesInfoNormaliser: AssertTypeFn<QuestionnaireInfo[]> = (
  arr,
) => assertArray(arr, questionnaireInfoNormaliser)

export const questionChoiceNormaliser: AssertTypeFn<QuestionChoice> = (
  obj,
) => ({
  id: assertString(obj.id),
  label: assertString(obj.label),
  nextQuestionAlias: assertStringOrNull(obj.nextQuestionAlias),
  value: assertNumberOrNull(obj.value),
})

export const questionNormaliser: AssertTypeFn<Question> = (obj) => ({
  alias: assertStringOrNull(obj.alias),
  choices: assertArrayOrNull(obj.choices, questionChoiceNormaliser),
  defaultValue: obj.defaultValue,
  id: assertString(obj.id),
  isOptional: assertBoolean(obj.isOptional),
  maximumValue: assertNumberOrNull(obj.maximumValue),
  maximumValueLabel: assertStringOrNull(obj.maximumValueLabel),
  minimumValue: assertNumberOrNull(obj.minimumValue),
  minimumValueLabel: assertStringOrNull(obj.minimumValueLabel),
  placeholder: assertStringOrNull(obj.placeholder),
  subtitle: assertStringOrNull(obj.subtitle),
  title: assertString(obj.title),
  type: assertEnum(obj.type, QuestionType),
})

export const questionnaireSectionNormaliser: AssertTypeFn<
  QuestionnaireSection
> = (obj) => ({
  endIndex: assertNumber(obj.endIndex),
  startIndex: assertNumber(obj.startIndex),
  title: assertString(obj.title),
})

export const questionnaireNormaliser: AssertTypeFn<Questionnaire> = (obj) => ({
  questions: assertArray(obj.questions, questionNormaliser),
  sections: assertArrayOrNull(obj.sections, questionnaireSectionNormaliser),
  ...questionnaireInfoNormaliser(obj),
})

export const assignedQuestionnaireInfoNormaliser: AssertTypeFn<
  AssignedQuestionnaireInfo
> = (obj) => ({
  dateCompleted: assertStringOrNull(obj.dateCompleted),
  dateDue: assertStringOrNull(obj.dateDue),
  dateReceived: assertString(obj.dateReceived),
  facilityId: assertStringOrNull(obj.facilityId),
  id: assertString(obj.id),
  patientDetails: assertObjectOrUndefined(
    obj.patientDetails,
    patientShortNormaliser,
  ),
  patientId: assertString(obj.patientId),
  practitionerName: assertStringOrNull(obj.practitionerName),
  questionnaireId: assertString(obj.questionnaireId),
  tags: assertArrayOrNull(obj.tags, assertString),
  title: assertString(obj.title),
  type: assertEnum(obj.type, QuestionnaireType),
})

export const assignedQuestionnairesInfoNormaliser: AssertTypeFn<
  AssignedQuestionnaireInfo[]
> = (arr) => assertArray(arr, assignedQuestionnaireInfoNormaliser)

export const questionnaireAnswerNormaliser: AssertTypeFn<
  CompletedQuestionnaireAnswer
> = (obj) => ({
  choices: assertArrayOrNull(obj.choices, questionChoiceNormaliser),
  questionId: assertString(obj.questionId),
  value: assertStringOrNull(obj.value),
})

export const assignedQuestionnaireNormaliser: AssertTypeFn<
  AssignedQuestionnaire
> = (obj) => ({
  answers: assertArrayOrNull(obj.answers, questionnaireAnswerNormaliser),
  dateCompleted: assertStringOrNull(obj.dateCompleted),
  dateDue: assertStringOrNull(obj.dateDue),
  dateReceived: assertString(obj.dateReceived),
  facilityId: assertStringOrNull(obj.facilityId),
  id: assertString(obj.id),
  note: assertObjectOrUndefined(obj.note, questionnaireNoteNormaliser),
  patientId: assertString(obj.patientId),
  practitionerName: assertStringOrNull(obj.practitionerName),
  questionnaire: questionnaireNormaliser(obj.questionnaire),
  title: assertString(obj.title),
  type: assertEnum(obj.type, QuestionnaireType),
})

export const measurementNormaliser: AssertTypeFn<QuestionnaireMeasurement> = (
  obj,
) => ({
  assignedQuestionnaireId: assertStringOrNull(obj.assignedQuestionnaireId),
  createdAt: assertString(obj.createdAt),
  value: assertNumber(obj.value),
})
