/* eslint-disable max-classes-per-file */
import {ApiErrorDto} from '@generated/models'

import {Header} from '../api'

export type {ApiErrorDto as CustomApiErrorBody} from '@generated/models'
export {CushlaErrorCodeType as ApiErrorType} from '@generated/models'

export type ValidationApiErrorBody = {
  fieldName: string
  objectName: string
  value: unknown
  message?: string
}[]

type ApiRequestErrorProps = {
  message: string
  endpoint: string
  headers: Header
}

type CustomApiRequestErrorProps = {
  body: ApiErrorDto
} & ApiRequestErrorProps

type ValidationApiRequestErrorProps = {
  body: ValidationApiErrorBody
} & ApiRequestErrorProps

export class NetworkError extends Error {
  constructor(message: string) {
    super()
    this.name = 'NetworkError'
    this.message = message
    this.stack = new Error().stack
  }
}

export class AuthError extends Error {
  constructor() {
    super()
    this.name = 'AuthError'
    this.message = 'API query failed. Unable to obtain auth header.'
    this.stack = new Error().stack
  }
}

export class ApiRequestError extends Error {
  endpoint

  headers

  constructor({message, endpoint, headers}: ApiRequestErrorProps) {
    super()
    this.name = 'ApiRequestError'
    this.message = message
    this.stack = new Error().stack
    this.endpoint = endpoint
    this.headers = headers
  }
}

export class CustomApiRequestError extends ApiRequestError {
  body

  constructor({body, ...rest}: CustomApiRequestErrorProps) {
    super(rest)
    this.body = body
  }
}

export class ValidationApiRequestError extends ApiRequestError {
  body

  constructor({body, ...rest}: ValidationApiRequestErrorProps) {
    super(rest)
    this.body = body
  }
}
