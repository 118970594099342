import React from 'react'
import {useTranslation} from 'react-i18next'
import {PagingData} from '@common/models'

import {
  TablePaginationWrapper,
  TablePaginationCol,
  CurrentPageNumber,
  NoResultsText,
} from './TablePagination.styled'

import {IconButton} from '~/components/general/iconButton/IconButton'
import {theme} from '~/styles/theme'

export type TablePaginationData = Pick<
  PagingData,
  'current' | 'pageSize' | 'totalElements' | 'totalPages'
>

export type TablePaginationProps = {
  onPageClick: (pageIndex: number) => void
  hideUnusedPagination?: boolean
  paginationData?: TablePaginationData
}

export const TablePagination: React.FC<TablePaginationProps> = ({
  paginationData,
  hideUnusedPagination,
  onPageClick,
}) => {
  const {t} = useTranslation()

  // data is probably loading
  if (!paginationData) {
    return null
  }

  const {
    current: currentPageIndex,
    pageSize,
    totalElements,
    totalPages,
  } = paginationData

  const currentPageNumber = currentPageIndex + 1
  const showingRowsFrom = currentPageIndex * pageSize + 1
  const rawShowingRowsTo = currentPageIndex * pageSize + pageSize
  const showingRowsTo = Math.min(totalElements, rawShowingRowsTo)

  const canGoNext = currentPageNumber < totalPages
  const canGoPrev = currentPageNumber > 1

  if (!totalElements) {
    return (
      <NoResultsText color={theme.colors.grey.main}>
        {t('No results to show')}
      </NoResultsText>
    )
  }

  if (
    hideUnusedPagination &&
    !currentPageIndex &&
    showingRowsTo === totalElements
  ) {
    return null
  }

  return (
    <TablePaginationWrapper>
      <TablePaginationCol justify="flex-start">
        {t(
          'Showing {{showingRowsFrom}} - {{showingRowsTo}} of {{count}} result',
          {
            showingRowsFrom,
            showingRowsTo,
            count: totalElements,
          },
        )}
      </TablePaginationCol>
      <TablePaginationCol justify="center" spaceBetween="xs">
        {t('Page')} <CurrentPageNumber>{currentPageNumber}</CurrentPageNumber>
      </TablePaginationCol>
      <TablePaginationCol justify="flex-end" spaceBetween="xxs">
        <IconButton
          name="ArrowLeft"
          color="secondary"
          disabled={!canGoPrev}
          onClick={() => onPageClick(currentPageIndex - 1)}
        />
        <IconButton
          name="ArrowRight"
          color="secondary"
          disabled={!canGoNext}
          onClick={() => onPageClick(currentPageIndex + 1)}
        />
      </TablePaginationCol>
    </TablePaginationWrapper>
  )
}
