import React from 'react'

import {StyledRadio, RadioInput, RadioCheckmark} from './Radio.styled'
import {Container} from '../container/Container'
import {Typography} from '../typography/Typography'

import {theme} from '~/styles/theme'

type Props = {
  label: string
  onChange?: () => void
  checked?: boolean
  name?: string
}

export const Radio: React.FC<Props> = ({label, onChange, checked, name}) => (
  <Container flex align="center" spacing={1.5}>
    <StyledRadio htmlFor={name} disabled={!onChange}>
      <RadioInput
        type="radio"
        onChange={onChange}
        checked={checked}
        name={name}
        id={name}
        readOnly={!onChange}
      />
      <RadioCheckmark checked={checked} disabled={!onChange} />
      <Typography
        color={checked ? theme.colors.primary.main : theme.colors.grey.main}
        weight={checked ? 'medium' : 'normal'}>
        {label}
      </Typography>
    </StyledRadio>
  </Container>
)
