/* tslint:disable */
/* eslint-disable */
/**
 * Cushla API
 * REST API for Cushla
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */

export enum PregnancyFamilyHistoryConditionOptionDto {
  DIABETES = 'DIABETES',
  PRE_ECLAMPSIA = 'PRE_ECLAMPSIA',
  HEART_DISEASE = 'HEART_DISEASE',
  MENTAL_ILLNESS = 'MENTAL_ILLNESS',
  THROMBOEMBOLISM = 'THROMBOEMBOLISM',
  SICKLE_CELL_OR_THALASSAEMIA = 'SICKLE_CELL_OR_THALASSAEMIA',
  DEVELOPMENTAL_DYSPLASIA_OF_HIPS = 'DEVELOPMENTAL_DYSPLASIA_OF_HIPS',
  CONGENITAL_ABNORMALITIES = 'CONGENITAL_ABNORMALITIES',
  GENETIC_DISORDERS = 'GENETIC_DISORDERS',
  CEREBRAL_PALSY = 'CEREBRAL_PALSY',
  LEARNING_DIFFICULTIES = 'LEARNING_DIFFICULTIES',
  MULTIPLE_PREGNANCIES = 'MULTIPLE_PREGNANCIES',
}
