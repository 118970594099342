import {
  AllergyEffect,
  Appointment,
  AppointmentSummary,
  AssignedQuestionnaire,
  Birth,
  Consultation,
  DiagnosedCondition,
  LifeMilestone,
  MedicalTest,
  Medication,
  PatientAllergy,
  PatientCholesterol,
  PatientVaccination,
  RecordType,
  RowGroupType,
  Surgery,
} from '@common/models'
import {
  capitalizeFirstLetter,
  createExactOrEstimatedDate,
  EnumType,
  formatConditionStatus,
  formatDate,
  formatExactOrEstimatedDate,
  formatLocaleNumber,
  formatMedicationEndDate,
  formatQuestionnaireAnswersForDisplay,
  formatSurgeryTitle,
  isTruthy,
  parseQuestionnaireAnswers,
  useEnumTranslations,
} from '@common/utils'
import {useTranslation} from 'react-i18next'
import {useCallback} from 'react'
import {DateTime} from 'luxon'

import {TimelineCardWithSubCardProps} from '~/components/general/timeline/Timeline'
import {HealthRecordModalData} from '~/components/patients/common/healthRecordModal/HealthRecordModal'

type GetTimelineCardDataFn<T> = (item: T) => TimelineCardWithSubCardProps

export const formatCholesterolValue = (value?: number | null) =>
  value == null ? '-' : `${formatLocaleNumber(value, 1)} mmol/l`

export const useTimelineCardData = () => {
  const {t} = useTranslation()
  const {translateEnumValue} = useEnumTranslations()

  const getAllergyTimelineCardData: GetTimelineCardDataFn<PatientAllergy> =
    useCallback(
      (allergy) => ({
        title: allergy.allergen.name,
        subtitle: formatExactOrEstimatedDate(allergy.date) || '-',
        note: allergy.treatment ?? undefined,
      }),
      [],
    )

  const getBirthTimelineCardData: GetTimelineCardDataFn<Birth> = useCallback(
    (birth) => ({
      title: birth.name ?? '-',
      subtitle: birth.dateOfBirth
        ? formatDate(DateTime.DATE_FULL, birth.dateOfBirth)
        : undefined,
      note: birth.notes ?? undefined,
    }),
    [],
  )

  const getConditionTimelineCardData: GetTimelineCardDataFn<DiagnosedCondition> =
    useCallback(
      (condition) => ({
        title:
          condition.condition || condition.customName || t('Medical condition'),
        subtitle: formatExactOrEstimatedDate(condition.date) || '-',
        note: condition.endDate ? t('Inactive') : t('Active'),
      }),
      [t],
    )

  const getConsultationTimelineCardData: GetTimelineCardDataFn<Consultation> =
    useCallback(
      (consultation) => ({
        title: t('Consultation'),
        subtitle: formatDate(DateTime.DATE_FULL, consultation.date),
        isParsed: true,
      }),
      [t],
    )

  const getMedicalTestTimelineCardData: GetTimelineCardDataFn<MedicalTest> =
    useCallback(
      (test) => {
        const isParsed = test.recordType === RecordType.PARSED

        return {
          title: isParsed
            ? translateEnumValue(EnumType.ParsedMedicalTestType, test.type)
            : test.type.value.label || t('Medical test'),
          subtitle: formatExactOrEstimatedDate(test.date),
          note: test.facility?.name,
          isParsed,
        }
      },
      [translateEnumValue, t],
    )

  const getMedicationTimelineCardData: GetTimelineCardDataFn<Medication> =
    useCallback(
      (medication) => {
        const isParsed = medication.recordType === RecordType.PARSED

        return isParsed
          ? {
              title: medication.drugs?.join(', ') || t('Medication'),
              subtitle: formatExactOrEstimatedDate(medication.date),
              isParsed,
            }
          : {
              title:
                medication.drug?.tradeName ||
                medication.name ||
                t('Medication'),
              subtitle: formatExactOrEstimatedDate(medication.startDate),
              note: formatMedicationEndDate(t, medication.endDate),
              isParsed,
            }
      },
      [t],
    )

  const getSurgeryTimelineCardData: GetTimelineCardDataFn<Surgery> =
    useCallback(
      (surgery) => ({
        title: formatSurgeryTitle(surgery, t) || t('Surgery'),
        subtitle: formatExactOrEstimatedDate(surgery.date),
        note: surgery.facility?.name,
        isParsed: surgery.recordType === RecordType.PARSED,
      }),
      [t],
    )

  const getVaccinationTimelineCardData: GetTimelineCardDataFn<PatientVaccination> =
    useCallback((vaccination) => {
      const isParsed = vaccination.recordType === RecordType.PARSED

      return isParsed
        ? {
            title: vaccination.shotName,
            subtitle: formatExactOrEstimatedDate(vaccination.date),
            note: vaccination.facility?.name,
            isParsed,
          }
        : {
            title: vaccination.vaccine.name,
            subtitle: formatExactOrEstimatedDate(vaccination.date),
            note: vaccination.facility?.name,
            isParsed,
          }
    }, [])

  return {
    getAllergyTimelineCardData,
    getBirthTimelineCardData,
    getConditionTimelineCardData,
    getConsultationTimelineCardData,
    getMedicalTestTimelineCardData,
    getMedicationTimelineCardData,
    getSurgeryTimelineCardData,
    getVaccinationTimelineCardData,
  }
}

export const useModalData = () => {
  const {t} = useTranslation()
  const {translateEnumValue} = useEnumTranslations()

  const getFormattedAllergyEffects = useCallback(
    (effects?: AllergyEffect[] | null, description?: string | null) => {
      if (!effects) {
        return [description || '-']
      }

      const translatedEffects = effects
        .filter((effect) => effect !== AllergyEffect.OTHER)
        .map((effect) => translateEnumValue(EnumType.AllergyEffect, effect))

      return description
        ? [...translatedEffects, description]
        : translatedEffects
    },
    [translateEnumValue],
  )

  const getAllergyModalData = useCallback(
    (allergy: PatientAllergy): HealthRecordModalData => {
      const {
        allergen,
        date,
        description,
        effects,
        id,
        sourceDocument,
        treatment,
      } = allergy

      return {
        fileEndpointId: id,
        header: t('Allergy'),
        date,
        hasAttachment: !!sourceDocument,
        rows: [
          {
            field: t('Allergy'),
            value: allergen.name,
          },
          {
            field: t('Allergy category'),
            value: allergen.category,
          },
          {
            field: t('Date detected'),
            value: formatExactOrEstimatedDate(date) || '-',
          },
          {
            field: t('Effects'),
            type: RowGroupType.MULTILINE,
            values: getFormattedAllergyEffects(effects, description),
          },
          {
            field: t('Treatment'),
            value: treatment ?? '-',
          },
        ],
      }
    },
    [getFormattedAllergyEffects, t],
  )

  const getCholesterolModalData = useCallback(
    (cholesterol: PatientCholesterol): HealthRecordModalData => {
      const {
        date,
        facility,
        hdl,
        hdlRatio,
        ldl,
        totalAmount,
        triglycerides,
        id,
        sourceDocument,
      } = cholesterol

      return {
        fileEndpointId: id,
        header: t('Cholesterol'),
        facility,
        hasAttachment: !!sourceDocument,
        date,
        rows: [
          {
            field: t('Date of test'),
            value: formatExactOrEstimatedDate(date) || '-',
          },
          {
            field: t('Total amount'),
            value: formatCholesterolValue(totalAmount),
          },
          {
            field: t('HDL'),
            value: formatCholesterolValue(hdl),
          },
          {
            field: t('HDL ratio'),
            value: formatLocaleNumber(hdlRatio, 2) || '-',
          },
          {
            field: t('LDL'),
            value: formatCholesterolValue(ldl),
          },
          {
            field: t('Triglycerides'),
            value: formatCholesterolValue(triglycerides),
          },
        ],
      }
    },
    [t],
  )

  const getConditionModalData = useCallback(
    (diagnosedCondition: DiagnosedCondition): HealthRecordModalData => {
      const {
        condition,
        customName,
        date,
        endDate,
        facility,
        id,
        sourceDocument,
      } = diagnosedCondition

      return {
        fileEndpointId: id,
        header: t('Medical condition'),
        facility,
        hasAttachment: !!sourceDocument,
        date,
        rows: [
          {
            field: t('Condition'),
            value: condition || customName || '-',
          },
          {
            field: t('Date of diagnosis'),
            value: formatExactOrEstimatedDate(date) || '-',
          },
          {
            field: t('Current status'),
            value: formatConditionStatus(t, endDate),
          },
          {
            field: t('Diagnosed or treated by a professional?'),
            value: facility ? t('Yes') : t('No'),
          },
        ],
      }
    },
    [t],
  )

  const getConsultationModalData = useCallback(
    (consultation: Consultation): HealthRecordModalData => {
      const {date, filePath, id} = consultation

      return {
        date: createExactOrEstimatedDate(date),
        fileEndpointId: id,
        hasAttachment: !!filePath,
        rows: [],
      }
    },
    [],
  )

  const getLifeMilestoneModalData = useCallback(
    (lifeMilestone: LifeMilestone): HealthRecordModalData => {
      const {date, description, id, impact, milestone, type} = lifeMilestone

      return {
        header: t('Life milestone'),
        fileEndpointId: id,
        date,
        rows: [
          {
            field: t('Milestone'),
            value:
              capitalizeFirstLetter(milestone) ||
              translateEnumValue(EnumType.LifeMilestoneType, type),
          },
          {
            field: t('Impact'),
            value:
              translateEnumValue(EnumType.LifeMilestoneImpact, impact) || '-',
          },
          {
            field: t('Description'),
            value: description || '-',
          },
        ],
      }
    },
    [t, translateEnumValue],
  )

  const getMedicalTestModalData = useCallback(
    (test: MedicalTest): HealthRecordModalData => {
      const isParsed = test.recordType === RecordType.PARSED

      return {
        header: t('Medical test'),
        facility: test.facility,
        fileEndpointId: test.id,
        hasAttachment: isParsed ? !!test.uploadId : !!test.sourceDocument,
        date: test.date,
        rows: isParsed
          ? [
              {
                field: t('Medical test'),
                value: test.header,
              },
              ...test.rowGroups,
            ]
          : [
              {
                field: t('Type'),
                value: test.type.value.label || '-',
              },
              {
                field: t('Result / description'),
                value: test.description || '-',
              },
            ],
        uploadId: isParsed ? test.uploadId : undefined,
      }
    },
    [t],
  )

  const getMedicationModalData = useCallback(
    (medication: Medication): HealthRecordModalData => {
      const isParsed = medication.recordType === RecordType.PARSED

      return {
        fileEndpointId: medication.id,
        header: t('Medication'),
        hasAttachment: isParsed
          ? !!medication.uploadId
          : !!medication.sourceDocument,
        facility: isParsed ? medication.facility : null,
        date: isParsed ? medication.date : medication.startDate,
        uploadId: isParsed ? medication.uploadId : undefined,
        rows: isParsed
          ? [
              {
                field: t('Drugs'),
                type: RowGroupType.MULTILINE,
                values: medication.drugs,
              },
              {
                field: t('Dosage'),
                value: medication.dosage || '-',
              },
            ]
          : [
              {
                field: t('Medication'),
                value: medication.drug?.tradeName || medication.name || '-',
              },
              {
                field: t('Dosage'),
                value: medication.dosage || '-',
              },
              {
                field: t('Frequency'),
                value: medication.frequency || '-',
              },
              {
                field: t('Started taking'),
                value: formatExactOrEstimatedDate(medication.startDate) || '-',
              },
              {
                field: t('End date'),
                value:
                  formatExactOrEstimatedDate(medication.endDate) ||
                  t('Currently taking'),
              },
            ],
      }
    },
    [t],
  )

  const getSurgeryModalData = useCallback(
    (surgery: Surgery): HealthRecordModalData => {
      const isParsed = surgery.recordType === RecordType.PARSED

      return {
        fileEndpointId: surgery.id,
        header: t('Procedure'),
        facility: surgery.facility,
        date: surgery.date,
        hasAttachment: isParsed ? !!surgery.uploadId : !!surgery.sourceDocument,
        uploadId: isParsed ? surgery.uploadId : undefined,
        rows: [
          {
            field: t('Type'),
            value: isParsed ? '-' : surgery.type?.value.label || '-',
          },
          {
            field: t('Description'),
            value: surgery.description,
          },
        ],
      }
    },
    [t],
  )

  const getVaccinationModalData = useCallback(
    (vaccination: PatientVaccination): HealthRecordModalData => {
      const isParsed = vaccination.recordType === RecordType.PARSED

      return {
        fileEndpointId: vaccination.id,
        header: t('Vaccination'),
        facility: vaccination.facility,
        date: vaccination.date,
        hasAttachment: isParsed
          ? !!vaccination.uploadId
          : !!vaccination.sourceDocument,
        uploadId: isParsed ? vaccination.uploadId : undefined,
        rows: [
          {
            field: t('Name'),
            value: isParsed ? vaccination.shotName : vaccination.vaccine.name,
          },
          {
            field: t('Shot description'),
            value: (isParsed && vaccination.shotDescription) || '-',
          },
          {
            field: t('Manufacturer'),
            value: (isParsed && vaccination.manufacturer) || '-',
          },
          {
            field: t('Batch'),
            value: (isParsed && vaccination.batch) || '-',
          },
          {
            field: t('Expiry date'),
            value:
              (isParsed && formatExactOrEstimatedDate(vaccination.expiry)) ||
              '-',
          },
        ],
      }
    },
    [t],
  )

  const getAppointmentSummaryModalData = useCallback(
    (
      summary: AppointmentSummary,
      appointment?: Appointment,
    ): HealthRecordModalData => ({
      fileEndpointId: appointment?.id,
      header: t('Appointment summary'),
      facility: appointment?.facility,
      date: createExactOrEstimatedDate(summary.createdAt),
      hasAttachment: !!summary.file,
      rows: [
        {
          field: t('Key patient messages'),
          value: summary.summary || '-',
        },
        {
          type: RowGroupType.LINE,
          line: t('Prescribed medications'),
        },
        ...summary.prescribedMedications.map(
          ({dosage, prescriptionEndDate, frequency, productName}) => ({
            type: RowGroupType.MULTILINE,
            field: productName,
            values: [
              dosage,
              frequency,
              prescriptionEndDate
                ? t('Until {{date}}', {
                    date: formatDate(DateTime.DATE_MED, prescriptionEndDate),
                  })
                : null,
            ].filter(isTruthy),
          }),
        ),
      ],
    }),
    [t],
  )

  const getAppointmentTriageModalData = useCallback(
    (
      triage: AssignedQuestionnaire,
      appointment?: Appointment,
    ): HealthRecordModalData => {
      const {questions} = triage.questionnaire
      const parsedAnswers = parseQuestionnaireAnswers(
        questions,
        triage.answers ?? [],
      )

      const formattedAnswers = formatQuestionnaireAnswersForDisplay(
        parsedAnswers,
        questions,
      )

      return {
        header: t('Appointment triage'),
        facility: appointment?.facility,
        date: createExactOrEstimatedDate(triage.dateCompleted),
        rows: [
          {
            type: RowGroupType.LINE,
            line: t('Answers'),
          },
          ...formattedAnswers.map(({title, value}) => ({
            field: title,
            value: value ?? '-',
          })),
        ],
      }
    },
    [t],
  )

  return {
    getAllergyModalData,
    getAppointmentSummaryModalData,
    getAppointmentTriageModalData,
    getCholesterolModalData,
    getConsultationModalData,
    getConditionModalData,
    getLifeMilestoneModalData,
    getMedicalTestModalData,
    getMedicationModalData,
    getSurgeryModalData,
    getVaccinationModalData,
  }
}
