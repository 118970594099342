import * as React from 'react'

import {IconProps} from '.'

function ShareIcon({color, size, isFilled}: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill={isFilled ? color : 'none'}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4 10C5.10457 10 6 9.10457 6 8C6 6.89543 5.10457 6 4 6C2.89543 6 2 6.89543 2 8C2 9.10457 2.89543 10 4 10Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 14.5C12.1046 14.5 13 13.6046 13 12.5C13 11.3954 12.1046 10.5 11 10.5C9.89543 10.5 9 11.3954 9 12.5C9 13.6046 9.89543 14.5 11 14.5Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 5.5C12.1046 5.5 13 4.60457 13 3.5C13 2.39543 12.1046 1.5 11 1.5C9.89543 1.5 9 2.39543 9 3.5C9 4.60457 9.89543 5.5 11 5.5Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.31782 4.58203L5.68213 6.91926"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.68213 9.08203L9.31782 11.4193"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default ShareIcon
