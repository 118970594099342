import {useCallback, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {DateTime} from 'luxon'

import {
  BasicPatientData,
  HealthDataType,
  HealthDataTypeProperties,
  LatestActivity,
  LatestHealthDataDetails,
  LifestyleInformation,
  MonthlySurveyType,
  SurveyTypeWithScores,
} from '../models'
import {getHealthDataGrades, getTobaccoGrade} from './grades'
import {useEnumTranslations} from './translations'
import {EnumType} from './types'

export const surveyHealthDataTypeMap: Record<
  SurveyTypeWithScores,
  HealthDataType
> = {
  [MonthlySurveyType.EATING_HABITS]: HealthDataType.DIET_SCORE,
  [MonthlySurveyType.MOOD]: HealthDataType.MOOD_SCORE,
  [MonthlySurveyType.SLEEP]: HealthDataType.SLEEP_SCORE,
}

const healthDataTypeIsSummableSet = new Set([
  HealthDataType.ACTIVELY_BURNED_CALORIES,
  HealthDataType.MOVEMENT_SCORE,
  HealthDataType.STEPS,
  HealthDataType.ACTIVE_MINUTES,
  HealthDataType.WATER_CONSUMPTION_ML,
])

export const healthDataFractionDigitsMap: Record<HealthDataType, number> = {
  [HealthDataType.ACTIVELY_BURNED_CALORIES]: 0,
  [HealthDataType.ALCOHOL_SCORE]: 1,
  [HealthDataType.BLOOD_PRESSURE]: 0,
  [HealthDataType.BMI]: 1,
  [HealthDataType.BODY_FAT_PERCENTAGE]: 1,
  [HealthDataType.CHOLESTEROL_HDL_RATIO]: 2,
  [HealthDataType.DIET_SCORE]: 0,
  [HealthDataType.HEART_AGE_YEARS]: 0,
  [HealthDataType.HEIGHT_CM]: 0,
  [HealthDataType.MOOD_SCORE]: 0,
  [HealthDataType.MOVEMENT_SCORE]: 0,
  [HealthDataType.PEAK_FLOW]: 0,
  [HealthDataType.RESTING_HEART_RATE]: 0,
  [HealthDataType.QUALITY_SLEEP_SECONDS]: 2,
  [HealthDataType.SLEEP_SCORE]: 0,
  [HealthDataType.STEPS]: 0,
  [HealthDataType.TOBACCO_SCORE]: 1,
  [HealthDataType.VO2_MAX]: 0,
  [HealthDataType.ACTIVE_MINUTES]: 0,
  [HealthDataType.WAIST_CIRCUMFERENCE_CM]: 1,
  [HealthDataType.WATER_CONSUMPTION_ML]: 2,
  [HealthDataType.WEIGHT_KG]: 1,
}

export const useHealthDataTypeUnits: () => Record<
  HealthDataType,
  string | undefined
> = () => {
  const {t} = useTranslation()

  return useMemo(
    () => ({
      [HealthDataType.ACTIVELY_BURNED_CALORIES]: 'kcal',
      [HealthDataType.ALCOHOL_SCORE]: t('units per week'),
      [HealthDataType.BLOOD_PRESSURE]: undefined,
      [HealthDataType.BMI]: 'kg/m\u00B2',
      [HealthDataType.BODY_FAT_PERCENTAGE]: '%',
      [HealthDataType.CHOLESTEROL_HDL_RATIO]: undefined,
      [HealthDataType.DIET_SCORE]: undefined,
      [HealthDataType.HEART_AGE_YEARS]: t('years'),
      [HealthDataType.HEIGHT_CM]: 'cm',
      [HealthDataType.MOOD_SCORE]: undefined,
      [HealthDataType.MOVEMENT_SCORE]: undefined,
      [HealthDataType.PEAK_FLOW]: t('l/min'),
      [HealthDataType.RESTING_HEART_RATE]: t('BPM'),
      [HealthDataType.QUALITY_SLEEP_SECONDS]: 'h',
      [HealthDataType.SLEEP_SCORE]: undefined,
      [HealthDataType.STEPS]: undefined,
      [HealthDataType.TOBACCO_SCORE]: t('units per day'),
      [HealthDataType.VO2_MAX]: 'ml/kg/min',
      [HealthDataType.ACTIVE_MINUTES]: 'min',
      [HealthDataType.WAIST_CIRCUMFERENCE_CM]: 'cm',
      [HealthDataType.WATER_CONSUMPTION_ML]: 'l',
      [HealthDataType.WEIGHT_KG]: 'kg',
    }),
    [t],
  )
}

const healthDataValueConverterMap: Partial<
  Record<HealthDataType, (value: number) => number>
> = {
  [HealthDataType.QUALITY_SLEEP_SECONDS]: (value: number) => value / 3600,
  [HealthDataType.WATER_CONSUMPTION_ML]: (value: number) => value / 1000,
}

export const convertHealthDataValue = (
  value: number | undefined,
  healthDataType: HealthDataType,
) => {
  if (value == null) {
    return undefined
  }

  const convertValue =
    healthDataValueConverterMap[healthDataType] ?? ((val) => val)
  return convertValue(value)
}

export const useHealthDataTypeProperties = () => {
  const {translateEnumValue} = useEnumTranslations()
  const unitMap = useHealthDataTypeUnits()

  return useCallback(
    (healthDataType: HealthDataType): HealthDataTypeProperties => ({
      title: translateEnumValue(EnumType.HealthDataType, healthDataType),
      unit: unitMap[healthDataType],
      isSummable: healthDataTypeIsSummableSet.has(healthDataType),
      fractionDigits: healthDataFractionDigitsMap[healthDataType],
    }),
    [translateEnumValue, unitMap],
  )
}

const getWeightGrades = (
  latestActivities: LatestActivity | undefined,
  patient: BasicPatientData | undefined,
) => {
  const lastBmiDate = latestActivities?.bmi[0]?.date
  const lastWeightDate = latestActivities?.weightKg[0]?.date
  const doDatesMatch =
    !!lastBmiDate &&
    !!lastWeightDate &&
    DateTime.fromISO(lastBmiDate).toISODate() ===
      DateTime.fromISO(lastWeightDate).toISODate() // this removes time information, in case there would be any

  if (!doDatesMatch) {
    return undefined
  }

  return getHealthDataGrades(
    HealthDataType.BMI,
    latestActivities.bmi[0]?.value,
    latestActivities.bmi[1]?.value,
    patient,
  )
}

export const useLatestHealthDataDetails = ({
  latestActivities,
  lifestyleData,
  patient,
}: {
  latestActivities: LatestActivity | undefined
  lifestyleData: LifestyleInformation | undefined
  patient: BasicPatientData | undefined
}) => {
  const getHealthDataProperties = useHealthDataTypeProperties()

  const getLatestHealthDataDetails = useCallback(
    (
      healthDataType: HealthDataType,
      lastValue: number | undefined,
      previousValue: number | undefined,
      user: BasicPatientData | undefined,
    ) => ({
      ...getHealthDataGrades(healthDataType, lastValue, previousValue, user),
      ...getHealthDataProperties(healthDataType),
      value: convertHealthDataValue(lastValue, healthDataType),
    }),
    [getHealthDataProperties],
  )

  return useMemo<Record<HealthDataType, LatestHealthDataDetails>>(
    () => ({
      [HealthDataType.ACTIVELY_BURNED_CALORIES]: {
        ...getLatestHealthDataDetails(
          HealthDataType.ACTIVELY_BURNED_CALORIES,
          latestActivities?.activelyBurnedCalories[0]?.value,
          latestActivities?.activelyBurnedCalories[1]?.value,
          patient,
        ),
        date: latestActivities?.activelyBurnedCalories[0]?.date,
      },
      [HealthDataType.ALCOHOL_SCORE]: {
        ...getLatestHealthDataDetails(
          HealthDataType.ALCOHOL_SCORE,
          latestActivities?.alcoholScore[0]?.value,
          latestActivities?.alcoholScore[1]?.value,
          patient,
        ),
        date: latestActivities?.alcoholScore[0]?.date,
      },
      [HealthDataType.BLOOD_PRESSURE]: {
        ...getLatestHealthDataDetails(
          HealthDataType.BLOOD_PRESSURE,
          latestActivities?.bloodPressure[0]?.systolic,
          latestActivities?.bloodPressure[1]?.systolic,
          patient,
        ),
        date: latestActivities?.bloodPressure[0]?.date,
        value: `${latestActivities?.bloodPressure[0]?.systolic ?? '-'}\n${
          latestActivities?.bloodPressure[0]?.diastolic ?? '-'
        }`,
      },
      [HealthDataType.BMI]: {
        ...getLatestHealthDataDetails(
          HealthDataType.BMI,
          latestActivities?.bmi[0]?.value,
          latestActivities?.bmi[1]?.value,
          patient,
        ),
        date: latestActivities?.bmi[0]?.date,
      },
      [HealthDataType.BODY_FAT_PERCENTAGE]: {
        ...getLatestHealthDataDetails(
          HealthDataType.BODY_FAT_PERCENTAGE,
          latestActivities?.bodyFatPercentage[0]?.value,
          latestActivities?.bodyFatPercentage[1]?.value,
          patient,
        ),
        date: latestActivities?.bodyFatPercentage[0]?.date,
      },
      [HealthDataType.CHOLESTEROL_HDL_RATIO]: {
        ...getLatestHealthDataDetails(
          HealthDataType.CHOLESTEROL_HDL_RATIO,
          latestActivities?.cholesterolHdlRatio[0]?.value,
          latestActivities?.cholesterolHdlRatio[1]?.value,
          patient,
        ),
        date: latestActivities?.cholesterolHdlRatio[0]?.date,
      },
      [HealthDataType.DIET_SCORE]: {
        ...getLatestHealthDataDetails(
          HealthDataType.DIET_SCORE,
          latestActivities?.dietScore[0]?.value,
          latestActivities?.dietScore[1]?.value,
          patient,
        ),
        date: latestActivities?.dietScore[0]?.date,
      },
      [HealthDataType.HEART_AGE_YEARS]: {
        ...getLatestHealthDataDetails(
          HealthDataType.HEART_AGE_YEARS,
          latestActivities?.heartAgeYears[0]?.value,
          latestActivities?.heartAgeYears[1]?.value,
          patient,
        ),
        date: latestActivities?.heartAgeYears[0]?.date,
      },
      [HealthDataType.HEIGHT_CM]: {
        ...getLatestHealthDataDetails(
          HealthDataType.HEIGHT_CM,
          latestActivities?.heightCm[0]?.value,
          latestActivities?.heightCm[1]?.value,
          patient,
        ),
        date: latestActivities?.heightCm[0]?.date,
      },
      [HealthDataType.MOOD_SCORE]: {
        ...getLatestHealthDataDetails(
          HealthDataType.MOOD_SCORE,
          latestActivities?.moodScore[0]?.value,
          latestActivities?.moodScore[1]?.value,
          patient,
        ),
        date: latestActivities?.moodScore[0]?.date,
      },
      [HealthDataType.MOVEMENT_SCORE]: {
        ...getLatestHealthDataDetails(
          HealthDataType.MOVEMENT_SCORE,
          latestActivities?.movementScore[0]?.value,
          latestActivities?.movementScore[1]?.value,
          patient,
        ),
        date: latestActivities?.movementScore[0]?.date,
      },
      [HealthDataType.PEAK_FLOW]: {
        ...getLatestHealthDataDetails(
          HealthDataType.PEAK_FLOW,
          latestActivities?.peakFlow[0]?.value,
          latestActivities?.peakFlow[1]?.value,
          patient,
        ),
        date: latestActivities?.peakFlow[0]?.date,
      },
      [HealthDataType.QUALITY_SLEEP_SECONDS]: {
        ...getLatestHealthDataDetails(
          HealthDataType.QUALITY_SLEEP_SECONDS,
          latestActivities?.qualitySleepSeconds[0]?.value,
          latestActivities?.qualitySleepSeconds[1]?.value,
          patient,
        ),
        date: latestActivities?.qualitySleepSeconds[0]?.date,
      },
      [HealthDataType.RESTING_HEART_RATE]: {
        ...getLatestHealthDataDetails(
          HealthDataType.RESTING_HEART_RATE,
          latestActivities?.restingHeartRate[0]?.value,
          latestActivities?.restingHeartRate[1]?.value,
          patient,
        ),
        date: latestActivities?.restingHeartRate[0]?.date,
      },
      [HealthDataType.SLEEP_SCORE]: {
        ...getLatestHealthDataDetails(
          HealthDataType.SLEEP_SCORE,
          latestActivities?.sleepScore[0]?.value,
          latestActivities?.sleepScore[1]?.value,
          patient,
        ),
        date: latestActivities?.sleepScore[0]?.date,
      },
      [HealthDataType.STEPS]: {
        ...getLatestHealthDataDetails(
          HealthDataType.STEPS,
          latestActivities?.steps[0]?.value,
          latestActivities?.steps[1]?.value,
          patient,
        ),
        date: latestActivities?.steps[0]?.date,
      },
      [HealthDataType.TOBACCO_SCORE]: {
        ...getLatestHealthDataDetails(
          HealthDataType.TOBACCO_SCORE,
          latestActivities?.tobaccoScore[0]?.value,
          latestActivities?.tobaccoScore[1]?.value,
          patient,
        ),
        date: latestActivities?.tobaccoScore[0]?.date,
        grade: getTobaccoGrade(lifestyleData?.tobacco),
      },
      [HealthDataType.VO2_MAX]: {
        ...getLatestHealthDataDetails(
          HealthDataType.VO2_MAX,
          latestActivities?.vo2Max[0]?.value,
          latestActivities?.vo2Max[1]?.value,
          patient,
        ),
        date: latestActivities?.vo2Max[0]?.date,
      },
      [HealthDataType.ACTIVE_MINUTES]: {
        ...getLatestHealthDataDetails(
          HealthDataType.ACTIVE_MINUTES,
          latestActivities?.activeMinutes[0]?.value,
          latestActivities?.activeMinutes[1]?.value,
          patient,
        ),
        date: latestActivities?.activeMinutes[0]?.date,
      },
      [HealthDataType.WAIST_CIRCUMFERENCE_CM]: {
        ...getLatestHealthDataDetails(
          HealthDataType.WAIST_CIRCUMFERENCE_CM,
          latestActivities?.waistCircumferenceCm[0]?.value,
          latestActivities?.waistCircumferenceCm[1]?.value,
          patient,
        ),
        date: latestActivities?.waistCircumferenceCm[0]?.date,
      },
      [HealthDataType.WATER_CONSUMPTION_ML]: {
        ...getLatestHealthDataDetails(
          HealthDataType.WATER_CONSUMPTION_ML,
          latestActivities?.waterConsumptionMl[0]?.value,
          latestActivities?.waterConsumptionMl[1]?.value,
          patient,
        ),
        date: latestActivities?.waterConsumptionMl[0]?.date,
      },
      [HealthDataType.WEIGHT_KG]: {
        ...getLatestHealthDataDetails(
          HealthDataType.WEIGHT_KG,
          latestActivities?.weightKg[0]?.value,
          latestActivities?.weightKg[1]?.value,
          patient,
        ),
        date: latestActivities?.weightKg[0]?.date,
        ...getWeightGrades(latestActivities, patient),
      },
    }),
    [
      getLatestHealthDataDetails,
      latestActivities,
      lifestyleData?.tobacco,
      patient,
    ],
  )
}
