import React, {ReactNode} from 'react'

import {
  StyledAuthLayout,
  AuthLayoutBg,
  AuthLayoutContent,
} from './AuthLayout.styled'
import {LayoutContainer} from '../container/LayoutContainer'

import {Image} from '~/components/general/image/Image'

type AuthLayoutProps = {
  children: ReactNode
}

export const AuthLayout: React.FC<AuthLayoutProps> = ({children}) => (
  <StyledAuthLayout>
    <AuthLayoutBg>
      <Image name="AuthLayout" height="100%" width={undefined} />
    </AuthLayoutBg>
    <AuthLayoutContent>
      <LayoutContainer>{children}</LayoutContainer>
    </AuthLayoutContent>
  </StyledAuthLayout>
)
