import * as React from 'react'

import {IconProps} from '.'

function Send({color, size, isFilled}: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill={isFilled ? color : 'none'}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.1406 2.24143L1.49513 5.52606C1.39716 5.55369 1.30996 5.61057 1.24518 5.68909C1.1804 5.76761 1.14112 5.86403 1.13261 5.96546C1.1241 6.0669 1.14675 6.16852 1.19753 6.25674C1.24832 6.34496 1.32482 6.41557 1.41682 6.45915L6.76749 8.99368C6.87185 9.04312 6.95589 9.12715 7.00532 9.23151L9.53985 14.5822C9.58343 14.6742 9.65404 14.7507 9.74226 14.8015C9.83048 14.8523 9.9321 14.8749 10.0335 14.8664C10.135 14.8579 10.2314 14.8186 10.3099 14.7538C10.3884 14.689 10.4453 14.6018 10.4729 14.5039L13.7576 2.85839C13.7817 2.77284 13.7826 2.68242 13.7601 2.59642C13.7377 2.51041 13.6927 2.43195 13.6299 2.3691C13.567 2.30626 13.4886 2.2613 13.4026 2.23886C13.3166 2.21641 13.2262 2.2173 13.1406 2.24143Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.92896 9.07061L9.75738 6.24219"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default Send
