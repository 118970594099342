import React, {useContext} from 'react'
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom'
import {MaterialsFolder, MaterialsItem, MaterialsItemType} from '@common/models'
import {MISSING_ID} from '@common/utils'

import {MaterialsContext} from '../MaterialsContext'
import {SelectedMaterials} from '../sideBar/SelectedMaterials'

import {MaterialsContent} from '~/components/educationalMaterials/materials/Materials.styled'
import {Layout} from '~/components/layout/Layout'
import {Material} from '~/components/educationalMaterials/materials/Material'

type SubcategoryPageContentProps = {
  id: string
}

const SubcategoryPageContent: React.FC<SubcategoryPageContentProps> = ({
  id,
}) => {
  const {addItem, itemIdMap} = useContext(MaterialsContext)
  const navigate = useNavigate()
  const location = useLocation()

  const contentToDisplay = (itemIdMap[id] as MaterialsFolder)?.children

  const handleMaterialClick = (item: MaterialsItem) => {
    if (item.type === MaterialsItemType.FOLDER) {
      navigate(`${location.pathname}/${item.id}`)
    } else {
      navigate(`/app/materials/file/${item.id}`)
    }
  }

  return (
    <Layout rightSide={<SelectedMaterials />}>
      <MaterialsContent>
        {contentToDisplay?.map((item) => (
          <Material
            key={item.id}
            item={item}
            onClick={() => handleMaterialClick(item)}
            onIconClick={() => {
              addItem(item)
            }}
          />
        ))}
      </MaterialsContent>
    </Layout>
  )
}

export const MaterialsSubcategoryPage: React.FC = () => {
  const params = useParams()

  return (
    <div>
      <Routes>
        <Route path=":id/*" element={<MaterialsSubcategoryPage />} />
        <Route
          path="*"
          element={<SubcategoryPageContent id={params.id || MISSING_ID} />}
        />
      </Routes>
    </div>
  )
}
