// eslint-disable-next-line import/extensions
import en from './en/translation.json'
// eslint-disable-next-line import/extensions
import enIE from './en-IE/translation.json'

export default {
  en: {
    translation: en,
  },
  'en-IE': {
    translation: enIE,
  },
}
