import styled from 'styled-components'

type TextProp = {
  isLast?: boolean
}

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`

export const StyledText = styled.h3<TextProp>`
  color: ${({isLast, theme}) =>
    isLast ? theme.colors.primary.main : theme.colors.grey.main};
  font-weight: ${({isLast, theme}) =>
    isLast ? theme.fontWeight.medium : theme.fontWeight.normal};
  ${({onClick}) => !!onClick && 'cursor: pointer;'}

  &:hover {
    color: ${({theme}) => theme.colors.primary.main};
  }
`

export const StyledSeparator = styled.h3`
  margin: 0 ${({theme}) => theme.spacing.md};
  color: ${({theme}) => theme.colors.grey.main};
  font-weight: ${({theme}) => theme.fontWeight.normal};
`
