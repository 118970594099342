import {RowGroupType} from '@common/models'
import React from 'react'

import {StyledTable} from './PatientTimelineModal.styled'
import {ExtendedRowGroup} from './PatientTimelineModal'

import {Typography} from '~/components/general/typography/Typography'
import {theme} from '~/styles/theme'
import {ExpandableText} from '~/components/general/expandableText/ExpandableText'

type RowGroupValueProps = {
  row: Partial<ExtendedRowGroup>
}

const RowGroupValue: React.FC<RowGroupValueProps> = ({row}) => {
  switch (row.type) {
    case RowGroupType.MULTILINE:
      if (!row.values) {
        return null
      }
      return (
        <>
          {row.values.map((value, index) => (
            <Typography color={theme.colors.grey.main} key={index}>
              <ExpandableText
                shortenedLength={200}
                disableExpansion={!row.expandable}
                text={value}
              />
            </Typography>
          ))}
        </>
      )
    case RowGroupType.LINE:
      return (
        <Typography color={theme.colors.grey.main}>
          <ExpandableText
            shortenedLength={200}
            disableExpansion={!row.expandable}
            text={row.subLine || '-'}
          />
        </Typography>
      )
    case RowGroupType.TABLE:
      return (
        <StyledTable>
          <tbody>
            {row.rows?.map((tableRow, rowIndex) => (
              <tr key={rowIndex}>
                <td>
                  <Typography ellipsis>{tableRow.field}</Typography>
                </td>
                {tableRow.cells.map((cell, index) => (
                  <td key={`${cell}${index}`}>
                    <Typography>{cell}</Typography>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </StyledTable>
      )
    default:
      return (
        <Typography color={theme.colors.primary.blue900}>
          <ExpandableText
            shortenedLength={200}
            disableExpansion={!row.expandable}
            text={row.value || '-'}
          />
        </Typography>
      )
  }
}

export default RowGroupValue
