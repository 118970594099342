import React, {useMemo} from 'react'
import {useResolutionOptions} from '@common/utils'
import {HealthDataResolution} from '@common/models'

import {Option, Select} from '~/components/general/select/Select'
import {useKeyPress} from '~/utils'
import {Container} from '~/components/general/container/Container'
import {theme} from '~/styles/theme'
import {Typography} from '~/components/general/typography/Typography'

const getOptionKey = (label: string) => label.slice(0, 1).toLocaleLowerCase()

type TrendChartResolutionSelectProps = {
  resolution: HealthDataResolution
  onChange: (resolution: HealthDataResolution) => void
}

export const TrendChartResolutionSelect: React.FC<
  TrendChartResolutionSelectProps
> = ({resolution, onChange}) => {
  const resolutionOptions = useResolutionOptions()

  const keyPressMap = useMemo(
    () =>
      resolutionOptions.reduce<Record<string, () => void>>(
        (obj, option) => ({
          ...obj,
          [getOptionKey(option.label)]: () => onChange(option.value),
        }),
        {},
      ),
    [resolutionOptions, onChange],
  )

  useKeyPress(keyPressMap)

  return (
    <Select
      width={150}
      value={resolutionOptions.find(({value}) => value === resolution)?.label}>
      {resolutionOptions.map(({value, label}) => (
        <Option key={value} onClick={() => onChange(value)}>
          <Container fullWidth flex justify="space-between">
            <Typography color={theme.colors.grey.main}>{label}</Typography>
            <Typography color={theme.colors.grey.main}>
              {getOptionKey(label).toUpperCase()}
            </Typography>
          </Container>
        </Option>
      ))}
    </Select>
  )
}
