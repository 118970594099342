import React, {ReactNode, useEffect} from 'react'

import {Portal} from '~/components/general/modal/Portal'
import {
  ModalBody,
  StyledModal,
  ModalHeader,
  ModalContent,
} from '~/components/general/modal/SideModal.styled'
import {Icon} from '~/components/general/icon/Icon'

type SideModalProps = {
  children: React.ReactNode
  header: ReactNode
  onClose: () => void
  isVisible: boolean
}

export const SideModal: React.FC<SideModalProps> = ({
  header,
  children,
  onClose,
  isVisible,
}) => {
  useEffect(() => {
    const handleEscapeClose = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose()
      }
    }

    document.addEventListener('keydown', handleEscapeClose)
    return () => {
      document.removeEventListener('keydown', handleEscapeClose)
    }
  }, [onClose])

  return isVisible ? (
    <Portal selector="#modal">
      <ModalBody onClick={onClose}>
        <StyledModal onClick={(e) => e.stopPropagation()}>
          {header && (
            <ModalHeader>
              {header}
              <Icon onClick={onClose} circled="md" name="Close" size="sm" />
            </ModalHeader>
          )}
          <ModalContent>{children}</ModalContent>
        </StyledModal>
      </ModalBody>
    </Portal>
  ) : null
}
