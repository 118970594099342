import {
  ExactOrEstimatedDate,
  Facility,
  Practitioner,
  RowGroup,
  RowGroupType,
} from '@common/models'
import {useTranslation} from 'react-i18next'
import React, {useContext} from 'react'
import {
  capitalizeFirstLetter,
  EnumType,
  formatExactOrEstimatedDate,
  useEnumTranslations,
} from '@common/utils'

import {
  ContentColumn,
  RowHeader,
  StyledChip,
  StyledHeaderText,
} from './HealthRecordModal.styled'
import {
  HealthRecordAttachmentButton,
  HealthRecordFileEndpointType,
} from './HealthRecordAttachmentButton'
import RowGroupValue from './RowGroupValue'

import {SideModal} from '~/components/general/modal/SideModal'
import {Typography} from '~/components/general/typography/Typography'
import {Chip} from '~/components/general/chip/Chip'
import {Container} from '~/components/general/container/Container'
import {PatientsContext} from '~/components/patients/PatientsContext'
import {formatName} from '~/utils'
import {theme} from '~/styles/theme'

type HealthRecordModalContentProps = {
  rows: Partial<RowGroup>[]
}

export type HealthRecordModalData = {
  date?: ExactOrEstimatedDate | null
  facility?: Facility | null
  hasAttachment?: boolean
  header?: string | null
  fileEndpointId?: string | null
  practitioner?: Practitioner
  rows: Partial<RowGroup>[]
  uploadId?: string | null
}

type HealthRecordModalProps = {
  fileEndpoint?: HealthRecordFileEndpointType
  data?: HealthRecordModalData
  isVisible: boolean
  onClose: () => void
}

const getHeaderColor = (rowType?: RowGroupType) =>
  rowType === RowGroupType.LINE || rowType === RowGroupType.MULTILINE
    ? theme.colors.primary.main
    : theme.colors.grey.main

const HealthRecordContent: React.FC<HealthRecordModalContentProps> = ({
  rows,
}) => (
  <div>
    {rows.map((row, index) => (
      <React.Fragment key={index}>
        <RowHeader
          weight={row.type === RowGroupType.LINE ? 'medium' : 'normal'}
          color={getHeaderColor(row.type)}>
          {capitalizeFirstLetter(row.field ?? row.line)}
        </RowHeader>
        <RowGroupValue row={row} />
      </React.Fragment>
    ))}
  </div>
)

export const HealthRecordModal: React.FC<HealthRecordModalProps> = ({
  data,
  fileEndpoint,
  isVisible,
  onClose,
}) => {
  const {t} = useTranslation()
  const {patient} = useContext(PatientsContext)
  const {translateEnumValue} = useEnumTranslations()

  if (!data) {
    return null
  }

  const {date, facility, hasAttachment, practitioner, rows, uploadId} = data

  const headerText = capitalizeFirstLetter(data.header) ?? t('Health record')

  const fileEndpointId = uploadId ?? data.fileEndpointId
  const showAttachmentButton =
    !!hasAttachment && !!fileEndpoint && !!fileEndpointId

  const header = (
    <div>
      <StyledHeaderText size="lg" weight="medium">
        {headerText}
      </StyledHeaderText>
      <Container spacing={1}>
        {!!date && <Chip text={formatExactOrEstimatedDate(date)} />}
        {uploadId ? (
          <Chip text={t('Parsed')} color="green" />
        ) : (
          <Chip text={t('Manually added')} color="yellow" />
        )}
      </Container>
    </div>
  )

  return (
    <SideModal isVisible={isVisible} onClose={onClose} header={header}>
      <Container flex>
        <ContentColumn>
          <Typography color={theme.colors.grey.main}>
            {t("Patient's name")}
          </Typography>
          <StyledChip text={formatName(patient)} />
        </ContentColumn>
        {!!practitioner && (
          <ContentColumn>
            <Typography color={theme.colors.grey.main}>
              {t("Practitioner's name")}
            </Typography>
            <StyledChip text={formatName(practitioner)} />
          </ContentColumn>
        )}
      </Container>
      {!!facility && (
        <Container flex>
          <ContentColumn>
            <Typography color={theme.colors.grey.main}>
              {t('Facility')}
            </Typography>
            <StyledChip text={facility?.name} />
          </ContentColumn>
          <ContentColumn>
            <Typography color={theme.colors.grey.main}>
              {t('Specialty')}
            </Typography>
            <StyledChip
              text={translateEnumValue(
                EnumType.Specialization,
                facility?.specializations[0],
              )}
            />
          </ContentColumn>
        </Container>
      )}
      <Container spacing={4} direction="column">
        <HealthRecordContent rows={rows} />
        {showAttachmentButton && (
          <HealthRecordAttachmentButton
            id={fileEndpointId}
            endpoint={uploadId ? 'sourceFile' : fileEndpoint}
          />
        )}
      </Container>
    </SideModal>
  )
}
