import {useTranslation} from 'react-i18next'
import React, {useContext, useMemo} from 'react'
import {
  getFamilyHistoryCardData,
  MISSING_ID,
  useFormatFamilyHistoryResponses,
} from '@common/utils'
import {FamilyMember, MedicalPermissionSubcategory} from '@common/models'

import {PermissionCheck} from '~/components/patients/common/requestData/PermissionCheck'
import {PatientsContext} from '~/components/patients/PatientsContext'
import {useGetFamilyHistoryTypeList, useGetPatientFamilyHistory} from '~/api'
import {SimpleTable} from '~/components/general/table/SimpleTable'

const getSortValue = (responses?: FamilyMember[] | null) => {
  if (responses === undefined) {
    return 0
  }
  if (responses === null) {
    return 2
  }
  return responses.length ? 3 : 1
}

const FamilyHistoryPageContent: React.FC = () => {
  const {t} = useTranslation()
  const formatFamilyHistoryResponses = useFormatFamilyHistoryResponses()
  const {patient} = useContext(PatientsContext)
  const {data: familyHistory, isInitialLoading: isLoadingFamilyHistory} =
    useGetPatientFamilyHistory({
      patientId: patient?.patientId ?? MISSING_ID,
    })
  const {data: diseases, isInitialLoading: isLoadingDiseases} =
    useGetFamilyHistoryTypeList()

  const familyHistoryRows = useMemo(
    () =>
      getFamilyHistoryCardData(familyHistory, diseases?.records)
        .sort(
          (row1, row2) =>
            getSortValue(row2.responses) - getSortValue(row1.responses),
        )
        .map(({title, responses}) => ({
          columns: [title, formatFamilyHistoryResponses(responses)],
        })),
    [diseases, familyHistory, formatFamilyHistoryResponses],
  )

  const tableHeader = useMemo(
    () => ({
      columns: [
        {
          title: t('Medical condition'),
          columnWidthPercent: 50,
        },
        {
          title: t('Response'),
          columnWidthPercent: 50,
        },
      ],
    }),
    [t],
  )

  return (
    <SimpleTable
      isLoading={isLoadingFamilyHistory || isLoadingDiseases}
      rows={familyHistoryRows}
      header={tableHeader}
      showSourceIcons
    />
  )
}

export const FamilyHistoryPage: React.FC = () => (
  <PermissionCheck permission={MedicalPermissionSubcategory.FAMILY_HISTORY}>
    <FamilyHistoryPageContent />
  </PermissionCheck>
)
