import styled, {css} from 'styled-components'

import {spacing} from '~/styles/theme'

type ContainerProps = {
  flex?: boolean
  inline?: boolean
  direction?: 'row' | 'column'
  flexNumber?: number
  fullWidth?: boolean
  align?: 'flex-start' | 'center' | 'flex-end' | 'stretch'
  justify?: 'flex-start' | 'center' | 'flex-end' | 'space-between'
  wrap?: boolean
  spacing?: number
}

export const StyledContainer = styled.div<ContainerProps>`
  ${({flex}) => flex && `display: flex`};
  ${({fullWidth}) => fullWidth && `width: 100%`};
  ${({inline}) => inline && `display: inline-flex`};
  ${({direction}) => direction && `flex-direction: ${direction}`};
  ${({align}) => align && `align-items: ${align}`};
  ${({flexNumber}) => flexNumber && `flex: ${flexNumber}`};
  ${({justify}) => justify && `justify-content: ${justify}`};
  ${({wrap}) => wrap && `flex-wrap: wrap`};
  ${({spacing: spacingValue, direction}) =>
    spacingValue &&
    direction === 'row' &&
    css`
      & > * {
        margin-right: ${spacing(spacingValue)}px;
      }
      & > *:last-child {
        margin-right: 0;
      }
    `};
  ${({spacing: spacingValue, direction}) =>
    spacingValue &&
    direction === 'column' &&
    css`
      & > * {
        margin-bottom: ${spacing(spacingValue)}px;
      }
      & > *:last-child {
        margin-bottom: 0;
      }
    `}
`
