import {useEffect, useState} from 'react'

import {SelectOptions} from './translations'

const createBooleanArrayFromValues = <T>(
  options: SelectOptions<T>,
  values: T[],
) => options.map((option) => values.includes(option.value))

const getValuesFromBooleanArray = <T>(
  options: SelectOptions<T>,
  booleanValues: boolean[],
) =>
  booleanValues.reduce((values, checkboxValue, index) => {
    if (checkboxValue && options[index]?.value) {
      values.push(options[index].value)
    }
    return values
  }, [] as T[])

export const useMultiSelectValues = <T>(
  options: SelectOptions<T>,
  onChange: (values: T[]) => void,
  values: T[] | null | undefined,
  initialValues?: T[],
) => {
  const [checkboxValues, setCheckboxValues] = useState(
    createBooleanArrayFromValues(options, initialValues ?? values ?? []),
  )

  useEffect(() => {
    if (values) {
      setCheckboxValues(createBooleanArrayFromValues(options, values))
    }
  }, [options, values])

  const toggleValue = (checkedIndex: number) => {
    const newCheckboxValues = checkboxValues.map(
      (item, itemIndex) => (checkedIndex === itemIndex) !== item,
    )
    setCheckboxValues(newCheckboxValues)

    const newValues = getValuesFromBooleanArray(options, newCheckboxValues)
    onChange(newValues)
  }

  return {checkboxValues, toggleValue}
}
