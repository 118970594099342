import React, {useContext, useEffect} from 'react'

import {AuthContext} from '~/auth/AuthContext'

export const LoginRedirect: React.FC = () => {
  const {signIn} = useContext(AuthContext)

  useEffect(() => {
    signIn()
  }, [signIn])

  return null
}
