import {UnitSystem} from '../models'
import {formatLocaleNumber} from './helpers'

export const STONE_IN_KG = 6.35029318
export const POUND_IN_KG = 0.45359237
export const OUNCE_IN_KG = 0.028349523125

export const FOOT_IN_CM = 30.48
export const INCH_IN_CM = 2.54

export const INCH_IN_FOOT = 12
export const POUND_IN_STONE = 14
export const OUNCE_IN_POUND = 16

export const MGDL_IN_MMOLL = 0.0555

export const convertWeightToImperialUnits = (weightInKg: number) => {
  const stones = Math.floor(weightInKg / STONE_IN_KG)
  const remainingWeight = weightInKg - stones * STONE_IN_KG
  const pounds = remainingWeight / POUND_IN_KG

  return {stones, pounds}
}

export const formatLocaleWeight = (
  weightInKg?: number | null,
  unitSystem: UnitSystem = UnitSystem.METRIC,
) => {
  if (!weightInKg) {
    return ''
  }

  if (unitSystem === UnitSystem.METRIC) {
    return `${formatLocaleNumber(weightInKg, 1)} kg`
  }

  const {stones, pounds} = convertWeightToImperialUnits(weightInKg)
  return `${formatLocaleNumber(stones, 0)} st, ${formatLocaleNumber(
    pounds,
    1,
  )} lb`
}

export const formatLocaleBirthWeight = (
  weightInKg?: number | null,
  unitSystem: UnitSystem = UnitSystem.METRIC,
) => {
  if (!weightInKg) {
    return ''
  }

  if (unitSystem === UnitSystem.METRIC) {
    return `${formatLocaleNumber(weightInKg * 1000, 0)} g`
  }

  const pounds = Math.floor(weightInKg / POUND_IN_KG)
  const remainingWeight = weightInKg - pounds * POUND_IN_KG
  const ounces = remainingWeight / OUNCE_IN_KG

  return `${formatLocaleNumber(pounds, 0)} lb, ${formatLocaleNumber(
    ounces,
    0,
  )} oz`
}

export const convertLengthToImperialUnits = (heightInCm: number) => {
  const feet = Math.floor(heightInCm / FOOT_IN_CM)
  const remainingHeight = heightInCm - feet * FOOT_IN_CM
  const inches = remainingHeight / INCH_IN_CM

  return {feet, inches}
}

export const formatLocaleLength = (
  lengthInCm?: number | null,
  unitSystem: UnitSystem = UnitSystem.METRIC,
  inchesOnly = false,
) => {
  if (!lengthInCm) {
    return ''
  }

  if (unitSystem === UnitSystem.METRIC) {
    return `${formatLocaleNumber(lengthInCm, 0)} cm`
  }

  if (inchesOnly) {
    return `${formatLocaleNumber(lengthInCm / INCH_IN_CM, 0)}''`
  }

  const {feet, inches} = convertLengthToImperialUnits(lengthInCm)
  return `${formatLocaleNumber(feet, 0)}' ${formatLocaleNumber(inches, 0)}''`
}

export const formatLocaleConcentration = (
  concentrationInMMolL?: number | null,
  unitSystem: UnitSystem = UnitSystem.METRIC,
) => {
  if (concentrationInMMolL == null) {
    return ''
  }

  return unitSystem === UnitSystem.METRIC
    ? `${formatLocaleNumber(concentrationInMMolL, 1)} mmol/l`
    : `${formatLocaleNumber(concentrationInMMolL / MGDL_IN_MMOLL, 0)} mg/dl`
}
