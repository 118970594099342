import React, {useContext} from 'react'
import {useTranslation} from 'react-i18next'

import {Container} from '../general/container/Container'
import {Typography} from '../general/typography/Typography'
import {Button} from '../general/button/Button'
import {SideModal} from '../general/modal/SideModal'
import {useCancelAppointmentForFacility} from '../../api/practitioner'
import {
  NotificationContext,
  NotificationType,
} from '../notifications/NotificationContext'

interface CancelAppointmentModalProps {
  isVisible: boolean
  onClose: () => void
  appointmentId: string
  facilityId: string
  originalDate: string
  patientName: string
  patientDob: string | null
}

export const CancelAppointmentModal: React.FC<CancelAppointmentModalProps> = ({
  isVisible,
  onClose,
  appointmentId,
  facilityId,
  originalDate,
  patientName,
  patientDob,
}) => {
  const {t} = useTranslation()
  const {addNotification} = useContext(NotificationContext)

  const {mutateAsync: cancelAppointment, isLoading} =
    useCancelAppointmentForFacility({
      facilityId,
      appointmentId,
    })

  const handleConfirm = async () => {
    try {
      if (
        window.confirm(t('Are you sure you want to cancel this appointment?'))
      ) {
        await cancelAppointment()

        addNotification(
          t('Appointment successfully cancelled'),
          NotificationType.Success,
        )

        onClose()
      }
    } catch (error) {
      addNotification(
        `${t('Error cancelling appointment')}\n${
          error instanceof Error ? error.message : t('Unknown error occurred')
        }`,
        NotificationType.Error,
      )
      console.error('Error cancelling appointment:', error)
    }
  }

  return (
    <SideModal
      isVisible={isVisible}
      onClose={onClose}
      header={
        <div>
          <Typography size="lg" weight="medium">
            {t('Cancel Appointment')}
          </Typography>
        </div>
      }>
      <Container flex direction="column" spacing={2}>
        <Typography weight="medium" size="lg">
          {t('Patient Information')}
        </Typography>
        <Container flex direction="column" spacing={1}>
          <Typography weight="medium" size="md">
            {t('Name')}
          </Typography>
          <Typography color="secondary" size="sm">
            {patientName}
          </Typography>

          {patientDob && (
            <>
              <Typography weight="medium" size="md">
                {t('Date of Birth')}
              </Typography>
              <Typography color="secondary" size="sm">
                {new Date(patientDob).toLocaleDateString()}
              </Typography>
            </>
          )}
        </Container>

        <div style={{height: '16px'}} />

        <Container flex direction="column" spacing={1}>
          <Typography weight="medium" size="lg">
            {t('Appointment Details')}
          </Typography>
          <Container flex direction="column" spacing={1}>
            <Typography weight="medium" size="md">
              {t('Appointment date')}
            </Typography>
            <Typography color="secondary" size="sm">
              {new Date(originalDate).toLocaleString()}
            </Typography>
          </Container>

          <Container flex justify="flex-end" spacing={2}>
            <Button onClick={handleConfirm} disabled={isLoading}>
              {t('Cancel Appointment')}
            </Button>
          </Container>
        </Container>
      </Container>
    </SideModal>
  )
}
