import * as React from 'react'

import {IconProps} from '.'

function Contact({color, size, isFilled}: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 21 21"
      fill={isFilled ? color : 'none'}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.48306 11.5215C10.3022 13.3415 12.6072 14.5015 13.9781 13.1365L14.3106 12.804C14.7531 12.3615 14.6914 11.629 14.1781 11.2715C13.8539 11.0456 13.5056 10.8031 13.1206 10.5323C12.7222 10.2523 12.1756 10.2948 11.8297 10.6381L11.4539 11.0115C10.9881 10.7165 10.5272 10.3373 10.0989 9.90979L10.0972 9.90812C9.66973 9.48062 9.29056 9.01896 8.99556 8.55312L9.3689 8.17729C9.71223 7.83146 9.75556 7.28396 9.4739 6.88563C9.20306 6.50146 8.96056 6.15396 8.7364 5.83063C8.37973 5.31563 7.6464 5.25313 7.20306 5.69646L6.87056 6.02896C5.5064 7.39979 6.66556 9.70312 8.48473 11.524"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1071 17.0664L10.4404 18.7331L8.77376 17.0664H5.44043C4.0596 17.0664 2.94043 15.9472 2.94043 14.5664V4.56641C2.94043 3.18557 4.0596 2.06641 5.44043 2.06641H15.4404C16.8213 2.06641 17.9404 3.18557 17.9404 4.56641V14.5664C17.9404 15.9472 16.8213 17.0664 15.4404 17.0664H12.1071Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default Contact
