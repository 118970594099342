/* tslint:disable */
/* eslint-disable */
/**
 * Cushla API
 * REST API for Cushla
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */

export enum Language {
  ENGLISH = 'ENGLISH',
  GERMAN = 'GERMAN',
  SPANISH = 'SPANISH',
  FRENCH = 'FRENCH',
  CHINESE = 'CHINESE',
  JAPANESE = 'JAPANESE',
  KOREAN = 'KOREAN',
  ARABIC = 'ARABIC',
  PORTUGUESE = 'PORTUGUESE',
  RUSSIAN = 'RUSSIAN',
  ITALIAN = 'ITALIAN',
  DUTCH = 'DUTCH',
  POLISH = 'POLISH',
  TURKISH = 'TURKISH',
  SWEDISH = 'SWEDISH',
  DANISH = 'DANISH',
  NORWEGIAN = 'NORWEGIAN',
  FINNISH = 'FINNISH',
  HUNGARIAN = 'HUNGARIAN',
  CZECH = 'CZECH',
  GREEK = 'GREEK',
  ROMANIAN = 'ROMANIAN',
  UKRAINIAN = 'UKRAINIAN',
  BULGARIAN = 'BULGARIAN',
  CROATIAN = 'CROATIAN',
  SERBIAN = 'SERBIAN',
  SLOVAK = 'SLOVAK',
  SLOVENIAN = 'SLOVENIAN',
  LITHUANIAN = 'LITHUANIAN',
  LATVIAN = 'LATVIAN',
  ESTONIAN = 'ESTONIAN',
  MALTESE = 'MALTESE',
  ICELANDIC = 'ICELANDIC',
  FAROESE = 'FAROESE',
  ALBANIAN = 'ALBANIAN',
  MACEDONIAN = 'MACEDONIAN',
}
