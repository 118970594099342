import {AssertTypeFn} from './general'
import {
  CholesterolTestTypeValue,
  FamilyHistoryTypeValue,
  GeneralItemType,
  GeneralItemTypeValue,
  MedicalTestTypeValue,
  SettingsType,
} from '../models'
import {
  assertEnumOrNull,
  assertString,
  assertStringOrNull,
  castBooleanOrNull,
  castNumber,
} from '../utils'

export const getItemTypeNormaliser: <T>(
  normaliser: AssertTypeFn<T>,
) => AssertTypeFn<GeneralItemType<T>> = (normaliser) => (obj) => ({
  alias: assertString(obj.alias),
  type: assertEnumOrNull(obj.type, SettingsType),
  value: normaliser(obj.value),
})

export const generalItemTypeValueNormaliser: AssertTypeFn<
  GeneralItemTypeValue
> = (obj) => ({
  label: assertString(obj.label),
})

export const generalItemTypeNormaliser: AssertTypeFn<GeneralItemType> =
  getItemTypeNormaliser(generalItemTypeValueNormaliser)

export const medicalTestTypeValueNormaliser: AssertTypeFn<
  MedicalTestTypeValue
> = (obj) => ({
  label: assertString(obj.label),
  isCholesterol: castBooleanOrNull(obj.isCholesterol),
})

export const familyHistoryTypeValueNormaliser: AssertTypeFn<
  FamilyHistoryTypeValue
> = (obj) => ({
  title: assertString(obj.title),
  highlight: assertString(obj.highlight),
})

export const cholesterolTestTypeValueNormaliser: AssertTypeFn<
  CholesterolTestTypeValue
> = (obj) => ({
  title: assertString(obj.title),
  description: assertString(obj.description),
  price: castNumber(obj.price),
  url: assertStringOrNull(obj.url),
})
