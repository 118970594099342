import {
  FacilityIdParam,
  IdUrlParam,
  PatientAppointmentFileUrlParams,
  PatientAppointmentUrlParams,
  PatientAppointmentsUrlParams,
  endpointKeys,
} from '@common/api'
import {
  Appointment,
  AppointmentSummary,
  PagedResponse,
  PractitionerCreateAppointmentRequest,
  PractitionerCreateAppointmentResponse,
  ScreeningConsultation,
  StaticFile,
  UpdateConsultation,
} from '@common/models'
import {
  appointmentNormaliser,
  appointmentSummaryOrNullNormaliser,
  getPagedResponseNormaliser,
  screeningConsultationNormaliser,
  staticFileNormaliser,
  staticFileOrNullNormaliser,
} from '@common/normalisers'
import {UseQueryOptions} from '@tanstack/react-query'

import {useApi, useWebGet, useWebSet} from './helpers'

export const useGetPatientAppointments = (
  params: PatientAppointmentsUrlParams,
) =>
  useWebGet<PagedResponse<Appointment>>(
    [endpointKeys.patientAppointments, params],
    useApi().patientAppointments(params),
    getPagedResponseNormaliser(appointmentNormaliser),
  )

export const useGetPatientAppointment = (params: PatientAppointmentUrlParams) =>
  useWebGet<Appointment>(
    [endpointKeys.patientAppointment, params],
    useApi().patientAppointment(params),
    appointmentNormaliser,
  )

export const useGetPatientAppointmentFile = (
  params: PatientAppointmentFileUrlParams,
  options: UseQueryOptions<StaticFile, Error>,
) =>
  useWebGet<StaticFile>(
    [endpointKeys.patientAppointmentFile, params],
    useApi().patientAppointmentFile(params),
    staticFileNormaliser,
    options,
  )

export const useGetPatientAppointmentSummary = (
  params: PatientAppointmentUrlParams,
) =>
  useWebGet<AppointmentSummary | null>(
    [endpointKeys.patientAppointmentSummary, params],
    useApi().patientAppointmentSummary(params),
    appointmentSummaryOrNullNormaliser,
  )

export const useGetPatientAppointmentSummaryFile = (
  params: PatientAppointmentUrlParams,
  options?: UseQueryOptions<StaticFile | null, Error>,
) =>
  useWebGet<StaticFile | null>(
    [endpointKeys.patientAppointmentSummaryFile, params],
    useApi().patientAppointmentSummaryFile(params),
    staticFileOrNullNormaliser,
    options,
  )

export const useGetScreeningConsultation = (params: IdUrlParam) =>
  useWebGet<ScreeningConsultation>(
    [endpointKeys.screeningConsultation, params],
    useApi().screeningConsultation(params),
    screeningConsultationNormaliser,
  )

export const useUpdateScreeningConsultation = (params: IdUrlParam) =>
  useWebSet<UpdateConsultation>(
    [endpointKeys.screeningConsultation, params],
    useApi().screeningConsultation(params),
    'PATCH',
    undefined,
    [[endpointKeys.screeningConsultation, params]],
  )

export const useCreatePatientAppointment = (params: FacilityIdParam) =>
  useWebSet<
    PractitionerCreateAppointmentRequest,
    PractitionerCreateAppointmentResponse
  >(
    [endpointKeys.appointments],
    useApi().createPatientAppointment(params),
    'POST',
    (data) => data,
    [[endpointKeys.appointments]],
  )
