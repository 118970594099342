import {NavLink} from 'react-router-dom'
import styled from 'styled-components'

export const ViewAllLinkWrapper = styled.div`
  display: flex;
  justify-content: center;
`

export const ViewAllLink = styled(NavLink)`
  padding: ${({theme}) => theme.spacing.md};
`
