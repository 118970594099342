import {
  endpointKeys,
  PatientHealthJourneyUrlParams,
  PatientIdUrlParam,
} from '@common/api'
import {
  patientHealthJourneyNormaliser,
  patientHealthJourneysNormaliser,
} from '@common/normalisers'
import {PatientHealthJourney} from '@common/models'
import {UseQueryOptions} from '@tanstack/react-query'

import {useWebGet, useApi} from './helpers'

export const useGetPatientHealthJourneys = (
  params: PatientIdUrlParam,
  options?: UseQueryOptions<PatientHealthJourney[], Error>,
) =>
  useWebGet<PatientHealthJourney[]>(
    [endpointKeys.patientHealthJourneys, params],
    useApi().patientHealthJourneys(params),
    patientHealthJourneysNormaliser,
    options,
  )

export const useGetPatientHealthJourney = (
  params: PatientHealthJourneyUrlParams,
  options?: UseQueryOptions<PatientHealthJourney, Error>,
) =>
  useWebGet<PatientHealthJourney>(
    [endpointKeys.patientHealthJourney, params],
    useApi().patientHealthJourney(params),
    patientHealthJourneyNormaliser,
    options,
  )
