import styled from 'styled-components'

type CardProps = {
  spaceAfter?: boolean
}

export const StyledCard = styled.div<CardProps>`
  background-color: ${({theme}) => theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${({theme}) => theme.colors.grey.grey50};
  border-radius: ${({theme}) => theme.borderRadius.md};
  padding: ${({theme}) => theme.spacing.lg};
  ${({spaceAfter, theme}) =>
    spaceAfter && `margin-bottom: ${theme.spacing.md}`};
  cursor: pointer;

  & > * {
    margin-right: ${({theme}) => theme.spacing.md};
  }

  & > *:last-child {
    margin-right: 0;
  }
`

export const CardIcon = styled.div`
  width: ${({theme}) => theme.spacing.xl};
  height: ${({theme}) => theme.spacing.xl};
  background-color: ${({theme}) => theme.colors.primary.blue50};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${({theme}) => theme.borderRadius.xxs};
`
