/* tslint:disable */
/* eslint-disable */
/**
 * Cushla API
 * REST API for Cushla
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */

export enum TerminationReason {
  TERMINATION = 'TERMINATION',
  ECTOPIC_PREGNANCY = 'ECTOPIC_PREGNANCY',
  MISCARRIAGE = 'MISCARRIAGE',
  INTRA_UTERINE_DEATH = 'INTRA_UTERINE_DEATH',
  STILL_BIRTH = 'STILL_BIRTH',
  NEONATAL_DEATH = 'NEONATAL_DEATH',
}
