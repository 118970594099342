import React from 'react'

import {
  QuestionnairePreviewAnswerWrap,
  QuestionnairePreviewCard,
  StyledTextArea,
  TextAreaWrapper,
} from './QuestionnairePreview.styled'
import {QuestionProps} from './QuestionCard'

import {Typography} from '~/components/general/typography/Typography'

export const TextQuestion: React.FC<QuestionProps> = ({question, answer}) => (
  <QuestionnairePreviewCard>
    <Typography weight="medium">{question.title}</Typography>
    <Typography>{question.subtitle}</Typography>
    <QuestionnairePreviewAnswerWrap>
      <TextAreaWrapper>
        <StyledTextArea value={answer?.toString()} readOnly />
      </TextAreaWrapper>
    </QuestionnairePreviewAnswerWrap>
  </QuestionnairePreviewCard>
)
