import React, {useCallback, useContext, useEffect, useState} from 'react'
import {RowGroup, RowGroupType} from '@common/models'
import {useParams} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import {MISSING_ID} from '@common/utils'

import {
  StyledDashboardInnerCard,
  StyledDashboardInnerCardTable,
} from '../../GeneralDataCard.styled'
import {TextAreaDataInput} from './components/TextAreaDataInput'
import {TextDataInput} from './components/TextDataInput'
import {SelectDataInput} from './components/SelectDataInput'
import {ComponentType, ComponentValue, config} from './fieldConfig'
import {PlaceHolder} from './components/PlaceHolder'
import {CalculatedField} from './components/CalculatedField'

import {PatientsContext} from '~/components/patients/PatientsContext'
import {Layout} from '~/components/layout/Layout'
import {
  NotificationContext,
  NotificationType,
} from '~/components/notifications/NotificationContext'
import {
  useGetPatientContactDetails,
  useGetPatientCriticalNote,
  useGetScreeningConsultation,
  useUpdateScreeningConsultation,
} from '~/api'
import {Typography} from '~/components/general/typography/Typography'
import {Container} from '~/components/general/container/Container'
import {theme} from '~/styles/theme'
import {Card} from '~/components/general/card/Card'
import {Button} from '~/components/general/button/Button'
import {Loading} from '~/components/general/loading/Loading'
import {useSaveShortcut} from '~/utils'
import {PatientHeader} from '~/components/patients/dashboard/PatientHeader'

// Component mapping
const componentMap: Record<ComponentType, React.FC<any>> = {
  TextDataInput,
  TextAreaDataInput,
  SelectDataInput,
  PlaceHolder,
  CalculatedField,
}

/**
 * Temporary workaround for the Screening Page.
 * This page is used as a temporary solution until a permanent fix is implemented.
 * It displays the screening data for a patient and allows the user to update and save the data.
 */
const TempScreeningPage: React.FC = () => {
  const {patient} = useContext(PatientsContext)
  const {appointmentId} = useParams<{appointmentId: string}>()
  const {mutate} = useUpdateScreeningConsultation({id: appointmentId ?? ''})
  const {addNotification} = useContext(NotificationContext)
  const {t} = useTranslation()
  const [data, setData] = useState<ComponentValue[]>([])
  const [isLoaded, setIsLoaded] = useState(false)
  const {isLoading, data: serverData} = useGetScreeningConsultation({
    id: appointmentId!,
  })
  const {data: criticalNote} = useGetPatientCriticalNote({
    patientId: patient?.patientId ?? MISSING_ID,
  })
  const {data: contactDetails} = useGetPatientContactDetails({
    patientId: patient?.patientId || MISSING_ID,
  })

  const saveRowGroups = useCallback(async () => {
    mutate(
      {
        rowGroups: data.map((item) => ({
          type: RowGroupType.FIELD,
          field: item.name,
          value: item.value,
        })),
      },
      {
        onSuccess: () =>
          addNotification('Data saved successfully', NotificationType.Success),
        onError: () =>
          addNotification('Failed to save data', NotificationType.Error),
      },
    )
  }, [data, mutate, addNotification])

  useSaveShortcut(saveRowGroups)

  const updateConsultationData = async (newData: ComponentValue[]) => {
    const updatedData = newData.reduce(
      (acc: ComponentValue[], newItem: ComponentValue) => {
        const index = acc.findIndex((item) => item.name === newItem.name)
        if (index !== -1) {
          if (newItem.value) {
            acc[index] = newItem
          } else {
            acc.splice(index, 1)
          }
        } else if (newItem.value) {
          acc.push(newItem)
        }
        return acc
      },
      [...data],
    )
    setData(updatedData)
  }

  useEffect(() => {
    if (!isLoading && serverData !== undefined) {
      setData(
        serverData.rowGroups.map((item: RowGroup) => ({
          name: item.field,
          value: item.value,
        })) as ComponentValue[],
      )
      setIsLoaded(true)
    }
  }, [serverData, isLoading]) // Change this later to update after fetching from the server.

  if (!patient) {
    return (
      <Layout>
        <Typography color="red">Unknown Patient</Typography>
      </Layout>
    )
  }

  return (
    <Layout>
      {!isLoaded ? (
        <Loading />
      ) : (
        <>
          <PatientHeader
            patient={patient}
            criticalNote={criticalNote}
            contactDetails={contactDetails}
          />
          <br />
          {Object.keys(config).map((cardTitle, index) => (
            <Card key={cardTitle}>
              <Container
                flex
                justify="space-between"
                spacing={2}
                align="center">
                <Typography
                  weight="medium"
                  size="xl"
                  color={theme.colors.secondary.main}>
                  {t(cardTitle)}
                </Typography>

                {index === 0 && (
                  <Typography
                    weight="medium"
                    size="sm"
                    color={theme.colors.other.graphic}>
                    {t('Press Ctrl/⌘ + S to save')}
                  </Typography>
                )}
              </Container>
              <Container direction="row">
                <StyledDashboardInnerCard>
                  <StyledDashboardInnerCardTable>
                    <tbody>
                      {config[cardTitle].reduce((acc, _, i, array) => {
                        if (i % 2 === 0) {
                          const Component1 = componentMap[array[i].component]
                          const Component2 = array[i + 1]
                            ? componentMap[array[i + 1].component]
                            : null

                          const initialValue1 = data.find(
                            (item) => item.name === array[i].name,
                          )

                          const initialValue2 = array[i + 1]
                            ? data.find(
                                (item) => item.name === array[i + 1].name,
                              )
                            : undefined

                          acc.push(
                            <tr key={i}>
                              <Component1
                                title={t(array[i].name)}
                                name={array[i].name}
                                {...array[i].otherProps}
                                onUpdate={updateConsultationData}
                                initialValue={initialValue1?.value}
                                currentData={data}
                              />
                              {Component2 && (
                                <Component2
                                  title={t(array[i + 1].name)}
                                  name={array[i + 1].name}
                                  {...array[i + 1].otherProps}
                                  onUpdate={updateConsultationData}
                                  initialValue={initialValue2?.value}
                                  currentData={data}
                                />
                              )}
                            </tr>,
                          )
                        }
                        return acc
                      }, [] as JSX.Element[])}
                    </tbody>
                  </StyledDashboardInnerCardTable>
                </StyledDashboardInnerCard>
              </Container>
            </Card>
          ))}
          <Card>
            <Container flex justify="flex-end">
              <Button onClick={saveRowGroups}>
                {t('Save Screening Data')}
              </Button>
            </Container>
          </Card>
        </>
      )}
    </Layout>
  )
}

export default TempScreeningPage
