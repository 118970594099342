import React, {useContext, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useParams} from 'react-router-dom'
import {MISSING_ID} from '@common/utils'
import * as DomPurify from 'dompurify'

import {MaterialsContext} from '../MaterialsContext'
import {SelectedMaterials} from '../sideBar/SelectedMaterials'

import {Button} from '~/components/general/button/Button'
import {Layout} from '~/components/layout/Layout'
import {useGetMaterial} from '~/api'
import {Icon} from '~/components/general/icon/Icon'
import {
  NotificationContext,
  NotificationType,
} from '~/components/notifications/NotificationContext'

export const domPurifyConfig = {
  ADD_TAGS: ['iframe'],
  ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling'],
}

const TopBarButton: React.FC<{materialId?: string}> = ({materialId}) => {
  const {t} = useTranslation()
  const {addItem, selectedMaterials, itemIdMap} = useContext(MaterialsContext)

  const isSelected = selectedMaterials.some(
    (material) => material.id === materialId,
  )

  const handleButtonClick = () => {
    if (!materialId) {
      return
    }
    if (!isSelected) {
      addItem(itemIdMap[materialId])
    }
  }

  if (!materialId) {
    return null
  }

  return (
    <Button
      onClick={handleButtonClick}
      color="primary"
      endIcon={isSelected ? <Icon name="Check" size="sm" /> : undefined}>
      {isSelected ? t('Selected') : t('Select topic')}
    </Button>
  )
}

export const MaterialViewerPage: React.FC = () => {
  const {materialId} = useParams()
  const {t} = useTranslation()
  const {data} = useGetMaterial({id: materialId || MISSING_ID})
  const [htmlContent, setHtmlContent] = useState('')
  const {addNotification} = useContext(NotificationContext)

  useEffect(() => {
    const loadHtml = async () => {
      if (!data) {
        return
      }

      try {
        const html = await fetch(data.url)
        setHtmlContent(await html.text())
      } catch (error) {
        addNotification(
          t('Failed to fetch the material.'),
          NotificationType.Error,
        )
      }
    }

    loadHtml()
  }, [data, addNotification, t])

  return (
    <Layout
      rightSide={<SelectedMaterials />}
      navMenu={<TopBarButton materialId={materialId} />}>
      <div
        className="educational-content"
        // [CUSH-656] TODO: Investigate how to not use dangerouslySetInnerHTML
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: DomPurify.sanitize(htmlContent, domPurifyConfig),
        }}
      />
    </Layout>
  )
}
