import * as React from 'react'

import {IconProps} from '.'

function UserGroup({color, size, isFilled}: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill={isFilled ? color : 'none'}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.66675 15.8333C1.66675 14 3.16675 12.5 5.00008 12.5H8.33341C10.1667 12.5 11.6667 14 11.6667 15.8333"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.74998 5.00029C9.91665 6.16696 9.91665 8.00029 8.74998 9.08362C7.58331 10.167 5.74998 10.2503 4.66665 9.08362C3.58331 7.91696 3.49998 6.16696 4.58331 5.00029C5.66665 3.83362 7.58331 3.91696 8.74998 5.00029"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.3335 11.666H15.8335C17.2502 11.666 18.3335 12.7493 18.3335 14.166"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.0833 5.58398C16.9167 6.41732 16.9167 7.75065 16.0833 8.50065C15.25 9.25065 13.9167 9.33399 13.1667 8.50065C12.4167 7.66732 12.3333 6.33398 13.1667 5.58398C13.9167 4.83398 15.25 4.83398 16.0833 5.58398"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default UserGroup
