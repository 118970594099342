import styled from 'styled-components'

import {Card} from '~/components/general/card/Card'
import {Container} from '~/components/general/container/Container'
import {Typography} from '~/components/general/typography/Typography'

type CardContainer = {
  disableLinks?: boolean
}

export const StyledDashboardCard = styled(Card)`
  flex: 1;
  padding-top: ${({theme}) => theme.spacing.md};
`

export const StyledDashboardInnerCard = styled.div`
  background-color: ${({theme}) => theme.colors.grey.grey20};
  border-radius: ${({theme}) => theme.borderRadius.sm};
  padding: ${({theme}) => theme.spacing.lg};
`

export const StyledDashboardInnerCardTable = styled.table`
  width: 100%;
`

export const StyledDashboardInnerCardTableCell = styled.td`
  vertical-align: top;

  &:first-child {
    white-space: nowrap;
  }

  &:not(:last-child) {
    padding-right: ${({theme}) => theme.spacing.md};
  }

  *:not(:last-child) > & {
    padding-bottom: ${({theme}) => theme.spacing.md};
  }
`

export const StyledTableText = styled(Typography)`
  &:not(:last-child) {
    margin-bottom: ${({theme}) => theme.spacing.xs};
  }
`

export const StyledCardContainer = styled(Container)<CardContainer>`
  ${({disableLinks}) => disableLinks && 'pointer-events: none;'}
`
