import React, {useContext} from 'react'
import {Outlet, useNavigate} from 'react-router-dom'
import {useTranslation} from 'react-i18next'

import {Container} from '../general/container/Container'
import {Button} from '../general/button/Button'
import {MaterialsContext} from '../educationalMaterials/MaterialsContext'
import {QuestionnairesContext} from '../questionnaires/QuestionnairesContext'
import {Icon} from '../general/icon/Icon'
import {PatientsContext} from './PatientsContext'

import {PatientsSidebarNav} from '~/components/patients/PatientsSidebarNav'
import {Layout} from '~/components/layout/Layout'
import {theme} from '~/styles/theme'

type PatientDetailProps = {
  fullPath: string
}

export const PatientDetail: React.FC<PatientDetailProps> = ({fullPath}) => {
  const navigate = useNavigate()
  const {t} = useTranslation()
  const {patient} = useContext(PatientsContext)

  const {setSelectedPatient} = useContext(MaterialsContext)
  const {setSelectedPatients} = useContext(QuestionnairesContext)

  const handleShareMaterialsClick = () => {
    if (patient?.patientId) {
      setSelectedPatient(patient)
    }

    navigate('/app/materials')
  }

  const handleShareQuestionnaireClick = () => {
    if (patient?.patientId) {
      setSelectedPatients([patient])
    }

    navigate('/app/questionnaires/library')
  }

  return (
    <Layout
      sideNav={<PatientsSidebarNav onClick={navigate} fullPath={fullPath} />}
      navMenu={
        <Container flex align="center" spacing={1}>
          <Button
            size="sm"
            startIcon={
              <Icon name="Plus" color={theme.colors.white} size="sm" />
            }
            onClick={handleShareQuestionnaireClick}>
            {t('New questionnaire')}
          </Button>
          <Button
            size="sm"
            startIcon={
              <Icon name="Plus" color={theme.colors.white} size="sm" />
            }
            onClick={handleShareMaterialsClick}>
            {t('Share materials')}
          </Button>
        </Container>
      }>
      <Outlet />
    </Layout>
  )
}
