import React from 'react'

import {
  QuestionnairePreviewCard,
  QuestionnairePreviewAnswerWrap,
} from './QuestionnairePreview.styled'
import {QuestionProps} from './QuestionCard'

import {Typography} from '~/components/general/typography/Typography'
import {Radio} from '~/components/general/radio/Radio'

export const SelectQuestion: React.FC<QuestionProps> = ({question, answer}) => (
  <QuestionnairePreviewCard>
    <Typography weight="medium">{question.title}</Typography>
    <Typography>{question.subtitle}</Typography>
    <QuestionnairePreviewAnswerWrap>
      {question.choices?.map((choice, index) => (
        <Radio
          label={choice.label}
          checked={index === answer}
          key={choice.id}
        />
      ))}
    </QuestionnairePreviewAnswerWrap>
  </QuestionnairePreviewCard>
)
