import * as React from 'react'

import {IconProps} from '.'

function UserPercentage({color, size, isFilled}: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={isFilled ? color : 'none'}
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1354_10042)">
        <path
          d="M12 2C13.1 2 14 2.9 14 4C14 5.1 13.1 6 12 6C10.9 6 10 5.1 10 4C10 2.9 10.9 2 12 2ZM21 8C21 8.55228 20.5523 9 20 9H18C16.3431 9 15 10.3431 15 12V21C15 21.5523 14.5523 22 14 22C13.4477 22 13 21.5523 13 21V17C13 16.4477 12.5523 16 12 16C11.4477 16 11 16.4477 11 17V21C11 21.5523 10.5523 22 10 22C9.44772 22 9 21.5523 9 21V12C9 10.3431 7.65685 9 6 9H4C3.44772 9 3 8.55228 3 8C3 7.44772 3.44772 7 4 7H20C20.5523 7 21 7.44772 21 8Z"
          fill={color}
        />
        <path
          d="M11.315 12.155C11.1383 12.3317 10.9033 12.42 10.61 12.42C10.3167 12.42 10.0817 12.3317 9.905 12.155C9.72833 11.975 9.64 11.7383 9.64 11.445C9.64 11.1483 9.72833 10.9117 9.905 10.735C10.0817 10.555 10.3167 10.465 10.61 10.465C10.9033 10.465 11.1383 10.555 11.315 10.735C11.495 10.9117 11.585 11.1483 11.585 11.445C11.585 11.7383 11.495 11.975 11.315 12.155ZM10.595 14L12.8 10.525H13.405L11.2 14H10.595ZM10.61 11.945C10.73 11.945 10.8267 11.9 10.9 11.81C10.9767 11.7167 11.015 11.595 11.015 11.445C11.015 11.2917 10.9767 11.1683 10.9 11.075C10.8267 10.9817 10.73 10.935 10.61 10.935C10.4933 10.935 10.3967 10.9817 10.32 11.075C10.2467 11.1683 10.21 11.2917 10.21 11.445C10.21 11.595 10.2467 11.7167 10.32 11.81C10.3967 11.9 10.4933 11.945 10.61 11.945ZM14.065 13.795C13.8883 13.9717 13.6533 14.06 13.36 14.06C13.0667 14.06 12.8317 13.9717 12.655 13.795C12.4783 13.615 12.39 13.3783 12.39 13.085C12.39 12.7883 12.4783 12.5517 12.655 12.375C12.8317 12.195 13.0667 12.105 13.36 12.105C13.6533 12.105 13.8883 12.195 14.065 12.375C14.245 12.555 14.335 12.7917 14.335 13.085C14.335 13.3783 14.245 13.615 14.065 13.795ZM13.36 13.59C13.48 13.59 13.5767 13.545 13.65 13.455C13.7267 13.3617 13.765 13.2383 13.765 13.085C13.765 12.9317 13.7267 12.81 13.65 12.72C13.5767 12.6267 13.48 12.58 13.36 12.58C13.2433 12.58 13.1467 12.6267 13.07 12.72C12.9967 12.8133 12.96 12.935 12.96 13.085C12.96 13.235 12.9967 13.3567 13.07 13.45C13.1467 13.5433 13.2433 13.59 13.36 13.59Z"
          fill="white"
        />
      </g>
    </svg>
  )
}

export default UserPercentage
