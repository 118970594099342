import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {SearchButton, SearchForm, SearchInput} from './SearchBox.styled'

export type SearchBoxProps = {
  initialValue: string | null
  onClick: (searchTerm: string) => void
}

export const SearchBox: React.FC<SearchBoxProps> = ({
  initialValue,
  onClick,
}) => {
  const {t} = useTranslation()
  const [searchTerm, setSearchTerm] = useState(initialValue || '')

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    onClick(searchTerm)
  }

  return (
    <SearchForm onSubmit={handleSubmit}>
      <SearchInput
        placeholder={t('Search timeline...')}
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <SearchButton name="Search" color="secondary" />
    </SearchForm>
  )
}
