import * as React from 'react'

import {IconProps} from '.'

function Distance({color, size, isFilled}: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={isFilled ? color : 'none'}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.495 12.495C21.7683 12.2216 21.7683 11.7784 21.495 11.505L17.0402 7.05025C16.7668 6.77689 16.3236 6.77689 16.0503 7.05025C15.7769 7.32362 15.7769 7.76684 16.0503 8.0402L20.0101 12L16.0503 15.9598C15.7769 16.2332 15.7769 16.6764 16.0503 16.9497C16.3236 17.2231 16.7668 17.2231 17.0402 16.9497L21.495 12.495ZM14 12.7L21 12.7V11.3L14 11.3V12.7Z"
        fill={color}
      />
      <path
        d="M2.50502 11.505C2.23166 11.7784 2.23166 12.2216 2.50502 12.495L6.9598 16.9497C7.23316 17.2231 7.67638 17.2231 7.94975 16.9497C8.22311 16.6764 8.22311 16.2332 7.94975 15.9598L3.98995 12L7.94975 8.0402C8.22311 7.76684 8.22311 7.32362 7.94975 7.05025C7.67638 6.77689 7.23316 6.77689 6.9598 7.05025L2.50502 11.505ZM14 11.3L3 11.3V12.7L14 12.7V11.3Z"
        fill={color}
      />
    </svg>
  )
}

export default Distance
