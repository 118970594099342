import {PractitionersPatient, QuestionnaireInfo} from '@common/models'
import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react'

const QUESTIONNAIRES_CONTEXT_STORAGE_KEY = 'questionnairesContextData'

type QuestionnaireContextStorageData = {
  selectedPatients: PractitionersPatient[]
  questionnaire?: QuestionnaireInfo
}

type QuestionnaireContext = {
  setSelectedPatients: Dispatch<SetStateAction<PractitionersPatient[]>>
  setQuestionnaire: Dispatch<SetStateAction<QuestionnaireInfo | undefined>>
} & QuestionnaireContextStorageData

export const QuestionnairesContext = createContext({} as QuestionnaireContext)

type QuestionnairesContextProviderProps = {
  children: React.ReactNode
}

export const QuestionnairesContextProvider: React.FC<
  QuestionnairesContextProviderProps
> = ({children}) => {
  const [selectedPatients, setSelectedPatients] = useState<
    PractitionersPatient[]
  >([])
  const [questionnaire, setQuestionnaire] = useState<QuestionnaireInfo>()

  // Load context from storage
  useEffect(() => {
    try {
      const storedData = window.sessionStorage.getItem(
        QUESTIONNAIRES_CONTEXT_STORAGE_KEY,
      )
      const parsedStoredData = storedData
        ? (JSON.parse(storedData) as QuestionnaireContextStorageData)
        : undefined

      if (parsedStoredData) {
        setQuestionnaire(parsedStoredData.questionnaire)
        setSelectedPatients(parsedStoredData.selectedPatients)
      }
    } catch (error) {
      // [CUSH-655] FIXME: Add error logging
      // eslint-disable-next-line no-console
      console.warn("Could not load questionnaires' context", error)
    }
  }, [])

  useEffect(() => {
    window.sessionStorage.setItem(
      QUESTIONNAIRES_CONTEXT_STORAGE_KEY,
      JSON.stringify({selectedPatients, questionnaire}),
    )
  }, [selectedPatients, questionnaire])

  const contextValue = useMemo(
    () => ({
      selectedPatients,
      setSelectedPatients,
      questionnaire,
      setQuestionnaire,
    }),
    [selectedPatients, questionnaire],
  )

  return (
    <QuestionnairesContext.Provider value={contextValue}>
      {children}
    </QuestionnairesContext.Provider>
  )
}
