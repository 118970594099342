import styled, {DefaultTheme} from 'styled-components'

type Button = {
  color: 'primary' | 'secondary'
  disabled: boolean
}

const getBgdColor = (color: string, hover: boolean, theme: DefaultTheme) => {
  switch (color) {
    case 'secondary':
      return hover ? theme.colors.buttonHover : theme.colors.primary.blue50
    default:
      return hover
        ? theme.colors.secondary.orange600
        : theme.colors.secondary.main
  }
}

export const StyledIconButton = styled.button<Button>`
  background-color: ${({color, theme}) => getBgdColor(color, false, theme)};
  ${({disabled}) => disabled && 'opacity: 0.5'};
  height: ${({theme}) => theme.spacing.xl};
  width: ${({theme}) => theme.spacing.xl};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${({theme}) => theme.borderRadius.circled};
  cursor: ${({disabled}) => (disabled ? 'default' : 'pointer')};
  transition: background-color 350ms;

  &:hover {
    background-color: ${({color, disabled, theme}) =>
      !disabled && getBgdColor(color, true, theme)};
  }
`
