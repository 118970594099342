import {PractitionersPatient} from '@common/models'
import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {useGetPatientContactDetails} from '~/api'
import {DataAccordionContent} from '~/components/general/accordion/DataAccordionContent'
import {Loading} from '~/components/general/loading/Loading'

type ContactDetailsAccordionContentProps = {
  patient: PractitionersPatient
}

export const ContactDetailsAccordionContent: React.FC<
  ContactDetailsAccordionContentProps
> = ({patient}) => {
  const {t} = useTranslation()

  const {data, isInitialLoading} = useGetPatientContactDetails({
    patientId: patient.patientId,
  })

  const accordionData = useMemo(
    () => [
      {
        title: t('Home phone'),
        value: data?.homePhone || '-',
      },
      {
        title: t('Mobile phone'),
        value: data?.phoneNumber || '-',
      },
      {
        title: t('Email address'),
        value: data?.email || '-',
      },
    ],
    [data, t],
  )

  if (isInitialLoading) {
    return <Loading />
  }

  return <DataAccordionContent data={accordionData} />
}
