import {LinkValidationResponse, SharingLinkStatus} from '@common/models'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {Loading} from '../general/loading/Loading'
import {Container} from '../general/container/Container'
import {Typography} from '../general/typography/Typography'
import {PatientDashboardCard} from '../patients/dashboard/PatientDashboardCard'
import {PatientDashboardTrendsCard} from '../patients/dashboard/PatientDashboardTrendsCard'
import {PatientDashboardMedicalCard} from '../patients/dashboard/PatientDashboardMedicalCard'
import {PatientDashboardPersonalCard} from '../patients/dashboard/PatientDashboardPersonalCard'
import {PatientHeader} from '../patients/dashboard/PatientHeader'
import {useGetLinkSharingData} from './actions'

import {useGetSharingLinkCriticalNote} from '~/api'

export type LinkSharingContentProps = {
  password: string
  response: LinkValidationResponse
  token: string
}

const LinkSharingCards: React.FC<LinkSharingContentProps> = ({
  password,
  response,
  token,
}) => {
  const {t} = useTranslation()

  const {isLoading, basicHealthInfo, latestActivities} = useGetLinkSharingData({
    password,
    response,
    token,
  })

  if (isLoading) {
    return <Loading />
  }

  return (
    <Container flex direction="column" spacing={2}>
      <Container flex spacing={2}>
        <PatientDashboardCard title={t('Medical')}>
          <PatientDashboardMedicalCard
            data={basicHealthInfo}
            permissions={response.permissions ?? []}
          />
        </PatientDashboardCard>
        <PatientDashboardPersonalCard
          personalData={basicHealthInfo?.personalInformation}
          permissions={response.permissions ?? []}
          hideShowAllButton
        />
      </Container>
      <Container flex spacing={2}>
        <PatientDashboardTrendsCard
          disableBadgeLinks
          latestActivities={latestActivities}
          lifestyleData={basicHealthInfo?.lifestyleInformation}
          patient={response?.patient}
          hideShowAllButton
        />
      </Container>
    </Container>
  )
}

export const LinkSharingContent: React.FC<LinkSharingContentProps> = ({
  password,
  response,
  token,
}) => {
  const {t} = useTranslation()

  const {data: criticalNote} = useGetSharingLinkCriticalNote(
    {token, password},
    {enabled: response.status === SharingLinkStatus.VALID},
  )

  return (
    <Container flex direction="column" spacing={4}>
      <PatientHeader patient={response?.patient} criticalNote={criticalNote} />
      {response.status === SharingLinkStatus.EXPIRED ? (
        <Typography size="lg">{t('Link has expired.')}</Typography>
      ) : (
        <LinkSharingCards
          token={token}
          password={password}
          response={response}
        />
      )}
    </Container>
  )
}
