import styled from 'styled-components'

export enum PopupSide {
  LEFT,
  RIGHT,
}
type StyledPopupProps = {
  side: PopupSide
}

export const StyledPopupContainer = styled.div`
  position: relative;
`

export const StyledPopup = styled.div<StyledPopupProps>`
  position: absolute;
  ${({side}) => (side === PopupSide.LEFT ? 'right: 0;' : '')}
  top: calc(100% + 4px);
  z-index: 10;
  cursor: pointer;
`

export const StyledPopupMenu = styled.ul`
  min-width: 150px;
  background-color: ${({theme}) => theme.colors.white};
  border: 1px solid ${({theme}) => theme.colors.grey.grey90};
  border-radius: ${({theme}) => theme.borderRadius.xs};
  box-shadow: ${({theme}) => theme.boxShadow.popup};
  overflow: hidden;

  & > *:nth-child(odd) {
    background-color: ${({theme}) => theme.colors.grey.grey20};
  }
`

export const StyledPopupMenuItem = styled.li`
  height: ${({theme}) => theme.height.menuItem};
  display: flex;
  align-items: center;
  padding: 0 ${({theme}) => theme.spacing.lg};
  white-space: nowrap;
  cursor: pointer;
`
