import React, {useMemo} from 'react'
import {matchPath, Outlet, useLocation, useNavigate} from 'react-router-dom'
import {useTranslation} from 'react-i18next'

import {Layout} from '~/components/layout/Layout'
import {QuestionnairesSidebar} from '~/components/questionnaires/questionnairesSidebar/QuestionnairesSidebar'

type QuestionnairesPageProps = {
  fullPath: string
}

export const QuestionnairesPage: React.FC<QuestionnairesPageProps> = ({
  fullPath,
}) => {
  const {t} = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()

  const navData = useMemo(
    () => [
      {
        title: 'active',
        list: [
          {
            id: 'responses',
            label: t('Responses'),
          },
          {
            id: 'unanswered',
            label: t('Unanswered'),
          },
        ],
      },
      {
        title: 'libraries',
        list: [
          {
            id: 'library',
            label: t('Cushla library'),
          },
        ],
      },
    ],
    [t],
  )

  const flatNavData = useMemo(
    () => navData.flatMap((item) => item.list),
    [navData],
  )

  const activeRouteId = useMemo(
    () =>
      flatNavData
        .map(({id}) => matchPath(`${fullPath}${id}`, location.pathname) && id)
        .find((result) => !!result) || flatNavData[0].id,
    [flatNavData, fullPath, location.pathname],
  )

  return (
    <Layout
      questionnaires
      sideNav={
        <QuestionnairesSidebar
          sidebarDataList={navData}
          activeId={activeRouteId}
          onClick={navigate}
        />
      }>
      <Outlet />
    </Layout>
  )
}
