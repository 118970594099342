import React, {useCallback, useContext, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'
import {PractitionersPatient} from '@common/models'
import {DEBOUNCE_TIME, MISSING_ID} from '@common/utils'
import {useDebounce} from 'use-debounce'

import {PractitionerContext} from '~/components/profile/PractitionerContext'
import {MaterialsContext} from '~/components/educationalMaterials/MaterialsContext'
import {useGetPractitionerPatients} from '~/api'
import {Layout} from '~/components/layout/Layout'
import {Loading} from '~/components/general/loading/Loading'
import {PatientsTable} from '~/components/patients/common/patientsTable/PatientsTable'
import {PatientsTableRowData} from '~/components/patients/common/patientsTable/PatientsTableRow'
import {Container} from '~/components/general/container/Container'
import {Input} from '~/components/general/input/Input'
import {formatName, getTableDateValue} from '~/utils'
import {QuestionnairesContext} from '~/components/questionnaires/QuestionnairesContext'

const PATIENTS_PER_TABLE_PAGE = 10

export const PatientsPage: React.FC = () => {
  const navigate = useNavigate()
  const {t} = useTranslation()
  const {setSelectedPatient: setMaterialsContextPatient} =
    useContext(MaterialsContext)
  const {setSelectedPatients: setQuestionnairesContextPatients} = useContext(
    QuestionnairesContext,
  )
  const {selectedEmployment} = useContext(PractitionerContext)

  const [currentTablePageIndex, setCurrentTablePageIndex] = useState(0)
  const [query, setQuery] = useState('')
  const [debouncedQuery] = useDebounce(query, DEBOUNCE_TIME)

  const {
    data: patientsData,
    isInitialLoading: isLoadingPatientsData,
    isFetching: isLoadingCurrentPage,
  } = useGetPractitionerPatients({
    perPage: PATIENTS_PER_TABLE_PAGE,
    page: currentTablePageIndex,
    facilityId: selectedEmployment?.facilityId ?? MISSING_ID,
    query: debouncedQuery,
  })

  const currentPagePatients = useMemo(
    () => patientsData?.records ?? [],
    [patientsData?.records],
  )

  const handleSendQuestionnaireClick = useCallback(
    (patient: PractitionersPatient) => {
      setQuestionnairesContextPatients([patient])
      navigate('/app/questionnaires/library')
    },
    [navigate, setQuestionnairesContextPatients],
  )

  const handleContactDetailsClick = useCallback(
    (patient: PractitionersPatient) => navigate(`${patient.patientId}/profile`),
    [navigate],
  )

  const handleShareMaterialsClick = useCallback(
    (patient: PractitionersPatient) => {
      setMaterialsContextPatient(patient)
      navigate('/app/materials')
    },
    [setMaterialsContextPatient, navigate],
  )

  const patientsTableRowDataList: PatientsTableRowData[] = useMemo(() => {
    const data = currentPagePatients.map((patient) => {
      const getAddressLine = () => {
        const addressString = [
          patient.address?.addressLine,
          patient.address?.postCode,
          patient.address?.town,
          patient.address?.country,
          patient.address?.county,
        ]
          .filter((val) => !!val)
          .join(', ')

        return addressString || '-'
      }

      return {
        patientId: patient.patientId,
        patientName: formatName(patient),
        lastAppointmentDate: getTableDateValue(patient.lastConsultationDate),
        dateOfBirth: getTableDateValue(patient.dateOfBirth),
        address: getAddressLine(),
        menuItems: [
          {
            label: t('Contact details'),
            onClick: () => handleContactDetailsClick(patient),
          },
          {
            label: t('Send questionnaire'),
            onClick: () => handleSendQuestionnaireClick(patient),
          },
          {
            label: t('Share materials'),
            onClick: () => handleShareMaterialsClick(patient),
          },
        ],
      }
    })

    return data
  }, [
    currentPagePatients,
    t,
    handleContactDetailsClick,
    handleShareMaterialsClick,
    handleSendQuestionnaireClick,
  ])

  const handleSearchQueryChange: React.ChangeEventHandler<HTMLInputElement> = (
    event,
  ) => {
    setCurrentTablePageIndex(0)
    setQuery(event.target.value)
  }

  return (
    <Layout
      navMenu={
        <Container flex align="center" spacing={1}>
          <Input
            placeholder={t('Search patients...')}
            startIcon="Search"
            onChange={handleSearchQueryChange}
            noBorder
          />
          {/* TODO: add when filter requirements are defined and BE supports them
          <Button
            disabled
            size="sm"
            startIcon={<Icon name="Filter" />}
            onClick={() => undefined}
            color="secondary">
            {t('Filter')}
          </Button> */}
        </Container>
      }>
      {isLoadingPatientsData ? (
        <Loading />
      ) : (
        <PatientsTable
          rows={patientsTableRowDataList}
          onRowClick={navigate}
          paginationData={patientsData?.page}
          isLoading={isLoadingCurrentPage}
          loaderColSpan={5}
          onPageClick={setCurrentTablePageIndex}
        />
      )}
    </Layout>
  )
}
