import {SelectOptions, useMultiSelectValues} from '@common/utils'
import React, {useState} from 'react'

import {
  MultiSelectInputContainer,
  MultiSelectContainer,
  MultiSelectIcon,
  MultiSelectOptions,
  MultiSelectWrapper,
} from './MultiSelect.styled'
import {Typography} from '../typography/Typography'
import {SelectorWrapper} from '../select/Select.styled'
import {Checkbox} from '../checkbox/Checkbox'

import {useOutsideClickClose} from '~/utils'
import {theme} from '~/styles/theme'

type MultiSelectProps<T> = {
  onChange: (values: T[]) => void
  options: SelectOptions<T>
  initialValues: T[]
  title: string
  width?: number
}

export const MultiSelect = <T extends string>({
  onChange,
  options,
  initialValues,
  title,
  width,
}: MultiSelectProps<T>) => {
  const [showOptions, setShowOptions] = useState(false)
  const {checkboxValues, toggleValue} = useMultiSelectValues(
    options,
    onChange,
    initialValues,
  )
  const ref = useOutsideClickClose(() => setShowOptions(false))

  return (
    <MultiSelectWrapper ref={ref} width={width}>
      <SelectorWrapper>
        <MultiSelectContainer showOptions={showOptions}>
          <MultiSelectInputContainer
            onClick={() => setShowOptions(!showOptions)}>
            <Typography color={theme.colors.grey.main}>{title}</Typography>
            <MultiSelectIcon
              showOptions={showOptions}
              name="ArrowDown"
              color={theme.colors.grey.grey500}
            />
          </MultiSelectInputContainer>
          {showOptions && (
            <MultiSelectOptions>
              {options.map((option, index) => (
                <Checkbox
                  label={option.label}
                  key={option.value}
                  checked={checkboxValues[index]}
                  onChange={() => toggleValue(index)}
                />
              ))}
            </MultiSelectOptions>
          )}
        </MultiSelectContainer>
      </SelectorWrapper>
    </MultiSelectWrapper>
  )
}
