import {DateTime} from 'luxon'
import {InfiniteData} from '@tanstack/react-query'

import {Address, Facility, PagedResponse} from '../models'

type NullishRecord<T> = {[K in keyof T]: T[K] | undefined}

export const isNotNull = <T>(value: T | null | undefined): value is T =>
  value != null

export const isTruthy = <T>(
  value: T | null | undefined | false | 0 | '',
): value is T => !!value

const isEmptyValue = (value: unknown) => value == null || value === ''

export const nullifyFalsyValues = <T>(obj: T) => {
  if (typeof obj !== 'object' || !obj) {
    return obj
  }

  return Object.keys(obj).reduce((newObj, k) => {
    const key = k as keyof T
    newObj[key] = isEmptyValue(obj[key]) ? undefined : obj[key]
    return newObj
  }, {} as NullishRecord<T>)
}

export const objectToForm = (obj: object) =>
  Object.entries(obj).reduce((result, [key, value]) => {
    if (value) {
      if (Array.isArray(value)) {
        value.forEach((v) => result.append(key, v))
      } else {
        result.append(key, value)
      }
    }
    return result
  }, new FormData())

export const isPdf = (url: string) => {
  const endOfPath = url.indexOf('?')
  const cleanedUrl = endOfPath === -1 ? url : url.slice(0, endOfPath)

  return cleanedUrl.endsWith('.pdf') || !cleanedUrl.slice(-4).includes('.')
}

export type AlphabetSectionData<T> = {
  letter: string
  data: T[]
}[]

type CreateSectionDataBaseProps<T> = {
  data: T[]
  nameSelector: (listItem: T) => string
  itemSelector?: never
}

type CreateSectionDataPropsWithItemSelector<T, K> = {
  data: K[]
  itemSelector: (dataItem: K) => T
  nameSelector: (listItem: T) => string
}

type CreateSectionData = {
  <T, K>({
    data,
    itemSelector,
    nameSelector,
  }: CreateSectionDataPropsWithItemSelector<T, K>): AlphabetSectionData<T>
  <T>({
    data,
    itemSelector,
    nameSelector,
  }: CreateSectionDataBaseProps<T>): AlphabetSectionData<T>
}

export const createAlphabetSectionData: CreateSectionData = <T>({
  data,
  nameSelector,
  itemSelector = (item) => item,
}: {
  data: any[]
  nameSelector: (listItem: T) => string
  itemSelector?: (dataItem: any) => T
}) => {
  const getFirstLetter = (item: T) =>
    nameSelector(itemSelector(item)).charAt(0).toUpperCase()

  const sectionData = data
    ?.filter((item) => !!itemSelector(item))
    .reduce<AlphabetSectionData<T>>((sectionList, dataItem) => {
      const sectionItem = sectionList.find(
        (listItem) => listItem.letter === getFirstLetter(dataItem),
      )

      if (sectionItem) {
        sectionItem.data.push(itemSelector(dataItem))
      } else {
        sectionList.push({
          letter: getFirstLetter(dataItem),
          data: [itemSelector(dataItem)],
        })
      }

      return sectionList
    }, [])

  return sectionData.sort((a, b) => a.letter.localeCompare(b.letter))
}

export const capitalizeFirstLetter = (string?: string | null) => {
  if (!string) {
    return null
  }
  return string.charAt(0).toUpperCase() + string.slice(1)
}

// returns enumerated items in format "A, B, C <conjunction> D"
export const enumerateListItems = (
  values?: string[],
  conjunction?: string | null,
) => {
  const separator = conjunction ? ` ${conjunction} ` : ', '

  if (!values?.length) {
    return ''
  }

  if (values.length === 1) {
    return values[0]
  }

  return values
    .slice(0, values.length - 1)
    .join(', ')
    .concat(separator)
    .concat(values[values.length - 1])
}

export const calculateAgeFromDateOfBirth = (dateOfBirth?: string | null) => {
  if (!dateOfBirth) {
    return undefined
  }

  const date = DateTime.fromISO(dateOfBirth)
  const diffInYears = DateTime.now().diff(date, 'years').toObject().years

  return diffInYears ? Math.floor(diffInYears) : undefined
}

export const formatFacilityAddress = ({addressLine, city, county}: Facility) =>
  [addressLine, city, county].filter(Boolean).join(', ')

export const formatUserAddress = ({
  addressLine,
  country,
  county,
  town,
}: Partial<Address>) =>
  [addressLine, town, county, country].filter(Boolean).join(', ')

export const getFirstRecordFromInfiniteData = <T>(
  infiniteData?: InfiniteData<PagedResponse<T>>,
) => infiniteData?.pages[0]?.records[0]

type QueryProps = {
  isError: boolean
  isInitialLoading: boolean
  error: Error | null
  refetch: () => void
}

export const mergeQueryProps = (
  queries: (QueryProps | undefined | false)[],
) => {
  const activeQueries = queries.filter(isTruthy)
  const refetch = async () => activeQueries.forEach((query) => query.refetch())

  return {
    isError: activeQueries.some((query) => query.isError),
    isInitialLoading: activeQueries.some((query) => query.isInitialLoading),
    error: activeQueries.find((query) => !!query.error)?.error ?? null,
    refetch,
  }
}

export const getUpdatedArray = <T>(array: T[], newItem: T, index?: number) =>
  index === -1 || index == null
    ? [...array, newItem]
    : [...array.slice(0, index), newItem, ...array.slice(index + 1)]

export const removeItemFromArray = <T>(array: T[], indexToRemove: number) =>
  array.filter((_, index) => index !== indexToRemove)

export const formatLocaleNumber = (
  value?: number | null,
  fractionDigits?: number,
) => {
  if (value == null) {
    return ''
  }

  return value.toLocaleString(undefined, {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  })
}

export const getBoundedValue = (
  value: number,
  minValue?: number | null,
  maxValue?: number | null,
) => {
  const boundedValue = minValue == null ? value : Math.max(value, minValue)

  return maxValue == null ? boundedValue : Math.min(boundedValue, maxValue)
}

export const ellipsizeText = (
  text: string | null | undefined,
  maxLength: number,
) => {
  if (!text) {
    return ''
  }

  return text.length > maxLength
    ? `${text.slice(0, maxLength).trim()}...`
    : text
}
