import {HealthDataResolution} from '@common/models'
import React from 'react'
import {VictoryAxis, VictoryChart} from 'victory'
import {DateTime} from 'luxon'

import {
  getChartDomain,
  getChartTickFormatProps,
  useInitialDimensions,
} from '~/utils'
import {theme} from '~/styles/theme'
import {Container} from '~/components/general/container/Container'

const LEFT_OFFSET = 250 + 70

type TrendChartAxisProps = {
  resolution: HealthDataResolution
  startDate: DateTime
}

export const TrendChartAxis: React.FC<TrendChartAxisProps> = ({
  resolution,
  startDate,
}) => {
  const {dimensions, ref} = useInitialDimensions()

  return (
    <Container ref={ref} fullWidth>
      <VictoryChart
        domainPadding={{x: 8, y: 0}}
        padding={{top: 30, bottom: 0, left: 10 + LEFT_OFFSET, right: 10}}
        height={40}
        width={dimensions?.width}
        scale={{x: 'time', y: 'linear'}}
        domain={getChartDomain(resolution, startDate)}>
        <VictoryAxis
          {...getChartTickFormatProps(resolution, startDate)}
          orientation="top"
          fixLabelOverlap
          style={{
            axis: {stroke: theme.colors.primary.main, strokeWidth: 2},
            ticks: {stroke: theme.colors.primary.main, size: 0},
            tickLabels: {
              fill: theme.colors.primary.main,
              fontSize: 10,
              fontFamily: 'Inter',
            },
          }}
        />
      </VictoryChart>
    </Container>
  )
}
