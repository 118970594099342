import {AddressDto, NextOfKinDto, PreferredPronoun} from '@generated/models'

import {Sex} from './medical'
import {GeneralItemType} from './medicalLists'

export type {
  AddressDto as Address,
  ContactDetailDto as ContactDetails,
  HealthInsuranceDto as InsuranceDetails,
  NameDetailDto as NameDetails,
  NextOfKinDto as NextOfKin,
  OrganDonorProfile as OrganDonorConsentDetails,
} from '@generated/models'

export {
  AddressType,
  FamilyRelationship as Relationship,
  HealthCareType as PatientCategory,
  HealthIdentifierType,
  PreferredPronoun as Pronoun,
} from '@generated/models'

export type DemographicDetails = {
  dateOfBirth?: string
  dependents?: string
  gender?: Sex
  hobbies?: string[]
  nationality?: string
  occupation?: GeneralItemType
  patientId: string
  placeOfBirth?: string
  preferredPronoun?: PreferredPronoun
}

export type AddressDetails = AddressDto[]

export type NextOfKinDetails = NextOfKinDto[]
