import {
  endpointKeys,
  SharingLinkAggregatedActivitiesParams,
  SharingLinkLatestActivitiesParams,
  SharingLinkMeasurementsParams,
  SharingLinkParams,
} from '@common/api'
import {
  AggregatedActivity,
  BasicHealthInfoResponse,
  CriticalNote,
  LatestActivity,
  LinkValidationResponse,
  PagedResponse,
  QuestionnaireMeasurement,
} from '@common/models'
import {
  aggregatedActivitiesNormaliser,
  basicHealthInfoNormaliser,
  criticalNoteOrNullNormaliser,
  getPagedResponseNormaliser,
  latestActivityNormaliser,
  linkValidationResponseNormaliser,
  measurementNormaliser,
} from '@common/normalisers'
import {UseQueryOptions} from '@tanstack/react-query'

import {useApi, useWebGet} from './helpers'

export const useValidateLinkPassword = (
  params: SharingLinkParams,
  options?: UseQueryOptions<LinkValidationResponse, Error>,
) =>
  useWebGet<LinkValidationResponse>(
    [endpointKeys.sharingLinkValidation, params],
    useApi().sharingLinkValidation(params),
    linkValidationResponseNormaliser,
    options,
    false,
  )

export const useGetSharingLinkMeasurements = (
  params: SharingLinkMeasurementsParams,
  options?: UseQueryOptions<PagedResponse<QuestionnaireMeasurement>, Error>,
) =>
  useWebGet<PagedResponse<QuestionnaireMeasurement>>(
    [endpointKeys.sharingLinkMeasurements, params],
    useApi().sharingLinkMeasurements(params),
    getPagedResponseNormaliser(measurementNormaliser),
    options,
    false,
  )

export const useGetSharingLinkBasicHealthInfo = (
  params: SharingLinkParams,
  options?: UseQueryOptions<BasicHealthInfoResponse, Error>,
) =>
  useWebGet<BasicHealthInfoResponse>(
    [endpointKeys.sharingLinkBasicHealthInfo, params],
    useApi().sharingLinkBasicHealthInfo(params),
    basicHealthInfoNormaliser,
    options,
    false,
  )

export const useGetSharingLinkAggregatedActivities = (
  params: SharingLinkAggregatedActivitiesParams,
  options?: UseQueryOptions<AggregatedActivity[], Error>,
) =>
  useWebGet<AggregatedActivity[]>(
    [endpointKeys.sharingLinkAggregatedActivities, params],
    useApi().sharingLinkAggregatedActivities(params),
    aggregatedActivitiesNormaliser,
    options,
    false,
  )

export const useGetSharingLinkLatestActivities = (
  params: SharingLinkLatestActivitiesParams,
  options?: UseQueryOptions<LatestActivity, Error>,
) =>
  useWebGet<LatestActivity>(
    [endpointKeys.sharingLinkLatestActivities, params],
    useApi().sharingLinkLatestActivities(params),
    latestActivityNormaliser,
    options,
    false,
  )

export const useGetSharingLinkCriticalNote = (
  params: SharingLinkParams,
  options?: UseQueryOptions<CriticalNote | null, Error>,
) =>
  useWebGet<CriticalNote | null>(
    [endpointKeys.sharingLinkCriticalNote, params],
    useApi().sharingLinkCriticalNote(params),
    criticalNoteOrNullNormaliser,
    options,
    false,
  )
