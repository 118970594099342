import i18n from 'i18next'
import {initReactI18next} from 'react-i18next'
import locales from '@common/locales'

import {browserLanguage} from '~/utils'

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false
  }
}

const initializeI18Next = () =>
  i18n.use(initReactI18next).init({
    resources: locales,
    lng: browserLanguage,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    returnNull: false,
  })

export default initializeI18Next
