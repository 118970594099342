import {PractitionersPatient} from '@common/models'
import {useTranslation} from 'react-i18next'
import React, {useCallback, useContext, useState} from 'react'

import {
  SelectedPatientsTable,
  SelectedPatientsTableCell,
  SelectedPatientsTableRow,
  SelectedPatientsTableWrapper,
} from './ShareQuestionnaireModal.styled'

import {PatientsSuggestionsList} from '~/components/general/select/PatientsSuggestionsList'
import {Select} from '~/components/general/select/Select'
import {QuestionnairesContext} from '~/components/questionnaires/QuestionnairesContext'
import {Typography} from '~/components/general/typography/Typography'
import {formatName, getTableDateValue} from '~/utils'
import {Icon} from '~/components/general/icon/Icon'
import {theme} from '~/styles/theme'

type SelectedPatientRowProps = {
  patient: PractitionersPatient
  removePatient: (patient: PractitionersPatient) => void
}

const SelectedPatientRow: React.FC<SelectedPatientRowProps> = ({
  patient,
  removePatient,
}) => (
  <SelectedPatientsTableRow>
    <SelectedPatientsTableCell columnWidthPercent={60}>
      <Typography ellipsis size="lg">
        {formatName(patient)}
      </Typography>
    </SelectedPatientsTableCell>
    <SelectedPatientsTableCell columnWidthPercent={35}>
      <Typography color={theme.colors.grey.main}>
        {getTableDateValue(patient.dateOfBirth)}
      </Typography>
    </SelectedPatientsTableCell>
    <SelectedPatientsTableCell columnWidthPercent={5}>
      <Icon name="Close" onClick={() => removePatient(patient)} size="sm" />
    </SelectedPatientsTableCell>
  </SelectedPatientsTableRow>
)

export const ShareQuestionnaireSelectPatients: React.FC = () => {
  const {t} = useTranslation()
  const [query, setQuery] = useState('')
  const {selectedPatients, setSelectedPatients} = useContext(
    QuestionnairesContext,
  )

  const isSelected = useCallback(
    (patient: PractitionersPatient) =>
      !!selectedPatients.find(
        (selectedPatient) => selectedPatient.patientId === patient.patientId,
      ),
    [selectedPatients],
  )

  const handlePatientClick = (patient: PractitionersPatient) => {
    setQuery('')
    if (!isSelected(patient)) {
      setSelectedPatients([...selectedPatients, patient])
    }
  }

  const handleRemovePatient = (patient: PractitionersPatient) => {
    const newSelection = selectedPatients.filter(
      (selectedPatient) => selectedPatient.patientId !== patient.patientId,
    )
    setSelectedPatients(newSelection)
  }

  return (
    <>
      <Select
        search
        placeholder={t('Add patients...')}
        onChange={(event) => setQuery(event.target.value)}
        value={query}>
        <PatientsSuggestionsList
          query={query}
          onPatientClick={handlePatientClick}
          filteredPatients={selectedPatients}
        />
      </Select>
      <SelectedPatientsTableWrapper>
        <SelectedPatientsTable>
          <tbody>
            {selectedPatients.map((patient) => (
              <SelectedPatientRow
                key={patient.patientId}
                patient={patient}
                removePatient={handleRemovePatient}
              />
            ))}
          </tbody>
        </SelectedPatientsTable>
      </SelectedPatientsTableWrapper>
    </>
  )
}
