import * as React from 'react'
import {useMemo, useReducer} from 'react'
import {useMsal} from '@azure/msal-react'

import {loginRequest, msalConfig} from './AuthConfig'
import {AuthContext} from './AuthContext'

type AuthState = {
  isLoading: boolean
  signUpInProgress: boolean
}

type AuthReducerAction = {
  type:
    | 'SET_LOADING_FALSE'
    | 'SIGN_UP_START'
    | 'SIGN_UP_END'
    | 'MANUAL_ACCESS_TOKEN'
  token?: string
}

const initialState = {
  isLoading: false,
  signUpInProgress: false,
}

const reducer = (prevState: AuthState, action: AuthReducerAction) => {
  switch (action.type) {
    case 'SET_LOADING_FALSE':
      return {
        ...prevState,
        isLoading: false,
      }
    case 'SIGN_UP_START':
      return {
        ...prevState,
        signUpInProgress: true,
      }
    case 'SIGN_UP_END':
      return {
        ...prevState,
        signUpInProgress: false,
      }
    case 'MANUAL_ACCESS_TOKEN':
      return {
        ...prevState,
        accessToken: action.token,
        isLoading: false,
      }
    default:
      return prevState
  }
}

type AuthContextProviderProps = {
  children: React.ReactNode
}

export const AuthContextProvider: React.FC<AuthContextProviderProps> = ({
  children,
}) => {
  const {instance} = useMsal()
  const [{isLoading, signUpInProgress}, dispatch] = useReducer(
    reducer,
    initialState,
  )

  const authContext = useMemo(
    () => ({
      setLoadingFalse: () => {
        dispatch({type: 'SET_LOADING_FALSE'})
      },
      signIn: async () => {
        try {
          await instance.loginRedirect(loginRequest)
        } catch (error) {
          // [CUSH-655] FIXME: Add error logging
          // eslint-disable-next-line no-console
          console.error(error)
        }
      },
      signUp: async () => {
        try {
          await instance.loginRedirect(loginRequest)
        } catch (error) {
          // [CUSH-655] FIXME: Add error logging
          // eslint-disable-next-line no-console
          console.error(error)
        }
      },
      signOut: async () => {
        instance.logoutRedirect({
          postLogoutRedirectUri: msalConfig.auth.redirectUri,
        })
      },
      startSignUp: async () => {
        dispatch({type: 'SIGN_UP_START'})
      },
      endSignUp: async () => {
        dispatch({type: 'SIGN_UP_END'})
      },
      changePassword: async () => {
        try {
          // TODO: await changePassword()
        } catch (error) {
          // [CUSH-655] FIXME: Add error logging
          // eslint-disable-next-line no-console
          console.error(error)
        }
      },
      isLoading,
      signUpInProgress,
    }),
    [isLoading, signUpInProgress, instance],
  )

  if (isLoading) {
    return null // TODO: add loading view?
  }

  return (
    <AuthContext.Provider value={authContext}>{children}</AuthContext.Provider>
  )
}
