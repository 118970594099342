import React, {ReactNode, useRef, useEffect, useState} from 'react'
import {createPortal} from 'react-dom'

type Props = {
  children: ReactNode
  selector: string
}

export const Portal: React.FC<Props> = ({children, selector}) => {
  const ref = useRef<HTMLDivElement | null>(null)
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    ref.current = document.querySelector(selector)
    setMounted(true)
  }, [selector])

  return mounted && ref.current ? createPortal(children, ref.current) : null
}
