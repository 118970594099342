import styled from 'styled-components'

import {Select} from '~/components/general/select/Select'
import {Typography} from '~/components/general/typography/Typography'

export const FooterContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const StyledText = styled.span`
  font-weight: ${({theme}) => theme.fontWeight.medium};
  color: ${({theme}) => theme.colors.primary.main};
`

export const PatientBadgeWrapper = styled.div`
  border-radius: ${({theme}) => theme.borderRadius.lg};
  margin-left: ${({theme}) => theme.spacing.xs};
  padding: ${({theme}) => theme.spacing.xxs};
  padding-right: ${({theme}) => theme.spacing.xs};
  background-color: ${({theme}) => theme.colors.grey.grey50};
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const StyledBadgeText = styled(Typography)`
  margin: 0 ${({theme}) => theme.spacing.xs};
`

export const StyledSelect = styled(Select)`
  margin-top: ${({theme}) => theme.spacing.xl};
  margin-bottom: ${({theme}) => theme.spacing.md};
`
