import React from 'react'
import {VictoryAxis, VictoryChart, VictoryLine, VictoryScatter} from 'victory'
import {VictoryStringOrNumberCallback} from 'victory-core'
import {DateTime} from 'luxon'
import {
  ChartData,
  ChartDataPoint,
  HealthDataGrade,
  HealthDataResolution,
} from '@common/models'
import {formatLocaleNumber} from '@common/utils'

import {TrendChartTooltip, tooltipEvents} from './TrendChartTooltip'
import {TrendChartContainer} from './TrendChart.styled'

import {getChartDomain, useInitialDimensions} from '~/utils'
import {theme} from '~/styles/theme'

type TrendLineChartProps = {
  data: ChartData
  fractionDigits: number
  resolution: HealthDataResolution
  startDate: DateTime
  unit: string | undefined
}

const fillColors = {
  [HealthDataGrade.GOOD]: theme.colors.indicator.body.green,
  [HealthDataGrade.AVERAGE]: theme.colors.indicator.body.orange,
  [HealthDataGrade.BAD]: theme.colors.indicator.body.red,
}

const strokeColors = {
  [HealthDataGrade.GOOD]: theme.colors.indicator.border.green,
  [HealthDataGrade.AVERAGE]: theme.colors.indicator.border.orange,
  [HealthDataGrade.BAD]: theme.colors.indicator.border.red,
}

const getFillColor: VictoryStringOrNumberCallback = ({datum}) =>
  datum.grade && datum.grade in HealthDataGrade
    ? fillColors[datum.grade as HealthDataGrade]
    : theme.colors.indicator.body.blue

const getStrokeColor: VictoryStringOrNumberCallback = ({datum}) =>
  datum.grade && datum.grade in HealthDataGrade
    ? strokeColors[datum.grade as HealthDataGrade]
    : theme.colors.indicator.border.blue

export const TrendLineChart: React.FC<TrendLineChartProps> = ({
  data,
  fractionDigits,
  resolution,
  startDate,
  unit,
}) => {
  const {dimensions, ref} = useInitialDimensions()

  return (
    <TrendChartContainer ref={ref} fullWidth>
      <VictoryChart
        domainPadding={{x: 8, y: 10}}
        padding={{left: 10, right: 10, bottom: 8, top: 0}}
        height={dimensions?.height}
        width={dimensions?.width}
        scale={{x: 'time', y: 'linear'}}
        domain={getChartDomain(resolution, startDate)}>
        <VictoryLine
          interpolation="monotoneX"
          data={data}
          x="date"
          style={{data: {stroke: theme.colors.grey.main, strokeWidth: 2}}}
        />
        <VictoryScatter
          size={6}
          data={data}
          events={tooltipEvents}
          labels={({datum}) => datum.date}
          labelComponent={
            <TrendChartTooltip
              unit={unit}
              dy={-8}
              resolution={resolution}
              formatValue={(datum: ChartDataPoint) =>
                formatLocaleNumber(datum.y, fractionDigits)
              }
            />
          }
          x="date"
          style={{
            data: {fill: getFillColor, stroke: getStrokeColor, strokeWidth: 4},
          }}
        />
        <VictoryAxis
          dependentAxis
          style={{
            axis: {stroke: 'transparent'},
            ticks: {stroke: 'transparent'},
            tickLabels: {fill: 'transparent'},
          }}
        />
      </VictoryChart>
    </TrendChartContainer>
  )
}
