import React from 'react'

import {TimelineRow} from './TimelineRow'
import {TimelineDataTable, TimelineDataTableBody} from './TimelineTable.styled'
import {TimelineHeader} from './TimelineHeader'

import {TimelineItem} from '~/utils/timeline'

type TimelineTableProps = {
  items: Array<TimelineTableItem>
}

export type TimelineTableItem = {
  item: TimelineItem
  onClick: () => void
}

export const TimelineTable = ({items}: TimelineTableProps) => (
  <TimelineDataTable>
    <TimelineHeader />
    <TimelineDataTableBody>
      {items.map((item, index) => (
        <TimelineRow
          data={item.item}
          onClick={item.onClick}
          key={`TimelineRow-${index}`}
        />
      ))}
    </TimelineDataTableBody>
  </TimelineDataTable>
)
