import {assertArray, assertString, assertStringOrNull} from '../utils'
import {VitalConfig, VitalProvider, VitalProviders, VitalToken} from '../models'
import {AssertTypeFn} from './general'

export const vitalTokenNormaliser: AssertTypeFn<VitalToken> = (obj) => ({
  vitalToken: assertString(obj.vitalToken),
})

export const vitalProviderNormaliser: AssertTypeFn<VitalProvider> = (obj) => ({
  description: assertString(obj.description),
  logo: assertStringOrNull(obj.logo),
  name: assertString(obj.name),
  slug: assertString(obj.slug),
})

export const vitalProvidersNormaliser: AssertTypeFn<VitalProviders> = (
  obj,
) => ({
  providers: assertArray(obj.providers, vitalProviderNormaliser),
})

export const vitalConfigNormaliser: AssertTypeFn<VitalConfig> = (obj) => ({
  apiKey: assertString(obj.apiKey),
  environment: assertString(obj.environment),
  region: assertString(obj.region),
  userId: assertString(obj.userId),
})
