import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {AddAppointmentModalCard} from './AddAppointmentModal.styled'

import {Button} from '~/components/general/button/Button'
import {Input} from '~/components/general/input/Input'
import {Typography} from '~/components/general/typography/Typography'
import {Container} from '~/components/general/container/Container'
import FilteredSelect from '~/components/general/select/FilteredSelect'

interface AddAppointmentCardProps {
  locations: string[]
  onAppointmentCreate: (appointmentDate: Date, location: string | null) => void
}

/*
 * The date input requires the date to be in the format 'YYYY-MM-DDTHH:MM'
 */
const formatDateToLocalInput = (date: Date) => {
  // Convert the date to ISO string and remove the seconds and milliseconds
  const pad = (num: number): string => num.toString().padStart(2, '0')
  const year = date.getFullYear()
  const month = pad(date.getMonth() + 1)
  const day = pad(date.getDate())
  const hours = pad(date.getHours())
  const minutes = pad(date.getMinutes())

  return `${year}-${month}-${day}T${hours}:${minutes}`
}

const parseDatetime = (datetimeString: string) => {
  // Split the date and time parts
  const [datePart, timePart] = datetimeString.split('T')

  // Extract individual components
  const [year, month, day] = datePart.split('-').map(Number)
  const [hours, minutes] = timePart.split(':').map(Number)

  // Create a new Date object with local time
  return new Date(year, month - 1, day, hours, minutes)
}

const AddAppointmentCard: React.FC<AddAppointmentCardProps> = ({
  locations,
  onAppointmentCreate,
}) => {
  const {t} = useTranslation()
  const [selectedLocation, setSelectedLocation] = useState<string | undefined>()
  const [selectedDate, setSelectedDate] = useState<string>(() => {
    const timeNow = new Date()
    // Round to the nearest 10 minutes in the past
    const lastTenMinRounded = new Date(
      timeNow.setMinutes(Math.floor(timeNow.getMinutes() / 10) * 10),
    )
    return formatDateToLocalInput(lastTenMinRounded)
  })

  const handleCreateAppointment = () => {
    if (selectedDate && (locations.length === 0 || selectedLocation)) {
      onAppointmentCreate(parseDatetime(selectedDate), selectedLocation ?? null)
    }
  }

  const canAdd = selectedDate && (locations.length === 0 || selectedLocation)

  return (
    <AddAppointmentModalCard>
      <Container flex direction="column" spacing={2}>
        <Container flex direction="column">
          <Typography size="md" weight="medium">
            {t('Appointment Details')}
          </Typography>
        </Container>
        <Container flex direction="column" spacing={2}>
          <Typography>{t('Date and time')}</Typography>
          <Input
            type="datetime-local"
            value={selectedDate}
            onChange={(e) => {
              setSelectedDate(e.target.value)
            }}
          />
          {locations.length > 0 && (
            <>
              <Typography>{t('Location')}</Typography>
              <FilteredSelect
                options={locations.map((location) => ({
                  label: location,
                  value: location,
                }))}
                onChange={setSelectedLocation}
              />
            </>
          )}
        </Container>
        <Container flex justify="flex-end">
          <Button onClick={handleCreateAppointment} disabled={!canAdd}>
            Create Appointment
          </Button>
        </Container>
      </Container>
    </AddAppointmentModalCard>
  )
}

export default AddAppointmentCard
