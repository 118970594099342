import React, {useContext} from 'react'
import styled from 'styled-components'
import {Link} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import {MISSING_ID} from '@common/utils'
import {MedicalPermissionSubcategory} from '@common/models'

import {Pregnancy, PregnancyContainer, isBirth} from './PregnancyContainer'
import {Container} from '../../general/container/Container'
import {StyledCardContainer} from '../dashboard/PatientDashboardCard.styled'
import {PatientsContext} from '../PatientsContext'
import {PermissionCheck} from '../common/requestData/PermissionCheck'

import {theme} from '~/styles/theme'
import {useGetPatientObstetrics} from '~/api'
import {Loading} from '~/components/general/loading/Loading'
import {Typography} from '~/components/general/typography/Typography'

type PreviousPreganciesPageProps = {
  showReturnLink?: boolean
}

const LinkCard = styled(StyledCardContainer)`
  margin-bottom: ${theme.spacing.md};
  padding: 0;
`

const getPregnancyEndDate = (item: Pregnancy) =>
  isBirth(item) ? item.dateOfBirth : item.terminationDate

const comparePregnanciesByDate = (a: Pregnancy, b: Pregnancy) =>
  getPregnancyEndDate(b)?.localeCompare(getPregnancyEndDate(a))

const PreviousPreganciesContent = ({
  showReturnLink,
}: PreviousPreganciesPageProps) => {
  const {t} = useTranslation()

  const {patient} = useContext(PatientsContext)
  const {data, isInitialLoading} = useGetPatientObstetrics({
    patientId: patient?.patientId ?? MISSING_ID,
  })

  if (isInitialLoading) {
    return <Loading />
  }
  if (!data) {
    return <Typography>{t('No data to display')}</Typography>
  }

  const pregnancies = [
    ...(data.births || []),
    ...(data.terminatedPregnancies || []),
  ].sort(comparePregnanciesByDate)

  return (
    <>
      {showReturnLink && (
        <LinkCard>
          <Link to="../journeys">← {t('Return to current pregnancy')}</Link>
        </LinkCard>
      )}
      <Container>
        {pregnancies.map((item) => (
          <PregnancyContainer data={item} key={item.id} />
        ))}
        {!pregnancies.length && (
          <Typography>
            {data == null
              ? t("Patient hasn't entered the data about his pregnancies.")
              : t('Patient has no pregnancies.')}
          </Typography>
        )}
      </Container>
    </>
  )
}

export const PreviousPreganciesPage = (props: PreviousPreganciesPageProps) => (
  <PermissionCheck permission={MedicalPermissionSubcategory.OBSTETRICS}>
    <PreviousPreganciesContent {...props} />
  </PermissionCheck>
)
