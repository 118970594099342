import styled from 'styled-components'

export const ModalBody = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  inset: 0px;
  z-index: 800;
  padding: ${({theme}) => theme.spacing.xxl};
  outline: none;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: ${({theme}) => theme.colors.modalOverlay};
`

export const ModalWrapper = styled.div`
  position: relative;
  top: 0px;
  width: 100%;
  max-width: 504px;
  min-height: 100%;
  display: flex;
  align-items: center;
  margin: 0px auto;
  transition: top 0.3s ease-in-out 0s;
`

export const StyledModal = styled.div`
  background-color: ${({theme}) => theme.colors.white};
  position: relative;
  bottom: auto;
  width: 100%;
  max-height: 100%;
  height: auto;
  border-radius: ${({theme}) => theme.borderRadius.md};
  padding-bottom: 0px;
  overflow: visible;
`

export const ModalHeader = styled.div`
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({theme}) => `${theme.spacing.sm} ${theme.spacing.lg}`};
  border-bottom: 1px solid ${({theme}) => theme.colors.grey.grey40};
`

export const ModalContent = styled.div`
  padding: ${({theme}) => theme.spacing.lg};
`

export const ModalFooter = styled.div`
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({theme}) => `${theme.spacing.sm} ${theme.spacing.lg}`};
  border-top: 1px solid ${({theme}) => theme.colors.grey.grey40};
`
