import {
  AggregatedActivity,
  HealthDataResolution,
  HealthDataType,
  LatestHealthDataDetails,
  RowGroup,
} from '@common/models'
import React, {useContext, useMemo, useState} from 'react'
import {
  formatBloodPressureChartData,
  formatChartData,
  formatLocaleNumber,
  isNotNull,
} from '@common/utils'
import {DateTime} from 'luxon'

import {
  ChartBackground,
  ChartContainer,
  DomainValuesContainer,
  StyledHealthDataBadge,
} from './TrendRow.styled'
import {TrendLineChart} from './TrendLineChart'
import {PatientsContext} from '../PatientsContext'
import {BloodPressureTrendChart} from './BloodPressureTrendChart'
import {AddTrendDataCard} from './AddTrendDataCard/AddTrendDataCard'
import {EditableTrendTypes, isEditableTrendType} from './utils'

import {Container} from '~/components/general/container/Container'
import {Typography} from '~/components/general/typography/Typography'
import {theme} from '~/styles/theme'
import {Loading} from '~/components/general/loading/Loading'
import {IconButton} from '~/components/general/iconButton/IconButton'

type TrendRowProps = {
  aggregatedData: AggregatedActivity[] | undefined
  isLoading?: boolean
  latestDataDetails: LatestHealthDataDetails
  healthDataType: HealthDataType
  resolution: HealthDataResolution
  startDate: DateTime
  onUpdate?: (newData: RowGroup[]) => void
}

export const TrendRow: React.FC<TrendRowProps> = ({
  aggregatedData,
  isLoading,
  healthDataType,
  latestDataDetails,
  resolution,
  startDate,
  onUpdate,
}) => {
  const {patient} = useContext(PatientsContext)
  const [showEditControls, setShowEditControls] = useState(false)

  const chartData = useMemo(() => {
    if (!aggregatedData) {
      return []
    }

    if (healthDataType === HealthDataType.BLOOD_PRESSURE) {
      return formatBloodPressureChartData(aggregatedData, patient)
    }

    return formatChartData(aggregatedData, healthDataType, patient)
  }, [aggregatedData, healthDataType, patient])

  const domain = useMemo(() => {
    if (!chartData.length) {
      return {}
    }

    const values = chartData
      .flatMap((point) => ('y0' in point ? [point.y, point.y0] : point.y))
      .filter(isNotNull) as number[]

    return {min: Math.min(...values), max: Math.max(...values)}
  }, [chartData])

  const formatDomainValue = (value?: number) =>
    formatLocaleNumber(value, latestDataDetails.fractionDigits)

  const areBoundsDifferent = domain.min !== domain.max

  const ChartComponent =
    healthDataType === HealthDataType.BLOOD_PRESSURE
      ? BloodPressureTrendChart
      : TrendLineChart

  return (
    <Container flex>
      <StyledHealthDataBadge
        {...latestDataDetails}
        healthDataType={healthDataType}
      />
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <DomainValuesContainer>
            <Typography color={theme.colors.grey.main} textAlign="center">
              {formatDomainValue(domain.max)}
            </Typography>
            {areBoundsDifferent && (
              <Typography color={theme.colors.grey.main} textAlign="center">
                {formatDomainValue(domain.min)}
              </Typography>
            )}
          </DomainValuesContainer>
          <ChartBackground>
            <ChartContainer>
              {showEditControls ? (
                <AddTrendDataCard
                  healthDataType={healthDataType as EditableTrendTypes}
                  onUpdate={onUpdate!}
                  onCancel={() => setShowEditControls(false)}
                />
              ) : (
                <ChartComponent
                  data={chartData}
                  fractionDigits={latestDataDetails.fractionDigits}
                  resolution={resolution}
                  startDate={startDate}
                  unit={latestDataDetails.unit}
                />
              )}
            </ChartContainer>
          </ChartBackground>
          {!!onUpdate &&
            isEditableTrendType(healthDataType) &&
            !showEditControls && (
              <Container>
                <IconButton
                  name="Plus"
                  color="secondary"
                  onClick={() => {
                    setShowEditControls(true)
                  }}
                />
              </Container>
            )}
        </>
      )}
    </Container>
  )
}
