import React, {useEffect} from 'react'

import {Icon} from '../icon/Icon'
import {Portal} from './Portal'

import {
  ModalBody,
  ModalContentNormal,
  ModalContentCompact,
  ModalHeaderNormal,
  ModalHeaderCompact,
  StyledHeaderText,
  StyledModal,
} from '~/components/general/modal/FullscreenModal.styled'

type FullscreenModal = {
  children: React.ReactNode
  headerText: string
  onClose: () => void
  isVisible: boolean
  useCompactLayout?: boolean
}

export const FullscreenModal: React.FC<FullscreenModal> = ({
  headerText,
  children,
  onClose,
  isVisible,
  useCompactLayout,
}) => {
  useEffect(() => {
    const handleEscapeClose = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose()
      }
    }

    document.addEventListener('keydown', handleEscapeClose)
    return () => {
      document.removeEventListener('keydown', handleEscapeClose)
    }
  }, [onClose])

  const header = (
    <div>
      <StyledHeaderText size="lg" weight="medium">
        {headerText}
      </StyledHeaderText>
    </div>
  )

  const ModalHeader = useCompactLayout ? ModalHeaderCompact : ModalHeaderNormal
  const ModalContent = useCompactLayout
    ? ModalContentCompact
    : ModalContentNormal

  return isVisible ? (
    <Portal selector="#modal">
      <ModalBody onClick={onClose}>
        <StyledModal onClick={(e) => e.stopPropagation()}>
          {header && (
            <ModalHeader>
              {header}
              <Icon onClick={onClose} circled="md" name="Close" size="sm" />
            </ModalHeader>
          )}
          <ModalContent>{children}</ModalContent>
        </StyledModal>
      </ModalBody>
    </Portal>
  ) : null
}
