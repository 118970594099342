import React, {ReactNode} from 'react'

import {StyledLayoutContainer} from './LayoutContainer.styled'

type LayoutContainerProps = {
  children: ReactNode
  size?: 'normal' | 'small'
}

export const LayoutContainer: React.FC<LayoutContainerProps> = ({
  children,
  size = 'normal',
}) => <StyledLayoutContainer size={size}>{children}</StyledLayoutContainer>
