import * as React from 'react'

export const BannerBgd = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="232"
    height="232"
    viewBox="0 0 232 232"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path d="M116 0H0V116H116V0Z" fill="#002A7E" />
    <path
      d="M0 116C30.7651 116 60.2702 103.779 82.0244 82.0244C103.779 60.2702 116 30.7651 116 0H0"
      fill="#73D1E6"
    />
    <path d="M116 116H0V232H116V116Z" fill="#EEF0F5" />
    <path
      d="M0 116C0 146.765 12.2214 176.27 33.9756 198.024C55.7298 219.779 85.2349 232 116 232V116"
      fill="white"
    />
    <path
      d="M7.62939e-06 116C6.28461e-06 146.765 12.2214 176.27 33.9756 198.024C55.7298 219.779 85.2349 232 116 232L116 116"
      fill="#DCE1ED"
    />
    <path
      d="M196.925 33.2195C160.116 -2.40992 116 0.00308853 116 0.00308853C116 0.00308853 113.048 41.6732 148.047 79.7193C183.046 117.765 231.924 115.955 231.924 115.955C231.924 115.955 233.734 68.8489 196.925 33.2195Z"
      fill="#F56F50"
    />
  </svg>
)
