import * as React from 'react'

import {IconProps} from '.'

function Weight({color, size, isFilled}: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill={isFilled ? color : 'none'}
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.87187 6.66535C1.87187 4.01803 4.01795 1.87195 6.66527 1.87195H13.3347C15.982 1.87195 18.1281 4.01803 18.1281 6.66535V13.3348C18.1281 15.9821 15.982 18.1282 13.3347 18.1282H6.66527C4.01795 18.1282 1.87187 15.9821 1.87187 13.3348V6.66535ZM6.66527 3.12195C4.70831 3.12195 3.12187 4.70838 3.12187 6.66535V13.3348C3.12187 15.2918 4.70831 16.8782 6.66527 16.8782H13.3347C15.2917 16.8782 16.8781 15.2918 16.8781 13.3348V6.66535C16.8781 4.70838 15.2917 3.12195 13.3347 3.12195H6.66527Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.86555 6.45673C7.55321 6.4566 7.25728 6.59654 7.0592 6.83803C6.86114 7.07951 6.78179 7.39706 6.84298 7.70332C6.84298 7.70334 6.84297 7.70329 6.84298 7.70332L7.00971 8.537C7.10714 9.02443 7.53521 9.37533 8.03228 9.37513H11.9675C12.4646 9.37533 12.8929 9.0245 12.9903 8.53707L13.157 7.70339C13.157 7.70337 13.157 7.70341 13.157 7.70339C13.2182 7.39714 13.1389 7.07951 12.9408 6.83803C12.7428 6.59654 12.4468 6.4566 12.1345 6.45673H7.86555ZM7.8658 5.20673C7.179 5.20653 6.52829 5.51428 6.09273 6.0453C5.65711 6.57639 5.48261 7.27483 5.61724 7.94839L5.78397 8.78207C5.78397 8.78205 5.78398 8.78209 5.78397 8.78207C5.99825 9.85392 6.93949 10.6254 8.03253 10.6251C8.03245 10.6251 8.03261 10.6251 8.03253 10.6251H11.9673M7.8658 5.20673L12.134 5.20673C12.1341 5.20673 12.1339 5.20673 12.134 5.20673C12.8208 5.20653 13.4717 5.51428 13.9073 6.0453C14.3429 6.57639 14.5174 7.27483 14.3828 7.94839L14.2161 8.78207C14.2161 8.78209 14.2161 8.78205 14.2161 8.78207C14.0018 9.85392 13.0603 10.6254 11.9673 10.6251"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.2795 7.77376C10.5883 7.92813 10.7134 8.30355 10.559 8.61229L9.72535 10.2796C9.57098 10.5884 9.19556 10.7135 8.88683 10.5592C8.57809 10.4048 8.45295 10.0294 8.60732 9.72063L9.441 8.05327C9.59537 7.74453 9.97079 7.61939 10.2795 7.77376Z"
        fill={color}
      />
    </svg>
  )
}

export default Weight
