import React, {ReactNode} from 'react'

import {LayoutContainer} from './container/LayoutContainer'
import {SideNav} from './sideNavs/sideNav/SideNav'
import {TopBar} from './topBar/TopBar'
import {
  StyledLayout,
  LayoutWrapper,
  LayoutContent,
  SideNavWrapper,
} from './Layout.styled'

type LayoutProps = {
  children: React.ReactNode
  navMenu?: ReactNode
  sideNav?: ReactNode
  rightSide?: ReactNode
  questionnaires?: boolean
  hideSideNavButtons?: boolean
  disableBreadcrumbs?: boolean
}

export const Layout: React.FC<LayoutProps> = ({
  children,
  navMenu,
  sideNav,
  rightSide,
  questionnaires,
  hideSideNavButtons,
  disableBreadcrumbs,
}) => (
  <StyledLayout>
    <SideNav hideButtons={hideSideNavButtons} />
    <LayoutWrapper>
      <TopBar navMenu={navMenu} disableBreadcrumbs={disableBreadcrumbs} />
      <LayoutWrapper inner>
        <SideNavWrapper>{sideNav}</SideNavWrapper>
        <LayoutContent>
          {questionnaires ? (
            children
          ) : (
            <LayoutContainer>{children}</LayoutContainer>
          )}
        </LayoutContent>
      </LayoutWrapper>
    </LayoutWrapper>
    {rightSide}
    <div id="modal" />
  </StyledLayout>
)
