/* tslint:disable */
/* eslint-disable */
/**
 * Cushla API
 * REST API for Cushla
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */

export enum BloodType {
  A_POS = 'A_POS',
  A_NEG = 'A_NEG',
  B_POS = 'B_POS',
  B_NEG = 'B_NEG',
  ZERO_POS = 'ZERO_POS',
  ZERO_NEG = 'ZERO_NEG',
  AB_POS = 'AB_POS',
  AB_NEG = 'AB_NEG',
}
