import styled from 'styled-components'

import {Chip} from '~/components/general/chip/Chip'
import {Typography} from '~/components/general/typography/Typography'

export const StyledTitle = styled(Typography)`
  margin-bottom: ${({theme}) => theme.spacing.lg};
`

export const EmptyTableText = styled(Typography)`
  margin-left: ${({theme}) => theme.spacing.lg};
`

export const SectionWrapper = styled.div`
  margin-bottom: ${({theme}) => theme.spacing.xxl};
`

export const HistoryTableHeaderCellWrap = styled.div`
  height: ${({theme}) => theme.height.tableHeader};
  background-color: ${({theme}) => theme.colors.grey.grey20};
  display: flex;
  align-items: center;
  padding: 0 ${({theme}) => theme.spacing.lg};
  justify-content: center;
  border: 1px solid ${({theme}) => theme.colors.grey.grey50};
  border-bottom-width: 0px;

  *:first-child > & {
    justify-content: flex-start;
  }
`

export const HistoryTableChipWrapper = styled.div`
  display: flex;
`

export const HistoryTableCellChip = styled(Chip)`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: auto;
`
