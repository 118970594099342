import React, {useContext} from 'react'
import {MISSING_ID} from '@common/utils'
import {PermissionCategory} from '@common/models'

import {PatientHeader} from './PatientHeader'
import {PatientDashboardPersonalCard} from './PatientDashboardPersonalCard'
import {PatientDashboardTrendsCard} from './PatientDashboardTrendsCard'
import {PatientDashboardSummaryCard} from './PatientDashboardSummaryCard'
import {PatientDashboardScreeningCard} from './PatientDashboardScreeningCard'

import {PatientsContext} from '~/components/patients/PatientsContext'
import {Container} from '~/components/general/container/Container'
import {Loading} from '~/components/general/loading/Loading'
import {useHealthData} from '~/utils'
import {useGetPatientCriticalNote} from '~/api'
import {useGetPatientHealthJourneys} from '~/api/healthJourneys'

export const PatientDashboard: React.FC = () => {
  const {patient} = useContext(PatientsContext)
  const hasHealthJourneyPermission = !!patient?.permissions.includes(
    PermissionCategory.OBSTETRICS,
  )

  const {
    isLoading: isHealthDataLoading,
    healthSummaryData,
    latestActivities,
  } = useHealthData()
  const {data: criticalNote, isInitialLoading: isCriticalNoteLoading} =
    useGetPatientCriticalNote({patientId: patient?.patientId ?? MISSING_ID})
  const {
    data: patientHealthJourneys,
    isInitialLoading: isHealthJourneysDataLoading,
  } = useGetPatientHealthJourneys(
    {patientId: patient?.patientId ?? MISSING_ID},
    {enabled: hasHealthJourneyPermission},
  )

  const isLoading =
    isHealthDataLoading || isCriticalNoteLoading || isHealthJourneysDataLoading

  if (!patient?.patientId) {
    // Handled in PatientDetail
    return null
  }

  if (isLoading) {
    return <Loading />
  }

  return (
    <Container flex direction="column" spacing={1.5}>
      <PatientHeader patient={patient} criticalNote={criticalNote} />
      <PatientDashboardSummaryCard
        healthJourneysData={patientHealthJourneys}
        healthSummaryData={healthSummaryData}
      />
      <PatientDashboardTrendsCard
        latestActivities={latestActivities}
        lifestyleData={healthSummaryData?.lifestyleInformation}
        patient={patient}
      />
      <Container flex spacing={1.5}>
        <PatientDashboardPersonalCard
          permissions={patient.permissions}
          personalData={healthSummaryData?.personalInformation}
        />
        <PatientDashboardScreeningCard />
      </Container>
    </Container>
  )
}
