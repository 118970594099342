import * as React from 'react'

import {IconProps} from '.'

function Dots({color, size, isFilled}: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill={isFilled ? color : 'none'}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 9.125C8.62132 9.125 9.125 8.62132 9.125 8C9.125 7.37868 8.62132 6.875 8 6.875C7.37868 6.875 6.875 7.37868 6.875 8C6.875 8.62132 7.37868 9.125 8 9.125Z"
        fill={color}
      />
      <path
        d="M2 9.125C2.62132 9.125 3.125 8.62132 3.125 8C3.125 7.37868 2.62132 6.875 2 6.875C1.37868 6.875 0.875 7.37868 0.875 8C0.875 8.62132 1.37868 9.125 2 9.125Z"
        fill={color}
      />
      <path
        d="M14 9.125C14.6213 9.125 15.125 8.62132 15.125 8C15.125 7.37868 14.6213 6.875 14 6.875C13.3787 6.875 12.875 7.37868 12.875 8C12.875 8.62132 13.3787 9.125 14 9.125Z"
        fill={color}
      />
    </svg>
  )
}

export default Dots
