import React, {useContext, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {EmploymentStatus} from '@common/models'

import {Option, Select} from '../general/select/Select'
import {Typography} from '../general/typography/Typography'
import {SelectWrapper} from './ProfilePage.styled'
import {Button} from '../general/button/Button'

import {AuthContext} from '~/auth/AuthContext'
import {PractitionerContext} from '~/components/profile/PractitionerContext'
import {Avatar} from '~/components/general/avatar/Avatar'
import {Layout} from '~/components/layout/Layout'
import {formatName} from '~/utils'
import {Container} from '~/components/general/container/Container'
import {useSetDefaultPatientPage} from '~/api'

const pages = [{title: 'Dashboard'}, {title: 'Timeline'}]

export const ProfilePage: React.FC = () => {
  const {t} = useTranslation()
  const authContext = useContext(AuthContext)
  const {practitioner, selectedEmployment, setSelectedEmployment} =
    useContext(PractitionerContext)
  const practitionerName = formatName(practitioner)

  const [defaultPatientPage, setDefaultPatientPage] = useState(
    practitioner?.defaultPatientPage,
  )

  const {mutate} = useSetDefaultPatientPage()

  const handleDefaultPatientPageChange = (page: string) => {
    setDefaultPatientPage(page)
    mutate({defaultPatientPage: page})
  }

  const approvedEmployments = useMemo(
    () =>
      practitioner?.employments.filter(
        (employment) => employment.status === EmploymentStatus.APPROVED,
      ),
    [practitioner?.employments],
  )

  return (
    <Layout
      navMenu={<Button onClick={authContext.signOut}>{t('Sign out')}</Button>}>
      <Container flex spacing={5} direction="column">
        <Container flex spacing={3} align="center">
          <Avatar size="lg" name={practitioner} />
          <h2>{practitionerName}</h2>
        </Container>
        <Container flex spacing={1.5} direction="column">
          <Container inline spacing={1.5}>
            <Typography weight="medium">{t('Email: ')}</Typography>
            <Typography>{practitioner?.email}</Typography>
          </Container>
          <Container inline spacing={1.5}>
            <Typography weight="medium">{t('Phone: ')}</Typography>
            <Typography>{practitioner?.phoneNumber}</Typography>
          </Container>
        </Container>

        <SelectWrapper flex spacing={2} direction="column">
          <Typography weight="medium">
            {t('Select your employment:')}
          </Typography>
          <Select
            placeholder={t('Select facility')}
            value={selectedEmployment?.facilityName}>
            {approvedEmployments?.map((employment) => (
              <Option
                key={employment.id}
                onClick={() => setSelectedEmployment(employment)}>
                <Typography>{employment.facilityName}</Typography>
              </Option>
            ))}
          </Select>
        </SelectWrapper>

        <SelectWrapper flex spacing={2} direction="column">
          <Typography weight="medium">{t('Default Patient Page:')}</Typography>
          <Select
            placeholder={t('Select default page')}
            value={defaultPatientPage}>
            {pages?.map((page) => (
              <Option
                key={page.title}
                onClick={() => handleDefaultPatientPageChange(page.title)}>
                <Typography>{page.title}</Typography>
              </Option>
            ))}
          </Select>
        </SelectWrapper>
      </Container>
    </Layout>
  )
}
