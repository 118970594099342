import {
  AlcoholDto,
  GetMeasurementsDto,
  SleepOverviewDto,
  TobaccoDto,
} from '@generated/models'

import {GeneralItemType} from './medicalLists'

export type {
  AlcoholDto as Alcohol,
  GetMeasurementsDto as BodyMeasurements,
  SleepOverviewDto as SleepOverview,
  TobaccoDto as TobaccoUsage,
} from '@generated/models'

export {Smoker as IsSmoker, TobaccoType} from '@generated/models'

export type LifestyleInformation = {
  alcohol?: AlcoholDto
  diet?: GeneralItemType
  measurements?: GetMeasurementsDto
  sleepOverview?: SleepOverviewDto
  tobacco?: TobaccoDto
}
