import ReactDatePicker from 'react-datepicker'
import styled from 'styled-components'

export const StyledDatePickerWrapper = styled.div`
  background-color: ${({theme}) => theme.colors.white};
  border: 1px solid ${({theme}) => theme.colors.inputBorder};
  border-radius: ${({theme}) => theme.borderRadius.xs};
  overflow: hidden;
  height: ${({theme}) => theme.height.input.small};
  display: flex;
  align-items: center;
  padding-right: ${({theme}) => theme.spacing.xs};
`

export const StyledDatePicker = styled(ReactDatePicker)`
  height: ${({theme}) => theme.height.input.small};
  line-height: ${({theme}) => theme && theme.lineHeight.lg};
  font-size: ${({theme}) => theme.fontSize.md};
  width: 100%;
  height: 100%;
  padding: 0 ${({theme}) => theme.spacing.md};
  border: 0;
  outline: 0;
`
