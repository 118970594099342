import styled from 'styled-components'

type ItemProp = {
  logo?: boolean
  active?: boolean
}

export const StyledSideNav = styled.div`
  width: 80px;
  height: 100vh;
  position: fixed;
  background-color: #fafbfd;
  flex-shrink: 0;
  border-right: 1px solid ${({theme}) => theme.colors.grey.grey40};
  z-index: 100;
  display: flex;
  flex-direction: column;
`

export const SideNavItem = styled.div<ItemProp>`
  background-color: ${({active, theme}) =>
    active ? theme.colors.white : theme.colors.grey.grey20};
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({logo, theme}) =>
    logo
      ? `border-bottom: 1px solid ${theme.colors.grey.grey40}`
      : `cursor: pointer`};
`

export const Pusher = styled.div`
  margin-bottom: auto;
`
