import * as React from 'react'

import {IconProps} from '.'

function Chart({color, size, isFilled}: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={isFilled ? color : 'none'}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.0019 21.0041L15.0015 17.0024"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.99854 21.0041L8.99895 17.0024"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.928 17.0024H5.0722C3.92573 17.0024 2.99634 16.073 2.99634 14.9266V5.07245C2.99634 3.92598 3.92573 2.99658 5.0722 2.99658H18.927C19.4777 2.99632 20.006 3.21491 20.3955 3.60424C20.785 3.99356 21.0038 4.52172 21.0038 5.07245V14.9256C21.0041 15.4763 20.7855 16.0045 20.3962 16.3941C20.0069 16.7836 19.4787 17.0024 18.928 17.0024Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="7.49829"
        y="10.2495"
        width="3.00125"
        height="3.75156"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="10.4995"
        y="5.99756"
        width="3.00125"
        height="8.00333"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="13.5007"
        y="8.24854"
        width="3.00125"
        height="5.7524"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default Chart
