import {
  AssignedQuestionnaire,
  Question,
  QuestionnaireStatus,
} from '@common/models'
import {useTranslation} from 'react-i18next'
import React, {useContext, useMemo} from 'react'
import {useOutletContext} from 'react-router-dom'
import {formatDate, MISSING_ID} from '@common/utils'
import {QuestionnaireOrder} from '@common/api/types'
import {DateTime} from 'luxon'

import {
  HistoryTableCellChip,
  HistoryTableChipWrapper,
  HistoryTableHeaderCellWrap,
} from './PatientQuestionnaires.styled'

import {useGetDetailedAssignedQuestionnaires} from '~/api'
import {PatientsContext} from '~/components/patients/PatientsContext'
import {LayoutContainer} from '~/components/layout/container/LayoutContainer'
import {
  StyledTable,
  BorderedTableCell,
  TableRow,
  TableHeaderCell,
} from '~/components/general/table/Table.styled'
import {Loading} from '~/components/general/loading/Loading'
import {Typography} from '~/components/general/typography/Typography'
import {SimpleTableHeaderProps} from '~/components/general/table/SimpleTableHeader'
import {theme} from '~/styles/theme'

const LAST_RESPONSES_COUNT = 3

type HistoryTableBodyProps = {
  questions: Question[]
  responses: AssignedQuestionnaire[]
}

const greenAnswerSet = new Set([
  'Yes',
  'None',
  'Not at all',
  'A little bit',
  'Mild',
  'Never',
  'Rarely',
])

const redAnswerSet = new Set([
  'No',
  'Severe',
  'Very severe',
  'Quite a bit',
  'Very much',
  'Frequently',
  'Almost constantly',
])

const yellowAnswerSet = new Set(['Moderate', 'Somewhat', 'Occasionally'])

const getChipColorVariant = (answer?: string | null) => {
  if (!answer) {
    return 'black'
  }
  if (greenAnswerSet.has(answer)) {
    return 'green'
  }
  if (yellowAnswerSet.has(answer)) {
    return 'yellow'
  }
  if (redAnswerSet.has(answer)) {
    return 'red'
  }
  return 'black'
}

const HistoryTableHeader: React.FC<SimpleTableHeaderProps> = ({columns}) => (
  <thead>
    <tr>
      {columns.map((column, index) => (
        <TableHeaderCell
          columnWidthPercent={column.columnWidthPercent}
          key={`${column.title}${index}`}>
          <HistoryTableHeaderCellWrap>
            <Typography color={theme.colors.grey.grey600}>
              {column.title}
            </Typography>
          </HistoryTableHeaderCellWrap>
        </TableHeaderCell>
      ))}
    </tr>
  </thead>
)

const HistoryTableBody: React.FC<HistoryTableBodyProps> = ({
  questions,
  responses,
}) => (
  <tbody>
    {questions.map((question, questionIndex) => (
      <TableRow key={question.id}>
        <BorderedTableCell>
          <Typography
            ellipsis
            color={theme.colors.grey.grey600}
            weight="normal">
            {question.title}
          </Typography>
        </BorderedTableCell>
        {responses.map((response) => (
          <BorderedTableCell key={response.id}>
            <HistoryTableChipWrapper>
              <HistoryTableCellChip
                text={response.answers?.[questionIndex]?.value || '-'}
                color={getChipColorVariant(
                  response.answers?.[questionIndex]?.value,
                )}
              />
            </HistoryTableChipWrapper>
          </BorderedTableCell>
        ))}
      </TableRow>
    ))}
  </tbody>
)

const PatientQuestionnaireHistoryContent: React.FC = () => {
  const {t} = useTranslation()
  const contextData = useOutletContext<AssignedQuestionnaire>()
  const {patient} = useContext(PatientsContext)

  const {data, isInitialLoading} = useGetDetailedAssignedQuestionnaires({
    facilityId: patient?.facilityId ?? MISSING_ID,
    questionnaireId: contextData.questionnaire.id,
    orderBy: QuestionnaireOrder.DATE_COMPLETED,
    perPage: LAST_RESPONSES_COUNT,
    questionnaireStatus: QuestionnaireStatus.COMPLETED,
  })

  const reversedResponses = useMemo(
    () => (data?.records ?? []).reverse(),
    [data?.records],
  )

  const tableHeader = useMemo(
    () =>
      [
        {
          title: t('Question'),
          columnWidthPercent: 50,
        },
      ].concat(
        reversedResponses.map((response) => ({
          title: formatDate(DateTime.DATE_MED, response.dateCompleted),
          columnWidthPercent: 15,
        })) ?? [],
      ),
    [t, reversedResponses],
  )

  if (isInitialLoading) {
    return <Loading />
  }

  if (!reversedResponses.length) {
    return <Typography>{t('No responses yet')}</Typography>
  }

  return (
    <StyledTable>
      <HistoryTableHeader columns={tableHeader} />
      <HistoryTableBody
        questions={contextData.questionnaire.questions}
        responses={reversedResponses}
      />
    </StyledTable>
  )
}

export const PatientQuestionnaireHistory: React.FC = () => (
  <LayoutContainer>
    <PatientQuestionnaireHistoryContent />
  </LayoutContainer>
)
