import React from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigate, useParams} from 'react-router-dom'
import {MISSING_ID, formatDate} from '@common/utils'
import {DateTime} from 'luxon'

import {PatientDashboardCard} from './PatientDashboardCard'
import {
  StyledDashboardInnerCard,
  StyledDashboardInnerCardTable,
  StyledDashboardInnerCardTableCell,
} from './PatientDashboardCard.styled'

import {Typography} from '~/components/general/typography/Typography'
import {theme} from '~/styles/theme'
import {Button} from '~/components/general/button/Button'
import {useFeatureFlags} from '~/components/general/FeatureFlagContext'
import {useGetPreventiveActivities} from '~/api/practitioner'
import {Loading} from '~/components/general/loading/Loading'
import {isActivityOkay} from '~/utils/preventive'

export const PatientDashboardScreeningCard = () => {
  const {t} = useTranslation()
  const navigate = useNavigate()
  const {patientId} = useParams<{patientId: string}>()
  const {preventiveMeasuresEnabled} = useFeatureFlags()

  const {data: activities, isInitialLoading} = useGetPreventiveActivities({
    patientId: patientId ?? MISSING_ID,
  })

  const cardData = React.useMemo(() => {
    if (!activities) return []

    // Filter activities that need attention and sort by due date
    const activitiesNeedingAttention = activities
      .filter((activity) => !isActivityOkay(activity))
      .sort(
        (a, b) => new Date(a.dueDate).getTime() - new Date(b.dueDate).getTime(),
      )
      .slice(0, 4) // Limit to 4 activities

    return activitiesNeedingAttention.map((activity) => ({
      title: activity.description,
      values: activity.dueDate
        ? formatDate(DateTime.DATE_SHORT, activity.dueDate)
        : '-',
      isOkay: false,
    }))
  }, [activities])

  const totalActivitiesNeedingAttention = React.useMemo(() => {
    if (!activities) return 0
    return activities.filter((activity) => !isActivityOkay(activity)).length
  }, [activities])

  if (!preventiveMeasuresEnabled) return null

  if (isInitialLoading) {
    return <Loading />
  }

  return (
    <PatientDashboardCard
      title={t('Preventive Activities')}
      topRightComponent={
        <Button color="primary" onClick={() => navigate('../preventive')}>
          {t('Go to Preventive Activities')}
          {totalActivitiesNeedingAttention > 0 &&
            ` (${totalActivitiesNeedingAttention})`}
        </Button>
      }>
      <StyledDashboardInnerCard>
        <StyledDashboardInnerCardTable>
          <tbody>
            {cardData.length > 0 ? (
              cardData.map((section) => (
                <tr key={section.title}>
                  <StyledDashboardInnerCardTableCell>
                    <Typography weight="medium" color={theme.colors.grey.main}>
                      {section.title}
                    </Typography>
                  </StyledDashboardInnerCardTableCell>
                  <StyledDashboardInnerCardTableCell>
                    <Typography color={theme.colors.grey.main}>
                      {section.values}
                    </Typography>
                  </StyledDashboardInnerCardTableCell>
                </tr>
              ))
            ) : (
              <tr>
                <StyledDashboardInnerCardTableCell colSpan={2}>
                  <Typography>
                    {t('No preventive activities requiring attention')}
                  </Typography>
                </StyledDashboardInnerCardTableCell>
              </tr>
            )}
          </tbody>
        </StyledDashboardInnerCardTable>
      </StyledDashboardInnerCard>
    </PatientDashboardCard>
  )
}
