import {MedicalRecordPermission} from '@generated/models'

export type MedicalPermissionSubcategory =
  | MedicalRecordPermission.ALLERGY
  | MedicalRecordPermission.FAMILY_HISTORY
  | MedicalRecordPermission.MEDICATION
  | MedicalRecordPermission.SURGERY
  | MedicalRecordPermission.VACCINATION
  | MedicalRecordPermission.DIAGNOSED_CONDITIONS
  | MedicalRecordPermission.OBSTETRICS
  | MedicalRecordPermission.MEDICAL_TEST
  | MedicalRecordPermission.BLOOD_TYPE
  | MedicalRecordPermission.CHOLESTEROL

export const MedicalPermissionSubcategory: {
  [key in MedicalPermissionSubcategory]: key
} = {
  [MedicalRecordPermission.ALLERGY]: MedicalRecordPermission.ALLERGY,
  [MedicalRecordPermission.FAMILY_HISTORY]:
    MedicalRecordPermission.FAMILY_HISTORY,
  [MedicalRecordPermission.MEDICATION]: MedicalRecordPermission.MEDICATION,
  [MedicalRecordPermission.SURGERY]: MedicalRecordPermission.SURGERY,
  [MedicalRecordPermission.VACCINATION]: MedicalRecordPermission.VACCINATION,
  [MedicalRecordPermission.DIAGNOSED_CONDITIONS]:
    MedicalRecordPermission.DIAGNOSED_CONDITIONS,
  [MedicalRecordPermission.OBSTETRICS]: MedicalRecordPermission.OBSTETRICS,
  [MedicalRecordPermission.MEDICAL_TEST]: MedicalRecordPermission.MEDICAL_TEST,
  [MedicalRecordPermission.BLOOD_TYPE]: MedicalRecordPermission.BLOOD_TYPE,
  [MedicalRecordPermission.CHOLESTEROL]: MedicalRecordPermission.CHOLESTEROL,
}

export type MyProfilePermissionSubcategory =
  | MedicalRecordPermission.ADDRESS
  | MedicalRecordPermission.CONTACT_DETAILS
  | MedicalRecordPermission.DEMOGRAPHIC_DETAILS
  | MedicalRecordPermission.HEALTH_INSURANCE
  | MedicalRecordPermission.NAME_DETAILS
  | MedicalRecordPermission.NEXT_OF_KIN
  | MedicalRecordPermission.ORGAN_DONOR

export const MyProfilePermissionSubcategory: {
  [key in MyProfilePermissionSubcategory]: key
} = {
  [MedicalRecordPermission.ADDRESS]: MedicalRecordPermission.ADDRESS,
  [MedicalRecordPermission.CONTACT_DETAILS]:
    MedicalRecordPermission.CONTACT_DETAILS,
  [MedicalRecordPermission.DEMOGRAPHIC_DETAILS]:
    MedicalRecordPermission.DEMOGRAPHIC_DETAILS,
  [MedicalRecordPermission.HEALTH_INSURANCE]:
    MedicalRecordPermission.HEALTH_INSURANCE,
  [MedicalRecordPermission.NAME_DETAILS]: MedicalRecordPermission.NAME_DETAILS,
  [MedicalRecordPermission.NEXT_OF_KIN]: MedicalRecordPermission.NEXT_OF_KIN,
  [MedicalRecordPermission.ORGAN_DONOR]: MedicalRecordPermission.ORGAN_DONOR,
}

export type BasePermissionCategory = Exclude<
  MedicalRecordPermission,
  MyProfilePermissionSubcategory | MedicalPermissionSubcategory
>

export const BasePermissionCategory: {
  [key in BasePermissionCategory]: key
} = {
  [MedicalRecordPermission.ACTIVITY]: MedicalRecordPermission.ACTIVITY,
  [MedicalRecordPermission.LIFESTYLE_INFORMATION]:
    MedicalRecordPermission.LIFESTYLE_INFORMATION,
  [MedicalRecordPermission.PERSONAL_INFORMATION]:
    MedicalRecordPermission.PERSONAL_INFORMATION,
  [MedicalRecordPermission.LIFE_MILESTONES]:
    MedicalRecordPermission.LIFE_MILESTONES,
  [MedicalRecordPermission.APPOINTMENT]: MedicalRecordPermission.APPOINTMENT,
  [MedicalRecordPermission.PHYSITRACK_PROGRAMS]:
    MedicalRecordPermission.PHYSITRACK_PROGRAMS,
  [MedicalRecordPermission.HEALTH_NETWORK]:
    MedicalRecordPermission.HEALTH_NETWORK,
}

export enum ParentPermissionCategory {
  MEDICAL = 'MEDICAL',
  MY_PROFILE = 'MY_PROFILE',
}

export type GeneralPermissionCategory =
  | MedicalRecordPermission
  | ParentPermissionCategory

export const ParentSubcategoryMap = {
  [ParentPermissionCategory.MEDICAL]: MedicalPermissionSubcategory,
  [ParentPermissionCategory.MY_PROFILE]: MyProfilePermissionSubcategory,
}

export const AllPermissionCategories = {
  ...ParentPermissionCategory,
  ...MedicalRecordPermission,
}

export type PermissionSelection = {
  [key in MedicalRecordPermission]: boolean
}

export type {
  HealthDataRequest as DataPermissionsRequest,
  HealthServicePermissionRequest as PatientAddPermissionsRequest,
  LinkSharingDto as SharingLink,
  LinkSharingRequest as SharingLinkRequest,
  LinkValidationResponse,
} from '@generated/models'

export {
  LinkStatus as SharingLinkStatus,
  MedicalRecordPermission as PermissionCategory,
} from '@generated/models'
