import styled from 'styled-components'

import {theme as globalTheme} from '~/styles/theme'

type TextAreaProps = {
  fontSize: keyof typeof globalTheme.fontSize
}

type TextAreaWrapperProps = {
  height?: string
}

export const StyledTextArea = styled.textarea<TextAreaProps>`
  background: none;
  display: block;
  width: 100%;
  font-size: ${({fontSize, theme}) => theme && theme.fontSize[fontSize]};
  line-height: ${({fontSize, theme}) => theme && theme.lineHeight[fontSize]};
  color: ${({theme, color, disabled}) =>
    disabled ? theme.colors.grey.main : color ?? theme.colors.inputText};

  background-color: ${({disabled, theme}) =>
    disabled ? theme.colors.grey.grey20 : theme.colors.white};
  border: 0;
  outline: 0;
  resize: none;

  &::placeholder {
    color: ${({theme}) => theme.colors.inputPlaceholder};
  }
`

export const TextAreaWrapper = styled.div<TextAreaWrapperProps>`
  overflow: hidden;
  position: relative;
  width: 100%;
  height: ${({height}) => height};
  display: inline-flex;
  position: relative;
  border: 1px solid ${({theme}) => theme.colors.inputBorder};
  border-radius: ${({theme}) => theme.borderRadius.xs};
  cursor: text;

  ${StyledTextArea} {
    padding: 15px;
  }
`
