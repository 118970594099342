import styled from 'styled-components'

import {Typography} from '~/components/general/typography/Typography'

type PaginationCol = {
  justify?: 'flex-start' | 'center' | 'flex-end'
  spaceBetween?: 'xxs' | 'xs'
}

export const TablePaginationWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${({theme}) => theme.spacing.lg};
  padding: 0 ${({theme}) => theme.spacing.lg};
`

export const TablePaginationCol = styled.div<PaginationCol>`
  width: calc(100% / 3);
  display: flex;
  align-items: center;
  justify-content: ${({justify}) => justify};
  color: ${({theme}) => theme.colors.grey.grey600};

  & > * {
    ${({theme, spaceBetween}) =>
      spaceBetween && `margin-right: ${theme.spacing[spaceBetween]}`};
  }

  & > *:last-child {
    ${({spaceBetween}) => spaceBetween && `margin-right: 0`};
  }
`

export const CurrentPageNumber = styled.div`
  width: ${({theme}) => theme.spacing.xl};
  height: ${({theme}) => theme.spacing.xl};
  background-color: ${({theme}) => theme.colors.grey.grey20};
  border-radius: ${({theme}) => theme.borderRadius.xs};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: ${({theme}) => theme.spacing.xs};
`

export const NoResultsText = styled(Typography)`
  margin: ${({theme}) => theme.spacing.lg};
`
