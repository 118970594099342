import React from 'react'

import {
  QuestionnairePreviewAnswerWrap,
  QuestionnairePreviewCard,
  SliderStyledText,
  StyledSlider,
} from './QuestionnairePreview.styled'
import {QuestionProps} from './QuestionCard'

import {Typography} from '~/components/general/typography/Typography'
import {theme} from '~/styles/theme'

export const SliderQuestion: React.FC<QuestionProps> = ({question, answer}) => {
  const minimumValue = question.minimumValue ?? 1
  const maximumValue = question.maximumValue ?? minimumValue + 9
  const value =
    typeof answer === 'number' ? answer : (maximumValue + minimumValue) / 2

  return (
    <QuestionnairePreviewCard>
      <Typography weight="medium">{question.title}</Typography>
      <Typography>{question.subtitle}</Typography>
      <QuestionnairePreviewAnswerWrap>
        <Typography color={theme.colors.grey.main}>{minimumValue}</Typography>
        <StyledSlider
          type="range"
          min={minimumValue}
          max={maximumValue}
          value={value}
          disabled
        />
        <Typography color={theme.colors.grey.main}>{maximumValue}</Typography>
      </QuestionnairePreviewAnswerWrap>
      {typeof answer === 'number' && (
        <SliderStyledText weight="medium">{answer}</SliderStyledText>
      )}
    </QuestionnairePreviewCard>
  )
}
