import React, {useMemo} from 'react'

import {Portal} from '~/components/general/modal/Portal'
import {
  ModalBody,
  ModalWrapper,
  StyledModal,
} from '~/components/general/modal/Modal.styled'
import {useKeyPress} from '~/utils'

type BaseModalProps = {
  children: React.ReactNode
  onClose?: () => void
  isVisible: boolean
}

export const BaseModal: React.FC<BaseModalProps> = ({
  children,
  onClose,
  isVisible,
}) => {
  const keyPressMap = useMemo(
    () => ({
      Escape: onClose,
    }),
    [onClose],
  )

  useKeyPress(keyPressMap)

  if (!isVisible) {
    return null
  }

  return (
    <Portal selector="#modal">
      <ModalBody onClick={onClose}>
        <ModalWrapper>
          <StyledModal onClick={(e) => e.stopPropagation()}>
            {children}
          </StyledModal>
        </ModalWrapper>
      </ModalBody>
    </Portal>
  )
}
