import {useCallback, useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {
  CaresForOption,
  HomeSharedWithOption,
  PermissionCategory,
  PersonalInformation,
} from '../../models'
import {enumerateListItems, isTruthy} from '../helpers'
import {useEnumTranslations} from '../translations'
import {EnumType} from '../types'

export const usePersonalCardData = ({
  data,
  permissions,
}: {
  data?: PersonalInformation | null
  permissions?: PermissionCategory[]
}) => {
  const {t} = useTranslation()
  const {translateEnumValue} = useEnumTranslations()

  const livingSituationData = data?.livingSituation

  const getCardItemValues = useCallback(
    (items: (string | null)[], permission: PermissionCategory) => {
      if (permissions && !permissions.includes(permission)) {
        return t('Not shared')
      }

      const filteredItems = items.filter(isTruthy)
      return filteredItems.length ? filteredItems : t('Not entered')
    },
    [t, permissions],
  )

  const getHomeSharedWithText = useCallback(
    (homeSharedWith?: HomeSharedWithOption[] | null) => {
      if (!homeSharedWith?.length) {
        return null
      }

      if (
        homeSharedWith.length === 1 &&
        homeSharedWith[0] === HomeSharedWithOption.ALONE
      ) {
        return t('Living alone')
      }

      const livingWithText = homeSharedWith.includes(HomeSharedWithOption.ALONE)
        ? t('Living alone with ')
        : t('Living with ')

      const formatedHomeSharedValues = homeSharedWith
        .filter((value) => value !== HomeSharedWithOption.ALONE)
        .map((person) =>
          translateEnumValue(
            EnumType.HomeSharedWithOption,
            person,
          ).toLowerCase(),
        )

      return livingWithText.concat(
        enumerateListItems(formatedHomeSharedValues, t('and')),
      )
    },
    [t, translateEnumValue],
  )

  const getCaresForText = useCallback(
    (caresFor?: CaresForOption[] | null) => {
      if (!caresFor?.length) {
        return t('Not a carer')
      }

      const persons = caresFor.map((person) =>
        translateEnumValue(EnumType.CaresForOption, person).toLowerCase(),
      )

      return t('Cares for {{persons}}', {
        persons: enumerateListItems(persons, t('and')),
      })
    },
    [t, translateEnumValue],
  )

  return useMemo(
    () => [
      {
        title: t('Marital status'),
        values: getCardItemValues(
          [
            translateEnumValue(
              EnumType.MaritalStatus,
              data?.personalNetwork?.maritalStatus,
            ),
          ],
          PermissionCategory.PERSONAL_INFORMATION,
        ),
      },
      {
        title: t('Children'),
        values: getCardItemValues(
          [data?.personalNetwork?.children?.length?.toLocaleString() ?? null],
          PermissionCategory.PERSONAL_INFORMATION,
        ),
      },
      {
        title: t('Cares for'),
        values: getCardItemValues(
          [getCaresForText(data?.personalNetwork?.caresFor)],
          PermissionCategory.PERSONAL_INFORMATION,
        ),
      },
      {
        title: t('Living situation'),
        values: getCardItemValues(
          [
            translateEnumValue(
              EnumType.HomeType,
              livingSituationData?.homeType,
            ),
            getHomeSharedWithText(livingSituationData?.homeSharedWith),
          ],
          PermissionCategory.PERSONAL_INFORMATION,
        ),
      },
    ],
    [
      data,
      getCardItemValues,
      getCaresForText,
      getHomeSharedWithText,
      livingSituationData,
      t,
      translateEnumValue,
    ],
  )
}
