import React from 'react'

import {Typography} from '../typography/Typography'
import {StyledPopupMenu, StyledPopupMenuItem} from './Popup.styled'

import {theme} from '~/styles/theme'

export type PopupMenuItem = {
  label: string
  onClick: () => void
}

export type PopupMenuProps = {
  menuItems: PopupMenuItem[]
}

export const PopupMenu: React.FC<PopupMenuProps> = ({menuItems}) => {
  if (!menuItems.length) {
    return null
  }

  return (
    <StyledPopupMenu>
      {menuItems.map((item) => (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
        <StyledPopupMenuItem
          key={item.label}
          onClick={() => {
            item.onClick()
          }}>
          <Typography color={theme.colors.primary.blue900}>
            {item.label}
          </Typography>
        </StyledPopupMenuItem>
      ))}
    </StyledPopupMenu>
  )
}
