import {useTranslation} from 'react-i18next'
import {TFunction} from 'i18next'

import {Alcohol, IsSmoker, TobaccoUsage} from '../models'
import {formatLocaleNumber, isTruthy} from './helpers'

export const alcoholVolumes = {
  beerBottle: '330 ml',
  beerPint: '500 ml',
  wineBottle: '750 ml',
  wineGlass: '175 ml',
  spiritShot: '35.5 ml',
  spiritBottle: '700 ml',
  cocktail: '250 ml',
}

export const alcoholPercentages = {
  beerBottle: '4.5%',
  beerPint: '4.5%',
  wineBottle: '14%',
  wineGlass: '14%',
  spiritShot: '40%',
  spiritBottle: '40%',
  cocktail: null,
}

export const alcoholUnits = {
  beerBottle: 1.485,
  beerPint: 2.25,
  wineBottle: 10.5,
  wineGlass: 2.45,
  spiritShot: 1.42,
  spiritBottle: 28,
  cocktail: 2,
}

export const calculateAlcoholUnits = (alcohol: Alcohol | null | undefined) => {
  if (
    alcohol == null ||
    Object.values(alcohol).every((value) => value == null) // when all values are undefined, the alcohol data were not filled in yet
  ) {
    return undefined
  }

  return Object.keys(alcohol).reduce((sum, currentKey) => {
    const key = currentKey as keyof Alcohol
    return sum + alcoholUnits[key] * (alcohol[key] ?? 0)
  }, 0)
}

export const useAlcoholConsumptionTexts = (alcohol?: Alcohol | null) => {
  const {t} = useTranslation()

  if (!alcohol) {
    return []
  }

  const {
    beerBottle,
    beerPint,
    wineBottle,
    wineGlass,
    spiritShot,
    spiritBottle,
    cocktail,
  } = alcohol

  return [
    beerBottle
      ? t('{{count}} bottle of beer | {{volume}}', {
          count: beerBottle,
          volume: alcoholVolumes.beerBottle,
        })
      : null,
    beerPint
      ? t('{{count}} pint of beer | {{volume}}', {
          count: beerPint,
          volume: alcoholVolumes.beerPint,
        })
      : null,
    wineBottle
      ? t('{{count}} bottle of wine | {{volume}}', {
          count: wineBottle,
          volume: alcoholVolumes.wineBottle,
        })
      : null,
    wineGlass
      ? t('{{count}} glass of wine | {{volume}}', {
          count: wineGlass,
          volume: alcoholVolumes.wineGlass,
        })
      : null,
    spiritShot
      ? t('{{count}} shot of spirit | {{volume}}', {
          count: spiritShot,
          volume: alcoholVolumes.spiritShot,
        })
      : null,
    spiritBottle
      ? t('{{count}} bottle of spirit | {{volume}}', {
          count: spiritBottle,
          volume: alcoholVolumes.spiritBottle,
        })
      : null,
    cocktail
      ? t('{{count}} glass of cocktail | {{volume}}', {
          count: cocktail,
          volume: alcoholVolumes.cocktail,
        })
      : null,
  ].filter(isTruthy)
}

export const formatTobaccoText = (
  t: TFunction,
  tobacco?: TobaccoUsage | null,
) => {
  if (!tobacco?.smoker) {
    return undefined
  }
  if (tobacco.smoker === IsSmoker.NEVER) {
    return t('Not smoking')
  }
  if (tobacco.smoker === IsSmoker.NOT_ANYMORE) {
    return t('No longer smoking')
  }
  return tobacco.amount == null
    ? t('Smoker')
    : t('{{amount}} per day', {amount: tobacco.amount})
}

export const formatAlcoholText = (
  t: TFunction,
  alcohol: Alcohol | null | undefined,
) => {
  const units = calculateAlcoholUnits(alcohol)

  if (units == null) {
    return undefined
  }
  return t(`{{count}} unit`, {
    count: Math.round(units),
  })
}

export const formatWaterConsumptionText = (
  t: TFunction,
  waterConsumption?: number | null,
) =>
  waterConsumption == null
    ? null
    : t('{{amount}} ml', {
        amount: formatLocaleNumber(waterConsumption, 1),
      })

export const formatBloodPressure = (
  diastolicPressure?: number | null,
  systolicPressure?: number | null,
) =>
  diastolicPressure && systolicPressure
    ? `${systolicPressure} / ${diastolicPressure} mm/Hg`
    : undefined

export const formatHeartRate = (heartRate?: number | null) =>
  heartRate ? `${formatLocaleNumber(heartRate, 0)} bpm` : undefined
