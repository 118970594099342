import React from 'react'

import {CircledBgd, IconCircleSize, IconSize, IconWrap} from './Icon.styled'

import {iconNameMap} from '~/icons'
import {theme} from '~/styles/theme'

export type IconName = keyof typeof iconNameMap

const iconSizeMap: Record<IconSize, number> = {
  xxs: 8,
  xs: 12,
  sm: 16,
  md: 20,
  lg: 24,
  xl: 40,
}

export type IconProps = {
  name: IconName
  circled?: IconCircleSize
  circledBgd?: string
  className?: string
  color?: string
  flip?: boolean
  onClick?: React.MouseEventHandler
  size?: IconSize
  title?: string
  isFilled?: boolean
}

export const Icon: React.FC<IconProps> = ({
  name,
  circled,
  circledBgd = theme.colors.grey.grey50,
  className,
  color = theme.colors.primary.main,
  flip,
  onClick,
  size = 'lg',
  title,
  isFilled,
}) => {
  const Component = iconNameMap[name]

  return circled ? (
    <CircledBgd
      title={title}
      className={className}
      circled={circled}
      circledBgd={circledBgd}
      flip={flip}
      onClick={onClick}
      clickable={Boolean(onClick)}>
      <Component color={color} size={iconSizeMap[size]} />
    </CircledBgd>
  ) : (
    <IconWrap
      title={title}
      onClick={onClick}
      flip={flip}
      clickable={Boolean(onClick)}
      className={className}>
      <Component color={color} size={iconSizeMap[size]} isFilled={isFilled} />
    </IconWrap>
  )
}
