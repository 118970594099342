import {
  endpointKeys,
  LifeMilestonesUrlParams,
  PatientIdUrlParam,
} from '@common/api'
import {
  AddressDetails,
  ContactDetails,
  DemographicDetails,
  InsuranceDetails,
  LifeMilestone,
  NameDetails,
  NextOfKinDetails,
  OrganDonorConsentDetails,
  PagedResponse,
  PersonalInformation,
} from '@common/models'
import {
  addressDetailsNormaliser,
  contactDetailsNormaliser,
  demographicDetailsNormaliser,
  getPagedResponseNormaliser,
  insuranceDetailsNormaliser,
  lifeMilestoneNormaliser,
  nameDetailsNormaliser,
  nextOfKinDetailsNormaliser,
  organDonorConsentNormaliser,
  personalInformationOrNullNormaliser,
} from '@common/normalisers'
import {UseInfiniteQueryOptions} from '@tanstack/react-query'

import {useApi, useWebGet, useWebInfiniteGet} from './helpers'

export const useGetPatientPersonalInformation = (params: PatientIdUrlParam) =>
  useWebGet<PersonalInformation | null>(
    [endpointKeys.patientPersonalInformation, params],
    useApi().patientPersonalInformation(params),
    personalInformationOrNullNormaliser,
  )

export const useGetPatientContactDetails = (params: PatientIdUrlParam) =>
  useWebGet<ContactDetails>(
    [endpointKeys.contactDetails, params],
    useApi().contactDetails(params),
    contactDetailsNormaliser,
  )

export const useGetPatientAddress = (params: PatientIdUrlParam) =>
  useWebGet<AddressDetails>(
    [endpointKeys.addresses, params],
    useApi().addresses(params),
    addressDetailsNormaliser,
  )

export const useGetPatientNextOfKinDetails = (params: PatientIdUrlParam) =>
  useWebGet<NextOfKinDetails>(
    [endpointKeys.nextOfKinDetails, params],
    useApi().nextOfKinDetails(params),
    nextOfKinDetailsNormaliser,
  )

export const useGetPatientNameDetails = (params: PatientIdUrlParam) =>
  useWebGet<NameDetails>(
    [endpointKeys.nameDetails, params],
    useApi().nameDetails(params),
    nameDetailsNormaliser,
  )

export const useGetPatientDemographicDetails = (params: PatientIdUrlParam) =>
  useWebGet<DemographicDetails>(
    [endpointKeys.demographicDetails, params],
    useApi().demographicDetails(params),
    demographicDetailsNormaliser,
  )

export const useGetPatientOrganDonorConsent = (params: PatientIdUrlParam) =>
  useWebGet<OrganDonorConsentDetails>(
    [endpointKeys.organDonor, params],
    useApi().organDonor(params),
    organDonorConsentNormaliser,
  )

export const useGetPatientInsuranceDetails = (params: PatientIdUrlParam) =>
  useWebGet<InsuranceDetails>(
    [endpointKeys.insuranceDetails, params],
    useApi().insuranceDetails(params),
    insuranceDetailsNormaliser,
  )

export const useGetPatientLifeMilestones = (params: LifeMilestonesUrlParams) =>
  useWebGet<PagedResponse<LifeMilestone>>(
    [endpointKeys.lifeMilestones, params],
    useApi().lifeMilestones(params),
    getPagedResponseNormaliser(lifeMilestoneNormaliser),
  )

export const useInfiniteGetPatientLifeMilestones = (
  params: LifeMilestonesUrlParams,
  options?: UseInfiniteQueryOptions<PagedResponse<LifeMilestone>, Error>,
) =>
  useWebInfiniteGet<PagedResponse<LifeMilestone>>(
    [endpointKeys.lifeMilestones, params],
    useApi().lifeMilestones(params),
    getPagedResponseNormaliser(lifeMilestoneNormaliser),
    options,
  )
