import * as React from 'react'

import {IconProps} from '.'

function UserActive({color, size, isFilled}: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={isFilled ? color : 'none'}
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1354_10039)">
        <path
          d="M20.7543 7.01734C20.6146 6.45831 20.044 6.12407 19.4813 6.24841C17.1136 6.77161 14.4692 7 12 7C9.53076 7 6.88641 6.77161 4.51866 6.24841C3.956 6.12407 3.38542 6.45831 3.24566 7.01734C3.10929 7.56283 3.43481 8.1185 3.98311 8.24311C4.66245 8.39749 5.36716 8.53133 6.07931 8.64504C7.7226 8.90743 9 10.2787 9 11.9428V21C9 21.5523 9.44772 22 10 22C10.5523 22 11 21.5523 11 21V17C11 16.4477 11.4477 16 12 16C12.5523 16 13 16.4477 13 17V21C13 21.5523 13.4477 22 14 22C14.5523 22 15 21.5523 15 21V11.9428C15 10.2787 16.2774 8.90743 17.9207 8.64504C18.6328 8.53133 19.3376 8.39749 20.0169 8.24311C20.5652 8.1185 20.8907 7.56283 20.7543 7.01734ZM12 6C13.1 6 14 5.1 14 4C14 2.9 13.1 2 12 2C10.9 2 10 2.9 10 4C10 5.1 10.9 6 12 6Z"
          fill={color}
        />
      </g>
    </svg>
  )
}

export default UserActive
