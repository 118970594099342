import React from 'react'
import {createRoot} from 'react-dom/client'
import {PublicClientApplication} from '@azure/msal-browser'
import {MsalProvider} from '@azure/msal-react'
import {QueryClient, QueryClientProvider} from '@tanstack/react-query'
import {BrowserRouter} from 'react-router-dom'
import {ThemeProvider} from 'styled-components'

import {msalConfig} from './auth/AuthConfig'
import {App} from './App'
import {AuthContextProvider} from './auth/AuthContextProvider'
import initializeI18Next from '../i18n'
import {GlobalStyle} from './styles/GlobalStyle.styled'
import {theme} from './styles/theme'
import {NotificationContextProvider} from './components/notifications/NotificationContext'

initializeI18Next()

const msalInstance = new PublicClientApplication(msalConfig)
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      keepPreviousData: true,
      staleTime: 1000 * 60 * 50, // XXX: DON'T LEAVE THIS CHANGE HERE!!!
    },
  },
})

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(document.getElementById('app')!)

root.render(
  <ThemeProvider theme={theme}>
    <MsalProvider instance={msalInstance}>
      <QueryClientProvider client={queryClient}>
        <AuthContextProvider>
          <NotificationContextProvider>
            <BrowserRouter>
              <GlobalStyle />
              <App />
            </BrowserRouter>
          </NotificationContextProvider>
        </AuthContextProvider>
      </QueryClientProvider>
    </MsalProvider>
  </ThemeProvider>,
)
