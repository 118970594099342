import {BasePermissionCategory, LifeMilestone} from '@common/models'
import {
  capitalizeFirstLetter,
  EnumType,
  formatExactOrEstimatedDate,
  MISSING_ID,
  useEnumTranslations,
} from '@common/utils'
import React, {useContext, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {HealthRecordModal} from '../common/healthRecordModal/HealthRecordModal'
import {PatientsContext} from '../PatientsContext'

import {useGetPatientLifeMilestones} from '~/api'
import {Loading} from '~/components/general/loading/Loading'
import {SimplePaginatedTable} from '~/components/general/table/SimplePaginatedTable'
import {PermissionCheck} from '~/components/patients/common/requestData/PermissionCheck'
import {useModalData} from '~/utils'

const LifeMilestonesPageContent: React.FC = () => {
  const {t} = useTranslation()
  const {translateEnumValue} = useEnumTranslations()
  const {patient} = useContext(PatientsContext)
  const {getLifeMilestoneModalData} = useModalData()

  const [currentPageIndex, setCurrentPageIndex] = useState(0)
  const [isMilestoneModalVisible, setIsMilestoneModalVisible] = useState(false)
  const [selectedMilestone, setSelectedMilestone] = useState<LifeMilestone>()

  const {data, isInitialLoading, isFetching} = useGetPatientLifeMilestones({
    patientId: patient?.patientId ?? MISSING_ID,
    page: currentPageIndex,
  })

  const headerData = useMemo(
    () => ({
      columns: [
        {title: t('Milestone'), columnWidthPercent: 30},
        {title: t('Date'), columnWidthPercent: 20},
        {title: t('Impact'), columnWidthPercent: 20},
        {title: t('Description'), columnWidthPercent: 30},
      ],
    }),
    [t],
  )

  const rowsData = useMemo(
    () =>
      data?.records?.map((milestone) => ({
        columns: [
          capitalizeFirstLetter(milestone.milestone) ||
            translateEnumValue(EnumType.LifeMilestoneType, milestone.type),
          formatExactOrEstimatedDate(milestone.date) || '-',
          translateEnumValue(EnumType.LifeMilestoneImpact, milestone.impact) ||
            '-',
          milestone.description || '-',
        ],
        onRowClick: () => {
          setSelectedMilestone(milestone)
          setIsMilestoneModalVisible(true)
        },
      })) ?? [],
    [data, translateEnumValue],
  )

  const selectedMilestoneModalData = useMemo(
    () => selectedMilestone && getLifeMilestoneModalData(selectedMilestone),
    [getLifeMilestoneModalData, selectedMilestone],
  )

  if (isInitialLoading) {
    return <Loading />
  }

  return (
    <>
      <SimplePaginatedTable
        header={headerData}
        rows={rowsData}
        isLoading={isFetching}
        onPageClick={setCurrentPageIndex}
        paginationData={data?.page}
        showSourceIcons
      />
      <HealthRecordModal
        isVisible={isMilestoneModalVisible}
        onClose={() => setIsMilestoneModalVisible(false)}
        data={selectedMilestoneModalData}
        fileEndpoint="medicalRecord"
      />
    </>
  )
}

export const LifeMilestonesPage: React.FC = () => (
  <PermissionCheck permission={BasePermissionCategory.LIFE_MILESTONES}>
    <LifeMilestonesPageContent />
  </PermissionCheck>
)
