import {DateTime} from 'luxon'

import {
  Answer,
  CompletedQuestionnaireAnswer,
  MonthlySurveyType,
  Question,
  QuestionnaireAnswers,
  QuestionType,
  RawQuestionnaireAnswer,
} from '../models'
import {formatDate} from './dates'
import {formatLocaleNumber, isTruthy} from './helpers'

export const getEmptyQuestionnaireAnswers = (questions: Question[]) =>
  questions.reduce((answers: QuestionnaireAnswers, item) => {
    answers[item.id] = null
    return answers
  }, {})

const isValidTextAnswer = (
  question: Question,
  answer: Answer,
): answer is string =>
  question.type === QuestionType.TEXT && typeof answer === 'string' && !!answer

const isValidTimeAnswer = (
  question: Question,
  answer: Answer,
): answer is string =>
  question.type === QuestionType.TIME &&
  typeof answer === 'string' &&
  DateTime.fromISO(answer).isValid

const isValidDateAnswer = (
  question: Question,
  answer: Answer,
): answer is string =>
  question.type === QuestionType.DATE &&
  typeof answer === 'string' &&
  DateTime.fromISO(answer).isValid

const isValidDateTimeAnswer = (
  question: Question,
  answer: Answer,
): answer is string =>
  question.type === QuestionType.DATE_TIME &&
  typeof answer === 'string' &&
  DateTime.fromISO(answer).isValid

const isValidNumberAnswer = (
  question: Question,
  answer: Answer,
): answer is string =>
  question.type === QuestionType.NUMBER &&
  typeof answer === 'string' &&
  !!answer

const isValidSliderAnswer = (
  question: Question,
  answer: Answer,
): answer is number =>
  question.type === QuestionType.SLIDER && typeof answer === 'number'

const isValidWeightAnswer = (
  question: Question,
  answer: Answer,
): answer is number =>
  question.type === QuestionType.WEIGHT && typeof answer === 'number'

const isValidHeightAnswer = (
  question: Question,
  answer: Answer,
): answer is number =>
  question.type === QuestionType.HEIGHT && typeof answer === 'number'

const isValidChoiceAnswer = (
  question: Question,
  answer: Answer,
): answer is number =>
  question.type === QuestionType.CHOICE &&
  typeof answer === 'number' &&
  !!question.choices

export const isValidMultiChoiceAnswer = (
  question: Question,
  answer: Answer,
): answer is boolean[] =>
  question.type === QuestionType.MULTI_CHOICE &&
  !!question.choices &&
  Array.isArray(answer) &&
  answer.every((item) => typeof item === 'boolean') &&
  answer.length === question.choices.length

export const formatQuestionnaireAnswersForDisplay = (
  answers: QuestionnaireAnswers,
  questions: Question[],
) =>
  questions.map((question) => {
    const {title} = question
    const answer = answers[question.id]

    if (answer == null) {
      return {
        title,
        value: null,
      }
    }

    if (
      isValidTextAnswer(question, answer) ||
      isValidNumberAnswer(question, answer)
    ) {
      return {
        title,
        value: answer,
      }
    }
    if (isValidTimeAnswer(question, answer)) {
      return {
        title,
        value: formatDate(DateTime.TIME_SIMPLE, answer),
      }
    }
    if (isValidDateTimeAnswer(question, answer)) {
      return {
        title,
        value: formatDate(DateTime.DATETIME_SHORT, answer),
      }
    }
    if (isValidDateAnswer(question, answer)) {
      return {
        title,
        value: formatDate(DateTime.DATE_FULL, answer),
      }
    }
    if (isValidSliderAnswer(question, answer)) {
      return {
        title,
        value: answer.toLocaleString(),
      }
    }
    if (isValidHeightAnswer(question, answer)) {
      return {
        title,
        value: `${formatLocaleNumber(answer, 1)} cm`,
      }
    }
    if (isValidWeightAnswer(question, answer)) {
      return {
        title,
        value: `${formatLocaleNumber(answer, 1)} kg`,
      }
    }
    if (isValidChoiceAnswer(question, answer)) {
      return {
        title,
        value: question.choices![answer].label,
      }
    }
    if (isValidMultiChoiceAnswer(question, answer)) {
      return {
        title,
        value: answer
          .map(
            (isSelected, index) => isSelected && question.choices![index].label,
          )
          .filter(Boolean)
          .join(', '),
      }
    }

    return {
      title,
      value: null,
    }
  }, {} as Record<string, string>)

export const formatQuestionnaireAnswersForRequest = (
  answers: QuestionnaireAnswers,
  questions: Question[],
) =>
  questions
    .map((question) => {
      const answer = answers[question.id]

      if (answer == null) {
        return null
      }

      if (
        isValidTextAnswer(question, answer) ||
        isValidNumberAnswer(question, answer) ||
        isValidTimeAnswer(question, answer) ||
        isValidDateAnswer(question, answer) ||
        isValidDateTimeAnswer(question, answer)
      ) {
        return {
          questionId: question.id,
          value: answer,
        }
      }
      if (
        isValidSliderAnswer(question, answer) ||
        isValidHeightAnswer(question, answer) ||
        isValidWeightAnswer(question, answer)
      ) {
        return {
          questionId: question.id,
          value: answer.toLocaleString(),
        }
      }
      if (isValidChoiceAnswer(question, answer)) {
        return {
          questionId: question.id,
          choices: [question.choices![answer].id],
        }
      }
      if (isValidMultiChoiceAnswer(question, answer)) {
        return {
          questionId: question.id,
          choices: answer
            .map(
              (isSelected, index) => isSelected && question.choices?.[index].id,
            )
            .filter(isTruthy),
        }
      }
      return null
    })
    .filter(
      (item) => !!item?.questionId && (item.value != null || !!item.choices),
    ) as RawQuestionnaireAnswer[]

const parseQuestionnaireAnswer = (
  question: Question,
  answer: CompletedQuestionnaireAnswer,
) => {
  switch (question.type) {
    case QuestionType.TEXT:
      return answer.value
    case QuestionType.TIME:
      return answer.value
    case QuestionType.DATE:
      return answer.value
    case QuestionType.DATE_TIME:
      return answer.value
    case QuestionType.NUMBER:
      return answer.value
    case QuestionType.SLIDER:
      return parseInt(answer.value!, 10)
    case QuestionType.HEIGHT:
      return answer.value == null ? null : parseFloat(answer.value)
    case QuestionType.WEIGHT:
      return answer.value == null ? null : parseFloat(answer.value)
    case QuestionType.CHOICE:
      const index = question.choices!.findIndex(
        (choice) => choice.id === answer.choices![0].id,
      )
      return index === -1 ? null : index
    case QuestionType.MULTI_CHOICE:
      return question.choices?.reduce((arr, choice) => {
        arr.push(
          answer.choices!.some(
            (selectedChoice) => selectedChoice.id === choice.id,
          ),
        )
        return arr
      }, [] as boolean[])
    default:
      return answer.value
  }
}

export const parseQuestionnaireAnswers = (
  questions: Question[],
  answers?: CompletedQuestionnaireAnswer[] | null,
) =>
  (answers ?? []).reduce((arr, answer) => {
    const currentQuestion = questions.find(
      (question) => question.id === answer.questionId,
    )

    if (currentQuestion && (answer.value != null || answer.choices)) {
      arr[answer.questionId] =
        parseQuestionnaireAnswer(currentQuestion, answer) ?? null
    }
    return arr
  }, getEmptyQuestionnaireAnswers(questions))

export const findNextQuestion = (
  questions: Question[],
  answer: Answer | null,
  currentIndex: number,
) => {
  const currentQuestion = questions[currentIndex]

  if (
    typeof answer !== 'number' ||
    currentQuestion.type !== QuestionType.CHOICE ||
    !currentQuestion.choices
  ) {
    return currentIndex + 1
  }

  const choice = currentQuestion.choices[answer]
  if (!choice?.nextQuestionAlias) {
    return currentIndex + 1
  }

  const nextIndex = questions.findIndex(
    (question) => question.alias === choice.nextQuestionAlias,
  )

  return nextIndex === -1 ? currentIndex + 1 : nextIndex
}

export const isMonthlySurveyType = (type: unknown): type is MonthlySurveyType =>
  typeof type === 'string' &&
  Object.values<string>(MonthlySurveyType).includes(type)
