import React, {useContext, useMemo} from 'react'
import useBreadcrumbs, {
  BreadcrumbMatch,
  BreadcrumbsRoute,
} from 'use-react-router-breadcrumbs'
import {useTranslation} from 'react-i18next'
import {useLocation, useNavigate} from 'react-router'

import {StyledText, StyledSeparator, Wrapper} from './Breadcrumbs.styled'

import {MaterialsContext} from '~/components/educationalMaterials/MaterialsContext'
import {PatientsContext} from '~/components/patients/PatientsContext'
import {QuestionnairesContext} from '~/components/questionnaires/QuestionnairesContext'
import {formatName} from '~/utils'

const useBreadcrumbRoutes = () => {
  const {t} = useTranslation()
  const {contents, itemIdMap} = useContext(MaterialsContext)
  const {patient} = useContext(PatientsContext)
  const {questionnaire} = useContext(QuestionnairesContext)

  const location = useLocation()
  const materialFolders = location.pathname.split('/').slice(4)

  const getMaterialsBreadcrumb = (id: string | undefined) => {
    if (!id || !itemIdMap[id]) {
      return null
    }
    return <span>{itemIdMap[id].name}</span>
  }

  const getPatientBreadcrumb = () => formatName(patient)

  const baseMaterialsPath = '/app/materials/:conditionId'

  const materialFolderBreadcrumbs = materialFolders.map<
    BreadcrumbsRoute<string>
  >((_, index) => {
    const activeFolders = materialFolders.slice(0, index)
    const path = [baseMaterialsPath, ...activeFolders].join('/')

    return {
      path: `${path}/:subcategoryId`,
      breadcrumb: ({match}: {match: BreadcrumbMatch}) =>
        getMaterialsBreadcrumb(match.params.subcategoryId),
    }
  }, [])

  return [
    {
      path: '/app/patients',
      breadcrumb: t('My patients'),
    },
    {
      path: '/app/patients/:patientId',
      breadcrumb: getPatientBreadcrumb(),
    },
    {
      path: '/app/patients/:patientId/timeline',
      breadcrumb: t('Timeline'),
    },
    {
      path: '/app/patients/:patientId/trends',
      breadcrumb: t('Trends'),
    },
    {
      path: '/app/patients/:patientId/appointments',
      breadcrumb: t('Appointments'),
    },
    {
      path: '/app/patients/:patientId/activities',
      breadcrumb: t('Activities'),
    },
    {
      path: '/app/patients/:patientId/medical',
      breadcrumb: t('Medical information'),
    },
    {
      path: '/app/patients/:patientId/lifestyle',
      breadcrumb: t('Lifestyle information'),
    },
    {
      path: '/app/patients/:patientId/preventive',
      breadcrumb: t('Preventive Activities'),
    },
    {
      path: '/app/patients/:patientId/personal',
      breadcrumb: t('Personal information'),
    },
    {
      path: '/app/patients/:patientId/consultations',
      breadcrumb: t('Consultations'),
    },
    {
      path: '/app/patients/:patientId/questionnaires',
      breadcrumb: t('Questionnaires'),
    },
    {
      path: '/app/patients/:patientId/questionnaires/:assignedQuestionnaireId',
      breadcrumb: questionnaire?.title,
    },
    {
      path: '/app/patients/:patientId/materials',
      breadcrumb: t('Shared materials'),
    },
    {
      path: '/app/patients/:patientId/profile',
      breadcrumb: t('Personal details'),
    },
    {
      path: '/app/profile',
      breadcrumb: t('My Profile'),
    },
    {
      path: '/app/materials',
      breadcrumb: t('Educational materials'),
    },
    {
      path: '/app/materials/file',
      breadcrumb: null,
    },
    {
      path: '/app/materials/preview',
      breadcrumb: t('Preview'),
    },
    {
      path: '/app/materials/file/:materialId',
      breadcrumb: ({match}: {match: BreadcrumbMatch}) =>
        getMaterialsBreadcrumb(match.params.materialId),
    },
    {
      path: '/app/materials/:conditionId',
      breadcrumb: ({match}: {match: BreadcrumbMatch}) => (
        <span>
          {
            contents?.find(
              (condition) =>
                condition.structure.id === match.params.conditionId,
            )?.structure.condition
          }
        </span>
      ),
    },
    ...materialFolderBreadcrumbs,
    {
      path: '/app/questionnaires',
      breadcrumb: t('Questionnaires'),
      children: [
        {
          path: 'library',
          breadcrumb: null,
        },
        {
          path: 'responses',
          breadcrumb: null,
        },
        {
          path: 'unanswered',
          breadcrumb: null,
        },
        {
          path: ':questionnaireId',
          breadcrumb: questionnaire?.title,
        },
      ],
    },
    {
      path: '/medical-record-share',
      breadcrumb: t('Link sharing'),
    },
    {
      path: '/app/appointments',
      breadcrumb: t('My appointments'),
      children: [
        {path: ':appointmentId/:patientId', breadcrumb: t('Screening Details')},
      ],
    },
  ]
}

type BreadcrumbsProps = {
  clickable: boolean
}

export const Breadcrumbs: React.FC<BreadcrumbsProps> = ({clickable}) => {
  const navigate = useNavigate()
  const routes = useBreadcrumbRoutes()
  const breadcrumbs = useBreadcrumbs(routes, {
    disableDefaults: true,
  })

  const breadcrumbsToShow = useMemo(() => breadcrumbs.slice(-3), [breadcrumbs])

  return (
    <Wrapper>
      {breadcrumbsToShow.map(({breadcrumb, match}, index) => (
        <React.Fragment key={index}>
          {!!index && <StyledSeparator>/</StyledSeparator>}
          <StyledText
            isLast={index === breadcrumbsToShow.length - 1}
            onClick={clickable ? () => navigate(match.pathname) : undefined}>
            {breadcrumbsToShow.length === 3 && !index ? '...' : breadcrumb}
          </StyledText>
        </React.Fragment>
      ))}
    </Wrapper>
  )
}
