/* tslint:disable */
/* eslint-disable */
/**
 * Cushla API
 * REST API for Cushla
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Reason why the appointment was cancelled. `null` if not cancelled.
 * @export
 * @enum {string}
 */

export enum AppointmentCancellationReasonDto {
  FEELING_BETTER = 'FEELING_BETTER',
  CONDITION_WORSE = 'CONDITION_WORSE',
  SICK = 'SICK',
  COVID_19 = 'COVID_19',
  AWAY = 'AWAY',
  OTHER = 'OTHER',
  WORK = 'WORK',
}
