import React, {forwardRef, ReactNode} from 'react'

import {StyledContainer} from './Container.styled'

type ContainerProps = {
  children: ReactNode
  className?: string
  flex?: boolean
  fullWidth?: boolean
  inline?: boolean
  direction?: 'row' | 'column'
  flexNumber?: number
  align?: 'flex-start' | 'center' | 'flex-end' | 'stretch'
  justify?: 'flex-start' | 'center' | 'flex-end' | 'space-between'
  wrap?: boolean
  spacing?: number
  onMouseEnter?: React.MouseEventHandler<HTMLDivElement>
  onMouseLeave?: React.MouseEventHandler<HTMLDivElement>
}
export const Container = forwardRef<HTMLDivElement, ContainerProps>(
  (
    {
      children,
      className,
      flex,
      inline,
      direction = 'row',
      flexNumber,
      fullWidth,
      align,
      justify,
      wrap,
      spacing,
      onMouseEnter,
      onMouseLeave,
    },
    ref,
  ) => (
    <StyledContainer
      className={className}
      flex={flex}
      inline={inline}
      direction={direction}
      align={align}
      flexNumber={flexNumber}
      fullWidth={fullWidth}
      justify={justify}
      wrap={wrap}
      spacing={spacing}
      ref={ref}
      onMouseLeave={onMouseLeave}
      onMouseEnter={onMouseEnter}>
      {children}
    </StyledContainer>
  ),
)
