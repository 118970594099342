/* tslint:disable */
/* eslint-disable */
/**
 * Cushla API
 * REST API for Cushla
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */

export enum MedicalRecordPermission {
  ADDRESS = 'ADDRESS',
  CONTACT_DETAILS = 'CONTACT_DETAILS',
  DEMOGRAPHIC_DETAILS = 'DEMOGRAPHIC_DETAILS',
  HEALTH_INSURANCE = 'HEALTH_INSURANCE',
  NAME_DETAILS = 'NAME_DETAILS',
  NEXT_OF_KIN = 'NEXT_OF_KIN',
  BLOOD_TYPE = 'BLOOD_TYPE',
  ORGAN_DONOR = 'ORGAN_DONOR',
  ALLERGY = 'ALLERGY',
  CHOLESTEROL = 'CHOLESTEROL',
  DIAGNOSED_CONDITIONS = 'DIAGNOSED_CONDITIONS',
  FAMILY_HISTORY = 'FAMILY_HISTORY',
  MEDICAL_TEST = 'MEDICAL_TEST',
  MEDICATION = 'MEDICATION',
  OBSTETRICS = 'OBSTETRICS',
  SURGERY = 'SURGERY',
  VACCINATION = 'VACCINATION',
  LIFE_MILESTONES = 'LIFE_MILESTONES',
  LIFESTYLE_INFORMATION = 'LIFESTYLE_INFORMATION',
  PERSONAL_INFORMATION = 'PERSONAL_INFORMATION',
  APPOINTMENT = 'APPOINTMENT',
  HEALTH_NETWORK = 'HEALTH_NETWORK',
  ACTIVITY = 'ACTIVITY',
  PHYSITRACK_PROGRAMS = 'PHYSITRACK_PROGRAMS',
}
