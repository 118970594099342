import {AcceptInviteResponse, OpenCampaignInvite, StatusType} from '../models'
import {AssertTypeFn} from './general'
import {
  assertArray,
  assertEnum,
  assertString,
  assertStringOrNull,
} from '../utils'

export const openCampaignInviteNormaliser: AssertTypeFn<OpenCampaignInvite> = (
  obj,
) => ({
  campaignId: assertString(obj.campaignId),
  campaignImage: assertStringOrNull(obj.campaignImage),
  inviteId: assertString(obj.inviteId),
  payerName: assertString(obj.payerName),
})

export const aggregatedCampaignInvitesNormaliser: AssertTypeFn<
  OpenCampaignInvite[]
> = (arr) => assertArray(arr, openCampaignInviteNormaliser)

export const acceptInviteResponseNormaliser: AssertTypeFn<
  AcceptInviteResponse
> = (obj) => ({
  result: assertEnum(obj.result, StatusType),
})
