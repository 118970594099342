import {NextOfKin, PractitionersPatient} from '@common/models'
import {EnumType, useEnumTranslations} from '@common/utils'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'

import {useGetPatientNextOfKinDetails} from '~/api'
import {DataAccordionContent} from '~/components/general/accordion/DataAccordionContent'
import {Loading} from '~/components/general/loading/Loading'
import {Container} from '~/components/general/container/Container'
import {Typography} from '~/components/general/typography/Typography'

type NextOfKinAccordionContentProps = {
  patient: PractitionersPatient
}

export const NextOfKinAccordionContent: React.FC<
  NextOfKinAccordionContentProps
> = ({patient}) => {
  const {t} = useTranslation()
  const {translateEnumValue} = useEnumTranslations()

  const {data, isInitialLoading} = useGetPatientNextOfKinDetails({
    patientId: patient.patientId,
  })

  const getAccordionData = useCallback(
    (nextOfKin: NextOfKin) => [
      {
        title: t('Name'),
        value: nextOfKin?.name || '-',
      },
      {
        title: t('Phone number'),
        value: nextOfKin?.phoneNumber || '-',
      },
      {
        title: t('Address'),
        value: nextOfKin?.address || '-',
      },
      {
        title: t('Relationship'),
        value:
          translateEnumValue(EnumType.Relationship, nextOfKin?.relationship) ||
          '-',
      },
    ],
    [t, translateEnumValue],
  )

  if (isInitialLoading) {
    return <Loading />
  }

  return (
    <Container direction="column" spacing={5}>
      {data?.map((nextOfKin, index) => (
        <Container direction="column" spacing={1} key={index}>
          <Typography weight="medium">
            {t('Emergency contact {{index}}', {index: index + 1})}
          </Typography>
          <DataAccordionContent data={getAccordionData(nextOfKin)} />
        </Container>
      ))}
    </Container>
  )
}
